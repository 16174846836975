import { staffConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  earningCreate: false,
  excel: null,
  monthlySalaryStaff: [],
  payment: false
}

export function staff(state = {}, action) {
  switch (action.type) {
    //#region Staff
    case staffConstants.STAFF_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case staffConstants.STAFF_ARCHIVING_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.STAFF_GETALL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_GETALL_SUCCESS:
      return {
        ...state,
        staff: action.staff,
        loading: false
      };
    case staffConstants.STAFF_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.STAFF_GETID_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_GETID_SUCCESS:
      return {
        ...state,
        detail: action.staff,
        loading: false
      };
    case staffConstants.STAFF_GETID_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.STAFF_DELETE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case staffConstants.STAFF_DELETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.STAFF_CREATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.staff,
        loading: false
      };
    case staffConstants.STAFF_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
      
    case staffConstants.STAFF_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.staff || action.params,
        detail: action.params,
        loading: false
      };
    case staffConstants.STAFF_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        
        detail: action.params,
        error: action.error,
        loading: false
      };
    
    case staffConstants.STAFF_GETID_CHECKOUT_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_GETID_CHECKOUT_SUCCESS:
      return {
        ...state,
        staffCheckout: action.staffCheckout,
        loading: false
      };
    case staffConstants.STAFF_GETID_CHECKOUT_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.STAFF_GETID_OVERTIME_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_GETID_OVERTIME_SUCCESS:
      return {
        ...state,
        staffOvertime: action.staffOvertime,
        loading: false
      };
    case staffConstants.STAFF_GETID_OVERTIME_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.STAFF_GETID_LEAVE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_GETID_LEAVE_SUCCESS:
      return {
        ...state,
        staffLeave: action.staffLeave,
        loading: false
      };
    case staffConstants.STAFF_GETID_LEAVE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.STAFF_GETID_SALARY_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_GETID_SALARY_SUCCESS:
      return {
        ...state,
        staffSalary: action.staffSalary,
        loading: false
      };
    case staffConstants.STAFF_GETID_SALARY_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.STAFF_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.STAFF_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case staffConstants.STAFF_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Leave
    case staffConstants.LEAVE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.LEAVE_GETALL_SUCCESS:
      return {
        ...state,
        leave: action.leave,
        loading: false
      };
    case staffConstants.LEAVE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.LEAVE_GETID_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.LEAVE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.leave,
        loading: false
      };
    case staffConstants.LEAVE_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.LEAVE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.LEAVE_DELETE_SUCCESS:
      return {
        ...state,
        
        isDeleted: true,
        loading: false
      };
    case staffConstants.LEAVE_DELETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case staffConstants.LEAVE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.LEAVE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.leave,
        loading: false
      };
    case staffConstants.LEAVE_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.LEAVE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.LEAVE_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.leave || action.params,
        detail: action.params,
        loading: false
      };
    case staffConstants.LEAVE_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case staffConstants.LEAVE_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.LEAVE_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case staffConstants.LEAVE_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Earning Leave
    case staffConstants.EARNING_GETID_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.EARNING_GETID_SUCCESS:
      return {
        ...state,
        earning: action.earning,
        loading: false
      };
    case staffConstants.EARNING_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
      
    case staffConstants.EARNING_DELETE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.EARNING_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        earning: state.earning.filter(item => item.id !== action.params.Id),
        loading: false
      };
    case staffConstants.EARNING_DELETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.EARNING_CREATE_REQUEST:
      return {
        ...state,
        earningCreate: true,
        loading: true
      };
    case staffConstants.EARNING_CREATE_SUCCESS:
      return {
        ...state,
        earningCreate: action.earningCreate,
        loading: false
      };
    case staffConstants.EARNING_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.EARNING_GETBALANCE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.EARNING_GETBALANCE_SUCCESS:
      return {
        ...state,
        balance: action.balance,
        loading: false
      };
    case staffConstants.EARNING_GETBALANCE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Salary
    case staffConstants.SALARY_GETALL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.SALARY_GETALL_SUCCESS:
      return {
        ...state,
        salary: action.salary,
        loading: false
      };
    case staffConstants.SALARY_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.SALARY_GETID_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.SALARY_GETID_SUCCESS:
      return {
        ...state,
        detail: action.salary,
        loading: false
      };
    case staffConstants.SALARY_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
      
    case staffConstants.SALARY_DELETE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.SALARY_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case staffConstants.SALARY_DELETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.SALARY_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.SALARY_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case staffConstants.SALARY_ARCHIVING_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.SALARY_CREATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.SALARY_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.salary,
        loading: false
      };
    case staffConstants.SALARY_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.SALARY_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.SALARY_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.salary || action.params,
        detail: action.params,
        loading: false
      };
    case staffConstants.SALARY_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case staffConstants.SALARY_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.SALARY_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case staffConstants.SALARY_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Overtime
    case staffConstants.OVERTIME_GETALL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.OVERTIME_GETALL_SUCCESS:
      return {
        ...state,
        overtime: action.overtime,
        loading: false
      };
    case staffConstants.OVERTIME_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
      
    case staffConstants.OVERTIME_GETID_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.OVERTIME_GETID_SUCCESS:
      return {
        ...state,
        detail: action.overtime,
        loading: false
      };
    case staffConstants.OVERTIME_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.OVERTIME_DELETE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.OVERTIME_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case staffConstants.OVERTIME_DELETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.OVERTIME_CREATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.OVERTIME_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.overtime,
        loading: false
      };
    case staffConstants.OVERTIME_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.OVERTIME_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.OVERTIME_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.overtime || action.params,
        detail: action.params,
        loading: false
      };
    case staffConstants.OVERTIME_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case staffConstants.OVERTIME_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.OVERTIME_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case staffConstants.OVERTIME_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Certificate
    case staffConstants.CERTIFICATE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.CERTIFICATE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case staffConstants.CERTIFICATE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.CERTIFICATE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.CERTIFICATE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.certificate,
        loading: false
      };
    case staffConstants.CERTIFICATE_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region MonthlySalary
    case staffConstants.MONTHLY_SALARY_GETALL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.MONTHLY_SALARY_GETALL_SUCCESS:
      return {
        ...state,
        monthlySalary: action.monthlySalary,
        loading: false
      };
    case staffConstants.MONTHLY_SALARY_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.MONTHLY_SALARY_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.MONTHLY_SALARY_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case staffConstants.MONTHLY_SALARY_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.MONTHLY_SALARY_GET_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.MONTHLY_SALARY_GET_SUCCESS:
      return {
        ...state,
        detail: action.monthlySalary,
        loading: false
      };
    case staffConstants.MONTHLY_SALARY_GET_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.MONTHLY_SALARY_GET_MONTHLY_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.MONTHLY_SALARY_GET_MONTHLY_SUCCESS:
      return {
        ...state,
        monthlySalaryStaff: action.monthlySalaryStaff,
        loading: false
      };
    case staffConstants.MONTHLY_SALARY_GET_MONTHLY_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.MONTHLY_SALARY_CREATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.MONTHLY_SALARY_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.salary,
        loading: false
      };
    case staffConstants.MONTHLY_SALARY_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case staffConstants.MONTHLY_SALARY_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.MONTHLY_SALARY_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.salary || action.params,
        detail: action.params,
        loading: false
      };
    case staffConstants.MONTHLY_SALARY_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case staffConstants.MONTHLY_SALARY_UPDATE_PAYMENT_REQUEST:
      return { ...state, ...initialState };
    case staffConstants.MONTHLY_SALARY_UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: true,
        loading: false
      };
    case staffConstants.MONTHLY_SALARY_UPDATE_PAYMENT_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    default:
      return state
  }
}
