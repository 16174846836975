export const PurchaseOrderStatus = [
  {
    id: 1,
    name: 'PURCHASEORDERSTATUS.ONHOLD'
  },
  {
    id: 2,
    name: 'PURCHASEORDERSTATUS.DELIVERED'
  },
  {
    id: 3,
    name: 'PURCHASEORDERSTATUS.CANCELLED'
  }
]
