import { proposalConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  excel: null
}

export function proposal(state = {}, action) {
  switch (action.type) {
    //#region Proposal
    case proposalConstants.PROPOSAL_GETALL_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_GETALL_SUCCESS:
      return {
        ...state,
        proposal: action.proposal,
        loading: false
      };
    case proposalConstants.PROPOSAL_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case proposalConstants.PROPOSAL_GETID_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_GETID_SUCCESS:
      return {
        ...state,
        detail: action.proposal,
        loading: false
      };
    case proposalConstants.PROPOSAL_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case proposalConstants.PROPOSAL_DELETE_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case proposalConstants.PROPOSAL_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case proposalConstants.PROPOSAL_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case proposalConstants.PROPOSAL_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case proposalConstants.PROPOSAL_CREATE_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.proposal,
        loading: false
      };
    case proposalConstants.PROPOSAL_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case proposalConstants.PROPOSAL_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.proposal || action.params,
        detail: action.params,
        loading: false
      };
    case proposalConstants.PROPOSAL_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case proposalConstants.PROPOSAL_UPDATESTATUS_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_UPDATESTATUS_SUCCESS:
      return {
        ...state,
        isRedirect: action.proposal || action.params,
        loading: false
      };
    case proposalConstants.PROPOSAL_UPDATESTATUS_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
      
    case proposalConstants.PROPOSAL_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case proposalConstants.PROPOSAL_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case proposalConstants.PROPOSAL_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion    
    default:
      return state
  }
}
