import { checkConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  transfer: false,
  excel: null
}

export function check(state = {}, action) {
  switch (action.type) {
    //#region Check
    case checkConstants.CHECK_GETALL_REQUEST:
      return { ...state, ...initialState };
    case checkConstants.CHECK_GETALL_SUCCESS:
      return {
        ...state,
        check: action.check,
        loading: false
      };
    case checkConstants.CHECK_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkConstants.CHECK_GETID_REQUEST:
      return { ...state, ...initialState };
    case checkConstants.CHECK_GETID_SUCCESS:
      return {
        ...state,
        detail: action.check,
        loading: false
      };
    case checkConstants.CHECK_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    
    case checkConstants.CHECK_ADDCOLLECTION_REQUEST:
      return { ...state, ...initialState };
    case checkConstants.CHECK_ADDCOLLECTION_SUCCESS:
      return {
        ...state,
        transfer: action.transfer || true,
        loading: false
      };
    case checkConstants.CHECK_ADDCOLLECTION_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkConstants.CHECK_ADDPAYMENT_REQUEST:
      return { ...state, ...initialState };
    case checkConstants.CHECK_ADDPAYMENT_SUCCESS:
      return {
        ...state,
        transfer: action.transfer || true,
        loading: false
      };
    case checkConstants.CHECK_ADDPAYMENT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkConstants.CHECK_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case checkConstants.CHECK_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case checkConstants.CHECK_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkConstants.CHECK_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case checkConstants.CHECK_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.check || action.params,
        detail: action.params,
        loading: false
      };
    case checkConstants.CHECK_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion    
    default:
      return state
  }
}
