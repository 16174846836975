import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

// This should be the same instance you pass to MsalProvider
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE || event.eventType === EventType.SSO_SILENT_FAILURE) {
    console.error("msalInstance.addEventCallback v2" + event.eventType);
    localStorage.removeItem("accessToken");
    msalInstance.logoutRedirect();
  }
});


export class AuthorizedApiBase {
    constructor() {
      this.transformOptions = (options) => {

        return new Promise(async (resolve, reject) => {
          let token = await acquireAccessToken(msalInstance);
          localStorage.setItem('accessToken', token);
          
          options.headers = Object.assign(Object.assign({}, options.headers), {
            'Authorization': 'Bearer ' + token
          });

          resolve(options);
        });
        };
    }
}

const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    msalInstance.logoutRedirect();
  }
  const request = {
    scopes: [window.ENVVARS.scope],
    account: activeAccount || accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken
};
