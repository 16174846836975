/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { KTSVG } from '../../../helpers';
import { KTCard, KTCardBody } from '../../../components';
import { PageTitle } from '../../../core';

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { useDispatch, useSelector } from 'react-redux';
import { bankPaymentOrderActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { isRedirect, detail, loading, getAvailablePayments } = useSelector(state => state.bankPaymentOrder);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bankPaymentOrderActions.bankPaymentOrderGetAvailablePayments());
    dispatch(bankPaymentOrderActions.bankPaymentOrderGetId(id));
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PaymentRequestList: Yup.array().of(Yup.object().shape({
      PaymentRequestId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' }))
    })).min(1, intl.formatMessage({ id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    Id: '',
    Name: '',
    PaymentDate: new Date(),
    PaymentRequestList: [],
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      dispatch(bankPaymentOrderActions.bankPaymentOrderUpdate(values));
    }
  })

  useEffect(() => {
    if (getAvailablePayments?.length > 0) {
      for (const property in detail) {
        const propVal = property.charAt(0).toUpperCase() + property.slice(1);

        if (property === 'bankPaymentOrderItems') {
          detail['bankPaymentOrderItems']?.forEach((item, index) => {
            const matchingPayment = getAvailablePayments.find(payment => payment.paymentRequestId === item.paymentRequestId);
            
            if (matchingPayment) formik.setFieldValue(`PaymentRequestList[${index}][PaymentRequestId]`, item.paymentRequestId);
          });
        } else {
          for (const values in formValues) {
            if (values === propVal) formik.setFieldValue(propVal, detail[property]);
          }
        }
      }
    }
}, [detail, getAvailablePayments]);

  const handleCheckboxChange = (event, paymentRequestId) => {
    const isChecked = event.target.checked
    const newPaymentRequestList = isChecked
      ? [...formik.values.PaymentRequestList, { PaymentRequestId: paymentRequestId }]
      : formik.values.PaymentRequestList.filter(
          (item) => item.PaymentRequestId !== paymentRequestId
        )
    formik.setFieldValue('PaymentRequestList', newPaymentRequestList)
  }

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.CASH.BANKPAYMENTORDER' }),
      path: '/cash/bank-payment-order',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/cash/bank-payment-order/detail/${id}`,
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.CASH.BANKPAYMENTORDER.EDIT' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('PaymentDate')}
                    onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST' })}</label>
              <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                <div className='table-responsive mt-4'>
                  <table className='table align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='w-25px'>&nbsp;</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.PROJECTNAME' })}</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.COMPANYTITLE' })}</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.COMPANYBALANCE' })}</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.REQUESTEDPAYMENT' })}</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.REQUESTEDDATE' })}</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.REQUESTEDUSERNAMESURNAME' })}</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.APPROVEDPAYMENT' })}</th>
                        <th>{intl.formatMessage({ id: 'BANKPAYMENTORDER.CREATE.PAYMENTREQUESTLIST.APPROVEDDATE' })}</th>
                      </tr>
                    </thead>

                    <tbody>
                    {
                      getAvailablePayments?.map((item, index) => {
                        const isChecked = formik.values.PaymentRequestList?.some(ch => ch.PaymentRequestId === item?.paymentRequestId);

                        return (
                          <tr key={index}>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className="form-check-input widget-9-check"
                                  type="checkbox"
                                  value={item?.paymentRequestId}
                                  checked={isChecked}
                                  onChange={(event) => handleCheckboxChange(event, item?.paymentRequestId)}
                                />
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  <div className='text-dark fw-bold fs-6'>
                                    {item?.projectName}
                                  </div>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {item?.name}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-muted fw-semibold">{item?.companyTitle}</td>
                            <td className="text-muted fw-semibold">{intl.formatNumber(item?.companyBalance, { style: 'currency', currency: 'try' })}</td>
                            <td className="text-muted fw-semibold">{intl.formatNumber(item?.requestedPayment, { style: 'currency', currency: 'try' })}</td>
                            <td className="text-muted fw-semibold">{intl.formatDate(new Date(item?.requestedDate), { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                            <td className="text-muted fw-semibold">{item?.requestedUserNameSurname}</td>
                            <td className="text-muted fw-semibold">{intl.formatNumber(item?.approvedPayment, { style: 'currency', currency: 'try' })}</td>
                            <td className="text-muted fw-semibold">{intl.formatDate(new Date(item?.approvedDate), { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
