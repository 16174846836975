export const tenantcompanyConstants = {
  //#region TenantCompany
  TENANTCOMPANY_GETID_REQUEST: 'TENANTCOMPANY_GETID_REQUEST',
  TENANTCOMPANY_GETID_SUCCESS: 'TENANTCOMPANY_GETID_SUCCESS',
  TENANTCOMPANY_GETID_FAILURE: 'TENANTCOMPANY_GETID_FAILURE',

  TENANTCOMPANY_UPDATE_REQUEST: 'TENANTCOMPANY_UPDATE_REQUEST',
  TENANTCOMPANY_UPDATE_SUCCESS: 'TENANTCOMPANY_UPDATE_SUCCESS',
  TENANTCOMPANY_UPDATE_FAILURE: 'TENANTCOMPANY_UPDATE_FAILURE',
  //#endregion            
};
