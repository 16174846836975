export const paymentplanConstants = {

  PAYMENTPLAN_CREATE_REQUEST: 'PAYMENTPLAN_CREATE_REQUEST',
  PAYMENTPLAN_CREATE_SUCCESS: 'PAYMENTPLAN_CREATE_SUCCESS',
  PAYMENTPLAN_CREATE_FAILURE: 'PAYMENTPLAN_CREATE_FAILURE',

  PAYMENTPLAN_DELETE_REQUEST: 'PAYMENTPLAN_DELETE_REQUEST',
  PAYMENTPLAN_DELETE_SUCCESS: 'PAYMENTPLAN_DELETE_SUCCESS',
  PAYMENTPLAN_DELETE_FAILURE: 'PAYMENTPLAN_DELETE_FAILURE',

  PAYMENTPLAN_UPDATE_REQUEST: 'PAYMENTPLAN_UPDATE_REQUEST',
  PAYMENTPLAN_UPDATE_SUCCESS: 'PAYMENTPLAN_UPDATE_SUCCESS',
  PAYMENTPLAN_UPDATE_FAILURE: 'PAYMENTPLAN_UPDATE_FAILURE',

  PAYMENTPLAN_UNSCHEDULED_GETALL_REQUEST: 'PAYMENTPLAN_UNSCHEDULED_GETALL_REQUEST',
  PAYMENTPLAN_UNSCHEDULED_GETALL_SUCCESS: 'PAYMENTPLAN_UNSCHEDULED_GETALL_SUCCESS',
  PAYMENTPLAN_UNSCHEDULED_GETALL_FAILURE: 'PAYMENTPLAN_UNSCHEDULED_GETALL_FAILURE',

  PAYMENTPLAN_EXPENSEINVOICE_GETALL_REQUEST: 'PAYMENTPLAN_EXPENSEINVOICE_GETALL_REQUEST',
  PAYMENTPLAN_EXPENSEINVOICE_GETALL_SUCCESS: 'PAYMENTPLAN_EXPENSEINVOICE_GETALL_SUCCESS',
  PAYMENTPLAN_EXPENSEINVOICE_GETALL_FAILURE: 'PAYMENTPLAN_EXPENSEINVOICE_GETALL_FAILURE',

  PAYMENTPLAN_EXPENSEINVOICE_EXCEL_REQUEST: 'PAYMENTPLAN_EXPENSEINVOICE_EXCEL_REQUEST',
  PAYMENTPLAN_EXPENSEINVOICE_EXCEL_SUCCESS: 'PAYMENTPLAN_EXPENSEINVOICE_EXCEL_SUCCESS',
  PAYMENTPLAN_EXPENSEINVOICE_EXCEL_FAILURE: 'PAYMENTPLAN_EXPENSEINVOICE_EXCEL_FAILURE',

  PAYMENTPLAN_EXPENSEINVOICE_CREATE_REQUEST: 'PAYMENTPLAN_EXPENSEINVOICE_CREATE_REQUEST',
  PAYMENTPLAN_EXPENSEINVOICE_CREATE_SUCCESS: 'PAYMENTPLAN_EXPENSEINVOICE_CREATE_SUCCESS',
  PAYMENTPLAN_EXPENSEINVOICE_CREATE_FAILURE: 'PAYMENTPLAN_EXPENSEINVOICE_CREATE_FAILURE',

  PAYMENTPLAN_SALEINVOICE_GETALL_REQUEST: 'PAYMENTPLAN_SALEINVOICE_GETALL_REQUEST',
  PAYMENTPLAN_SALEINVOICE_GETALL_SUCCESS: 'PAYMENTPLAN_SALEINVOICE_GETALL_SUCCESS',
  PAYMENTPLAN_SALEINVOICE_GETALL_FAILURE: 'PAYMENTPLAN_SALEINVOICE_GETALL_FAILURE',

  PAYMENTPLAN_SALEINVOICE_EXCEL_REQUEST: 'PAYMENTPLAN_SALEINVOICE_EXCEL_REQUEST',
  PAYMENTPLAN_SALEINVOICE_EXCEL_SUCCESS: 'PAYMENTPLAN_SALEINVOICE_EXCEL_SUCCESS',
  PAYMENTPLAN_SALEINVOICE_EXCEL_FAILURE: 'PAYMENTPLAN_SALEINVOICE_EXCEL_FAILURE',

  PAYMENTPLAN_SALEINVOICE_CREATE_REQUEST: 'PAYMENTPLAN_SALEINVOICE_CREATE_REQUEST',
  PAYMENTPLAN_SALEINVOICE_CREATE_SUCCESS: 'PAYMENTPLAN_SALEINVOICE_CREATE_SUCCESS',
  PAYMENTPLAN_SALEINVOICE_CREATE_FAILURE: 'PAYMENTPLAN_SALEINVOICE_CREATE_FAILURE',

  PAYMENTPLAN_EXPENSESALEINVOICE_GETID_REQUEST: 'PAYMENTPLAN_EXPENSESALEINVOICE_GETID_REQUEST',
  PAYMENTPLAN_EXPENSESALEINVOICE_GETID_SUCCESS: 'PAYMENTPLAN_EXPENSESALEINVOICE_GETID_SUCCESS',
  PAYMENTPLAN_EXPENSESALEINVOICE_GETID_FAILURE: 'PAYMENTPLAN_EXPENSESALEINVOICE_GETID_FAILURE',
};
