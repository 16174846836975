/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, DeleteModal, ArchiveModal, DataTables, Select, Timeline, ImageUpload, FastTaskUpdate } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { workplanActions, lookupActions } from '../../../redux/actions';
import { SourceType, TaskState, KTSVG, detailPageUrl, DetailPage } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import * as Yup from 'yup'
import { useFormik } from 'formik'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Modal } from 'react-bootstrap'

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, error, loading, isRedirect } = useSelector(state => state.workplan);
  const { staffLookup, plotLookup } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(workplanActions.taskGetId(id));
    dispatch(lookupActions.staffGetAll());
  }, [id, isRedirect])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    TaskStateId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Name: '',
    StartDate: new Date(),
    EndDate: new Date(),
    Duration: 0,
    TaskStateId: null,
    StaffId: null,
    ProjectId: '',
    Description: "",
    CompletionRate: 0,
    TotalPersonDay: 0,
    TotalWork: 0,
    PlotId: null,
    RootTaskId: id,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(workplanActions.taskCreate(values));
      setShowForm(false)
      resetForm()
    },
  })

  useEffect(() => {
    if (detail) {
      formik.setFieldValue('ProjectId', detail?.projectId);
      if (detail?.blockId) {        
        dispatch(lookupActions.plotLookup({
          projectId: detail?.projectId,
          constructionId: detail?.constructionSiteId,
          blockId: detail?.blockId
        }));
        if (detail?.plotId) formik.setFieldValue('PlotId', detail?.plotId)
        else formik.setFieldValue('PlotId', null)
      }
    }    
  }, [detail])
  
  const handleDelete = () => dispatch(workplanActions.taskDelete({ id }));
  const handleArchive = () => dispatch(workplanActions.taskArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.TASK' }),
      path: '/workplan/task',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.TASK.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>

          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>                            
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen044.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.taskStateName}
                          </div>
                          {detail?.rootTaskName && <>
                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <Link to={detailPageUrl(DetailPage.Task, detail?.rootTaskId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                  path='/images/icons/duotune/arrows/arr066.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                                {detail?.rootTaskName}
                            </Link>
                          </div>
                          </>}

                          {detail?.projectName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.projectName} - {detail?.constructionSiteName} - {detail?.blockName} - {detail?.plotName}
                            </div>
                          </>}
                          

                          {detail?.staffName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Staff.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.staffName}
                            </div>
                          </>}

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        {detail?.startDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TASK.GET.STARTDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.startDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}

                        {detail?.endDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TASK.GET.ENDDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.endDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TASK.GET.REMANINGDAY' })}</div>
                          <div className="fw-bold fs-4">{detail?.remainingDay == 0 ? '-' : detail?.remainingDay < 0 ? detail?.remainingDay * -1 + intl.formatMessage({ id: 'PROJECT.DAYPASSED' }) : detail?.remainingDay + intl.formatMessage({ id: 'PROJECT.DAYLEFT' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TASK.GET.DURATION' })}</div>
                          <div className="fw-bold fs-4">{detail?.duration} {intl.formatMessage({ id: 'TASK.GET.DURATIONDAY' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TASK.CREATE.TOTALPERSONDAY' })}</div>
                          <div className="fw-bold fs-4">{detail?.totalPersonDay} {intl.formatMessage({ id: 'TASK.GET.DURATIONDAY' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TASK.CREATE.TOTALWORK' })}</div>
                          <div className="fw-bold fs-4">{detail?.totalWork} </div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TASK.GET.COMPLETIONRATE' })}</div>
                          <div className="fw-bold fs-4">% {detail?.completionRate} </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {detail?.description && <>
                  <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
                    {detail?.description}
                  </div>
                </>}                
              </KTCardBody>
            </KTCard>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >

                  <Tab eventKey="subTasks" title={intl.formatMessage({ id: 'TASK.GET.SUBTASKS' })}>

                    <div className='fv-row fv-plugins-icon-container mt-5'>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success"
                        onClick={() => setShowForm(true)}
                      >
                        <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'TASK.GET.SUBTASKS.ADD' })}
                      </button>
                    </div>

                    <div className='separator mt-4 mb-4'></div>

                    {detail?.subTasks?.length > 0 && <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'TASK.SEARCH.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Task, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'TASK.SEARCH.STAFFNAME' }),
                          prop: 'staffName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'TASK.SEARCH.STARTDATE' }),
                          prop: 'startDate',
                          cell: (row) => row?.startDate ? intl.formatDate(new Date(row?.startDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'TASK.SEARCH.ENDDATE' }),
                          cell: (row) => row?.endDate ? intl.formatDate(new Date(row?.endDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-',
                          prop: 'endDate',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'TASK.SEARCH.TOTALWORK' }),
                          prop: 'totalWork',
                          cell: (row) => `${row.totalWork}`,
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'TASK.SEARCH.COMPLETIONRATE' }),
                          prop: 'completionRate',
                          cell: (row) => `%${row.completionRate}`,
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'TASK.SEARCH.TASKSTATENAME' }),
                          prop: 'taskStateName',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.subTasks}
                      exportTitle={intl.formatMessage({ id: 'TASK.GET.SUBTASKS' })}
                    />}
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>

            <ImageUpload SourceId={Number(id)} SourceTypeId={SourceType.Task} />
          </>}
        </div>

        <div className='col-xl-3'>
          <FastTaskUpdate Id={Number(id)} TaskStateId={Number(detail?.taskStateId)} CompletionRate={Number(detail?.completionRate)} />
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Task} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.Task} collapse={false} />
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal
        onClick={handleArchive}
        onOpen={showArchive}
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={showForm}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowForm(false)}
        size="lg"
      >
        <div className='modal-content'>
          <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
              <h5 className='fw-bold m-0'>{intl.formatMessage({ id: 'TASK.GET.SUBTASKS.ADD' })}</h5>
              <div className='d-flex ms-2'>
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={() => setShowForm(false)}
                >
                  <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
                </div>
              </div>
            </div>

            <div className='d-block py-5 ps-8 pe-5 '>
            
              <div className='pt-5'>
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.NAME' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('Name')}
                        />
                        {formik.touched.Name && formik.errors.Name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Name}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.TASKSTATEID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={TaskState}
                          required
                          {...formik.getFieldProps('TaskStateId')}
                          onChange={item => formik.setFieldValue('TaskStateId', item.value)}
                          textType={'intl'}
                          menuPortalTarget={null}
                        />
                        {formik.touched.TaskStateId && formik.errors.TaskStateId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.TaskStateId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.STARTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container mb-6'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            {...formik.getFieldProps('StartDate')}
                            onChange={([date]) => formik.setFieldValue('StartDate', date)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.ENDDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            {...formik.getFieldProps('EndDate')}
                            onChange={([date]) => formik.setFieldValue('EndDate', date)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.PLOTID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container mb-6'>
                        <Select
                          data={plotLookup}
                          {...formik.getFieldProps('PlotId')}
                          onChange={item => formik.setFieldValue('PlotId', Number(item?.value))}
                          menuPortalTarget={null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.STAFFID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container mb-6'>
                        <Select
                          data={staffLookup}
                          {...formik.getFieldProps('StaffId')}
                          onChange={item => formik.setFieldValue('StaffId', Number(item?.value))}
                          menuPortalTarget={null}
                        />
                      </div>
                    </div>
                  </div>                  
                </div>

                <div className='row mb-6'>

                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.TOTALWORK' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='number'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('TotalWork')}
                          onFocus={event => event.target.select()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.TOTALPERSONDAY' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='number'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('TotalPersonDay')}
                          onFocus={event => event.target.select()}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.DURATION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container mb-6'>
                        <input
                          type='number'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('Duration')}
                          onFocus={event => event.target.select()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='row'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.COMPLETIONRATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='number'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('CompletionRate')}
                          onFocus={event => event.target.select()}
                        />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
              <div className='d-flex align-items-center me-3'>
                <button className="btn btn-light me-3" onClick={() => setShowForm(false)}>{intl.formatMessage({id: 'BUTTON.CLOSE' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
