import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { reportingActions } from '../../../redux/actions';

import { pagination } from '../../../helpers';

function List() {
  const intl = useIntl()
  const { dynamicreport } = useSelector(state => state.reporting);
  const dispatch = useDispatch();

  const [params, setParams] = useState(pagination)
  const buttons = []

  useEffect(() => {
    dispatch(reportingActions.searchDynamicReportRequestGetAll(params));
  }, [params])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'DYNAMICREPORT.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props} to={`${props.data[props.row.index].route}`} double="name" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'DYNAMICREPORT.SEARCH.DESCRIPTION' })} className='min-w-250px' />,
      accessor: 'description',
      Cell: ({ ...props }) => <CustomCell item={props} to={`${props.data[props.row.index].route}`} double="description" />,
    }];

  return (
   <>
      <PageTitle>{intl.formatMessage({ id: 'DYNAMICREPORT.HEADER' })}</PageTitle>
      <KTCard>
        <ListHeader search={(item) => setParams(prevState => ({ ...prevState, name: item }))} buttons={buttons} />
        <KTCardBody>
          {!dynamicreport && <ListLoading />}
          {dynamicreport && <Table
            items={dynamicreport}
            cols={Columns}
            handlePagination={(pagination) => setParams({ name: null, ...pagination })}
            paddless
          />}
        </KTCardBody>
      </KTCard>
    </>
  )
}
export { List };
