import { gibinvoiceConstants } from '../constants';
import {
  //#region Invoice
  SearchInvoiceClient,
  GetInvoiceClient,
  SendInvoiceClient,
  GetDocumentPdfClient,
  GetInvoicePdfClient
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region gibinvoice
const searchInvoiceClient = new SearchInvoiceClient(SERVICE_URL);
const getInvoiceClient = new GetInvoiceClient(SERVICE_URL);
const sendInvoiceClient = new SendInvoiceClient(SERVICE_URL);
const getDocumentPdfClient = new GetDocumentPdfClient(SERVICE_URL);
const getInvoicePdfClient = new GetInvoicePdfClient(SERVICE_URL);
//#endregion

export const gibinvoiceActions = {
  //#region Invoice
  gibinvoiceGetAll,
  gibinvoiceGetId,
  gibinvoiceSend,
  gibinvoceGetDocumentPdf,
  gibinvoceGetInvoicePdf
  //#endregion    
};

//#region Invoice
function gibinvoiceGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchInvoiceClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        gibinvoice => dispatch(success(gibinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: gibinvoiceConstants.GIBINVOICE_GETALL_REQUEST } }
  function success(gibinvoice) { return { type: gibinvoiceConstants.GIBINVOICE_GETALL_SUCCESS, gibinvoice } }
  function failure(error) { return { type: gibinvoiceConstants.GIBINVOICE_GETALL_FAILURE, error } }
}

function gibinvoiceGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getInvoiceClient.get(id)
      .then(
        gibinvoice => dispatch(success(gibinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: gibinvoiceConstants.GIBINVOICE_GETID_REQUEST, id } }
  function success(gibinvoice) { return { type: gibinvoiceConstants.GIBINVOICE_GETID_SUCCESS, gibinvoice } }
  function failure(error) { return { type: gibinvoiceConstants.GIBINVOICE_GETID_FAILURE, error } }
}

function gibinvoiceSend(id) {
  return dispatch => {
    dispatch(request(id));
    sendInvoiceClient.send(id)
      .then(
        gibinvoice => dispatch(success(gibinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: gibinvoiceConstants.GIBINVOICE_SEND_REQUEST, id } }
  function success(gibinvoice) { return { type: gibinvoiceConstants.GIBINVOICE_SEND_SUCCESS, gibinvoice } }
  function failure(error) { return { type: gibinvoiceConstants.GIBINVOICE_SEND_FAILURE, error } }
}

function gibinvoceGetDocumentPdf(params) {
  return dispatch => {
    dispatch(request(params));

    getDocumentPdfClient.getDocumentPdf(params.uuid, params.documentType)
      .then(
        documentdata => dispatch(success(documentdata)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: gibinvoiceConstants.GIBINVOICE_DOCUMENTPDF_REQUEST, id } }
  function success(documentdata) { return { type: gibinvoiceConstants.GIBINVOICE_DOCUMENTPDF_SUCCESS, documentdata } }
  function failure(error) { return { type: gibinvoiceConstants.GIBINVOICE_DOCUMENTPDF_FAILURE, error } }
}

function gibinvoceGetInvoicePdf(id) {
  return dispatch => {
    dispatch(request(id));

    getInvoicePdfClient.getPdf(id)
      .then(
        pdfdata => dispatch(success(pdfdata)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: gibinvoiceConstants.GIBINVOICE_INVOICEPDF_REQUEST, id } }
  function success(pdfdata) { return { type: gibinvoiceConstants.GIBINVOICE_INVOICEPDF_SUCCESS, pdfdata } }
  function failure(error) { return { type: gibinvoiceConstants.GIBINVOICE_INVOICEPDF_FAILURE, error } }
}
//#endregion
