import { productConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  isDefault: false,
  updateWareHouses: false,
  excel: null,
}

export function product(state = {}, action) {
  switch (action.type) {
    //#region Product
    case productConstants.PRODUCT_GETALL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_GETALL_SUCCESS:
      return {
        ...state,
        product: action.product,
        loading: false
      };
    case productConstants.PRODUCT_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_GETID_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_GETID_SUCCESS:
      return {
        ...state,
        detail: action.product,
        loading: false
      };
    case productConstants.PRODUCT_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.PRODUCT_GET_INFO_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_GET_INFO_SUCCESS:
      return {
        ...state,
        detail: action.productInfo,
        loading: false
      };
    case productConstants.PRODUCT_GET_INFO_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_DELETE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case productConstants.PRODUCT_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case productConstants.PRODUCT_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_CREATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.product,
        loading: false
      };
    case productConstants.PRODUCT_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.PRODUCT_CREATEFAST_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_CREATEFAST_SUCCESS:
      return {
        ...state,
        isRedirect: action.product,
        loading: false
      };
    case productConstants.PRODUCT_CREATEFAST_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.product || action.params,
        detail: action.params,
        loading: false
      };
    case productConstants.PRODUCT_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_UPDATEWAREHOUSES_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_UPDATEWAREHOUSES_SUCCESS:
      return {
        ...state,
        updateWareHouses: true,
        loading: false
      };
    case productConstants.PRODUCT_UPDATEWAREHOUSES_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_GETSTOCK_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_GETSTOCK_SUCCESS:
      return {
        ...state,
        productStock: action.productStock,
        loading: false
      };
    case productConstants.PRODUCT_GETSTOCK_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_SEARCHSTOCKS_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_SEARCHSTOCKS_SUCCESS:
      return {
        ...state,
        searchStock: action.searchStock,
        loading: false
      };
    case productConstants.PRODUCT_SEARCHSTOCKS_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_SEARCHSALEINVOICES_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_SEARCHSALEINVOICES_SUCCESS:
      return {
        ...state,
        productSaleInvoice: action.productSaleInvoice,
        loading: false
      };
    case productConstants.PRODUCT_SEARCHSALEINVOICES_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_SEARCHEXPENSEINVOICES_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_SEARCHEXPENSEINVOICES_SUCCESS:
      return {
        ...state,
        productExpenseInvoice: action.productExpenseInvoice,
        loading: false
      };
    case productConstants.PRODUCT_SEARCHEXPENSEINVOICES_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.PRODUCT_SEARCHWAREHOUSES_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_SEARCHWAREHOUSES_SUCCESS:
      return {
        ...state,
        searchWarehouse: action.searchWarehouse,
        loading: false
      };
    case productConstants.PRODUCT_SEARCHWAREHOUSES_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.PRODUCT_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.PRODUCT_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case productConstants.PRODUCT_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    //#endregion

    //#region Warehouse
    case productConstants.WAREHOUSE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_GETALL_SUCCESS:
      return {
        ...state,
        warehouse: action.warehouse,
        loading: false
      };
    case productConstants.WAREHOUSE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.WAREHOUSE_GETID_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.warehouse,
        loading: false
      };
    case productConstants.WAREHOUSE_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
  
    case productConstants.WAREHOUSE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case productConstants.WAREHOUSE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.WAREHOUSE_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case productConstants.WAREHOUSE_ARCHIVING_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.WAREHOUSE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_CREATE_SUCCESS:
      return {
        ...state,                
        isRedirect: action.warehouse,
        loading: false
      };
    case productConstants.WAREHOUSE_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
  
    case productConstants.WAREHOUSE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.warehouse || action.params,
        detail: action.params,
        loading: false
      };
    case productConstants.WAREHOUSE_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.WAREHOUSE_SETDEFAULT_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_SETDEFAULT_SUCCESS:
      return {
        ...state,
        isDefault: true,
        loading: false
      };
    case productConstants.WAREHOUSE_SETDEFAULT_FAILURE:
      AuthCheck(action)
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.WAREHOUSE_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSE_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case productConstants.WAREHOUSE_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region OutgoingWaybill
    case productConstants.OUTGOINGWAYBILL_GETALL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.OUTGOINGWAYBILL_GETALL_SUCCESS:
      return {
        ...state,
        outgoingWaybill: action.outgoingWaybill,
        loading: false
      };
    case productConstants.OUTGOINGWAYBILL_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.OUTGOINGWAYBILL_GETID_REQUEST:
      return { ...state, ...initialState };
    case productConstants.OUTGOINGWAYBILL_GETID_SUCCESS:
      return {
        ...state,
        detail: action.outgoingWaybill,
        loading: false
      };
    case productConstants.OUTGOINGWAYBILL_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.OUTGOINGWAYBILL_DELETE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.OUTGOINGWAYBILL_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case productConstants.OUTGOINGWAYBILL_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.OUTGOINGWAYBILL_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case productConstants.OUTGOINGWAYBILL_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case productConstants.OUTGOINGWAYBILL_ARCHIVING_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.OUTGOINGWAYBILL_CREATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.OUTGOINGWAYBILL_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.outgoingWaybill,
        loading: false
      };
    case productConstants.OUTGOINGWAYBILL_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.OUTGOINGWAYBILL_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.OUTGOINGWAYBILL_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.outgoingWaybill || action.params,
        detail: action.params,
        loading: false
      };
    case productConstants.OUTGOINGWAYBILL_UPDATE_FAILURE:
      AuthCheck(action)
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.OUTGOINGWAYBILL_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.OUTGOINGWAYBILL_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case productConstants.OUTGOINGWAYBILL_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region IncomingWaybill
    case productConstants.INCOMINGWAYBILL_GETALL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.INCOMINGWAYBILL_GETALL_SUCCESS:
      return {
        ...state,
        incomingWaybill: action.incomingWaybill,
        loading: false
      };
    case productConstants.INCOMINGWAYBILL_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.INCOMINGWAYBILL_GETID_REQUEST:
      return { ...state, ...initialState };
    case productConstants.INCOMINGWAYBILL_GETID_SUCCESS:
      return {
        ...state,
        detail: action.incomingWaybill,
        loading: false
      };
    case productConstants.INCOMINGWAYBILL_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.INCOMINGWAYBILL_DELETE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.INCOMINGWAYBILL_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case productConstants.INCOMINGWAYBILL_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.INCOMINGWAYBILL_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case productConstants.INCOMINGWAYBILL_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case productConstants.INCOMINGWAYBILL_ARCHIVING_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.INCOMINGWAYBILL_CREATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.INCOMINGWAYBILL_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.incomingWaybill,
        loading: false
      };
    case productConstants.INCOMINGWAYBILL_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.INCOMINGWAYBILL_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.INCOMINGWAYBILL_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.incomingWaybill || action.params,
        detail: action.params,
        loading: false
      };
    case productConstants.INCOMINGWAYBILL_UPDATE_FAILURE:
      AuthCheck(action)
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.INCOMINGWAYBILL_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.INCOMINGWAYBILL_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case productConstants.INCOMINGWAYBILL_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region WarehouseTransfer
    case productConstants.WAREHOUSETRANSFER_GETALL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSETRANSFER_GETALL_SUCCESS:
      return {
        ...state,
        warehouseTransfer: action.warehouseTransfer,
        loading: false
      };
    case productConstants.WAREHOUSETRANSFER_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.WAREHOUSETRANSFER_GETID_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSETRANSFER_GETID_SUCCESS:
      return {
        ...state,
        detail: action.warehouseTransfer,
        loading: false
      };
    case productConstants.WAREHOUSETRANSFER_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.WAREHOUSETRANSFER_DELETE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSETRANSFER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case productConstants.WAREHOUSETRANSFER_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.WAREHOUSETRANSFER_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSETRANSFER_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case productConstants.WAREHOUSETRANSFER_ARCHIVING_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case productConstants.WAREHOUSETRANSFER_CREATE_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSETRANSFER_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.warehouseTransfer,
        loading: false
      };
    case productConstants.WAREHOUSETRANSFER_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case productConstants.WAREHOUSETRANSFER_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case productConstants.WAREHOUSETRANSFER_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case productConstants.WAREHOUSETRANSFER_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion
    default:
      return state
  }
}
