export const AccountType = [
  {
    id: 1,
    name: 'Banka'
  },
  {
    id: 2,
    name: 'Nakit'
  },
  {
    id: 7,
    name: 'Kredili Hesap'
  }
]
