/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { KTSVG, detailPageUrl, DetailPage } from '../../../helpers'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, KTCardHeader, Select } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions, lookupActions } from '../../../redux/actions';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function Detail() {
  const [isCollapse, setIsCollapse] = useState(true);

  const query = useQuery();
  const location = useLocation()
  const intl = useIntl()
  const ProjectId = query.get("projectId")
  const Month = query.get("month")
  const Year = query.get("year")

  const { detail, payment, loading } = useSelector(state => state.staff);
  const { accountLookup } = useSelector(state => state.lookup);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const validSchema = Yup.object().shape({
    PaymentName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    AccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    PaymentName: '',
    AccountId: '',
    PaymentDate: new Date(),
    UpdateSalaryItems: [{
      Id: '',
      StaffId: ''
    }]
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(staffActions.monthlySalaryUpdatePayment(values));
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(staffActions.monthlySalaryGet({ProjectId, Month, Year}));
    dispatch(lookupActions.bankCashAccountGetAll());
  }, [ProjectId, Month, Year, payment])

  useEffect(() => {
    if (detail && detail?.salaryList) {
      formik.setFieldValue(`UpdateSalaryItems`, [])
  
      detail?.salaryList?.map((item, index) => {
        formik.setFieldValue(`UpdateSalaryItems[${index}]['Id']`, item?.salaryId)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['StaffId']`, item?.staffId)
      })
    }
  }, [detail])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.MONTHLY_SALARY' }),
      path: '/staff/monthly-salary',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.MONTHLY_SALARY.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="fs-2 fw-bold me-1">
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='d-flex align-items-center text-dark text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>
                          </div>

                          <div className='ms-5'>

                          </div>
                        </div>
                      </div>

                      {detail?.isUpdateActive && <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit?projectId=${ProjectId}&month=${Month}&year=${Year}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                      </div>}
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.MONTHNAME' })}</div>
                          <div className="fw-bold fs-4">{detail?.monthName}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.YEAR' })}</div>
                          <div className="fw-bold fs-4">{detail?.year}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.PAYMENT' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.payment, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.BALANCE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.balance, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400"> {intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.BESPAYMENT' })}</div>
                          <div className="fw-bold fs-4">   {intl.formatNumber(detail?.besPayment, { style: 'currency', currency: 'try' })}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>
            
            {detail?.salaryList?.length > 0 && <>
              <KTCard>
                <KTCardBody className='p-9'>
                  <Tabs
                    className="nav-line-tabs"
                    fill
                  >
                    <Tab eventKey="salaryList" title={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.SALARYLIST' })}>
                      <div className='row p-5'>
                        <div className='col-lg-12 order-2 order-lg-1'>
                          <div className='table-responsive'>
                            <table className='table align-middle table-row-dashed gy-5 fs-6 table-striped table-hover'>
                              <thead>
                                <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                  <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.SALARYLIST.NAMESURNAME' })}</th>
                                  <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.SALARYLIST.NAME' })}</th>
                                  <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.SALARYLIST.PROGRESSPAYMENTDATE' })}</th>
                                  <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.SALARYLIST.PAYMENT' })}</th>
                                  <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.SALARYLIST.BESPAYMENT' })}</th>
                                  <th className='text-end'>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.GET.SALARYLIST.BALANCE' })}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {detail?.salaryList?.map((item, index) => <tr key={index} role="button">
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6 text-uppercase'>
                                      <Link to={detailPageUrl(DetailPage.Staff, item?.staffId)} className='text-dark text-hover-primary' target='_blank'>
                                        {item?.nameSurname}
                                      </Link>
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6 text-uppercase'>
                                      {item?.name}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6 text-uppercase'>
                                      {item?.progressPaymentDate ? intl.formatDate(new Date(item?.progressPaymentDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {intl.formatNumber(item.payment, { style: 'currency', currency: 'try' })}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {intl.formatNumber(item.besPayment, { style: 'currency', currency: 'try' })}
                                    </div>
                                  </td>
                                  <td className='text-end p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {intl.formatNumber(item.balance, { style: 'currency', currency: 'try' })}
                                    </div>
                                  </td>
                                </tr>)}
                              </tbody>                          
                            </table>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </KTCardBody>
              </KTCard>
            </> }
          </>}
        </div>

        {detail && <div className='col-xl-3'>
          {detail?.isPayrollActive && <KTCard className='mb-5'>
            <KTCardHeader title={intl.formatMessage({ id: 'PAYMENT.ADD' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody
              className='pt-5'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENT.DATE' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        {...formik.getFieldProps('PaymentDate')}
                        onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                      />
                    </div>
                    {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.PaymentDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'ACCOUNT' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select
                      data={accountLookup}
                      required
                      {...formik.getFieldProps('AccountId')}
                      onChange={item => formik.setFieldValue('AccountId', Number(item?.value))}
                    />
                    {formik.touched.AccountId && formik.errors.AccountId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.AccountId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPLANATION' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <textarea
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={4}
                      {...formik.getFieldProps('PaymentName')}
                    />
                    {formik.touched.PaymentName && formik.errors.PaymentName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.PaymentName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>}
        </div>}
      </div>


      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
