export const reportingConstants = {
  //#region DynamicReport
  DYNAMICREPORT_SEARCH_REQUEST: 'DYNAMICREPORT_SEARCH_REQUEST',
  DYNAMICREPORT_SEARCH_SUCCESS: 'DYNAMICREPORT_SEARCH_SUCCESS',
  DYNAMICREPORT_SEARCH_FAILURE: 'DYNAMICREPORT_SEARCH_FAILURE',
  //#endregion          

  //#region SaleInvoiceReport
  SALEINVOICE_CATEGORY_REQUEST: 'SALEINVOICE_CATEGORY_REQUEST',
  SALEINVOICE_CATEGORY_SUCCESS: 'SALEINVOICE_CATEGORY_SUCCESS',
  SALEINVOICE_CATEGORY_FAILURE: 'SALEINVOICE_CATEGORY_FAILURE',

  SALEINVOICE_PROJECT_REQUEST: 'SALEINVOICE_PROJECT_REQUEST',
  SALEINVOICE_PROJECT_SUCCESS: 'SALEINVOICE_PROJECT_SUCCESS',
  SALEINVOICE_PROJECT_FAILURE: 'SALEINVOICE_PROJECT_FAILURE',

  SALEINVOICE_DATE_REQUEST: 'SALEINVOICE_DATE_REQUEST',
  SALEINVOICE_DATE_SUCCESS: 'SALEINVOICE_DATE_SUCCESS',
  SALEINVOICE_DATE_FAILURE: 'SALEINVOICE_DATE_FAILURE',

  SALEINVOICE_MONTH_REQUEST: 'SALEINVOICE_MONTH_REQUEST',
  SALEINVOICE_MONTH_SUCCESS: 'SALEINVOICE_MONTH_SUCCESS',
  SALEINVOICE_MONTH_FAILURE: 'SALEINVOICE_MONTH_FAILURE',

  SALEINVOICE_STATUS_REQUEST: 'SALEINVOICE_STATUS_REQUEST',
  SALEINVOICE_STATUS_SUCCESS: 'SALEINVOICE_STATUS_SUCCESS',
  SALEINVOICE_STATUS_FAILURE: 'SALEINVOICE_STATUS_FAILURE',
  //#endregion

  //#region ExpenseInvoiceReport
  EXPENSEINVOICE_CATEGORY_REQUEST: 'EXPENSEINVOICE_CATEGORY_REQUEST',
  EXPENSEINVOICE_CATEGORY_SUCCESS: 'EXPENSEINVOICE_CATEGORY_SUCCESS',
  EXPENSEINVOICE_CATEGORY_FAILURE: 'EXPENSEINVOICE_CATEGORY_FAILURE',

  EXPENSEINVOICE_PROJECT_REQUEST: 'EXPENSEINVOICE_PROJECT_REQUEST',
  EXPENSEINVOICE_PROJECT_SUCCESS: 'EXPENSEINVOICE_PROJECT_SUCCESS',
  EXPENSEINVOICE_PROJECT_FAILURE: 'EXPENSEINVOICE_PROJECT_FAILURE',

  EXPENSEINVOICE_DATE_REQUEST: 'EXPENSEINVOICE_DATE_REQUEST',
  EXPENSEINVOICE_DATE_SUCCESS: 'EXPENSEINVOICE_DATE_SUCCESS',
  EXPENSEINVOICE_DATE_FAILURE: 'EXPENSEINVOICE_DATE_FAILURE',

  EXPENSEINVOICE_MONTH_REQUEST: 'EXPENSEINVOICE_MONTH_REQUEST',
  EXPENSEINVOICE_MONTH_SUCCESS: 'EXPENSEINVOICE_MONTH_SUCCESS',
  EXPENSEINVOICE_MONTH_FAILURE: 'EXPENSEINVOICE_MONTH_FAILURE',

  EXPENSEINVOICE_STATUS_REQUEST: 'EXPENSEINVOICE_STATUS_REQUEST',
  EXPENSEINVOICE_STATUS_SUCCESS: 'EXPENSEINVOICE_STATUS_SUCCESS',
  EXPENSEINVOICE_STATUS_FAILURE: 'EXPENSEINVOICE_STATUS_FAILURE',
  //#endregion

  //#region WorkProgramReport
  WORKPROGRAM_PROJECT_COMPLETE_REQUEST: 'WORKPROGRAM_PROJECT_COMPLETE_REQUEST',
  WORKPROGRAM_PROJECT_COMPLETE_SUCCESS: 'WORKPROGRAM_PROJECT_COMPLETE_SUCCESS',
  WORKPROGRAM_PROJECT_COMPLETE_FAILURE: 'WORKPROGRAM_PROJECT_COMPLETE_FAILURE',
  
  WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_REQUEST: 'WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_REQUEST',
  WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_SUCCESS: 'WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_SUCCESS',
  WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_FAILURE: 'WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_FAILURE',

  WORKPROGRAM_BLOCK_COMPLETE_REQUEST: 'WORKPROGRAM_BLOCK_COMPLETE_REQUEST',
  WORKPROGRAM_BLOCK_COMPLETE_SUCCESS: 'WORKPROGRAM_BLOCK_COMPLETE_SUCCESS',
  WORKPROGRAM_BLOCK_COMPLETE_FAILURE: 'WORKPROGRAM_BLOCK_COMPLETE_FAILURE',

  WORKPROGRAM_ROOTTASK_COMPLETE_REQUEST: 'WORKPROGRAM_ROOTTASK_COMPLETE_REQUEST',
  WORKPROGRAM_ROOTTASK_COMPLETE_SUCCESS: 'WORKPROGRAM_ROOTTASK_COMPLETE_SUCCESS',
  WORKPROGRAM_ROOTTASK_COMPLETE_FAILURE: 'WORKPROGRAM_ROOTTASK_COMPLETE_FAILURE',

  WORKPROGRAM_DAILY_COMPLETE_REQUEST: 'WORKPROGRAM_DAILY_COMPLETE_REQUEST',
  WORKPROGRAM_DAILY_COMPLETE_SUCCESS: 'WORKPROGRAM_DAILY_COMPLETE_SUCCESS',
  WORKPROGRAM_DAILY_COMPLETE_FAILURE: 'WORKPROGRAM_DAILY_COMPLETE_FAILURE',
  //#endregion

  //#region Audit
  AUDIT_SEARCH_REQUEST: 'AUDIT_SEARCH_REQUEST',
  AUDIT_SEARCH_SUCCESS: 'AUDIT_SEARCH_SUCCESS',
  AUDIT_SEARCH_FAILURE: 'AUDIT_SEARCH_FAILURE',

  AUDIT_GETID_REQUEST: 'AUDIT_GETID_REQUEST',
  AUDIT_GETID_SUCCESS: 'AUDIT_GETID_SUCCESS',
  AUDIT_GETID_FAILURE: 'AUDIT_GETID_FAILURE',

  AUDIT_GETLIST_REQUEST: 'AUDIT_GETLIST_REQUEST',
  AUDIT_GETLIST_SUCCESS: 'AUDIT_GETLIST_SUCCESS',
  AUDIT_GETLIST_FAILURE: 'AUDIT_GETLIST_FAILURE',
  //#endregion   

  //#region CashFlow
  CASHFLOW_SEARCH_REQUEST: 'CASHFLOW_SEARCH_REQUEST',
  CASHFLOW_SEARCH_SUCCESS: 'CASHFLOW_SEARCH_SUCCESS',
  CASHFLOW_SEARCH_FAILURE: 'CASHFLOW_SEARCH_FAILURE',
  //#endregion   
};
