import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const {leave, loading} = useSelector(state => state.staff);
  const { staffLookup } = useSelector(state => state.lookup);
	const dispatch = useDispatch();

  const [ params, setParams ] = useState()

  useEffect(() => {
    if (params) dispatch(staffActions.leaveGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.staffGetAll());
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.SEARCH.NAMESURNAME' })} className='min-w-125px' />,
      accessor: 'staffNameSurname',
      Cell: ({...props}) => <CustomCellDouble item={props} double="name" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.SEARCH.LEAVE.DATE.START' })} className='min-w-125px' />,
      accessor: 'startDate',
      Cell: ({...props}) => <CustomCell item={props} date="short" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.SEARCH.LEAVE.DAYCOUNT' })} className='min-w-125px' />,
      accessor: 'dayCount',
      Cell: ({...props}) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    },
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(staffActions.leaveExcel(params, intl.formatMessage({ id: 'BC.LEAVE' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'STAFF.SEARCH.NAMESURNAME',
          data: staffLookup,
          name: 'staffId'
        }
      ]
    }
  ]

  return (
    <>      
      <PageTitle>{intl.formatMessage({ id: 'BC.LEAVE' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'LEAVE'}
        />
        <KTCardBody>
          {!leave && <ListLoading /> }
          {leave && <Table 
                      items={leave} 
                      cols={Columns} 
                      handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))} 
                      paddless 
                    /> }
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {List};
