import { paymentplanConstants } from '../constants';
import {AuthCheck, DownloadFileFn} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  isRedirectPage: false, 
  error: null,
  isDeleted: false,
  excel: null
}

export function paymentplan(state = {}, action) {
  switch (action.type) {
    case paymentplanConstants.PAYMENTPLAN_DELETE_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_CREATE_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_CREATE_SUCCESS:
      return {
        ...state,
        paymentPlan: action.paymentPlan,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_UPDATE_SUCCESS:
      return {
        ...state,
        paymentPlan: action.paymentPlan || action.params,
        isRedirectPage: true,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_UNSCHEDULED_GETALL_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_UNSCHEDULED_GETALL_SUCCESS:
      return {
        ...state,
        unscheduled: action.unscheduled,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_UNSCHEDULED_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_GETALL_SUCCESS:
      return {
        ...state,
        expenseinvoice: action.expenseinvoice,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_EXCEL_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
      
      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    }

    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
      
    case paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_EXCEL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_GETALL_SUCCESS:
      return {
        ...state,
        saleinvoice: action.saleinvoice,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_EXCEL_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
      
      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    }
    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_EXCEL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.saleinvoice,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_SALEINVOICE_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentplanConstants.PAYMENTPLAN_EXPENSESALEINVOICE_GETID_REQUEST:
      return { ...state, ...initialState };
    case paymentplanConstants.PAYMENTPLAN_EXPENSESALEINVOICE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.expensesaleinvoice,
        loading: false
      };
    case paymentplanConstants.PAYMENTPLAN_EXPENSESALEINVOICE_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
      
    
    default:
      return state
  }
}
