import { projectConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  excel: null
}

export function project(state = {}, action) {
  switch (action.type) {
    //#region Project
    case projectConstants.PROJECT_GETALL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PROJECT_GETALL_SUCCESS:
      return {
        ...state,
        project: action.project,
        loading: false
      };
    case projectConstants.PROJECT_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PROJECT_GETID_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PROJECT_GETID_SUCCESS:
      return {
        ...state,
        detail: action.project,
        loading: false
      };
    case projectConstants.PROJECT_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PROJECT_DELETE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case projectConstants.PROJECT_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PROJECT_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PROJECT_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case projectConstants.PROJECT_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PROJECT_CREATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PROJECT_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.project,
        loading: false
      };
    case projectConstants.PROJECT_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PROJECT_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.project || action.params,
        detail: action.params,
        loading: false
      };
    case projectConstants.PROJECT_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case projectConstants.PROJECT_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PROJECT_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case projectConstants.PROJECT_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region RealEstate
    case projectConstants.REALESTATE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.REALESTATE_GETALL_SUCCESS:
      return {
        ...state,
        realEstate: action.realEstate,
        loading: false
      };
    case projectConstants.REALESTATE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.REALESTATE_GETID_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.REALESTATE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.realEstate,
        loading: false
      };
    case projectConstants.REALESTATE_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.REALESTATE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.REALESTATE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case projectConstants.REALESTATE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.REALESTATE_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.REALESTATE_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case projectConstants.REALESTATE_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.REALESTATE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.REALESTATE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.realEstate,
        loading: false
      };
    case projectConstants.REALESTATE_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.REALESTATE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.REALESTATE_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.realEstate || action.params,
        detail: action.params,
        loading: false
      };
    case projectConstants.REALESTATE_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case projectConstants.REALESTATE_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.REALESTATE_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case projectConstants.REALESTATE_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Contracts
    case projectConstants.CONTRACTS_GETALL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONTRACTS_GETALL_SUCCESS:
      return {
        ...state,
        contracts: action.contracts,
        loading: false
      };
    case projectConstants.CONTRACTS_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONTRACTS_GETID_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONTRACTS_GETID_SUCCESS:
      return {
        ...state,
        detail: action.contracts,
        loading: false
      };
    case projectConstants.CONTRACTS_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONTRACTS_DELETE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONTRACTS_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case projectConstants.CONTRACTS_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONTRACTS_CREATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONTRACTS_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.contracts,
        loading: false
      };
    case projectConstants.CONTRACTS_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONTRACTS_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONTRACTS_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.contracts || action.params,
        detail: action.params,
        loading: false
      };
    case projectConstants.CONTRACTS_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
      case projectConstants.CONTRACTS_EXCEL_REQUEST:
        return { ...state, ...initialState };
      case projectConstants.CONTRACTS_EXCEL_SUCCESS:
        DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
  
        return {
          ...state,
          excel: action.excel,
          loading: false
        };
      case projectConstants.CONTRACTS_EXCEL_FAILURE:
        AuthCheck(action)
  
        return {
          ...state,
          error: action.error,
          loading: false
        };
    //#endregion

    //#region Constructionsite
    case projectConstants.CONSTRUCTIONSITE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONSTRUCTIONSITE_GETALL_SUCCESS:
      return {
        ...state,
        constructionsite: action.constructionsite,
        loading: false
      };
    case projectConstants.CONSTRUCTIONSITE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONSTRUCTIONSITE_GETID_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONSTRUCTIONSITE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.constructionsite,
        loading: false
      };
    case projectConstants.CONSTRUCTIONSITE_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONSTRUCTIONSITE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONSTRUCTIONSITE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case projectConstants.CONSTRUCTIONSITE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONSTRUCTIONSITE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONSTRUCTIONSITE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.constructionsite,
        loading: false
      };
    case projectConstants.CONSTRUCTIONSITE_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.CONSTRUCTIONSITE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.CONSTRUCTIONSITE_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.constructionsite || action.params,
        detail: action.params,
        loading: false
      };
    case projectConstants.CONSTRUCTIONSITE_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
      case projectConstants.CONSTRUCTIONSITE_EXCEL_REQUEST:
        return { ...state, ...initialState };
      case projectConstants.CONSTRUCTIONSITE_EXCEL_SUCCESS:
        DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
  
        return {
          ...state,
          excel: action.excel,
          loading: false
        };
      case projectConstants.CONSTRUCTIONSITE_EXCEL_FAILURE:
        AuthCheck(action)
  
        return {
          ...state,
          error: action.error,
          loading: false
        };
    //#endregion

    //#region Block
    case projectConstants.BLOCK_GETALL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BLOCK_GETALL_SUCCESS:
      return {
        ...state,
        block: action.block,
        loading: false
      };
    case projectConstants.BLOCK_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BLOCK_GETID_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BLOCK_GETID_SUCCESS:
      return {
        ...state,
        detail: action.block,
        loading: false
      };
    case projectConstants.BLOCK_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BLOCK_DELETE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BLOCK_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case projectConstants.BLOCK_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BLOCK_CREATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BLOCK_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.block,
        loading: false
      };
    case projectConstants.BLOCK_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BLOCK_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BLOCK_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.block || action.params,
        detail: action.params,
        loading: false
      };
    case projectConstants.BLOCK_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
      case projectConstants.BLOCK_EXCEL_REQUEST:
        return { ...state, ...initialState };
      case projectConstants.BLOCK_EXCEL_SUCCESS:
        DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
  
        return {
          ...state,
          excel: action.excel,
          loading: false
        };
      case projectConstants.BLOCK_EXCEL_FAILURE:
        AuthCheck(action)
  
        return {
          ...state,
          error: action.error,
          loading: false
        };
    //#endregion

    //#region Buildingblocktype
    case projectConstants.BUILDINGBLOCKTYPE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BUILDINGBLOCKTYPE_GETALL_SUCCESS:
      return {
        ...state,
        buildingblocktype: action.buildingblocktype,
        loading: false
      };
    case projectConstants.BUILDINGBLOCKTYPE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BUILDINGBLOCKTYPE_GETID_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BUILDINGBLOCKTYPE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.buildingblocktype,
        loading: false
      };
    case projectConstants.BUILDINGBLOCKTYPE_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BUILDINGBLOCKTYPE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BUILDINGBLOCKTYPE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case projectConstants.BUILDINGBLOCKTYPE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BUILDINGBLOCKTYPE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BUILDINGBLOCKTYPE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.buildingblocktype,
        loading: false
      };
    case projectConstants.BUILDINGBLOCKTYPE_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.BUILDINGBLOCKTYPE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.BUILDINGBLOCKTYPE_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.buildingblocktype || action.params,
        detail: action.params,
        loading: false
      };
    case projectConstants.BUILDINGBLOCKTYPE_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
      case projectConstants.BUILDINGBLOCKTYPE_EXCEL_REQUEST:
        return { ...state, ...initialState };
      case projectConstants.BUILDINGBLOCKTYPE_EXCEL_SUCCESS:
        DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
  
        return {
          ...state,
          excel: action.excel,
          loading: false
        };
      case projectConstants.BUILDINGBLOCKTYPE_EXCEL_FAILURE:
        AuthCheck(action)
  
        return {
          ...state,
          error: action.error,
          loading: false
        };
    //#endregion

    //#region Plot
    case projectConstants.PLOT_GETALL_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PLOT_GETALL_SUCCESS:
      return {
        ...state,
        plot: action.plot,
        loading: false
      };
    case projectConstants.PLOT_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PLOT_GETID_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PLOT_GETID_SUCCESS:
      return {
        ...state,
        detail: action.plot,
        loading: false
      };
    case projectConstants.PLOT_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PLOT_DELETE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PLOT_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case projectConstants.PLOT_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PLOT_CREATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PLOT_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.plot,
        loading: false
      };
    case projectConstants.PLOT_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case projectConstants.PLOT_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case projectConstants.PLOT_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.plot || action.params,
        detail: action.params,
        loading: false
      };
    case projectConstants.PLOT_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
      case projectConstants.PLOT_EXCEL_REQUEST:
        return { ...state, ...initialState };
      case projectConstants.PLOT_EXCEL_SUCCESS:
        DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
  
        return {
          ...state,
          excel: action.excel,
          loading: false
        };
      case projectConstants.PLOT_EXCEL_FAILURE:
        AuthCheck(action)
  
        return {
          ...state,
          error: action.error,
          loading: false
        };
    //#endregion
    default:
      return state
  }
}
