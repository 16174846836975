/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers';
import { Modal } from 'react-bootstrap'
import ReactJsonViewCompare from 'react-json-view-compare';

const AuditModal = ({onOpen, onClose, onClick, error, oldData, newData}) => {
  const [show, setShow] = useState(onOpen);
  const intl = useIntl()

  useEffect(() => {
    setShow(onOpen)
  }, [onOpen])

  const handleClose = () => {
    setShow(false)
    onClose && onClose()
  }

  const handleClick = () => onClick && onClick()

  useEffect(() => {
    if (error && show) {
      setShow(false)
    }
  }, [error])

  const convertToJSON = (data) => {
    if (typeof data === 'string') {
      try {
        const jsonData = JSON.parse(data);
        return jsonData;
      } catch (error) {
        return data;
      }
    } else {
      try {
        const jsonString = JSON.stringify(data);
        const jsonData = JSON.parse(jsonString);
        return jsonData;
      } catch (error) {
        return data;
      }
    }
}
  
  return (
    <>
      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
        size='xl'
      >
        <div className='modal-content'>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'REPORTING.AUDIT.TYPE' })}</h5>
            <div className='d-flex ms-2'>
              <div
                className='btn btn-icon btn-sm btn-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>
          </div>

          <div className='d-block py-5 ps-8 pe-5 '>
            <ReactJsonViewCompare oldData={convertToJSON(oldData)} newData={convertToJSON(newData)} />
          </div>

          <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
            <div className='d-flex align-items-center me-3'>
              <button className="btn btn-light me-3" onClick={handleClose}>{intl.formatMessage({id: 'BUTTON.CLOSE' })}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

AuditModal.propTypes = {
	onOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

AuditModal.defaultProps = {
	onOpen: false,
};

export {AuditModal}
