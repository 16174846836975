import React from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../helpers'

const AsideMenuItem = ({
  children,
  to,
  title,
  icon,
  hasBullet = false,
  className
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link', className, {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

AsideMenuItem.propTypes = {
  children: PropTypes.any,
	to: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.string,
	className: PropTypes.string,
	hasBullet: PropTypes.bool
};

export {AsideMenuItem}
