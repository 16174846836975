import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { paymentRequestActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { paymentRequest, loading } = useSelector(state => state.paymentRequest);
  const { projectLookup, companyLookup, PaymentRequestType, PaymentRequestState, userProjectLookup } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) dispatch(paymentRequestActions.paymentRequestGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('PaymentRequestType'));
    dispatch(lookupActions.basicLookup('PaymentRequestState'));
    dispatch(lookupActions.userProjectLookup());
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PAYMENTREQUEST.SEARCH.PROJECTNAME' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="name" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PAYMENTREQUEST.SEARCH.COMPANYTITLE' })} className='min-w-125px' />,
      accessor: 'companyTitle',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PAYMENTREQUEST.SEARCH.PAYMENTREQUESTTYPENAME' })} className='min-w-125px' />,
      accessor: 'paymentRequestTypeName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PAYMENTREQUEST.SEARCH.PAYMENTREQUESTSTATENAME' })} className='min-w-125px' />,
      accessor: 'paymentRequestStateName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PAYMENTREQUEST.SEARCH.REQUESTEDPAYMENT' })} className='min-w-125px' />,
      accessor: 'requestedPayment',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PAYMENTREQUEST.SEARCH.APPROVEDPAYMENT' })} className='min-w-125px' />,
      accessor: 'approvedPayment',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
  ];

  const actionButtons = [
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'PAYMENTREQUEST.SEARCH.PROJECTNAME',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'PAYMENTREQUEST.SEARCH.COMPANYTITLE',
          data: companyLookup,
          name: 'companyId'
        },
        {
          title: 'PAYMENTREQUEST.SEARCH.REQUESTEDUSERID',
          data: userProjectLookup,
          name: 'requestedUserId'
        },
        {
          title: 'PAYMENTREQUEST.SEARCH.BEGINDATE',
          type: 'date',
          name: 'beginDate'
        },
        {
          title: 'PAYMENTREQUEST.SEARCH.ENDDATE',
          type: 'date',
          name: 'endDate'
        },
        {
          title: 'PAYMENTREQUEST.SEARCH.PAYMENTREQUESTTYPENAME',
          data: PaymentRequestType,
          name: 'paymentRequestTypeId'
        },
        {
          title: 'PAYMENTREQUEST.SEARCH.PAYMENTREQUESTSTATENAME',
          data: PaymentRequestState,
          name: 'paymentRequestStateId'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.PAYMENTREQUEST' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages='PAYMENTREQUEST'
        />
        <KTCardBody>
          {!paymentRequest && <ListLoading />}
          {paymentRequest && <Table
            items={paymentRequest}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
