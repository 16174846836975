/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik, FormikProvider, FieldArray } from 'formik'

import { CompanyType, KTSVG } from '../../../helpers';
import { KTCard, KTCardBody, Select } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, companyActions, categoryActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { GibInvoiceType, CommercialEnterpriseType, Province, District, projectLookup } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.company);
  const { category } = useSelector((state) => state.category)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.basicLookup('CommercialEnterpriseType'));
    dispatch(lookupActions.basicLookup('Province'));
    dispatch(lookupActions.basicLookup('GibInvoiceType'));
    dispatch(lookupActions.projectLookup());
    dispatch(categoryActions.categoryGetAll({ SourceTypeId: 11 }))
  }, [])

  const onChangeDistrict = (value) => {
    formik.setFieldValue('ProvinceId', value);
    dispatch(lookupActions.districtLookup({ 'provinceId': value }));
  }

  const [commercialEnterpriseType, setCommercialEnterpriseType] = useState()

  const onChangeCommercialType = (value) => {
    formik.setFieldValue('CommercialEnterpriseTypeId', value);
    setCommercialEnterpriseType(value)
  }

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Title: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectIds: Yup.array().of(Yup.string()).min(1, intl.formatMessage({ id: 'REQUIRED.GENERAL' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    CompanyTypeId: CompanyType.Customer,
    Name: '',
    Title: '',
    Email: '',
    Phone: null,
    Fax: '',
    Iban: '',
    Address: '',
    PostalCode: '',
    ProvinceId: null,
    DistrictId: null,
    CommercialEnterpriseTypeId: null,
    IdentityNumber: '',
    TaxOffice: '',
    TaxNumber: '',
    CompanyPeoples: [{
      NameSurname: '',
      Phone: '',
      Email: '',
      Description: ''
    }],
    ProjectIds: [],
    GibInvoiceTypeId: null,
    InvoiceEmail: '',
    CategoryId: null,
  })

  const onClickNewItem = (arrayHelpers) => {
    arrayHelpers.push({
      NameSurname: '',
      Phone: '',
      Email: '',
      Description: ''
    })
  }

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(companyActions.companyCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.CUSTOMERS' }),
      path: '/sales/customers',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.CUSTOMERS.ADD' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <FormikProvider value={formik}>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'COMPANY.CREATE.NAME' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    required
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Name')}
                  />
                  {formik.touched.Name && formik.errors.Name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'COMPANY.CREATE.TITLE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    required
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Title')}
                  />
                  {formik.touched.Title && formik.errors.Title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Title}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'COMPANY.CREATE.PROJECTS' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={projectLookup}
                    required
                    {...formik.getFieldProps('ProjectIds')}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={item => {
                      const newData = []
                      item?.map(data => newData.push(data.value))
                      formik.setFieldValue('ProjectIds', newData)
                    }}
                  />
                  {formik.touched.ProjectIds && formik.errors.ProjectIds && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ProjectIds}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.CATEGORY' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={category}
                    {...formik.getFieldProps('CategoryId')}
                    onChange={item => formik.setFieldValue('CategoryId', Number(item?.value))}
                  />
                </div>
              </div>
              {CommercialEnterpriseType && <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.COMMERCIALENTERPRISETYPE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={CommercialEnterpriseType}
                    {...formik.getFieldProps('CommercialEnterpriseTypeId')}
                    onChange={item => onChangeCommercialType(Number(item.value))}
                    defaultValue={commercialEnterpriseType}
                  />
                </div>
              </div>}
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.IDENTITYNUMBER' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('IdentityNumber')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.TAXOFFICE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('TaxOffice')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.TAXNUMBER' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('TaxNumber')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.IBAN' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Iban')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.EMAIL' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Email')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.PHONE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Phone')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.FAX' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Fax')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.PROVINCE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={Province}
                    {...formik.getFieldProps('ProvinceId')}
                    onChange={item => onChangeDistrict(Number(item.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.DISTRICT' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={District}
                    {...formik.getFieldProps('DistrictId')}
                    onChange={item => formik.setFieldValue('DistrictId', Number(item?.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.POSTALCODE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('PostalCode')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.ADDRESS' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Address')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.GIBINVOICETYPE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={GibInvoiceType}
                    {...formik.getFieldProps('GibInvoiceTypeId')}
                    onChange={item => formik.setFieldValue('GibInvoiceTypeId', Number(item?.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'COMPANY.CREATE.INVOICEEMAIL' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('InvoiceEmail')}
                  />
                </div>
              </div>
              <FieldArray
                name="CompanyPeoples"
                render={(arrayHelpers) => (
                  <>
                    <div className="table-responsive">
                      <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" >
                        <thead>
                          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                            <th>{intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.NAMESURNAME' })}</th>
                            <th>{intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.PHONE' })}</th>
                            <th>{intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.EMAIL' })}</th>
                            <th>{intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.DESCRIPTION' })}</th>
                          </tr>
                        </thead>
                        <tbody>

                          {formik.values.CompanyPeoples.map((item, index) => (
                            <tr className="border-bottom border-bottom-dashed" key={index}>
                              <td className="ps-0">
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  autoComplete='off'
                                  disabled={formik.isSubmitting}
                                  placeholder={intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.NAMESURNAME' })}
                                  name={`CompanyPeoples[${index}].NameSurname`}
                                  value={formik.values.CompanyPeoples[index].NameSurname}
                                  required
                                  onChange={(event) => formik.handleChange(event)}
                                />
                              </td>
                              <td className="ps-0">
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  autoComplete='off'
                                  disabled={formik.isSubmitting}
                                  placeholder={intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.PHONE' })}
                                  name={`CompanyPeoples[${index}].Phone`}
                                  value={formik.values.CompanyPeoples[index].Phone}
                                  required
                                  onChange={(event) => formik.handleChange(event)}
                                />
                              </td>
                              <td className="ps-0">
                                <input
                                  type='email'
                                  className='form-control form-control-solid'
                                  autoComplete='off'
                                  disabled={formik.isSubmitting}
                                  placeholder={intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.EMAIL' })}
                                  name={`CompanyPeoples[${index}].Email`}
                                  value={formik.values.CompanyPeoples[index].Email}
                                  required
                                  onChange={(event) => formik.handleChange(event)}
                                />
                              </td>
                              <td className="ps-0">
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  autoComplete='off'
                                  disabled={formik.isSubmitting}
                                  placeholder={intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.DESCRIPTION' })}
                                  name={`CompanyPeoples[${index}].Description`}
                                  value={formik.values.CompanyPeoples[index].Description}
                                  required
                                  onChange={(event) => formik.handleChange(event)}
                                />
                              </td>

                              <td className="pt-5 text-end">
                                <div className='d-flex'>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-icon btn-active-color-primary"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>

                    <button
                      type="button"
                      className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success"
                      onClick={() => onClickNewItem(arrayHelpers)}
                    >
                      <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                      {intl.formatMessage({ id: 'ADD_NEW_LINE' })}
                    </button>
                  </>
                )}
              />

              <div className='row pt-15'>
                <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                  <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
