import { purchaseConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  isAdded: false,
  excel: null,
}

export function purchase(state = {}, action) {
  switch (action.type) {
    //#region Purchase Request
    case purchaseConstants.PURCHASEREQUEST_GETALL_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_GETALL_SUCCESS:
      return {
        ...state,
        request: action.purchase,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case purchaseConstants.PURCHASEREQUEST_GETID_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_GETID_SUCCESS:
      return {
        ...state,
        detail: action.purchase,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case purchaseConstants.PURCHASEREQUEST_DELETE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case purchaseConstants.PURCHASEREQUEST_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case purchaseConstants.PURCHASEREQUEST_CREATE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.purchase,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case purchaseConstants.PURCHASEREQUEST_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.purchase || action.params,
        detail: action.params,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEREQUEST_UPDATESTATUS_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_UPDATESTATUS_SUCCESS:
      return {
        ...state,
        isRedirect: action.purchase || action.params,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_UPDATESTATUS_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEREQUEST_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEREQUEST_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case purchaseConstants.PURCHASEREQUEST_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Purchase Proposal
    case purchaseConstants.PURCHASEPROPOSAL_DELETE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEPROPOSAL_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case purchaseConstants.PURCHASEPROPOSAL_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEPROPOSAL_CREATE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEPROPOSAL_CREATE_SUCCESS:
      return {
        ...state,
        isAdded: true,
        loading: false
      };
    case purchaseConstants.PURCHASEPROPOSAL_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    //#endregion

    //#region Purchase Order
    case purchaseConstants.PURCHASEORDER_GETALL_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_GETALL_SUCCESS:
      return {
        ...state,
        order: action.purchase,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEORDER_GETID_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_GETID_SUCCESS:
      return {
        ...state,
        detail: action.purchase,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_GETID_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEORDER_DELETE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEORDER_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEORDER_CREATE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.purchase,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEORDER_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.purchase || action.params,
        detail: action.params,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEORDER_UPDATESTATUS_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_UPDATESTATUS_SUCCESS:
      return {
        ...state,
        isRedirect: action.purchase || action.params,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_UPDATESTATUS_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case purchaseConstants.PURCHASEORDER_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case purchaseConstants.PURCHASEORDER_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case purchaseConstants.PURCHASEORDER_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion
    default:
      return state
  }
}
