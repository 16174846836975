import { lookupConstants } from '../constants';
import {
  StaffTenantLookupClient,
  AccountTenantLookupClient,
  ExtendedAccountLookupClient,
  BasicLookupClient,
  DistrictLookupClient,
  AllProductTenantLookupClient,
  StockProductTenantLookupClient,
  WarehouseTenantLookupClient,
  ProjectTenantLookupClient,
  AllProjectTenantLookupClient,
  AllCompanyTenantLookupClient,
  GetUsableCheckTenantLookupClient,
  AllRoleTenantLookupClient,
  AllProductForUpdateClient,
  ScoreCardLookupClient,
  DynamicReportRoleLookupClient,
  ConstructionSiteTenantLookupClient,
  BlockTenantLookupClient,
  PlotTenantLookupClient,
  BuildingBlockTypeTenantLookupClient,
  AdvanceCheckLookupClient,
  AllAccountLookupClient,
  AllCompanyByProjectLookupClient,
  CompanyInformationLookupClient,
  UserByUserProjectTenantLookupClient,
  AllUserTenantLookupClient
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//const SERVICE_URL = "https://localhost:7016";

const basicLookupClient = new BasicLookupClient(SERVICE_URL);
const staffTenantLookupClient = new StaffTenantLookupClient(SERVICE_URL);
const accountTenantLookupClient = new AccountTenantLookupClient(SERVICE_URL);
const extendedAccountLookupClient = new ExtendedAccountLookupClient(SERVICE_URL);
const districtLookupClient = new DistrictLookupClient(SERVICE_URL);
const allProductTenantLookupClient = new AllProductTenantLookupClient(SERVICE_URL);
const stockProductTenantLookupClient = new StockProductTenantLookupClient(SERVICE_URL);
const warehouseTenantLookupClient = new WarehouseTenantLookupClient(SERVICE_URL);
const projectTenantLookupClient = new ProjectTenantLookupClient(SERVICE_URL);
const allProjectTenantLookupClient = new AllProjectTenantLookupClient(SERVICE_URL);
const allCompanyTenantLookupClient = new AllCompanyTenantLookupClient(SERVICE_URL);
const getUsableCheckTenantLookupClient = new GetUsableCheckTenantLookupClient(SERVICE_URL);
const allRoleTenantLookupClient = new AllRoleTenantLookupClient(SERVICE_URL);
const allProductForUpdateClient = new AllProductForUpdateClient(SERVICE_URL);
const scoreCardLookupClient = new ScoreCardLookupClient(SERVICE_URL);
const dynamicReportRoleLookupClient = new DynamicReportRoleLookupClient(SERVICE_URL);
const constructionSiteTenantLookupClient = new ConstructionSiteTenantLookupClient(SERVICE_URL);
const blockTenantLookupClient = new BlockTenantLookupClient(SERVICE_URL);
const plotTenantLookupClient = new PlotTenantLookupClient(SERVICE_URL);
const buildingBlockTypeTenantLookupClient = new BuildingBlockTypeTenantLookupClient(SERVICE_URL);
const advanceCheckLookupClient = new AdvanceCheckLookupClient(SERVICE_URL);
const allAccountLookupClient = new AllAccountLookupClient(SERVICE_URL);
const allCompanyByProjectLookupClient = new AllCompanyByProjectLookupClient(SERVICE_URL);
const companyInformationLookupClient = new CompanyInformationLookupClient(SERVICE_URL);
const userByUserProjectTenantLookupClient = new UserByUserProjectTenantLookupClient(SERVICE_URL);
const allUserTenantLookupClient = new AllUserTenantLookupClient(SERVICE_URL);

export const lookupActions = {
  basicLookup,
  staffGetAll,
  bankCashAccountGetAll,
  extendedBankCashAccountGetAll,
  districtLookup,
  allProductLookup,
  stockProductLookup,
  warehouseLookup,
  projectLookup,
  allProjectLookup,
  allCompanyLookup,
  usableCheckLookup,
  allRoleLookup,
  allProductForUpdate,
  scoreCardLookup,
  dynamicReportRoleLookup,
  constructionSiteLookup,
  blockLookup,
  plotLookup,
  buildingBlockTypeLookup,
  advanceCheckLookup,
  allAccountLookup,
  allCompanyByProjectLookup,
  companyInformationLookup,
  userProjectLookup,
  allUserLookup
};

function basicLookup(name) {
  return dispatch => {
    dispatch(request(name));

    basicLookupClient.basicLookup({ name })
      .then(
        lookup => dispatch(success(lookup, name)),
        error => dispatch(failure(error))
      );
  };

  function request(name) { return { type: lookupConstants.BASIC_LOOKUP_REQUEST, name } }
  function success(lookup, name) { return { type: lookupConstants.BASIC_LOOKUP_SUCCESS, lookup, name } }
  function failure(error) { return { type: lookupConstants.BASIC_LOOKUP_FAILURE, error } }
}

function staffGetAll() {
  return dispatch => {
    dispatch(request());

    staffTenantLookupClient.staffLookup({})
      .then(
        staffLookup => dispatch(success(staffLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_STAFF_REQUEST } }
  function success(staffLookup) { return { type: lookupConstants.TENANT_LOOKUP_STAFF_SUCCESS, staffLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_STAFF_FAILURE, error } }
}

function bankCashAccountGetAll() {
  return dispatch => {
    dispatch(request());

    accountTenantLookupClient.accountLookup({})
      .then(
        accountLookup => dispatch(success(accountLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_ACCOUNT_REQUEST } }
  function success(accountLookup) { return { type: lookupConstants.TENANT_LOOKUP_ACCOUNT_SUCCESS, accountLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_ACCOUNT_FAILURE, error } }
}

function extendedBankCashAccountGetAll() {
  return dispatch => {
    dispatch(request());

    extendedAccountLookupClient.extendedAccountLookup({})
      .then(
        extendedAccountLookup => dispatch(success(extendedAccountLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_EXTENDED_ACCOUNT_REQUEST } }
  function success(extendedAccountLookup) { return { type: lookupConstants.TENANT_LOOKUP_EXTENDED_ACCOUNT_SUCCESS, extendedAccountLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_EXTENDED_ACCOUNT_FAILURE, error } }
}

function districtLookup(params) {
  return dispatch => {
    dispatch(request(params));

    districtLookupClient.districtLookup(params)
      .then(
        District => dispatch(success(District)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.DISTRICT_LOOKUP_REQUEST, params } }
  function success(District) { return { type: lookupConstants.DISTRICT_LOOKUP_SUCCESS, District } }
  function failure(error) { return { type: lookupConstants.DISTRICT_LOOKUP_FAILURE, error } }
}

function allProductLookup() {
  return dispatch => {
    dispatch(request());

    allProductTenantLookupClient.productLookup({})
      .then(
        productLookup => dispatch(success(productLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_PRODUCT_REQUEST } }
  function success(productLookup) { return { type: lookupConstants.TENANT_LOOKUP_PRODUCT_SUCCESS, productLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_PRODUCT_FAILURE, error } }
}

function allCompanyLookup() {
  return dispatch => {
    dispatch(request());

    allCompanyTenantLookupClient.companyLookup({})
      .then(
        companyLookup => dispatch(success(companyLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_COMPANY_REQUEST } }
  function success(companyLookup) { return { type: lookupConstants.TENANT_LOOKUP_COMPANY_SUCCESS, companyLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_COMPANY_FAILURE, error } }
}

function usableCheckLookup() {
  return dispatch => {
    dispatch(request());

    getUsableCheckTenantLookupClient.getUsableCheckLookup({})
      .then(
        usablecheckLookup => dispatch(success(usablecheckLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_USABLECHECK_REQUEST } }
  function success(usablecheckLookup) { return { type: lookupConstants.TENANT_LOOKUP_USABLECHECK_SUCCESS, usablecheckLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_USABLECHECK_FAILURE, error } }
}

function projectLookup() {
  return dispatch => {
    dispatch(request());

    projectTenantLookupClient.projectLookup({})
      .then(
        projectLookup => dispatch(success(projectLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_PROJECT_REQUEST } }
  function success(projectLookup) { return { type: lookupConstants.TENANT_LOOKUP_PROJECT_SUCCESS, projectLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_PROJECT_FAILURE, error } }
}

function allProjectLookup() {
  return dispatch => {
    dispatch(request());

    allProjectTenantLookupClient.allProjectLookup({})
      .then(
        allProjectLookup => dispatch(success(allProjectLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_ALL_PROJECT_REQUEST } }
  function success(allProjectLookup) { return { type: lookupConstants.TENANT_LOOKUP_ALL_PROJECT_SUCCESS, allProjectLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_ALL_PROJECT_FAILURE, error } }
}

function stockProductLookup() {
  return dispatch => {
    dispatch(request());

    stockProductTenantLookupClient.stockProductLookup({})
      .then(
        stockproductLookup => dispatch(success(stockproductLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_STOCKPRODUCT_REQUEST } }
  function success(stockproductLookup) { return { type: lookupConstants.TENANT_LOOKUP_STOCKPRODUCT_SUCCESS, stockproductLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_STOCKPRODUCT_FAILURE, error } }
}

function warehouseLookup() {
  return dispatch => {
    dispatch(request());

    warehouseTenantLookupClient.warehouseLookup({})
      .then(
        warehouseLookup => dispatch(success(warehouseLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_WAREHOUSE_REQUEST } }
  function success(warehouseLookup) { return { type: lookupConstants.TENANT_LOOKUP_WAREHOUSE_SUCCESS, warehouseLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_WAREHOUSE_FAILURE, error } }
}

function allRoleLookup() {
  return dispatch => {
    dispatch(request());

    allRoleTenantLookupClient.roleLookup({})
      .then(
        roleLookup => dispatch(success(roleLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_ROLE_REQUEST } }
  function success(roleLookup) { return { type: lookupConstants.TENANT_LOOKUP_ROLE_SUCCESS, roleLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_ROLE_FAILURE, error } }
}

function allProductForUpdate() {
  return dispatch => {
    dispatch(request());

    allProductForUpdateClient.allProductForUpdate({})
      .then(
        allProductForUpdate => dispatch(success(allProductForUpdate)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_PRODUCT_FOR_UPDATE_REQUEST } }
  function success(allProductForUpdate) { return { type: lookupConstants.TENANT_LOOKUP_PRODUCT_FOR_UPDATE_SUCCESS, allProductForUpdate } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_PRODUCT_FOR_UPDATE_FAILURE, error } }
}

function scoreCardLookup() {
  return dispatch => {
    dispatch(request());

    scoreCardLookupClient.scoreCardLookup({})
      .then(
        scorecard => dispatch(success(scorecard)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_SCORECARD_REQUEST } }
  function success(scorecard) { return { type: lookupConstants.TENANT_LOOKUP_SCORECARD_SUCCESS, scorecard } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_SCORECARD_FAILURE, error } }
}

function dynamicReportRoleLookup() {
  return dispatch => {
    dispatch(request());

    dynamicReportRoleLookupClient.dynamicReportRoleLookup({})
      .then(
        dynamicReportRoleLookup => dispatch(success(dynamicReportRoleLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_DYNAMICREPORT_ROLE_REQUEST } }
  function success(dynamicReportRoleLookup) { return { type: lookupConstants.TENANT_LOOKUP_DYNAMICREPORT_ROLE_SUCCESS, dynamicReportRoleLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_DYNAMICREPORT_ROLE_FAILURE, error } }
}

function constructionSiteLookup(params) {
  return dispatch => {
    dispatch(request(params));

    constructionSiteTenantLookupClient.constructionSiteLookup(params)
      .then(
        constructionSiteLookup => dispatch(success(constructionSiteLookup)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.TENANT_LOOKUP_CONSTRUCTION_SITE_REQUEST, params } }
  function success(constructionSiteLookup) { return { type: lookupConstants.TENANT_LOOKUP_CONSTRUCTION_SITE_SUCCESS, constructionSiteLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_CONSTRUCTION_SITE_FAILURE, error } }
}

function blockLookup(params) {
  return dispatch => {
    dispatch(request(params));

    blockTenantLookupClient.blockLookup(params)
      .then(
        blockLookup => dispatch(success(blockLookup)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.TENANT_LOOKUP_BLOCK_REQUEST, params } }
  function success(blockLookup) { return { type: lookupConstants.TENANT_LOOKUP_BLOCK_SUCCESS, blockLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_BLOCK_FAILURE, error } }
}

function plotLookup(params) {
  return dispatch => {
    dispatch(request(params));

    plotTenantLookupClient.plotLookup(params)
      .then(
        plotLookup => dispatch(success(plotLookup)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.TENANT_LOOKUP_PLOT_REQUEST, params } }
  function success(plotLookup) { return { type: lookupConstants.TENANT_LOOKUP_PLOT_SUCCESS, plotLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_PLOT_FAILURE, error } }
}

function buildingBlockTypeLookup(params) {
  return dispatch => {
    dispatch(request(params));

    buildingBlockTypeTenantLookupClient.buildingBlockTypeLookup(params)
      .then(
        buildingBlockTypeLookup => dispatch(success(buildingBlockTypeLookup)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.TENANT_LOOKUP_BUILDINGBLOCKTYPE_REQUEST, params } }
  function success(buildingBlockTypeLookup) { return { type: lookupConstants.TENANT_LOOKUP_BUILDINGBLOCKTYPE_SUCCESS, buildingBlockTypeLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_BUILDINGBLOCKTYPE_FAILURE, error } }
}

function advanceCheckLookup(params) {
  return dispatch => {
    dispatch(request(params));
    advanceCheckLookupClient.getAdvanceCheckLookup(params)
      .then(
        advanceCheckLookup => dispatch(success(advanceCheckLookup)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.TENANT_LOOKUP_ADVANCECHECK_REQUEST, params } }
  function success(advanceCheckLookup) { return { type: lookupConstants.TENANT_LOOKUP_ADVANCECHECK_SUCCESS, advanceCheckLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_ADVANCECHECK_FAILURE, error } }
}

function allAccountLookup() {
  return dispatch => {
    dispatch(request());
    allAccountLookupClient.allAccountLookup()
      .then(
        allAccountLookup => dispatch(success(allAccountLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_ALLACCOUNT_REQUEST } }
  function success(allAccountLookup) { return { type: lookupConstants.TENANT_LOOKUP_ALLACCOUNT_SUCCESS, allAccountLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_ALLACCOUNT_FAILURE, error } }
}

function allCompanyByProjectLookup(params) {
  return dispatch => {
    dispatch(request(params));
    allCompanyByProjectLookupClient.getAllCompanyByProjectLookup(params)
      .then(
        allCompanyByProjectLookup => dispatch(success(allCompanyByProjectLookup)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.TENANT_LOOKUP_ALLCOMPANYBYPROJECT_REQUEST, params } }
  function success(allCompanyByProjectLookup) { return { type: lookupConstants.TENANT_LOOKUP_ALLCOMPANYBYPROJECT_SUCCESS, allCompanyByProjectLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_ALLCOMPANYBYPROJECT_FAILURE, error } }
}

function companyInformationLookup(params) {
  return dispatch => {
    dispatch(request(params));
    companyInformationLookupClient.getCompanyInformationLookup(params)
      .then(
        companyInformationLookup => dispatch(success(companyInformationLookup)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: lookupConstants.TENANT_LOOKUP_COMPANYINFORMATION_REQUEST, params } }
  function success(companyInformationLookup) { return { type: lookupConstants.TENANT_LOOKUP_COMPANYINFORMATION_SUCCESS, companyInformationLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_COMPANYINFORMATION_FAILURE, error } }
}

function userProjectLookup() {
  return dispatch => {
    dispatch(request());
    userByUserProjectTenantLookupClient.usersLookup()
      .then(
        userProjectLookup => dispatch(success(userProjectLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_USERPROJECT_REQUEST } }
  function success(userProjectLookup) { return { type: lookupConstants.TENANT_LOOKUP_USERPROJECT_SUCCESS, userProjectLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_USERPROJECT_FAILURE, error } }
}

function allUserLookup() {
  return dispatch => {
    dispatch(request());
    allUserTenantLookupClient.userLookup()
      .then(
        allUserLookup => dispatch(success(allUserLookup)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: lookupConstants.TENANT_LOOKUP_ALLUSER_REQUEST } }
  function success(allUserLookup) { return { type: lookupConstants.TENANT_LOOKUP_ALLUSER_SUCCESS, allUserLookup } }
  function failure(error) { return { type: lookupConstants.TENANT_LOOKUP_ALLUSER_FAILURE, error } }
}
