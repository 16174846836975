/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, DeleteModal, ArchiveModal, DataTables, Category, FileManager } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from '../../../redux/actions';
import { SourceType, KTSVG, detailPageUrl, ExpenseDetailPage, DetailPage } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, error } = useSelector(state => state.project);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(projectActions.projectGetId(id));
  }, [])

  const handleDelete = () => dispatch(projectActions.projectDelete({ id }));

  const handleArchive = () => dispatch(projectActions.projectArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const handleRemainingDay = (remainingDay) => {
    if (remainingDay !== undefined) {
      return remainingDay.toString().startsWith('-') ? remainingDay * -1 + intl.formatMessage({ id: 'PROJECT.DAYPASSED' }) : remainingDay + intl.formatMessage({ id: 'PROJECT.DAYLEFT' })
    }
  }

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'MENU.PROJECTS.PROJECTS' }),
      path: '/projects/projects',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.PROJECTS.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>
                           
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">                          

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen044.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.projectStateName}
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/communication/com006.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.projectLeader}
                          </div>
                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/nav/Staff.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.siteResponsibility}
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/nav/Staff.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.technicalOfficeChief}
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen018.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.address} {detail.provinceName} - {detail.districtName}
                          </div>
                         
                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.Project} />
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle 
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROJECT.REMAININGDAY' })}</div>
                          <div className="fw-bold fs-4">{handleRemainingDay(detail.remainingDay)}</div>                          
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROJECT.TOTALSALES' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalSales, { style: 'currency', currency: 'try' })}</span></div>                          
                        </div>                      

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROJECT.TOTALEXPENSES' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalExpenses, { style: 'currency', currency: 'try' })}</span></div>                          
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROJECT.TOTALSALARIES' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalSalaries, { style: 'currency', currency: 'try' })}</span></div>
                        </div>

                        {detail.totalPaymentPlanExpense > 0 && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROJECT.TOTALPAYMENTPLANEXPENSE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalPaymentPlanExpense, { style: 'currency', currency: 'try' })}</span></div>
                        </div>}

                        {detail.totalPaymentPlanSale > 0 && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROJECT.TOTALPAYMENTPLANSALE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalPaymentPlanSale, { style: 'currency', currency: 'try' })}</span></div>
                        </div>}
                    
                      </div>
                    </div>
                  </div>
                </div>
                {detail?.description && <>
                  <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                    {detail?.description}
                  </div>
                </>}      
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>                      
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.CUSTOMERNAME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.customerName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.STARTDATE' })}</label>
                          {detail?.startDate && <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.startDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>}
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.ENDDATE' })}</label>
                          {detail?.endDate && <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.endDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>}
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.TOTALSALESBALANCE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalSaleBalances, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.TOTALCOLLECTION' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalCollection, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.TOTALEXPENSESBALANCE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalExpenseBalances, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.TOTALPAYMENT' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalPayment, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PROJECT.TOTALONSALEREALESTATES' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalOnSaleRealEstates, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  {detail?.realEstates?.length > 0 && <Tab eventKey="realEstates" title={intl.formatMessage({id: 'PROJECT.REALESTATES' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'PROJECT.REALESTATES.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.REALESTATES.BASESALEPRICE' }),
                          prop: 'baseSalePrice',
                          cell: (row) => intl.formatNumber(row.baseSalePrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.REALESTATES.ESTATESTATENAME' }),
                          prop: 'estateStateName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.REALESTATES.ESTATETYPENAME' }),
                          prop: 'estateTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.REALESTATES.GROSSAREA' }),
                          prop: 'grossArea',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.realEstates}
                      exportTitle={intl.formatMessage({id: 'PROJECT.REALESTATES' })}
                    />
                  </Tab>}

                  {detail?.saleInvoices?.length > 0 && <Tab eventKey="saleInvoices" title={intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.SaleInvoice, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES.COMPANYNAME' }),
                          prop: 'companyName',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.CompanyCustomer, row?.companyId)} className='text-dark text-hover-primary' target='_blank'>{row.companyName}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES.SALEPAYMENTSTATUSNAME' }),
                          prop: 'salePaymentStatusName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES.INVOICEKINDNAME' }),
                          prop: 'invoiceKindName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES.BALANCE' }),
                          prop: 'balance',
                          cell: (row) => intl.formatNumber(row.balance, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.saleInvoices}
                      exportTitle={intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES' })}
                    />
                  </Tab>}

                  {detail?.expenseInvoices?.length > 0 && <Tab eventKey="expenseInvoices" title={intl.formatMessage({ id: 'COMPANY.GET.EXPENSES' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(ExpenseDetailPage[row?.expenseTypeId], row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.COMPANYNAME' }),
                          prop: 'companyName',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.CompanySupplier, row?.companyId)} className='text-dark text-hover-primary' target='_blank'>{row.companyName}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.EXPENSEPAYMENTSTATUSNAME' }),
                          prop: 'expensePaymentStatusName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.SALEINVOICES.INVOICEKINDNAME' }),
                          prop: 'invoiceKindName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.BALANCE' }),
                          prop: 'balance',
                          cell: (row) => intl.formatNumber(row.balance, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.expenseInvoices}
                      exportTitle={intl.formatMessage({ id: 'COMPANY.GET.EXPENSES' })}
                    />
                  </Tab>}

                  {detail?.staffs?.length > 0 && <Tab eventKey="staffs" title={intl.formatMessage({ id: 'PROJECT.GET.STAFFS' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.STAFFS.NAMESURNAME' }),
                          prop: 'nameSurname',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Staff, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.nameSurname}</Link>,
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.staffs}
                      exportTitle={intl.formatMessage({ id: 'PROJECT.GET.STAFFS' })}
                    />
                  </Tab>}

                  {detail?.bankCashes?.length > 0 && <Tab eventKey="bankCashes" title={intl.formatMessage({ id: 'PROJECT.GET.BANKCASHES' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.BANKCASHES.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Bank, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.BANKCASHES.BALANCE' }),
                          prop: 'balance',
                          cell: (row) => intl.formatNumber(row.balance, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.bankCashes}
                      exportTitle={intl.formatMessage({ id: 'PROJECT.GET.BANKCASHES' })}
                    />
                  </Tab>}

                  {detail?.salaries?.length > 0 && <Tab eventKey="salaries" title={intl.formatMessage({ id: 'PROJECT.GET.SALARIES' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.SALARIES.NAMESURNAME' }),
                          prop: 'nameSurname',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Salary, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.nameSurname}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.SALARIES.PAYMENT' }),
                          prop: 'payment',
                          cell: (row) => intl.formatNumber(row.payment, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.salaries}
                      exportTitle={intl.formatMessage({ id: 'PROJECT.GET.SALARIES' })}
                    />
                  </Tab>}

                  {detail?.companiesWithExpenseAndSalePrices?.length > 0 && <Tab eventKey="companiesWithExpenseAndSalePrices" title={intl.formatMessage({ id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES.COMPANYNAME' }),
                          prop: 'companyName',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.CompanySupplier, row?.companyId)} className='text-dark text-hover-primary' target='_blank'>{row.companyName}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES.EXPENSETOTAL' }),
                          prop: 'expenseTotal',
                          cell: (row) => intl.formatNumber(row.expenseTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES.EXPENSEBALANCETOTAL' }),
                          prop: 'expenseBalanceTotal',
                          cell: (row) => intl.formatNumber(row.expenseBalanceTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES.SALETOTAL' }),
                          prop: 'saleTotal',
                          cell: (row) => intl.formatNumber(row.saleTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES.SALEBALANCETOTAL' }),
                          prop: 'saleBalanceTotal',
                          cell: (row) => intl.formatNumber(row.saleBalanceTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES.PAYMENT' }),
                          prop: 'totalAdvance',
                          cell: (row) => intl.formatNumber(row.totalAdvance, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES.COLLECTION' }),
                          prop: 'totalCollection',
                          cell: (row) => intl.formatNumber(row.totalCollection, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }
                      ]}
                      data={detail?.companiesWithExpenseAndSalePrices}
                      exportTitle={intl.formatMessage({ id: 'PROJECT.GET.COMPANIESWITHEXPENSEANDSALEPRICES' })}
                    />
                  </Tab>}

                  {detail?.paymentPlanExpenses?.length > 0 && <Tab eventKey="paymentPlanExpense" title={intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.EXPENSE' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.EXPENSE.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.COMPANYNAME' }),
                          prop: 'companyName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.PAYMENTPLANTYPENAME' }),
                          prop: 'paymentPlanTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.DESCRIPTION' }),
                          prop: 'description',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.PAYMENTPLAN.DEFERMENTDATE' }),
                          prop: 'defermentDate',
                          cell: (row) => intl.formatDate(new Date(row?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.paymentPlanExpenses}
                      exportTitle={intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.EXPENSE' })}
                    />
                  </Tab>}

                  {detail?.paymentPlanSales?.length > 0 && <Tab eventKey="paymentPlanSale" title={intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.SALE' })}>
                    <DataTables 
                      columns={[
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.SALE.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.COMPANYNAME' }),
                          prop: 'companyName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.PAYMENTPLANTYPENAME' }),
                          prop: 'paymentPlanTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.DESCRIPTION' }),
                          prop: 'description',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.PAYMENTPLAN.DEFERMENTDATE' }),
                          prop: 'defermentDate',
                          cell: (row) => intl.formatDate(new Date(row?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.paymentPlanSales}
                      exportTitle={intl.formatMessage({id: 'COMPANY.GET.PAYMENTPLAN.SALE' })}
                    />
                  </Tab>}
                </Tabs>
              </KTCardBody>
            </KTCard>

            <FileManager 
              SourceId={Number(id)} 
              SourceTypeId={SourceType.Project}
            />
          </>}
        </div>
        <div className='col-xl-3'>
          <Notes 
            SourceId={Number(id)} 
            SourceTypeId={SourceType.Project}
          />
        </div>
      </div>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal 
        onClick={handleArchive} 
        onOpen={showArchive} 
        onClose={() => setShowArchive(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
