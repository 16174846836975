import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { workplanActions, lookupActions } from '../../../redux/actions';

import { TaskState } from '../../../helpers';

function List() {
  const intl = useIntl()
  const { task, loading } = useSelector(state => state.workplan);
  const { projectLookup, staffLookup, constructionSiteLookup, blockLookup, plotLookup } = useSelector(state => state.lookup);

  const dispatch = useDispatch();

  const [params, setParams] = useState()
  const [project, setProject] = useState(null)
  const [constructionSite, setConstructionSite] = useState(null)
  const [constructionSiteVal, setConstructionSiteVal] = useState(null)
  const [block, setBlock] = useState(null)
  const [blockVal, setBlockVal] = useState(null)
  const [plot, setPlot] = useState(null)

  useEffect(() => {
    if (params) dispatch(workplanActions.taskGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.staffGetAll());
  }, [])

  useEffect(() => {
    if (project) dispatch(lookupActions.constructionSiteLookup({projectId: project}));
  }, [project])

  useEffect(() => {
    if (constructionSiteVal) dispatch(lookupActions.blockLookup({constructionSiteId: constructionSiteVal}));
  }, [constructionSiteVal])

  useEffect(() => {
    if (blockVal) dispatch(lookupActions.plotLookup({
      projectId: project,
      constructionId: constructionSiteVal,
      blockId: blockVal
    }));
  }, [blockVal])

  useEffect(() => {
    if (constructionSiteLookup) {
      setConstructionSite(constructionSiteLookup)
      setBlock(null)
      setPlot(null)
    }

    if (blockLookup) {
      setBlock(blockLookup)
      setPlot(null)
    }

    if (plotLookup) setPlot(plotLookup)
  }, [constructionSiteLookup, blockLookup, plotLookup])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({...props}) => <CustomCellDouble item={props} double='rootTaskName' />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.PROJECT' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.CONSTRUCTIONSITE' })} className='min-w-125px' />,
      accessor: 'constructionSiteName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.BLOCKPLOT' })} className='min-w-125px' />,
      accessor: 'blockName',
      Cell: ({ ...props }) => <CustomCellDouble item={props}  double='plotName' />,
    },    
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.STARTDATE' })} className='min-w-125px' />,
      accessor: 'startDate',
      Cell: ({...props}) => <CustomCell item={props} date="short" />,
    },    
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.ENDDATE' })} className='min-w-125px' />,
      accessor: 'endDate',
      Cell: ({ ...props }) => <CustomCellDouble item={props} date="short" double='remainingDay' />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.COMPLETIONRATE' })} className='min-w-125px' />,
      accessor: 'completionRate',
      Cell: ({ ...props }) => <CustomCell item={props} leftsuffix="%" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TASK.SEARCH.TASKSTATENAME' })} className='min-w-125px' />,
      accessor: 'taskStateName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    }
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(workplanActions.taskExcel(params, intl.formatMessage({ id: 'BC.TASK' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [ 
        {
          title: 'TASK.SEARCH.PROJECT',
          data: projectLookup,
          name: 'projectId',
          onChange: (value) => setProject(value)
        },
        {
          title: 'TASK.SEARCH.CONSTRUCTIONSITE',
          data: constructionSite,
          name: 'constructionSiteId',
          onChange: (value) => setConstructionSiteVal(value)
        },
        {
          title: 'TASK.SEARCH.BLOCK',
          data: block,
          name: 'blockId',
          onChange: (value) => setBlockVal(value)
        },
        {
          title: 'TASK.SEARCH.PLOTID',
          data: plot,
          name: 'plotId'
        },
        {
          title: 'TASK.SEARCH.TASKSTATENAME',
          data: TaskState,
          name: 'taskStateId'
        },
        {
          title: 'TASK.SEARCH.TASKPLAN',
          data: [
            {
              id: 'true',
              name: intl.formatMessage({ id: 'TASK.SEARCH.ROOTTASK' })
            },
            {
              id: 'false',
              name: intl.formatMessage({ id: 'TASK.SEARCH.SUBTASK' })
            },
          ],
          name: 'onlyRootTask'
        },
        {
          title: 'TASK.SEARCH.STAFFNAME',
          data: staffLookup,
          name: 'staffId'
        },
        {
          title: 'TASK.SEARCH.STARTDATE',
          type: 'date',
          name: 'startDate'
        },
        {
          title: 'TASK.SEARCH.ENDDATE',
          type: 'date',
          name: 'endDate'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.TASK' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => {
            if (values?.projectId) setProject(values?.projectId)
            if (values?.constructionSiteId) setConstructionSiteVal(values?.constructionSiteId)
            if (values?.blockId) setBlockVal(values?.blockId)
            setParams({ ...values })
          }}
          pages={'TASK'}
        />
        <KTCardBody>
          {!task && <ListLoading />}
          {task && <Table
            items={task}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
