import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { KTCard } from '../Card/KTCard'
import { KTCardBody } from '../Card/KTCardBody'
import { KTCardHeader } from '../Card/KTCardHeader'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch } from 'react-redux';
import { workplanActions } from '../../redux/actions';
import { Select } from '..';
import {TaskState, KTSVG} from '../../helpers';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

const FastTaskUpdate = ({collapse, Id, TaskStateId, CompletionRate}) => {
  const intl = useIntl()
  const [isCollapse, setIsCollapse] = useState(collapse);

	const dispatch = useDispatch();

  const validSchema = Yup.object().shape({
    TaskStateId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    CompletionRate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id,
    EndDate: new Date(),
    TaskStateId,
    CompletionRate: 0,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(workplanActions.taskUpdateCompletion(values));
      resetForm()
    },
  })

  useEffect(() => {
    if (TaskStateId) formik.setFieldValue('TaskStateId', TaskStateId)
    if (CompletionRate) formik.setFieldValue('CompletionRate', CompletionRate)
  }, [TaskStateId, CompletionRate])

  return (
    <>
      <KTCard className='mb-5'>
        <KTCardHeader title={intl.formatMessage({id: 'TASK.UPDATE.TITLE' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

        <KTCardBody 
          className='p-5'
          collapseble
          isShow={isCollapse}
        >
          <form className='position-relative mb-6 form' onSubmit={formik.handleSubmit} noValidate>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'TASK.UPDATE.ENDDATE' })}</label>
              <div className="position-relative d-flex align-items-center">
                <KTSVG
                  path='/images/icons/duotune/general/gen014.svg'
                  className='svg-icon svg-icon-2 position-absolute mx-4'
                />
                <Flatpickr
                  className="form-control form-control-solid ps-12"
                  options={{ "locale": Turkish }}
                  {...formik.getFieldProps('EndDate')}
                  onChange={([date]) => formik.setFieldValue('EndDate', date)}
                />
              </div>
            </div>

            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'TASK.UPDATE.TASKSTATEID' })}</label>
              <Select
                data={TaskState} 
                required
                {...formik.getFieldProps('TaskStateId')}
                onChange={item => formik.setFieldValue('TaskStateId', Number(item?.value))}
                textType={'intl'}
              />
              {formik.touched.TaskStateId && formik.errors.TaskStateId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.TaskStateId}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'TASK.UPDATE.COMPLETIONRATE' })}</label>
              <input
                type='number'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                required
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('CompletionRate')}
                onFocus={event => event.target.select()}
              />
              {formik.touched.CompletionRate && formik.errors.CompletionRate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.CompletionRate}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-grid gap-2'>
              <button
                type='submit'
                className='btn btn-light-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'PLEASE' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </KTCardBody>
      </KTCard>
    </>
  )
}

FastTaskUpdate.propTypes = {
	collapse: PropTypes.bool,
  SourceId: PropTypes.number,
	SourceTypeId: PropTypes.number,
};

FastTaskUpdate.defaultProps = {
	collapse: true
};

export {FastTaskUpdate}
