/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTSVG, PurchaseRequestStatus } from '../../../helpers';
import { KTCard, KTCardBody, KTCardHeader, Select, SelectCreatable } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, purchaseActions, productActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { productLookup, projectLookup, companyLookup, ProductUnit, staffLookup } = useSelector(state => state.lookup);
  const { detail: product, isRedirect: createProduct } = useSelector(state => state.product);
  const { isRedirect, loading } = useSelector(state => state.purchase);
  const dispatch = useDispatch();
  const [changeProduct, setChangeProduct] = useState();
  const [createItem, setCreateItem] = useState();

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.allProductLookup());
    dispatch(lookupActions.basicLookup('ProductUnit'));
    dispatch(lookupActions.staffGetAll());
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PurchaseRequestStatusId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Name: '',
    ProjectId: null,
    CompanyId: null,
    EditDate: new Date(),
    DueDate: new Date(),
    PurchaseRequestStatusId: '',
    Note: '',
    RelatedStaffId: null,
    PurchaseRequestItems: [{
      ProductId: null,
      Amount: 0,
      ProductUnitId: 1,
      Description: ''
    }]
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(purchaseActions.purchaseRequestCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.REQUEST' }),
      path: '/purchase/request',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  const onChangeItem = (target, value, index) => {
    formik.setFieldValue(`PurchaseRequestItems[${index}][${target}]`, value);
  }

  const onClickNewItem = (arrayHelpers) => {
    arrayHelpers.push({
      ProductId: null,
      Amount: 0,
      ProductUnitId: 1,
      Description: ''
    })
  }

  const onChangeProduct = (item, index) => {
    formik.setFieldValue(`PurchaseRequestItems[${index}].ProductId`, Number(item?.value))
    setChangeProduct(index)
    dispatch(productActions.productGetInfo({ Id: item?.value }));
  }

  useEffect(() => {
    if (product) formik.setFieldValue(`PurchaseRequestItems[${changeProduct}].ProductUnitId`, product?.productUnitId);
  }, [product])

  const onClickFastProduct = (Name, Index) => {
    dispatch(productActions.productCreateFast({ Name }));
    setCreateItem({ Name, Index })
  }

  useEffect(() => {
    if (createProduct) dispatch(lookupActions.allProductLookup());
  }, [createProduct])

  useEffect(() => {
    if (productLookup && createProduct) {
      formik.setFieldValue(`PurchaseRequestItems[${createItem?.Index}].ProductId`, createProduct.id);
    }
  }, [productLookup, createProduct])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.REQUEST.ADD' })}</PageTitle>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <KTCard>
            <KTCardBody>
              <FormikProvider value={formik}>
                <form className='form' onSubmit={formik.handleSubmit} noValidate>

                  <div className='row mb-6'>
                    <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REQUEST.CREATE.NAME' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        required
                        disabled={formik.isSubmitting}
                        {...formik.getFieldProps('Name')}
                      />
                      {formik.touched.Name && formik.errors.Name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.Name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REQUEST.CREATE.COMPANYID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={companyLookup}
                        {...formik.getFieldProps('CompanyId')}
                        onChange={item => formik.setFieldValue('CompanyId', Number(item?.value))}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REQUEST.CREATE.PROJECTID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={projectLookup}
                        required
                        {...formik.getFieldProps('ProjectId')}
                        onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                      />
                      {formik.touched.ProjectId && formik.errors.ProjectId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.ProjectId}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REQUEST.CREATE.PURCHASEREQUESTSTATUSID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={PurchaseRequestStatus}
                        required
                        {...formik.getFieldProps('PurchaseRequestStatusId')}
                        onChange={item => formik.setFieldValue('PurchaseRequestStatusId', Number(item?.value))}
                        textType={'intl'}
                      />
                      {formik.touched.PurchaseRequestStatusId && formik.errors.PurchaseRequestStatusId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.PurchaseRequestStatusId}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>



                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REQUEST.CREATE.EDITDATE' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <div className="position-relative d-flex align-items-center">
                        <KTSVG
                          path='/images/icons/duotune/general/gen014.svg'
                          className='svg-icon svg-icon-2 position-absolute mx-4'
                        />
                        <Flatpickr
                          className="form-control form-control-solid ps-12"
                          options={{ "locale": Turkish }}
                          {...formik.getFieldProps('EditDate')}
                          onChange={([date]) => formik.setFieldValue('EditDate', date)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REQUEST.CREATE.DUEDATE' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <div className="position-relative d-flex align-items-center">
                        <KTSVG
                          path='/images/icons/duotune/general/gen014.svg'
                          className='svg-icon svg-icon-2 position-absolute mx-4'
                        />
                        <Flatpickr
                          className="form-control form-control-solid ps-12"
                          options={{ "locale": Turkish }}
                          {...formik.getFieldProps('DueDate')}
                          onChange={([date]) => formik.setFieldValue('DueDate', date)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REQUEST.CREATE.RELATEDSTAFFID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={staffLookup}
                        required
                        {...formik.getFieldProps('RelatedStaffId')}
                        onChange={item => formik.setFieldValue('RelatedStaffId', Number(item?.value))}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REQUEST.CREATE.NOTE' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <textarea
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        disabled={formik.isSubmitting}
                        rows={4}
                        {...formik.getFieldProps('Note')}
                      />
                    </div>
                  </div>


                  <FieldArray
                    name="PurchaseRequestItems"
                    render={(arrayHelpers) => (
                      <>
                        <div className="table-responsive">
                          <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" >
                            <thead>
                              <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                <th>{intl.formatMessage({ id: 'REQUEST.CREATE.PURCHASEREQUESTITEMS.PRODUCTID' })}</th>
                                <th>{intl.formatMessage({ id: 'REQUEST.CREATE.PURCHASEREQUESTITEMS.AMOUNT' })}</th>
                                <th>{intl.formatMessage({ id: 'REQUEST.CREATE.PURCHASEREQUESTITEMS.PRODUCTUNITID' })}</th>
                                <th>{intl.formatMessage({ id: 'REQUEST.CREATE.PURCHASEREQUESTITEMS.DESCRIPTION' })}</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>

                              {formik.values.PurchaseRequestItems.map((item, index) => (
                                <tr className="border-bottom border-bottom-dashed" key={index}>
                                  <td className="pe-7 ps-1">
                                    <SelectCreatable
                                      data={productLookup}
                                      name={`PurchaseRequestItems[${index}].ProductId`}
                                      value={formik.values.PurchaseRequestItems[index].ProductId}
                                      required
                                      onChange={item => onChangeProduct(item, index)}
                                      onCreate={item => onClickFastProduct(item, index)}
                                    />
                                  </td>

                                  <td className="ps-0">
                                    <input
                                      type='number'
                                      className='form-control form-control-solid'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      placeholder={intl.formatMessage({ id: 'REQUEST.CREATE.PURCHASEREQUESTITEMS.AMOUNT' })}
                                      name={`PurchaseRequestItems[${index}].Amount`}
                                      value={formik.values.PurchaseRequestItems[index].Amount}
                                      required
                                      onChange={event => onChangeItem('Amount', Number(event.target.value), index)}
                                      onFocus={event => event.target.select()}
                                    />
                                  </td>

                                  <td className="ps-0">
                                    <Select
                                      data={ProductUnit}
                                      name={`PurchaseRequestItems[${index}].ProductUnitId`}
                                      value={formik.values.PurchaseRequestItems[index].ProductUnitId}
                                      required
                                      onChange={item => onChangeItem('ProductUnitId', Number(item?.value), index)}
                                    />
                                  </td>

                                  <td className='ps-0'>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      placeholder={intl.formatMessage({ id: 'REQUEST.CREATE.PURCHASEREQUESTITEMS.DESCRIPTION' })}
                                      name={`PurchaseRequestItems[${index}].Description`}
                                      value={formik.values.PurchaseRequestItems[index].Description}
                                      onChange={formik.handleChange}
                                    />
                                  </td>

                                  <td className="pt-5 text-end">
                                    <div className='d-flex'>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-active-color-primary"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr className="border-top border-top-dashed align-middle fs-6 fw-bold text-gray-700">
                                <th colSpan={4}>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success"
                                    onClick={() => onClickNewItem(arrayHelpers)}
                                  >
                                    <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                                    {intl.formatMessage({ id: 'ADD_NEW_LINE' })}
                                  </button>
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </>
                    )}
                  />

                  <div className='row pt-15'>
                    <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                      <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </FormikProvider>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
