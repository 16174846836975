/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, Timeline, DeleteModal, DataTables } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../../../redux/actions';
import { SourceType, KTSVG } from '../../../helpers';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, error, loading } = useSelector(state => state.product);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(productActions.outgoingWaybillGetId(id));
  }, [])

  const handleDelete = () => dispatch(productActions.outgoingWaybillDelete({ id }));

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.OUT_WAYBILLS' }),
      path: '/stock/out-waybills',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.OUT_WAYBILLS.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.projectName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.projectName}
                            </div>
                          </>}


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>                        
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">                            
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>		  
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">                       
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.COMPANY' })}</div>
                          <div className="fw-bold fs-4">{detail.companyName}</div>
                        </div>
                        {detail?.shipmentDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.SHIPMENTDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.shipmentDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.WAYBILLNUMBER' })}</div>
                          <div className="fw-bold fs-4">{detail.waybillNumber}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>                                                                 
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.SHIPMENTADDRESS' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.shipmentAddress}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.SHIPMENTPROVINCE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.shipmentProvinceName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.SHIPMENTDISTRICT' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.shipmentDistrictName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.EXITADDRESS' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.exitAddress}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.EXITPROVINCE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.exitProvinceName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.EXITDISTRICT' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.exitDistrictName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.SHIPMENTTYPE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.shipmentTypeName}</span></div>
                        </div>
                        {detail.shipmentTypeId === 1 && <><div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.CARPLATE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.carPlate}</span></div>
                        </div>
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.DRIVERNAME' })}</label>
                            <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.driverName}</span></div>
                          </div></>}
                        {detail.shipmentTypeId === 2 && <><div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.CARGOCOMPANY' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.cargoCompany}</span></div>
                        </div>
                          <div className="row mb-7">
                            <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.CARGOCOMPANYTAXNUMBER' })}</label>
                            <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.cargoCompanyTaxNumber}</span></div>
                          </div></>}
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'OUTGOINGWAYBILL.GET.ISSUEDATE' })}</label>
                          {detail?.issueDate && <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.issueDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>}
                        </div>                     
                      </div>
                    </div>
                  </Tab>
                  {detail?.outgoingWaybillItems?.length > 0 && <Tab eventKey="outwaybillitems" title={intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS' })}>
                      <div className='row p-5'>
                        <div className='col-lg-10 order-2 order-lg-1'>
                          <DataTables
                            columns={[
                              {
                                title: intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS.PRODUCTID' }),
                                prop: 'productName',
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS.WAREHOUSEID' }),
                                prop: 'warehouseName',
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS.AMOUNT' }),
                                prop: 'amount',
                                isSortable: true,
                                isFilterable: true
                              }]
                            }
                            data={detail?.outgoingWaybillItems}
                            exportTitle={intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS' })}
                          />
                        </div>
                      </div>
                    </Tab>}
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}          
        </div>
        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.OutgoingWaybill} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.OutgoingWaybill} collapse={false} />
        </div>
      </div>
      
      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
