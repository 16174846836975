export const scorecardConstants = {
  //#region ScoreCard
  SCORECARD_GETALL_REQUEST: 'SCORECARD_GETALL_REQUEST',
  SCORECARD_GETALL_SUCCESS: 'SCORECARD_GETALL_SUCCESS',
  SCORECARD_GETALL_FAILURE: 'SCORECARD_GETALL_FAILURE',

  SCORECARD_GETID_REQUEST: 'SCORECARD_GETID_REQUEST',
  SCORECARD_GETID_SUCCESS: 'SCORECARD_GETID_SUCCESS',
  SCORECARD_GETID_FAILURE: 'SCORECARD_GETID_FAILURE',

  SCORECARD_DELETE_REQUEST: 'SCORECARD_DELETE_REQUEST',
  SCORECARD_DELETE_SUCCESS: 'SCORECARD_DELETE_SUCCESS',
  SCORECARD_DELETE_FAILURE: 'SCORECARD_DELETE_FAILURE',

  SCORECARD_CREATE_REQUEST: 'SCORECARD_CREATE_REQUEST',
  SCORECARD_CREATE_SUCCESS: 'SCORECARD_CREATE_SUCCESS',
  SCORECARD_CREATE_FAILURE: 'SCORECARD_CREATE_FAILURE',

  SCORECARD_UPDATE_REQUEST: 'SCORECARD_UPDATE_REQUEST',
  SCORECARD_UPDATE_SUCCESS: 'SCORECARD_UPDATE_SUCCESS',
  SCORECARD_UPDATE_FAILURE: 'SCORECARD_UPDATE_FAILURE',
  //#endregion

  //#region ScoreCardDay
  SCORECARDDAY_GETALL_REQUEST: 'SCORECARDDAY_GETALL_REQUEST',
  SCORECARDDAY_GETALL_SUCCESS: 'SCORECARDDAY_GETALL_SUCCESS',
  SCORECARDDAY_GETALL_FAILURE: 'SCORECARDDAY_GETALL_FAILURE',
                                         
  SCORECARDDAY_UPDATE_REQUEST: 'SCORECARDDAY_UPDATE_REQUEST',
  SCORECARDDAY_UPDATE_SUCCESS: 'SCORECARDDAY_UPDATE_SUCCESS',
  SCORECARDDAY_UPDATE_FAILURE: 'SCORECARDDAY_UPDATE_FAILURE',

  //#endregion
};
