export const WitholdingType = [
  {
    id: 1,
    name: 'Tümüne 100',
    value: 100
  },
  {
    id: 2,
    name: '9/10 90',
    value: 90
  },
  {
    id: 3,
    name: '7/10 70',
    value: 70
  },
  {
    id: 4,
    name: '5/10 50',
    value: 50
  },
  {
    id: 5,
    name: '4/10 40',
    value: 40
  },
  {
    id: 6,
    name: '3/10 30',
    value: 30
  },
  {
    id: 7,
    name: '2/10 20',
    value: 20
  }
]