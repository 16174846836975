export const bankPaymentOrderConstants = {
  //#region PaymentRequest
  BANKPAYMENTORDER_GETALL_REQUEST: 'BANKPAYMENTORDER_GETALL_REQUEST',
  BANKPAYMENTORDER_GETALL_SUCCESS: 'BANKPAYMENTORDER_GETALL_SUCCESS',
  BANKPAYMENTORDER_GETALL_FAILURE: 'BANKPAYMENTORDER_GETALL_FAILURE',

  BANKPAYMENTORDER_GETID_REQUEST: 'BANKPAYMENTORDER_GETID_REQUEST',
  BANKPAYMENTORDER_GETID_SUCCESS: 'BANKPAYMENTORDER_GETID_SUCCESS',
  BANKPAYMENTORDER_GETID_FAILURE: 'BANKPAYMENTORDER_GETID_FAILURE',

  BANKPAYMENTORDER_DELETE_REQUEST: 'BANKPAYMENTORDER_DELETE_REQUEST',
  BANKPAYMENTORDER_DELETE_SUCCESS: 'BANKPAYMENTORDER_DELETE_SUCCESS',
  BANKPAYMENTORDER_DELETE_FAILURE: 'BANKPAYMENTORDER_DELETE_FAILURE',

  BANKPAYMENTORDER_CREATE_REQUEST: 'BANKPAYMENTORDER_CREATE_REQUEST',
  BANKPAYMENTORDER_CREATE_SUCCESS: 'BANKPAYMENTORDER_CREATE_SUCCESS',
  BANKPAYMENTORDER_CREATE_FAILURE: 'BANKPAYMENTORDER_CREATE_FAILURE',

  BANKPAYMENTORDER_UPDATE_REQUEST: 'BANKPAYMENTORDER_UPDATE_REQUEST',
  BANKPAYMENTORDER_UPDATE_SUCCESS: 'BANKPAYMENTORDER_UPDATE_SUCCESS',
  BANKPAYMENTORDER_UPDATE_FAILURE: 'BANKPAYMENTORDER_UPDATE_FAILURE',

  BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_REQUEST: 'BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_REQUEST',
  BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_SUCCESS: 'BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_SUCCESS',
  BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_FAILURE: 'BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_FAILURE',

  BANKPAYMENTORDER_EXCEL_REQUEST: 'BANKPAYMENTORDER_EXCEL_REQUEST',
  BANKPAYMENTORDER_EXCEL_SUCCESS: 'BANKPAYMENTORDER_EXCEL_SUCCESS',
  BANKPAYMENTORDER_EXCEL_FAILURE: 'BANKPAYMENTORDER_EXCEL_FAILURE',

  BANKPAYMENTORDER_SENDTOCONFIRMATION_REQUEST: 'BANKPAYMENTORDER_SENDTOCONFIRMATION_REQUEST',
  BANKPAYMENTORDER_SENDTOCONFIRMATION_SUCCESS: 'BANKPAYMENTORDER_SENDTOCONFIRMATION_SUCCESS',
  BANKPAYMENTORDER_SENDTOCONFIRMATION_FAILURE: 'BANKPAYMENTORDER_SENDTOCONFIRMATION_FAILURE',

  BANKPAYMENTORDER_CONFIRM_REQUEST: 'BANKPAYMENTORDER_CONFIRM_REQUEST',
  BANKPAYMENTORDER_CONFIRM_SUCCESS: 'BANKPAYMENTORDER_CONFIRM_SUCCESS',
  BANKPAYMENTORDER_CONFIRM_FAILURE: 'BANKPAYMENTORDER_CONFIRM_FAILURE',

  BANKPAYMENTORDER_COMPLETEBANKORDER_REQUEST: 'BANKPAYMENTORDER_COMPLETEBANKORDER_REQUEST',
  BANKPAYMENTORDER_COMPLETEBANKORDER_SUCCESS: 'BANKPAYMENTORDER_COMPLETEBANKORDER_SUCCESS',
  BANKPAYMENTORDER_COMPLETEBANKORDER_FAILURE: 'BANKPAYMENTORDER_COMPLETEBANKORDER_FAILURE',
  //#endregion
};
