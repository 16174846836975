/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardHeader, KTCardBody, DeleteModal } from '../../../components'
import { PageTitle } from '../../../core';
import { KTSVG, SourceType } from '../../../helpers';
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { useDispatch, useSelector } from 'react-redux';
import { categoryActions } from '../../../redux/actions';
import { ListLoading } from '../../../components/Table/ListLoading';

function List() {
  const intl = useIntl()
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({SourceTypeId: null, Id: null});
  const [sourceTypeId, setSourceTypeId] = useState();
  const [modalType, setModalType] = useState();
  const [updateItem, setUpdateItem] = useState();

  const [data, setData] = useState([
    {
      SourceTypeId: SourceType.Staff,
      SourceTypeName: intl.formatMessage({ id: 'MENU.STAFF' }),
      Collapsed: false
    },
    {
      SourceTypeId: SourceType.SaleInvoice,
      SourceTypeName: intl.formatMessage({ id: 'MENU.SALES' }),
      Collapsed: false
    },
    {
      SourceTypeId: SourceType.ExpenseInvoice,
      SourceTypeName: intl.formatMessage({ id: 'MENU.EXPENSES' }),
      Collapsed: false
    },
    {
      SourceTypeId: SourceType.Project,
      SourceTypeName: intl.formatMessage({ id: 'MENU.PROJECTS' }),
      Collapsed: false
    },
    {
      SourceTypeId: SourceType.RealEstate,
      SourceTypeName: intl.formatMessage({ id: 'SOURCETYPE.REALESTATE' }),
      Collapsed: false
    },
    {
      SourceTypeId: SourceType.Company,
      SourceTypeName: intl.formatMessage({ id: 'SOURCETYPE.COMPANY' }),
      Collapsed: false
    },
    {
      SourceTypeId: SourceType.Product,
      SourceTypeName: intl.formatMessage({ id: 'SOURCETYPE.PRODUCT' }),
      Collapsed: false
    },
    {
      SourceTypeId: SourceType.PaymentPlan,
      SourceTypeName: intl.formatMessage({ id: 'SOURCETYPE.PAYMENTPLAN' }),
      Collapsed: false
    }
  ]);
  
  const { category, loading, error, isDeleted, isRedirect, detail } = useSelector(state => state.category);
  const [list, setList] = useState(category)

  const dispatch = useDispatch();

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Name: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      if (modalType === 'add') {
        dispatch(categoryActions.categoryCreate({SourceTypeId: sourceTypeId, Name: values.Name}));
      } else {
        dispatch(categoryActions.categoryUpdate({ Name: values.Name, Id: updateItem.Id }));
      }
      resetForm()
      setShow(false)
    },
  })

  useEffect(() => {
    if (category) setList(category)
  }, [category])
  
  useEffect(() => {
    if (isDeleted || isRedirect) dispatch(categoryActions.categoryGetAll({SourceTypeId: sourceTypeId}));
  }, [isDeleted, isRedirect, detail])

  const handleClickToggle = (index, SourceTypeId) => {
    data[index].Collapsed = !data[index].Collapsed;
    data.map((item, i) => {
      if (i !== index) data[i].Collapsed = false
    })

    setData([...data])
    setList(null)

    dispatch(categoryActions.categoryGetAll({SourceTypeId}));
  }

  const handleClickDelete = (Id, SourceTypeId) => {
    setDeleteItem({SourceTypeId, Id})
    setShowDeleteModal(true)
    setSourceTypeId(SourceTypeId)
  }
  
  const handleDelete = () => {
    dispatch(categoryActions.categoryDelete(deleteItem));
    setShowDeleteModal(false)
  }

  const handleClickAdd = (SourceTypeId) => {
    setShow(true)
    formik.setFieldValue('Name', '')
    setSourceTypeId(SourceTypeId)
    setModalType('add')
  }

  const handleClickUpdate = (item, SourceTypeId) => {
    setUpdateItem({ Name: item.name, Id: item.id })
    setShow(true)
    formik.setFieldValue('Name', item.name)
    setSourceTypeId(SourceTypeId)
    setModalType('update')
  }

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.CATEGORIES' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <div className='row gy-10 gx-xl-10'>
            {
              data?.map((item, index) => {
                return (
                  <div className='col-xl-4' key={index}>
                    <KTCard>
                      <KTCardHeader title={item.SourceTypeName} collapsed={item.Collapsed} onCollapse={() => handleClickToggle(index, item.SourceTypeId)} />

                      <KTCardBody 
                        className='p-9' 
                        collapseble
                        isShow={item.Collapsed}
                      >
                        {!loading && <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 m-0">
                            <tbody>
                              {list?.map((cat, i) => <tr key={i}>
                                <td>{cat.name}</td>
                                <td>
                                  <div className="d-flex justify-content-end flex-shrink-0">
                                    <button 
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                      onClick={() => handleClickUpdate(cat, item.SourceTypeId)}
                                    >
                                      <KTSVG path={'/images/icons/duotune/general/gen055.svg'} className='svg-icon-2' />
                                    </button>
                                    <button 
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                      onClick={() => handleClickDelete(cat.id, item.SourceTypeId)}
                                    >
                                      <KTSVG path={'/images/icons/duotune/general/gen027.svg'} className='svg-icon-2' />
                                    </button>
                                  </div>
                                </td>
                              </tr>)}
                            </tbody>
                          </table>
                        </div>}

                        {!loading && category?.length == 0 && <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({ id: 'NON_RESULT' })}
                        </div>}

                        {loading && <ListLoading />}

                        {!loading && <>
                          <div className='separator mt-5'></div>
                          <div className="btn-group pt-5" role="group">
                            <button 
                              className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success" 
                              onClick={() => handleClickAdd(item.SourceTypeId)}
                            >
                              <KTSVG path={'/images/icons/duotune/arrows/arr075.svg'} className='svg-icon-2' />
                              {intl.formatMessage({ id: 'BUTTON.ADD' })}
                            </button>
                          </div>
                        </>}
                      </KTCardBody>
                    </KTCard>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={showDeleteModal} 
        onClose={() => setShowDeleteModal(false)}
        error={error}
      />

      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShow(false)}
      >
        <div className='modal-content'>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <h5 className='fw-bold m-0'>{modalType === 'add' ? intl.formatMessage({id: 'BUTTON.ADD' }) : intl.formatMessage({id: 'BUTTON.EDIT' })}</h5>
          </div>
          
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <KTCard>
              <KTCardBody className='p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CATEGORY.CREATE.NAME' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      required
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('Name')}
                    />
                    {formik.touched.Name && formik.errors.Name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.Name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
              <div className='d-flex align-items-center me-3'>
                <button className="btn btn-light me-3" onClick={() => setShow(false)}>{intl.formatMessage({id: 'BUTTON.CLOSE' })}</button>
                <button className="btn btn-primary">{modalType === 'add' ? intl.formatMessage({id: 'BUTTON.ADD' }) : intl.formatMessage({id: 'BUTTON.EDIT' })}</button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}
export {List};
