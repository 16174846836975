import { userConstants } from '../constants';
import {
  SearchUserClient,
  GetUserClient,
  DeleteUserClient,
  UpdateUserClient,
  UpdateUserRolesClient,
  CreateUserClient,
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
const searchUserClient = new SearchUserClient(SERVICE_URL);
const getUserClient = new GetUserClient(SERVICE_URL);
const createUserClient = new CreateUserClient(SERVICE_URL);
const deleteUserClient = new DeleteUserClient(SERVICE_URL);
const updateUserClient = new UpdateUserClient(SERVICE_URL);
const updateUserRolesClient = new UpdateUserRolesClient(SERVICE_URL);

export const userActions = {
  userGetAll,
  userGetId,
  userDelete,
  userCreate,
  userUpdate,
  userRolesUpdate,
  userExcel
};

function userGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchUserClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        user => dispatch(success(user)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: userConstants.USER_GETALL_REQUEST } }
  function success(user) { return { type: userConstants.USER_GETALL_SUCCESS, user } }
  function failure(error) { return { type: userConstants.USER_GETALL_FAILURE, error } }
}

function userExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchUserClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: userConstants.USER_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: userConstants.USER_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: userConstants.USER_EXCEL_FAILURE, error } }
}

function userGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getUserClient.get(id)
      .then(
        user => dispatch(success(user)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: userConstants.USER_GETID_REQUEST, id } }
  function success(user) { return { type: userConstants.USER_GETID_SUCCESS, user } }
  function failure(error) { return { type: userConstants.USER_GETID_FAILURE, error } }
}

function userDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteUserClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: userConstants.USER_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: userConstants.USER_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: userConstants.USER_DELETE_FAILURE, error } }
}

function userCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createUserClient.create(params)
      .then(
        user => dispatch(success(user)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: userConstants.USER_CREATE_REQUEST, params } }
  function success(user) { return { type: userConstants.USER_CREATE_SUCCESS, user } }
  function failure(error) { return { type: userConstants.USER_CREATE_FAILURE, error } }
}

function userUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateUserClient.update(params)
      .then(
        user => dispatch(success(user, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: userConstants.USER_UPDATE_REQUEST, params } }
  function success(user, params) { return { type: userConstants.USER_UPDATE_SUCCESS, user, params } }
  function failure(error, params) { return { type: userConstants.USER_UPDATE_FAILURE, error, params } }
}

function userRolesUpdate(params) {
  return dispatch => {
    dispatch(request(params));
    
    updateUserRolesClient.update(params)
      .then(
        user => dispatch(success(user, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: userConstants.USERROLES_UPDATE_REQUEST, params } }
  function success(user, params) { return { type: userConstants.USERROLES_UPDATE_SUCCESS, user, params } }
  function failure(error, params) { return { type: userConstants.USERROLES_UPDATE_FAILURE, error, params } }
}
