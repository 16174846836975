/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { toAbsoluteUrl, KTSVG, SourceType, detailPageUrl, DetailPage } from '../../../helpers'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, KTCardHeader, Timeline, Notes, PaymentStatus, DeleteModal, ArchiveModal, CheckoutDetail, Select, PaymentPlan } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions, lookupActions, checkoutActions } from '../../../redux/actions';
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [checkoutId, setCheckouId] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { detail, isDeleted, isArchived, error } = useSelector(state => state.staff);
  const { accountLookup } = useSelector(state => state.lookup);
  const { checkout, loading } = useSelector(state => state.checkout);
  const { paymentPlan, isDeleted: paymentDelete } = useSelector(state => state.paymentplan);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const validSchema = Yup.object().shape({
    FromAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Amount: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    SalaryId: '',
    PaymentDate: new Date(),
    FromAccountId: '',
    ToAccountId: '',
    Amount: 0,
    Name: '',
    StaffId: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(checkoutActions.checkoutSalaryPayment(values));
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(staffActions.salaryGetId(id));
    dispatch(lookupActions.bankCashAccountGetAll());
  }, [id, checkout, paymentPlan, paymentDelete])

  useEffect(() => {
    formik.setFieldValue('SalaryId', Number(id))
    formik.setFieldValue('StaffId', detail?.staffId)
    formik.setFieldValue('ToAccountId', detail?.staffAccountId)
  }, [detail])

  const handleDelete = () => dispatch(staffActions.salaryDelete({ id }));

  const handleArchive = () => dispatch(staffActions.salaryArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.SALARY' }),
      path: '/staff/salary',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.SALARY.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                          {detail?.projectName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                                <KTSVG
                                  path='/images/icons/nav/Projects.svg'
                                  className='svg-icon svg-icon-2 fs-4 me-1'
                                />
                                {detail?.projectName}
                              </Link>
                            </div>
                          </>}
                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'SALARY.STAFFNAMESURNAME' })}</div>
                          <Link to={detailPageUrl(DetailPage.Staff, detail.staffId)} className='text-dark fw-bold fs-4 text-hover-primary' target='_blank'>{detail.staffNameSurname}</Link>
                        </div>
                        {detail?.progressPaymentDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'SALARY.MONTH' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.progressPaymentDate), { month: 'long' })}</div>
                          </div>
                        </>}
                        {detail?.dueDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENT.DUE.DATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.dueDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENT' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.payment, { style: 'currency', currency: 'try' })}</div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400"> {intl.formatMessage({ id: 'SALARY.BALANCE' })}</div>
                          <div className="fw-bold fs-4">   {intl.formatNumber(detail?.balance, { style: 'currency', currency: 'try' })}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  {detail?.checkouts?.length > 0 && <Tab eventKey="salarygeneral" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-12 order-2 order-lg-1'>
                        <div className='table-responsive'>
                          <table className='table align-middle table-row-dashed gy-5 fs-6 table-striped table-hover'>                           
                            <tbody>
                              {detail?.checkouts.map((item, index) => <tr key={index} role="button" onClick={() => setCheckouId(item.checkoutId)}>
                                <td className='p-5'>
                                  <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-50px me-5'>
                                      <span className='symbol-label bg-light'>
                                        <img
                                          src={toAbsoluteUrl('/images/icons/duotune/arrows/arr078.svg')}
                                          className='h-50'
                                          alt=''
                                        />
                                      </span>
                                    </div>
                                    <div className='d-flex justify-content-start flex-column'>
                                      <div className='text-dark fw-semibold mb-1 fs-6'>
                                        {item.checkoutName}
                                      </div>
                                      <span className='text-muted fw-semibold d-block fs-7'>
                                        {intl.formatDate(new Date(item?.checkoutPaymenDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className='p-5'>
                                  <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-50px me-5'>
                                      <span className='symbol-label bg-light'>
                                        <img
                                          src={toAbsoluteUrl('/images/icons/duotune/finance/fin003.svg')}
                                          className='h-50'
                                          alt=''
                                        />
                                      </span>
                                    </div>
                                    <div className='d-flex justify-content-start flex-column'>
                                      <div className='text-dark fw-semibold mb-1 fs-6'>
                                        {item.fromAccountName}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className='text-end p-5'>
                                  <div className='text-dark fw-semibold d-block mb-1 fs-6 text-uppercase'>
                                    {item.processTypeName}
                                  </div>
                                </td>
                                <td className='text-end p-5'>
                                  <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                    {intl.formatNumber(item.amount, { style: 'currency', currency: 'try' })}
                                  </div>
                                </td>
                              </tr>)}
                            </tbody>                          
                          </table>
                        </div>
                      </div>
                    </div>
                  </Tab>}

                  <Tab eventKey="plans" title={intl.formatMessage({ id: 'SALEINVOICE.CREATE.PLANS' })}>
                    <PaymentPlan SalaryId={id} data={detail?.paymentPlans} />
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>

          {detail && <PaymentStatus
            balance={detail?.balance}
            dueDate={detail?.dueDate}
            payment={detail?.payment}
          />}

          {detail?.paymentStateId === 2 && <KTCard className='mb-5'>
            <KTCardHeader title={intl.formatMessage({ id: 'PAYMENT.ADD' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody
              className='pt-5'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT.DATE' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        {...formik.getFieldProps('PaymentDate')}
                        onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                      />
                    </div>
                    {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.PaymentDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'ACCOUNT' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select
                      data={accountLookup}
                      required
                      {...formik.getFieldProps('FromAccountId')}
                      onChange={item => formik.setFieldValue('FromAccountId', Number(item?.value))}
                    />
                    {formik.touched.FromAccountId && formik.errors.FromAccountId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.FromAccountId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <input
                      type='number'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('Amount')}
                      onFocus={event => event.target.select()}
                    />
                    {formik.touched.Amount && formik.errors.Amount && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.Amount}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPLANATION' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <textarea
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={4}
                      {...formik.getFieldProps('Name')}
                    />
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>}



          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Salary} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.Salary} collapse={false} />
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal
        onClick={handleArchive}
        onOpen={showArchive}
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <CheckoutDetail id={checkoutId} onClose={() => setCheckouId(null)} />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
