/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTCard, KTCardBody, KTCardHeader, Select } from '../../../components';

import { PageTitle } from '../../../core';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, lookupActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')
  const { roleLookup, allProjectLookup, dynamicReportRoleLookup } = useSelector(state => state.lookup);
  const { isRedirect, detail, loading } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.userGetId(id));
    dispatch(lookupActions.allProjectLookup());
    dispatch(lookupActions.allRoleLookup({}));
    dispatch(lookupActions.dynamicReportRoleLookup({}));
  }, [])

  const validSchema = Yup.object().shape({
    FirstName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    LastName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    Id: '',
    FirstName: '',
    LastName: '',
    Roles: [],
    ProjectIds: [],
    DynamicReportIds: []
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      dispatch(userActions.userUpdate(values));
      dispatch(userActions.userRolesUpdate({ 'Id': values.Id, 'Roles': values.Roles }));
    },
  })

  useEffect(() => {
    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);
      if (property == 'projects') {
        detail['projects']?.map((item, index) => {
          for (const invoice in item) {
            const propValArr = invoice.charAt(0).toUpperCase() + invoice.slice(1);
            if (propValArr === 'Id') {
              formik.setFieldValue(`ProjectIds[${index}]`, item[invoice])
            }
          }
        })
      } if (property == 'dynamicReports') {
        detail['dynamicReports']?.map((item, index) => {
          for (const invoice in item) {
            const propValArr = invoice.charAt(0).toUpperCase() + invoice.slice(1);
            if (propValArr === 'Id') {
              formik.setFieldValue(`DynamicReportIds[${index}]`, item[invoice])
            }
          }
        })
      } else {
        for (const values in formValues) {
          if (values === propVal) {
            formik.setFieldValue(propVal, detail[property])
          }
        }
      }
    }
  }, [detail])
  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.USERS' }),
      path: '/admin/users',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/admin/users/detail/${id}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.USERS.EDIT' })}</PageTitle>
      <KTCard>        
        <KTCardBody>
          {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USER.CREATE.FIRSTNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('FirstName')}
                />
                {formik.touched.FirstName && formik.errors.FirstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.FirstName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USER.CREATE.LASTNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('LastName')}
                />
                {formik.touched.LastName && formik.errors.LastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.LastName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'USER.CREATE.EMAIL' })}</label>
              <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.email}</span></div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'USER.CREATE.ROLES' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={roleLookup}
                  {...formik.getFieldProps('Roles')}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={item => {
                    const newData = []
                    item?.map(data => newData.push(data.value))
                    formik.setFieldValue('Roles', newData)
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USER.CREATE.DYNAMICREPORTROLES' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={dynamicReportRoleLookup}
                  {...formik.getFieldProps('DynamicReportIds')}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={item => {
                    const newData = []
                    item?.map(data => newData.push(data.value))
                    formik.setFieldValue('DynamicReportIds', newData)
                  }}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USER.CREATE.PROJECTS' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={allProjectLookup}
                  required
                  {...formik.getFieldProps('ProjectIds')}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={item => {
                    const newData = []
                    item?.map(data => newData.push(data.value))
                    formik.setFieldValue('ProjectIds', newData)
                  }}
                />
              </div>
            </div>
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>                
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
