/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { SourceType, PurchaseProposalStatus, KTSVG, detailPageUrl, DetailPage } from '../../../helpers'
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import * as Yup from 'yup'
import {useFormik} from 'formik'
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, KTCardHeader, Notes, Timeline, DeleteModal, ArchiveModal, Select, PaymentPlan, PaymentStatus } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { proposalActions } from '../../../redux/actions';

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  
  const { detail, isDeleted, isArchived, error, loading } = useSelector(state => state.proposal);

	const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const validSchema = Yup.object().shape({
    ProposalStatusId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    ProposalStatusId: '',
    Id: id,
  })
  
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(proposalActions.proposalUpdateStatus(values));
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(proposalActions.proposalGetId(id));
  }, [])

  useEffect(() => {
    if (detail) formik.setFieldValue('ProposalStatusId', detail?.proposalStatusId)
  }, [detail])

  const handleDelete = () => dispatch(proposalActions.proposalDelete({id}));

  const handleArchive = () => dispatch(proposalActions.proposalArchiving({id}));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.PROPOSAL' }),
      path: '/sales/proposal',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.PROPOSAL.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>                            
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.projectName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                                {detail.projectName}
                                </Link>
                            </div>
                          </>}
                          

                          {detail?.editDate && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/general/gen014.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {intl.formatDate(new Date(detail?.editDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                            </div>
                          </>}

                        </div>                       
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>                    
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROPOSAL.CREATE.COMPANYID' })}</div>
                          <div className="fw-bold fs-4"><Link to={detailPageUrl(DetailPage.CompanyCustomer, detail?.companyId)} className='text-dark text-hover-primary' target='_blank'>{detail?.companyName}</Link></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROPOSAL.CREATE.TOTALPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalPrice, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        {detail?.defermentDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PROPOSAL.CREATE.DEFERMENTDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}                        
                      
                      </div>
                    </div>
                  </div>                                   
                </div>                
                {detail?.terms && <>
                  <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                   {detail?.terms}
                  </div>
                </>}     
              </KTCardBody>
            </KTCard>

            <KTCard> 
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="saleInvoice" title={intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS' })}>
                    {detail?.proposalItems?.length > 0 && <div className='table-responsive'>
                      <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                        <thead>
                          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                            <th>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS.PRODUCTID' })}</th>
                            <th>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS.AMOUNT' })}</th>
                            <th>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS.UNITPRICE' })}</th>
                            <th>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS.TAXID' })}</th>
                            <th>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS.DISCOUNT' })}</th>
                            <th>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS.WITHOLDING' })}</th>
                            <th className='text-end'>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALITEMS.TOTALPRICE' })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail?.proposalItems.map((item, index) => <tr key={index}>
                            <td className='p-5'>
                              <div className="flex-grow-1">
                                <div className="text-dark fw-semibold fs-6">{item.productName}</div>
                                <span className="text-muted d-block fw-semibold">{item.description}</span>
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                                {item.amount} {item.productUnitName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                                {intl.formatNumber(item.unitPrice, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                                {item.taxName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {intl.formatNumber(item.discount, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {intl.formatNumber(item.witholding, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-end'>
                                {intl.formatNumber(item.totalPrice, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                          </tr>)}
                        </tbody>

                        <tfoot>
                          <tr className="border-top border-top-dashed align-middle fs-6 fw-bold text-gray-700">
                            <th colSpan={4}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'PROPOSAL.CREATE.SUBTOTALPRICE' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail?.subTotalPrice, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={4}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'PROPOSAL.CREATE.TOTALDISCOUNT' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail?.totalDiscount, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={4}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'PROPOSAL.CREATE.GROSSTOTALPRICE' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail?.grossTotalPrice, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={4}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'PROPOSAL.CREATE.TOTALTAX' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail?.totalTax, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={4}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'PROPOSAL.CREATE.TOTALWITHOLDING' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail?.totalWitholding, { style: 'currency', currency: 'try' })}</th>
                          </tr>                         
                        </tfoot>
                      </table>
                    </div>}
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}         
        </div>

        <div className='col-xl-3'>
          <KTCard className='mb-5'>
            <KTCardHeader title={intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALSTATUSNAME' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody 
              className='ps-10 pe-10 pt-5 pb-0'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PROPOSAL.CREATE.PROPOSALSTATUSNAME' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select 
                      data={PurchaseProposalStatus} 
                      required
                      {...formik.getFieldProps('ProposalStatusId')}
                      onChange={item => formik.setFieldValue('ProposalStatusId', Number(item?.value))}
                      textType={'intl'}
                    />
                    {formik.touched.ProposalStatusId && formik.errors.ProposalStatusId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.ProposalStatusId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>

          {detail && <KTCard className='mb-5'>
            <KTCardBody className='p-10'>
              <div className='py-1 d-flex justify-content-between align-items-baseline flex-wrap'>
                <span className='fw-bold fs-5 mb-1'>{intl.formatMessage({id: 'PROPOSAL.CREATE.PROPOSALPRICE' })}</span>
                <span className='text-dark fs-1 fw-bold me-2'>{intl.formatNumber(detail?.totalPrice, { style: 'currency', currency: 'try' })}</span>
              </div>

              {detail?.delayDay !== 0 && <div className='d-flex align-items-baseline fw-bold fs-3 mt-5'>
                {detail?.delayDay < 0 ? detail?.delayDay * -1 + intl.formatMessage({ id: 'PROJECT.DAYLEFT' }) : detail?.delayDay + intl.formatMessage({ id: 'PROJECT.DAYPASSED' }) }
              </div>}
            </KTCardBody>
          </KTCard>}

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Proposal} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.Proposal} collapse={false} />
        </div>
      </div>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal 
        onClick={handleArchive} 
        onOpen={showArchive} 
        onClose={() => setShowArchive(false)}
        error={error}
      />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Detail};
