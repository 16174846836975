import { checkConstants } from '../constants';
import {
  //#region Check
  SearchCheckClient,
  GetCheckClient,
  CheckAddCollectionCheckClient,
  CheckAddPaymentCheckClient,
  UpdateCheckClient
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region Check
const searchCheckClient = new SearchCheckClient(SERVICE_URL);
const getCheckClient = new GetCheckClient(SERVICE_URL);
const checkAddCollectionCheckClient = new CheckAddCollectionCheckClient(SERVICE_URL);
const checkAddPaymentCheckClient = new CheckAddPaymentCheckClient(SERVICE_URL);
const updateCheckClient = new UpdateCheckClient(SERVICE_URL);
//#endregion

export const checkActions = {
  //#region Check
  checkGetAll,
  checkGetId,
  checkAddCollection,
  checkAddPayment,
  checkExcel,
  checkUpdate
  //#endregion    
};

//#region Check
function checkGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchCheckClient.search(params.name, params.checkTypeId, params.defermentStartDate, params.defermentEndDate, params.companyId, params.checkStateId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        check => dispatch(success(check)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: checkConstants.CHECK_GETALL_REQUEST } }
  function success(check) { return { type: checkConstants.CHECK_GETALL_SUCCESS, check } }
  function failure(error) { return { type: checkConstants.CHECK_GETALL_FAILURE, error } }
}

function checkExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchCheckClient.excel(params.name, params.checkTypeId, params.defermentStartDate, params.defermentEndDate, params.companyId, params.checkStateId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: checkConstants.CHECK_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: checkConstants.CHECK_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: checkConstants.CHECK_EXCEL_FAILURE, error } }
}

function checkGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getCheckClient.get(id)
      .then(
        check => dispatch(success(check)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: checkConstants.CHECK_GETID_REQUEST, id } }
  function success(check) { return { type: checkConstants.CHECK_GETID_SUCCESS, check } }
  function failure(error) { return { type: checkConstants.CHECK_GETID_FAILURE, error } }
}

function checkAddCollection(params) {
  return dispatch => {
    dispatch(request(params));

    checkAddCollectionCheckClient.create(params)
      .then(
        transfer => dispatch(success(transfer)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: checkConstants.CHECK_ADDCOLLECTION_REQUEST, params } }
  function success(transfer) { return { type: checkConstants.CHECK_ADDCOLLECTION_SUCCESS, transfer } }
  function failure(error) { return { type: checkConstants.CHECK_ADDCOLLECTION_FAILURE, error } }
}

function checkAddPayment(params) {
  return dispatch => {
    dispatch(request(params));

    checkAddPaymentCheckClient.create(params)
      .then(
        transfer => dispatch(success(transfer)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: checkConstants.CHECK_ADDPAYMENT_REQUEST, params } }
  function success(transfer) { return { type: checkConstants.CHECK_ADDPAYMENT_SUCCESS, transfer } }
  function failure(error) { return { type: checkConstants.CHECK_ADDPAYMENT_FAILURE, error } }
}

function checkUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateCheckClient.update(params)
      .then(
        check => dispatch(success(check, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkConstants.CHECK_UPDATE_REQUEST, params } }
  function success(check, params) { return { type: checkConstants.CHECK_UPDATE_SUCCESS, check, params } }
  function failure(error, params) { return { type: checkConstants.CHECK_UPDATE_FAILURE, error, params } }
}
//#endregion
