/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, DeleteModal, DataTables, Category, BankPaymentOrderSendToConfirmation, BankPaymentOrderConfirmation, BankPaymentOrderCompleteBankOrder } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { bankPaymentOrderActions } from '../../../redux/actions';
import { SourceType, KTSVG } from '../../../helpers';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState('sendToConfirmation');
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { detail, isDeleted, error, loading } = useSelector(state => state.bankPaymentOrder);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(bankPaymentOrderActions.bankPaymentOrderGetId(id));
  }, [id])

  const handleDelete = () => dispatch(bankPaymentOrderActions.bankPaymentOrderDelete({ id }));

  useEffect(() => {
    if (detail){
    setTimeout(() => {
      if (detail?.canSendConfirmation) setActiveTab('sendToConfirmation')
      if (detail?.canApprove) setActiveTab('approve')
      if (detail?.canCompleteBankOrder) setActiveTab('completeBankOrder')
    }, 1000);
  }
  }, [detail])

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.CASH.BANKPAYMENTORDER' }),
      path: '/expenses/bank-payment-order',
      isSeparator: false,
      isActive: false,
    }
  ]

  console.log("fıuck", activeTab)

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.CASH.BANKPAYMENTORDER.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 gap-4">

                          {detail?.bankPaymentOrderStateName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <div className='text-gray-400' >
                                <KTSVG
                                  path='/images/icons/nav/Staff.svg'
                                  className='svg-icon svg-icon-2 fs-4 me-1'
                                />
                                {detail?.bankPaymentOrderStateName}
                              </div>                            
                            </div>
                          </>}

                          {detail?.requestedUserNameSurname && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <div className='text-gray-400' >
                                <KTSVG
                                  path='/images/icons/nav/Staff.svg'
                                  className='svg-icon svg-icon-2 fs-4 me-1'
                                />
                                {detail?.requestedUserNameSurname}
                              </div>                            
                            </div>
                          </>}

                          {detail?.approvedUserNameSurname && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <div className='text-gray-400' >
                                <KTSVG
                                  path='/images/icons/nav/Staff.svg'
                                  className='svg-icon svg-icon-2 fs-4 me-1'
                                />
                                {detail?.approvedUserNameSurname}
                              </div>                            
                            </div>
                          </>}

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.BankPaymentOrder} />

                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>

                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(bankPaymentOrderActions.bankPaymentOrderExcel({BankPaymentOrderId: id}, `${intl.formatDate(new Date(), { year: 'numeric', month: 'long', day: 'numeric' })}-${detail?.name}-${intl.formatMessage({ id: 'BC.CASH.BANKPAYMENTORDER' })}`))} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.EXCEL' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.PAYMENTDATE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{detail?.paymentDate ? intl.formatDate(new Date(detail?.paymentDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</span></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.APPROVEDDATE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{detail?.approvedDate ? intl.formatDate(new Date(detail?.approvedDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</span></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.REQUESTEDPAYMENT' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail?.requestedPayment, { style: 'currency', currency: 'try' })}</span></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.APPROVEDPAYMENT' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail?.approvedPayment, { style: 'currency', currency: 'try' })}</span></div>
                        </div>

                        {detail?.bankCashAccountName != null && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKCASHACCOUNTNAME' })}</div>
                            <div className="fw-bold fs-4"><span className="fw-bold fs-6">{detail?.bankCashAccountName}</span></div>
                          </div>
                        </>}
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS' })}>
                    <div className='row p-5'>
                      <div className='col-lg-12 order-2 order-lg-1'>
                        {detail?.bankPaymentOrderItems?.length > 0 &&
                          <DataTables
                            columns={[
                              {
                                title: intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS.NAME' }),
                                prop: 'name',
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS.COMPANYTITLE' }),
                                prop: 'companyTitle',
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS.COMPANYBALANCE' }),
                                prop: 'companyBalance',
                                cell: (row) => intl.formatNumber(row.companyBalance, { style: 'currency', currency: 'try' }),
                                isSortable: true,
                                isFilterable: true,
                                alignment: {
                                  horizontal: 'right'
                                }
                              },
                              {
                                title: intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS.REQUESTEDDATE' }),
                                prop: 'requestedDate',
                                cell: (row) => row?.requestedDate ? intl.formatDate(new Date(row?.requestedDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-',
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS.REQUESTEDPAYMENT' }),
                                prop: 'balance',
                                cell: (row) => intl.formatNumber(row.requestedPayment, { style: 'currency', currency: 'try' }),
                                isSortable: true,
                                isFilterable: true,
                                alignment: {
                                  horizontal: 'right'
                                }
                              },
                              {
                                title: intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS.APPROVEDPAYMENT' }),
                                prop: 'totalPrice',
                                cell: (row) => intl.formatNumber(row.approvedPayment, { style: 'currency', currency: 'try' }),
                                isSortable: true,
                                isFilterable: true,
                                alignment: {
                                  horizontal: 'right'
                                }
                              }]
                            }
                            data={detail?.bankPaymentOrderItems}
                            exportTitle={intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.BANKPAYMENTORDERITEMS' })}
                          />
                        }
                      </div>
                    </div>
                  </Tab>

                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>
          {(detail?.canSendConfirmation || detail?.canApprove || detail?.canCompleteBankOrder) && <KTCard className='mb-5'>
            <KTCardBody className='p-3'>
              <div className='position-relative mb-6'>
                <Tabs
                  defaultActiveKey={activeTab}
                  activeKey={activeTab}
                  fill
                  onSelect={(active) => setActiveTab(active)}
                  className="nav-line-tabs"
                >
                  {detail?.canSendConfirmation && <Tab eventKey="sendToConfirmation" title={intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.SENDTOCONFIRMATION' })} className='p-5'>
                    <BankPaymentOrderSendToConfirmation BankPaymentOrderId={id} BankPaymentOrderUsers={detail?.bankPaymentOrderUsers} />
                  </Tab>}
                  
                  {detail?.canApprove && <Tab eventKey="approve" title={intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.CONFIRM' })} className='p-5'>
                    <BankPaymentOrderConfirmation BankPaymentOrderId={id} />
                  </Tab>}
                  
                  {detail?.canCompleteBankOrder && <Tab eventKey="completeBankOrder" title={intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.COMPLETEBANKORDER' })} className='p-5'>
                    <BankPaymentOrderCompleteBankOrder BankPaymentOrderId={id} />
                  </Tab>}

                </Tabs>
              </div>
            </KTCardBody>
          </KTCard>}

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.BankPaymentOrder} />
        </div>

      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
