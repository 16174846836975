/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { KTSVG, detailPageUrl, DetailPage } from '../../../helpers'

import { KTCard, KTCardBody, KTCardHeader, DeleteModal, ArchiveModal, CheckoutDetail, Select, DataTables, InputPrice } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions, lookupActions, checkoutActions } from '../../../redux/actions';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Dropdown from 'react-bootstrap/Dropdown';

import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);
  const [activeTab, setActiveTab] = useState('input');
  const [checkoutId, setCheckouId] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { detail, accountList, isDeleted, isArchived, error } = useSelector(state => state.account);
  const { accountLookup, projectLookup } = useSelector(state => state.lookup);
  const { transaction, loading, updateDescription } = useSelector(state => state.checkout);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const [stepSchema, setStepSchema] = useState({
    ToAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Amount: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    FromAccountId: Number(id),
    ToAccountId: '',
    PaymentDate: new Date(),
    Amount: 0,
    Rate: 0,
    Description: '',
    ProjectId: '',
    IsOfficial: true
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      if (activeTab === 'transfer') {
        dispatch(checkoutActions.checkoutAccountToAccountTransaction(values));
      } else if (activeTab === 'input') {
        dispatch(checkoutActions.checkoutDebitTransaction(values));
      } else {
        dispatch(checkoutActions.checkoutCreditTransaction(values));
      }

      formik.setFieldValue('Amount', '')
      formik.setFieldValue('Description', '')
    },
  })

  useEffect(() => {
    dispatch(accountActions.accountGetBankCash(id));
    dispatch(accountActions.accountSearchTransactions({ id }));
    dispatch(lookupActions.bankCashAccountGetAll());
    dispatch(lookupActions.projectLookup());
  }, [id, transaction, updateDescription])

  useEffect(() => {
    formik.setFieldValue('FromAccountId', Number(id))
    formik.setFieldValue('ToAccountId', '')
    formik.setFieldValue('PaymentDate', new Date())
    formik.setFieldValue('Amount', '')
    formik.setFieldValue('Rate', 0)
    formik.setFieldValue('Description', '')
    formik.setFieldValue('ProjectId', '')
    formik.setFieldValue('IsOfficial', true)

    if (activeTab === 'transfer') {
      setStepSchema({
        ToAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Amount: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    } else if (activeTab === 'input') {
      formik.setFieldValue('FromAccountId', '')
      formik.setFieldValue('ToAccountId', Number(id))
      setStepSchema({
        ToAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Amount: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    } else if (activeTab === 'output') {
      setStepSchema({
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Amount: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    }
  }, [activeTab])

  const handleDelete = () => dispatch(accountActions.accountDelete({ id }));

  const handleArchive = () => dispatch(accountActions.accountArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.BANKCASE' }),
      path: '/cash/bank-safes',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.BANKCASE.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>

          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name} / {detail.currencyName}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/finance/fin006.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.accountTypeName}
                          </div>

                          {detail?.projectId &&<div className="d-flex align-items-center text-gray-400 me-5 mb-2">                            
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>
                          </div>}

                          {(detail?.accountTypeId === 1 || detail.accountTypeId === 7) && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/finance/fin002.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.iban}
                            </div>
                          </>}
                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">
                       
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.BALANCE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail.balance, { style: 'currency', currency: detail.currencyCode })}</div>
                        </div>


                        {(detail?.accountTypeId === 1 || detail.accountTypeId === 7) && <>

                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.BANKNAME' })}</div>
                            <div className="fw-bold fs-4">{detail.bankName}</div>
                          </div>

                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.BRANCH' })}</div>
                            <div className="fw-bold fs-4">{detail.branch}</div>
                          </div>

                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.ACCOUNTNUMBER' })}</div>
                            <div className="fw-bold fs-4">{detail.accountNumber}</div>
                          </div>                                               
                        </>}

                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>
          
            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.LIST' })}>
                    <div className='row p-5'>
                      <div className='col-lg-12 order-2 order-lg-1'>
                        {accountList?.length > 0 &&
                          <DataTables
                            columns={[
                              {
                                title: intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.FROMACCOUNTNAME' }),
                                prop: 'fromAccountName',
                                cell: (row) => (
                                  <div className='text-dark fw-semibold d-block mb-1 fs-6' onClick={() => setCheckouId(row.id)}>
                                    {row.fromAccountName}
                                    {row.name && <span className="text-muted fw-semibold d-block">{row.name}</span>}
                                  </div>
                                ),
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.PAYMENTDATE' }),
                                prop: 'paymentDate',
                                cell: (row) => intl.formatDate(new Date(row.paymentDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' }),
                                prop: 'createdDate',                              
                                cell: (row) => (
                                  <div className='text-dark fw-semibold d-block mb-1 fs-6' onClick={() => setCheckouId(row.id)}>
                                    {row.createdBy}
                                    {row.createdDate ? <span className="text-muted fw-semibold d-block">{intl.formatDate(new Date(row.createdDate), { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span> : '-'}
                                  </div>
                                ),
                                isSortable: true,
                                isFilterable: true
                              },
                              {
                                title: intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.PROCESSTYPENAME' }),
                                prop: 'processTypeName',
                                isSortable: true,
                                isFilterable: true
                            },
                            {
                              title: intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.ISOFFICIALNAME' }),
                              prop: 'isOfficialName',
                              isSortable: true,
                              isFilterable: true
                            },
                            {
                              title: intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.AMOUNT' }),
                              prop: 'amount',
                              cell: (row) => (
                                <div className='text-dark fw-semibold d-block mb-1 fs-6' onClick={() => setCheckouId(row.id)}>                                 
                                  {intl.formatNumber(row.amount, { style: 'currency', currency: row.currencyCode })}
                                  {row.rate ? <span className="text-muted fw-semibold d-block">{intl.formatNumber(row.rate, { style: 'currency', currency: 'try' })}</span> : ''}
                                </div>
                              ),
                              isSortable: true,
                              isFilterable: true,
                              alignment: {
                                horizontal: 'right'
                              }
                            },
                            {
                              title: intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.BALANCE' }),
                              prop: 'balance',
                              cell: (row) => intl.formatNumber(row.balance, { style: 'currency', currency: row.currencyCode }),
                              isSortable: true,
                              isFilterable: true,
                              alignment: {
                                horizontal: 'right'
                              }
                            }]
                            }
                            data={accountList}
                            onClick={(item) => setCheckouId(item.id)}
                            initialProp={'paymentDate'}
                            exportTitle={intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.LIST' })}
                          />
                        }
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>
          <KTCard>
            <KTCardHeader title={intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.ADD' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody
              className='p-3'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <Tabs
                  className="nav-line-tabs"
                  defaultActiveKey={activeTab}
                  fill
                  onSelect={(active) => setActiveTab(active)}
                >                                

                  <Tab eventKey="input" title={intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.INPUT' })} className='p-5'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.DEBITTRANSACTION.PAYMENTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('PaymentDate')}
                            onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                          />
                        </div>
                        {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.PaymentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.DEBITTRANSACTION.PROJECTID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={projectLookup}
                          required
                          {...formik.getFieldProps('ProjectId')}
                          onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                        />
                        {formik.touched.ProjectId && formik.errors.ProjectId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ProjectId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.DEBITTRANSACTION.ISOFFICIAL' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input 
                            className="form-check-input h-30px w-50px" 
                            type="checkbox" 
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.DEBITTRANSACTION.AMOUNT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <InputPrice 
                          {...formik.getFieldProps('Amount')}
                          suffix={detail?.currencySymbol}
                          onChange={(value) => formik.setFieldValue('Amount', Number(value))} 
                        />
                        {formik.touched.Amount && formik.errors.Amount && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Amount}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {detail?.currencyId != 1 && <>
                      <div className='row mb-6'>
                        <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.DEBITTRANSACTION.RATE' })}</label>
                        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                          <input
                            type='number'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            autoComplete='off'
                            disabled={formik.isSubmitting}
                            {...formik.getFieldProps('Rate')}
                            onFocus={event => event.target.select()}
                          />
                          {formik.touched.Rate && formik.errors.Rate && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.Rate}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>} 
                    
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.DEBITTRANSACTION.DESCRIPTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Description')}
                        />
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="output" title={intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.OUTPUT' })} className='p-5'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.CREDITTRANSACTION.PAYMENTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('PaymentDate')}
                            onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                          />
                        </div>
                        {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.PaymentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.CREDITTRANSACTION.PROJECTID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={projectLookup}
                          required
                          {...formik.getFieldProps('ProjectId')}
                          onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                        />
                        {formik.touched.ProjectId && formik.errors.ProjectId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ProjectId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.CREDITTRANSACTION.ISOFFICIAL' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input 
                            className="form-check-input h-30px w-50px" 
                            type="checkbox" 
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.CREDITTRANSACTION.AMOUNT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <InputPrice 
                          {...formik.getFieldProps('Amount')}
                          suffix={detail?.currencySymbol}
                          onChange={(value) => formik.setFieldValue('Amount', Number(value))} 
                        />
                        {formik.touched.Amount && formik.errors.Amount && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Amount}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {detail?.currencyId != 1 && <>
                      <div className='row mb-6'>
                        <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.DEBITTRANSACTION.RATE' })}</label>
                        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                          <input
                            type='number'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            autoComplete='off'
                            disabled={formik.isSubmitting}
                            {...formik.getFieldProps('Rate')}
                            onFocus={event => event.target.select()}
                          />
                          {formik.touched.Rate && formik.errors.Rate && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.Rate}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>} 

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.CREDITTRANSACTION.DESCRIPTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Description')}
                        />
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>

                  {detail?.currencyId === 1 && <Tab eventKey="transfer" title={intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.TRANSFER' })} className='p-5'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.ACCOUNTTOACCOUNTTRANSACTION.TOACCOUNTID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={accountLookup}
                          {...formik.getFieldProps('ToAccountId')}
                          required
                          onChange={item => formik.setFieldValue('ToAccountId', Number(item?.value))}
                        />
                        {formik.touched.ToAccountId && formik.errors.ToAccountId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ToAccountId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.ACCOUNTTOACCOUNTTRANSACTION.PAYMENTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('PaymentDate')}
                            onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                          />
                        </div>
                        {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.PaymentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECKOUT.ACCOUNTTOACCOUNTTRANSACTION.ISOFFICIAL' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input 
                            className="form-check-input h-30px w-50px" 
                            type="checkbox" 
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.ACCOUNTTOACCOUNTTRANSACTION.AMOUNT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='number'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('Amount')}
                          onFocus={event => event.target.select()}
                        />
                        {formik.touched.Amount && formik.errors.Amount && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Amount}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECKOUT.ACCOUNTTOACCOUNTTRANSACTION.DESCRIPTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Description')}
                        />
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                 </Tab> }                     
                </Tabs>
              </form>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal
        onClick={handleArchive}
        onOpen={showArchive}
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <CheckoutDetail id={checkoutId} onClose={() => setCheckouId(null)} isUpdatable />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
