/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTSVG, TaskState } from '../../../helpers';
import { KTCard, KTCardBody, Select } from '../../../components';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { workplanActions, lookupActions } from '../../../redux/actions';


function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { staffLookup, projectLookup, constructionSiteLookup, blockLookup, plotLookup } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.workplan);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.staffGetAll());
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    TaskStateId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ConstructionSiteId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    BlockId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })), 
  })

  const [formValues] = useState({
    Name: '',
    StartDate: new Date(),
    EndDate: new Date(),
    TaskStateId: null,
    RootTaskId: null,
    Duration: 0,
    StaffId: null,
    Description: "",
    ProjectId: null,
    CompletionRate: 0,
    PlotId: null,
    ConstructionSiteId: '',
    BlockId: '',
    TotalWork: 0,
    TotalPersonDay: 0,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(workplanActions.taskCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.TASK' }),
      path: '/workplan/task',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  useEffect(() => {
    if (formik.values.ProjectId) {
      dispatch(lookupActions.constructionSiteLookup({projectId: formik.values.ProjectId}));
      formik.setFieldValue('ConstructionSiteId', '')
      formik.setFieldValue('BlockId', '')
      formik.setFieldValue('PlotId', '')
    }
  }, [formik.values.ProjectId])

  useEffect(() => {
    if (formik.values.ConstructionSiteId) {
      dispatch(lookupActions.blockLookup({constructionSiteId: formik.values.ConstructionSiteId}));
      formik.setFieldValue('BlockId', '')
      formik.setFieldValue('PlotId', '')
    }
  }, [formik.values.ConstructionSiteId])

  useEffect(() => {
    if (formik.values.BlockId) {
      dispatch(lookupActions.plotLookup({
        projectId: formik.values.ProjectId,
        constructionId: formik.values.ConstructionSiteId,
        blockId: formik.values.BlockId
      }));
      formik.setFieldValue('PlotId', null)
    }
  }, [formik.values.BlockId])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.TASK.ADD' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>

            <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.TASKSTATEID' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={TaskState}
                  required
                  {...formik.getFieldProps('TaskStateId')}
                  onChange={item => formik.setFieldValue('TaskStateId', item.value)}
                  textType={'intl'}
                />
                {formik.touched.TaskStateId && formik.errors.TaskStateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.TaskStateId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.PROJECTID' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={projectLookup}
                  required
                  {...formik.getFieldProps('ProjectId')}
                  onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                />
                {formik.touched.ProjectId && formik.errors.ProjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {formik.values.ProjectId && <>
              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.CONSTRUCTIONSITE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    required
                    data={constructionSiteLookup}
                    {...formik.getFieldProps('ConstructionSiteId')}
                    onChange={item => formik.setFieldValue('ConstructionSiteId', Number(item?.value))}
                  />
                  {formik.touched.ConstructionSiteId && formik.errors.ConstructionSiteId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ConstructionSiteId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {formik.values.ConstructionSiteId && <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.BLOCK' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    required
                    data={blockLookup}
                    {...formik.getFieldProps('BlockId')}
                    onChange={item => formik.setFieldValue('BlockId', Number(item?.value))}
                  />
                  {formik.touched.BlockId && formik.errors.BlockId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.BlockId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div> }

              {formik.values.BlockId && <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.PLOTID' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={plotLookup}
                    {...formik.getFieldProps('PlotId')}
                    onChange={item => formik.setFieldValue('PlotId', Number(item?.value))}
                  />                  
                </div>
              </div>}

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.STARTDATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      {...formik.getFieldProps('StartDate')}
                      onChange={([date]) => formik.setFieldValue('StartDate', date)}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.ENDDATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      {...formik.getFieldProps('EndDate')}
                      onChange={([date]) => formik.setFieldValue('EndDate', date)}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.STAFFID' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={staffLookup}
                    {...formik.getFieldProps('StaffId')}
                    onChange={item => formik.setFieldValue('StaffId', Number(item?.value))}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.DESCRIPTION' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <textarea
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    rows={4}
                    {...formik.getFieldProps('Description')}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.DURATION' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='number'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Duration')}
                    onFocus={event => event.target.select()}
                  />
                </div>
              </div>             


              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.TOTALPERSONDAY' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='number'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('TotalPersonDay')}
                    onFocus={event => event.target.select()}
                  />
                </div>
              </div>

              
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.TOTALWORK' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='number'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('TotalWork')}
                    onFocus={event => event.target.select()}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'TASK.CREATE.COMPLETIONRATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='number'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('CompletionRate')}
                    onFocus={event => event.target.select()}
                  />
                </div>
              </div>

              <div className='row pt-15'>
                <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                  <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </> }
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
