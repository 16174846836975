/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { KTSVG } from '../../../helpers';
import { KTCard, KTCardBody, Select, InputPrice } from '../../../components';
import { PageTitle } from '../../../core';

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, paymentRequestActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { PaymentRequestType } = useSelector(state => state.lookup);
  const { isRedirect, detail, loading } = useSelector(state => state.paymentRequest);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(lookupActions.basicLookup('PaymentRequestType'));
    dispatch(paymentRequestActions.paymentRequestGetId(id));
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    CompanyTitle: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    CompanyBalance: Yup.number().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Iban: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    RequestedPayment: Yup.number().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    RequestedDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PaymentRequestTypeId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: '',
    Name: '',
    CompanyTitle: '',
    CompanyBalance: 0,
    IdentityNumber: '',
    TaxNumber: '',
    Iban: '',
    RequestedPayment: 0,
    RequestedDate: new Date(),
    PaymentRequestTypeId: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      dispatch(paymentRequestActions.paymentRequestUpdate(values));
    }
  })

  useEffect(() => {
    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);

      for (const values in formValues) {
        if (values === propVal) formik.setFieldValue(propVal, detail[property])
      }
    }
  }, [detail])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.PAYMENTREQUEST' }),
      path: '/expenses/payment-request',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/expenses/payment-request/detail/${id}`,
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.PAYMENTREQUEST.EDIT' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>            

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.COMPANYTITLE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('CompanyTitle')}
                />
                {formik.touched.CompanyTitle && formik.errors.CompanyTitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.CompanyTitle}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>              

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.COMPANYBALANCE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('CompanyBalance')} 
                  onChange={(value) => formik.setFieldValue('CompanyBalance', Number(value))} 
                />
                {formik.touched.CompanyBalance && formik.errors.CompanyBalance && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.CompanyBalance}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.IDENTITYNUMBER' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  maxLength={11}
                  {...formik.getFieldProps('IdentityNumber')}
                />
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.TAXNUMBER' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('TaxNumber')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.IBAN' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Iban')}
                />

                {formik.touched.Iban && formik.errors.Iban && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Iban}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.REQUESTEDPAYMENT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('RequestedPayment')} 
                  onChange={(value) => formik.setFieldValue('RequestedPayment', Number(value))} 
                />

                {formik.touched.RequestedPayment && formik.errors.RequestedPayment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.RequestedPayment}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.REQUESTEDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('RequestedDate')}
                    onChange={([date]) => formik.setFieldValue('RequestedDate', date)}
                  />
                </div>
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.CREATE.PAYMENTREQUESTTYPEID' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={PaymentRequestType}
                  required
                  {...formik.getFieldProps('PaymentRequestTypeId')}
                  onChange={item => formik.setFieldValue('PaymentRequestTypeId', Number(item?.value))}
                />

                {formik.touched.PaymentRequestTypeId && formik.errors.PaymentRequestTypeId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.PaymentRequestTypeId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
