import { notificationConstants } from '../constants';
import {
  //#region Notification
  DashboardNotificationClient,
  SearchNotificationClient,
  CompleteNotificationClient
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region Check
const dashboardNotificationClient = new DashboardNotificationClient(SERVICE_URL);
const searchNotificationClient = new SearchNotificationClient(SERVICE_URL);
const completeNotificationClient = new CompleteNotificationClient(SERVICE_URL);
//#endregion

export const notificationActions = {
  //#region Check
  notificationGetDashboard,
  notificationGetAll,
  notificationExcel,
  notificationComplete
  //#endregion    
};

//#region Check
function notificationGetDashboard() {
  return dispatch => {
    dispatch(request());

    dashboardNotificationClient.get()
      .then(
        notificationDashboard => dispatch(success(notificationDashboard)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: notificationConstants.NOTIFICATION_GETDASHBOARD_REQUEST } }
  function success(notificationDashboard) { return { type: notificationConstants.NOTIFICATION_GETDASHBOARD_SUCCESS, notificationDashboard } }
  function failure(error) { return { type: notificationConstants.NOTIFICATION_GETDASHBOARD_FAILURE, error } }
}

function notificationGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchNotificationClient.search(params.content, params.notificationTypeId, params.isCompleted, params.items_per_page, params.page, params.order, params.sort)
      .then(
        notification => dispatch(success(notification)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: notificationConstants.NOTIFICATION_GETALL_REQUEST } }
  function success(notification) { return { type: notificationConstants.NOTIFICATION_GETALL_SUCCESS, notification } }
  function failure(error) { return { type: notificationConstants.NOTIFICATION_GETALL_FAILURE, error } }
}

function notificationExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchNotificationClient.excel(params.content, params.notificationTypeId, params.isCompleted, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: notificationConstants.NOTIFICATION_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: notificationConstants.NOTIFICATION_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: notificationConstants.NOTIFICATION_EXCEL_FAILURE, error } }
}

function notificationComplete(id) {
  return dispatch => {
    dispatch(request());

    completeNotificationClient.complete(id)
      .then(
        complete => dispatch(success(complete)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: notificationConstants.NOTIFICATION_COMPLETE_REQUEST } }
  function success(complete) { return { type: notificationConstants.NOTIFICATION_COMPLETE_SUCCESS, complete } }
  function failure(error) { return { type: notificationConstants.NOTIFICATION_COMPLETE_FAILURE, error } }
}

//#endregion
