/* eslint-disable no-unused-vars */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import PropTypes from 'prop-types';

const ChartDonut = ({className, backGroundColor, chartHeight, options, title, data, categories, legend, tooltip}) => {
  const chartRef = useRef(null)

  const defaultOption = {
    series: data,
    labels: categories,
    chart: {
      type: 'donut',
      height: chartHeight,
    },
    legend: {
      show: legend,
      position: 'bottom',
    },
    tooltip,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
      }
    }]
  }

  const chartOptions = { ...defaultOption, ...options }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions)
    if (chart) {
      chart.render()
    }

    return chart;
  }

  return (
    <div className={`card ${className} theme-dark-bg-body`} style={{backgroundColor: backGroundColor}}>
      <div className='card-body d-flex flex-column'>
        <div className='d-flex flex-column flex-grow-1'>
          <span className='text-dark text-hover-primary fw-bolder fs-3'>{title}</span>

          <div
            ref={chartRef}
            className='mixed-widget-13-chart'
            style={{height: chartHeight, minHeight: chartHeight}}
          ></div>
        </div>
      </div>
    </div>
  )
  
}

ChartDonut.propTypes = {
	title: PropTypes.string,
	labelColor: PropTypes.string,
	strokeColor: PropTypes.string,
  categories: PropTypes.array,
  data: PropTypes.array,
  legend: PropTypes.bool,
  tooltip: PropTypes.any,
};

ChartDonut.defaultProps = {
	categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
	data: [15, 25, 15, 40, 20, 50],
  labelColor: '#3F4254',
  strokeColor: '#E4E6EF',
  legend: true,
  tooltip: {
    y: {
      formatter: (val) => {
        return val
      }
    }
  }
};

export {ChartDonut}
