import { paymentplanConstants } from '../constants';
import {
  //#region PaymentPlan
  CreatePaymentPlanClient,
  DeletePaymentPlanClient,
  UpdatePaymentPlanClient,
  SearchPaymentPlanUnscheduledClient,
  //#endregion

  //#region Expense Invoice
  SearchPaymentPlanForExpenseInvoiceClient,
  CreateExpensePaymentPlanClient,
  //#endregion

  //#region Sale Invoice
  SearchPaymentPlanForSaleInvoiceClient,
  CreateSalePaymentPlanClient,
  //#endregion
  
  //#region Expense & Sale Invoice
  GetPaymentPlanClient,
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

const createPaymentPlanClient = new CreatePaymentPlanClient(SERVICE_URL);
const deletePaymentPlanClient = new DeletePaymentPlanClient(SERVICE_URL);
const updatePaymentPlanClient = new UpdatePaymentPlanClient(SERVICE_URL);
const searchPaymentPlanUnscheduledClient = new SearchPaymentPlanUnscheduledClient(SERVICE_URL);

const searchPaymentPlanForExpenseInvoiceClient = new SearchPaymentPlanForExpenseInvoiceClient(SERVICE_URL);
const createExpensePaymentPlanClient = new CreateExpensePaymentPlanClient(SERVICE_URL);

const searchPaymentPlanForSaleInvoiceClient = new SearchPaymentPlanForSaleInvoiceClient(SERVICE_URL);
const createSalePaymentPlanClient = new CreateSalePaymentPlanClient(SERVICE_URL);

const getPaymentPlanClient = new GetPaymentPlanClient(SERVICE_URL);

export const paymentplanActions = {  
  //#region PaymentPlan
  paymentPlanCreate,
  paymentPlanDelete,
  paymentPlanUpdate,
  paymentPlanUnscheduled,
  //#endregion

  //#region Expense Invoice
  paymentPlanExpenseInvoiceGetAll,
  paymentPlanExpenseInvoiceExcel,
  paymentPlanExpenseInvoiceCreate,
  //#endregion

  //#region Sale Invoice
  paymentPlanSaleInvoiceGetAll,
  paymentPlanSaleInvoiceExcel,
  paymentPlanSaleInvoiceCreate,
  //#endregion  
  
  //#region Expense & Sale Invoice
  paymentPlanExpenseSaleInvoiceGetId
  //#endregion    
};

//#region PaymentPlan
function paymentPlanCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createPaymentPlanClient.create(params)
      .then(
        paymentPlan => dispatch(success(paymentPlan)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentplanConstants.PAYMENTPLAN_CREATE_REQUEST, params } }
  function success(paymentPlan) { return { type: paymentplanConstants.PAYMENTPLAN_CREATE_SUCCESS, paymentPlan } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_CREATE_FAILURE, error } }
}

function paymentPlanDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deletePaymentPlanClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentplanConstants.PAYMENTPLAN_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: paymentplanConstants.PAYMENTPLAN_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_DELETE_FAILURE, error } }
}

function paymentPlanUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updatePaymentPlanClient.update(params)
      .then(
        paymentPlan => dispatch(success(paymentPlan, params)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentplanConstants.PAYMENTPLAN_UPDATE_REQUEST, params } }
  function success(paymentPlan, params) { return { type: paymentplanConstants.PAYMENTPLAN_UPDATE_SUCCESS, paymentPlan, params } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_UPDATE_FAILURE, error } }
}

function paymentPlanUnscheduled(params) {
  return dispatch => {
    dispatch(request());
    searchPaymentPlanUnscheduledClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        unscheduled => dispatch(success(unscheduled)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: paymentplanConstants.PAYMENTPLAN_UNSCHEDULED_GETALL_REQUEST } }
  function success(unscheduled) { return { type: paymentplanConstants.PAYMENTPLAN_UNSCHEDULED_GETALL_SUCCESS, unscheduled } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_UNSCHEDULED_GETALL_FAILURE, error } }
}
//#endregion

//#region Expense Invoice
function paymentPlanExpenseInvoiceGetAll(params) {
  return dispatch => {
    dispatch(request());
    searchPaymentPlanForExpenseInvoiceClient.search(params.name, params.startDate, params.endDate, params.projectId, params.companyId, params.paymentPlanTypeId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_GETALL_REQUEST } }
  function success(expenseinvoice) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_GETALL_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_GETALL_FAILURE, error } }
}

function paymentPlanExpenseInvoiceExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchPaymentPlanForExpenseInvoiceClient.excel(params.name, params.startDate, params.endDate, params.projectId, params.companyId, params.paymentPlanTypeId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_EXCEL_FAILURE, error } }
}

function paymentPlanExpenseInvoiceCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createExpensePaymentPlanClient.create(params)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_CREATE_REQUEST, params } }
  function success(expenseinvoice) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_CREATE_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSEINVOICE_CREATE_FAILURE, error } }
}
//#endregion

//#region Sale Invoice
function paymentPlanSaleInvoiceGetAll(params) {
  return dispatch => {
    dispatch(request());
    searchPaymentPlanForSaleInvoiceClient.search(params.name, params.startDate, params.endDate, params.projectId, params.companyId, params.paymentPlanTypeId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        saleinvoice => dispatch(success(saleinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_GETALL_REQUEST } }
  function success(saleinvoice) { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_GETALL_SUCCESS, saleinvoice } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_GETALL_FAILURE, error } }
}

function paymentPlanSaleInvoiceExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchPaymentPlanForSaleInvoiceClient.excel(params.name, params.startDate, params.endDate, params.projectId, params.companyId, params.paymentPlanTypeId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_EXCEL_FAILURE, error } }
}

function paymentPlanSaleInvoiceCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createSalePaymentPlanClient.create(params)
      .then(
        saleinvoice => dispatch(success(saleinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_CREATE_REQUEST, params } }
  function success(saleinvoice) { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_CREATE_SUCCESS, saleinvoice } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_SALEINVOICE_CREATE_FAILURE, error } }
}
//#endregion

//#region Expense & Sale Invoice
function paymentPlanExpenseSaleInvoiceGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getPaymentPlanClient.get(id)
      .then(
        expensesaleinvoice => dispatch(success(expensesaleinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSESALEINVOICE_GETID_REQUEST, id } }
  function success(expensesaleinvoice) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSESALEINVOICE_GETID_SUCCESS, expensesaleinvoice } }
  function failure(error) { return { type: paymentplanConstants.PAYMENTPLAN_EXPENSESALEINVOICE_GETID_FAILURE, error } }
}
//#endregion
