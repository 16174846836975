import { officialConstants } from '../constants';
import {
  //#region OfficialExpense
  CreateOfficialExpenseClient,
  DeleteOfficialExpenseClient,
  UpdateOfficialExpenseClient,
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

const createOfficialExpenseClient = new CreateOfficialExpenseClient(SERVICE_URL);
const deleteOfficialExpenseClient = new DeleteOfficialExpenseClient(SERVICE_URL);
const updateOfficialExpenseClient = new UpdateOfficialExpenseClient(SERVICE_URL);

export const officialActions = {  
  //#region OfficialExpense
  officialExpenseCreate,
  officialExpenseDelete,
  officialExpenseUpdate,
  //#endregion

};

//#region OfficialExpense
function officialExpenseCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createOfficialExpenseClient.create(params?.ExpenseInvoiceId, params?.Name, params?.InvoiceNumber, params?.TotalPrice, params?.TotalTax, params?.TotalWithHolding, params?.OfficialExpenseFile, params?.InvoiceDate)
      .then(
        officialExpense => dispatch(success(officialExpense)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: officialConstants.OFFICIALEXPENSE_CREATE_REQUEST, params } }
  function success(officialExpense) { return { type: officialConstants.OFFICIALEXPENSE_CREATE_SUCCESS, officialExpense } }
  function failure(error) { return { type: officialConstants.OFFICIALEXPENSE_CREATE_FAILURE, error } }
}

function officialExpenseDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteOfficialExpenseClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: officialConstants.OFFICIALEXPENSE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: officialConstants.OFFICIALEXPENSE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: officialConstants.OFFICIALEXPENSE_DELETE_FAILURE, error } }
}

function officialExpenseUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateOfficialExpenseClient.update(params)
      .then(
        officialExpense => dispatch(success(officialExpense, params)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: officialConstants.OFFICIALEXPENSE_UPDATE_REQUEST, params } }
  function success(officialExpense, params) { return { type: officialConstants.OFFICIALEXPENSE_UPDATE_SUCCESS, officialExpense, params } }
  function failure(error) { return { type: officialConstants.OFFICIALEXPENSE_UPDATE_FAILURE, error } }
}
//#endregion
