import { profileConstants } from '../constants';
import {
  UserInfoMetaClient,
  GetUserProfileClient,
  UpdateUserProfileClient
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
const userInfoMetaClient = new UserInfoMetaClient(SERVICE_URL);
const getUserProfileClient = new GetUserProfileClient(SERVICE_URL);
const updateUserProfileClient = new UpdateUserProfileClient(SERVICE_URL);

export const profileActions = {
  profileGetInfo,
  userProfileGet,
  userProfileUpdate
};

function profileGetInfo() {
  return dispatch => {
    dispatch(request());

    userInfoMetaClient.info()
      .then(
        info => dispatch(success(info)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: profileConstants.PROFILE_GETINFO_REQUEST } }
  function success(info) { return { type: profileConstants.PROFILE_GETINFO_SUCCESS, info } }
  function failure(error) { return { type: profileConstants.PROFILE_GETINFO_FAILURE, error } }
}

function userProfileGet() {
  return dispatch => {
    dispatch(request());

    getUserProfileClient.get()
      .then(
        userProfile => dispatch(success(userProfile)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: profileConstants.USERPROFILE_GETINFO_REQUEST } }
  function success(userProfile) { return { type: profileConstants.USERPROFILE_GETINFO_SUCCESS, userProfile } }
  function failure(error) { return { type: profileConstants.USERPROFILE_GETINFO_FAILURE, error } }
}

function userProfileUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateUserProfileClient.update(params)
      .then(
        userProfile => dispatch(success(userProfile, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: profileConstants.USERPROFILE_UPDATE_REQUEST, params } }
  function success(userProfile, params) { return { type: profileConstants.USERPROFILE_UPDATE_SUCCESS, userProfile, params } }
  function failure(error, params) { return { type: profileConstants.USERPROFILE_UPDATE_FAILURE, error, params } }
}
