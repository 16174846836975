/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import classNames from 'classnames'
import Dropdown from 'react-bootstrap/Dropdown';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTSVG, InvoiceType, SalePaymentStatus, TaxType, WitholdingType } from '../../../helpers';
import { KTCard, KTCardBody, Select, SelectCreatable, InputPrice } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, saleinvoiceActions, productActions, categoryActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { productLookup, projectLookup, companyLookup, warehouseLookup, accountLookup, ProductUnit, DiscountType, InvoiceKind } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.saleinvoice);
  const { detail: product, isRedirect: createProduct } = useSelector(state => state.product);
  const { category } = useSelector((state) => state.category)
  const dispatch = useDispatch();
  const [changeItem, setChangeItem] = useState();
  const [changeData, setChangeData] = useState();
  const [changeProduct, setChangeProduct] = useState();
  const [createItem, setCreateItem] = useState();
  const [visibiltyItem, setVisibiltyItem] = useState([{
    Discount: false,
    Description: false,
    WitholdingRate: false
  }]);

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.allProductLookup());
    dispatch(lookupActions.warehouseLookup());
    dispatch(lookupActions.bankCashAccountGetAll());
    dispatch(lookupActions.basicLookup('ProductUnit'));
    dispatch(lookupActions.basicLookup('DiscountType'));
    dispatch(lookupActions.basicLookup('InvoiceKind'));
    dispatch(categoryActions.categoryGetAll({ SourceTypeId: 7 }))
  }, [])

  const [stepSchema, setStepSchema] = useState({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    InvoiceKindId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    SaleInvoiceItems: Yup.array().of(Yup.object().shape({
      ProductId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      Amount: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      UnitPrice: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      ProductUnitId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      TaxId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    })
    )
  })

  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    Name: '',
    InvoiceTypeId: 2,
    ProjectId: null,
    CompanyId: null,
    EditDate: new Date(),
    DefermentDate: new Date(),
    InvoiceNumber: '',
    SubTotalPrice: 0,
    TotalTax: 0,
    TotalDiscount: 0,
    GrossTotalPrice: 0,
    TotalWitholding: 0,
    TotalPrice: 0,
    SalePaymentStatusId: 1,
    ToAccountId: null,
    CheckoutName: null,
    PaymentDate: new Date(),
    CategoryId: null,
    InvoiceKindId: '',
    SaleInvoiceItems: [{
      ProductId: null,
      WarehouseId: null,
      Amount: 1,
      UnitPrice: 0,
      ProductUnitId: 1,
      TaxId: 6,
      TotalTax: null,
      SubTotalPrice: 0,
      TotalPrice: 0,
      Description: null,
      DiscountTypeId: 1,
      DiscountRate: 0,
      Discount: null,
      WitholdingRateId: null,
      Witholding: null,
    }],
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(saleinvoiceActions.saleinvoiceCreate(values));
      resetForm()
    },
  })

  useEffect(() => {
    if (formik.values.SalePaymentStatusId == 1) {
      setStepSchema({
        Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        InvoiceKindId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        SaleInvoiceItems: Yup.array().of(Yup.object().shape({
          ProductId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          Amount: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          UnitPrice: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          ProductUnitId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          TaxId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        })
        )
      })
    } else {
      setStepSchema({
        Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ToAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        CheckoutName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        InvoiceKindId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        SaleInvoiceItems: Yup.array().of(Yup.object().shape({
          ProductId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          Amount: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          UnitPrice: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          ProductUnitId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
          TaxId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        })
        )
      })
    }
  }, [formik.values.SalePaymentStatusId])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.SALESINVOICES' }),
      path: '/sales/sales-invoices',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  const onChangeItem = (target, value, index) => {
    setChangeItem({ index, target })
    formik.setFieldValue(`SaleInvoiceItems[${index}][${target}]`, value);
  }

  const sumArrayItem = (item) => {
    return formik.values.SaleInvoiceItems.reduce((a, v) => a = a + v[item], 0)
  }

  useEffect(() => {
    const saleInvoiceItems = formik.values.SaleInvoiceItems[changeItem?.index];

    if (saleInvoiceItems) {
      const subTotalPrice = saleInvoiceItems.UnitPrice * saleInvoiceItems.Amount;
      const discount = saleInvoiceItems.DiscountTypeId == 1 ? ((subTotalPrice * saleInvoiceItems.DiscountRate) / 100) : saleInvoiceItems.DiscountRate;
      const tax = TaxType.find(item => item.id == saleInvoiceItems.TaxId)
      const totalTax = ((subTotalPrice - discount) * (tax?.value || 0)) / 100;
      const witholdingRate = WitholdingType.find(item => item.id == saleInvoiceItems.WitholdingRateId);
      const witholding = (totalTax * (witholdingRate?.value || 0)) / 100;
      const totalPrice = (subTotalPrice + totalTax) - (witholding + discount);

      formik.setFieldValue(`SaleInvoiceItems[${changeItem?.index}].SubTotalPrice`, subTotalPrice);
      formik.setFieldValue(`SaleInvoiceItems[${changeItem?.index}].Discount`, discount);
      formik.setFieldValue(`SaleInvoiceItems[${changeItem?.index}].TotalTax`, totalTax);
      formik.setFieldValue(`SaleInvoiceItems[${changeItem?.index}].Witholding`, witholding);
      formik.setFieldValue(`SaleInvoiceItems[${changeItem?.index}].TotalPrice`, totalPrice);

      setChangeData(totalPrice)
    }

  }, [changeItem])

  useEffect(() => {
    formik.setFieldValue('SubTotalPrice', sumArrayItem('SubTotalPrice'));
    formik.setFieldValue('TotalTax', sumArrayItem('TotalTax'));
    formik.setFieldValue('TotalDiscount', sumArrayItem('Discount'));
    formik.setFieldValue('GrossTotalPrice', (sumArrayItem('SubTotalPrice') - sumArrayItem('Discount')));
    formik.setFieldValue('TotalWitholding', sumArrayItem('Witholding'));
    formik.setFieldValue('TotalPrice', sumArrayItem('TotalPrice'));
  }, [changeData])

  const onClickNewItem = (arrayHelpers) => {
    arrayHelpers.push({
      ProductId: null,
      WarehouseId: null,
      Amount: 1,
      UnitPrice: 0,
      ProductUnitId: 1,
      TaxId: 6,
      TotalTax: null,
      SubTotalPrice: 0,
      TotalPrice: 0,
      Description: null,
      DiscountTypeId: 1,
      DiscountRate: 0,
      Discount: null,
      WitholdingRateId: null,
      Witholding: null,
    })
    setVisibiltyItem([...visibiltyItem, {
      Discount: false,
      Description: false,
      WitholdingRate: false
    }])
  }

  const onClickVisibityItem = (item, index) => {
    const newArr = [...visibiltyItem]
    newArr[index][item] = !newArr[index][item];
    setVisibiltyItem(newArr)
  }

  const onChangeProduct = (item, index) => {
    formik.setFieldValue(`SaleInvoiceItems[${index}].ProductId`, Number(item?.value))
    setChangeProduct(index)
    dispatch(productActions.productGetInfo({ Id: item?.value }));
  }

  useEffect(() => {
    if (product) {
      formik.setFieldValue(`SaleInvoiceItems[${changeProduct}].ProductUnitId`, product?.productUnitId);
      formik.setFieldValue(`SaleInvoiceItems[${changeProduct}].UnitPrice`, product?.baseSalePrice);
      formik.setFieldValue(`SaleInvoiceItems[${changeProduct}].TaxId`, product?.taxId);
      setChangeItem({ index: changeProduct, target: 'UnitPrice' })
    }
  }, [product])

  const onClickFastProduct = (Name, Index) => {
    dispatch(productActions.productCreateFast({ Name }));
    setCreateItem({ Name, Index })
  }

  useEffect(() => {
    if (createProduct) dispatch(lookupActions.allProductLookup());
  }, [createProduct])

  useEffect(() => {
    if (productLookup && createProduct) {
      formik.setFieldValue(`SaleInvoiceItems[${createItem?.Index}].ProductId`, createProduct.id);
    }
  }, [productLookup, createProduct])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.SALESINVOICES.ADD' })}</PageTitle>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <KTCard>
            <KTCardBody>
              <FormikProvider value={formik}>
                <form className='form' onSubmit={formik.handleSubmit} noValidate>

                  <div className='row mb-6'>
                    <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.NAME' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        required
                        disabled={formik.isSubmitting}
                        {...formik.getFieldProps('Name')}
                      />
                      {formik.touched.Name && formik.errors.Name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.Name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.CATEGORY' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={category}
                        {...formik.getFieldProps('CategoryId')}
                        onChange={item => formik.setFieldValue('CategoryId', Number(item?.value))}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.COMPANYID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={companyLookup}
                        required
                        {...formik.getFieldProps('CompanyId')}
                        onChange={item => formik.setFieldValue('CompanyId', Number(item?.value))}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.PROJECTID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={projectLookup}
                        required
                        {...formik.getFieldProps('ProjectId')}
                        onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                      />
                      {formik.touched.ProjectId && formik.errors.ProjectId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.ProjectId}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.INVOICEKINDNAME' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <Select
                        data={InvoiceKind}
                        required
                        {...formik.getFieldProps('InvoiceKindId')}
                        onChange={item => formik.setFieldValue('InvoiceKindId', Number(item?.value))}
                      />
                      {formik.touched.InvoiceKind && formik.errors.InvoiceKind && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.InvoiceKind}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.INVOICETYPEID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container d-flex gap-10'>
                      {
                        InvoiceType.map((item, index) => {
                          return (
                            <div className="form-check form-check-custom form-check-solid form-check-sm" key={index}>
                              <input
                                className="form-check-input"
                                name='InvoiceTypeId'
                                type="radio"
                                value={item.Id}
                                id={`InvoiceTypeId-${index}`}
                                onChange={() => formik.setFieldValue("InvoiceTypeId", item.Id)}
                                defaultChecked={item.Id === 2}
                              />
                              <label className="form-check-label" htmlFor={`InvoiceTypeId-${index}`}>
                                {intl.formatMessage({ id: item.Name })}
                              </label>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEPAYMENTSTATUSID' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container d-flex gap-10'>
                      {
                        SalePaymentStatus.map((item, index) => {
                          return (
                            <div className="form-check form-check-custom form-check-solid form-check-sm" key={index}>
                              <input
                                className="form-check-input"
                                name='SalePaymentStatusId'
                                type="radio"
                                value={item.Id}
                                id={`SalePaymentStatusId-${index}`}
                                defaultChecked={item.Id === 1}
                                onChange={() => formik.setFieldValue("SalePaymentStatusId", item.Id)}
                              />
                              <label className="form-check-label" htmlFor={`SalePaymentStatusId-${index}`}>
                                {intl.formatMessage({ id: item.Name })}
                              </label>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>

                  {formik.values.SalePaymentStatusId == 1 && <>
                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.EDITDATE' })}</label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('EditDate')}
                            onChange={([date]) => formik.setFieldValue('EditDate', date)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.DEFERMENTDATE' })}</label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('DefermentDate')}
                            onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
                          />
                        </div>
                      </div>
                    </div>
                  </>}

                  {formik.values.SalePaymentStatusId == 2 && <>
                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.PAYMENTDATE' })}</label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            required
                            {...formik.getFieldProps('PaymentDate')}
                            onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                          />
                        </div>
                        {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.PaymentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOACCOUNTID' })}</label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <Select
                          data={accountLookup}
                          required
                          {...formik.getFieldProps('ToAccountId')}
                          onChange={item => formik.setFieldValue('ToAccountId', Number(item?.value))}
                        />
                        {formik.touched.ToAccountId && formik.errors.ToAccountId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ToAccountId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.CHECKOUTNAME' })}</label>
                      <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                        <textarea
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          required
                          rows={4}
                          {...formik.getFieldProps('CheckoutName')}
                        />
                        {formik.touched.CheckoutName && formik.errors.CheckoutName && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.CheckoutName}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>}

                  <div className='row mb-6'>
                    <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.INVOICENUMBER' })}</label>
                    <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                        {...formik.getFieldProps('InvoiceNumber')}
                      />
                    </div>
                  </div>

                  <FieldArray
                    name="SaleInvoiceItems"
                    render={(arrayHelpers) => (
                      <>
                        <div className="table-responsive">
                          <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" >
                            <thead>
                              <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.PRODUCTID' })}</th>
                                {formik.values.InvoiceTypeId === 1 && <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.WAREHOUSEID' })}</th>}
                                <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.AMOUNT' })}</th>
                                <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.PRODUCTUNITID' })}</th>
                                <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.UNITPRICE' })}</th>
                                <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.TAXID' })}</th>
                                <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.TOTALPRICE' })}</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>

                              {formik.values.SaleInvoiceItems.map((item, index) => (
                                <tr className="border-bottom border-bottom-dashed" key={index}>
                                  <td className="pe-7 ps-1">
                                    <SelectCreatable
                                      data={productLookup}
                                      name={`SaleInvoiceItems[${index}].ProductId`}
                                      value={formik.values.SaleInvoiceItems[index].ProductId}
                                      required
                                      onChange={item => onChangeProduct(item, index)}
                                      onCreate={item => onClickFastProduct(item, index)}
                                    />

                                    {visibiltyItem[index].Description && <input
                                      type='text'
                                      className='form-control form-control-solid mt-2'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      placeholder={intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.DESCRIPTION' })}
                                      name={`SaleInvoiceItems[${index}].Description`}
                                      value={formik.values.SaleInvoiceItems[index].Description}
                                      onChange={formik.handleChange}
                                    />}
                                  </td>

                                  {formik.values.InvoiceTypeId === 1 && <td className="ps-0">
                                    <Select
                                      data={warehouseLookup}
                                      name={`SaleInvoiceItems[${index}].WarehouseId`}
                                      value={formik.values.SaleInvoiceItems[index].WarehouseId}
                                      required
                                      onChange={item => formik.setFieldValue(`SaleInvoiceItems[${index}].WarehouseId`, Number(item?.value))}
                                    />
                                  </td>}

                                  <td className="ps-0">
                                    <input
                                      type='number'
                                      className='form-control form-control-solid'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      placeholder={intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.AMOUNT' })}
                                      name={`SaleInvoiceItems[${index}].Amount`}
                                      value={formik.values.SaleInvoiceItems[index].Amount}
                                      required
                                      onChange={event => onChangeItem('Amount', Number(event.target.value), index)}
                                      onFocus={event => event.target.select()}
                                    />

                                    {visibiltyItem[index].Discount && <div className="input-group mb-5">
                                      <input
                                        type='number'
                                        className='form-control form-control-solid mt-2'
                                        autoComplete='off'
                                        disabled={formik.isSubmitting}
                                        placeholder={intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.DISCOUNTRATE' })}
                                        name={`SaleInvoiceItems[${index}].DiscountRate`}
                                        value={formik.values.SaleInvoiceItems[index].DiscountRate}
                                        onChange={event => onChangeItem('DiscountRate', Number(event.target.value), index)}
                                        onFocus={event => event.target.select()}
                                      />
                                      <Select
                                        className='mt-2'
                                        data={DiscountType}
                                        name={`SaleInvoiceItems[${index}].DiscountTypeId`}
                                        value={formik.values.SaleInvoiceItems[index].DiscountTypeId}
                                        required
                                        onChange={item => onChangeItem('DiscountTypeId', Number(item?.value), index)}
                                      />
                                    </div>}
                                  </td>

                                  <td className="ps-0">
                                    <Select
                                      data={ProductUnit}
                                      name={`SaleInvoiceItems[${index}].ProductUnitId`}
                                      value={formik.values.SaleInvoiceItems[index].ProductUnitId}
                                      required
                                      onChange={item => onChangeItem('ProductUnitId', Number(item?.value), index)}
                                    />

                                    {visibiltyItem[index].WitholdingRate && <Select
                                      data={WitholdingType}
                                      className={'mt-2'}
                                      name={`SaleInvoiceItems[${index}].WitholdingRateId`}
                                      value={formik.values.SaleInvoiceItems[index].WitholdingRateId}
                                      required
                                      onChange={item => onChangeItem('WitholdingRateId', Number(item?.value), index)}
                                    />}
                                  </td>

                                  <td className="ps-0">
                                    <InputPrice
                                      placeholder={intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.UNITPRICE' })}
                                      name={`SaleInvoiceItems[${index}].UnitPrice`}
                                      required
                                      value={formik.values.SaleInvoiceItems[index].UnitPrice}
                                      onChange={value => onChangeItem('UnitPrice', Number(value), index)}
                                    />
                                  </td>

                                  <td className="ps-0">
                                    <Select
                                      data={TaxType}
                                      name={`SaleInvoiceItems[${index}].TaxId`}
                                      value={formik.values.SaleInvoiceItems[index].TaxId}
                                      required
                                      onChange={item => onChangeItem('TaxId', Number(item?.value), index)}
                                    />
                                  </td>

                                  <td className='ps-0'>
                                    <InputPrice
                                      placeholder={intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.TOTALPRICE' })}
                                      name={`SaleInvoiceItems[${index}].TotalPrice`}
                                      value={formik.values.SaleInvoiceItems[index].TotalPrice}
                                    />
                                  </td>

                                  <td className="pt-5 text-end">
                                    <div className='d-flex'>
                                      <Dropdown autoClose='outside'>
                                        <Dropdown.Toggle variant='link' className='btn btn-sm btn-icon btn-active-color-info'>
                                          <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => onClickVisibityItem('Discount', index)}
                                            className={classNames({ 'active': visibiltyItem[index].Discount })}
                                          >
                                            {intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.DISCOUNT' })}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => onClickVisibityItem('Description', index)}
                                            className={classNames({ 'active': visibiltyItem[index].Description })}
                                          >
                                            {intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.DESCRIPTION' })}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => onClickVisibityItem('WitholdingRate', index)}
                                            className={classNames({ 'active': visibiltyItem[index].WitholdingRate })}
                                          >
                                            {intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.WITHOLDINGRATEID' })}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-active-color-primary"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}

                            </tbody>
                            <tfoot>
                              <tr className="border-top border-top-dashed align-middle fs-6 fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success"
                                    onClick={() => onClickNewItem(arrayHelpers)}
                                  >
                                    <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                                    {intl.formatMessage({ id: 'ADD_NEW_LINE' })}
                                  </button>
                                </th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({ id: 'SALEINVOICE.CREATE.SUBTOTALPRICE' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.SubTotalPrice, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>

                              {formik.values.TotalDiscount > 0 && <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALDISCOUNT' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.TotalDiscount, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>}

                              <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({ id: 'SALEINVOICE.CREATE.GROSSTOTALPRICE' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.GrossTotalPrice, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>

                              <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALTAX' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.TotalTax, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>

                              {formik.values.TotalWitholding > 0 && <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="border-bottom border-bottom-dashed ps-0 fs-5" colSpan={2}>
                                  {intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALWITHOLDING' })}
                                </th>
                                <th className="border-bottom border-bottom-dashed text-end">{intl.formatNumber(formik.values.TotalWitholding, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>}

                              <tr className="align-middle fw-bold text-gray-700">
                                <th colSpan={formik.values.InvoiceTypeId === 1 ? 4 : 3}></th>
                                <th className="fs-4 ps-0" colSpan={2}>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALPRICE' })}</th>
                                <th className="text-end fs-4 text-nowrap">{intl.formatNumber(formik.values.TotalPrice, { style: 'currency', currency: 'try' })}</th>
                                <th>&nbsp;</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </>
                    )}
                  />

                  <div className='row pt-15'>
                    <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                      <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </FormikProvider>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
