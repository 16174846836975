/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, KTCardHeader } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { tenantcompanyActions } from '../../../redux/actions';
import { KTSVG } from '../../../helpers';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Detail() {
  const location = useLocation()
  const intl = useIntl()
  const { detail } = useSelector(state => state.tenantcompany);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(tenantcompanyActions.tenantcompanyGet());
  }, [])


  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.COMPANY_INFO.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen018.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.address} {detail.provinceName} - {detail.districtName}
                          </div>


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>                        
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>                        
                      </div>		
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.TAXOFFICE' })}</div>
                          <div className="fw-bold fs-4">{detail.taxOffice}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.TAXNUMBER' })}</div>
                          <div className="fw-bold fs-4">{detail.taxNumber}</div>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>
                {detail?.title && <>
                  <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                    {detail?.title}
                  </div>
                </>}    
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>                                          
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.PHONE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.phone}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.FAX' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.fax}</span></div>
                        </div>                       
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.REGISTERNUMBER' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.registerNumber}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.EMAIL' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.email}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.IBAN' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.iban}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.MERSISNO' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.mersisNo}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.POSTALCODE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.postalCode}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.TICARISICILNO' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.ticariSicilNo}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.WEBSITE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.webSite}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.LOGOFILE' })}</label>
                          <div className="col-lg-8 fv-row"><img src={detail?.logoUrl} width="100" height="100" /></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'TENANTCOMPANY.GET.SEALSIGNATUREFILE' })}</label>
                          <div className="col-lg-8 fv-row"><img src={detail?.sealSignatureUrl} width="100" height="100" /></div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}        
        </div>
      </div>
    </>
  )
}
export { Detail };
