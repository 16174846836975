/* eslint-disable no-unused-vars */
import React from 'react'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import classNames from 'classnames'

const SelectCreatable = (props) => {
  const {className, value, required, data, placeholder, onChange, onCreate, isClearable, disabled,  ...rest} = props
  const intl = useIntl()

  const options = [];
  let newValue;
  data?.map((item) => options.push({value: item.id, label: item.name}))
  if (rest.isMulti) {
    const multi = []
    value.map(item => {
      const findItem = options.find(fitem => fitem.value == item);
      multi.push(findItem)
    })
    newValue = multi;
  } else {
    newValue = options.find(item => item.value == value);
  }

  const handleCreate = (value) => {
    if (value) onCreate && onCreate(value)
  }

  const handleChange = (value) => onChange && onChange(value) 

  const formatCreateLabel = () => `+ ${intl.formatMessage({id: 'BUTTON.ADD'})}`

  return (
    <CreatableSelect
      placeholder={placeholder || intl.formatMessage({id: 'CHOOSE' })}
      classNamePrefix='form-select-creatable'
      className={classNames('form-select-solid', className)}
      options={options}
      isLoading={!options}
      onCreateOption={handleCreate}
      formatCreateLabel={formatCreateLabel}
      onChange={(newValue) => handleChange(newValue)}
      allowCreateWhileLoading
      isValidNewOption={() => true}
      value={newValue}
      menuPortalTarget={document.body}
      isDisabled={disabled}
      {...rest}
    />
  )
}

SelectCreatable.propTypes = {
  value: PropTypes.any,
	required: PropTypes.bool,
	data: PropTypes.array,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onCreate: PropTypes.func,
}

export {SelectCreatable}
