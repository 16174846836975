import { categoryConstants } from '../constants';
import {
  //#region Category
  GetAllCategoryClient,  
  DeleteCategoryClient,
  UpdateCategoryClient,
  CreateCategoryClient,  
  //#endregion

  //#region RecordCategory    
  UpdateRecordCategoryClient,
  CreateRecordCategoryClient,
  GetRecordCategoryClient,
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region Category
const getAllCategoryClient = new GetAllCategoryClient(SERVICE_URL);
const createCategoryClient = new CreateCategoryClient(SERVICE_URL);
const deleteCategoryClient = new DeleteCategoryClient(SERVICE_URL);
const updateCategoryClient = new UpdateCategoryClient(SERVICE_URL);
//#endregion

//#region RecordCategory
const createRecordCategoryClient = new CreateRecordCategoryClient(SERVICE_URL);
const getRecordCategoryClient = new GetRecordCategoryClient(SERVICE_URL);
const updateRecordCategoryClient = new UpdateRecordCategoryClient(SERVICE_URL);
//#endregion

export const categoryActions = {
  //#region Category
  categoryGetAll,  
  categoryDelete,
  categoryCreate,
  categoryUpdate,  
  //#endregion

  //#region RecordCategory  
  recordcategoryGet,
  recordcategoryCreate,
  recordcategoryUpdate,
  //#endregion
};

//#region Category
function categoryGetAll(categoryTypeId) {
  return dispatch => {
    dispatch(request());

    getAllCategoryClient.getAll(categoryTypeId)
      .then(
        category => dispatch(success(category)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: categoryConstants.CATEGORY_GETALL_REQUEST } }
  function success(category) { return { type: categoryConstants.CATEGORY_GETALL_SUCCESS, category } }
  function failure(error) { return { type: categoryConstants.CATEGORY_GETALL_FAILURE, error } }
}

function categoryDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteCategoryClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: categoryConstants.CATEGORY_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: categoryConstants.CATEGORY_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: categoryConstants.CATEGORY_DELETE_FAILURE, error } }
}

function categoryCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createCategoryClient.create(params)
      .then(
        category => dispatch(success(category)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: categoryConstants.CATEGORY_CREATE_REQUEST, params } }
  function success(category) { return { type: categoryConstants.CATEGORY_CREATE_SUCCESS, category } }
  function failure(error) { return { type: categoryConstants.CATEGORY_CREATE_FAILURE, error } }
}

function categoryUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateCategoryClient.update(params)
      .then(
        category => dispatch(success(category, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: categoryConstants.CATEGORY_UPDATE_REQUEST, params } }
  function success(category, params) { return { type: categoryConstants.CATEGORY_UPDATE_SUCCESS, category, params } }
  function failure(error, params) { return { type: categoryConstants.CATEGORY_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region RecordCategory

function recordcategoryCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createRecordCategoryClient.create(params)
      .then(
        recordcategory => dispatch(success(recordcategory)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: categoryConstants.RECORDCATEGORY_CREATE_REQUEST, params } }
  function success(recordcategory) { return { type: categoryConstants.RECORDCATEGORY_CREATE_SUCCESS, recordcategory } }
  function failure(error) { return { type: categoryConstants.RECORDCATEGORY_CREATE_FAILURE, error } }
}

function recordcategoryUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateRecordCategoryClient.update(params)
      .then(
        recordcategory => dispatch(success(recordcategory, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: categoryConstants.RECORDCATEGORY_UPDATE_REQUEST, params } }
  function success(recordcategory, params) { return { type: categoryConstants.RECORDCATEGORY_UPDATE_SUCCESS, recordcategory, params } }
  function failure(error, params) { return { type: categoryConstants.RECORDCATEGORY_UPDATE_FAILURE, error, params } }
}

function recordcategoryGet(params) {
  return dispatch => {
    dispatch(request(params));

    getRecordCategoryClient.get(params)
      .then(
        recordcategory => dispatch(success(recordcategory)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: categoryConstants.RECORDCATEGORY_GETID_REQUEST, params } }
  function success(recordcategory) { return { type: categoryConstants.RECORDCATEGORY_GETID_SUCCESS, recordcategory } }
  function failure(error) { return { type: categoryConstants.RECORDCATEGORY_GETID_FAILURE, error } }
}
//#endregion
