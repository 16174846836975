export const profileConstants = {
  PROFILE_GETINFO_REQUEST: 'PROFILE_GETINFO_REQUEST',
  PROFILE_GETINFO_SUCCESS: 'PROFILE_GETINFO_SUCCESS',
  PROFILE_GETINFO_FAILURE: 'PROFILE_GETINFO_FAILURE',

  USERPROFILE_GETINFO_REQUEST: 'USERPROFILE_GETINFO_REQUEST',
  USERPROFILE_GETINFO_SUCCESS: 'USERPROFILE_GETINFO_SUCCESS',
  USERPROFILE_GETINFO_FAILURE: 'USERPROFILE_GETINFO_FAILURE',
  
  USERPROFILE_UPDATE_REQUEST: 'USERPROFILE_UPDATE_REQUEST',
  USERPROFILE_UPDATE_SUCCESS: 'USERPROFILE_UPDATE_SUCCESS',
  USERPROFILE_UPDATE_FAILURE: 'USERPROFILE_UPDATE_FAILURE',
};
