export const ExpensePaymentStatus = [
  {
    Id: 1,
    Name: 'EXPENSE_PAYMENT_STATUS.CHARGED'
  },
  {
    Id: 2,
    Name: 'EXPENSE_PAYMENT_STATUS.DONE'
  }
]
