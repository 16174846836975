export const saleinvoiceConstants = {
  //#region SaleInvoice
  SALEINVOICE_GETALL_REQUEST: 'SALEINVOICE_GETALL_REQUEST',
  SALEINVOICE_GETALL_SUCCESS: 'SALEINVOICE_GETALL_SUCCESS',
  SALEINVOICE_GETALL_FAILURE: 'SALEINVOICE_GETALL_FAILURE',

  SALEINVOICE_GETID_REQUEST: 'SALEINVOICE_GETID_REQUEST',
  SALEINVOICE_GETID_SUCCESS: 'SALEINVOICE_GETID_SUCCESS',
  SALEINVOICE_GETID_FAILURE: 'SALEINVOICE_GETID_FAILURE',

  SALEINVOICE_DELETE_REQUEST: 'SALEINVOICE_DELETE_REQUEST',
  SALEINVOICE_DELETE_SUCCESS: 'SALEINVOICE_DELETE_SUCCESS',
  SALEINVOICE_DELETE_FAILURE: 'SALEINVOICE_DELETE_FAILURE',

  SALEINVOICE_CREATE_REQUEST: 'SALEINVOICE_CREATE_REQUEST',
  SALEINVOICE_CREATE_SUCCESS: 'SALEINVOICE_CREATE_SUCCESS',
  SALEINVOICE_CREATE_FAILURE: 'SALEINVOICE_CREATE_FAILURE',

  SALEINVOICE_UPDATE_REQUEST: 'SALEINVOICE_UPDATE_REQUEST',
  SALEINVOICE_UPDATE_SUCCESS: 'SALEINVOICE_UPDATE_SUCCESS',
  SALEINVOICE_UPDATE_FAILURE: 'SALEINVOICE_UPDATE_FAILURE',

  SALEINVOICE_ARCHIVE_REQUEST: 'SALEINVOICE_ARCHIVE_REQUEST',
  SALEINVOICE_ARCHIVE_SUCCESS: 'SALEINVOICE_ARCHIVE_SUCCESS',
  SALEINVOICE_ARCHIVE_FAILURE: 'SALEINVOICE_ARCHIVE_FAILURE',

  SALEINVOICE_EXCEL_REQUEST: 'SALEINVOICE_EXCEL_REQUEST',
  SALEINVOICE_EXCEL_SUCCESS: 'SALEINVOICE_EXCEL_SUCCESS',
  SALEINVOICE_EXCEL_FAILURE: 'SALEINVOICE_EXCEL_FAILURE'

  //#endregion            
};
