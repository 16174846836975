import { productConstants } from '../constants';
import {
  //#region Product
  SearchProductClient,
  GetProductClient,
  GetProductInfoClient,
  DeleteProductClient,
  UpdateProductClient,
  CreateProductClient,
  CreateFastProductClient,
  ArchivingProductClient,
  UpdateWarehousesProductClient,
  GetStockProductClient,
  SearchExpenseInvoiceProductClient,
  SearchSaleInvoiceProductClient,
  SearchStockProductClient,
  SearchWarehouseProductClient,
  //#endregion

  //#region Warehouse
  SearchWarehouseClient,
  GetWarehouseClient,
  DeleteWarehouseClient,
  UpdateWarehouseClient,
  CreateWarehouseClient,
  ArchivingWarehouseClient,
  SetDefaultWarehouseClient,
  //#endregion

  //#region OutgoingWaybill
  SearchOutgoingWaybillClient,
  GetOutgoingWaybillClient,
  DeleteOutgoingWaybillClient,
  UpdateOutgoingWaybillClient,
  CreateOutgoingWaybillClient,
  //#endregion

  //#region IncomingWaybill
  SearchIncomingWaybillClient,
  GetIncomingWaybillClient,
  DeleteIncomingWaybillClient,
  UpdateIncomingWaybillClient,
  CreateIncomingWaybillClient,
  //#endregion

  //#region WarehouseTransfer
  SearchWarehouseTransferClient,
  GetWarehouseTransferClient,
  DeleteWarehouseTransferClient,
  CreateWarehouseTransferClient,
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;


//#region Product
const archivingProductClient = new ArchivingProductClient(SERVICE_URL);
const searchProductClient = new SearchProductClient(SERVICE_URL);
const getProductClient = new GetProductClient(SERVICE_URL);
const getProductInfoClient = new GetProductInfoClient(SERVICE_URL);
const createProductClient = new CreateProductClient(SERVICE_URL);
const createFastProductClient = new CreateFastProductClient(SERVICE_URL);
const deleteProductClient = new DeleteProductClient(SERVICE_URL);
const updateProductClient = new UpdateProductClient(SERVICE_URL);
const updateWarehousesProductClient = new UpdateWarehousesProductClient(SERVICE_URL);
const getStockProductClient = new GetStockProductClient(SERVICE_URL);
const searchExpenseInvoiceProductClient = new SearchExpenseInvoiceProductClient(SERVICE_URL);
const searchSaleInvoiceProductClient = new SearchSaleInvoiceProductClient(SERVICE_URL);
const searchStockProductClient = new SearchStockProductClient(SERVICE_URL);
const searchWarehouseProductClient = new SearchWarehouseProductClient(SERVICE_URL);
//#endregion

//#region Warehouse
const archivingWarehouseClient = new ArchivingWarehouseClient(SERVICE_URL);
const searchWarehouseClient = new SearchWarehouseClient(SERVICE_URL);
const getWarehouseClient = new GetWarehouseClient(SERVICE_URL);
const createWarehouseClient = new CreateWarehouseClient(SERVICE_URL);
const deleteWarehouseClient = new DeleteWarehouseClient(SERVICE_URL);
const updateWarehouseClient = new UpdateWarehouseClient(SERVICE_URL);
const setDefaultWarehouseClient = new SetDefaultWarehouseClient(SERVICE_URL);
//#endregion

//#region OutgoingWaybill
const searchOutgoingWaybillClient = new SearchOutgoingWaybillClient(SERVICE_URL);
const getOutgoingWaybillClient = new GetOutgoingWaybillClient(SERVICE_URL);
const createOutgoingWaybillClient = new CreateOutgoingWaybillClient(SERVICE_URL);
const deleteOutgoingWaybillClient = new DeleteOutgoingWaybillClient(SERVICE_URL);
const updateOutgoingWaybillClient = new UpdateOutgoingWaybillClient(SERVICE_URL);
//#endregion

//#region IncomingWaybill
const searchIncomingWaybillClient = new SearchIncomingWaybillClient(SERVICE_URL);
const getIncomingWaybillClient = new GetIncomingWaybillClient(SERVICE_URL);
const createIncomingWaybillClient = new CreateIncomingWaybillClient(SERVICE_URL);
const deleteIncomingWaybillClient = new DeleteIncomingWaybillClient(SERVICE_URL);
const updateIncomingWaybillClient = new UpdateIncomingWaybillClient(SERVICE_URL);
//#endregion

//#region WarehouseTransfer
const searchWarehouseTransferClient = new SearchWarehouseTransferClient(SERVICE_URL);
const getWarehouseTransferClient = new GetWarehouseTransferClient(SERVICE_URL);
const createWarehouseTransferClient = new CreateWarehouseTransferClient(SERVICE_URL);
const deleteWarehouseTransferClient = new DeleteWarehouseTransferClient(SERVICE_URL);
//#endregion

export const productActions = {
  //#region Product
  productGetAll,
  productGetId,
  productGetInfo,
  productDelete,
  productCreate,
  productCreateFast,
  productUpdate,
  productArchiving,
  productUpdateWarehouses,
  productGetStock,
  productSearchExpenseInvoice,
  productSearchSaleInvoice,
  productSearchStock,
  productSearchWarehouse,
  productExcel,
  //#endregion

  //#region Warehouse
  warehouseGetAll,
  warehouseGetId,
  warehouseDelete,
  warehouseCreate,
  warehouseUpdate,
  warehouseArchiving,
  warehouseSetDefault,
  warehouseExcel,
  //#endregion

  //#region OutgoingWaybill
  outgoingWaybillGetAll,
  outgoingWaybillGetId,
  outgoingWaybillDelete,
  outgoingWaybillCreate,
  outgoingWaybillUpdate,
  outgoingWaybillExcel,
  //#endregion

  //#region IncomingWaybill
  incomingWaybillGetAll,
  incomingWaybillGetId,
  incomingWaybillDelete,
  incomingWaybillCreate,
  incomingWaybillUpdate,
  incomingWaybillExcel,
  //#endregion

  //#region WarehouseTransfer
  warehouseTransferGetAll,
  warehouseTransferGetId,
  warehouseTransferDelete,
  warehouseTransferCreate,
  warehouseTransferExcel,
  //#endregion
};

//#region Product
function productGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchProductClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        product => dispatch(success(product)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.PRODUCT_GETALL_REQUEST } }
  function success(product) { return { type: productConstants.PRODUCT_GETALL_SUCCESS, product } }
  function failure(error) { return { type: productConstants.PRODUCT_GETALL_FAILURE, error } }
}

function productExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchProductClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.PRODUCT_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: productConstants.PRODUCT_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: productConstants.PRODUCT_EXCEL_FAILURE, error } }
}

function productGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getProductClient.get(id)
      .then(
        product => dispatch(success(product)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: productConstants.PRODUCT_GETID_REQUEST, id } }
  function success(product) { return { type: productConstants.PRODUCT_GETID_SUCCESS, product } }
  function failure(error) { return { type: productConstants.PRODUCT_GETID_FAILURE, error } }
}

function productGetInfo(params) {
  return dispatch => {
    dispatch(request(params));

    getProductInfoClient.get(params)
      .then(
        productInfo => dispatch(success(productInfo)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.PRODUCT_GET_INFO_REQUEST, params } }
  function success(productInfo) { return { type: productConstants.PRODUCT_GET_INFO_SUCCESS, productInfo } }
  function failure(error) { return { type: productConstants.PRODUCT_GET_INFO_FAILURE, error } }
}

function productDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteProductClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.PRODUCT_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: productConstants.PRODUCT_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: productConstants.PRODUCT_DELETE_FAILURE, error } }
}

function productArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingProductClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.PRODUCT_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: productConstants.PRODUCT_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: productConstants.PRODUCT_ARCHIVE_FAILURE, error } }
}

function productCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createProductClient.create(params)
      .then(
        product => dispatch(success(product)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.PRODUCT_CREATE_REQUEST, params } }
  function success(product) { return { type: productConstants.PRODUCT_CREATE_SUCCESS, product } }
  function failure(error) { return { type: productConstants.PRODUCT_CREATE_FAILURE, error } }
}

function productCreateFast(params) {
  return dispatch => {
    dispatch(request(params));

    createFastProductClient.create(params)
      .then(
        product => dispatch(success(product)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.PRODUCT_CREATEFAST_REQUEST, params } }
  function success(product) { return { type: productConstants.PRODUCT_CREATEFAST_SUCCESS, product } }
  function failure(error) { return { type: productConstants.PRODUCT_CREATEFAST_FAILURE, error } }
}

function productUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateProductClient.update(params)
      .then(
        product => dispatch(success(product, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: productConstants.PRODUCT_UPDATE_REQUEST, params } }
  function success(product, params) { return { type: productConstants.PRODUCT_UPDATE_SUCCESS, product, params } }
  function failure(error, params) { return { type: productConstants.PRODUCT_UPDATE_FAILURE, error, params } }
}

function productUpdateWarehouses(params) {
  return dispatch => {
    dispatch(request(params));

    updateWarehousesProductClient.update(params)
      .then(
        productUpdateWareHouse => dispatch(success(productUpdateWareHouse, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: productConstants.PRODUCT_UPDATEWAREHOUSES_REQUEST, params } }
  function success(productUpdateWareHouse, params) { return { type: productConstants.PRODUCT_UPDATEWAREHOUSES_SUCCESS, productUpdateWareHouse, params } }
  function failure(error, params) { return { type: productConstants.PRODUCT_UPDATEWAREHOUSES_FAILURE, error, params } }
}

function productGetStock(params) {
  return dispatch => {
    dispatch(request());

    getStockProductClient.getAll(params)
      .then(
        productStock => dispatch(success(productStock)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.PRODUCT_GETSTOCK_REQUEST } }
  function success(productStock) { return { type: productConstants.PRODUCT_GETSTOCK_SUCCESS, productStock } }
  function failure(error) { return { type: productConstants.PRODUCT_GETSTOCK_FAILURE, error } }
}

function productSearchExpenseInvoice(params) {
  return dispatch => {
    dispatch(request());

    searchExpenseInvoiceProductClient.search(params)
      .then(
        productExpenseInvoice => dispatch(success(productExpenseInvoice)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.PRODUCT_SEARCHEXPENSEINVOICES_REQUEST } }
  function success(productExpenseInvoice) { return { type: productConstants.PRODUCT_SEARCHEXPENSEINVOICES_SUCCESS, productExpenseInvoice } }
  function failure(error) { return { type: productConstants.PRODUCT_SEARCHEXPENSEINVOICES_FAILURE, error } }
}

function productSearchSaleInvoice(params) {
  return dispatch => {
    dispatch(request());

    searchSaleInvoiceProductClient.search(params)
      .then(
        productSaleInvoice => dispatch(success(productSaleInvoice)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.PRODUCT_SEARCHSALEINVOICES_REQUEST } }
  function success(productSaleInvoice) { return { type: productConstants.PRODUCT_SEARCHSALEINVOICES_SUCCESS, productSaleInvoice } }
  function failure(error) { return { type: productConstants.PRODUCT_SEARCHSALEINVOICES_FAILURE, error } }
}

function productSearchStock(params) {
  return dispatch => {
    dispatch(request());

    searchStockProductClient.search(params)
      .then(
        searchStock => dispatch(success(searchStock)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.PRODUCT_SEARCHSTOCKS_REQUEST } }
  function success(searchStock) { return { type: productConstants.PRODUCT_SEARCHSTOCKS_SUCCESS, searchStock } }
  function failure(error) { return { type: productConstants.PRODUCT_SEARCHSTOCKS_FAILURE, error } }
}

function productSearchWarehouse(params) {
  return dispatch => {
    dispatch(request());

    searchWarehouseProductClient.search(params)
      .then(
        searchWarehouse => dispatch(success(searchWarehouse)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.PRODUCT_SEARCHWAREHOUSES_REQUEST } }
  function success(searchWarehouse) { return { type: productConstants.PRODUCT_SEARCHWAREHOUSES_SUCCESS, searchWarehouse } }
  function failure(error) { return { type: productConstants.PRODUCT_SEARCHWAREHOUSES_FAILURE, error } }
}
//#endregion

//#region Warehouse
function warehouseGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchWarehouseClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        warehouse => dispatch(success(warehouse)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.WAREHOUSE_GETALL_REQUEST } }
  function success(warehouse) { return { type: productConstants.WAREHOUSE_GETALL_SUCCESS, warehouse } }
  function failure(error) { return { type: productConstants.WAREHOUSE_GETALL_FAILURE, error } }
}

function warehouseExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchWarehouseClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.WAREHOUSE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: productConstants.WAREHOUSE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: productConstants.WAREHOUSE_EXCEL_FAILURE, error } }
}

function warehouseGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getWarehouseClient.get(id)
      .then(
        warehouse => dispatch(success(warehouse)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: productConstants.WAREHOUSE_GETID_REQUEST, id } }
  function success(warehouse) { return { type: productConstants.WAREHOUSE_GETID_SUCCESS, warehouse } }
  function failure(error) { return { type: productConstants.WAREHOUSE_GETID_FAILURE, error } }
}

function warehouseDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteWarehouseClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.WAREHOUSE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: productConstants.WAREHOUSE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: productConstants.WAREHOUSE_DELETE_FAILURE, error } }
}

function warehouseArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingWarehouseClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.WAREHOUSE_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: productConstants.WAREHOUSE_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: productConstants.WAREHOUSE_ARCHIVE_FAILURE, error } }
}

function warehouseCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createWarehouseClient.create(params)
      .then(        
        warehouse => dispatch(success(warehouse)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.WAREHOUSE_CREATE_REQUEST, params } }
  function success(warehouse) { return { type: productConstants.WAREHOUSE_CREATE_SUCCESS, warehouse } }
  function failure(error) { return { type: productConstants.WAREHOUSE_CREATE_FAILURE, error } }
}

function warehouseUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateWarehouseClient.update(params)
      .then(
        warehouse => dispatch(success(warehouse, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: productConstants.WAREHOUSE_UPDATE_REQUEST, params } }
  function success(warehouse, params) { return { type: productConstants.WAREHOUSE_UPDATE_SUCCESS, warehouse, params } }
  function failure(error, params) { return { type: productConstants.WAREHOUSE_UPDATE_FAILURE, error, params } }
}

function warehouseSetDefault(params) {
  return dispatch => {
    dispatch(request(params));

    setDefaultWarehouseClient.update(params)
      .then(
        isDefault => dispatch(success(isDefault)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.WAREHOUSE_SETDEFAULT_REQUEST, params } }
  function success(isDefault) { return { type: productConstants.WAREHOUSE_SETDEFAULT_SUCCESS, isDefault } }
  function failure(error) { return { type: productConstants.WAREHOUSE_SETDEFAULT_FAILURE, error } }
}
//#endregion

//#region OutgoingWaybill
function outgoingWaybillGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchOutgoingWaybillClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        outgoingWaybill => dispatch(success(outgoingWaybill)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.OUTGOINGWAYBILL_GETALL_REQUEST } }
  function success(outgoingWaybill) { return { type: productConstants.OUTGOINGWAYBILL_GETALL_SUCCESS, outgoingWaybill } }
  function failure(error) { return { type: productConstants.OUTGOINGWAYBILL_GETALL_FAILURE, error } }
}

function outgoingWaybillExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchOutgoingWaybillClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.OUTGOINGWAYBILL_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: productConstants.OUTGOINGWAYBILL_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: productConstants.OUTGOINGWAYBILL_EXCEL_FAILURE, error } }
}

function outgoingWaybillGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getOutgoingWaybillClient.get(id)
      .then(
        outgoingWaybill => dispatch(success(outgoingWaybill)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: productConstants.OUTGOINGWAYBILL_GETID_REQUEST, id } }
  function success(outgoingWaybill) { return { type: productConstants.OUTGOINGWAYBILL_GETID_SUCCESS, outgoingWaybill } }
  function failure(error) { return { type: productConstants.OUTGOINGWAYBILL_GETID_FAILURE, error } }
}

function outgoingWaybillDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteOutgoingWaybillClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.OUTGOINGWAYBILL_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: productConstants.OUTGOINGWAYBILL_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: productConstants.OUTGOINGWAYBILL_DELETE_FAILURE, error } }
}

function outgoingWaybillCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createOutgoingWaybillClient.create(params)
      .then(
        outgoingWaybill => dispatch(success(outgoingWaybill)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.OUTGOINGWAYBILL_CREATE_REQUEST, params } }
  function success(outgoingWaybill) { return { type: productConstants.OUTGOINGWAYBILL_CREATE_SUCCESS, outgoingWaybill } }
  function failure(error) { return { type: productConstants.OUTGOINGWAYBILL_CREATE_FAILURE, error } }
}

function outgoingWaybillUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateOutgoingWaybillClient.update(params)
      .then(
        outgoingWaybill => dispatch(success(outgoingWaybill, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: productConstants.OUTGOINGWAYBILL_UPDATE_REQUEST, params } }
  function success(outgoingWaybill, params) { return { type: productConstants.OUTGOINGWAYBILL_UPDATE_SUCCESS, outgoingWaybill, params } }
  function failure(error, params) { return { type: productConstants.OUTGOINGWAYBILL_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region IncomingWaybill
function incomingWaybillGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchIncomingWaybillClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        incomingWaybill => dispatch(success(incomingWaybill)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.INCOMINGWAYBILL_GETALL_REQUEST } }
  function success(incomingWaybill) { return { type: productConstants.INCOMINGWAYBILL_GETALL_SUCCESS, incomingWaybill } }
  function failure(error) { return { type: productConstants.INCOMINGWAYBILL_GETALL_FAILURE, error } }
}

function incomingWaybillExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchIncomingWaybillClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.INCOMINGWAYBILL_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: productConstants.INCOMINGWAYBILL_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: productConstants.INCOMINGWAYBILL_EXCEL_FAILURE, error } }
}

function incomingWaybillGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getIncomingWaybillClient.get(id)
      .then(
        incomingWaybill => dispatch(success(incomingWaybill)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: productConstants.INCOMINGWAYBILL_GETID_REQUEST, id } }
  function success(incomingWaybill) { return { type: productConstants.INCOMINGWAYBILL_GETID_SUCCESS, incomingWaybill } }
  function failure(error) { return { type: productConstants.INCOMINGWAYBILL_GETID_FAILURE, error } }
}

function incomingWaybillDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteIncomingWaybillClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.INCOMINGWAYBILL_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: productConstants.INCOMINGWAYBILL_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: productConstants.INCOMINGWAYBILL_DELETE_FAILURE, error } }
}

function incomingWaybillCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createIncomingWaybillClient.create(params)
      .then(
        incomingWaybill => dispatch(success(incomingWaybill)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.INCOMINGWAYBILL_CREATE_REQUEST, params } }
  function success(incomingWaybill) { return { type: productConstants.INCOMINGWAYBILL_CREATE_SUCCESS, incomingWaybill } }
  function failure(error) { return { type: productConstants.INCOMINGWAYBILL_CREATE_FAILURE, error } }
}

function incomingWaybillUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateIncomingWaybillClient.update(params)
      .then(
        incomingWaybill => dispatch(success(incomingWaybill, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: productConstants.INCOMINGWAYBILL_UPDATE_REQUEST, params } }
  function success(incomingWaybill, params) { return { type: productConstants.INCOMINGWAYBILL_UPDATE_SUCCESS, incomingWaybill, params } }
  function failure(error, params) { return { type: productConstants.INCOMINGWAYBILL_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region WarehouseTransfer
function warehouseTransferGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchWarehouseTransferClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        warehouseTransfer => dispatch(success(warehouseTransfer)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.WAREHOUSETRANSFER_GETALL_REQUEST } }
  function success(warehouseTransfer) { return { type: productConstants.WAREHOUSETRANSFER_GETALL_SUCCESS, warehouseTransfer } }
  function failure(error) { return { type: productConstants.WAREHOUSETRANSFER_GETALL_FAILURE, error } }
}

function warehouseTransferExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchWarehouseTransferClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: productConstants.WAREHOUSETRANSFER_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: productConstants.WAREHOUSETRANSFER_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: productConstants.WAREHOUSETRANSFER_EXCEL_FAILURE, error } }
}

function warehouseTransferGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getWarehouseTransferClient.get(id)
      .then(
        warehouseTransfer => dispatch(success(warehouseTransfer)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: productConstants.WAREHOUSETRANSFER_GETID_REQUEST, id } }
  function success(warehouseTransfer) { return { type: productConstants.WAREHOUSETRANSFER_GETID_SUCCESS, warehouseTransfer } }
  function failure(error) { return { type: productConstants.WAREHOUSETRANSFER_GETID_FAILURE, error } }
}

function warehouseTransferDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteWarehouseTransferClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.WAREHOUSETRANSFER_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: productConstants.WAREHOUSETRANSFER_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: productConstants.WAREHOUSETRANSFER_DELETE_FAILURE, error } }
}

function warehouseTransferCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createWarehouseTransferClient.create(params)
      .then(
        warehouseTransfer => dispatch(success(warehouseTransfer)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: productConstants.WAREHOUSETRANSFER_CREATE_REQUEST, params } }
  function success(warehouseTransfer) { return { type: productConstants.WAREHOUSETRANSFER_CREATE_SUCCESS, warehouseTransfer } }
  function failure(error) { return { type: productConstants.WAREHOUSETRANSFER_CREATE_FAILURE, error } }
}
//#endregion
