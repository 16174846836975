import { paymentRequestConstants } from '../constants';
import {
  //#region PaymentRequest
  SearchPaymentRequestClient,
  CreatePaymentRequestClient,
  GetPaymentRequestClient,
  DeletePaymentRequestClient,
  UpdatePaymentRequestClient,
  UpdateOnConfirmationStateClient,
  GetConfirmationStateClient,
  UpdatePaymentRequestConfirmationClient,
  GetWaitingConfirmationClient
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region PaymentRequest
const searchPaymentRequestClient = new SearchPaymentRequestClient(SERVICE_URL);
const createPaymentRequestClient = new CreatePaymentRequestClient(SERVICE_URL);
const getPaymentRequestClient = new GetPaymentRequestClient(SERVICE_URL);
const deletePaymentRequestClient = new DeletePaymentRequestClient(SERVICE_URL);
const updatePaymentRequestClient = new UpdatePaymentRequestClient(SERVICE_URL);
const updateOnConfirmationStateClient = new UpdateOnConfirmationStateClient(SERVICE_URL);
const getConfirmationStateClient = new GetConfirmationStateClient(SERVICE_URL);
const updatePaymentRequestConfirmationClient = new UpdatePaymentRequestConfirmationClient(SERVICE_URL);
const getWaitingConfirmationClient = new GetWaitingConfirmationClient(SERVICE_URL);
//#endregion

export const paymentRequestActions = {
  //#region PaymentRequest
  paymentRequestGetAll,
  paymentRequestCreate,
  paymentRequestGetId,
  paymentRequestDelete,
  paymentRequestUpdate,
  paymentRequestUpdateOnConfirmationState,
  paymentRequestGetConfirmationState,
  paymentRequestUpdateConfirmation,
  //#endregion 
  //#region WaitingConfirmation
  getWaitingConfirmationGetAll
  //#endregion    
};

//#region PaymentRequest
function paymentRequestGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchPaymentRequestClient.search(params.name, params.projectId, params.companyId, params.beginDate, params.endDate, params.requestedUserId, params.paymentRequestTypeId, params.paymentRequestStateId, params.items_Per_Page, params.page, params.order, params.sort)
      .then(
        paymentRequest => dispatch(success(paymentRequest)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: paymentRequestConstants.PAYMENTREQUEST_GETALL_REQUEST } }
  function success(paymentRequest) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETALL_SUCCESS, paymentRequest } }
  function failure(error) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETALL_FAILURE, error } }
}

function paymentRequestCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createPaymentRequestClient.create(params)
      .then(
        paymentRequest => dispatch(success(paymentRequest)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentRequestConstants.PAYMENTREQUEST_CREATE_REQUEST, params } }
  function success(paymentRequest) { return { type: paymentRequestConstants.PAYMENTREQUEST_CREATE_SUCCESS, paymentRequest } }
  function failure(error) { return { type: paymentRequestConstants.PAYMENTREQUEST_CREATE_FAILURE, error } }
}

function paymentRequestGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getPaymentRequestClient.get(id)
      .then(
        paymentRequest => dispatch(success(paymentRequest)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETID_REQUEST, id } }
  function success(paymentRequest) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETID_SUCCESS, paymentRequest } }
  function failure(error) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETID_FAILURE, error } }
}

function paymentRequestDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deletePaymentRequestClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentRequestConstants.PAYMENTREQUEST_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: paymentRequestConstants.PAYMENTREQUEST_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: paymentRequestConstants.PAYMENTREQUEST_DELETE_FAILURE, error } }
}

function paymentRequestUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updatePaymentRequestClient.update(params)
      .then(
        paymentRequest => dispatch(success(paymentRequest, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: paymentRequestConstants.PAYMENTREQUEST_UPDATE_REQUEST, params } }
  function success(paymentRequest, params) { return { type: paymentRequestConstants.PAYMENTREQUEST_UPDATE_SUCCESS, paymentRequest, params } }
  function failure(error, params) { return { type: paymentRequestConstants.PAYMENTREQUEST_UPDATE_FAILURE, error, params } }
}

function paymentRequestUpdateOnConfirmationState(params) {
  return dispatch => {
    dispatch(request(params));

    updateOnConfirmationStateClient.update(params)
      .then(
        onConfirmationState => dispatch(success(onConfirmationState)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentRequestConstants.PAYMENTREQUEST_ONCONFIRMATIONSTATE_REQUEST, params } }
  function success(onConfirmationState) { return { type: paymentRequestConstants.PAYMENTREQUEST_ONCONFIRMATIONSTATE_SUCCESS, onConfirmationState } }
  function failure(error) { return { type: paymentRequestConstants.PAYMENTREQUEST_ONCONFIRMATIONSTATE_FAILURE, error } }
}

function paymentRequestGetConfirmationState(params) {
  return dispatch => {
    dispatch(request(params));

    getConfirmationStateClient.get(params)
      .then(
        getConfirmationState => dispatch(success(getConfirmationState)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETCONFIRMATIONSTATE_REQUEST, params } }
  function success(getConfirmationState) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETCONFIRMATIONSTATE_SUCCESS, getConfirmationState } }
  function failure(error) { return { type: paymentRequestConstants.PAYMENTREQUEST_GETCONFIRMATIONSTATE_FAILURE, error } }
}

function paymentRequestUpdateConfirmation(params) {
  return dispatch => {
    dispatch(request(params));

    updatePaymentRequestConfirmationClient.update(params)
      .then(
        updateConfirmation => dispatch(success(updateConfirmation)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: paymentRequestConstants.PAYMENTREQUEST_UPDATECONFIRMATION_REQUEST, params } }
  function success(updateConfirmation) { return { type: paymentRequestConstants.PAYMENTREQUEST_UPDATECONFIRMATION_SUCCESS, updateConfirmation } }
  function failure(error) { return { type: paymentRequestConstants.PAYMENTREQUEST_UPDATECONFIRMATION_FAILURE, error } }
}
//#endregion

//#region WaitingConfirmation
function getWaitingConfirmationGetAll(params) {
  return dispatch => {
    dispatch(request());
    
    getWaitingConfirmationClient.search(params.projectId, params.companyId, params.approveUserId, params.items_Per_Page, params.page, params.order, params.sort)
      .then(
        waitingConfirmation => dispatch(success(waitingConfirmation)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: paymentRequestConstants.WAITINGCONFIRMATION_GETALL_REQUEST } }
  function success(waitingConfirmation) { return { type: paymentRequestConstants.WAITINGCONFIRMATION_GETALL_SUCCESS, waitingConfirmation } }
  function failure(error) { return { type: paymentRequestConstants.WAITINGCONFIRMATION_GETALL_FAILURE, error } }
}
//#endregion

