import { staffConstants } from '../constants';
import {
  //#region Staff
  ArchivingStaffClient,
  SearchStaffClient,
  GetStaffClient,
  DeleteStaffClient,
  UpdateStaffClient,
  CreateStaffClient,
  SearchByStaffIdCheckoutClient,
  SearchByStaffIdOvertimeClient,
  SearchByStaffIdLeaveClient,
  SearchByStaffIdSalaryClient,
  //#endregion

  //#region Leave
  SearchLeaveClient,
  GetLeaveClient,
  DeleteLeaveClient,
  UpdateLeaveClient,
  CreateLeaveClient,

  //#endregion

  //#region Earning Leave
  GetAllEarningLeaveByStaffIdClient,
  GetBalanceEarningLeaveClient,
  CreateEarningLeaveClient,
  DeleteEarningLeaveClient,
  //#endregion

  //#region Salary
  SearchSalaryClient,
  GetSalaryClient,
  DeleteSalaryClient,
  CreateSalaryClient,
  UpdateSalaryClient,
  ArchivingSalaryClient,
  //#endregion

  //#region Overtime
  SearchOvertimeClient,
  GetOvertimeClient,
  DeleteOvertimeClient,
  CreateOvertimeClient,
  UpdateOvertimeClient,
  //#endregion

  //#region Certificate
  DeleteCertificateClient,
  CreateCertificateClient,
  //#endregion

  //#region MonthlySalary
  SearchMonthlySalaryClient,
  GetMonthlySalaryByYearMonthClient,
  UpdateMonthlySalaryClient,
  GetMonthlySalaryClient,
  UpdateMonthlySalaryForPaymentClient
  //#endregion

} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

//#region Staff
const archivingStaffClient = new ArchivingStaffClient(SERVICE_URL);
const searchStaffClient = new SearchStaffClient(SERVICE_URL);
const getStaffClient = new GetStaffClient(SERVICE_URL);
const createStaffClient = new CreateStaffClient(SERVICE_URL);
const deleteStaffClient = new DeleteStaffClient(SERVICE_URL);
const updateStaffClient = new UpdateStaffClient(SERVICE_URL);
const searchByStaffIdCheckoutClient = new SearchByStaffIdCheckoutClient(SERVICE_URL);
const searchByStaffIdOvertimeClient = new SearchByStaffIdOvertimeClient(SERVICE_URL);
const searchByStaffIdLeaveClient = new SearchByStaffIdLeaveClient(SERVICE_URL);
const searchByStaffIdSalaryClient = new SearchByStaffIdSalaryClient(SERVICE_URL);
//#endregion

//#region Leave
const searchLeaveClient = new SearchLeaveClient(SERVICE_URL);
const getLeaveClient = new GetLeaveClient(SERVICE_URL);
const createLeaveClient = new CreateLeaveClient(SERVICE_URL);
const deleteLeaveClient = new DeleteLeaveClient(SERVICE_URL);
const updateLeaveClient = new UpdateLeaveClient(SERVICE_URL);
//#endregion

//#region Earning Leave
const getAllEarningLeaveByStaffIdClient = new GetAllEarningLeaveByStaffIdClient(SERVICE_URL);
const getBalanceEarningLeaveClient = new GetBalanceEarningLeaveClient(SERVICE_URL);
const createEarningLeaveClient = new CreateEarningLeaveClient(SERVICE_URL);
const deleteEarningLeaveClient = new DeleteEarningLeaveClient(SERVICE_URL);
//#endregion

//#region Salary
const searchSalaryClient = new SearchSalaryClient(SERVICE_URL);
const getSalaryClient = new GetSalaryClient(SERVICE_URL);
const deleteSalaryClient = new DeleteSalaryClient(SERVICE_URL);
const createSalaryClient = new CreateSalaryClient(SERVICE_URL);
const updateSalaryClient = new UpdateSalaryClient(SERVICE_URL);
const archivingSalaryClient = new ArchivingSalaryClient(SERVICE_URL);
//#endregion

//#region Overtime
const searchOvertimeClient = new SearchOvertimeClient(SERVICE_URL);
const getOvertimeClient = new GetOvertimeClient(SERVICE_URL);
const deleteOvertimeClient = new DeleteOvertimeClient(SERVICE_URL);
const createOvertimeClient = new CreateOvertimeClient(SERVICE_URL);
const updateOvertimeClient = new UpdateOvertimeClient(SERVICE_URL);
//#endregion

//#region Certificate
const deleteCertificateClient = new DeleteCertificateClient(SERVICE_URL);
const createCertificateClient = new CreateCertificateClient(SERVICE_URL);
//#endregion

//#region MonthlySalary
const searchMonthlySalaryClient = new SearchMonthlySalaryClient(SERVICE_URL);
const getMonthlySalaryByYearMonthClient = new GetMonthlySalaryByYearMonthClient(SERVICE_URL);
const updateMonthlySalaryClient = new UpdateMonthlySalaryClient(SERVICE_URL);
const getMonthlySalaryClient = new GetMonthlySalaryClient(SERVICE_URL);
const updateMonthlySalaryForPaymentClient = new UpdateMonthlySalaryForPaymentClient(SERVICE_URL);
//#endregion

export const staffActions = {
  //#region Staff
  staffArchiving,
  staffGetAll,
  staffGetId,
  staffDelete,
  staffCreate,
  staffUpdate,
  staffGetIdCheckout,
  staffGetIdOvertime,
  staffGetIdLeave,
  staffGetIdSalary,
  staffExcel,
  //#endregion

  //#region Leave
  leaveGetAll,
  leaveGetId,
  leaveDelete,
  leaveCreate,
  leaveUpdate,
  leaveExcel,
  //#endregion

  //#region Earning Leave
  earningGetAll,
  earningGetBalence,
  earningCreate,
  earningDelete,
  //#endregion

  //#region Salary
  salaryGetAll,
  salaryGetId,
  salaryDelete,
  salaryCreate,
  salaryUpdate,
  salaryArchiving,
  salaryExcel,
  //#endregion

  //#region Overtime
  overtimeGetAll,
  overtimeGetId,
  overtimeDelete,
  overtimeCreate,
  overtimeUpdate,
  overtimeExcel,
  //#endregion

  //#region Certificate
  certificateDelete,
  certificateCreate,
  //#endregion

  //#region MonthlySalary
  monthlySalaryGetAll,
  monthlySalaryExcel,
  monthlySalaryGet,
  monthlySalaryGetMonthly,
  monthlySalaryCreate,
  monthlySalaryUpdate,
  monthlySalaryUpdatePayment,
  //#endregion
};

//#region Staff
function staffArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingStaffClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.STAFF_ARCHIVING_REQUEST, params } }
  function success(isArchived) { return { type: staffConstants.STAFF_ARCHIVING_SUCCESS, isArchived } }
  function failure(error) { return { type: staffConstants.STAFF_ARCHIVING_FAILURE, error } }
}

function staffGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchStaffClient.search(params.name, params.identityNumber, params.projectId, params.hasBalance, params.items_per_page, params.page, params.order, params.sort)
      .then(
        staff => dispatch(success(staff)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.STAFF_GETALL_REQUEST } }
  function success(staff) { return { type: staffConstants.STAFF_GETALL_SUCCESS, staff } }
  function failure(error) { return { type: staffConstants.STAFF_GETALL_FAILURE, error } }
}

function staffExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchStaffClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.STAFF_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: staffConstants.STAFF_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: staffConstants.STAFF_EXCEL_FAILURE, error } }
}

function staffGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getStaffClient.get(id)
      .then(
        staff => dispatch(success(staff)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.STAFF_GETID_REQUEST, id } }
  function success(staff) { return { type: staffConstants.STAFF_GETID_SUCCESS, staff } }
  function failure(error) { return { type: staffConstants.STAFF_GETID_FAILURE, error } }
}

function staffDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteStaffClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.STAFF_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: staffConstants.STAFF_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: staffConstants.STAFF_DELETE_FAILURE, error } }
}

function staffCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createStaffClient.create(params)
      .then(
        staff => dispatch(success(staff)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.STAFF_CREATE_REQUEST, params } }
  function success(staff) { return { type: staffConstants.STAFF_CREATE_SUCCESS, staff } }
  function failure(error) { return { type: staffConstants.STAFF_CREATE_FAILURE, error } }
}

function staffUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateStaffClient.update(params)
      .then(
        staff => dispatch(success(staff, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: staffConstants.STAFF_UPDATE_REQUEST, params } }
  function success(staff, params) { return { type: staffConstants.STAFF_UPDATE_SUCCESS, staff, params } }
  function failure(error, params) { return { type: staffConstants.STAFF_UPDATE_FAILURE, error, params } }
}

function staffGetIdCheckout(params) {
  return dispatch => {
    dispatch(request(params));

    searchByStaffIdCheckoutClient.search(params)
      .then(
        staffCheckout => dispatch(success(staffCheckout)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.STAFF_GETID_CHECKOUT_REQUEST, id } }
  function success(staffCheckout) { return { type: staffConstants.STAFF_GETID_CHECKOUT_SUCCESS, staffCheckout } }
  function failure(error) { return { type: staffConstants.STAFF_GETID_CHECKOUT_FAILURE, error } }
}

function staffGetIdOvertime(id) {
  return dispatch => {
    dispatch(request(id));

    searchByStaffIdOvertimeClient.search(id)
      .then(
        staffOvertime => dispatch(success(staffOvertime)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.STAFF_GETID_OVERTIME_REQUEST, id } }
  function success(staffOvertime) { return { type: staffConstants.STAFF_GETID_OVERTIME_SUCCESS, staffOvertime } }
  function failure(error) { return { type: staffConstants.STAFF_GETID_OVERTIME_FAILURE, error } }
}

function staffGetIdLeave(id) {
  return dispatch => {
    dispatch(request(id));

    searchByStaffIdLeaveClient.search(id)
      .then(
        staffLeave => dispatch(success(staffLeave)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.STAFF_GETID_LEAVE_REQUEST, id } }
  function success(staffLeave) { return { type: staffConstants.STAFF_GETID_LEAVE_SUCCESS, staffLeave } }
  function failure(error) { return { type: staffConstants.STAFF_GETID_LEAVE_FAILURE, error } }
}

function staffGetIdSalary(id) {
  return dispatch => {
    dispatch(request(id));

    searchByStaffIdSalaryClient.search(id)
      .then(
        staffSalary => dispatch(success(staffSalary)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.STAFF_GETID_SALARY_REQUEST, id } }
  function success(staffSalary) { return { type: staffConstants.STAFF_GETID_SALARY_SUCCESS, staffSalary } }
  function failure(error) { return { type: staffConstants.STAFF_GETID_SALARY_FAILURE, error } }
}
//#endregion

//#region Leave
function leaveGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchLeaveClient.search(params.name, params.staffId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        leave => dispatch(success(leave)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.LEAVE_GETALL_REQUEST } }
  function success(leave) { return { type: staffConstants.LEAVE_GETALL_SUCCESS, leave } }
  function failure(error) { return { type: staffConstants.LEAVE_GETALL_FAILURE, error } }
}

function leaveExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchLeaveClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.LEAVE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: staffConstants.LEAVE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: staffConstants.LEAVE_EXCEL_FAILURE, error } }
}

function leaveGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getLeaveClient.get(id)
      .then(
        leave => dispatch(success(leave)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.LEAVE_GETID_REQUEST, id } }
  function success(leave) { return { type: staffConstants.LEAVE_GETID_SUCCESS, leave } }
  function failure(error) { return { type: staffConstants.LEAVE_GETID_FAILURE, error } }
}

function leaveDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteLeaveClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.LEAVE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: staffConstants.LEAVE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: staffConstants.LEAVE_DELETE_FAILURE, error } }
}

function leaveCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createLeaveClient.create(params)
      .then(
        leave => dispatch(success(leave)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.LEAVE_CREATE_REQUEST, params } }
  function success(leave) { return { type: staffConstants.LEAVE_CREATE_SUCCESS, leave } }
  function failure(error) { return { type: staffConstants.LEAVE_CREATE_FAILURE, error } }
}

function leaveUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateLeaveClient.update(params)
      .then(
        leave => dispatch(success(leave, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: staffConstants.LEAVE_UPDATE_REQUEST, params } }
  function success(leave, params) { return { type: staffConstants.LEAVE_UPDATE_SUCCESS, leave, params } }
  function failure(error, params) { return { type: staffConstants.LEAVE_UPDATE_FAILURE, error, params } }
}

//#endregion

//#region Earning Leave
function earningGetAll(id) {
  return dispatch => {
    dispatch(request(id));

    getAllEarningLeaveByStaffIdClient.getAll(id)
      .then(
        earning => dispatch(success(earning)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.EARNING_GETID_REQUEST, id } }
  function success(earning) { return { type: staffConstants.EARNING_GETID_SUCCESS, earning } }
  function failure(error) { return { type: staffConstants.EARNING_GETID_FAILURE, error } }
}

function earningGetBalence(id) {
  return dispatch => {
    dispatch(request(id));

    getBalanceEarningLeaveClient.get(id)
      .then(
        balance => dispatch(success(balance)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.EARNING_GETBALANCE_REQUEST, id } }
  function success(balance) { return { type: staffConstants.EARNING_GETBALANCE_SUCCESS, balance } }
  function failure(error) { return { type: staffConstants.EARNING_GETBALANCE_FAILURE, error } }
}

function earningCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createEarningLeaveClient.create(params)
      .then(
        earningCreate => dispatch(success(earningCreate, params)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.EARNING_CREATE_REQUEST, params } }
  function success(earningCreate, params) { return { type: staffConstants.EARNING_CREATE_SUCCESS, earningCreate, params } }
  function failure(error) { return { type: staffConstants.EARNING_CREATE_FAILURE, error } }
}

function earningDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteEarningLeaveClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted, params)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.EARNING_DELETE_REQUEST, params } }
  function success(isDeleted, params) { return { type: staffConstants.EARNING_DELETE_SUCCESS, isDeleted, params } }
  function failure(error) { return { type: staffConstants.EARNING_DELETE_FAILURE, error } }
}
//#endregion

//#region Salary
function salaryGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchSalaryClient.searchSalary(params.name, params.progressPaymentDate, params.projectId, params.staffId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        salary => dispatch(success(salary)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.SALARY_GETALL_REQUEST } }
  function success(salary) { return { type: staffConstants.SALARY_GETALL_SUCCESS, salary } }
  function failure(error) { return { type: staffConstants.SALARY_GETALL_FAILURE, error } }
}

function salaryExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchSalaryClient.excel(params.name, params.progressPaymentDate, params.projectId, params.staffId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.SALARY_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: staffConstants.SALARY_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: staffConstants.SALARY_EXCEL_FAILURE, error } }
}

function salaryGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getSalaryClient.get(id)
      .then(
        salary => dispatch(success(salary)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.SALARY_GETID_REQUEST, id } }
  function success(salary) { return { type: staffConstants.SALARY_GETID_SUCCESS, salary } }
  function failure(error) { return { type: staffConstants.SALARY_GETID_FAILURE, error } }
}

function salaryDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteSalaryClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.SALARY_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: staffConstants.SALARY_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: staffConstants.SALARY_DELETE_FAILURE, error } }
}

function salaryArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingSalaryClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.SALARY_ARCHIVING_REQUEST, params } }
  function success(isArchived) { return { type: staffConstants.SALARY_ARCHIVING_SUCCESS, isArchived } }
  function failure(error) { return { type: staffConstants.SALARY_ARCHIVING_FAILURE, error } }
}

function salaryCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createSalaryClient.create(params)
      .then(
        salary => dispatch(success(salary)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.SALARY_CREATE_REQUEST, params } }
  function success(salary) { return { type: staffConstants.SALARY_CREATE_SUCCESS, salary } }
  function failure(error) { return { type: staffConstants.SALARY_CREATE_FAILURE, error } }
}

function salaryUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateSalaryClient.update(params)
      .then(
        salary => dispatch(success(salary, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: staffConstants.SALARY_UPDATE_REQUEST, params } }
  function success(salary, params) { return { type: staffConstants.SALARY_UPDATE_SUCCESS, salary, params } }
  function failure(error, params) { return { type: staffConstants.SALARY_UPDATE_FAILURE, error, params } }
}

//#endregion

//#region Overtime
function overtimeGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchOvertimeClient.search(params.name, params.staffId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        overtime => dispatch(success(overtime)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.OVERTIME_GETALL_REQUEST } }
  function success(overtime) { return { type: staffConstants.OVERTIME_GETALL_SUCCESS, overtime } }
  function failure(error) { return { type: staffConstants.OVERTIME_GETALL_FAILURE, error } }
}

function overtimeExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchOvertimeClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.OVERTIME_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: staffConstants.OVERTIME_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: staffConstants.OVERTIME_EXCEL_FAILURE, error } }
}

function overtimeGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getOvertimeClient.get(id)
      .then(
        overtime => dispatch(success(overtime)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: staffConstants.OVERTIME_GETID_REQUEST, id } }
  function success(overtime) { return { type: staffConstants.OVERTIME_GETID_SUCCESS, overtime } }
  function failure(error) { return { type: staffConstants.OVERTIME_GETID_FAILURE, error } }
}

function overtimeDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteOvertimeClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.OVERTIME_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: staffConstants.OVERTIME_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: staffConstants.OVERTIME_DELETE_FAILURE, error } }
}

function overtimeCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createOvertimeClient.create(params)
      .then(
        overtime => dispatch(success(overtime)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.OVERTIME_CREATE_REQUEST, params } }
  function success(overtime) { return { type: staffConstants.OVERTIME_CREATE_SUCCESS, overtime } }
  function failure(error) { return { type: staffConstants.OVERTIME_CREATE_FAILURE, error } }
}

function overtimeUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateOvertimeClient.update(params)
      .then(
        overtime => dispatch(success(overtime, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: staffConstants.OVERTIME_UPDATE_REQUEST, params } }
  function success(overtime, params) { return { type: staffConstants.OVERTIME_UPDATE_SUCCESS, overtime, params } }
  function failure(error, params) { return { type: staffConstants.OVERTIME_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Certificate
function certificateDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteCertificateClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.CERTIFICATE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: staffConstants.CERTIFICATE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: staffConstants.CERTIFICATE_DELETE_FAILURE, error } }
}

function certificateCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createCertificateClient.create(params.StaffId, params.Name, params.StartDate, params.EndDate, params.File)
      .then(
        certificate => dispatch(success(certificate)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.CERTIFICATE_CREATE_REQUEST, params } }
  function success(certificate) { return { type: staffConstants.CERTIFICATE_CREATE_SUCCESS, certificate } }
  function failure(error) { return { type: staffConstants.CERTIFICATE_CREATE_FAILURE, error } }
}
//#endregion

//#region MonthlySalary
function monthlySalaryGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchMonthlySalaryClient.search(params.projectId, params.year, params.month, params.items_per_page, params.page, params.order, params.sort)
      .then(
        monthlySalary => dispatch(success(monthlySalary)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.MONTHLY_SALARY_GETALL_REQUEST } }
  function success(monthlySalary) { return { type: staffConstants.MONTHLY_SALARY_GETALL_SUCCESS, monthlySalary } }
  function failure(error) { return { type: staffConstants.MONTHLY_SALARY_GETALL_FAILURE, error } }
}

function monthlySalaryExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchMonthlySalaryClient.searchMonthlyExcel(params.projectId, params.year, params.month, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: staffConstants.MONTHLY_SALARY_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: staffConstants.MONTHLY_SALARY_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: staffConstants.MONTHLY_SALARY_EXCEL_FAILURE, error } }
}

function monthlySalaryGet(params) {
  return dispatch => {
    dispatch(request(params));

    getMonthlySalaryByYearMonthClient.get(params)
      .then(
        monthlySalary => dispatch(success(monthlySalary)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.MONTHLY_SALARY_GET_REQUEST, params } }
  function success(monthlySalary) { return { type: staffConstants.MONTHLY_SALARY_GET_SUCCESS, monthlySalary } }
  function failure(error) { return { type: staffConstants.MONTHLY_SALARY_GET_FAILURE, error } }
}

function monthlySalaryGetMonthly(params) {
  return dispatch => {
    dispatch(request(params));

    getMonthlySalaryClient.get(params)
      .then(
        monthlySalaryStaff => dispatch(success(monthlySalaryStaff)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.MONTHLY_SALARY_GET_MONTHLY_REQUEST, params } }
  function success(monthlySalaryStaff) { return { type: staffConstants.MONTHLY_SALARY_GET_MONTHLY_SUCCESS, monthlySalaryStaff } }
  function failure(error) { return { type: staffConstants.MONTHLY_SALARY_GET_MONTHLY_FAILURE, error } }
}

function monthlySalaryCreate(params) {
  return dispatch => {
    dispatch(request(params));

    updateMonthlySalaryClient.update(params)
      .then(
        salary => dispatch(success(salary)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: staffConstants.MONTHLY_SALARY_CREATE_REQUEST, params } }
  function success(salary) { return { type: staffConstants.MONTHLY_SALARY_CREATE_SUCCESS, salary } }
  function failure(error) { return { type: staffConstants.MONTHLY_SALARY_CREATE_FAILURE, error } }
}

function monthlySalaryUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateMonthlySalaryClient.update(params)
      .then(
        salary => dispatch(success(salary, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: staffConstants.MONTHLY_SALARY_UPDATE_REQUEST, params } }
  function success(salary, params) { return { type: staffConstants.MONTHLY_SALARY_UPDATE_SUCCESS, salary, params } }
  function failure(error, params) { return { type: staffConstants.MONTHLY_SALARY_UPDATE_FAILURE, error, params } }
}

function monthlySalaryUpdatePayment(params) {
  return dispatch => {
    dispatch(request(params));

    updateMonthlySalaryForPaymentClient.update(params)
      .then(
        payment => dispatch(success(payment, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: staffConstants.MONTHLY_SALARY_UPDATE_PAYMENT_REQUEST, params } }
  function success(payment, params) { return { type: staffConstants.MONTHLY_SALARY_UPDATE_PAYMENT_SUCCESS, payment, params } }
  function failure(error, params) { return { type: staffConstants.MONTHLY_SALARY_UPDATE_PAYMENT_FAILURE, error, params } }
}
//#endregion