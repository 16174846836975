import React from 'react'
import { useMsal } from "@azure/msal-react";
import { loginRequest, b2cPolicies } from "../../authConfig";

import './Auth.scss';

function Login() {
  const { instance } = useMsal();

  return (
    <div
      className='form w-100'
      noValidate
      id='kt_login_signin_form'
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Beneksus&apos;a Giriş Yapın</h1>
        <div className="text-gray-500 fw-semibold fs-6">Projeniz Kontrol Altında</div>
      </div>

      <div className='text-center'>
        <button
          className='btn btn-primary flex-center text-nowrap w-100'
          onClick={() => instance.loginRedirect(loginRequest)}
        >         
          Giriş Yapın
        </button>
      </div>
      <br />
      <div className='text-center'>
        <span
          className="link-primary fs-6 fw-bolder cursor-pointer"
          onClick={() => instance.loginRedirect({ authority: b2cPolicies.authorities.forgotPassword.authority })}
        >
          Şifremi Unuttum
        </span>
      </div>
    </div>
  )
}

export default Login;
