export const SourceType = {
  Note: 1,
  Staff: 2,
  Salary: 3,
  Leave: 4,
  Overtime: 5,
  Tenant: 6,
  SaleInvoice: 7,
  ExpenseInvoice: 8,
  Project: 9,
  RealEstate: 10,
  Company: 11,
  Check: 12,
  Product: 13,
  Warehouse: 14,
  OutgoingWaybill: 15,
  IncomingWaybill: 16,
  Account: 17,
  Proposal: 18,
  FileFolder: 19,
  Task: 20,
  PurchaseRequest: 21,
  PurchaseOrder: 22,
  ActivityReport: 23,
  Certificate: 24,
  PurchaseProposal: 25,
  WarehouseTransfer: 26,
  Contract: 27,
  GuaranteeLetter: 28,
  PaymentPlan: 29,
  OfficialExpense: 30,
  PaymentRequest: 31,
  BankPaymentOrder: 32,
  UserTask: 33,
}
