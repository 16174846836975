import { accountConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  excel: null,
  statement: null
}

export function account(state = {}, action) {
  switch (action.type) {
    //#region Account
    case accountConstants.BANKCASHACCOUNT_GETALL_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.BANKCASHACCOUNT_GETALL_SUCCESS:
      return {
        ...state,
        account: action.account,
        loading: false
      };
    case accountConstants.BANKCASHACCOUNT_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case accountConstants.ACCOUNT_SEARCHTRANSACTION_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.ACCOUNT_SEARCHTRANSACTION_SUCCESS:
      return {
        ...state,
        accountList: action.accountList,
        loading: false
      };
    case accountConstants.ACCOUNT_SEARCHTRANSACTION_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case accountConstants.BANKCASHACCOUNT_GETID_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.BANKCASHACCOUNT_GETID_SUCCESS:
      return {
        ...state,
        detail: action.account,
        loading: false
      };
    case accountConstants.BANKCASHACCOUNT_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case accountConstants.ACCOUNT_DELETE_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case accountConstants.ACCOUNT_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case accountConstants.ACCOUNT_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.ACCOUNT_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case accountConstants.ACCOUNT_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case accountConstants.BANKACCOUNT_CREATE_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.BANKACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.account,
        loading: false
      };
    case accountConstants.BANKACCOUNT_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case accountConstants.BANKACCOUNT_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.BANKACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.account || action.params,
        detail: action.params,
        loading: false
      };
    case accountConstants.BANKACCOUNT_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    
    case accountConstants.CASHACCOUNT_CREATE_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.CASHACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.account,
        loading: false
      };
    case accountConstants.CASHACCOUNT_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case accountConstants.CASHACCOUNT_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.CASHACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.account || action.params,
        detail: action.params,
        loading: false
      };
    case accountConstants.CASHACCOUNT_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case accountConstants.BANKCASHACCOUNT_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.BANKCASHACCOUNT_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case accountConstants.BANKCASHACCOUNT_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case accountConstants.CURRENTACCOUNT_GET_REQUEST:
      return { ...state, ...initialState };
    case accountConstants.CURRENTACCOUNT_GET_SUCCESS:
      return {
        ...state,
        statement: action.statement,
        loading: false
      };
    case accountConstants.CURRENTACCOUNT_GET_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion    
    default:
      return state
  }
}
