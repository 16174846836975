import { commonConstants } from '../constants';
import {
  GetAllRecordHistoryClient,
  GetAllNoteClient,
  GetAllNoteImagesClient,
  DeleteNoteClient,
  CreateNoteClient,
  GlobalSearchClient,
  GeneralDashboardClient,
  BankCashDashboardClient,
  GetAllActivityClient,
  DeleteFileClient,
  DeleteFileFolderClient,
  CreateFileFolderClient,
  CreateFileClient,
  RenameFileFolderClient,
  GetAllFolderWithFilesClient,
  GetRootFileFolderIdClient,
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//const SERVICE_URL = "https://localhost:7016";

const getAllRecordHistoryClient = new GetAllRecordHistoryClient(SERVICE_URL);
const getAllNoteClient = new GetAllNoteClient(SERVICE_URL);
const getAllNoteImagesClient = new GetAllNoteImagesClient(SERVICE_URL);
const createNoteClient = new CreateNoteClient(SERVICE_URL);
const deleteNoteClient = new DeleteNoteClient(SERVICE_URL);
const globalSearchClient = new GlobalSearchClient(SERVICE_URL);
const generalDashboardClient = new GeneralDashboardClient(SERVICE_URL);
const bankCashDashboardClient = new BankCashDashboardClient(SERVICE_URL);
const getAllActivityClient = new GetAllActivityClient(SERVICE_URL);
const deleteFileClient = new DeleteFileClient(SERVICE_URL);
const deleteFileFolderClient = new DeleteFileFolderClient(SERVICE_URL);
const createFileFolderClient = new CreateFileFolderClient(SERVICE_URL);
const createFileClient = new CreateFileClient(SERVICE_URL);
const renameFileFolderClient = new RenameFileFolderClient(SERVICE_URL);
const getAllFolderWithFilesClient = new GetAllFolderWithFilesClient(SERVICE_URL);
const getRootFileFolderIdClient = new GetRootFileFolderIdClient(SERVICE_URL);

export const commonActions = {
  historyGetAll,
  noteGetAll,
  noteGetAllImages,
  noteCreate,
  noteDelete,
  globalSearch,
  generalDashboard,
  bankCashDashboard,
  activityGetAll,
  fileDelete,
  fileFolderDelete,
  fileCreate,
  fileFolderCreate,
  fileFolderRename,
  fileFolderGetAll,
  fileFolderGetId,
};


function historyGetAll(params) {
  return dispatch => {
    dispatch(request(params));

    getAllRecordHistoryClient.getAll(params)
      .then(
        history => dispatch(success(history)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.HISTORY_GETALL_REQUEST, params } }
  function success(history) { return { type: commonConstants.HISTORY_GETALL_SUCCESS, history } }
  function failure(error) { return { type: commonConstants.HISTORY_GETALL_FAILURE, error } }
}

function noteGetAll(params) {
  return dispatch => {
    dispatch(request(params));

    getAllNoteClient.getAll(params)
      .then(
        note => dispatch(success(note)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.NOTE_GETALL_REQUEST, params } }
  function success(note) { return { type: commonConstants.NOTE_GETALL_SUCCESS, note } }
  function failure(error) { return { type: commonConstants.NOTE_GETALL_FAILURE, error } }
}

function noteGetAllImages(params) {
  return dispatch => {
    dispatch(request(params));

    getAllNoteImagesClient.getAllNoteImages(params)
      .then(
        noteImages => dispatch(success(noteImages)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.NOTE_IMAGES_GETALL_REQUEST, params } }
  function success(noteImages) { return { type: commonConstants.NOTE_IMAGES_GETALL_SUCCESS, noteImages } }
  function failure(error) { return { type: commonConstants.NOTE_IMAGES_GETALL_FAILURE, error } }
}

function noteCreate(Description, SourceId, SourceTypeId, File) {
  return dispatch => {
    dispatch(request(Description, SourceId, SourceTypeId, File));

    createNoteClient.create(Description, SourceId, SourceTypeId, File)
      .then(
        noteCreate => dispatch(success(noteCreate)),
        error => dispatch(failure(error))
      );
  };

  function request(Description, SourceId, SourceTypeId, File) { return { type: commonConstants.NOTE_CREATE_REQUEST, Description, SourceId, SourceTypeId, File } }
  function success(noteCreate) { return { type: commonConstants.NOTE_CREATE_SUCCESS, noteCreate } }
  function failure(error) { return { type: commonConstants.NOTE_CREATE_FAILURE, error } }
}

function noteDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteNoteClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted, params)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.NOTE_DELETE_REQUEST, params } }
  function success(isDeleted, params) { return { type: commonConstants.NOTE_DELETE_SUCCESS, isDeleted, params } }
  function failure(error) { return { type: commonConstants.NOTE_DELETE_FAILURE, error } }
}

function globalSearch(params) {
  return dispatch => {
    dispatch(request(params));

    globalSearchClient.getAll(params)
      .then(
        search => dispatch(success(search)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.GLOBALSEARCH_GETALL_REQUEST, params } }
  function success(search) { return { type: commonConstants.GLOBALSEARCH_GETALL_SUCCESS, search } }
  function failure(error) { return { type: commonConstants.GLOBALSEARCH_GETALL_FAILURE, error } }
}

function generalDashboard(params) {
  return dispatch => {
    dispatch(request(params));

    generalDashboardClient.get(params)
      .then(
        generalDashResult => dispatch(success(generalDashResult)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.GENERAL_DASHBOARD_GET_REQUEST, params } }
  function success(generalDashResult) { return { type: commonConstants.GENERAL_DASHBOARD_GET_SUCCESS, generalDashResult } }
  function failure(error) { return { type: commonConstants.GENERAL_DASHBOARD_GET_FAILURE, error } }
}

function bankCashDashboard(params) {
  return dispatch => {
    dispatch(request(params));

    bankCashDashboardClient.get(params)
      .then(
        bankCashDashResult => dispatch(success(bankCashDashResult)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.BANKCASH_DASHBOARD_GET_REQUEST, params } }
  function success(bankCashDashResult) { return { type: commonConstants.BANKCASH_DASHBOARD_GET_SUCCESS, bankCashDashResult } }
  function failure(error) { return { type: commonConstants.BANKCASH_DASHBOARD_GET_FAILURE, error } }
}

function activityGetAll(params) {
  return dispatch => {
    dispatch(request(params));

    getAllActivityClient.getAll(params)
      .then(
        activity => dispatch(success(activity)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.ACTIVITY_GETALL_REQUEST, params } }
  function success(activity) { return { type: commonConstants.ACTIVITY_GETALL_SUCCESS, activity } }
  function failure(error) { return { type: commonConstants.ACTIVITY_GETALL_FAILURE, error } }
}

function fileDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteFileClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.FILE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: commonConstants.FILE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: commonConstants.FILE_DELETE_FAILURE, error } }
}

function fileFolderDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteFileFolderClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.FILEFOLDER_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: commonConstants.FILEFOLDER_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: commonConstants.FILEFOLDER_DELETE_FAILURE, error } }
}

function fileCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createFileClient.create(params?.SourceId, params?.SourceTypeId, params?.FormFile)
      .then(
        file => dispatch(success(file)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.FILE_CREATE_REQUEST, params } }
  function success(file) { return { type: commonConstants.FILE_CREATE_SUCCESS, file } }
  function failure(error) { return { type: commonConstants.FILE_CREATE_FAILURE, error } }
}

function fileFolderCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createFileFolderClient.create(params)
      .then(
        fileFolder => dispatch(success(fileFolder)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.FILEFOLDER_CREATE_REQUEST, params } }
  function success(fileFolder) { return { type: commonConstants.FILEFOLDER_CREATE_SUCCESS, fileFolder } }
  function failure(error) { return { type: commonConstants.FILEFOLDER_CREATE_FAILURE, error } }
}

function fileFolderRename(params) {
  return dispatch => {
    dispatch(request(params));

    renameFileFolderClient.update(params)
      .then(
        rename => dispatch(success(rename, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: commonConstants.FILEFOLDER_RENAME_REQUEST, params } }
  function success(rename, params) { return { type: commonConstants.FILEFOLDER_RENAME_SUCCESS, rename, params } }
  function failure(error, params) { return { type: commonConstants.FILEFOLDER_RENAME_FAILURE, error, params } }
}

function fileFolderGetAll(params) {
  return dispatch => {
    dispatch(request(params));

    getAllFolderWithFilesClient.getAll(params)
      .then(
        getAllWithFiles => dispatch(success(getAllWithFiles)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.FILEFOLDER_GETALLWITHFILES_REQUEST, params } }
  function success(getAllWithFiles) { return { type: commonConstants.FILEFOLDER_GETALLWITHFILES_SUCCESS, getAllWithFiles } }
  function failure(error) { return { type: commonConstants.FILEFOLDER_GETALLWITHFILES_FAILURE, error } }
}

function fileFolderGetId(params) {
  return dispatch => {
    dispatch(request(params));

    getRootFileFolderIdClient.getAll(params)
      .then(
        getFileFolderId => dispatch(success(getFileFolderId)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: commonConstants.FILEFOLDER_GETID_REQUEST, params } }
  function success(getFileFolderId) { return { type: commonConstants.FILEFOLDER_GETID_SUCCESS, getFileFolderId } }
  function failure(error) { return { type: commonConstants.FILEFOLDER_GETID_FAILURE, error } }
}