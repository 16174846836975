import React from 'react'
import { createRoot } from 'react-dom/client';
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../core';
import * as WebDataRocksReact from 'react-webdatarocks';
import { GetDynamicReportEntityClient } from '../../../api'

//https://github.com/WebDataRocks/pivot-react/tree/master/typescript/src

function SaleWithBalance() {
  const intl = useIntl()
  const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
  const reportClient = new GetDynamicReportEntityClient(SERVICE_URL);

  reportClient.getSaleWithBalanceQueryResponse().then(reportJson => {
    let report = {
      "formats": [
        {
          "name": "",
          "decimalPlaces": 2,
          "thousandsSeparator": ".",
          "decimalSeparator": ","
        }
      ],
      dataSource: {
        data: reportJson
      },
      localization: `https://cdn.webdatarocks.com/loc/${intl.locale}.json`,
      options: {
        grid: {
          type: "flat",
          showGrandTotals: "off"
        },
        showEmptyData: false
      },
      slice: {
        "columns": [
          {
            "uniqueName": "companyName",
            "caption": "MÜŞTERİ"
          },
          {
            "uniqueName": "expenseInvoiceName",
            "caption": "SATIŞ FATURASI"
          },
          {
            "uniqueName": "paidAmount",
            "caption": "ÖDENEN TUTAR"
          },
          {
            "uniqueName": "totalPrice",
            "caption": "TOPLAM TUTAR"
          },
          {
            "uniqueName": "defermentDate",
            "caption": "VADE TARİHİ"
          },
          {
            "uniqueName": "editDate",
            "caption": "DÜZENLEME TARİHİ"
          }
        ]
      }
    };

    const root = createRoot(document.getElementById('report-app'));
    root.render(<WebDataRocksReact.Pivot
      toolbar={true}
      width="100%"
      height="100%"
      report={report}
      beforetoolbarcreated={customizeToolbar}
    />);
  })

  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      delete tabs[0]; // delete Connect
      delete tabs[1]; // delete Open
      return tabs;
    }
  }

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'DYNAMICREPORT.REPORT.SALEWITHBALANCE' })}</PageTitle>
      <div className="App" id="report-app" style={{height: '80vh'}} />
    </>
  )
}
export { SaleWithBalance };
