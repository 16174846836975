export const gibinvoiceConstants = {
  //#region Invoice

  GIBINVOICE_SEND_REQUEST: 'GIBINVOICE_SEND_REQUEST',
  GIBINVOICE_SEND_SUCCESS: 'GIBINVOICE_SEND_SUCCESS',
  GIBINVOICE_SEND_FAILURE: 'GIBINVOICE_SEND_FAILURE',

  GIBINVOICE_DOCUMENTPDF_REQUEST: 'GIBINVOICE_DOCUMENTPDF_REQUEST',
  GIBINVOICE_DOCUMENTPDF_SUCCESS: 'GIBINVOICE_DOCUMENTPDF_SUCCESS',
  GIBINVOICE_DOCUMENTPDF_FAILURE: 'GIBINVOICE_DOCUMENTPDF_FAILURE',

  GIBINVOICE_INVOICEPDF_REQUEST: 'GIBINVOICE_INVOICEPDF_REQUEST',
  GIBINVOICE_INVOICEPDF_SUCCESS: 'GIBINVOICE_INVOICEPDF_SUCCESS',
  GIBINVOICE_INVOICEPDF_FAILURE: 'GIBINVOICE_INVOICEPDF_FAILURE',

  GIBINVOICE_GETALL_REQUEST: 'GIBINVOICE_GETALL_REQUEST',
  GIBINVOICE_GETALL_SUCCESS: 'GIBINVOICE_GETALL_SUCCESS',
  GIBINVOICE_GETALL_FAILURE: 'GIBINVOICE_GETALL_FAILURE',

  GIBINVOICE_GETID_REQUEST: 'GIBINVOICE_GETID_REQUEST',
  GIBINVOICE_GETID_SUCCESS: 'GIBINVOICE_GETID_SUCCESS',
  GIBINVOICE_GETID_FAILURE: 'GIBINVOICE_GETID_FAILURE',

  //#endregion            
};
