export const lookupConstants = {
  BASIC_LOOKUP_REQUEST: 'BASIC_LOOKUP_REQUEST',
  BASIC_LOOKUP_SUCCESS: 'BASIC_LOOKUP_SUCCESS',
  BASIC_LOOKUP_FAILURE: 'BASIC_LOOKUP_FAILURE',

  TENANT_LOOKUP_STAFF_REQUEST: 'TENANT_LOOKUP_STAFF_REQUEST',
  TENANT_LOOKUP_STAFF_SUCCESS: 'TENANT_LOOKUP_STAFF_SUCCESS',
  TENANT_LOOKUP_STAFF_FAILURE: 'TENANT_LOOKUP_STAFF_FAILURE',

  TENANT_LOOKUP_ACCOUNT_REQUEST: 'TENANT_LOOKUP_ACCOUNT_REQUEST',
  TENANT_LOOKUP_ACCOUNT_SUCCESS: 'TENANT_LOOKUP_ACCOUNT_SUCCESS',
  TENANT_LOOKUP_ACCOUNT_FAILURE: 'TENANT_LOOKUP_ACCOUNT_FAILURE',

  TENANT_LOOKUP_EXTENDED_ACCOUNT_REQUEST: 'TENANT_LOOKUP_EXTENDED_ACCOUNT_REQUEST',
  TENANT_LOOKUP_EXTENDED_ACCOUNT_SUCCESS: 'TENANT_LOOKUP_EXTENDED_ACCOUNT_SUCCESS',
  TENANT_LOOKUP_EXTENDED_ACCOUNT_FAILURE: 'TENANT_LOOKUP_EXTENDED_ACCOUNT_FAILURE',

  DISTRICT_LOOKUP_REQUEST: 'DISTRICT_LOOKUP_REQUEST',
  DISTRICT_LOOKUP_SUCCESS: 'DISTRICT_LOOKUP_SUCCESS',
  DISTRICT_LOOKUP_FAILURE: 'DISTRICT_LOOKUP_FAILURE',

  TENANT_LOOKUP_COMPANY_REQUEST: 'TENANT_LOOKUP_COMPANY_REQUEST',
  TENANT_LOOKUP_COMPANY_SUCCESS: 'TENANT_LOOKUP_COMPANY_SUCCESS',
  TENANT_LOOKUP_COMPANY_FAILURE: 'TENANT_LOOKUP_COMPANY_FAILURE',

  TENANT_LOOKUP_PRODUCT_REQUEST: 'TENANT_LOOKUP_PRODUCT_REQUEST',
  TENANT_LOOKUP_PRODUCT_SUCCESS: 'TENANT_LOOKUP_PRODUCT_SUCCESS',
  TENANT_LOOKUP_PRODUCT_FAILURE: 'TENANT_LOOKUP_PRODUCT_FAILURE',

  TENANT_LOOKUP_USABLECHECK_REQUEST: 'TENANT_LOOKUP_USABLECHECK_REQUEST',
  TENANT_LOOKUP_USABLECHECK_SUCCESS: 'TENANT_LOOKUP_USABLECHECK_SUCCESS',
  TENANT_LOOKUP_USABLECHECK_FAILURE: 'TENANT_LOOKUP_USABLECHECK_FAILURE',

  TENANT_LOOKUP_PROJECT_REQUEST: 'TENANT_LOOKUP_PROJECT_REQUEST',
  TENANT_LOOKUP_PROJECT_SUCCESS: 'TENANT_LOOKUP_PROJECT_SUCCESS',
  TENANT_LOOKUP_PROJECT_FAILURE: 'TENANT_LOOKUP_PROJECT_FAILURE',

  TENANT_LOOKUP_ALL_PROJECT_REQUEST: 'TENANT_LOOKUP_ALL_PROJECT_REQUEST',
  TENANT_LOOKUP_ALL_PROJECT_SUCCESS: 'TENANT_LOOKUP_ALL_PROJECT_SUCCESS',
  TENANT_LOOKUP_ALL_PROJECT_FAILURE: 'TENANT_LOOKUP_ALL_PROJECT_FAILURE',

  TENANT_LOOKUP_STOCKPRODUCT_REQUEST: 'TENANT_LOOKUP_STOCKPRODUCT_REQUEST',
  TENANT_LOOKUP_STOCKPRODUCT_SUCCESS: 'TENANT_LOOKUP_STOCKPRODUCT_SUCCESS',
  TENANT_LOOKUP_STOCKPRODUCT_FAILURE: 'TENANT_LOOKUP_STOCKPRODUCT_FAILURE',

  TENANT_LOOKUP_WAREHOUSE_REQUEST: 'TENANT_LOOKUP_WAREHOUSE_REQUEST',
  TENANT_LOOKUP_WAREHOUSE_SUCCESS: 'TENANT_LOOKUP_WAREHOUSE_SUCCESS',
  TENANT_LOOKUP_WAREHOUSE_FAILURE: 'TENANT_LOOKUP_WAREHOUSE_FAILURE',

  TENANT_LOOKUP_ROLE_REQUEST: 'TENANT_LOOKUP_ROLE_REQUEST',
  TENANT_LOOKUP_ROLE_SUCCESS: 'TENANT_LOOKUP_ROLE_SUCCESS',
  TENANT_LOOKUP_ROLE_FAILURE: 'TENANT_LOOKUP_ROLE_FAILURE',

  TENANT_LOOKUP_PRODUCT_FOR_UPDATE_REQUEST: 'TENANT_LOOKUP_PRODUCT_FOR_UPDATE_REQUEST',
  TENANT_LOOKUP_PRODUCT_FOR_UPDATE_SUCCESS: 'TENANT_LOOKUP_PRODUCT_FOR_UPDATE_SUCCESS',
  TENANT_LOOKUP_PRODUCT_FOR_UPDATE_FAILURE: 'TENANT_LOOKUP_PRODUCT_FOR_UPDATE_FAILURE',

  TENANT_LOOKUP_SCORECARD_REQUEST: 'TENANT_LOOKUP_SCORECARD_REQUEST',
  TENANT_LOOKUP_SCORECARD_SUCCESS: 'TENANT_LOOKUP_SCORECARD_SUCCESS',
  TENANT_LOOKUP_SCORECARD_FAILURE: 'TENANT_LOOKUP_SCORECARD_FAILURE',

  TENANT_LOOKUP_DYNAMICREPORT_ROLE_REQUEST: 'TENANT_LOOKUP_DYNAMICREPORT_ROLE_REQUEST',
  TENANT_LOOKUP_DYNAMICREPORT_ROLE_SUCCESS: 'TENANT_LOOKUP_DYNAMICREPORT_ROLE_SUCCESS',
  TENANT_LOOKUP_DYNAMICREPORT_ROLE_FAILURE: 'TENANT_LOOKUP_DYNAMICREPORT_ROLE_FAILURE',

  TENANT_LOOKUP_CONSTRUCTION_SITE_REQUEST: 'TENANT_LOOKUP_CONSTRUCTION_SITE_REQUEST',
  TENANT_LOOKUP_CONSTRUCTION_SITE_SUCCESS: 'TENANT_LOOKUP_CONSTRUCTION_SITE_SUCCESS',
  TENANT_LOOKUP_CONSTRUCTION_SITE_FAILURE: 'TENANT_LOOKUP_CONSTRUCTION_SITE_FAILURE',

  TENANT_LOOKUP_BLOCK_REQUEST: 'TENANT_LOOKUP_BLOCK_REQUEST',
  TENANT_LOOKUP_BLOCK_SUCCESS: 'TENANT_LOOKUP_BLOCK_SUCCESS',
  TENANT_LOOKUP_BLOCK_FAILURE: 'TENANT_LOOKUP_BLOCK_FAILURE',

  TENANT_LOOKUP_PLOT_REQUEST: 'TENANT_LOOKUP_PLOT_REQUEST',
  TENANT_LOOKUP_PLOT_SUCCESS: 'TENANT_LOOKUP_PLOT_SUCCESS',
  TENANT_LOOKUP_PLOT_FAILURE: 'TENANT_LOOKUP_PLOT_FAILURE',

  TENANT_LOOKUP_BUILDINGBLOCKTYPE_REQUEST: 'TENANT_LOOKUP_BUILDINGBLOCKTYPE_REQUEST',
  TENANT_LOOKUP_BUILDINGBLOCKTYPE_SUCCESS: 'TENANT_LOOKUP_BUILDINGBLOCKTYPE_SUCCESS',
  TENANT_LOOKUP_BUILDINGBLOCKTYPE_FAILURE: 'TENANT_LOOKUP_BUILDINGBLOCKTYPE_FAILURE',

  TENANT_LOOKUP_ADVANCECHECK_REQUEST: 'TENANT_LOOKUP_ADVANCECHECK_REQUEST',
  TENANT_LOOKUP_ADVANCECHECK_SUCCESS: 'TENANT_LOOKUP_ADVANCECHECK_SUCCESS',
  TENANT_LOOKUP_ADVANCECHECK_FAILURE: 'TENANT_LOOKUP_ADVANCECHECK_FAILURE',

  TENANT_LOOKUP_ALLACCOUNT_REQUEST: 'TENANT_LOOKUP_ALLACCOUNT_REQUEST',
  TENANT_LOOKUP_ALLACCOUNT_SUCCESS: 'TENANT_LOOKUP_ALLACCOUNT_SUCCESS',
  TENANT_LOOKUP_ALLACCOUNT_FAILURE: 'TENANT_LOOKUP_ALLACCOUNT_FAILURE',

  TENANT_LOOKUP_ALLCOMPANYBYPROJECT_REQUEST: 'TENANT_LOOKUP_ALLCOMPANYBYPROJECT_REQUEST',
  TENANT_LOOKUP_ALLCOMPANYBYPROJECT_SUCCESS: 'TENANT_LOOKUP_ALLCOMPANYBYPROJECT_SUCCESS',
  TENANT_LOOKUP_ALLCOMPANYBYPROJECT_FAILURE: 'TENANT_LOOKUP_ALLCOMPANYBYPROJECT_FAILURE',

  TENANT_LOOKUP_COMPANYINFORMATION_REQUEST: 'TENANT_LOOKUP_COMPANYINFORMATION_REQUEST',
  TENANT_LOOKUP_COMPANYINFORMATION_SUCCESS: 'TENANT_LOOKUP_COMPANYINFORMATION_SUCCESS',
  TENANT_LOOKUP_COMPANYINFORMATION_FAILURE: 'TENANT_LOOKUP_COMPANYINFORMATION_FAILURE',

  TENANT_LOOKUP_USERPROJECT_REQUEST: 'TENANT_LOOKUP_USERPROJECT_REQUEST',
  TENANT_LOOKUP_USERPROJECT_SUCCESS: 'TENANT_LOOKUP_USERPROJECT_SUCCESS',
  TENANT_LOOKUP_USERPROJECT_FAILURE: 'TENANT_LOOKUP_USERPROJECT_FAILURE',

  TENANT_LOOKUP_ALLUSER_REQUEST: 'TENANT_LOOKUP_ALLUSER_REQUEST',
  TENANT_LOOKUP_ALLUSER_SUCCESS: 'TENANT_LOOKUP_ALLUSER_SUCCESS',
  TENANT_LOOKUP_ALLUSER_FAILURE: 'TENANT_LOOKUP_ALLUSER_FAILURE',
};
