/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, DeleteModal, ArchiveModal, Category } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from '../../../redux/actions';
import { SourceType, KTSVG, detailPageUrl, DetailPage } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, error } = useSelector(state => state.project);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(projectActions.realEstateGetId(id));
  }, [])

  const handleDelete = () => dispatch(projectActions.realEstateDelete({ id }));

  const handleArchive = () => dispatch(projectActions.realEstateArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.ESTATES' }),
      path: '/projects/estates',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.ESTATES.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>                      
          {detail && <>
              <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>                          
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">                         

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen018.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.address} {detail.provinceName} - {detail.districtName}
                          </div>
                       
                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.RealEstate} />
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'REALESTATE.GET.ESTATESTATENAME' })}</div>
                          <div className="fw-bold fs-4">{detail.estateStateName}</div>                         
                        </div>
                        {detail?.estateTypeName && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'REALESTATE.GET.ESTATETYPENAME' })}</div>
                            <div className="fw-bold fs-4">{detail.estateTypeName}</div>
                          </div>
                        </>}
                        
                        {detail?.grossArea && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'REALESTATE.GET.GROSSAREA' })} - {intl.formatMessage({ id: 'REALESTATE.GET.NETAREA' })}</div>
                            <div className="fw-bold fs-4">{detail.grossArea} &#13217; - {detail.netArea} &#13217;</div>
                          </div>
                        </>}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">                          
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'REALESTATE.GET.BASESALEPRICE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail.baseSalePrice, { style: 'currency', currency: 'try' })}</span></div>
                        </div>

                      </div>
                    </div>
                  </div>                
                </div>
                {detail?.description && <>
                  <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                    {detail?.description}
                  </div>
                </>}      
              </KTCardBody>
            </KTCard>

              <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>                                          
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'REALESTATE.GET.BASEPURCHASEPRICE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.basePurchasePrice, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'REALESTATE.GET.BASESALEPRICE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.baseSalePrice, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'REALESTATE.GET.TAXNAME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.taxName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'REALESTATE.GET.PURCHASEPRICEWITHTAX' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.purchasePriceWithTax, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'REALESTATE.GET.SALEPRICEWITHTAX' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.salePriceWithTax, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          {detail?.saleInvoiceId > 0 && <div className="row mb-7">
                            <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'REALESTATE.GET.SALEINVOICE' })}</label>
                            <div className="col-lg-8 fv-row"><Link to={`/sales/sales-invoices/detail/${detail.saleInvoiceId}`} className="btn btn-sm btn-outline btn-outline-info">{intl.formatMessage({ id: 'BUTTON.LINK' })}</Link></div>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>
        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.RealEstate} />
        </div>
      </div>
      
      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal 
        onClick={handleArchive} 
        onOpen={showArchive} 
        onClose={() => setShowArchive(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
