/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams} from "react-router-dom";
import { PageTitle } from '../../../core';
import { Link } from "react-router-dom";

import { Audit, KTCard, KTCardBody, KTCardHeader, Notes, Select, Timeline } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { checkActions, lookupActions } from '../../../redux/actions';
import { SourceType, KTSVG, detailPageUrl, DetailPage, ExpenseDetailPage } from '../../../helpers';

import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Detail() {
  const [isCollapse, setIsCollapse] = useState(true);

  const { id } = useParams();
  const intl = useIntl()
  const pageUrl = location.pathname.split('/')

  const { detail, transfer, loading } = useSelector(state => state.check);
  const { accountLookup } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkActions.checkGetId(id));
  }, [id, transfer])

  useEffect(() => {
    if (detail?.checkStateId == 1 || detail?.checkStateId == 2) dispatch(lookupActions.bankCashAccountGetAll());

    if (detail?.checkTypeId == 1) {
      setStepSchema({
        ToAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    } else {
      setStepSchema({
        FromAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    }
  }, [detail])

  const [stepSchema, setStepSchema] = useState()
  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    CheckId: Number(id),
    FromAccountId: '',
    ToAccountId: '',
    PaymentDate: new Date(),
    Description: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      if (detail?.checkTypeId == 1) {
        dispatch(checkActions.checkAddCollection(values));
      } else {
        dispatch(checkActions.checkAddPayment(values));
      }

      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.CHECKS' }),
      path: '/cash/checks',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.CHECKS.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>


          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.description}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                          {detail?.bankName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/finance/fin001.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.bankName}
                            </div>
                          </>}

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/finance/fin004.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.checkNo}
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">                            
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>
                          </div>

                        </div>
                      </div>
                      
                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CHECK.GET.TOTALPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail.totalPrice, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CHECK.GET.CHECKTYPE' })}</div>
                          <div className="fw-bold fs-4">{detail.checkTypeName}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CHECK.GET.CHECKSTATE' })}</div>
                          <div className="fw-bold fs-4">{detail.checkStateName}</div>
                        </div>
                        {detail?.defermentDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CHECK.GET.DEFERMENTDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}                      
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CHECK.GET.EDITDATE' })}</label>
                          {detail?.editDate && <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.editDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>}
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CHECK.GET.FROMCOMPANY' })}</label>                          
                          <div className="col-lg-8 fv-row"><Link to={detailPageUrl(DetailPage.CompanySupplier, detail.fromCompanyId)} className='text-dark fw-bold d-block fs-6 text-hover-primary' target='_blank'>{detail.fromCompanyName}</Link></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CHECK.GET.TOCOMPANY' })}</label>
                          <div className="col-lg-8 fv-row"><Link to={detailPageUrl(DetailPage.CompanySupplier, detail.toCompanyId)} className='text-dark fw-bold d-block fs-6 text-hover-primary' target='_blank'>{detail.toCompanyName}</Link></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CHECK.GET.SALEINVOICE' })}</label>                          
                          <div className="col-lg-8 fv-row"><Link to={detailPageUrl(DetailPage.SaleInvoice, detail.saleInvoiceId)} className='text-dark fw-bold d-block fs-6 text-hover-primary' target='_blank'>{detail.saleInvoiceName}</Link></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CHECK.GET.EXPENSEINVOICE' })}</label>                          
                          <div className="col-lg-8 fv-row"><Link to={detailPageUrl(ExpenseDetailPage[1], detail.expenseInvoiceId)} className='text-dark fw-bold d-block fs-6 text-hover-primary' target='_blank'>{detail.expenseInvoiceName}</Link></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CHECK.GET.EXPENSEINVOICECOMPANYNAME' })}</label>
                          <div className="col-lg-8 fv-row"><Link to={detailPageUrl(DetailPage.CompanySupplier, detail?.expenseInvoiceCompanyId)} className='text-dark fw-bold d-block fs-6 text-hover-primary' target='_blank'>{detail?.expenseInvoiceCompanyName}</Link></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CHECK.GET.SALEINVOICECOMPANYNAME' })}</label>
                          <div className="col-lg-8 fv-row"><Link to={detailPageUrl(DetailPage.CompanySupplier, detail?.saleInvoiceCompanyId)} className='text-dark fw-bold d-block fs-6 text-hover-primary' target='_blank'>{detail?.saleInvoiceCompanyName}</Link></div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>           
          </>}

        </div>

        <div className='col-xl-3'>
          {detail && (detail?.checkStateId == 1 || detail?.checkStateId == 2) && <KTCard className='mb-5'>
            <KTCardHeader title={intl.formatMessage({ id: detail?.checkTypeId == 1 ? 'CHECK.GET.ADDCOLLECTION' : 'CHECK.GET.ADDPAYMENT' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody
              className='p-5'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECK.ADDCOLLECTION.TOACCOUNTID' })}</label>
                  {detail?.checkTypeId == 1 && <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select
                      data={accountLookup}
                      {...formik.getFieldProps('ToAccountId')}
                      required
                      onChange={item => formik.setFieldValue('ToAccountId', Number(item?.value))}
                    />
                    {formik.touched.ToAccountId && formik.errors.ToAccountId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.ToAccountId}</span>
                        </div>
                      </div>
                    )}
                  </div>}

                  {detail?.checkTypeId != 1 && <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select
                      data={accountLookup}
                      {...formik.getFieldProps('FromAccountId')}
                      required
                      onChange={item => formik.setFieldValue('FromAccountId', Number(item?.value))}
                    />
                    {formik.touched.FromAccountId && formik.errors.FromAccountId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.FromAccountId}</span>
                        </div>
                      </div>
                    )}
                  </div>}
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECK.ADDCOLLECTION.PAYMENTDATE' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        {...formik.getFieldProps('PaymentDate')}
                        onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                      />
                    </div>
                    {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.PaymentDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CHECK.ADDCOLLECTION.DESCRIPTION' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <textarea
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={2}
                      {...formik.getFieldProps('Description')}
                    />
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>}

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Check} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.Check} collapse={false} />

          {detail?.uniqueIdentifier && <Audit Guid={detail?.uniqueIdentifier} />}
        </div>
      </div>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
