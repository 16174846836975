import { reportingConstants } from '../constants';
import {
  SearchDynamicReportContollerClient,
  GetDynamicReportEntityClient,
  SearchAuditClient,
  GetAuditClient,
  ListAuditClient,
  CashFlowReportContollerClient
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

//#region DynamicReport
const searchDynamicReportContollerClient = new SearchDynamicReportContollerClient(SERVICE_URL);
//#endregion  

//#region CashFlow
const cashFlowReportContollerClient = new CashFlowReportContollerClient(SERVICE_URL);
//#endregion  

//#region SaleinvoiceReport & ExpenseinvoiceReport
const getDynamicReportEntityClient = new GetDynamicReportEntityClient(SERVICE_URL);
//#endregion  

//#region Audit
const searchAuditClient = new SearchAuditClient(SERVICE_URL);
const getAuditClient = new GetAuditClient(SERVICE_URL);
const listAuditClient = new ListAuditClient(SERVICE_URL);
//#endregion  

export const reportingActions = {
  //#region DynamicReport
  searchDynamicReportRequestGetAll,
  //#endregion  

  //#region CashFlow
  cashFlowReportGetAll,
  //#endregion  

  //#region SaleinvoiceReport
  saleInvoiceChartCategoryGetAll,
  saleInvoiceChartProjectGetAll,
  saleInvoiceChartDateGetAll,
  saleInvoiceChartMonthGetAll,
  saleInvoiceChartStatusGetAll,
  //#endregion  

  //#region ExpenseinvoiceReport
  expenseInvoiceChartCategoryGetAll,
  expenseInvoiceChartProjectGetAll,
  expenseInvoiceChartDateGetAll,
  expenseInvoiceChartMonthGetAll,
  expenseInvoiceChartStatusGetAll,
  //#endregion  

  //#region WorkProgramReport
  workPlanProjectCompleteRateByProjectGetAll,
  workPlanConstructionSiteCompleteRateByProjectGetAll,
  workPlanBlockCompleteRateByConstructionSiteGetAll,
  workPlanRootTaskCompleteRateByProjectGetAll,
  workPlanDailyTaskCompleteRateByProjectGetAll,
  //#endregion  

  //#region Audit
  auditGetAll,
  auditGetId,
  auditGetList
  //#endregion  
}

//#region DynamicReport
function searchDynamicReportRequestGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchDynamicReportContollerClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        dynamicreport => dispatch(success(dynamicreport)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.DYNAMICREPORT_SEARCH_REQUEST } }
  function success(dynamicreport) { return { type: reportingConstants.DYNAMICREPORT_SEARCH_SUCCESS, dynamicreport } }
  function failure(error) { return { type: reportingConstants.DYNAMICREPORT_SEARCH_FAILURE, error } }
}
//#endregion  

//#region SaleinvoiceReport
function saleInvoiceChartCategoryGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.saleInvoiceChartCategory(params.StartDate, params.EndDate, params.SaleCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        saleinvoiceCategory => dispatch(success(saleinvoiceCategory)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.SALEINVOICE_CATEGORY_REQUEST } }
  function success(saleinvoiceCategory) { return { type: reportingConstants.SALEINVOICE_CATEGORY_SUCCESS, saleinvoiceCategory } }
  function failure(error) { return { type: reportingConstants.SALEINVOICE_CATEGORY_FAILURE, error } }
}

function saleInvoiceChartProjectGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.saleInvoiceChartProject(params.StartDate, params.EndDate, params.SaleCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        saleinvoiceProject => dispatch(success(saleinvoiceProject)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.SALEINVOICE_PROJECT_REQUEST } }
  function success(saleinvoiceProject) { return { type: reportingConstants.SALEINVOICE_PROJECT_SUCCESS, saleinvoiceProject } }
  function failure(error) { return { type: reportingConstants.SALEINVOICE_PROJECT_FAILURE, error } }
}

function saleInvoiceChartDateGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.saleInvoiceChartDate(params.StartDate, params.EndDate, params.SaleCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        saleinvoiceDate => dispatch(success(saleinvoiceDate)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.SALEINVOICE_DATE_REQUEST } }
  function success(saleinvoiceDate) { return { type: reportingConstants.SALEINVOICE_DATE_SUCCESS, saleinvoiceDate } }
  function failure(error) { return { type: reportingConstants.SALEINVOICE_DATE_FAILURE, error } }
}

function saleInvoiceChartMonthGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.saleInvoiceChartMonth(params.StartDate, params.EndDate, params.SaleCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        saleinvoiceMonth => dispatch(success(saleinvoiceMonth)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.SALEINVOICE_MONTH_REQUEST } }
  function success(saleinvoiceMonth) { return { type: reportingConstants.SALEINVOICE_MONTH_SUCCESS, saleinvoiceMonth } }
  function failure(error) { return { type: reportingConstants.SALEINVOICE_MONTH_FAILURE, error } }
}

function saleInvoiceChartStatusGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.saleInvoiceChartStatus(params.StartDate, params.EndDate, params.SaleCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        saleinvoiceStatus => dispatch(success(saleinvoiceStatus)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.SALEINVOICE_STATUS_REQUEST } }
  function success(saleinvoiceStatus) { return { type: reportingConstants.SALEINVOICE_STATUS_SUCCESS, saleinvoiceStatus } }
  function failure(error) { return { type: reportingConstants.SALEINVOICE_STATUS_FAILURE, error } }
}
//#endregion  

//#region ExpenseinvoiceReport
function expenseInvoiceChartCategoryGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.expenseInvoiceChartCategory(params.StartDate, params.EndDate, params.StaffId, params.ExpenseCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        expenseinvoiceCategory => dispatch(success(expenseinvoiceCategory)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.EXPENSEINVOICE_CATEGORY_REQUEST } }
  function success(expenseinvoiceCategory) { return { type: reportingConstants.EXPENSEINVOICE_CATEGORY_SUCCESS, expenseinvoiceCategory } }
  function failure(error) { return { type: reportingConstants.EXPENSEINVOICE_CATEGORY_FAILURE, error } }
}

function expenseInvoiceChartProjectGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.expenseInvoiceChartProject(params.StartDate, params.EndDate, params.StaffId, params.ExpenseCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        expenseinvoiceProject => dispatch(success(expenseinvoiceProject)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.EXPENSEINVOICE_PROJECT_REQUEST } }
  function success(expenseinvoiceProject) { return { type: reportingConstants.EXPENSEINVOICE_PROJECT_SUCCESS, expenseinvoiceProject } }
  function failure(error) { return { type: reportingConstants.EXPENSEINVOICE_PROJECT_FAILURE, error } }
}

function expenseInvoiceChartDateGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.expenseInvoiceChartDate(params.StartDate, params.EndDate, params.StaffId, params.ExpenseCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        expenseinvoiceDate => dispatch(success(expenseinvoiceDate)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.EXPENSEINVOICE_DATE_REQUEST } }
  function success(expenseinvoiceDate) { return { type: reportingConstants.EXPENSEINVOICE_DATE_SUCCESS, expenseinvoiceDate } }
  function failure(error) { return { type: reportingConstants.EXPENSEINVOICE_DATE_FAILURE, error } }
}

function expenseInvoiceChartMonthGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.expenseInvoiceChartMonth(params.StartDate, params.EndDate, params.StaffId, params.ExpenseCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        expenseinvoiceMonth => dispatch(success(expenseinvoiceMonth)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.EXPENSEINVOICE_MONTH_REQUEST } }
  function success(expenseinvoiceMonth) { return { type: reportingConstants.EXPENSEINVOICE_MONTH_SUCCESS, expenseinvoiceMonth } }
  function failure(error) { return { type: reportingConstants.EXPENSEINVOICE_MONTH_FAILURE, error } }
}

function expenseInvoiceChartStatusGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.expenseInvoiceChartStatus(params.StartDate, params.EndDate, params.StaffId, params.ExpenseCategoryId, params.PaymentStatusId, params.ProjectId)
      .then(
        expenseinvoiceStatus => dispatch(success(expenseinvoiceStatus)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.EXPENSEINVOICE_STATUS_REQUEST } }
  function success(expenseinvoiceStatus) { return { type: reportingConstants.EXPENSEINVOICE_STATUS_SUCCESS, expenseinvoiceStatus } }
  function failure(error) { return { type: reportingConstants.EXPENSEINVOICE_STATUS_FAILURE, error } }
}
//#endregion  

//#region WorkProgramReport
function workPlanProjectCompleteRateByProjectGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.projectCompleteRateByProject(params.ProjectId)
      .then(
        projectCompleteRateByProject => dispatch(success(projectCompleteRateByProject)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.WORKPROGRAM_PROJECT_COMPLETE_REQUEST } }
  function success(projectCompleteRateByProject) { return { type: reportingConstants.WORKPROGRAM_PROJECT_COMPLETE_SUCCESS, projectCompleteRateByProject } }
  function failure(error) { return { type: reportingConstants.WORKPROGRAM_PROJECT_COMPLETE_FAILURE, error } }
}

function workPlanConstructionSiteCompleteRateByProjectGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.constructionSiteCompleteRateByProject(params.ProjectId)
      .then(
        constructionSiteCompleteRateByProject => dispatch(success(constructionSiteCompleteRateByProject)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_REQUEST } }
  function success(constructionSiteCompleteRateByProject) { return { type: reportingConstants.WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_SUCCESS, constructionSiteCompleteRateByProject } }
  function failure(error) { return { type: reportingConstants.WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_FAILURE, error } }
}

function workPlanBlockCompleteRateByConstructionSiteGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.blockCompleteRateWithConstructionSiteByProject(params.ProjectId)
      .then(
        blockCompleteRateWithConstructionSiteByProject => dispatch(success(blockCompleteRateWithConstructionSiteByProject)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.WORKPROGRAM_BLOCK_COMPLETE_REQUEST } }
  function success(blockCompleteRateWithConstructionSiteByProject) { return { type: reportingConstants.WORKPROGRAM_BLOCK_COMPLETE_SUCCESS, blockCompleteRateWithConstructionSiteByProject } }
  function failure(error) { return { type: reportingConstants.WORKPROGRAM_BLOCK_COMPLETE_FAILURE, error } }
}

function workPlanRootTaskCompleteRateByProjectGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.rootTaskCompleteRateByProject(params.ProjectId)
      .then(
        rootTaskCompleteRateByProject => dispatch(success(rootTaskCompleteRateByProject)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.WORKPROGRAM_ROOTTASK_COMPLETE_REQUEST } }
  function success(rootTaskCompleteRateByProject) { return { type: reportingConstants.WORKPROGRAM_ROOTTASK_COMPLETE_SUCCESS, rootTaskCompleteRateByProject } }
  function failure(error) { return { type: reportingConstants.WORKPROGRAM_ROOTTASK_COMPLETE_FAILURE, error } }
}

function workPlanDailyTaskCompleteRateByProjectGetAll(params) {
  return dispatch => {
    dispatch(request());

    getDynamicReportEntityClient.dailyTaskCompleteRateByProject(params.ProjectId)
      .then(
        dailyTaskCompleteRateByProject => dispatch(success(dailyTaskCompleteRateByProject)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.WORKPROGRAM_DAILY_COMPLETE_REQUEST } }
  function success(dailyTaskCompleteRateByProject) { return { type: reportingConstants.WORKPROGRAM_DAILY_COMPLETE_SUCCESS, dailyTaskCompleteRateByProject } }
  function failure(error) { return { type: reportingConstants.WORKPROGRAM_DAILY_COMPLETE_FAILURE, error } }
}
//#endregion  

//#region Audit
function auditGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchAuditClient.search(params.auditEntityId, params.userId, params.dateFrom, params.dateTo, params.type, params.items_Per_Page, params.page, params.order, params.sort)
      .then(
        audit => dispatch(success(audit)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.AUDIT_SEARCH_REQUEST } }
  function success(audit) { return { type: reportingConstants.AUDIT_SEARCH_SUCCESS, audit } }
  function failure(error) { return { type: reportingConstants.AUDIT_SEARCH_FAILURE, error } }
}

function auditGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getAuditClient.details(id)
      .then(
        details => dispatch(success(details)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: reportingConstants.AUDIT_GETID_REQUEST, id } }
  function success(details) { return { type: reportingConstants.AUDIT_GETID_SUCCESS, details } }
  function failure(error) { return { type: reportingConstants.AUDIT_GETID_FAILURE, error } }
}

function auditGetList(id) {
  return dispatch => {
    dispatch(request(id));

    listAuditClient.listByGuid(id)
      .then(
        auditList => dispatch(success(auditList)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: reportingConstants.AUDIT_GETLIST_REQUEST, id } }
  function success(auditList) { return { type: reportingConstants.AUDIT_GETLIST_SUCCESS, auditList } }
  function failure(error) { return { type: reportingConstants.AUDIT_GETLIST_FAILURE, error } }
}
//#endregion  

//#region CashFlow
function cashFlowReportGetAll(params) {
  return dispatch => {
    dispatch(request(params));

    cashFlowReportContollerClient.search(params)
      .then(
        cashflow => dispatch(success(cashflow)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: reportingConstants.CASHFLOW_SEARCH_REQUEST } }
  function success(cashflow) { return { type: reportingConstants.CASHFLOW_SEARCH_SUCCESS, cashflow } }
  function failure(error) { return { type: reportingConstants.CASHFLOW_SEARCH_FAILURE, error } }
}
//#endregion  