import { tenantcompanyConstants } from '../constants';
import {
  //#region TenantCompany
  GetTenantCompanyClient,
  UpdateTenantCompanyClient,  
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region TenantCompany
const getTenantCompanyClient = new GetTenantCompanyClient(SERVICE_URL);
const updateTenantCompanyClient = new UpdateTenantCompanyClient(SERVICE_URL);
//#endregion

export const tenantcompanyActions = {
  //#region TenantCompany
  tenantcompanyGet,
  tenantcompanyUpdate,
  //#endregion    
};

//#region TenantCompany
function tenantcompanyGet() {
  return dispatch => {
    dispatch(request());

    getTenantCompanyClient.get()
      .then(
        tenantcompany => dispatch(success(tenantcompany)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: tenantcompanyConstants.TENANTCOMPANY_GETID_REQUEST } }
  function success(tenantcompany) { return { type: tenantcompanyConstants.TENANTCOMPANY_GETID_SUCCESS, tenantcompany } }
  function failure(error) { return { type: tenantcompanyConstants.TENANTCOMPANY_GETID_FAILURE, error } }
}

function tenantcompanyUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateTenantCompanyClient.update(params.Name, params.Title, params.Address, params.ProvinceId, params.DistrictId, params.Phone, params.Fax, params.TaxOffice, params.TaxNumber, params.RegisterNumber, params.Email, params.LogoFile, params.Iban, params.MersisNo, params.PostalCode, params.TicariSicilNo, params.WebSite, params.SealSignatureFile)
      .then(
        tenantcompany => dispatch(success(tenantcompany, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: tenantcompanyConstants.TENANTCOMPANY_UPDATE_REQUEST, params } }
  function success(tenantcompany, params) { return { type: tenantcompanyConstants.TENANTCOMPANY_UPDATE_SUCCESS, tenantcompany, params } }
  function failure(error, params) { return { type: tenantcompanyConstants.TENANTCOMPANY_UPDATE_FAILURE, error, params } }
}
//#endregion
