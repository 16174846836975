export const proposalConstants = {
  //#region Proposal
  PROPOSAL_GETALL_REQUEST: 'PROPOSAL_GETALL_REQUEST',
  PROPOSAL_GETALL_SUCCESS: 'PROPOSAL_GETALL_SUCCESS',
  PROPOSAL_GETALL_FAILURE: 'PROPOSAL_GETALL_FAILURE',

  PROPOSAL_GETID_REQUEST: 'PROPOSAL_GETID_REQUEST',
  PROPOSAL_GETID_SUCCESS: 'PROPOSAL_GETID_SUCCESS',
  PROPOSAL_GETID_FAILURE: 'PROPOSAL_GETID_FAILURE',

  PROPOSAL_DELETE_REQUEST: 'PROPOSAL_DELETE_REQUEST',
  PROPOSAL_DELETE_SUCCESS: 'PROPOSAL_DELETE_SUCCESS',
  PROPOSAL_DELETE_FAILURE: 'PROPOSAL_DELETE_FAILURE',

  PROPOSAL_CREATE_REQUEST: 'PROPOSAL_CREATE_REQUEST',
  PROPOSAL_CREATE_SUCCESS: 'PROPOSAL_CREATE_SUCCESS',
  PROPOSAL_CREATE_FAILURE: 'PROPOSAL_CREATE_FAILURE',

  PROPOSAL_UPDATE_REQUEST: 'PROPOSAL_UPDATE_REQUEST',
  PROPOSAL_UPDATE_SUCCESS: 'PROPOSAL_UPDATE_SUCCESS',
  PROPOSAL_UPDATE_FAILURE: 'PROPOSAL_UPDATE_FAILURE',

  PROPOSAL_UPDATESTATUS_REQUEST: 'PROPOSAL_UPDATESTATUS_REQUEST',
  PROPOSAL_UPDATESTATUS_SUCCESS: 'PROPOSAL_UPDATESTATUS_SUCCESS',
  PROPOSAL_UPDATESTATUS_FAILURE: 'PROPOSAL_UPDATESTATUS_FAILURE',

  PROPOSAL_ARCHIVE_REQUEST: 'PROPOSAL_ARCHIVE_REQUEST',
  PROPOSAL_ARCHIVE_SUCCESS: 'PROPOSAL_ARCHIVE_SUCCESS',
  PROPOSAL_ARCHIVE_FAILURE: 'PROPOSAL_ARCHIVE_FAILURE',
  
  PROPOSAL_EXCEL_REQUEST: 'PROPOSAL_EXCEL_REQUEST',
  PROPOSAL_EXCEL_SUCCESS: 'PROPOSAL_EXCEL_SUCCESS',
  PROPOSAL_EXCEL_FAILURE: 'PROPOSAL_EXCEL_FAILURE',
  //#endregion            
};
