/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { toAbsoluteUrl, KTSVG, SourceType, detailPageUrl, DetailPage} from '../../../helpers'
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, KTCardHeader, Notes, DeleteModal, ArchiveModal, CheckoutDetail, Select, DataTables, Category, Certificate } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions, lookupActions, checkoutActions } from '../../../redux/actions';

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [checkoutId, setCheckouId] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { detail, isDeleted, isArchived, staffCheckout, staffOvertime, staffLeave, staffSalary, error } = useSelector(state => state.staff);
  const { accountLookup } = useSelector(state => state.lookup);
  const { checkout, loading } = useSelector(state => state.checkout);

  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const [stepSchema, setStepSchema] = useState({
    FromAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Amount: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    SalaryId: '',
    PaymentDate: new Date(),
    FromAccountId: '',
    ToAccountId: '',
    Amount: 0,
    Name: '',
    StaffId: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(checkoutActions.checkoutStaffPayment(values));
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(staffActions.staffGetId(id));
    dispatch(lookupActions.bankCashAccountGetAll());
  }, [id, checkout])

  useEffect(() => {
    if (detail?.totalBalance > 0) {
      formik.setFieldValue('Amount', detail?.totalBalance)
      setStepSchema({
        FromAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Amount: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })).max(detail?.totalBalance, intl.formatMessage({ id: 'REQUIRED.MAXAMOUNT' })),
      })
    }

    formik.setFieldValue('StaffId', Number(id))
    formik.setFieldValue('ToAccountId', detail?.accountId)

    dispatch(staffActions.staffGetIdCheckout({ StaffAccountId: detail?.accountId, StaffId: id }));
    dispatch(staffActions.staffGetIdOvertime(id));
    dispatch(staffActions.staffGetIdLeave({ StaffId: id }));
    dispatch(staffActions.staffGetIdSalary(id));
  }, [detail])

  const handleDelete = () => dispatch(staffActions.staffDelete({ id }));

  const handleArchive = () => dispatch(staffActions.staffArchiving({ StaffId: id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.STAFF' }),
      path: '/staff/staff',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.STAFF.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.nameSurname}</div>

                          <div className='ms-5'>
                            {detail.title}
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.phone && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/communication/com012.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.phone}
                            </div>
                          </>}

                          {detail?.address && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/general/gen018.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.address}
                            </div>
                          </>}

                         


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.Staff} />
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.GET.IDENTITYNUMBER' })}</div>
                          <div className="fw-bold fs-4">{detail.identityNumber}</div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.GET.TOTALBALANCE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalBalance, { style: 'currency', currency: 'try' })}</div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.GET.TOTALSALARYAMOUNT' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalSalaryAmount, { style: 'currency', currency: 'try' })}</div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF.GET.TOTALADVANCEPAYMENT' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalAdvancePayment, { style: 'currency', currency: 'try' })}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {detail?.iban && <>
                  <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                    {detail?.iban}
                  </div>
                </>}     
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.REGISTRYNUMBER' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.registryNumber}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.EMAIL' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.email}</span></div>
                        </div>                       
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.BLOODTYPENAME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.bloodTypeName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.RELATIVEINFORMATION' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.relativeInformation}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.JOBSTARTDATE' })}</label>
                          {detail?.jobStartDate && <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.jobStartDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>}
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.LEAVEDATE' })}</label>
                          {detail?.leaveDate && <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.leaveDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>}
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.SALARY' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.salary, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.TOTALPAIDSALARY' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalPaidSalary, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.EARNINGLEAVEBALANCE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.earningLeaveBalance}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'STAFF.GET.MONTLYOVERTIME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.montlyOvertime}</span></div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  {staffCheckout && staffCheckout.length > 0 && <Tab eventKey="checkout" title={intl.formatMessage({ id: 'SALARY.GET.CHECKOUTS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'CHECKOUT.GET.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'CHECKOUT.GET.PROCESSTYPENAME' }),
                          prop: 'checkoutProcessTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'CHECKOUT.GET.PAYMENTDATE' }),
                          prop: 'paymentDate',
                          cell: (row) => intl.formatDate(new Date(row.paymentDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'CHECKOUT.GET.AMOUNT' }),
                          prop: 'amount',
                          cell: (row) => intl.formatNumber(row.amount, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={staffCheckout}
                      onClick={(item) => setCheckouId(item.id)}
                      exportTitle={intl.formatMessage({ id: 'SALARY.GET.CHECKOUTS' })}
                    />
                  </Tab>}
                  {staffSalary && staffSalary.length > 0 && <Tab eventKey="salary" title={intl.formatMessage({ id: 'MENU.STAFF.SALARY_BONUS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'SALARY.GET.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Salary, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALARY.GET.PROGRESSPAYMENTDATE' }),
                          prop: 'progressPaymentDate',
                          cell: (row) => intl.formatDate(new Date(row?.progressPaymentDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALARY.GET.PROJECT' }),
                          prop: 'projectName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALARY.GET.PAYMENT' }),
                          prop: 'payment',
                          cell: (row) => intl.formatNumber(row.payment, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'SALARY.GET.BALANCE' }),
                          prop: 'balance',
                          cell: (row) => intl.formatNumber(row.balance, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={staffSalary}
                      exportTitle={intl.formatMessage({ id: 'MENU.STAFF.SALARY_BONUS' })}
                    />
                  </Tab>}
                  {staffLeave && staffLeave.length > 0 && <Tab eventKey="leave" title={intl.formatMessage({ id: 'MENU.STAFF.LEAVE' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'EXPLANATION' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Leave, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'LEAVE.TYPENAME' }),
                          prop: 'leaveTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'LEAVE.DATE.START' }),
                          prop: 'startDate',
                          cell: (row) => intl.formatDate(new Date(row?.startDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'LEAVE.DATE.END' }),
                          prop: 'endDate',
                          cell: (row) => intl.formatDate(new Date(row?.endDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'LEAVE.DAYCOUNT' }),
                          prop: 'dayCount',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={staffLeave}
                      exportTitle={intl.formatMessage({ id: 'MENU.STAFF.LEAVE' })}
                    />
                  </Tab>}
                  {staffOvertime && staffOvertime.length > 0 && <Tab eventKey="overtime" title={intl.formatMessage({ id: 'MENU.STAFF.OVERTIME' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'OVERTIME.GET.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Overtime, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'OVERTIME.GET.OVERTIMETYPENAME' }),
                          prop: 'overtimeTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'OVERTIME.GET.STARTDATE' }),
                          prop: 'startDate',
                          cell: (row) => intl.formatDate(new Date(row?.startDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'OVERTIME.GET.HOURDURATION' }),
                          prop: 'hourDuration',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={staffOvertime}
                      exportTitle={intl.formatMessage({ id: 'MENU.STAFF.OVERTIME' })}
                    />
                  </Tab>}
                  {detail?.projects?.length > 0 && <Tab eventKey="projects" title={intl.formatMessage({ id: 'STAFF.CREATE.PROJECTS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'STAFF.CREATE.PROJECTS.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Project, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.projects}
                      exportTitle={intl.formatMessage({ id: 'STAFF.CREATE.PROJECTS' })}
                    />
                  </Tab>}
                  <Tab eventKey="certificates" title={intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATES' })}>
                    <Certificate StaffId={id} data={detail?.certificates} />
                  </Tab>

                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>
          <KTCard className='mb-5'>
            <KTCardHeader title={detail?.totalBalance > 0 ? intl.formatMessage({ id: 'PAYMENT.ADD' }) : intl.formatMessage({ id: 'PAYMENT.ADD' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody
              className='pt-5'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT.DATE' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        {...formik.getFieldProps('PaymentDate')}
                        onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                      />
                    </div>
                    {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.PaymentDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'ACCOUNT' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select
                      data={accountLookup}
                      required
                      {...formik.getFieldProps('FromAccountId')}
                      onChange={item => formik.setFieldValue('FromAccountId', Number(item?.value))}
                    />
                    {formik.touched.FromAccountId && formik.errors.FromAccountId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.FromAccountId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <input
                      type='number'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('Amount')}
                      onFocus={event => event.target.select()}
                    />
                    {formik.touched.Amount && formik.errors.Amount && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.Amount}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPLANATION' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <textarea
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={4}
                      {...formik.getFieldProps('Name')}
                    />
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>



          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Staff} />
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal
        onClick={handleArchive}
        onOpen={showArchive}
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <CheckoutDetail id={checkoutId} onClose={() => setCheckouId(null)} />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
