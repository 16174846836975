import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl'
import { KTCard } from '../Card/KTCard'
import { KTCardBody } from '../Card/KTCardBody'
import { KTCardHeader } from '../Card/KTCardHeader'
import { KTSVG } from '../../helpers';
import { DeleteModal } from '../';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions } from '../../redux/actions';

import * as Yup from 'yup'
import {useFormik} from 'formik'

const Earning = ({collapse, StaffId}) => {
  const [isCollapse, setIsCollapse] = useState(collapse);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const intl = useIntl()

  const {earning, balance, earningCreate, error, isDeleted } = useSelector(state => state.staff);
	const dispatch = useDispatch();

  const validSchema = Yup.object().shape({
    DayCount: Yup.number().typeError(intl.formatMessage({id: 'REQUIRED.VALID' })).required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    StaffId: StaffId,
    DayCount: '',
    Name: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(staffActions.earningCreate(values));
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(staffActions.earningGetAll({StaffId}));
    dispatch(staffActions.earningGetBalence(StaffId));
  }, [StaffId, earningCreate, isDeleted])

  const handleClickDelete = (id) => {
    setDeleteId(id)
    setShow(true)
  }
  const handleDelete = () => {
    dispatch(staffActions.earningDelete({Id: deleteId, StaffId}));
    setShow(false)
  }

  return (
    <>
      <KTCard className='mb-5 mb-xl-10'>
        <KTCardHeader title={intl.formatMessage({id: 'LEAVE.PROGRESS' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

        <KTCardBody 
          className='pt-5' 
          collapseble
          isShow={isCollapse}
        >

          <form className='position-relative mb-6 form' onSubmit={formik.handleSubmit} noValidate>
            <div className='fv-row mb-7'>
              <input
                placeholder={`${intl.formatMessage({id: 'LEAVE.DAYCOUNT' })} *`}
                type='number'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                required
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('DayCount')}
                onFocus={event => event.target.select()}
              />
              {formik.touched.DayCount && formik.errors.DayCount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.DayCount}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-7'>
              <textarea
                placeholder={intl.formatMessage({id: 'EXPLANATION' })}
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled={formik.isSubmitting}
                rows={4}
                {...formik.getFieldProps('Name')}
              />
            </div>

            <div className='d-grid gap-2'>
              <button
                type='submit'
                className='btn btn-light-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'PLEASE' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>

          <div className='separator mb-4'></div>

          <div className='card-scroll mh-200px'>
            {balance && <>
              <div className='d-flex align-items-center mb-5'>
                <div className='text-muted fw-bold fs-5 flex-grow-1'>
                  Toplam {balance.balance} gün izni var
                </div>
              </div>
              <div className='separator mb-4'></div>
            </>}
            { earning?.map((item, index) => {
                return (
                  <div className='d-flex align-items-sm-center mb-7' key={index}>
                    <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                      <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                        <div className='text-gray-800 fw-bold fs-6'>
                          {item.name}
                        </div>
                        <span className='text-muted fw-semibold d-block pt-1'>{item.dayCount} gün</span>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button 
                          className='btn btn-icon btn-light-danger btn-sm border-0'
                          onClick={() => handleClickDelete(item.id)}
                        >
                          <KTSVG
                            path='/images/icons/duotune/arrows/arr015.svg'
                            className='svg-icon-2 svg-icon-primary'
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

        </KTCardBody>
      </KTCard>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}

Earning.propTypes = {
	collapse: PropTypes.bool,
  StaffId: PropTypes.any
};

Earning.defaultProps = {
	collapse: true
};

export {Earning}
