import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {KTCard} from '../Card/KTCard'
import {KTCardBody} from '../Card/KTCardBody'
import {KTCardHeader} from '../Card/KTCardHeader'
import {KTSVG} from '../../helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {commonActions} from '../../redux/actions'
import {DeleteModal, Fancybox} from '..'

import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, A11y} from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'

const ImageUpload = ({collapse, SourceId, SourceTypeId}) => {
  const intl = useIntl()
  const [isCollapse, setIsCollapse] = useState(collapse)
  const [deleteId, setDeleteId] = useState()
  const [show, setShow] = useState(false)

  const {noteImages, noteCreate, error, loading} = useSelector((state) => state.common)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(commonActions.noteGetAllImages({SourceId, SourceTypeId}))
  }, [SourceId, SourceTypeId, noteCreate])

  const validSchema = Yup.object().shape({
    Description: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
  })

  const [formValues] = useState({
    SourceId,
    SourceTypeId,
    Description: '',
    File: null,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(
        commonActions.noteCreate(
          values.Description,
          values.SourceId,
          values.SourceTypeId,
          values.File
        )
      )
      resetForm()
    },
  })

  const handleClickDelete = (id) => {
    setDeleteId(id)
    setShow(true)
  }

  const handleDelete = () => {
    dispatch(commonActions.noteDelete({Id: deleteId, SourceId, SourceTypeId}))
    setShow(false)
  }

  return (
    <>
      <KTCard className='mb-5'>
        <KTCardHeader
          title={intl.formatMessage({id: 'IMAGES'})}
          collapsed={isCollapse}
          onCollapse={(event) => setIsCollapse(event)}
        />

        <KTCardBody className='p-5' collapseble isShow={isCollapse}>
          <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-1'>
              <div className='col-lg-5 fv-row fv-plugins-icon-container d-flex h-100'>
                <label htmlFor='imageFile' className='dropzone dz-clickable d-flex w-100'>
                  <input
                    id='imageFile'
                    className='position-absolute z-index-2 opacity-0'
                    style={{width: 0, height: 0}}
                    type='file'
                    accept='image/*'
                    name={'File'}
                    onChange={(event) => formik.setFieldValue('File', event.currentTarget.files[0])}
                  />
                  <div className='dz-message needsclick'>
                    <KTSVG
                      path='/images/icons/duotune/communication/com008.svg'
                      className='svg-icon-1 position-relative z-index-1'
                    />
                    <div className='ms-4'>
                      <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                        {formik.values?.File?.name
                          ? formik.values?.File?.name
                          : intl.formatMessage({id: 'BUTTON.IMAGE'})}
                      </h3>
                      <span className='fs-7 fw-semibold text-gray-400'>
                        {intl.formatMessage({id: 'BUTTON.IMAGE.NOTE'})}
                      </span>
                    </div>
                  </div>
                </label>
              </div>

              <div className='col-lg-5 fv-row fv-plugins-icon-container'>
                <textarea
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0 h-80px'
                  placeholder={intl.formatMessage({id: 'IMAGES.NOTE'})}
                  {...formik.getFieldProps('Description')}
                />
                {formik.touched.Description && formik.errors.Description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Description}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-2'>
                <button className='btn btn-primary w-100'>
                  <KTSVG path='/images/icons/duotune/general/gen016.svg' className='svg-icon-2' />
                  {intl.formatMessage({id: 'BUTTON.UPLOAD'})}
                </button>
              </div>
            </div>
          </form>

          {noteImages && noteImages?.length > 0 && <>
            <div className='separator mt-10 mb-10'></div>

            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              <div className='d-flex w-100'>
                <Swiper
                  modules={[Navigation, A11y]}
                  spaceBetween={50}
                  slidesPerView={3}
                  navigation
                  className='d-flex w-100 p-10'
                >
                  {noteImages?.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <KTCard className='d-flex justify-content-center text-center flex-column p-8 shadow'>
                          <div
                            className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'
                            data-kt-image-input='true'
                          >
                            <a
                              data-fancybox='gallery'
                              data-caption={item?.description}
                              href={item?.fileUrl}
                              className='image-input-wrapper w-100 h-200px d-flex'
                              style={{backgroundImage: `url(${item?.fileUrl})`}}
                            ></a>
                            <span
                              className='btn btn-icon btn-circle btn-active-color-danger w-25px h-25px bg-body shadow'
                              data-kt-image-input-action='change'
                              data-bs-toggle='tooltip'
                              data-kt-initialized='1'
                              onClick={() => handleClickDelete(item?.id)}
                            >
                              <KTSVG
                                path='/images/icons/duotune/abstract/abs012.svg'
                                className='svg-icon-2'
                              />
                            </span>
                          </div>
                          <div className='text-gray-700 fs-6 text-truncate mt-5'>{item?.description}</div>
                          <div className='fs-8 fw-semibold text-gray-400'>
                            {intl.formatDate(new Date(item?.noteDate), {
                              hour: 'numeric',
                              minute: 'numeric',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })} - {item?.displayName}
                          </div>
                        </KTCard>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </Fancybox>
          </>}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}

ImageUpload.propTypes = {
  collapse: PropTypes.bool,
  SourceId: PropTypes.number,
  SourceTypeId: PropTypes.number,
}

ImageUpload.defaultProps = {
  collapse: true,
}

export {ImageUpload}
