import { officialConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  isRedirectPage: false, 
  error: null,
  isDeleted: false,
}

export function official(state = {}, action) {
  switch (action.type) {
    case officialConstants.OFFICIALEXPENSE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case officialConstants.OFFICIALEXPENSE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case officialConstants.OFFICIALEXPENSE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case officialConstants.OFFICIALEXPENSE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case officialConstants.OFFICIALEXPENSE_CREATE_SUCCESS:
      return {
        ...state,
        officialExpense: action.officialExpense,
        loading: false
      };
    case officialConstants.OFFICIALEXPENSE_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    case officialConstants.OFFICIALEXPENSE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case officialConstants.OFFICIALEXPENSE_UPDATE_SUCCESS:
      return {
        ...state,
        officialExpense: action.officialExpense || action.params,
        isRedirectPage: true,
        loading: false
      };
    case officialConstants.OFFICIALEXPENSE_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };


    default:
      return state
  }
}
