import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { toAbsoluteUrl, KTSVG } from '../../helpers'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { staffActions } from '../../redux/actions';
import { DeleteModal } from '../';

const Certificate = ({StaffId, data}) => {
  const intl = useIntl()
  const [deleteId, setDeleteId] = useState();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { isRedirect } = useSelector(state => state.staff);

  useEffect(() => {
    dispatch(staffActions.staffGetId(StaffId));
  }, [isRedirect])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    StartDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    EndDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    Name: '',
    StartDate:null,
    EndDate: null,
    File: null,
    StaffId,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(staffActions.certificateCreate(values));
      resetForm()
    },
  })

  const handleClickDelete = (id) => {
    setDeleteId(id)
    setShow(true)
  }

  const handleDelete = () => {
    dispatch(staffActions.certificateDelete({Id: deleteId}));
    setShow(false)
  }

  return (
    <>
      <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
        <div className='pt-5'>
          <div className='row mb-6'>
            <label className='col-lg-2 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATES.NAME' })}</label>
            <div className='col-lg-4 fv-row fv-plugins-icon-container'>
              <input
                type='text'
                required
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('Name')}
              />
            </div>
            <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATES.FILE' })}</label>
            <div className='col-lg-3 fv-row fv-plugins-icon-container'>
              <label htmlFor='File' className='btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark position-relative overflow-hidden'>
                  <input
                    className='position-absolute z-index-2 opacity-0 w-1px h-1px'
                    type='file'
                    accept='image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    id={'File'}
                    name={'File'}
                    onChange={(event) => formik.setFieldValue('File', event.currentTarget.files[0])}
                  />
                  <KTSVG
                    path='/images/icons/duotune/communication/com008.svg'
                    className='svg-icon-3 position-relative'
                  />
                  {intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATES.FILE' })}
                </label>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-2 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATES.STARTDATE' })}</label>
            <div className='col-lg-4 fv-row fv-plugins-icon-container mb-6'>
              <div className="position-relative d-flex align-items-center">
                <KTSVG
                  path='/images/icons/duotune/general/gen014.svg'
                  className='svg-icon svg-icon-2 position-absolute mx-4'
                />
                <Flatpickr
                  className="form-control form-control-solid ps-12"
                  options={{ "locale": Turkish }}
                  {...formik.getFieldProps('StartDate')}
                  onChange={([date]) => formik.setFieldValue('StartDate', date)}
                />
              </div>
            </div>

            <label className='col-lg-2 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATES.ENDDATE' })}</label>
            <div className='col-lg-4 fv-row fv-plugins-icon-container'>
              <div className="position-relative d-flex align-items-center">
                <KTSVG
                  path='/images/icons/duotune/general/gen014.svg'
                  className='svg-icon svg-icon-2 position-absolute mx-4'
                />
                <Flatpickr
                  className="form-control form-control-solid ps-12"
                  options={{ "locale": Turkish }}
                  {...formik.getFieldProps('EndDate')}
                  onChange={([date]) => formik.setFieldValue('EndDate', date)}
                />
              </div>
            </div>
          </div>
          <div className='text-center'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
              {(formik.isSubmitting) && (
                <span className='indicator-progress'>
                  {intl.formatMessage({ id: 'PLEASE' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      <div className='separator mt-4 mb-4'></div>
      {data?.length > 0 && <div className='table-responsive'>
        <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
          <thead>
            <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
              <th>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATESLIST.NAME' })}</th>
              <th>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATESLIST.STARTDATE' })}</th>
              <th>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATESLIST.ENDDATE' })}</th>
              <th>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATESLIST.FILE' })}</th>
              <th className='text-end'>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => <tr key={index}>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                  {item.name}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatDate(new Date(item?.startDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatDate(new Date(item?.endDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
              </td>
              <td className='p-5'>
                <div className="col-lg-8 fv-row">
                  <a href={item?.file} target='_blank' rel="noreferrer" className='d-flex align-items-center mb-7'>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'><img src={toAbsoluteUrl('/images/icons/duotune/files/folder-document.svg')} className="h-50 align-self-center" alt="" /></span>
                    </div>
                    <div className='flex-grow-1'>
                      <div className='text-dark fw-bold text-hover-primary fs-6'>{intl.formatMessage({ id: 'STAFF.CREATE.CERTIFICATES.FILE' })}</div>
                    </div>
                  </a>
                </div>
              </td>
              <td className='p-5 text-end'>
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  onClick={() => handleClickDelete(item.id)}
                >
                  <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                </button>
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>}
      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
      />
    </>
  )
}

Certificate.propTypes = {
	collapse: PropTypes.bool,
    SourceId: PropTypes.number,
	SourceTypeId: PropTypes.number,
};

export { Certificate }
