import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { userTaskActions, lookupActions } from '../../../redux/actions';

import { pagination } from '../../../helpers';

function List() {
  const intl = useIntl();
  const { userTask, loading } = useSelector(state => state.userTask);
  const { UserTaskStatus } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState(pagination);

  useEffect(() => {
    dispatch(userTaskActions.userTaskGetAll(params));
  }, [params]);

  useEffect(() => {
    dispatch(lookupActions.basicLookup('UserTaskStatus'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USERTASK.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USERTASK.SEARCH.USERNAMESURNAME' })} className='min-w-125px' />,
      accessor: 'userNameSurname',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USERTASK.SEARCH.REQUESTEDUSERNAMESURNAME' })} className='min-w-125px' />,
      accessor: 'requestedUserNameSurname',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USERTASK.SEARCH.TASKDATE' })} className='min-w-125px' />,
      accessor: 'taskDate',
      Cell: ({...props}) => <CustomCell item={props} date="short" />,
    },   
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USERTASK.SEARCH.USERTASKSTATUSNAME' })} className='min-w-125px' />,
      accessor: 'userTaskStatusName',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USERTASK.SEARCH.COMPLETEDATE' })} className='min-w-125px' />,
      accessor: 'completeDate',
      Cell: ({...props}) => <CustomCell item={props} date="short" />,
    },   
  ];

  const actionButtons = [
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'USERTASK.SEARCH.USERTASKSTATUSNAME',
          data: UserTaskStatus,
          name: 'userTaskStatusId'
        },
        {
          title: 'USERTASK.SEARCH.STARTDATE',
          type: 'date',
          name: 'startDate'
        },
        {
          title: 'USERTASK.SEARCH.ENDDATE',
          type: 'date',
          name: 'endDate'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.USERTASK' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages='USERTASK'
        />
        <KTCardBody>
          {!userTask && <ListLoading />}
          {userTask && <Table
            items={userTask}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
