import { scorecardConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false
}

export function scorecard(state = {}, action) {
  switch (action.type) {
    //#region scorecard
    case scorecardConstants.SCORECARD_GETALL_REQUEST:
      return { ...state, ...initialState };
    case scorecardConstants.SCORECARD_GETALL_SUCCESS:
      return {
        ...state,
        scorecard: action.scorecard,
        loading: false
      };
    case scorecardConstants.SCORECARD_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case scorecardConstants.SCORECARD_GETID_REQUEST:
      return { ...state, ...initialState };
    case scorecardConstants.SCORECARD_GETID_SUCCESS:
      return {
        ...state,
        detail: action.scorecard,
        loading: false
      };
    case scorecardConstants.SCORECARD_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case scorecardConstants.SCORECARD_DELETE_REQUEST:
      return { ...state, ...initialState };
    case scorecardConstants.SCORECARD_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case scorecardConstants.SCORECARD_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case scorecardConstants.SCORECARD_CREATE_REQUEST:
      return { ...state, ...initialState };
    case scorecardConstants.SCORECARD_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.scorecard,
        loading: false
      };
    case scorecardConstants.SCORECARD_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case scorecardConstants.SCORECARD_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case scorecardConstants.SCORECARD_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.scorecard || action.params,
        detail: action.params,
        loading: false
      };
    case scorecardConstants.SCORECARD_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region scorecardDay
    case scorecardConstants.SCORECARDDAY_GETALL_REQUEST:
        return { ...state, ...initialState };
        case scorecardConstants.SCORECARDDAY_GETALL_SUCCESS:
        return {
          ...state,
          scorecard: action.scorecard,
          loading: false
        };
        case scorecardConstants.SCORECARDDAY_GETALL_FAILURE:
        AuthCheck(action)
      
      return {
          ...state,
          error: action.error,
          loading: false
      };

    case scorecardConstants.SCORECARDDAY_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case scorecardConstants.SCORECARDDAY_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.scorecard || action.params,
        detail: action.params,
        loading: false
      };
    case scorecardConstants.SCORECARDDAY_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion
    default:
      return state
  }
}
