import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { KTCard } from '../Card/KTCard'
import { KTCardBody } from '../Card/KTCardBody'
import { KTCardHeader } from '../Card/KTCardHeader'
import { KTSVG, AuthType } from '../../helpers'

import {useIntl} from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { reportingActions } from '../../redux/actions';
import { AuditModal } from '..';

const Audit = ({collapse, Guid}) => {
  const intl = useIntl()
  const [isCollapse, setIsCollapse] = useState(collapse);
  const [item, setItem] = useState(null);

  const { auditList } = useSelector(state => state.reporting);
	const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportingActions.auditGetList(Guid));
  }, [Guid])

  return (
    <>
      <KTCard className='mb-5'>
        <KTCardHeader title={intl.formatMessage({id: 'REPORTING.AUDIT.TITLE' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

        <KTCardBody 
          className='p-5'
          collapseble
          isShow={isCollapse}
        >
          <div className='card-scroll mh-300px'>
            {auditList?.map((audit, index) => {
              return (
                <div className="d-flex align-items-center position-relative mb-7" key={index}>
                  <div className="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>

                  <div className="fw-semibold ms-6 me-4">
                    <div className="fs-6 fw-bold text-primary">{AuthType[audit?.type]}</div>

                    <div>
                      <div className="text-gray-600 fw-bold">{audit?.createdBy}</div>
                      <div className="text-gray-500">{intl.formatDate(new Date(audit?.dateTime), { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })}</div>
                    </div>
                  </div>

                  <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" onClick={() => setItem(audit)}>
                    <KTSVG path='/images/icons/duotune/arrows/arr024.svg' className='svg-icon-2' />
                  </button>
                </div>
              )
            })}
          </div>
        </KTCardBody>
      </KTCard>

      <AuditModal
        onOpen={item ? true : false}
        onClose={() => setItem(null)}
        oldData={item?.oldValues}
        newData={item?.newValues}
      />
    </>
  )
}

Audit.propTypes = {
	collapse: PropTypes.bool,
  Guid: PropTypes.number,
};

Audit.defaultProps = {
	collapse: true
};

export {Audit}
