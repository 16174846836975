import { expenseinvoiceConstants } from '../constants';
import {
  //#region ExpenseInvoice
  SearchExpenseInvoiceClient,
  DeleteExpenseInvoiceClient,
  ArchivingExpenseInvoiceClient,
  //#endregion

  //#region Bank
  UpdateBankExpenseInvoiceClient,
  CreateBankExpenseInvoiceClient,
  GetBankExpenseInvoiceClient,
  //#endregion

  //#region Detail
  UpdateDetailExpenseInvoiceClient,
  CreateDetailExpenseInvoiceClient,
  GetDetailExpenseInvoiceClient,
  //#endregion

  //#region Fast
  UpdateFastExpenseInvoiceClient,
  CreateFastExpenseInvoiceClient,
  GetFastExpenseInvoiceClient,
  //#endregion

  //#region Sundry
  UpdateSundryExpenseInvoiceClient,
  CreateSundryExpenseInvoiceClient,
  GetSundryExpenseInvoiceClient,
  //#endregion

  //#region Tax
  UpdateTaxExpenseInvoiceClient,
  CreateTaxExpenseInvoiceClient,
  GetTaxExpenseInvoiceClient,
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region ExpenseInvoice
const archivingExpenseInvoiceClient = new ArchivingExpenseInvoiceClient(SERVICE_URL);
const searchExpenseInvoiceClient = new SearchExpenseInvoiceClient(SERVICE_URL);
const deleteExpenseInvoiceClient = new DeleteExpenseInvoiceClient(SERVICE_URL);
//#endregion

//#region Bank
const getBankExpenseInvoiceClient = new GetBankExpenseInvoiceClient(SERVICE_URL);
const createBankExpenseInvoiceClient = new CreateBankExpenseInvoiceClient(SERVICE_URL);
const updateBankExpenseInvoiceClient = new UpdateBankExpenseInvoiceClient(SERVICE_URL);
//#endregion

//#region Detail
const getDetailExpenseInvoiceClient = new GetDetailExpenseInvoiceClient(SERVICE_URL);
const createDetailExpenseInvoiceClient = new CreateDetailExpenseInvoiceClient(SERVICE_URL);
const updateDetailExpenseInvoiceClient = new UpdateDetailExpenseInvoiceClient(SERVICE_URL);
//#endregion

//#region Fast
const getFastExpenseInvoiceClient = new GetFastExpenseInvoiceClient(SERVICE_URL);
const createFastExpenseInvoiceClient = new CreateFastExpenseInvoiceClient(SERVICE_URL);
const updateFastExpenseInvoiceClient = new UpdateFastExpenseInvoiceClient(SERVICE_URL);
//#endregion

//#region Sundry
const getSundryExpenseInvoiceClient = new GetSundryExpenseInvoiceClient(SERVICE_URL);
const createSundryExpenseInvoiceClient = new CreateSundryExpenseInvoiceClient(SERVICE_URL);
const updateSundryExpenseInvoiceClient = new UpdateSundryExpenseInvoiceClient(SERVICE_URL);
//#endregion

//#region Tax
const getTaxExpenseInvoiceClient = new GetTaxExpenseInvoiceClient(SERVICE_URL);
const createTaxExpenseInvoiceClient = new CreateTaxExpenseInvoiceClient(SERVICE_URL);
const updateTaxExpenseInvoiceClient = new UpdateTaxExpenseInvoiceClient(SERVICE_URL);
//#endregion

export const expenseinvoiceActions = {
  //#region ExpenseInvoice
  expenseinvoiceGetAll,
  expenseinvoiceArchiving,
  expenseinvoiceDelete,
  expenseinvoiceExcel,
  //#endregion

  //#region Bank
  expenseinvoiceBankCreate,
  expenseinvoiceBankUpdate,
  expenseinvoiceBankGetId,
  //#endregion

  //#region Detail
  expenseinvoiceDetailCreate,
  expenseinvoiceDetailUpdate,
  expenseinvoiceDetailGetId,
  //#endregion

  //#region Fast
  expenseinvoiceFastCreate,
  expenseinvoiceFastUpdate,
  expenseinvoiceFastGetId,
  //#endregion

  //#region Sundry
  expenseinvoiceSundryCreate,
  expenseinvoiceSundryUpdate,
  expenseinvoiceSundryGetId,
  //#endregion

  //#region Tax
  expenseinvoiceTaxCreate,
  expenseinvoiceTaxUpdate,
  expenseinvoiceTaxGetId,
  //#endregion

};

//#region ExpenseInvoice
function expenseinvoiceGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchExpenseInvoiceClient.search(params.name, params.editStartDate, params.editEndDate, params.projectId, params.companyId, params.expensePaymentStatusId, params.expenseTypeId, params.categoryId, params.invoiceKindId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: expenseinvoiceConstants.EXPENSEINVOICE_GETALL_REQUEST } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_GETALL_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_GETALL_FAILURE, error } }
}

function expenseinvoiceDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteExpenseInvoiceClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_DELETE_FAILURE, error } }
}

function expenseinvoiceArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingExpenseInvoiceClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_ARCHIVE_FAILURE, error } }
}

function expenseinvoiceExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchExpenseInvoiceClient.excel(params.name, params.editStartDate, params.editEndDate, params.projectId, params.companyId, params.expensePaymentStatusId, params.expenseTypeId, params.categoryId, params.invoiceKindId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: expenseinvoiceConstants.EXPENSEINVOICE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICE_EXCEL_FAILURE, error } }
}

//#endregion

//#region Bank
function expenseinvoiceBankCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createBankExpenseInvoiceClient.create(params)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_CREATE_REQUEST, params } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_CREATE_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_CREATE_FAILURE, error } }
}

function expenseinvoiceBankGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getBankExpenseInvoiceClient.get(id)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_GETID_REQUEST, id } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_GETID_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_GETID_FAILURE, error } }
}

function expenseinvoiceBankUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateBankExpenseInvoiceClient.update(params)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_UPDATE_REQUEST, params } }
  function success(expenseinvoice, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_UPDATE_SUCCESS, expenseinvoice, params } }
  function failure(error, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEBANK_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Detail
function expenseinvoiceDetailCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createDetailExpenseInvoiceClient.create(params.Name, params.InvoiceTypeId, params.ProjectId, params.CompanyId, params.EditDate, params.DefermentDate, params.InvoiceNumber, params.SubTotalPrice, params.TotalTax, params.TotalDiscount, params.GrossTotalPrice, params.TotalWitholding, params.TotalPrice, params.ExpensePaymentStatusId, params.FromAccountId, params.CheckoutName, params.PaymentDate, params.ExpenseFile, params.RelatedStaffId, params.GibInvoiceId, params.CategoryId, params.InvoiceKindId, params.ExpenseInvoiceItems)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_CREATE_REQUEST, params } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_CREATE_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_CREATE_FAILURE, error } }
}

function expenseinvoiceDetailGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getDetailExpenseInvoiceClient.get(id)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_GETID_REQUEST, id } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_GETID_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_GETID_FAILURE, error } }
}

function expenseinvoiceDetailUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateDetailExpenseInvoiceClient.update(params.Id, params.Name, params.ProjectId, params.EditDate, params.DefermentDate, params.InvoiceNumber, params.SubTotalPrice, params.ExpenseFile, params.RelatedStaffId, params.TotalTax, params.TotalDiscount, params.GrossTotalPrice, params.TotalWitholding, params.TotalPrice, params.InvoiceKindId, params.ExpenseInvoiceItems)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_UPDATE_REQUEST, params } }
  function success(expenseinvoice, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_UPDATE_SUCCESS, expenseinvoice, params } }
  function failure(error, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEDETAIL_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Fast
function expenseinvoiceFastCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createFastExpenseInvoiceClient.create(params.Name, params.ProjectId, params.CompanyId, params.EditDate, params.DefermentDate, params.SubTotalPrice, params.TotalTax, params.TotalPrice, params.ExpensePaymentStatusId, params.FromAccountId, params.CheckoutName, params.PaymentDate, params.ExpenseFile, params.RelatedStaffId, params.InvoiceNumber, params.CategoryId, params.InvoiceKindId)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_CREATE_REQUEST, params } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_CREATE_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_CREATE_FAILURE, error } }
}

function expenseinvoiceFastGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getFastExpenseInvoiceClient.get(id)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_GETID_REQUEST, id } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_GETID_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_GETID_FAILURE, error } }
}

function expenseinvoiceFastUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateFastExpenseInvoiceClient.update(params.Id, params.Name, params.ProjectId, params.EditDate, params.DefermentDate, params.SubTotalPrice, params.ExpenseFile, params.InvoiceNumber, params.RelatedStaffId, params.TotalTax, params.TotalPrice, params.InvoiceKindId)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_UPDATE_REQUEST, params } }
  function success(expenseinvoice, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_UPDATE_SUCCESS, expenseinvoice, params } }
  function failure(error, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICEFAST_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Sundry
function expenseinvoiceSundryCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createSundryExpenseInvoiceClient.create(params)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_CREATE_REQUEST, params } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_CREATE_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_CREATE_FAILURE, error } }
}

function expenseinvoiceSundryGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getSundryExpenseInvoiceClient.get(id)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_GETID_REQUEST, id } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_GETID_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_GETID_FAILURE, error } }
}

function expenseinvoiceSundryUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateSundryExpenseInvoiceClient.update(params)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_UPDATE_REQUEST, params } }
  function success(expenseinvoice, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_UPDATE_SUCCESS, expenseinvoice, params } }
  function failure(error, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICESUNDRY_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Tax
function expenseinvoiceTaxCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createTaxExpenseInvoiceClient.create(params)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_CREATE_REQUEST, params } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_CREATE_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_CREATE_FAILURE, error } }
}

function expenseinvoiceTaxGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getTaxExpenseInvoiceClient.get(id)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_GETID_REQUEST, id } }
  function success(expenseinvoice) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_GETID_SUCCESS, expenseinvoice } }
  function failure(error) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_GETID_FAILURE, error } }
}

function expenseinvoiceTaxUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateTaxExpenseInvoiceClient.update(params)
      .then(
        expenseinvoice => dispatch(success(expenseinvoice, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_UPDATE_REQUEST, params } }
  function success(expenseinvoice, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_UPDATE_SUCCESS, expenseinvoice, params } }
  function failure(error, params) { return { type: expenseinvoiceConstants.EXPENSEINVOICETAX_UPDATE_FAILURE, error, params } }
}
//#endregion
