/* eslint-disable */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {KTCard} from '../../Card/KTCard'
import {KTCardBody} from '../../Card/KTCardBody'
import {KTSVG} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {useDispatch, useSelector} from 'react-redux'
import {checkoutActions} from '../../../redux/actions'

const CheckoutDetail = ({id, onClose, receiptClick, isUpdatable = false}) => {
  const [show, setShow] = useState(false)
  const [updatable, setUpdatable] = useState(isUpdatable)
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const {detail, updateDescription} = useSelector((state) => state.checkout)
  const {info} = useSelector((state) => state.profile)

  const dispatch = useDispatch()

  const validSchema = Yup.object().shape({
    Description: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
  })

  const [formValues] = useState({
    Id: id,
    Description: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(checkoutActions.checkoutUpdateDescription(values))
    },
  })

  useEffect(() => {
    if (id) {
      dispatch(checkoutActions.checkoutGetId(id))
      setShow(true)
      setLoading(true)
    }
  }, [id])

  useEffect(() => {
    if (detail) {
      setLoading(false)
      formik.setFieldValue('Description', detail?.name)
      formik.setFieldValue('Id', id)
    }
  }, [detail])

  useEffect(() => {
    if (info && isUpdatable) {
      const isUpdateButton = info?.roles?.find((item) => item == 'Bank')
      setUpdatable(isUpdateButton ? true : false)
    }
  }, [info])

  useEffect(() => {
    if (updateDescription) {
      setShow(false)
    }
  }, [updateDescription])

  const handleDeleteCheckout = () => {
    dispatch(checkoutActions.checkoutDelete({id}))
    setShow(false)
  }

  const onCloseModal = () => {
    setShow(false)
    onClose && onClose()
  }

  return (
    <Modal
      className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={false}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={onCloseModal}
    >
      <div className='modal-content'>
        <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
          <h5 className='fw-bold m-0'>{detail?.processTypeName}</h5>
          <div className='d-flex ms-2'>
            <div
              className='btn btn-icon btn-sm btn-light-primary ms-2'
              data-bs-dismiss='modal'
              onClick={onCloseModal}
            >
              <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
        </div>
        <KTCard>
          <KTCardBody className='p-9'>
            {loading && (
              <div className='page-loader flex-column bg-dark bg-opacity-50'>
                <span className='spinner-border text-primary' role='status'></span>
                <span className='text-light fs-6 fw-semibold mt-5'>
                  {intl.formatMessage({id: 'LOADING'})}
                </span>
              </div>
            )}

            {!loading && (
              <>
                {detail?.checkNo && (
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({ id: 'CHECKOUT.GET.CHECKNO' })}
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>{detail?.checkNo}</span>
                    </div>
                  </div>
                )}
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>
                    {intl.formatMessage({id: 'CHECKOUT.GET.PAYMENTDATE'})}
                  </label>
                  {detail?.paymentDate && (
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>
                        {intl.formatDate(new Date(detail?.paymentDate), {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </span>
                    </div>
                  )}
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>
                    {intl.formatMessage({id: 'CHECKOUT.GET.TOACCOUNTNAME'})}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>{detail?.toAccountName}</span>
                  </div>
                </div>

                {updatable ? (
                  <form
                    className='form position-relative'
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <div className='row mb-7'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        {intl.formatMessage({id: 'CHECKOUT.GET.NAME'})}
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <textarea
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Description')}
                        />
                        <button className='btn btn-info btn-sm mt-3' type='submit'>
                          {intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'CHECKOUT.GET.NAME'})}
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <span className='fw-bold fs-6'>{detail?.name}</span>
                    </div>
                  </div>
                )}

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>
                    {intl.formatMessage({id: 'CHECKOUT.GET.AMOUNT'})}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <span className='fw-bold fs-6'>
                      {intl.formatNumber(detail?.amount, {
                        style: 'currency',
                        currency: detail?.currencyCode || 'try',
                      })}
                    </span>
                  </div>
                </div>
                {receiptClick && (
                  <div className='row mb-7 align-items-center'>
                    <label className='col-lg-4 fw-bold text-muted'>
                      {intl.formatMessage({id: 'CHECKOUT.GET.RECEIPT'})}
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <div className='d-flex g-5'>
                        <button
                          className='btn btn-sm btn-light-danger'
                          onClick={() => receiptClick && receiptClick('pdf')}
                        >
                          {intl.formatMessage({id: 'BUTTON.PDF.DOWNLOAD'})}
                        </button>
                        <button
                          className='btn btn-sm btn-light-info'
                          onClick={() => receiptClick && receiptClick('excel')}
                        >
                          {intl.formatMessage({id: 'BUTTON.EXCEL'})}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </KTCardBody>
        </KTCard>

        <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
          <div className='d-flex align-items-center me-3'>
            <button className='btn btn-light me-3' onClick={onCloseModal}>
              {intl.formatMessage({id: 'BUTTON.CLOSE'})}
            </button>
            <button className='btn btn-primary' onClick={handleDeleteCheckout}>
              {intl.formatMessage({id: 'BUTTON.DELETE'})}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

CheckoutDetail.propTypes = {
  id: PropTypes.number,
}

export {CheckoutDetail}
