export const projectConstants = {
  //#region Project
  PROJECT_GETALL_REQUEST: 'PROJECT_GETALL_REQUEST',
  PROJECT_GETALL_SUCCESS: 'PROJECT_GETALL_SUCCESS',
  PROJECT_GETALL_FAILURE: 'PROJECT_GETALL_FAILURE',

  PROJECT_GETID_REQUEST: 'PROJECT_GETID_REQUEST',
  PROJECT_GETID_SUCCESS: 'PROJECT_GETID_SUCCESS',
  PROJECT_GETID_FAILURE: 'PROJECT_GETID_FAILURE',

  PROJECT_DELETE_REQUEST: 'PROJECT_DELETE_REQUEST',
  PROJECT_DELETE_SUCCESS: 'PROJECT_DELETE_SUCCESS',
  PROJECT_DELETE_FAILURE: 'PROJECT_DELETE_FAILURE',

  PROJECT_CREATE_REQUEST: 'PROJECT_CREATE_REQUEST',
  PROJECT_CREATE_SUCCESS: 'PROJECT_CREATE_SUCCESS',
  PROJECT_CREATE_FAILURE: 'PROJECT_CREATE_FAILURE',

  PROJECT_UPDATE_REQUEST: 'PROJECT_UPDATE_REQUEST',
  PROJECT_UPDATE_SUCCESS: 'PROJECT_UPDATE_SUCCESS',
  PROJECT_UPDATE_FAILURE: 'PROJECT_UPDATE_FAILURE',

  PROJECT_ARCHIVE_REQUEST: 'PROJECT_ARCHIVE_REQUEST',
  PROJECT_ARCHIVE_SUCCESS: 'PROJECT_ARCHIVE_SUCCESS',
  PROJECT_ARCHIVE_FAILURE: 'PROJECT_ARCHIVE_FAILURE',

  PROJECT_EXCEL_REQUEST: 'PROJECT_EXCEL_REQUEST',
  PROJECT_EXCEL_SUCCESS: 'PROJECT_EXCEL_SUCCESS',
  PROJECT_EXCEL_FAILURE: 'PROJECT_EXCEL_FAILURE',
  //#endregion

  //#region RealEstate
  REALESTATE_GETALL_REQUEST: 'REALESTATE_GETALL_REQUEST',
  REALESTATE_GETALL_SUCCESS: 'REALESTATE_GETALL_SUCCESS',
  REALESTATE_GETALL_FAILURE: 'REALESTATE_GETALL_FAILURE',

  REALESTATE_GETID_REQUEST: 'REALESTATE_GETID_REQUEST',
  REALESTATE_GETID_SUCCESS: 'REALESTATE_GETID_SUCCESS',
  REALESTATE_GETID_FAILURE: 'REALESTATE_GETID_FAILURE',

  REALESTATE_DELETE_REQUEST: 'REALESTATE_DELETE_REQUEST',
  REALESTATE_DELETE_SUCCESS: 'REALESTATE_DELETE_SUCCESS',
  REALESTATE_DELETE_FAILURE: 'REALESTATE_DELETE_FAILURE',

  REALESTATE_CREATE_REQUEST: 'REALESTATE_CREATE_REQUEST',
  REALESTATE_CREATE_SUCCESS: 'REALESTATE_CREATE_SUCCESS',
  REALESTATE_CREATE_FAILURE: 'REALESTATE_CREATE_FAILURE',

  REALESTATE_UPDATE_REQUEST: 'REALESTATE_UPDATE_REQUEST',
  REALESTATE_UPDATE_SUCCESS: 'REALESTATE_UPDATE_SUCCESS',
  REALESTATE_UPDATE_FAILURE: 'REALESTATE_UPDATE_FAILURE',

  REALESTATE_ARCHIVE_REQUEST: 'REALESTATE_ARCHIVE_REQUEST',
  REALESTATE_ARCHIVE_SUCCESS: 'REALESTATE_ARCHIVE_SUCCESS',
  REALESTATE_ARCHIVE_FAILURE: 'REALESTATE_ARCHIVE_FAILURE',

  REALESTATE_EXCEL_REQUEST: 'REALESTATE_EXCEL_REQUEST',
  REALESTATE_EXCEL_SUCCESS: 'REALESTATE_EXCEL_SUCCESS',
  REALESTATE_EXCEL_FAILURE: 'REALESTATE_EXCEL_FAILURE',
  //#endregion

  //#region Contracts
  CONTRACTS_GETALL_REQUEST: 'CONTRACTS_GETALL_REQUEST',
  CONTRACTS_GETALL_SUCCESS: 'CONTRACTS_GETALL_SUCCESS',
  CONTRACTS_GETALL_FAILURE: 'CONTRACTS_GETALL_FAILURE',

  CONTRACTS_GETID_REQUEST: 'CONTRACTS_GETID_REQUEST',
  CONTRACTS_GETID_SUCCESS: 'CONTRACTS_GETID_SUCCESS',
  CONTRACTS_GETID_FAILURE: 'CONTRACTS_GETID_FAILURE',

  CONTRACTS_DELETE_REQUEST: 'CONTRACTS_DELETE_REQUEST',
  CONTRACTS_DELETE_SUCCESS: 'CONTRACTS_DELETE_SUCCESS',
  CONTRACTS_DELETE_FAILURE: 'CONTRACTS_DELETE_FAILURE',

  CONTRACTS_CREATE_REQUEST: 'CONTRACTS_CREATE_REQUEST',
  CONTRACTS_CREATE_SUCCESS: 'CONTRACTS_CREATE_SUCCESS',
  CONTRACTS_CREATE_FAILURE: 'CONTRACTS_CREATE_FAILURE',

  CONTRACTS_UPDATE_REQUEST: 'CONTRACTS_UPDATE_REQUEST',
  CONTRACTS_UPDATE_SUCCESS: 'CONTRACTS_UPDATE_SUCCESS',
  CONTRACTS_UPDATE_FAILURE: 'CONTRACTS_UPDATE_FAILURE',

  CONTRACTS_EXCEL_REQUEST: 'CONTRACTS_EXCEL_REQUEST',
  CONTRACTS_EXCEL_SUCCESS: 'CONTRACTS_EXCEL_SUCCESS',
  CONTRACTS_EXCEL_FAILURE: 'CONTRACTS_EXCEL_FAILURE',
  //#endregion

  //#region Constructionsite
  CONSTRUCTIONSITE_GETALL_REQUEST: 'CONSTRUCTIONSITE_GETALL_REQUEST',
  CONSTRUCTIONSITE_GETALL_SUCCESS: 'CONSTRUCTIONSITE_GETALL_SUCCESS',
  CONSTRUCTIONSITE_GETALL_FAILURE: 'CONSTRUCTIONSITE_GETALL_FAILURE',

  CONSTRUCTIONSITE_GETID_REQUEST: 'CONSTRUCTIONSITE_GETID_REQUEST',
  CONSTRUCTIONSITE_GETID_SUCCESS: 'CONSTRUCTIONSITE_GETID_SUCCESS',
  CONSTRUCTIONSITE_GETID_FAILURE: 'CONSTRUCTIONSITE_GETID_FAILURE',

  CONSTRUCTIONSITE_DELETE_REQUEST: 'CONSTRUCTIONSITE_DELETE_REQUEST',
  CONSTRUCTIONSITE_DELETE_SUCCESS: 'CONSTRUCTIONSITE_DELETE_SUCCESS',
  CONSTRUCTIONSITE_DELETE_FAILURE: 'CONSTRUCTIONSITE_DELETE_FAILURE',

  CONSTRUCTIONSITE_CREATE_REQUEST: 'CONSTRUCTIONSITE_CREATE_REQUEST',
  CONSTRUCTIONSITE_CREATE_SUCCESS: 'CONSTRUCTIONSITE_CREATE_SUCCESS',
  CONSTRUCTIONSITE_CREATE_FAILURE: 'CONSTRUCTIONSITE_CREATE_FAILURE',

  CONSTRUCTIONSITE_UPDATE_REQUEST: 'CONSTRUCTIONSITE_UPDATE_REQUEST',
  CONSTRUCTIONSITE_UPDATE_SUCCESS: 'CONSTRUCTIONSITE_UPDATE_SUCCESS',
  CONSTRUCTIONSITE_UPDATE_FAILURE: 'CONSTRUCTIONSITE_UPDATE_FAILURE',

  CONSTRUCTIONSITE_EXCEL_REQUEST: 'CONSTRUCTIONSITE_EXCEL_REQUEST',
  CONSTRUCTIONSITE_EXCEL_SUCCESS: 'CONSTRUCTIONSITE_EXCEL_SUCCESS',
  CONSTRUCTIONSITE_EXCEL_FAILURE: 'CONSTRUCTIONSITE_EXCEL_FAILURE',
  //#endregion

  //#region Block
  BLOCK_GETALL_REQUEST: 'BLOCK_GETALL_REQUEST',
  BLOCK_GETALL_SUCCESS: 'BLOCK_GETALL_SUCCESS',
  BLOCK_GETALL_FAILURE: 'BLOCK_GETALL_FAILURE',

  BLOCK_GETID_REQUEST: 'BLOCK_GETID_REQUEST',
  BLOCK_GETID_SUCCESS: 'BLOCK_GETID_SUCCESS',
  BLOCK_GETID_FAILURE: 'BLOCK_GETID_FAILURE',

  BLOCK_DELETE_REQUEST: 'BLOCK_DELETE_REQUEST',
  BLOCK_DELETE_SUCCESS: 'BLOCK_DELETE_SUCCESS',
  BLOCK_DELETE_FAILURE: 'BLOCK_DELETE_FAILURE',

  BLOCK_CREATE_REQUEST: 'BLOCK_CREATE_REQUEST',
  BLOCK_CREATE_SUCCESS: 'BLOCK_CREATE_SUCCESS',
  BLOCK_CREATE_FAILURE: 'BLOCK_CREATE_FAILURE',

  BLOCK_UPDATE_REQUEST: 'BLOCK_UPDATE_REQUEST',
  BLOCK_UPDATE_SUCCESS: 'BLOCK_UPDATE_SUCCESS',
  BLOCK_UPDATE_FAILURE: 'BLOCK_UPDATE_FAILURE',

  BLOCK_EXCEL_REQUEST: 'BLOCK_EXCEL_REQUEST',
  BLOCK_EXCEL_SUCCESS: 'BLOCK_EXCEL_SUCCESS',
  BLOCK_EXCEL_FAILURE: 'BLOCK_EXCEL_FAILURE',
  //#endregion

  //#region Buildingblocktype
  BUILDINGBLOCKTYPE_GETALL_REQUEST: 'BUILDINGBLOCKTYPE_GETALL_REQUEST',
  BUILDINGBLOCKTYPE_GETALL_SUCCESS: 'BUILDINGBLOCKTYPE_GETALL_SUCCESS',
  BUILDINGBLOCKTYPE_GETALL_FAILURE: 'BUILDINGBLOCKTYPE_GETALL_FAILURE',

  BUILDINGBLOCKTYPE_GETID_REQUEST: 'BUILDINGBLOCKTYPE_GETID_REQUEST',
  BUILDINGBLOCKTYPE_GETID_SUCCESS: 'BUILDINGBLOCKTYPE_GETID_SUCCESS',
  BUILDINGBLOCKTYPE_GETID_FAILURE: 'BUILDINGBLOCKTYPE_GETID_FAILURE',

  BUILDINGBLOCKTYPE_DELETE_REQUEST: 'BUILDINGBLOCKTYPE_DELETE_REQUEST',
  BUILDINGBLOCKTYPE_DELETE_SUCCESS: 'BUILDINGBLOCKTYPE_DELETE_SUCCESS',
  BUILDINGBLOCKTYPE_DELETE_FAILURE: 'BUILDINGBLOCKTYPE_DELETE_FAILURE',

  BUILDINGBLOCKTYPE_CREATE_REQUEST: 'BUILDINGBLOCKTYPE_CREATE_REQUEST',
  BUILDINGBLOCKTYPE_CREATE_SUCCESS: 'BUILDINGBLOCKTYPE_CREATE_SUCCESS',
  BUILDINGBLOCKTYPE_CREATE_FAILURE: 'BUILDINGBLOCKTYPE_CREATE_FAILURE',

  BUILDINGBLOCKTYPE_UPDATE_REQUEST: 'BUILDINGBLOCKTYPE_UPDATE_REQUEST',
  BUILDINGBLOCKTYPE_UPDATE_SUCCESS: 'BUILDINGBLOCKTYPE_UPDATE_SUCCESS',
  BUILDINGBLOCKTYPE_UPDATE_FAILURE: 'BUILDINGBLOCKTYPE_UPDATE_FAILURE',

  BUILDINGBLOCKTYPE_EXCEL_REQUEST: 'BUILDINGBLOCKTYPE_EXCEL_REQUEST',
  BUILDINGBLOCKTYPE_EXCEL_SUCCESS: 'BUILDINGBLOCKTYPE_EXCEL_SUCCESS',
  BUILDINGBLOCKTYPE_EXCEL_FAILURE: 'BUILDINGBLOCKTYPE_EXCEL_FAILURE',
  //#endregion

  //#region Plot
  PLOT_GETALL_REQUEST: 'PLOT_GETALL_REQUEST',
  PLOT_GETALL_SUCCESS: 'PLOT_GETALL_SUCCESS',
  PLOT_GETALL_FAILURE: 'PLOT_GETALL_FAILURE',

  PLOT_GETID_REQUEST: 'PLOT_GETID_REQUEST',
  PLOT_GETID_SUCCESS: 'PLOT_GETID_SUCCESS',
  PLOT_GETID_FAILURE: 'PLOT_GETID_FAILURE',

  PLOT_DELETE_REQUEST: 'PLOT_DELETE_REQUEST',
  PLOT_DELETE_SUCCESS: 'PLOT_DELETE_SUCCESS',
  PLOT_DELETE_FAILURE: 'PLOT_DELETE_FAILURE',

  PLOT_CREATE_REQUEST: 'PLOT_CREATE_REQUEST',
  PLOT_CREATE_SUCCESS: 'PLOT_CREATE_SUCCESS',
  PLOT_CREATE_FAILURE: 'PLOT_CREATE_FAILURE',

  PLOT_UPDATE_REQUEST: 'PLOT_UPDATE_REQUEST',
  PLOT_UPDATE_SUCCESS: 'PLOT_UPDATE_SUCCESS',
  PLOT_UPDATE_FAILURE: 'PLOT_UPDATE_FAILURE',

  PLOT_EXCEL_REQUEST: 'PLOT_EXCEL_REQUEST',
  PLOT_EXCEL_SUCCESS: 'PLOT_EXCEL_SUCCESS',
  PLOT_EXCEL_FAILURE: 'PLOT_EXCEL_FAILURE',
  //#endregion
};
