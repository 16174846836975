import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const {staff, loading} = useSelector(state => state.staff);
  const { projectLookup } = useSelector(state => state.lookup);
	const dispatch = useDispatch();

  const [ params, setParams ] = useState()

  useEffect(() => {
    if (params) dispatch(staffActions.staffGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({id: 'STAFF.SEARCH.NAMESURNAME' })} className='min-w-125px' />,
      accessor: 'nameSurname',
      Cell: ({...props}) => <CustomCellDouble item={props} double='title' />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.SEARCH.PHONE' })} className='min-w-125px' />,
      accessor: 'phone',
      Cell: ({...props}) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.SEARCH.TOTALBALANCE' })} className='min-w-125px' />,
      accessor: 'balance',
      Cell: ({...props}) => <CustomCellDouble item={props} double='balanceInformation' price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    },
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(staffActions.staffExcel(params, intl.formatMessage({ id: 'BC.STAFF' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'STAFF.SEARCH.PROJECT',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'STAFF.CREATE.IDENTITYNUMBER',
          type: 'text',
          name: 'identityNumber'
        },
        {
          title: 'FILTER.HASBALANCE',
          data: [            
            {
              id: 'true',
              name: intl.formatMessage({ id: 'FILTER.HASBALANCE.TRUE' })
            },
            {
              id: 'false',
              name: intl.formatMessage({ id: 'FILTER.HASBALANCE.FALSE' })
            },
          ],
          name: 'hasBalance'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.STAFF' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'STAFF'}
        />
        <KTCardBody>
          {!staff && <ListLoading /> }
          {staff && <Table 
                      items={staff} 
                      cols={Columns} 
                      handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
                      paddless 
                    /> }
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {List};
