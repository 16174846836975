/* eslint-disable react/react-in-jsx-scope */
import {useState, useMemo, useRef, useEffect} from 'react'
import debounce from 'lodash/debounce'
import {KTSVG} from '../../../helpers'

const useDebounce = (callback) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => ref.current?.()

    return debounce(func, 1000)
  }, [])

  return debouncedCallback
}

const ListSearchComponent = ({handleSearch, name}) => {
  const [value, setValue] = useState(name || '')

  const onChange = () => handleSearch && handleSearch(value)

  const debouncedOnChange = useDebounce(onChange)

  useEffect(() => {
    setValue(name)
  }, [name])

  return (
    <div className='card-title'>
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/images/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Arama'
          value={value}
          onChange={(e) => {
            debouncedOnChange(e)
            setValue(e.target.value.toLocaleLowerCase('TR'))
          }}
        />
      </div>
    </div>
  )
}

export {ListSearchComponent}
