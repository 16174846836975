import { profileConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false
}

export function profile(state = {}, action) {
  switch (action.type) {

    case profileConstants.PROFILE_GETINFO_REQUEST:
      return { ...state, ...initialState };
    case profileConstants.PROFILE_GETINFO_SUCCESS:
      return {
        ...state,
        info: action.info,
        loading: false
      };
    case profileConstants.PROFILE_GETINFO_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case profileConstants.USERPROFILE_GETINFO_REQUEST:
      return { ...state, ...initialState };
    case profileConstants.USERPROFILE_GETINFO_SUCCESS:
      return {
        ...state,
        userProfile: action.userProfile,
        loading: false
      };
    case profileConstants.USERPROFILE_GETINFO_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case profileConstants.USERPROFILE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case profileConstants.USERPROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.userProfile || action.params,
        userProfile: action.params,
        loading: false
      };
    case profileConstants.USERPROFILE_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    default:
      return state
  }
}
