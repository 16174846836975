/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl, KTSVG} from '../../helpers'
import {UserMenu} from './UserMenu'

import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../../redux/actions';

const AsideUserMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { info } = useSelector(state => state.profile);
	const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(profileActions.profileGetInfo());
    });
  }, [])

  return (
    <>
      <div className='d-flex flex-stack'>
        <div className='d-flex align-items-center mw-75'>
          <div className='symbol symbol-circle symbol-40px'>
            <img src={toAbsoluteUrl('/images/blank.png')} alt='avatar' />
          </div>

          <div className='ms-2 mw-100'>
            <div className='text-gray-800 fs-6 fw-bolder lh-1 text-truncate '>
              {info?.firstName} {info?.lastName}
            </div>
          </div>
        </div>

        <div className='ms-1'>
          <div
            className={clsx('btn btn-sm btn-icon btn-active-color-primary position-relative me-n2', {'show': isOpen, 'menu-dropdown': isOpen})}
            data-kt-menu-overflow='false'
            data-kt-menu-placement='top-end'
            onClick={() => setIsOpen(!isOpen)}
          >
            <KTSVG path='/images/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
          </div>
          <UserMenu isOpen={isOpen} user={info} />
        </div>
      </div>
    </>
  )
}

export {AsideUserMenu}
