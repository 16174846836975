/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import classNames from 'classnames'
import { toast } from 'react-toastify';

import {useDispatch, useSelector} from 'react-redux'
import {categoryActions} from '../../redux/actions'

const Category = (props) => {
  const {className, placeholder, SourceId, SourceTypeId, ...rest} = props
  const [value, setValue] = useState(null)
  const [newName, setNewName] = useState()
  const [data, setData] = useState()
  const intl = useIntl()

  const {category, isRedirect, detail} = useSelector((state) => state.category)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(categoryActions.categoryGetAll({SourceTypeId}))
  }, [SourceTypeId, isRedirect])

  useEffect(() => {
    setData(() => {
      const options = []
      category?.map((item) => options.push({value: item.id, label: item.name}))
      return [...options]
    })
  }, [category])

  useEffect(() => {
    if (data && data.length > 0) {
      dispatch(categoryActions.recordcategoryGet({SourceTypeId, SourceId}))
    }
  }, [data])

  useEffect(() => {
    if (detail && detail?.name) {
      setValue({value: detail?.id, label: detail?.name})
    } else {
      setValue(null)
    }
  }, [detail])

  useEffect(() => {
    if (isRedirect) {
      setValue({value: isRedirect?.id, label: newName})
      toast.success(intl.formatMessage({id: 'CATEGORY.MESSAGE'}), { autoClose: 5000, theme: "colored" })
    }
  }, [isRedirect])

  const handleOnChange = (item) => {
    dispatch(
      categoryActions.recordcategoryCreate({
        SourceId,
        SourceTypeId,
        Name: item.label,
        CategoryId: item.value,
      })
    )
  }

  const handleCreate = (Name) => {
    if (Name) {
      dispatch(categoryActions.recordcategoryCreate({SourceId, SourceTypeId, Name, CategoryId: null}))
      setNewName(Name)
    }
  }

  const formatCreateLabel = () => `+ ${intl.formatMessage({id: 'BUTTON.ADD'})}`

  return (
    <CreatableSelect
      placeholder={placeholder || intl.formatMessage({id: 'NON_CATEGORY'})}
      classNamePrefix='form-select-creatable'
      className={classNames('form-select-creatable--transparent', className)}
      options={data}
      isLoading={!category}
      onCreateOption={handleCreate}
      formatCreateLabel={formatCreateLabel}
      onChange={handleOnChange}
      allowCreateWhileLoading
      isValidNewOption={() => true}
      value={value}
      menuPortalTarget={document.body}
      {...rest}
    />
  )
}

Category.propTypes = {
  data: PropTypes.array,
  placeholder: PropTypes.string,
}

export {Category}
