import React from 'react'
import {NumericFormat} from 'react-number-format'
import PropTypes from 'prop-types';

const InputPrice = (props) => {
  const {onChange, suffix, ...rest} = props

  const handleValueChange = (values) => {
    const {floatValue} = values
    onChange && onChange(floatValue || 0)
  }

  return (
    <NumericFormat
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={2}
      fixedDecimalScale={true}
      suffix={` ${suffix}`}
      onValueChange={handleValueChange}
      className='form-control form-control-solid mb-3 mb-lg-0'
      {...rest}
    />
  )
}

InputPrice.propTypes = {
	suffix: PropTypes.string,
};

InputPrice.defaultProps = {
	suffix: '₺'
};

export {InputPrice}
