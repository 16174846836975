import React from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

const mappedLabel = (link) => {
  return link?.label
}

const ListPagination = ({pagination, handlePagination}) => {
  const intl = useIntl()

  const updatePage = (link) => {
    let page = link?.page
    if (!page || pagination?.page === page) return;
    // TODO : Request to link.url
    // TODO : Sonraki is visible at the end of the page
    handlePagination && handlePagination({page, items_per_page: pagination?.items_per_page || 10})
  }

  return (
    <div className='row mt-5'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <span className='text-start text-gray-400 fw-bold fs-7 gs-0'>{intl.formatMessage({ id: 'TABLE.TOTAL' })}: {pagination?.total}</span>
      </div>

      {pagination?.links?.length > 3 && <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {pagination?.links
              ?.map((link) => {
                return {...link, label: mappedLabel(link)}
              })
              .map((link) => (
                <li
                  key={link?.label}
                  className={clsx('page-item', {
                    active: pagination?.page === link?.page,
                    previous: link?.type === 'PREVIOUS',
                    next: link?.type === 'NEXT',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link?.type === 'PREVIOUS' || link?.type === 'NEXT',
                      'me-5': link?.type === 'PREVIOUS',
                    })}
                    onClick={() => updatePage(link)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>}
    </div>
  )
}

export {ListPagination}
