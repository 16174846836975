/* eslint-disable react/react-in-jsx-scope */
import { Link } from "react-router-dom";
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

const ListToolbar = ({buttons, actionButtons, onClickShow}) => {
  const intl = useIntl()
  
  return (
    <div className='d-flex justify-content-end gap-3'>
      {actionButtons?.map((item, index) => {
        return (
          <button key={index} className={`btn btn-${item.variant} d-flex`} onClick={(event) => {
            if (item.onClick) {
              item.onClick(event)
            } else {
              onClickShow && onClickShow()
            }
          }}>
            <KTSVG path={item.icon} className='svg-icon-2' />{intl.formatMessage({id: item.title })}
          </button>
        )
      })}

      <div className='btn-group' role="group">
        {buttons.map((item, index) => {
          if (item.list) {
            return (
              <Dropdown key={index} className='btn-group' autoClose="outside" align={{ lg: 'end' }}>
                <Dropdown.Toggle variant={item.variant}>
                  {item.icon && <KTSVG path={item.icon} className='svg-icon-2' />}
                  {intl.formatMessage({id: item.title })}
                </Dropdown.Toggle>

                <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                  {item.list?.map((list, i) => <Dropdown.Item key={i} href={list.to} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({id: list.title })}</div></Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
            )
          }

          return (<Link className={`btn btn-${item.variant} d-flex`} to={item.to} key={index}>
            {item.icon && <KTSVG path={item.icon} className='svg-icon-2' />}
            {intl.formatMessage({id: item.title })}
          </Link>)
        })}
      </div>
    </div>
  )
}

ListToolbar.propTypes = {
	buttons: PropTypes.array,
};

export {ListToolbar}
