/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl} from '../../helpers'
import {useLang, setLanguage} from '../../i18n/Metronici18n'
import {useIntl} from 'react-intl'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/images/flags/united-states.svg'),
  },
  {
    lang: 'tr',
    name: 'Türkçe',
    flag: toAbsoluteUrl('/images/flags/turkey.svg'),
  }
]
const Languages = ({languageMenuPlacement = 'lert-start'}) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl()
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  const styles = {
    zIndex: '106',
    position: 'fixed',
    inset: 'auto 0px 0px auto',
    margin: '0',
    transform: 'translate(175px, -103px)'
  }

  return (
    <div
      className={clsx('menu-item px-5', {'show': isOpen, 'menu-dropdown': isOpen})}
      data-kt-menu-placement={languageMenuPlacement}
      data-kt-menu-flip='bottom'
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'MENU.PROFILE.LANGUAGE' })}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='Flag'
            />
          </span>
        </span>
      </a>

      <div 
        className={clsx('menu-sub menu-sub-dropdown w-175px py-4', {'show': isOpen})}
        style={ isOpen ? {...styles} : {} }
      >
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='flag' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
