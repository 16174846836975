/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { KTCard, KTCardBody, KTCardHeader } from '../../components';
import { PageTitle } from '../../core';

import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../../redux/actions';

function ProfileEdit() {
  const intl = useIntl()
  const navigate = useNavigate();

  const { isRedirect, userProfile, loading } = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileActions.userProfileGet());
  }, [])

  const validSchema = Yup.object().shape({
    FirstName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    LastName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    FirstName: '',
    LastName: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(profileActions.userProfileUpdate(values));
    },
  })

  useEffect(() => {
    for (const property in userProfile) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);
      for (const values in formValues) {
        if (values === propVal) {
          formik.setFieldValue(propVal, userProfile[property])
        }
      }
    }
  }, [userProfile])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'MENU.PROFILE.PROFILE' }),
      path: '/profile',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/profile`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: 'PAGE_TITLE.EDIT' })}</PageTitle>
      <KTCard>
        <KTCardHeader title={`${intl.formatMessage({ id: 'PROFILE.UPDATE.EDIT' })} ${intl.formatMessage({id: 'PAGE_TITLE.EDIT' })}`}>
          <div className="btn-group" role="group">
            <button className="btn btn-light align-self-center" onClick={() => navigate(-1)}>{intl.formatMessage({id: 'BUTTON.BACK' })}</button>
          </div>
        </KTCardHeader>
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PROFILE.UPDATE.FIRSTNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('FirstName')}
                />
                {formik.touched.FirstName && formik.errors.FirstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.FirstName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PROFILE.UPDATE.LASTNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('LastName')}
                />
                {formik.touched.LastName && formik.errors.LastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.LastName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROFILE.UPDATE.EMAIL' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <span className="fw-bold fs-6">{userProfile.email}</span>
              </div>
            </div>

            <div className='text-center pt-15'>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'PLEASE' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {ProfileEdit};
