/* eslint-disable react/react-in-jsx-scope */
const CustomActionCell = ({ title, onClick, id }) => {
  return (
    <button className='btn btn-success btn-active-light-primary btn-sm' onClick={() => onClick && onClick(id)}>
      {title}
    </button>
  )
}

export {CustomActionCell}
