import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl'
import classNames from 'classnames'

const FormSelect = (props) => {
  const { className, value, required, data, placeholder, onChange, isClearable, textType, disabled, menuPortalTarget,  ...rest } = props;
  const intl = useIntl()

  const handleChange = (value) => onChange && onChange(value) 
  const options = [];
  let newValue;
  data?.map((item) => options.push({value: item.id || item.Id, label: textType === 'intl' ? intl.formatMessage({id: item.name || item?.Name }) : item.name || item?.Name}))
  if (rest.isMulti) {
    const multi = []
    value.map(item => {
      const findItem = options.find(fitem => fitem.value == item);
      multi.push(findItem)
    })
    newValue = multi;
  } else {
    newValue = options.find(item => item.value == value);
  }
  

  return (
    <Select 
      placeholder={placeholder || intl.formatMessage({id: 'CHOOSE' })}
      classNamePrefix = 'form-select'
      isClearable={isClearable}
      className={classNames('form-select-solid', className)}
      isLoading={!data}
      options={options}
      value={newValue}
      onChange={(newValue) => handleChange(newValue)}
      required={required}
      menuPortalTarget={menuPortalTarget}
      filterOption={(option, query) =>
        String(option.data.label)
          .toLocaleLowerCase('tr')
          .includes(query.toLocaleLowerCase('tr'))
      }
      isDisabled={disabled}
      {...rest}
    />
  )
}

FormSelect.propTypes = {
	value: PropTypes.any,
	required: PropTypes.bool,
	data: PropTypes.array,
	placeholder: PropTypes.string,
	isClearable: PropTypes.bool,
	onChange: PropTypes.func,
	textType: PropTypes.string,
	disabled: PropTypes.bool,
  menuPortalTarget: PropTypes.any
};

FormSelect.defaultProps = {
	isClearable: true,
  menuPortalTarget: document.body
};

export {FormSelect}
