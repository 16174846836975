import React from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { arial } from "./font";

const PdfButton = ({data, title}) => {
  const intl = useIntl();

  const downloadPdf = (data) => {
    const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
    
    doc.addFileToVFS("Arial-Regular.ttf", arial);
    doc.addFont("Arial-Regular.ttf", "Arial", "normal");
    doc.setFont("Arial");
    doc.autoTable(data?.columns, data?.data, { startY: 20, styles: { font: "Arial", fontStyle: "normal" } });
    doc.setLanguage('tr');
    doc.save(`${title}.pdf`);
  };

  return (
    <button type="button" className="btn btn-sm btn-light-primary" onClick={() => downloadPdf(data)}>{intl.formatMessage({ id: 'BUTTON.PDF.DOWNLOAD' })}</button>
  )
}

PdfButton.propTypes = {
	title: PropTypes.string,
  data: PropTypes.object
};

export {PdfButton}
