import React from 'react'
import {useIntl} from 'react-intl'

const PaymentStatus = ({className, balance, dueDate, payment, delayDay}) => {
  const intl = useIntl()

  const getDayDifference = () => {
    if (!delayDay && delayDay != 0) {
      const today = new Date();
      const otherDate = new Date(dueDate);
      const todayTime = today.getTime();
      const otherDateTime = otherDate.getTime();
      const timeDifference = todayTime - otherDateTime;

      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      return parseInt(daysDifference, 10);
    }

    return delayDay
  }

  const color = getDayDifference() > 0 && balance > 0 ? 'danger' : 'success';
  const progress = `${100 - ((balance / payment) * 100)}%`;

  return (
    <div className={`mb-5 card ${className}`}>
      <div className='card-body'>
        <div className='py-1 d-flex justify-content-between align-items-baseline flex-wrap'>
          <span className='fw-bold fs-5 mb-1'>{balance > 0 ? intl.formatMessage({id: 'SALARY.BALANCE' }) : intl.formatMessage({id: 'PAYMENT.PAID' })}</span>
          {balance > 0 && <span className='text-dark fs-1 fw-bold me-2'>{intl.formatNumber(balance, { style: 'currency', currency: 'try' })}</span>}
          {balance == 0 && <span className='text-dark fs-1 fw-bold me-2'>{intl.formatNumber(payment, { style: 'currency', currency: 'try' })}</span>}
        </div>

        <div className={`progress h-7px`}>
          <div
            className={`progress-bar bg-${color}`}
            role='progressbar'
            style={{width: progress}}
          />
        </div>

        {getDayDifference() != 0 && balance > 0 && <div className='d-flex align-items-baseline fw-bold fs-3 mt-5'>
          {getDayDifference() > 0 ? `${getDayDifference()} ${intl.formatMessage({ id: 'PROJECT.DAYPASSED' })}` : `${getDayDifference()*-1} ${intl.formatMessage({ id: 'PROJECT.DAYLEFT' })}` }
        </div> }
      </div>
    </div>
  )
}

export {PaymentStatus}
