import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { KTSVG, toAbsoluteUrl } from '../../helpers'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch } from 'react-redux';
import { officialActions } from '../../redux/actions';
import { DeleteModal, OfficialModal, InputPrice } from '..';

const Official = ({ExpenseInvoiceId, data, isFormVisible}) => {
  const intl = useIntl()
  const [deleteId, setDeleteId] = useState();
  const [expenseInvoiceId, setExpenseInvoiceId] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

	const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    TotalPrice: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    TotalTax: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    TotalWithHolding: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    InvoiceDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    ExpenseInvoiceId,
    Name: '',
    InvoiceNumber: '',
    TotalPrice: 0,
    TotalTax: 0,
    TotalWithHolding: 0,
    OfficialExpenseFile: null,
    InvoiceDate: new Date(),
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(officialActions.officialExpenseCreate(values));
      resetForm()
    },
  })

  const handleClickDelete = (id, expenseInvoiceId) => {
    setDeleteId(id)
    setExpenseInvoiceId(expenseInvoiceId)
    setShow(true)
  }

  const handleClickEdit = (item) => {
    setSelectedItem(item)
    setShowEdit(true)
  }

  const handleDelete = () => {
    dispatch(officialActions.officialExpenseDelete({Id: deleteId, ExpenseInvoiceId: expenseInvoiceId}));
    setShow(false)
  }

  return (
    <>
      {isFormVisible && <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-6 pt-5 pb-5'>
          <div className='col-lg-6'>
            <div className='row p-2'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.NAME' })}</label>
              <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
              </div>
            </div>
          </div>
          
          <div className='col-lg-6'>
            <div className='row p-2'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.INVOICENUMBER' })}</label>
              <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('InvoiceNumber')}
                />
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='row p-2'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.INVOICEDATE' })}</label>
              <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center mb-3 mb-lg-0">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr 
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('InvoiceDate')}
                    onChange={([date]) => formik.setFieldValue('InvoiceDate', date)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='row p-2'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.TOTALPRICE' })}</label>
              <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('TotalPrice')} 
                  onChange={(value) => formik.setFieldValue('TotalPrice', Number(value))} 
                />
                {formik.touched.TotalPrice && formik.errors.TotalPrice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.TotalPrice}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='row p-2'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.TOTALTAX' })}</label>
              <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('TotalPrice')} 
                  onChange={(value) => formik.setFieldValue('TotalPrice', Number(value))} 
                />
                {formik.touched.TotalTax && formik.errors.TotalTax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.TotalTax}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='row p-2'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.TOTALWITHHOLDING' })}</label>
              <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('TotalWithHolding')} 
                  onChange={(value) => formik.setFieldValue('TotalWithHolding', Number(value))} 
                />
                {formik.touched.TotalWithHolding && formik.errors.TotalWithHolding && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.TotalWithHolding}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='row p-2'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.FILE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <label htmlFor='OfficialExpenseFile' className='btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark position-relative overflow-hidden'>
                  <input
                    className='position-absolute z-index-2 opacity-0 w-1px h-1px'
                    type='file'
                    accept='image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    id={'OfficialExpenseFile'}
                    name={'OfficialExpenseFile'}
                    onChange={(event) => formik.setFieldValue('OfficialExpenseFile', event.currentTarget.files[0])}
                  />
                  <KTSVG
                    path='/images/icons/duotune/communication/com008.svg'
                    className='svg-icon-3 position-relative'
                  />
                  {intl.formatMessage({ id: 'OFFICIALEXPENSE.CREATE.FILE' })}
                </label>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({ id: 'PLEASE' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>}

      {data?.length > 0 && <div className='table-responsive'>
        <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
          <thead>
            <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
              <th>{intl.formatMessage({id: 'OFFICIALEXPENSE.GET.NAME' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'OFFICIALEXPENSE.GET.INVOICENUMBER' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'OFFICIALEXPENSE.GET.INVOICEDATE' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'OFFICIALEXPENSE.GET.TOTALPRICE' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'OFFICIALEXPENSE.GET.TOTALTAX' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'OFFICIALEXPENSE.GET.TOTALWITHHOLDING' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'OFFICIALEXPENSE.GET.FILE' })}</th>
              <th className='text-end'>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => <tr key={index}>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                  {item.name}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                  {item.invoiceNumber}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatDate(new Date(item?.invoiceDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatNumber(item.totalPrice, { style: 'currency', currency: 'try' })}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatNumber(item.totalTax, { style: 'currency', currency: 'try' })}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatNumber(item.totalWithHolding, { style: 'currency', currency: 'try' })}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {item?.fileUrl ? <a href={item?.fileUrl} target='_blank' rel="noreferrer" className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'><img src={toAbsoluteUrl('/images/icons/duotune/files/fil021.svg')} className="h-50 align-self-center" alt="" /></span>
                    </div>
                  </a> : '-'}
                </div>
              </td>
              <td className='p-5 text-end'>
                <button 
                  type="button" 
                  className="btn btn-sm btn-icon btn-active-color-primary" 
                  onClick={() => handleClickEdit(item)}
                >
                  <KTSVG path='/images/icons/duotune/general/gen055.svg' className='svg-icon-2' />
                </button>
                <button 
                  type="button" 
                  className="btn btn-sm btn-icon btn-active-color-primary" 
                  onClick={() => handleClickDelete(item.id, item.expenseInvoiceId)}
                >
                  <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                </button>
              </td>
            </tr> )}
          </tbody>
        </table>
      </div>}

      <OfficialModal
        onOpen={showEdit}
        data={selectedItem}
        onClose={() => setShowEdit(false)}
      />

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
      />
    </>
  )
}

Official.propTypes = {
	collapse: PropTypes.bool,
  SourceId: PropTypes.number,
	SourceTypeId: PropTypes.number,
	isFormVisible: PropTypes.bool,
};

Official.defaultProps = {
	isFormVisible: true
};

export {Official}
