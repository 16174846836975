import { paymentRequestConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  updateConfirmation: false,
  onConfirmationState: false
}

export function paymentRequest(state = {}, action) {
  switch (action.type) {
    //#region Payment Request
    case paymentRequestConstants.PAYMENTREQUEST_GETALL_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_GETALL_SUCCESS:
      return {
        ...state,
        paymentRequest: action.paymentRequest,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentRequestConstants.PAYMENTREQUEST_CREATE_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.paymentRequest,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case paymentRequestConstants.PAYMENTREQUEST_GETID_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_GETID_SUCCESS:
      return {
        ...state,
        detail: action.paymentRequest,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case paymentRequestConstants.PAYMENTREQUEST_DELETE_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case paymentRequestConstants.PAYMENTREQUEST_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.paymentRequest || action.params,
        detail: action.params,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case paymentRequestConstants.PAYMENTREQUEST_ONCONFIRMATIONSTATE_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_ONCONFIRMATIONSTATE_SUCCESS:
      return {
        ...state,
        onConfirmationState: true,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_ONCONFIRMATIONSTATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentRequestConstants.PAYMENTREQUEST_GETCONFIRMATIONSTATE_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_GETCONFIRMATIONSTATE_SUCCESS:
      return {
        ...state,
        getConfirmationState: action.getConfirmationState,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_GETCONFIRMATIONSTATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case paymentRequestConstants.PAYMENTREQUEST_UPDATECONFIRMATION_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.PAYMENTREQUEST_UPDATECONFIRMATION_SUCCESS:
      return {
        ...state,
        updateConfirmation: true,
        loading: false
      };
    case paymentRequestConstants.PAYMENTREQUEST_UPDATECONFIRMATION_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion  
    
    //#region WaitingConfirmation
    case paymentRequestConstants.WAITINGCONFIRMATION_GETALL_REQUEST:
      return { ...state, ...initialState };
    case paymentRequestConstants.WAITINGCONFIRMATION_GETALL_SUCCESS:
      return {
        ...state,
        waitingConfirmation: action.waitingConfirmation,
        loading: false
      };
    case paymentRequestConstants.WAITINGCONFIRMATION_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    //#endregion    
    default:
      return state
  }
}
