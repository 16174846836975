import { categoryConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  detail: null
}

export function category(state = {}, action) {
  switch (action.type) {
    //#region Category
    case categoryConstants.CATEGORY_GETALL_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.CATEGORY_GETALL_SUCCESS:
      return {
        ...state,
        category: action.category,
        loading: false
      };
    case categoryConstants.CATEGORY_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    
    case categoryConstants.CATEGORY_DELETE_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case categoryConstants.CATEGORY_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case categoryConstants.CATEGORY_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.CATEGORY_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case categoryConstants.CATEGORY_ARCHIVING_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case categoryConstants.CATEGORY_CREATE_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.category,
        loading: false
      };
    case categoryConstants.CATEGORY_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case categoryConstants.CATEGORY_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.category || action.params,
        detail: action.params,
        loading: false
      };
    case categoryConstants.CATEGORY_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region RecordCategory
    case categoryConstants.RECORDCATEGORY_CREATE_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.RECORDCATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.recordcategory,
        loading: false
      };
    case categoryConstants.RECORDCATEGORY_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case categoryConstants.RECORDCATEGORY_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.RECORDCATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.recordcategory || action.params,
        detail: action.params,
        loading: false
      };
    case categoryConstants.RECORDCATEGORY_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    
    case categoryConstants.RECORDCATEGORY_GETID_REQUEST:
      return { ...state, ...initialState };
    case categoryConstants.RECORDCATEGORY_GETID_SUCCESS:
      return {
        ...state,
        detail: action.recordcategory,
        loading: false
      };
    case categoryConstants.RECORDCATEGORY_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    default:
      return state
  }
}
