import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { expenseinvoiceActions, lookupActions, categoryActions } from '../../../redux/actions';

import { ExpensePaymentStatus } from '../../../helpers';

function List() {
  const intl = useIntl()
  const { expenseinvoice, loading } = useSelector(state => state.expenseinvoice);
  const { projectLookup, companyLookup, ExpenseType, InvoiceKind } = useSelector(state => state.lookup);
  const { category } = useSelector((state) => state.category)
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) dispatch(expenseinvoiceActions.expenseinvoiceGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('ExpenseType'));
    dispatch(lookupActions.basicLookup('InvoiceKind'));
    dispatch(categoryActions.categoryGetAll({ SourceTypeId: 8 }))
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCellDouble item={props} to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} double="companyName" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.PROJECTNAME' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props} to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} />,
    },    
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.CATEGORYNAME' })} className='min-w-125px' />,
      accessor: 'categoryName',
      Cell: ({ ...props }) => <CustomCell item={props} to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))} title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.INVOICEKINDNAME' })} className='min-w-125px' />,
      accessor: 'invoiceKindName',
      Cell: ({ ...props }) => <CustomCell item={props} to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} />,
    }, 
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.EDITDATE' })} className='min-w-125px' />,
      accessor: 'editDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.DEFERMENTDATE' })} className='min-w-125px' />,
      accessor: 'defermentDate',
      Cell: ({ ...props }) => <CustomCellDouble item={props} date="short" to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} double="delayDay" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.TOTALPRICE' })} className='min-w-125px' />,
      accessor: 'totalPrice',
      Cell: ({ ...props }) => <CustomCell item={props} to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'EXPENSEINVOICE.SEARCH.BALANCE' })} className='min-w-125px' />,
      accessor: 'balance',
      Cell: ({ ...props }) => <CustomCellDouble item={props} to={`${props.data[props.row.index].expenseTypeName}/detail/${props.data[props.row.index].id}`} double="paymentStatusName" price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    },
  ];

  const buttons = [
    {
      title: 'BUTTON.EXPENSE.SUNDRY.ADD',
      to: 'sundry/create',
      icon: '/images/icons/duotune/arrows/arr075.svg',
      variant: 'primary'
    },    
    {
      title: 'BUTTON.EXPENSE.FAST.ADD',
      to: 'fast/create',
      icon: '/images/icons/duotune/arrows/arr075.svg',
      variant: 'primary'
    },
    {
      title: 'BUTTON.EXPENSE.DETAIL.ADD',
      to: 'detail/create',
      icon: '/images/icons/duotune/arrows/arr075.svg',
      variant: 'primary'
    },
    {
      title: 'BUTTON.OTHER',
      to: 'create',
      icon: '/images/icons/duotune/arrows/arr075.svg',
      variant: 'primary',
      list: [
        {
          title: 'BUTTON.EXPENSE.TAX.ADD',
          to: 'expenses/expenses/tax/create'
        },
        {
          title: 'BUTTON.EXPENSE.BANK.ADD',
          to: 'expenses/expenses/bank/create'
        }       
      ]
    }
  ]

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(expenseinvoiceActions.expenseinvoiceExcel(params, intl.formatMessage({ id: 'BC.EXPENSES' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'EXPENSEINVOICE.SEARCH.PROJECTNAME',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'FILTER.EXPENSEINVOICE.COMPANYID',
          data: companyLookup,
          name: 'companyId'
        },
        {
          title: 'FILTER.EXPENSEINVOICE.EXPENSEPAYMENTSTATUSID',
          data: ExpensePaymentStatus,
          name: 'expensePaymentStatusId'
        },
        {
          title: 'FILTER.EXPENSEINVOICE.EXPENSETYPE',
          data: ExpenseType,
          name: 'expenseTypeId'
        },   
        {
          title: 'EXPENSEINVOICE.SEARCH.EDITSTARTDATE',
          type: 'date',
          name: 'editStartDate'
        },
        {
          title: 'EXPENSEINVOICE.SEARCH.EDITENDDATE',
          type: 'date',
          name: 'editEndDate'
        },
        {
          title: 'EXPENSEINVOICE.SEARCH.CATEGORYNAME',
          data: category,
          name: 'categoryId'
        },
        {
          title: 'EXPENSEINVOICE.SEARCH.INVOICEKINDNAME',
          data: InvoiceKind,
          name: 'invoiceKindId'
        }, 
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.EXPENSES' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          buttons={buttons}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages='EXPENSES'
        />
        <KTCardBody>
          {!expenseinvoice && <ListLoading />}
          {expenseinvoice && <Table
            items={expenseinvoice}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
