export const AuthRole = {
  Admin: { id: 1, name:'Admin'},
  Stock: { id: 2, name: 'Stock' },
  Staff: { id: 3, name: 'Staff' },
  Bank: { id: 4, name: 'Bank' },
  Project: { id: 5, name: 'Project' },
  Expense: { id: 6, name: 'Expense' },
  Sale: { id: 7, name: 'Sale' },
  Task: { id: 8, name: 'Task' },
  Purchase: { id: 9, name: 'Purchase' },
  Reporting: { id: 10, name: 'Reporting' }
}
