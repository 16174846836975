export const officialConstants = {

  OFFICIALEXPENSE_CREATE_REQUEST: 'OFFICIALEXPENSE_CREATE_REQUEST',
  OFFICIALEXPENSE_CREATE_SUCCESS: 'OFFICIALEXPENSE_CREATE_SUCCESS',
  OFFICIALEXPENSE_CREATE_FAILURE: 'OFFICIALEXPENSE_CREATE_FAILURE',

  OFFICIALEXPENSE_DELETE_REQUEST: 'OFFICIALEXPENSE_DELETE_REQUEST',
  OFFICIALEXPENSE_DELETE_SUCCESS: 'OFFICIALEXPENSE_DELETE_SUCCESS',
  OFFICIALEXPENSE_DELETE_FAILURE: 'OFFICIALEXPENSE_DELETE_FAILURE',

  OFFICIALEXPENSE_UPDATE_REQUEST: 'OFFICIALEXPENSE_UPDATE_REQUEST',
  OFFICIALEXPENSE_UPDATE_SUCCESS: 'OFFICIALEXPENSE_UPDATE_SUCCESS',
  OFFICIALEXPENSE_UPDATE_FAILURE: 'OFFICIALEXPENSE_UPDATE_FAILURE',
};
