export const checkConstants = {
  //#region Check
  CHECK_GETALL_REQUEST: 'CHECK_GETALL_REQUEST',
  CHECK_GETALL_SUCCESS: 'CHECK_GETALL_SUCCESS',
  CHECK_GETALL_FAILURE: 'CHECK_GETALL_FAILURE',

  CHECK_GETID_REQUEST: 'CHECK_GETID_REQUEST',
  CHECK_GETID_SUCCESS: 'CHECK_GETID_SUCCESS',
  CHECK_GETID_FAILURE: 'CHECK_GETID_FAILURE',

  CHECK_ADDCOLLECTION_REQUEST: 'CHECK_ADDCOLLECTION_REQUEST',
  CHECK_ADDCOLLECTION_SUCCESS: 'CHECK_ADDCOLLECTION_SUCCESS',
  CHECK_ADDCOLLECTION_FAILURE: 'CHECK_ADDCOLLECTION_FAILURE',

  CHECK_ADDPAYMENT_REQUEST: 'CHECK_ADDPAYMENT_REQUEST',
  CHECK_ADDPAYMENT_SUCCESS: 'CHECK_ADDPAYMENT_SUCCESS',
  CHECK_ADDPAYMENT_FAILURE: 'CHECK_ADDPAYMENT_FAILURE',
  
  CHECK_EXCEL_REQUEST: 'CHECK_EXCEL_REQUEST',
  CHECK_EXCEL_SUCCESS: 'CHECK_EXCEL_SUCCESS',
  CHECK_EXCEL_FAILURE: 'CHECK_EXCEL_FAILURE',
  
  CHECK_UPDATE_REQUEST: 'CHECK_UPDATE_REQUEST',
  CHECK_UPDATE_SUCCESS: 'CHECK_UPDATE_SUCCESS',
  CHECK_UPDATE_FAILURE: 'CHECK_UPDATE_FAILURE',
  //#endregion            
};
