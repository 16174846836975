/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTSVG } from '../../../helpers';
import { KTCard, KTCardBody, InputPrice, Select } from '../../../components';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions, lookupActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { BloodType, projectLookup } = useSelector(state => state.lookup);
  const { isRedirect, detail, loading } = useSelector(state => state.staff);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('BloodType'));
    dispatch(staffActions.staffGetId(id));
  }, [])

  const validSchema = Yup.object().shape({
    NameSurname: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectIds: Yup.array().of(Yup.string()).min(1, intl.formatMessage({ id: 'REQUIRED.GENERAL' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: id,
    BloodTypeId: null,
    NameSurname: '',
    Email: null,
    Phone: null,
    Address: null,
    RelativeInformation: null,
    JobStartDate: new Date(),
    LeaveDate: new Date(),
    Salary: 0,
    RegistryNumber: null,
    IdentityNumber: null,
    Title: null,
    Iban: null,
    ProjectIds: []
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(staffActions.staffUpdate(values));
      resetForm()
    },
  })

  useEffect(() => {
    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);

      if (property == 'projects') {
        detail['projects'].map((item, index) => {
          for (const invoice in item) {
            if (invoice === 'id') {
              formik.setFieldValue(`ProjectIds[${index}]`, item[invoice])
            }
          }
        })
      }

      for (const values in formValues) {
        if (values === propVal) {
          formik.setFieldValue(propVal, property === 'jobStartDate' || property === 'leaveDate' ? new Date(detail[property]) : detail[property])
        }
      }
    }
  }, [detail])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.STAFF' }),
      path: '/staff/staff',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/staff/staff/detail/${id}`,
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.STAFF.EDIT' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>

            <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.NAMESURNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('NameSurname')}
                />
                {formik.touched.NameSurname && formik.errors.NameSurname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.NameSurname}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'STAFF.CREATE.PROJECTS' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  required
                  data={projectLookup}
                  {...formik.getFieldProps('ProjectIds')}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={item => {
                    const newData = []
                    item?.map(data => newData.push(data.value))
                    formik.setFieldValue('ProjectIds', newData)
                  }}
                />
                {formik.touched.ProjectIds && formik.errors.ProjectIds && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectIds}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.TITLE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Title')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.IDENTITYNUMBER' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  maxLength={11}
                  minLength={11}
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('IdentityNumber')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.REGISTRYNUMBER' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('RegistryNumber')}
                />
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.PHONE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Phone')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.IBAN' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Iban')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.EMAIL' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  type='email'
                  autoComplete='off'
                  {...formik.getFieldProps('Email')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.ADDRESS' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <textarea
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={4}
                  {...formik.getFieldProps('Address')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.BLOODTYPEID' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={BloodType}
                  {...formik.getFieldProps('BloodTypeId')}
                  onChange={item => formik.setFieldValue('BloodTypeId', item.value)}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.RELATIVEINFORMATION' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('RelativeInformation')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.JOBSTARTDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    {...formik.getFieldProps('JobStartDate')}
                    onChange={([date]) => formik.setFieldValue('JobStartDate', date)}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.LEAVEDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    {...formik.getFieldProps('LeaveDate')}
                    onChange={([date]) => formik.setFieldValue('LeaveDate', date)}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'STAFF.CREATE.SALARY' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('Salary')} 
                  onChange={(value) => formik.setFieldValue('Salary', Number(value))} 
                />
              </div>
            </div>

            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
