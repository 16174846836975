import { userTaskConstants } from '../constants';
import {
  SearchUserTaskClient,
  GetUserTaskClient,
  DeleteUserTaskClient,
  UpdateUserTaskClient,
  CreateUserTaskClient,
  CompleteUserTaskClient
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
const searchUserTaskClient = new SearchUserTaskClient(SERVICE_URL);
const getUserTaskClient = new GetUserTaskClient(SERVICE_URL);
const createUserTaskClient = new CreateUserTaskClient(SERVICE_URL);
const deleteUserTaskClient = new DeleteUserTaskClient(SERVICE_URL);
const updateUserTaskClient = new UpdateUserTaskClient(SERVICE_URL);
const completeUserTaskClient = new CompleteUserTaskClient(SERVICE_URL);

export const userTaskActions = {
  userTaskGetAll,
  userTaskGetId,
  userTaskDelete,
  userTaskCreate,
  userTaskUpdate,
  userTaskComplete,
};

function userTaskGetAll(params) {
  return dispatch => {
    dispatch(request());
    searchUserTaskClient.search(params.name, params.userTaskStatusId, params.startDate, params.endDate, params.items_per_page, params.page, params.order, params.sort)
      .then(
        userTask => dispatch(success(userTask)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: userTaskConstants.USERTASK_GETALL_REQUEST } }
  function success(userTask) { return { type: userTaskConstants.USERTASK_GETALL_SUCCESS, userTask } }
  function failure(error) { return { type: userTaskConstants.USERTASK_GETALL_FAILURE, error } }
}

function userTaskGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getUserTaskClient.get(id)
      .then(
        userTask => dispatch(success(userTask)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: userTaskConstants.USERTASK_GETID_REQUEST, id } }
  function success(userTask) { return { type: userTaskConstants.USERTASK_GETID_SUCCESS, userTask } }
  function failure(error) { return { type: userTaskConstants.USERTASK_GETID_FAILURE, error } }
}

function userTaskDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteUserTaskClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: userTaskConstants.USERTASK_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: userTaskConstants.USERTASK_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: userTaskConstants.USERTASK_DELETE_FAILURE, error } }
}

function userTaskCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createUserTaskClient.create(params)
      .then(
        userTask => dispatch(success(userTask)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: userTaskConstants.USERTASK_CREATE_REQUEST, params } }
  function success(userTask) { return { type: userTaskConstants.USERTASK_CREATE_SUCCESS, userTask } }
  function failure(error) { return { type: userTaskConstants.USERTASK_CREATE_FAILURE, error } }
}

function userTaskUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateUserTaskClient.update(params)
      .then(
        userTask => dispatch(success(userTask, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: userTaskConstants.USERTASK_UPDATE_REQUEST, params } }
  function success(userTask, params) { return { type: userTaskConstants.USERTASK_UPDATE_SUCCESS, userTask, params } }
  function failure(error, params) { return { type: userTaskConstants.USERTASK_UPDATE_FAILURE, error, params } }
}

function userTaskComplete(params) {
  return dispatch => {
    dispatch(request(params));

    completeUserTaskClient.update(params)
      .then(
        completeTask => dispatch(success(completeTask)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: userTaskConstants.USERTASK_COMPLETE_REQUEST, params } }
  function success(completeTask) { return { type: userTaskConstants.USERTASK_COMPLETE_SUCCESS, completeTask } }
  function failure(error) { return { type: userTaskConstants.USERTASK_COMPLETE_FAILURE, error } }
}
