import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import {detailPageUrl, DetailPage } from '../../../helpers'

import { useDispatch, useSelector } from 'react-redux';
import { paymentRequestActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { waitingConfirmation, loading } = useSelector(state => state.paymentRequest);
  const { projectLookup, companyLookup, userProjectLookup } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) dispatch(paymentRequestActions.getWaitingConfirmationGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.userProjectLookup());
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'WAITINGCONFIRMATION.SEARCH.PROJECTNAME' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="name" to={detailPageUrl(DetailPage.PaymentRequest, props.data[props.row.index].paymentRequestId)} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'WAITINGCONFIRMATION.SEARCH.COMPANYTITLE' })} className='min-w-125px' />,
      accessor: 'companyTitle',
      Cell: ({ ...props }) => <CustomCell item={props} to={detailPageUrl(DetailPage.PaymentRequest, props.data[props.row.index].paymentRequestId)} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'WAITINGCONFIRMATION.SEARCH.REQUESTEDUSERNAMESURNAME' })} className='min-w-125px' />,
      accessor: 'requestedUserNameSurname',
      Cell: ({ ...props }) => <CustomCell item={props} to={detailPageUrl(DetailPage.PaymentRequest, props.data[props.row.index].paymentRequestId)} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'WAITINGCONFIRMATION.SEARCH.APPROVEUSERNAMESURNAME' })} className='min-w-125px' />,
      accessor: 'approveUserNameSurname',
      Cell: ({ ...props }) => <CustomCell item={props} to={detailPageUrl(DetailPage.PaymentRequest, props.data[props.row.index].paymentRequestId)} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'WAITINGCONFIRMATION.SEARCH.PAYMENTREQUESTCONFIRMATIONLEVELNAME' })} className='min-w-125px' />,
      accessor: 'paymentRequestConfirmationLevelName',
      Cell: ({ ...props }) => <CustomCell item={props} to={detailPageUrl(DetailPage.PaymentRequest, props.data[props.row.index].paymentRequestId)} />,
    },
  ];

  const buttons = []

  const actionButtons = [
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'WAITINGCONFIRMATION.SEARCH.PROJECTNAME',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'WAITINGCONFIRMATION.SEARCH.COMPANYTITLE',
          data: companyLookup,
          name: 'companyId'
        },
        {
          title: 'WAITINGCONFIRMATION.SEARCH.REQUESTEDUSERNAMESURNAME',
          data: userProjectLookup,
          name: 'approveUserId'
        },
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.WAITINGCONFIRMATION' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages='WAITINGCONFIRMATION'
          buttons={buttons}
        />
        <KTCardBody>
          {!waitingConfirmation && <ListLoading />}
          {waitingConfirmation && <Table
            items={waitingConfirmation}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
