import clsx from 'clsx'
import React, {useRef, useState, useEffect, useMemo} from 'react'
import debounce from 'lodash/debounce'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../helpers'
import {Link} from 'react-router-dom'

import {useDispatch, useSelector} from 'react-redux'
import {commonActions} from '../../redux/actions'

const useDebounce = (callback) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => ref.current?.()

    return debounce(func, 1000)
  }, [])

  return debouncedCallback
}

const Search = ({className = ''}) => {
  const [menuState] = useState('main')
  const [searchList, setSearchList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [newValue, setNewValue] = useState('')
  const element = useRef(null)
  const wrapperElement = useRef(null)
  const intl = useIntl()
  const dispatch = useDispatch()

  const {search, loading} = useSelector((state) => state.common)

  const handleChange = () => dispatch(commonActions.globalSearch({name: newValue}))

  const debouncedOnChange = useDebounce(handleChange)

  const handleOutsideClick = (event) => {
    const {target} = event

    if (target.nodeName == 'ASIDE' || target.parentElement.id == 'search-list') {
      setSearchList([])
      setNewValue('')
      setIsOpen(false)
      setIsMobileOpen(false)
    }
  }

  useEffect(() => {
    setSearchList(search)
    setIsOpen(search?.length > 0 ? true : false)
    setIsMobileOpen(search?.length > 0 ? true : false)
  }, [search])

  return (
    <aside
      id='kt_header_search'
      className={clsx(
        'd-flex align-items-center w-lg-250px',
        {show: isOpen || isMobileOpen, 'menu-dropdown': isOpen || isMobileOpen},
        className
      )}
      ref={element}
      onClick={(event) => handleOutsideClick(event)}
    >
      <div
        className={clsx('d-flex d-lg-none align-items-center', {
          active: isMobileOpen,
          show: isMobileOpen,
        })}
        onClick={() => setIsMobileOpen(true)}
      >
        <div className='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px'>
          <KTSVG path='/images/icons/duotune/general/gen021.svg' className='svg-icon-1' />
        </div>
      </div>

      <div
        data-kt-search-element='form'
        className={clsx(
          'd-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative mobile-search-form',
          {show: isMobileOpen}
        )}
        autoComplete='off'
      >
        <KTSVG
          path='/images/icons/duotune/general/gen004.svg'
          className='svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4'
        />

        <input
          type='text'
          className='form-control bg-transparent ps-13 fs-7 h-40px'
          name='search'
          placeholder={intl.formatMessage({id: 'DASHBOARD.SEARCH'})}
          data-kt-search-element='input'
          //onChange={handleChange}
          onChange={(e) => {
            debouncedOnChange(e)
            setNewValue(e.target.value.toLocaleLowerCase('TR'))
          }}
          value={newValue}
          autoComplete='off'
        />

        {loading && (
          <span
            className='position-absolute top-50 end-0 translate-middle-y lh-0 me-5'
            data-kt-search-element='spinner'
          >
            <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
          </span>
        )}
      </div>

      {isOpen && (
        <div
          data-kt-search-element='content'
          data-kt-menu='true'
          className={clsx(
            'menu menu-sub menu-sub-dropdown w-100 w-lg-350px py-7 px-7 overflow-hidden position-absolute search-content',
            {show: isOpen}
          )}
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <div>
              <div className='scroll-y mh-200px mh-lg-350px' id='search-list'>
                {searchList?.map((item, index) => {
                  return (
                    <Link
                      to={`/${item.sourceTypeLink}`}
                      key={index}
                      className='d-flex text-dark text-hover-primary align-items-center mb-5'
                    >
                      <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'>{item.name}</span>
                        <span className='fs-7 fw-bold text-muted'>{item.sourceTypeName}</span>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </aside>
  )
}

export {Search}
