/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik, FormikProvider, FieldArray} from 'formik'
import { KTSVG, ActivityReportType, ActivityReportState } from '../../../helpers';
import { KTCard, KTCardBody, Select } from '../../../components';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { workplanActions, lookupActions, productActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

	const { projectLookup, constructionSiteLookup, blockLookup, plotLookup, productLookup, ProductUnit } = useSelector(state => state.lookup);
  const { detail: product } = useSelector(state => state.product);
  const {isRedirect, loading, timeKeepingTemplate, checkreport, checkinfo} = useSelector(state => state.workplan);
	const dispatch = useDispatch();

  const [changeBlockItem, setChangeBlockItem] = useState(0)
  const [changePlotItem, setChangePlotItem] = useState(0)
  const [constructionId, setConstructionId] = useState(null)
  const [timeKeepingTemp, setTimeKeepingTemp] = useState([])
  const [changeProduct, setChangeProduct] = useState();

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.allProductLookup());
    dispatch(lookupActions.basicLookup('ProductUnit'));
    dispatch(lookupActions.staffGetAll());
  }, [])

  const validSchema = Yup.object().shape({
    ProjectId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    ActivityReportTypeId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    ActivityReportStateId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    EditDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    ActivityReportWorks: Yup.array().of(Yup.object().shape({
      PlotId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    }))
  })

  const [formValues] = useState({
    ProjectId: '',
    ActivityReportTypeId: '',
    ActivityReportStateId: 1,
    EditDate: new Date(),
    WeatherCondition: '',
    WorkProgress: '',
    LostWorkingHour: '',
    WorkmanTimesheet: '',
    Subcontractors: '',
    MachineryEquipment: '',
    AheadMilestones: '',
    HSELogs: '',
    QAQCLogs: '',
    ProjectLeader: '',
    SiteResponsibility: '',
    TechnicalOfficeChief: '',
    IncomingMaterial: '',
    ActivityReportWorks: [{
      blockItems: [],
      plotItems: [],
      PlotId: null,
      Description: ''
    }],
    ActivityReportTimeKeepings: [],
    ActivityReportProducts: [{
      ProductId: 0,
      Amount: 0,
      ProductUnitId: null,
      Description: ''
    }],
    IsRiskPrecaution: true,
    RiskPrecaution: ''
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(workplanActions.activityReportCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [    
    {
      title: intl.formatMessage({ id: 'BC.ACTIVITYREPORT' }),
      path: '/workplan/activity-report',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  const onClickNewItem = (arrayHelpers) => {
    arrayHelpers.push({
      blockItems: [],
      plotItems: [],
      PlotId: null,
      Description: ''
    })
  }

  useEffect(() => {
    if (formik.values.ProjectId) {
      dispatch(lookupActions.constructionSiteLookup({projectId: formik.values.ProjectId}));
      dispatch(workplanActions.activityReportGetAllTimeKeeping({projectId: formik.values.ProjectId}));
      formik.setFieldValue('ActivityReportWorks', [{
        blockItems: [],
        plotItems: [],
        PlotId: null,
        Description: ''
      }])
    }
  }, [formik.values.ProjectId])

  useEffect(() => {
    if (formik.values.ActivityReportTypeId && formik.values.EditDate && formik.values.ProjectId) {
      dispatch(workplanActions.activityReportCheckReport({
        projectId: formik.values.ProjectId, 
        activityReportTypeId: formik.values.ActivityReportTypeId, 
        editDate: formik.values.EditDate
      }));
    }
  }, [formik.values.ProjectId, formik.values.ActivityReportTypeId, formik.values.EditDate])

  const onChangeConstructionSite = (item, index) => {
    setChangeBlockItem(index)
    setConstructionId(item?.value)
    dispatch(lookupActions.blockLookup({constructionSiteId: item.value}));
  }

  useEffect(() => {
    formik.setFieldValue(`ActivityReportWorks[${changeBlockItem}].blockItems`, blockLookup);
  }, [blockLookup])

  const onChangeBlock = (item, index) => {
    setChangePlotItem(index)
    dispatch(lookupActions.plotLookup({
      projectId: formik.values.ProjectId,
      constructionId,
      blockId: item?.value
    }));
  }

  useEffect(() => {
    formik.setFieldValue(`ActivityReportWorks[${changePlotItem}].plotItems`, plotLookup);
  }, [plotLookup])

  useEffect(() => {
    if (timeKeepingTemplate) {
      const groupedData = {};
      const newFormItem = [];

      timeKeepingTemplate?.forEach(item => {
        const { timeKeepingTypeId, timeKeepingTypeName, id } = item;
      
        if (!groupedData[timeKeepingTypeId]) {
          groupedData[timeKeepingTypeId] = {
            timeKeepingTypeId,
            timeKeepingTypeName,
            items: [],
          };
        }
      
        groupedData[timeKeepingTypeId].items.push(item);
        newFormItem.push({
          employeeCount: 0,
          timeKeepingTemplateId: id
        })
      })
      
      formik.setFieldValue('ActivityReportTimeKeepings', newFormItem)
      setTimeKeepingTemp(Object.values(groupedData))
    } else {
      setTimeKeepingTemp([])
    }
  }, [timeKeepingTemplate])

  const onChangeTimeKeepingTemp = (value, id) => {
    const data = formik.values.ActivityReportTimeKeepings;
    data.map(item => {
      if (item.timeKeepingTemplateId == id) item.employeeCount = Number(value)
    })

    formik.setFieldValue(`ActivityReportTimeKeepings`, data);
  }

  const onChangeProduct = (item, index) => {
    formik.setFieldValue(`ActivityReportProducts[${index}].ProductId`, Number(item?.value))
    setChangeProduct(index)
    dispatch(productActions.productGetInfo({ Id: item?.value }));
  }

  useEffect(() => {
    if (product) {
      formik.setFieldValue(`ActivityReportProducts[${changeProduct}].ProductUnitId`, product?.productUnitId);
    }
  }, [product])

  const onClickNewProduct = (arrayHelpers) => {
    arrayHelpers.push({
      ProductId: 0,
      Amount: 0,
      ProductUnitId: null,
      Description: ''
    })
  }

  useEffect(() => {
    if (checkreport?.id) {
      navigate(`/${pageUrl[1]}/${pageUrl[2]}/edit/${checkreport?.id}`)
    } else if (checkreport) {
      dispatch(workplanActions.activityReportGetProjectInformation({ ProjectId: formik.values.ProjectId, ReportDate: formik.values.EditDate}));
    }
  }, [checkreport])

  useEffect(() => {
    if (checkinfo) {
      formik.setFieldValue(`ProjectLeader`, checkinfo?.projectLeader);
      formik.setFieldValue(`TechnicalOfficeChief`, checkinfo?.technicalOfficeChief);
      formik.setFieldValue(`SiteResponsibility`, checkinfo?.siteResponsibility);

      checkinfo['activityReportWorks'].map((item, index) => {
        for (const invoice in item) {
          const propValArr = invoice.charAt(0).toUpperCase() + invoice.slice(1);
          formik.setFieldValue(`ActivityReportWorks[${index}][${propValArr}]`, item[invoice]) 
        }

        formik.setFieldValue(`ActivityReportWorks[${index}]['blockItems']`, [{id: item['blockId'], name: item['blockName']}])
        formik.setFieldValue(`ActivityReportWorks[${index}]['plotItems']`, [{id: item['plotId'], name: item['plotName']}])
        formik.setFieldValue(`ActivityReportWorks[${index}]['IsDisabled']`, true)
      })
    } 
  }, [checkinfo])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.ACTIVITYREPORT.ADD' })}</PageTitle>
      <KTCard>        
        <KTCardBody>
          <FormikProvider value={formik}>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>            

              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.ACTIVITYREPORTTYPEID' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select 
                    data={ActivityReportType} 
                    required
                    {...formik.getFieldProps('ActivityReportTypeId')}
                    onChange={item => formik.setFieldValue('ActivityReportTypeId', Number(item?.value))}
                    textType={'intl'}
                  />
                  {formik.touched.ActivityReportTypeId && formik.errors.ActivityReportTypeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ActivityReportTypeId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.EDITDATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr 
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      required
                      {...formik.getFieldProps('EditDate')}
                      onChange={([date]) => formik.setFieldValue('EditDate', date)}
                    />
                    {formik.touched.EditDate && formik.errors.EditDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.EditDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.PROJECTID' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={projectLookup}
                    required
                    {...formik.getFieldProps('ProjectId')}
                    onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                  />
                  {formik.touched.ProjectId && formik.errors.ProjectId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ProjectId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {!checkreport && formik.values.ActivityReportTypeId && formik.values.EditDate && formik.values.ProjectId && <>
                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold required fs-6 mb-2'>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.ACTIVITYREPORTSTATEID' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <Select
                      data={ActivityReportState}
                      required
                      {...formik.getFieldProps('ActivityReportStateId')}
                      onChange={item => formik.setFieldValue('ActivityReportStateId', Number(item?.value))}
                      textType={'intl'}
                    />
                    {formik.touched.ActivityReportStateId && formik.errors.ActivityReportStateId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.ActivityReportStateId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.PROJECTLEADER' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('ProjectLeader')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.SITERESPONSIBILITY' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('SiteResponsibility')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.TECHNICALOFFICECHIEF' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('TechnicalOfficeChief')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.WEATHERCONDITION' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('WeatherCondition')}
                    />
                  </div>
                </div>

                <div className='row mb-10'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS' })}</label>
                  <div className='col-lg-9 mt-5'>
                    <FieldArray
                      name="ActivityReportWorks"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.ActivityReportWorks.map((item, index) => (
                            <div className='row mb-1' key={index}>
                              <div className='col-lg-2'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.CONSTRUCTIONSITE' })}</label>
                                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                                    <Select
                                      required
                                      data={constructionSiteLookup}
                                      name={`ActivityReportWorks[${index}].ConstructionId`}
                                      value={formik.values.ActivityReportWorks[index].ConstructionId}
                                      onChange={selectItem => onChangeConstructionSite(selectItem, index)}
                                      disabled={formik.values.ActivityReportWorks[index].IsDisabled}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='col-lg-2'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.BLOCK' })}</label>
                                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                                    <Select
                                      required
                                      data={item?.blockItems}
                                      name={`ActivityReportWorks[${index}].BlockId`}
                                      value={formik.values.ActivityReportWorks[index].BlockId}
                                      onChange={selectItem => onChangeBlock(selectItem, index)}
                                      disabled={formik.values.ActivityReportWorks[index].IsDisabled}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='col-lg-2'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.PLOTID' })}</label>
                                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                                    <Select
                                      required
                                      data={item?.plotItems}
                                      name={`ActivityReportWorks[${index}].PlotId`}
                                      value={formik.values.ActivityReportWorks[index].PlotId}
                                      onChange={item => formik.setFieldValue(`ActivityReportWorks[${index}].PlotId`, Number(item?.value))}
                                      disabled={formik.values.ActivityReportWorks[index].IsDisabled}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='col-lg-6'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.DESCRIPTION' })}</label>
                                  <div className='col-lg-11 fv-row fv-plugins-icon-container'>
                                    <textarea
                                      className='form-control form-control-solid mb-3 mb-lg-0'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      rows={1}
                                      name={`ActivityReportWorks[${index}].Description`}
                                      value={formik.values.ActivityReportWorks[index].Description}
                                      onChange={event => formik.setFieldValue(`ActivityReportWorks[${index}].Description`, event.target.value)}
                                    />
                                  </div>
                                  <div className='col-lg-1 fv-row fv-plugins-icon-container d-flex justify-content-end'>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-icon btn-active-color-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className='row'>
                            <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                              <button
                                type="button"
                                className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success"
                                onClick={() => onClickNewItem(arrayHelpers)}
                              >
                                <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                                {intl.formatMessage({ id: 'ADD_NEW_LINE' })}
                              </button>
                            </div>
                          </div>  
                        </>
                      )}
                    />
                  </div>
                </div>

                {timeKeepingTemp.length > 0 && <div className='row mb-6'>
                  <div className='col-lg-9'>
                    <label className='col-lg-9 fw-bold fs-6 mb-6'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.WORKMANTIMESHEET' })}</label>
                    <div className='row mb-6'>
                      {
                        timeKeepingTemp?.map((temp, index) => {
                          return (
                            <div className='col-lg-6' key={index}>
                              <label className='col-lg-9 fw-bold fs-6 mb-6'>{temp.timeKeepingTypeName}</label>
                              {
                                temp?.items?.map((item, key) => {
                                  return (
                                    <div className='row mb-6' key={key}>
                                      <label className='col-lg-9 fw-bold text-gray-700 fs-6 mb-2'>{item?.name}</label>
                                      <div className='col-lg-3 fv-row fv-plugins-icon-container'>
                                        <input
                                          type='number'
                                          className='form-control form-control-solid mb-3 mb-lg-0'
                                          autoComplete='off'
                                          defaultValue={0}
                                          onFocus={event => event.target.select()}
                                          onChange={(event) => onChangeTimeKeepingTemp(event.target.value, item.id)}
                                        />
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>}

                <div className='row mb-10'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL' })}</label>
                  <div className='col-lg-9 mt-5'>
                    <FieldArray
                      name="ActivityReportProducts"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.ActivityReportProducts.map((item, index) => (
                            <div className='row mb-1' key={index}>
                              <div className='col-lg-3'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.PRODUCTID' })}</label>
                                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                                    <Select
                                      required
                                      data={productLookup}
                                      name={`ActivityReportProducts[${index}].ProductId`}
                                      value={formik.values.ActivityReportProducts[index].ProductId}
                                      onChange={selected => onChangeProduct(selected, index)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='col-lg-2'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.PRODUCTUNITID' })}</label>
                                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                                    <Select
                                      disabled
                                      name={`ActivityReportProducts[${index}].ProductUnitId`}
                                      value={formik.values.ActivityReportProducts[index].ProductUnitId}
                                      data={ProductUnit}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='col-lg-3'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.AMOUNT' })}</label>
                                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                                    <input
                                      type='number'
                                      className='form-control form-control-solid mb-3 mb-lg-0'
                                      autoComplete='off'
                                      name={`ActivityReportProducts[${index}].Amount`}
                                      value={formik.values.ActivityReportProducts[index].Amount}
                                      onChange={event => formik.setFieldValue(`ActivityReportProducts[${index}].Amount`, Number(event.target.value))}
                                      onFocus={event => event.target.select()}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='col-lg-4'>
                                <div className='row mb-6'>
                                  <label className='col-lg-12 fw-bold text-gray-700 fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.DESCRIPTION' })}</label>
                                  <div className='col-lg-11 fv-row fv-plugins-icon-container'>
                                    <textarea
                                      className='form-control form-control-solid mb-3 mb-lg-0'
                                      autoComplete='off'
                                      disabled={formik.isSubmitting}
                                      rows={1}
                                      name={`ActivityReportProducts[${index}].Description`}
                                      value={formik.values.ActivityReportProducts[index].Description}
                                      onChange={event => formik.setFieldValue(`ActivityReportProducts[${index}].Description`, event.target.value)}
                                    />
                                  </div>
                                  <div className='col-lg-1 fv-row fv-plugins-icon-container d-flex justify-content-end'>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-icon btn-active-color-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className='row'>
                            <div className='col-lg-9 fv-row fv-plugins-icon-container'>
                              <button
                                type="button"
                                className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success"
                                onClick={() => onClickNewProduct(arrayHelpers)}
                              >
                                <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                                {intl.formatMessage({ id: 'ADD_NEW_LINE' })}
                              </button>
                            </div>
                          </div>  
                        </>
                      )}
                    />
                  </div>
                </div>
                
                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.MACHINERYEQUIPMENT' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <textarea
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={4}
                      {...formik.getFieldProps('MachineryEquipment')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.ISRISKPRECAUTION' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input 
                        className="form-check-input h-30px w-50px" 
                        type="checkbox" 
                        checked={formik.values.IsRiskPrecaution}
                        {...formik.getFieldProps('IsRiskPrecaution')}
                      />
                    </div>
                  </div>
                </div>  

                {formik.values.IsRiskPrecaution && <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACTIVITYREPORT.CREATE.RISKPRECAUTION' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <textarea
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={4}
                      {...formik.getFieldProps('RiskPrecaution')}
                    />
                  </div>
                </div>}

                <div className='row pt-15'>
                  <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                    <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                      <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                      {(formik.isSubmitting) && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({ id: 'PLEASE' })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </>}
            </form>
          </FormikProvider>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Create};
