export const Years = [
  {
    id: 2023,
    name: '2023'
  },
  {
    id: 2024,
    name: '2024'
  },
  {
    id: 2025,
    name: '2025'
  },
  {
    id: 2026,
    name: '2026'
  },
  {
    id: 2027,
    name: '2027'
  },
  {
    id: 2028,
    name: '2028'
  },
  {
    id: 2029,
    name: '2029'
  },
  {
    id: 2030,
    name: '2030'
  },
  {
    id: 2031,
    name: '2031'
  },
  {
    id: 2032,
    name: '2032'
  },
  {
    id: 2033,
    name: '2033'
  }
]
