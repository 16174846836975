import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { MetronicI18nProvider } from './i18n/Metronici18n'
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/sass/style.scss'
import './assets/sass/plugins.scss'
import './assets/sass/style.react.scss'

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS
    ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
    ||
    event.eventType === EventType.SSO_SILENT_SUCCESS) && event.payload.account
  ) {
    localStorage.setItem('accessToken', event.payload.accessToken);
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});


msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE || event.eventType === EventType.SSO_SILENT_FAILURE) {
    console.error("msalInstance.addEventCallback v1" + event.eventType);
    localStorage.removeItem("accessToken");
    msalInstance.logoutRedirect();
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
    <MetronicI18nProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MetronicI18nProvider>
  </MsalProvider>
);

reportWebVitals();
