/* eslint-disable react/jsx-no-target-blank */
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import { toAbsoluteUrl} from '../../helpers'
import {AsideMenu} from './AsideMenu'
import { AsideUserMenu } from './AsideUserMenu'

const AsideDefault = ({opened}) => {
  const size = useWindowSize();
  
  return (
    <aside
      id='kt_aside'
      className={clsx('aside py-9', {'drawer drawer-start drawer-on': size.width < 990 && opened})}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      <div className='aside-logo flex-column-auto px-9 mb-9' id='kt_aside_logo'>
        <Link to='/dashboard'>
          <img
            alt='Logo'
            className='h-25px logo theme-light-show'
            src={toAbsoluteUrl('/images/logo.svg')}
          />
        </Link>
      </div>

      <div id='kt_aside_menu' className='aside-menu flex-column-fluid ps-5 pe-3 mb-9 h-50'>
        <AsideMenu />
      </div>

      <div className='aside-footer flex-column-auto px-9' id='kt_aside_footer'>
        <AsideUserMenu />
      </div>
    </aside>
  )
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export {AsideDefault}
