import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { projectActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl();
  const { realEstate, loading } = useSelector(state => state.project);
  const { projectLookup, EstateType, EstateState } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState();

  useEffect(() => {
    if (params) dispatch(projectActions.realEstateGetAll(params));
  }, [params]);

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('EstateType'));
    dispatch(lookupActions.basicLookup('EstateState'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REALESTATE.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REALESTATE.SEARCH.BASESALEPRICE' })} className='min-w-125px' />,
      accessor: 'baseSalePrice',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REALESTATE.SEARCH.PROJECTNAME' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REALESTATE.SEARCH.ESTATETYPENAME' })} className='min-w-125px' />,
      accessor: 'estateTypeName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REALESTATE.SEARCH.GROSSAREA' })} className='min-w-125px' />,
      accessor: 'grossArea',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REALESTATE.SEARCH.NETAREA' })} className='min-w-125px' />,
      accessor: 'netArea',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REALESTATE.SEARCH.ESTATESTATENAME' })} className='min-w-125px' />,
      accessor: 'estateStateName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    }
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(projectActions.realEstateExcel(params, intl.formatMessage({ id: 'BC.ESTATES' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'REALESTATE.SEARCH.PROJECTNAME',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'REALESTATE.SEARCH.ESTATETYPENAME',
          data: EstateType,
          name: 'estateTypeId'
        },
        {
          title: 'REALESTATE.SEARCH.ESTATESTATENAME',
          data: EstateState,
          name: 'estateStateId'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.ESTATES' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'ESTATES'}
        />
        <KTCardBody>
          {!realEstate && <ListLoading />}
          {realEstate && <Table
            items={realEstate}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
