/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {KTCard} from '../Card/KTCard'
import {KTCardBody} from '../Card/KTCardBody'
import {KTCardHeader} from '../Card/KTCardHeader'
import Dropdown from 'react-bootstrap/Dropdown';
import {KTSVG, toAbsoluteUrl} from '../../helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {commonActions} from '../../redux/actions'
import {DeleteModal} from '..'
import { ListLoading } from '../Table/ListLoading'

const FileManager = ({collapse, SourceId, SourceTypeId}) => {
  const intl = useIntl()
  const [deleteFileId, setDeleteFileId] = useState()
  const [deleteFolderId, setDeleteFolderId] = useState()
  const [showFileModal, setShowFileModal] = useState(false)
  const [showFolderModal, setShowFolderModal] = useState(false)
  const [rootFileFolderId, setRootFileFolderId] = useState([])
  const [rootFileFolderNowId, setRootFileFolderNowId] = useState()
  const [newFolder, setNewFolder] = useState(false)
  const [newFile, setNewFile] = useState(false)
  const [reNameInput, setReNameInput] = useState()
  const [reNameInputShow, setReNameInputShow] = useState(null)

  const {getFileFolderId, getAllWithFiles, file, fileFolder, isDeleted, isRedirect, error, loading} = useSelector((state) => state.common)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(commonActions.fileFolderGetId({SourceId, SourceTypeId}))
  }, [SourceId, SourceTypeId])

  useEffect(() => {
    if (getFileFolderId) {
      setRootFileFolderNowId(getFileFolderId?.rootFileFolderId);
      setRootFileFolderId([getFileFolderId?.rootFileFolderId]);
    }
  }, [getFileFolderId])

  useEffect(() => {
    if (rootFileFolderNowId && !loading) dispatch(commonActions.fileFolderGetAll({SourceId, SourceTypeId, RootFileFolderId: rootFileFolderNowId}))
  }, [file, fileFolder, isDeleted, isRedirect, rootFileFolderNowId])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL'})),
  })

  const [formValues] = useState({
    SourceId,
    SourceTypeId,
    RootFileFolderId: '',
    Name: intl.formatMessage({id: 'FILE.NEWFOLDER'}),
    FormFile: null,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      if (newFolder) dispatch(commonActions.fileFolderCreate({SourceId, SourceTypeId, RootFileFolderId: rootFileFolderNowId, Name: values.Name}))
      if (newFile) dispatch(commonActions.fileCreate({SourceId: rootFileFolderNowId, SourceTypeId: 19, FormFile: values.FormFile}))
      if (newFile && !values.FormFile) return false
      setSubmitting(true)
      setNewFolder(false)
      setNewFile(false)
      resetForm()
    },
  })

  const handleClickDeleteFile = (id) => {
    setDeleteFileId(id)
    setShowFileModal(true)
  }

  const handleDeleteFile = () => {
    dispatch(commonActions.fileDelete(deleteFileId))
    setShowFileModal(false)
  }

  const handleClickDeleteFolder = (id) => {
    setDeleteFolderId(id)
    setShowFolderModal(true)
  }

  const handleDeleteFolder = () => {
    dispatch(commonActions.fileFolderDelete({Id: deleteFolderId, SourceId, SourceTypeId}))
    setShowFolderModal(false)
  }

  const onClickFolder = (id, rootId) => {
    setRootFileFolderId([...rootFileFolderId, rootId])
    setRootFileFolderNowId(id)
    setNewFolder(false)
    setNewFile(false)
    dispatch(commonActions.fileFolderGetAll({SourceId, SourceTypeId, RootFileFolderId: id}))
  }

  const removeValue = (value) => {
    setRootFileFolderId(prevState => {
      const newState = [...prevState];

      const index = newState.lastIndexOf(value);
      if (index !== -1) {
        newState.splice(index, 1);
      }

      return newState;
    });
  };

  const onClickUp = (rootId) => {
    removeValue(rootId);
    setRootFileFolderNowId(rootId)
    setNewFolder(false)
    setNewFile(false)
    dispatch(commonActions.fileFolderGetAll({SourceId, SourceTypeId, RootFileFolderId: rootId}))
  }

  const onSubmitReName = () => {
    dispatch(commonActions.fileFolderRename({SourceId, SourceTypeId, Id: reNameInput?.id, Name: reNameInput?.value}))
    setReNameInputShow(null)
    setNewFolder(false)
    setNewFile(false)
  }

  return (
    <>
      <KTCard className='mb-5 mt-5'>
        <KTCardHeader
          title={intl.formatMessage({id: 'FILE.TITLE'})}
          collapsed={collapse}
        >
          <div className="card-toolbar">
            <div className="d-flex justify-content-end">
              {rootFileFolderId && rootFileFolderId?.length > 1 && <button className="btn btn-icon btn-light-primary me-3" onClick={() => onClickUp(rootFileFolderId[rootFileFolderId.length - 1])}>
                <i className="fa fa-level-up" aria-hidden="true"></i>
              </button>}
              
              <button type="button" className="btn btn-flex btn-light-primary me-3" onClick={() => {
                setNewFolder(!newFolder)
                setNewFile(false)
              }}>
                <KTSVG path='/images/icons/duotune/files/fil013.svg' className='svg-icon-2' /> {intl.formatMessage({ id: 'FILE.NEWFOLDER' })}
              </button>

              <button type="button" className="btn btn-flex btn-primary" onClick={() => {
                setNewFile(!newFile)
                setNewFolder(false)
              }}>
                <i className="fa fa-cloud-upload" aria-hidden="true"></i> {intl.formatMessage({ id: 'FILE.NEWFILE' })}
              </button>
            </div>
          </div>
        </KTCardHeader>

        <KTCardBody className='p-5' collapseble isShow={collapse}>
          <table id="kt_file_manager_list" data-kt-filemanager-table="folders" className="table align-middle table-row-dashed fs-6 gy-5">
            <thead>
              <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-250px">{intl.formatMessage({ id: 'FILE.NAME' })}</th>
                <th className="min-w-10px">{intl.formatMessage({ id: 'FILE.SIZE' })}</th>
                <th className="min-w-125px">{intl.formatMessage({ id: 'FILE.LASTMODIFIEDINFO' })}</th>
                <th className="w-125px"></th>
              </tr>
            </thead>

            <tbody className="fw-semibold text-gray-600">

              { loading && <td colSpan="4" className='p-5'><ListLoading /></td> }
              
              {(newFolder || newFile) && <tr>
                <td colSpan="4" className="fv-row fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container">
                  <form className='form' onSubmit={formik.handleSubmit} noValidate>
                    <div className="d-flex">
                      {newFolder && <>
                        <span className="svg-icon svg-icon-2x svg-icon-primary me-4 mt-3">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor" />
                          </svg>
                        </span>
                        <div className='me-3'>
                          <input 
                            className="form-control" 
                            type='text'
                            required
                            autoComplete='off'
                            placeholder={intl.formatMessage({ id: 'FILE.NEWFOLDER.INPUT' })}
                            disabled={formik.isSubmitting}
                            onFocus={event => event.target.select()}
                            {...formik.getFieldProps('Name')}
                          />
                          {formik.touched.Name && formik.errors.Name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.Name}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>}

                      {newFile && <div className='mw-250px me-3'>
                        <label htmlFor='imageFile' className='dropzone dz-clickable d-flex w-100'>
                          <input
                            id='imageFile'
                            className='position-absolute z-index-2 opacity-0'
                            style={{width: 0, height: 0}}
                            type='file'
                            accept='image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                            name={'FormFile'}
                            onChange={(event) => formik.setFieldValue('FormFile', event.currentTarget.files[0])}
                          />
                          <div className='dz-message needsclick'>
                            <KTSVG
                              path='/images/icons/duotune/communication/com008.svg'
                              className='svg-icon-1 position-relative z-index-1'
                            />
                            <div className='ms-4'>
                              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                                {formik.values?.FormFile?.name
                                  ? formik.values?.FormFile?.name
                                  : intl.formatMessage({id: 'BUTTON.IMAGE'})}
                              </h3>
                            </div>
                          </div>
                        </label>
                      </div>}

                      <button className="btn btn-icon btn-light-primary me-3">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </button>

                      <button className="btn btn-icon btn-light-danger" onClick={() => setNewFolder(!newFolder)}>
                        <i className="fa fa-close" aria-hidden="true"></i>
                      </button>
                    </div>
                  </form>
                </td>
              </tr>}

              {
                getAllWithFiles?.map((item, index) => {
                  if (item?.sourceTypeId == 19) {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="currentColor" />
                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
                              </svg>
                            </span>
                            <a href={item?.fileUrl} target='_blank' rel='noreferrer' className="text-gray-800 text-hover-primary">
                              {item?.name}
                            </a>
                          </div>
                        </td>
                        <td>{item?.contentLengthName}</td>
                        <td>{intl.formatDate(new Date(item?.createdDate), { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })} - { item?.lastModifiedBy }</td>
                        <td className="text-end">
                          <div className="d-flex justify-content-end">

                            <div className="ms-2">
                              <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                                <Dropdown.Toggle 
                                  variant='light'
                                  size="sm"
                                  className='iconless btn-sm btn-icon btn-light btn-active-light-primary'
                                >
                                  <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                                  <Dropdown.Item href={item?.fileUrl} target='_blank' className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DOWNLOAD' })}</div></Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleClickDeleteFile(item?.id)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  }

                  return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                              <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor" />
                            </svg>
                          </span>
                          
                          {reNameInputShow != index && <div className="text-gray-800 text-hover-primary" role='button' onClick={() => onClickFolder(item?.id, item?.rootFileFolderId)}>
                            {item?.name}
                          </div>}

                          {reNameInputShow == index && <div className="d-flex">
                            <div className='mw-250px me-3'>
                              <input 
                                className="form-control" 
                                type='text'
                                required
                                autoComplete='off'
                                placeholder={intl.formatMessage({ id: 'FILE.NEWFOLDER.INPUT' })}
                                disabled={formik.isSubmitting}
                                onFocus={event => event.target.select()}
                                defaultValue={item?.name}
                                onChange={(event) => setReNameInput({id: item?.id, value: event.target.value})}
                              />
                            </div>

                            <button className="btn btn-icon btn-light-primary me-3" onClick={onSubmitReName}>
                              <i className="fa fa-check" aria-hidden="true"></i>
                            </button>

                            <button className="btn btn-icon btn-light-danger" onClick={() => setNewFolder(!newFolder)}>
                              <i className="fa fa-close" aria-hidden="true"></i>
                            </button>
                          </div>}
                        </div>
                      </td>
                      <td>-</td>
                      <td>{intl.formatDate(new Date(item?.createdDate), { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })} - {item?.lastModifiedBy}</td>
                      <td className="text-end">
                        <div className="d-flex justify-content-end">
                          <div className="ms-2">
                            <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                              <Dropdown.Toggle 
                                variant='light'
                                size="sm"
                                className='iconless btn-sm btn-icon btn-light btn-active-light-primary'
                              >
                                <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                                <Dropdown.Item className="menu-item px-3" onClick={() => setReNameInputShow(index)}><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'FILE.RENAME' })}</div></Dropdown.Item>
                                <Dropdown.Item onClick={() => handleClickDeleteFolder(item?.id)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }

              {
                getAllWithFiles?.length < 1 && <tr className="odd">
                  <td valign="top" colSpan="5" className="dataTables_empty">
                    <div className="d-flex flex-column flex-center">
                      <img src={toAbsoluteUrl('/images/empty.png')} alt='empty' className='mw-400px' />
                      <div className="fs-1 fw-bolder text-dark mb-4">{intl.formatMessage({ id: 'FILE.EMPTYFOLDER' })}</div>
                      <div className="fs-6">{intl.formatMessage({ id: 'FILE.EMPTYFOLDER.TEXT' })}</div>
                    </div>
                  </td>
                </tr>
              }
              
            </tbody>
          </table>

        </KTCardBody>
      </KTCard>

      <DeleteModal
        onClick={handleDeleteFile}
        onOpen={showFileModal}
        onClose={() => setShowFileModal(false)}
        error={error}
      />

      <DeleteModal
        onClick={handleDeleteFolder}
        onOpen={showFolderModal}
        onClose={() => setShowFolderModal(false)}
        error={error}
      />
    </>
  )
}

FileManager.propTypes = {
  collapse: PropTypes.bool,
  SourceId: PropTypes.number,
  SourceTypeId: PropTypes.number,
}

FileManager.defaultProps = {
  collapse: true,
}

export {FileManager}
