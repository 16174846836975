import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../../core';
import { KTSVG, SalePaymentStatus} from '../../../helpers'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, Select, ChartColumn, ChartDonut } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, categoryActions, reportingActions } from '../../../redux/actions';

function Report() {
  const [categoryCategories, setCategoryCategories] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [projectProjects, setProjectProjects] = useState([])
  const [projectData, setProjectData] = useState([])
  const [monthCategories, setMonthCategories] = useState([])
  const [monthData, setMonthData] = useState([])
  const [statusCategories, setStatusCategories] = useState([])
  const [statusData, setStatusData] = useState([])
  const [dateCategories, setDateCategories] = useState([])
  const [dateData, setDateData] = useState([])
  const [dateParent, setDateParent] = useState([])
  
  const { category } = useSelector((state) => state.category)
  const { projectLookup } = useSelector(state => state.lookup);
  const { loading, saleinvoiceCategory, saleinvoiceProject, saleinvoiceDate, saleinvoiceMonth, saleinvoiceStatus } = useSelector(state => state.reporting);

  const intl = useIntl()
  const dispatch = useDispatch();

  const validSchema = Yup.object().shape({})

  const firsDateMonth = new Date().setDate(1)

  const [formValues] = useState({
    SaleCategoryId: '',
    PaymentStatusId: '',
    ProjectId: '',
    StartDate: new Date(firsDateMonth),
    EndDate: new Date(),
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(reportingActions.saleInvoiceChartCategoryGetAll(values));
      dispatch(reportingActions.saleInvoiceChartProjectGetAll(values));
      dispatch(reportingActions.saleInvoiceChartDateGetAll(values));
      dispatch(reportingActions.saleInvoiceChartMonthGetAll(values));
      dispatch(reportingActions.saleInvoiceChartStatusGetAll(values));
    },
  })

  useEffect(() => {
    dispatch(categoryActions.categoryGetAll({ SourceTypeId: 7 }))
    dispatch(lookupActions.projectLookup());
  }, [])

  useEffect(() => {
    if (saleinvoiceCategory) {
      const saleinvoiceCategoryCategories = []
      const saleinvoiceCategoryData = []
    
      saleinvoiceCategory?.map(item => {
        saleinvoiceCategoryCategories.push(item.title)
        saleinvoiceCategoryData.push(item.dataCount)
      })

      setCategoryCategories(saleinvoiceCategoryCategories)
      setCategoryData(saleinvoiceCategoryData)
    }

    if (saleinvoiceProject) {
      const saleinvoiceProjectProjects = []
      const saleinvoiceProjectData = []

      saleinvoiceProject?.map(item => {
        saleinvoiceProjectProjects.push(item.title)
        saleinvoiceProjectData.push(item.dataCount)
      })

      setProjectProjects(saleinvoiceProjectProjects)
      setProjectData(saleinvoiceProjectData)
    }
  
    if (saleinvoiceMonth) {
      const saleinvoiceMonthCategories = []
      const saleinvoiceMonthData = []
    
      saleinvoiceMonth?.map(item => {
        saleinvoiceMonthCategories.push(item.title)
        saleinvoiceMonthData.push(item.dataCount)
      })

      setMonthCategories(saleinvoiceMonthCategories)
      setMonthData(saleinvoiceMonthData)
    }
  
    if (saleinvoiceStatus) {
      const saleinvoiceStatusCategories = []
      const saleinvoiceStatusData = []

      saleinvoiceStatus?.map(item => {
        saleinvoiceStatusCategories.push(item.title)
        saleinvoiceStatusData.push(item.dataCount)
      })

      setStatusCategories(saleinvoiceStatusCategories)
      setStatusData(saleinvoiceStatusData)
    }
  
    if (saleinvoiceDate) {
      const saleinvoiceDateCategories = []
      const saleinvoiceDateData = []
      const saleinvoiceDateParent = []
    
      saleinvoiceDate?.map(item => {
        saleinvoiceDateCategories.push(item.title)
        saleinvoiceDateData.push(item.dataCount)
        saleinvoiceDateParent.push(item.parentName)
      })

      setDateCategories(saleinvoiceDateCategories)
      setDateData(saleinvoiceDateData)
      setDateParent(saleinvoiceDateParent)
    }

  }, [saleinvoiceCategory, saleinvoiceProject, saleinvoiceDate, saleinvoiceMonth, saleinvoiceStatus])

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.REPORTING.SALEINVOICE' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <KTCard className='mb-5'>
            <KTCardBody className='p-9'>
              <form className='form' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REPORT.SALEINVOICE.STARTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('StartDate')}
                            onChange={([date]) => formik.setFieldValue('StartDate', date)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REPORT.SALEINVOICE.ENDDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('EndDate')}
                            onChange={([date]) => formik.setFieldValue('EndDate', date)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REPORT.SALEINVOICE.SALECATEGORY' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={category}
                          {...formik.getFieldProps('SaleCategoryId')}
                          onChange={item => formik.setFieldValue('SaleCategoryId', Number(item?.value))}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REPORT.SALEINVOICE.PAYMENTSTATUS' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={SalePaymentStatus}
                          {...formik.getFieldProps('PaymentStatusId')}
                          onChange={item => formik.setFieldValue('PaymentStatusId', Number(item?.value))}
                          textType={'intl'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REPORT.SALEINVOICE.PROJECT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={projectLookup}
                          {...formik.getFieldProps('ProjectId')}
                          onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row pt-5'>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                      <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SEARCH' })}</span>
                      {(formik.isSubmitting) && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({ id: 'PLEASE' })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      <div className='row gy-5 g-xl-10'>
        {statusData && statusData?.length > 0 && <div className='col-xl-6'>
          <ChartDonut
            chartHeight='400px'
            categories={statusCategories}
            data={statusData}
            title={intl.formatMessage({ id: 'REPORT.SALEINVOICE.STATUS' })}
            tooltip={{
              y: {
                formatter: (val) => {
                  return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                }
              }
            }}
          />
        </div>}

        {monthData && monthData?.length > 0 && <div className='col-xl-6'>
          <ChartColumn
            chartHeight='400px'
            categories={monthCategories}
            data={monthData}
            title={intl.formatMessage({ id: 'REPORT.SALEINVOICE.MONTH' })}
            tooltipTitle={intl.formatMessage({ id: 'REPORT.SALEREPORT.CATEGORYTOOLTIP' })}
            tooltip={{
              y: {
                formatter: (val) => {
                  return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                }
              }
            }}
          />
        </div>}

        {categoryData && categoryData?.length > 0 && <div className='col-xl-12'>
          <ChartColumn
            chartHeight='400px'
            categories={categoryCategories}
            data={categoryData}
            title={intl.formatMessage({ id: 'REPORT.SALEINVOICE.CATEGORY' })}
            tooltipTitle={intl.formatMessage({ id: 'REPORT.SALEREPORT.CATEGORYTOOLTIP' })}
            tooltip={{ 
              y: {
                formatter: (val) => {
                  return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                }
              }
            }}
          />
        </div>}

        {projectData && projectData?.length > 0 && <div className='col-xl-12'>
          <ChartColumn
            chartHeight='400px'
            categories={projectProjects}
            data={projectData}
            title={intl.formatMessage({ id: 'REPORT.SALEINVOICE.PROJECT' })}
            tooltipTitle={intl.formatMessage({ id: 'REPORT.SALEREPORT.PROJECTTOOLTIP' })}
            tooltip={{
              y: {
                formatter: (val) => {
                  return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                }
              }
            }}
          />
        </div>}

        {dateData && dateData?.length > 0 && <div className='col-xl-12'>
          <ChartColumn
            chartHeight='400px'
            categories={dateCategories}
            data={dateData}
            title={intl.formatMessage({ id: 'REPORT.SALEINVOICE.DATE' })}
            tooltip={{ 
              y: {
                formatter: (val) => {
                  return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                },
                title: {
                  formatter: (a, data) => {
                    return dateParent[data.dataPointIndex]
                  },
                },
              }
            }}
          />
        </div>}
      </div>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Report};
