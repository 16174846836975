import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { bankPaymentOrderActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { bankPaymentOrder, loading } = useSelector(state => state.bankPaymentOrder);
  const { PaymentRequestState } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) dispatch(bankPaymentOrderActions.bankPaymentOrderGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.basicLookup('PaymentRequestState'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKPAYMENTORDER.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKPAYMENTORDER.SEARCH.PAYMENTDATE' })} className='min-w-125px' />,
      accessor: 'paymentDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKPAYMENTORDER.SEARCH.BANKPAYMENTORDERSTATENAME' })} className='min-w-125px' />,
      accessor: 'bankPaymentOrderStateName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKPAYMENTORDER.SEARCH.REQUESTEDPAYMENT' })} className='min-w-125px' />,
      accessor: 'requestedPayment',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKPAYMENTORDER.SEARCH.APPROVEDPAYMENT' })} className='min-w-125px' />,
      accessor: 'approvedPayment',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
  ];

  const actionButtons = [
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'BANKPAYMENTORDER.SEARCH.BEGINDATE',
          type: 'date',
          name: 'beginDate'
        },
        {
          title: 'BANKPAYMENTORDER.SEARCH.ENDDATE',
          type: 'date',
          name: 'endDate'
        },
        {
          title: 'BANKPAYMENTORDER.SEARCH.BANKPAYMENTORDERSTATENAME',
          data: PaymentRequestState,
          name: 'bankPaymentOrderStateId'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.CASH.BANKPAYMENTORDER' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages='BANKPAYMENTORDER'
        />
        <KTCardBody>
          {!bankPaymentOrder && <ListLoading />}
          {bankPaymentOrder && <Table
            items={bankPaymentOrder}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
