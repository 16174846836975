import React, {useState} from 'react'
import PropTypes from 'prop-types';
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch } from 'react-redux';
import { bankPaymentOrderActions } from '../../redux/actions';
import { Select} from '..';

const BankPaymentOrderSendToConfirmation = ({BankPaymentOrderId, BankPaymentOrderUsers}) => {
  const intl = useIntl()

	const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    ApprovedUserId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: BankPaymentOrderId,
    ApprovedUserId: ''
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(bankPaymentOrderActions.bankPaymentOrderSendToConfirmation(values));
      resetForm()
    },
  })

  return (
    <>
      <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-6'>
          <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.SENDTOCONFIRMATION.APPROVEUSERLIST' })}</label>
          <div className='col-lg-12 fv-row fv-plugins-icon-container'>
            <Select
              data={BankPaymentOrderUsers} 
              required
              {...formik.getFieldProps('ApprovedUserId')}
              onChange={item => formik.setFieldValue('ApprovedUserId', Number(item?.value))}
            />
          </div>
        </div>

        <div className='text-center'>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                {intl.formatMessage({ id: 'PLEASE' })}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

BankPaymentOrderSendToConfirmation.propTypes = {
	collapse: PropTypes.bool
};

export {BankPaymentOrderSendToConfirmation}
