/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams, Link } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, Timeline, ImageUpload } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { workplanActions } from '../../../redux/actions';
import { SourceType, KTSVG } from '../../../helpers';

function Detail() {
  const { id } = useParams();
  const intl = useIntl()
  const { detail, loading, releaseReport } = useSelector(state => state.workplan);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const [timeKeepingTemp, setTimeKeepingTemp] = useState([])
  const [totalEmployeeCount, setTotalEmployeeCount] = useState([])

  useEffect(() => {
    dispatch(workplanActions.activityReportGetId(id));
  }, [id, releaseReport])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.ACTIVITYREPORT' }),
      path: '/workplan/activity-report',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (detail?.activityReportTimeKeepings) {
      const groupedData = {};
      let tempTotalCount = 0;

      detail?.activityReportTimeKeepings?.forEach(item => {
        const { timeKeepingTemplateTypeId, timeKeepingTemplateTypeName, employeeCount } = item;

        tempTotalCount += employeeCount;
        if (!groupedData[timeKeepingTemplateTypeId]) {
          groupedData[timeKeepingTemplateTypeId] = {
            timeKeepingTemplateTypeId,
            timeKeepingTemplateTypeName,
            items: [],
          };
        }

        groupedData[timeKeepingTemplateTypeId].items.push(item);
      })
      setTotalEmployeeCount(tempTotalCount);
      setTimeKeepingTemp(Object.values(groupedData))
    } else {
      setTimeKeepingTemp([])
    }
  }, [detail?.activityReportTimeKeepings])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.ACTIVITYREPORT.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">
                            {intl.formatDate(new Date(detail?.editDate), { year: 'numeric', month: 'long', day: 'numeric' })} {detail?.projectName} {detail?.activityReportTypeName} {intl.formatMessage({ id: 'ACTIVITYREPORT.GET.TITLE' })}
                          </div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.projectLeader && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Staff.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectLeader}
                            </div>
                          </>}

                          {detail?.siteResponsibility && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Staff.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.siteResponsibility}
                            </div>
                          </>}

                          {detail?.technicalOfficeChief && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Staff.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.technicalOfficeChief}
                            </div>
                          </>}

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <button className={`btn btn-sm btn-light-primary d-flex`} onClick={() => dispatch(workplanActions.activityReportExcelGetId(id, `${intl.formatDate(new Date(detail?.editDate), { year: 'numeric', month: 'long', day: 'numeric' })}-${detail?.projectName}-${detail?.activityReportTypeName}-${intl.formatMessage({ id: 'ACTIVITYREPORT.GET.TITLE' })}`))}>
                          <KTSVG path='/images/icons/duotune/arrows/arr044.svg' className='svg-icon-2' />{intl.formatMessage({id: 'BUTTON.EXCEL' })}
                        </button>
                        {detail?.activityReportStateId == 1 && <>
                          <button className={`btn btn-sm btn-light-info d-flex`} onClick={() => dispatch(workplanActions.activityReportRelease({id}))}>
                            <KTSVG path='/images/icons/duotune/ecommerce/ecm008.svg' className='svg-icon-2' />{intl.formatMessage({id: 'BUTTON.RELEASE.REPORT' })}
                          </button>

                          <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                            <KTSVG
                              path='/images/icons/duotune/general/gen055.svg'
                              className='svg-icon-2'
                            />
                            {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                          </Link>
                        </>}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.ACTIVITYREPORTSTATEID' })}</div>
                          <div className="fw-bold fs-4">{detail?.activityReportStateName}</div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ACTIVITYREPORT.GET.WEATHERCONDITION' })}</div>
                          <div className="fw-bold fs-4">{detail?.weatherCondition}</div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ACTIVITYREPORT.GET.TOTALWORKMAN' })}</div>
                          <div className="fw-bold fs-4">{totalEmployeeCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {detail?.machineryEquipment && <>
                  <div className='row p-5'>
                    <div className="col-lg-8 fv-row"><span className="fw-bold fs-6" dangerouslySetInnerHTML={{ __html: detail?.machineryEquipment?.replace(/\n/g, '<br />') }} /></div>
                  </div>
                </>}

                {detail?.isRiskPrecaution && <>
                  <div className='row p-5'>
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                      <span className="fw-bold fs-6 d-flex text-gray-700" dangerouslySetInnerHTML={{ __html: detail?.riskPrecaution?.replace(/\n/g, '<br />') }} />
                    </div>
                  </div>
                </>}
              </KTCardBody>
            </KTCard>

            <ImageUpload SourceId={Number(id)} SourceTypeId={SourceType.ActivityReport} />

            {detail?.activityReportWorks && detail?.activityReportWorks?.length > 0 && <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-5">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <div className="text-gray-900 fs-2 fw-bold me-1">
                        {intl.formatMessage({ id: 'ACTIVITYREPORT.GET.WORKPROGRESS' })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-stack">
                  <div className="d-flex flex-column flex-grow-1 pe-8">
                    <div className="d-flex flex-wrap">
                      <div className='col-lg-12 pt-5 border-top'>
                        <div className="row mb-12">
                          <div className='table-responsive col-lg-12'>
                            <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                              <thead>
                                <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.CONSTRUCTIONSITE' })}</th>
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.BLOCK' })}</th>
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.PLOTID' })}</th>
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.WORKPROGRESS.DESCRIPTION' })}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {detail?.activityReportWorks?.map((item, index) => <tr key={index}>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.constructionName}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.blockName}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.plotName}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.description}
                                    </div>
                                  </td>
                                </tr>)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </KTCardBody>
            </KTCard>}

            {timeKeepingTemp && timeKeepingTemp?.length > 0 && <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-5">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <div className="text-gray-900 fs-2 fw-bold me-1">
                        {intl.formatMessage({ id: 'ACTIVITYREPORT.GET.WORKMANTIMESHEET' })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-stack">
                  <div className="d-flex flex-column flex-grow-1 pe-8">
                    <div className="d-flex flex-wrap">
                      <div className='col-lg-12 pt-5 border-top'>
                        <div className="row mb-7">
                          {
                            timeKeepingTemp?.map((temp, key) => {
                              let totalCount = 0;
                              return (
                                <div className='table-responsive col-lg-6' key={key}>
                                  <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                                    <thead>
                                      <tr colSpan={2} className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                        <th>{temp.timeKeepingTemplateTypeName}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {temp?.items?.map((item, index) => {
                                        totalCount = totalCount + item.employeeCount
                                        return (
                                          <tr key={index}>
                                            <td className='p-5'>
                                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                                {item?.timeKeepingTemplateName}
                                              </div>
                                            </td>
                                            <td className='p-5'>
                                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                                {item?.employeeCount}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                    <tfoot>
                                      <tr className="align-middle fw-bold text-gray-700">
                                        <th className="fs-4 p-5 text-end">
                                          {intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.WORKMANTIMESHEET.TOTAL' })}
                                        </th>
                                        <th className="fs-4 text-nowrap p-5">{totalCount}</th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>}

            {detail?.activityReportProducts && detail?.activityReportProducts?.length > 0 && <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-5">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <div className="text-gray-900 fs-2 fw-bold me-1">
                        {intl.formatMessage({ id: 'ACTIVITYREPORT.GET.INCOMINGMATERIAL' })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-stack">
                  <div className="d-flex flex-column flex-grow-1 pe-8">
                    <div className="d-flex flex-wrap">
                      <div className='col-lg-12 pt-5 border-top'>
                        <div className="row mb-7">
                          <label className="col-lg-12 fw-bold"></label>
                          <div className='table-responsive col-lg-12'>
                            <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                              <thead>
                                <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.PRODUCTID' })}</th>
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.AMOUNT' })}</th>
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.PRODUCTUNITID' })}</th>
                                  <th>{intl.formatMessage({ id: 'ACTIVITYREPORT.CREATE.INCOMINGMATERIAL.DESCRIPTION' })}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {detail?.activityReportProducts?.map((item, index) => <tr key={index}>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.productName}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.amount}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.productUnitName}
                                    </div>
                                  </td>
                                  <td className='p-5'>
                                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                      {item?.description}
                                    </div>
                                  </td>
                                </tr>)}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>}
          </>}
        </div>
        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.ActivityReport} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.ActivityReport} collapse={false} />
        </div>
      </div>

      {
        loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
          <span className="spinner-border text-primary" role="status"></span>
          <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
        </div>)
      }
    </>
  )
}
export { Detail };
