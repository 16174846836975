/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import routes from '../../App/PrivateRoutes'
import {useSelector} from 'react-redux'
import {ListLoading} from '../../components/Table/ListLoading'

export function AsideMenuMain() {
  const intl = useIntl()
  const {info, loading} = useSelector((state) => state.profile)
  const userRolesSet = new Set(info?.roles);

  if (info) return (
    <>
      {loading && <ListLoading />}

      {!loading && (
        <>
          {routes?.map((route) => {
            const routeRoles = route.role;

            if (route?.visibility == 'hidden') return <Fragment key={route.key} />
            
            if (route?.child) {
              const hasUserRole = routeRoles.some(role => userRolesSet.has(role));

              if (hasUserRole) {
                return (
                  <AsideMenuItemWithSub
                    to={route.path}
                    title={intl.formatMessage({id: route.title})}
                    fontIcon='bi-archive'
                    icon={route?.icon}
                    key={route.key}
                  >
                    {route.child.map((children) => {
                      const hasChildRole = children?.role?.some(role => userRolesSet.has(role));
                      if (children?.claimType) {
                        const claimType = info?.claims?.find((item) => item.type == children?.claimType && item.value == children?.claimValue)

                        if (!claimType) return <Fragment key={children.key} />
                      }

                      if (children?.role && children?.role?.length > 0 && !hasChildRole) return <Fragment key={children.key} />
                      
                      return (
                        <AsideMenuItem
                          key={children.key}
                          to={children.path}
                          title={intl.formatMessage({id: children.title})}
                          hasBullet={true}
                          className='ps-13'
                        />
                      )
                    })}
                  </AsideMenuItemWithSub>
                )
              }
            }

            const hasRole = routeRoles.some(role => userRolesSet.has(role));

            if (hasRole || route.role.includes('All')) {
              return (
                <AsideMenuItem
                  to={route.path}
                  icon={route.icon}
                  title={intl.formatMessage({ id: route.title })}
                  fontIcon='bi-archive'
                  key={route.key}
                />
              );
            }

            return <Fragment key={route.key} />
          })}
        </>
      )}
    </>
  )

  return <></>
}
