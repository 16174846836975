export const workplanConstants = {
 
  //#region Task
  TASK_GETALL_REQUEST: 'TASK_GETALL_REQUEST',
  TASK_GETALL_SUCCESS: 'TASK_GETALL_SUCCESS',
  TASK_GETALL_FAILURE: 'TASK_GETALL_FAILURE',

  TASK_GETID_REQUEST: 'TASK_GETID_REQUEST',
  TASK_GETID_SUCCESS: 'TASK_GETID_SUCCESS',
  TASK_GETID_FAILURE: 'TASK_GETID_FAILURE',

  TASK_DELETE_REQUEST: 'TASK_DELETE_REQUEST',
  TASK_DELETE_SUCCESS: 'TASK_DELETE_SUCCESS',
  TASK_DELETE_FAILURE: 'TASK_DELETE_FAILURE',

  TASK_CREATE_REQUEST: 'TASK_CREATE_REQUEST',
  TASK_CREATE_SUCCESS: 'TASK_CREATE_SUCCESS',
  TASK_CREATE_FAILURE: 'TASK_CREATE_FAILURE',

  TASK_UPDATE_REQUEST: 'TASK_UPDATE_REQUEST',
  TASK_UPDATE_SUCCESS: 'TASK_UPDATE_SUCCESS',
  TASK_UPDATE_FAILURE: 'TASK_UPDATE_FAILURE',

  TASK_ARCHIVE_REQUEST: 'TASK_ARCHIVE_REQUEST',
  TASK_ARCHIVE_SUCCESS: 'TASK_ARCHIVE_SUCCESS',
  TASK_ARCHIVE_FAILURE: 'TASK_ARCHIVE_FAILURE',

  TASK_EXCEL_REQUEST: 'TASK_EXCEL_REQUEST',
  TASK_EXCEL_SUCCESS: 'TASK_EXCEL_SUCCESS',
  TASK_EXCEL_FAILURE: 'TASK_EXCEL_FAILURE',

  TASK_UPDATE_COMPLETION_REQUEST: 'TASK_UPDATE_COMPLETION_REQUEST',
  TASK_UPDATE_COMPLETION_SUCCESS: 'TASK_UPDATE_COMPLETION_SUCCESS',
  TASK_UPDATE_COMPLETION_FAILURE: 'TASK_UPDATE_COMPLETION_FAILURE',
  //#endregion

  //#region ActivityReport
  ACTIVITYREPORT_GETALL_REQUEST: 'ACTIVITYREPORT_GETALL_REQUEST',
  ACTIVITYREPORT_GETALL_SUCCESS: 'ACTIVITYREPORT_GETALL_SUCCESS',
  ACTIVITYREPORT_GETALL_FAILURE: 'ACTIVITYREPORT_GETALL_FAILURE',

  ACTIVITYREPORT_GETID_REQUEST: 'ACTIVITYREPORT_GETID_REQUEST',
  ACTIVITYREPORT_GETID_SUCCESS: 'ACTIVITYREPORT_GETID_SUCCESS',
  ACTIVITYREPORT_GETID_FAILURE: 'ACTIVITYREPORT_GETID_FAILURE',

  ACTIVITYREPORT_CREATE_REQUEST: 'ACTIVITYREPORT_CREATE_REQUEST',
  ACTIVITYREPORT_CREATE_SUCCESS: 'ACTIVITYREPORT_CREATE_SUCCESS',
  ACTIVITYREPORT_CREATE_FAILURE: 'ACTIVITYREPORT_CREATE_FAILURE',

  ACTIVITYREPORT_UPDATE_REQUEST: 'ACTIVITYREPORT_UPDATE_REQUEST',
  ACTIVITYREPORT_UPDATE_SUCCESS: 'ACTIVITYREPORT_UPDATE_SUCCESS',
  ACTIVITYREPORT_UPDATE_FAILURE: 'ACTIVITYREPORT_UPDATE_FAILURE',

  ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_REQUEST: 'ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_REQUEST',
  ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_SUCCESS: 'ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_SUCCESS',
  ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_FAILURE: 'ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_FAILURE',

  ACTIVITYREPORT_CHECK_REPORT_REQUEST: 'ACTIVITYREPORT_CHECK_REPORT_REQUEST',
  ACTIVITYREPORT_CHECK_REPORT_SUCCESS: 'ACTIVITYREPORT_CHECK_REPORT_SUCCESS',
  ACTIVITYREPORT_CHECK_REPORT_FAILURE: 'ACTIVITYREPORT_CHECK_REPORT_FAILURE',

  ACTIVITYREPORT_EXCEL_REQUEST: 'ACTIVITYREPORT_EXCEL_REQUEST',
  ACTIVITYREPORT_EXCEL_SUCCESS: 'ACTIVITYREPORT_EXCEL_SUCCESS',
  ACTIVITYREPORT_EXCEL_FAILURE: 'ACTIVITYREPORT_EXCEL_FAILURE',

  ACTIVITYREPORT_CHECK_INFO_REQUEST: 'ACTIVITYREPORT_CHECK_INFO_REQUEST',
  ACTIVITYREPORT_CHECK_INFO_SUCCESS: 'ACTIVITYREPORT_CHECK_INFO_SUCCESS',
  ACTIVITYREPORT_CHECK_INFO_FAILURE: 'ACTIVITYREPORT_CHECK_INFO_FAILURE',

  ACTIVITYREPORT_EXCELGETID_REQUEST: 'ACTIVITYREPORT_EXCELGETID_REQUEST',
  ACTIVITYREPORT_EXCELGETID_SUCCESS: 'ACTIVITYREPORT_EXCELGETID_SUCCESS',
  ACTIVITYREPORT_EXCELGETID_FAILURE: 'ACTIVITYREPORT_EXCELGETID_FAILURE',

  ACTIVITYREPORT_RELEASEREPORT_REQUEST: 'ACTIVITYREPORT_RELEASEREPORT_REQUEST',
  ACTIVITYREPORT_RELEASEREPORT_SUCCESS: 'ACTIVITYREPORT_RELEASEREPORT_SUCCESS',
  ACTIVITYREPORT_RELEASEREPORT_FAILURE: 'ACTIVITYREPORT_RELEASEREPORT_FAILURE',
  //#endregion
};
