/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { KTCard, KTCardBody, Notes, DeleteModal, ArchiveModal, DataTables, Category } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../../../redux/actions';
import { SourceType, KTSVG, detailPageUrl, DetailPage } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

import {useFormik, FormikProvider, FieldArray} from 'formik'

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, error, searchStock, searchWarehouse, productStock, productSaleInvoice, productExpenseInvoice, updateWareHouses } = useSelector(state => state.product);
  const dispatch = useDispatch();

  const [formValues] = useState({
    StockModels: [{
      ProductId: null,
      WarehouseId: null,
      Amount: 0,
    }],
  })

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(productActions.productUpdateWarehouses(values));
    },
  })

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(productActions.productGetId(id));
    dispatch(productActions.productGetStock({Id: id}));
    dispatch(productActions.productSearchSaleInvoice({Id: id}));
    dispatch(productActions.productSearchExpenseInvoice({Id: id}));
    dispatch(productActions.productSearchWarehouse({Id: id}));
  }, [])

  useEffect(() => {
    dispatch(productActions.productGetId(id));
    dispatch(productActions.productGetStock({Id: id}));
    dispatch(productActions.productSearchSaleInvoice({Id: id}));
    dispatch(productActions.productSearchExpenseInvoice({Id: id}));
    dispatch(productActions.productSearchWarehouse({Id: id}));
  }, [updateWareHouses])

  useEffect(() => {
    if (detail?.isFollowStock) dispatch(productActions.productSearchStock({Id: id}));
  }, [detail])

  useEffect(() => {
    searchWarehouse?.map((item, index) => {
      formik.setFieldValue(`StockModels[${index}]['ProductId']`, item.id)
      formik.setFieldValue(`StockModels[${index}]['WarehouseId']`, item.warehouseId)
      formik.setFieldValue(`StockModels[${index}]['Amount']`, item.amount)
      formik.setFieldValue(`StockModels[${index}]['WarehouseName']`, item.warehouseName)
    })
  }, [searchWarehouse])

  const handleDelete = () => dispatch(productActions.productDelete({ id }));

  const handleArchive = () => dispatch(productActions.productArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.PRODUCT_SERVICES' }),
      path: '/stock/product-services',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.PRODUCT_SERVICES.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>

          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>
                            {detail.code}
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.productUnitName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/general/gen044.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.productUnitName}
                            </div>
                          </>}


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.Product} />
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>	
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PRODUCT.GET.BASESALEPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.baseSalePrice, { style: 'currency', currency: 'try' })}</div>
                        </div>
                        
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'WAREHOUSE.GET.PRODUCTSTOCKCOUNT' })}</div>
                            <div className="fw-bold fs-4">{productStock?.currentStock}</div>
                          </div>                        
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>                                            
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PRODUCT.GET.ISFOLLOWSTOCK' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.isFollowStock ? intl.formatMessage({ id: 'PRODUCT.GET.ISFOLLOWSTOCK.YES' }) : intl.formatMessage({ id: 'PRODUCT.GET.ISFOLLOWSTOCK.NO' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PRODUCT.GET.BASEPURCHASEPRICE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail?.basePurchasePrice, { style: 'currency', currency: 'try' })}</span></div>
                        </div>                      
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PRODUCT.GET.TAX' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.taxName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PRODUCT.GET.PURCHASEPRICEWITHTAX' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail?.purchasePriceWithTax, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PRODUCT.GET.SALEPRICEWITHTAX' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail?.salePriceWithTax, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PRODUCT.GET.ISTOTALSTOCKALARM' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.isTotalStockAlarm ? intl.formatMessage({ id: 'PRODUCT.GET.ISTOTALSTOCKALARM.YES' }) : intl.formatMessage({ id: 'PRODUCT.GET.ISTOTALSTOCKALARM.NO' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PRODUCT.GET.TOTALSTOCKALARMCOUNT' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.totalStockAlarmCount}</span></div>
                        </div>                      
                      </div>
                    </div>
                  </Tab>

                  {searchStock?.length > 0 && <Tab eventKey="searchStock" title={intl.formatMessage({ id: 'WAREHOUSE.SEARCH.STOCKSINFO' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.NAME' }),
                          prop: 'warehouseName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.STOCKPROCESSTYPENAME' }),
                          prop: 'stockProcessTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.PROCESSDATE' }),
                          prop: 'processDate',
                          cell: (row) => intl.formatDate(new Date(row.processDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.AMOUNT' }),
                          prop: 'amount',
                          cell: (row) => `${row.amount} ${row.productUnitName}`,
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={searchStock}
                      exportTitle={intl.formatMessage({ id: 'WAREHOUSE.SEARCH.STOCKSINFO' })}
                    />
                  </Tab>}
                  
                  {productExpenseInvoice?.length > 0 && <Tab eventKey="productExpenseInvoice" title={intl.formatMessage({ id: 'MENU.EXPENSES' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'EXPENSEINVOICE.CREATEDETAIL.COMPANYID' }),
                          prop: 'companyName',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.CompanyCustomer, row?.companyId)} className='text-dark text-hover-primary' target='_blank'>{row.companyName}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.SALEINVOICENAME' }),
                          prop: 'expenseInvoiceName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.AMOUNT' }),
                          prop: 'amount',
                          cell: (row) => `${row.amount} ${row.productUnitName}`,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.UNITPRICE' }),
                          prop: 'unitPrice',
                          cell: (row) => intl.formatNumber(row.unitPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.EDITDATE' }),
                          prop: 'editDate',
                          cell: (row) => intl.formatDate(new Date(row.editDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={productExpenseInvoice}
                      exportTitle={intl.formatMessage({ id: 'MENU.EXPENSES' })}
                    />
                  </Tab>}
                  {productSaleInvoice?.length > 0 && <Tab eventKey="productSaleInvoice" title={intl.formatMessage({ id: 'MENU.SALES' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.PRODUCTID' }),
                          prop: 'productName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.SALEINVOICENAME' }),
                          prop: 'saleInvoiceName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.AMOUNT' }),
                          prop: 'amount',
                          cell: (row) => `${row.amount} ${row.productUnitName}`,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.UNITPRICE' }),
                          prop: 'unitPrice',
                          cell: (row) => intl.formatNumber(row.unitPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'SALEINVOICE.CREATE.EDITDATE' }),
                          prop: 'editDate',
                          cell: (row) => intl.formatDate(new Date(row.editDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={productSaleInvoice}
                      exportTitle={intl.formatMessage({ id: 'MENU.SALES' })}
                    />
                  </Tab>}
                  {detail?.isFollowStock && <Tab eventKey="stock" title={`${intl.formatMessage({ id: 'WAREHOUSE.SEARCH.SEARCHWAREHOUSES' })} (${productStock?.currentStock})`}>
                    <FormikProvider value={formik}>
                      <form className='form' onSubmit={formik.handleSubmit} noValidate>
                        <FieldArray
                          name="StockModels"
                          render={() => (
                            <>
                              <div className="table-responsive">
                                <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" >
                                  <thead>
                                    <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                      <th>{intl.formatMessage({ id: 'PRODUCT.SEARCHWAREHOUSES.WAREHOUSENAME' })}</th>
                                      <th>{intl.formatMessage({ id: 'PRODUCT.SEARCHWAREHOUSES.AMOUNT' })}</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    {formik.values.StockModels.map((item, index) => (
                                      <tr className="border-bottom border-bottom-dashed" key={index}>
                                        <td className='p-5'>
                                          <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                            {item.WarehouseName}
                                          </div>
                                        </td>
                                        <td className="ps-0">
                                          <input
                                            type='number'
                                            className='form-control form-control-solid'
                                            autoComplete='off'
                                            disabled={formik.isSubmitting}
                                            name={`StockModels[${index}].Amount`}
                                            value={formik.values.StockModels[index].Amount}
                                            onChange={formik.handleChange}
                                            onFocus={event => event.target.select()}
                                          />
                                        </td>
                                      </tr>
                                    ))}

                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        />
                        <div className='text-center pt-15'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                          >
                            <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                            {(formik.isSubmitting) && (
                              <span className='indicator-progress'>
                                {intl.formatMessage({ id: 'PLEASE' })}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </form>
                    </FormikProvider>
                  </Tab>}
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}       
        </div>
        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Product} />
        </div>
      </div>
      
      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal 
        onClick={handleArchive} 
        onOpen={showArchive} 
        onClose={() => setShowArchive(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
