import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { guaranteeletterActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { guaranteeletter, loading } = useSelector(state => state.guaranteeletter);
  const { projectLookup, companyLookup, Bank, GuaranteeLetterType, GuaranteeLetterState } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) dispatch(guaranteeletterActions.guaranteeletterGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('Bank'));
    dispatch(lookupActions.basicLookup('GuaranteeLetterType'));
    dispatch(lookupActions.basicLookup('GuaranteeLetterState'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.PROJECTNAME' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.COMPANYNAME' })} className='min-w-125px' />,
      accessor: 'companyName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.GUARATEELETTERTYPENAME' })} className='min-w-125px' />,
      accessor: 'guaranteeLetterTypeName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.GUARATEELETTERSTATENAME' })} className='min-w-125px' />,
      accessor: 'guaranteeLetterStateName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.BANKNAME' })} className='min-w-125px' />,
      accessor: 'bankName',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='branch' />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.DEFERMENTDATE' })} className='min-w-125px' />,
      accessor: 'defermentDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.ORIGINALAMOUNT' })} className='min-w-125px' />,
      accessor: 'originalAmount',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'GUARATEELETTER.SEARCH.BANKCOMISSION' })} className='min-w-125px' />,
      accessor: 'bankCommission',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(guaranteeletterActions.guaranteeletterExcel(params, intl.formatMessage({ id: 'BC.GUARATEELETTER' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'GUARATEELETTER.SEARCH.PROJECTNAME',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'GUARATEELETTER.SEARCH.COMPANYNAME',
          data: companyLookup,
          name: 'companyId'
        },
        {
          title: 'GUARATEELETTER.SEARCH.BANKNAME',
          data: Bank,
          name: 'bankId'
        },
        {
          title: 'GUARATEELETTER.SEARCH.GUARATEELETTERTYPENAME',
          data: GuaranteeLetterType,
          name: 'guaranteeLetterTypeId'
        },
        {
          title: 'GUARATEELETTER.SEARCH.GUARATEELETTERSTATENAME',
          data: GuaranteeLetterState,
          name: 'guaranteeLetterStateId'
        },
        {
          title: 'STAFF.CREATE.CERTIFICATESLIST.STARTDATE',
          type: 'date',
          name: 'startDate'
        },
        {
          title: 'STAFF.CREATE.CERTIFICATESLIST.ENDDATE',
          type: 'date',
          name: 'endDate'
        }, 
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.GUARATEELETTER' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'GUARATEELETTER'}
        />
        <KTCardBody>
          {!guaranteeletter && <ListLoading />}
          {guaranteeletter && <Table
            items={guaranteeletter}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
