/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik, FormikProvider, FieldArray } from 'formik'

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTSVG } from '../../../helpers';
import { KTCard, KTCardBody, Select, InputPrice } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, staffActions } from '../../../redux/actions';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function Edit() {
  const [projectId, setProjectId] = useState(null)
  const [progressPaymentDate, setProgressPaymentDate] = useState(new Date())

  const intl = useIntl()
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const ProjectId = query.get("projectId")
  const Month = query.get("month")
  const Year = query.get("year")

  const { projectLookup } = useSelector(state => state.lookup);
  const { isRedirect, loading, monthlySalaryStaff, detail } = useSelector(state => state.staff);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
  }, [])

  useEffect(() => {
    dispatch(staffActions.monthlySalaryGet({ProjectId, Month, Year}));
  }, [ProjectId, Month, Year])

  const [stepSchema] = useState({
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProgressPaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    DueDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    UpdateSalaryItems: Yup.array().of(Yup.object().shape({
      Payment: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      BesPayment: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    })
    )
  })

  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    ProjectId: '',
    ProgressPaymentDate: new Date(),
    Name: '',
    DueDate: new Date(),
    UpdateSalaryItems: [
      {
        Id: '',
        StaffId: '',
        Payment: '',
        BesPayment: '',
        JobLeaveDate: '',
        JobStartDate: '',
        NameSurname: '',
        IdentityNumber: ''
      }
    ]
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(staffActions.monthlySalaryUpdate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.MONTHLY_SALARY' }),
      path: '/staff/monthly-salary',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect) {
      const month = new Date(isRedirect?.DueDate).getMonth();
      const year = new Date(isRedirect?.DueDate).getFullYear();
      
      navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail?projectId=${isRedirect?.ProjectId}&year=${year}&month=${month}`)
    }
  }, [isRedirect])

  useEffect(() => {
    if (detail) {
      formik.setFieldValue('ProjectId', detail?.projectId)
      formik.setFieldValue('Name', detail?.name)
      formik.setFieldValue('ProgressPaymentDate', new Date(detail?.progressPaymentDate))
      formik.setFieldValue('DueDate', new Date(detail?.dueDate))
      setProjectId(detail?.projectId)
      setProgressPaymentDate(new Date(detail?.progressPaymentDate))
    }
  }, [detail])

  const handleChange = (el, item) => {
    if (el == 'ProjectId') {
      setProjectId(Number(item?.value))
      formik.setFieldValue('ProjectId', Number(item?.value))
    } else {
      setProgressPaymentDate(item)
      formik.setFieldValue('ProgressPaymentDate', item)
    }
  }

  useEffect(() => {
    if (projectId && progressPaymentDate) {
      dispatch(staffActions.monthlySalaryGetMonthly({
        ProjectId: formik.values.ProjectId, 
        ProgressPaymentDate: formik.values.ProgressPaymentDate
      }));
    }
  }, [projectId, progressPaymentDate])

  useEffect(() => {
    if (monthlySalaryStaff) {
      formik.setFieldValue(`UpdateSalaryItems`, [])
      formik.setFieldValue(`ProjectId`, monthlySalaryStaff?.projectId)
      formik.setFieldValue(`Name`, monthlySalaryStaff?.name)
      formik.setFieldValue(`ProgressPaymentDate`, monthlySalaryStaff?.progressPaymentDate ? new Date(monthlySalaryStaff?.progressPaymentDate) : new Date())
      formik.setFieldValue(`DueDate`, monthlySalaryStaff?.dueDate ? new Date(monthlySalaryStaff?.dueDate) : new Date())

      monthlySalaryStaff?.salaryList?.map((item, index) => {
        formik.setFieldValue(`UpdateSalaryItems[${index}]['Id']`, item?.id)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['StaffId']`, item?.staffId)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['Payment']`, item?.payment)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['BesPayment']`, item?.besPayment)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['JobLeaveDate']`, item?.jobLeaveDate)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['JobStartDate']`, item?.jobStartDate)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['NameSurname']`, item?.nameSurname)
        formik.setFieldValue(`UpdateSalaryItems[${index}]['IdentityNumber']`, item?.identityNumber)
      })
    }
  }, [monthlySalaryStaff])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.MONTHLY_SALARY.EDIT' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <FormikProvider value={formik}>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENT.PROJECT' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={projectLookup}
                    required
                    {...formik.getFieldProps('ProjectId')}
                    onChange={item => handleChange('ProjectId', item)}
                  />
                  {formik.touched.ProjectId && formik.errors.ProjectId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ProjectId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROGRESS_PAYMENT_DATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      required
                      {...formik.getFieldProps('ProgressPaymentDate')}
                      onChange={([date]) => handleChange('ProgressPaymentDate', date)}
                    />
                  </div>
                  {formik.touched.ProgressPaymentDate && formik.errors.ProgressPaymentDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ProgressPaymentDate}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT.DUE.DATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      required
                      {...formik.getFieldProps('DueDate')}
                      onChange={([date]) => formik.setFieldValue('DueDate', date)}
                    />
                  </div>
                  {formik.touched.DueDate && formik.errors.DueDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.DueDate}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPLANATION' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <textarea
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    required
                    rows={4}
                    {...formik.getFieldProps('Name')}
                  />
                  {formik.touched.Name && formik.errors.Name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {formik.values?.UpdateSalaryItems.length > 0 && monthlySalaryStaff?.salaryList?.length > 0 && <>
                <div className='row mb-6'>
                  <label className='col-lg-3 required fw-bold fs-6'>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS' })}</label>
                </div>

                <FieldArray
                  name="UpdateSalaryItems"
                  render={() => (
                    <>
                      <div className="table-responsive">
                        <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" >
                          <thead>
                            <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                              <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.STAFFID' })}</th>
                              <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.IDENTITYNUMBER' })}</th>
                              <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.JOBSTARTDATE' })}</th>
                              <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.JOBLEAVEDATE' })}</th>
                              <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.PAYMENT' })}</th>
                              <th>{intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.BESPAYMENT' })}</th>
                            </tr>
                          </thead>
                          <tbody>

                            {formik.values.UpdateSalaryItems.map((item, index) => (
                              <tr className="border-bottom border-bottom-dashed" key={index}>
                                <td className="pe-7 ps-1">
                                  {item?.NameSurname}
                                </td>

                                <td className="ps-0 text-center">
                                  {item?.IdentityNumber || '-'}
                                </td>

                                <td className="ps-0">
                                  {item?.JobStartDate ? intl.formatDate(new Date(item?.JobStartDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}
                                </td>

                                <td className="ps-0">
                                  {item?.JobLeaveDate ? intl.formatDate(new Date(item?.JobLeaveDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}
                                </td>

                                <td className="ps-0">
                                  <InputPrice
                                    placeholder={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.PAYMENT' })}
                                    name={`UpdateSalaryItems[${index}].Payment`}
                                    required
                                    value={formik.values.UpdateSalaryItems[index].Payment}
                                  />
                                </td>

                                <td className='ps-0'>
                                  <InputPrice
                                    placeholder={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.CREATE.UPDATESALARTITEMS.BESPAYMENT' })}
                                    name={`UpdateSalaryItems[${index}].BesPayment`}
                                    value={formik.values.UpdateSalaryItems[index].BesPayment}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                />
              </>}

              <div className='row pt-15'>
                <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                  <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
