import { bankPaymentOrderConstants } from '../constants';
import {AuthCheck, DownloadFileFn} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  excel: null,
}

export function bankPaymentOrder(state = {}, action) {
  switch (action.type) {
    //#region BankPaymentRequest
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETALL_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETALL_SUCCESS:
      return {
        ...state,
        bankPaymentOrder: action.bankPaymentOrder,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case bankPaymentOrderConstants.BANKPAYMENTORDER_CREATE_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.bankPaymentOrder,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETID_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETID_SUCCESS:
      return {
        ...state,
        detail: action.bankPaymentOrder,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case bankPaymentOrderConstants.BANKPAYMENTORDER_DELETE_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case bankPaymentOrderConstants.BANKPAYMENTORDER_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.bankPaymentOrder || action.params,
        detail: action.params,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_SUCCESS:
      return {
        ...state,
        getAvailablePayments: action.getAvailablePayments,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case bankPaymentOrderConstants.BANKPAYMENTORDER_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_EXCEL_SUCCESS:
      DownloadFileFn(action.excel, '', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', false)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case bankPaymentOrderConstants.BANKPAYMENTORDER_SENDTOCONFIRMATION_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_SENDTOCONFIRMATION_SUCCESS:
      return {
        ...state,
        sendToConfirmation: action.sendToConfirmation,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_SENDTOCONFIRMATION_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case bankPaymentOrderConstants.BANKPAYMENTORDER_CONFIRM_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_CONFIRM_SUCCESS:
      return {
        ...state,
        confirm: action.confirm,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_CONFIRM_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case bankPaymentOrderConstants.BANKPAYMENTORDER_COMPLETEBANKORDER_REQUEST:
      return { ...state, ...initialState };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_COMPLETEBANKORDER_SUCCESS:
      return {
        ...state,
        completeBankOrder: action.completeBankOrder,
        loading: false
      };
    case bankPaymentOrderConstants.BANKPAYMENTORDER_COMPLETEBANKORDER_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    //#endregion    
    default:
      return state
  }
}
