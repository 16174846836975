/* eslint-disable no-unused-vars */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import PropTypes from 'prop-types'

const ChartLine = ({
  className,
  backGroundColor,
  chartHeight,
  options,
  title,
  data,
  categories,
}) => {
  const chartRef = useRef(null)

  const colors = ApexCharts.colors

  const defaultOption = {
    series: data,
    chart: {
      height: chartHeight,
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
    },
    colors,
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories,
    },
  }

  const chartOptions = {...defaultOption, ...options}

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions)
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div
      className={`card ${className} theme-dark-bg-body`}
      style={{backgroundColor: backGroundColor}}
    >
      <div className='card-body d-flex flex-column'>
        <div className='d-flex flex-column flex-grow-1'>
          <span className='text-dark text-hover-primary fw-bolder fs-3'>{title}</span>

          <div
            ref={chartRef}
            className='mixed-widget-13-chart'
            style={{height: chartHeight, minHeight: chartHeight}}
          ></div>
        </div>
      </div>
    </div>
  )
}

ChartLine.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  data: PropTypes.array,
}

ChartLine.defaultProps = {
  categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  data: [15, 25, 15, 40, 20, 50],
}

export {ChartLine}
