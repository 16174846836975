export const TaxType = [
  {
    id: 1,
    name: '%0',
    value: 0
  },
  {
    id: 2,
    name: '%1',
    value: 1
  },
  {
    id: 3,
    name: '%8',
    value: 8
  },
  {
    id: 4,
    name: '%18',
    value: 18
  },
  {
    id: 5,
    name: '%10',
    value: 10
  },
  {
    id: 6,
    name: '%20',
    value: 20
  }
]
