import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { projectActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl();
  const { contracts, loading } = useSelector(state => state.project);
  const { projectLookup, companyLookup, ContractType, ContractState } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState();

  useEffect(() => {
    if (params) dispatch(projectActions.contractsGetAll(params));
  }, [params]);

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.basicLookup('ContractType'));
    dispatch(lookupActions.basicLookup('ContractState'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CONTRACT.SEARCH.PROJECTID' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CONTRACT.SEARCH.CONTRACTTYPEID' })} className='min-w-125px' />,
      accessor: 'contractTypeName',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CONTRACT.SEARCH.CONTRACTSTATEID' })} className='min-w-125px' />,
      accessor: 'contractStateName',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CONTRACT.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CONTRACT.SEARCH.COMPANYID' })} className='min-w-125px' />,
      accessor: 'companyName',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CONTRACT.SEARCH.STARTDATE' })} className='min-w-125px' />,
      accessor: 'startDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CONTRACT.SEARCH.ENDDATE' })} className='min-w-125px' />,
      accessor: 'endDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" />,
    },
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(projectActions.contractsExcel(params, intl.formatMessage({ id: 'BC.CONTRACTS' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'CONTRACT.SEARCH.PROJECTID',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'CONTRACT.SEARCH.COMPANYID',
          data: companyLookup,
          name: 'companyId'
        },
        {
          title: 'CONTRACT.SEARCH.CONTRACTTYPEID',
          data: ContractType,
          name: 'contractTypeId'
        },
        {
          title: 'CONTRACT.SEARCH.CONTRACTSTATEID',
          data: ContractState,
          name: 'contractStateId'
        },
        {
          title: 'CONTRACT.SEARCH.NAME',
          type: 'text',
          name: 'contractName'
        },
        {
          title: 'CONTRACT.SEARCH.STARTDATE',
          type: 'date',
          name: 'startDate'
        },
        {
          title: 'CONTRACT.SEARCH.ENDDATE',
          type: 'date',
          name: 'endDate'
        },
      ]
    }
  ]


  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.CONTRACTS' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'CONTRACTS'}
        />
        <KTCardBody>
          {!contracts && <ListLoading />}
          {contracts && <Table
            items={contracts}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
