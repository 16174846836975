import {workplanConstants} from '../constants'
import {AuthCheck, DownloadFileFn} from '../../helpers'

const initialState = {
  loading: true,
  isRedirect: false,
  error: null,
  isDeleted: false,
  isArchived: false,
  detail: null,
  checkreport: null,
  checkinfo: null,
  excel: null,
  releaseReport: false
}

export function workplan(state = {}, action) {
  switch (action.type) {
    //#region Task
    case workplanConstants.TASK_GETALL_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_GETALL_SUCCESS:
      return {
        ...state,
        task: action.task,
        loading: false,
      }
    case workplanConstants.TASK_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.TASK_GETID_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_GETID_SUCCESS:
      return {
        ...state,
        detail: action.task,
        loading: false,
      }
    case workplanConstants.TASK_GETID_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.TASK_DELETE_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false,
      }
    case workplanConstants.TASK_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.TASK_ARCHIVE_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false,
      }
    case workplanConstants.TASK_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.TASK_CREATE_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.task,
        loading: false,
      }
    case workplanConstants.TASK_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.TASK_UPDATE_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.task || action.params,
        detail: action.params,
        loading: false,
      }
    case workplanConstants.TASK_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false,
      }

    case workplanConstants.TASK_EXCEL_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_EXCEL_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false,
      }
    }
    case workplanConstants.TASK_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.TASK_UPDATE_COMPLETION_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.TASK_UPDATE_COMPLETION_SUCCESS:
      return {
        ...state,
        isRedirect: action.task || action.params,
        detail: action.params,
        loading: false,
      }
    case workplanConstants.TASK_UPDATE_COMPLETION_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false,
      }
    //#endregion

    //#region ActivityReport
    case workplanConstants.ACTIVITYREPORT_GETALL_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_GETALL_SUCCESS:
      return {
        ...state,
        activityReport: action.activityReport,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_GETID_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_GETID_SUCCESS:
      return {
        ...state,
        detail: action.activityReport,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_GETID_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_CREATE_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.activityReport,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_UPDATE_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.activityReport || action.params,
        detail: action.params,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_SUCCESS:
      return {
        ...state,
        timeKeepingTemplate: action.timeKeepingTemplate,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_CHECK_REPORT_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_CHECK_REPORT_SUCCESS:
      return {
        ...state,
        checkreport: action.checkreport || true,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_CHECK_REPORT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_EXCEL_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_EXCEL_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false,
      }
    }
    case workplanConstants.ACTIVITYREPORT_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_CHECK_INFO_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_CHECK_INFO_SUCCESS:
      return {
        ...state,
        checkinfo: action.checkinfo,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_CHECK_INFO_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_EXCELGETID_REQUEST:
      return {...state}
    case workplanConstants.ACTIVITYREPORT_EXCELGETID_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false,
      }
    }
    case workplanConstants.ACTIVITYREPORT_EXCELGETID_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case workplanConstants.ACTIVITYREPORT_RELEASEREPORT_REQUEST:
      return {...state, ...initialState}
    case workplanConstants.ACTIVITYREPORT_RELEASEREPORT_SUCCESS:
      return {
        ...state,
        releaseReport: true,
        loading: false,
      }
    case workplanConstants.ACTIVITYREPORT_RELEASEREPORT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false,
      }

    //#endregion
    default:
      return state
  }
}
