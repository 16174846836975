/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers';
import { Modal } from 'react-bootstrap'

const DeleteModal = ({onOpen, onClose, onClick, error}) => {
  const [show, setShow] = useState(onOpen);
  const intl = useIntl()

  useEffect(() => {
    setShow(onOpen)
  }, [onOpen])

  const handleClose = () => {
    setShow(false)
    onClose && onClose()
  }

  const handleClick = () => onClick && onClick()

  useEffect(() => {
    if (error && show) {
      setShow(false)
    }
  }, [error])
  
  return (
    <>
      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'BUTTON.DELETE' })}</h5>
            <div className='d-flex ms-2'>
              <div
                className='btn btn-icon btn-sm btn-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>
          </div>

          <div className='d-block text-center py-5 ps-8 pe-5 '>
            <div className="d-block">
              <KTSVG className='text-danger' svgClassName='w-125px h-125px' path='/images/icons/duotune/arrows/arr015.svg' />
            </div>
            <div className="d-block text-center mt-6 mb-13">
              <h1 className="mb-3">{intl.formatMessage({id: 'MODAL.DELETE.TITLE' })}</h1>
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
            <div className='d-flex align-items-center me-3'>
              <button className="btn btn-light me-3" onClick={handleClose}>{intl.formatMessage({id: 'BUTTON.CLOSE' })}</button>
              <button className="btn btn-danger" onClick={handleClick}>{intl.formatMessage({id: 'BUTTON.DELETE' })}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

DeleteModal.propTypes = {
	onOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

DeleteModal.defaultProps = {
	onOpen: false
};

export {DeleteModal}
