import { checkoutConstants } from '../constants';
import {
  //#region Checkout
  SearchByStaffIdCheckoutClient,
  SalaryPaymentCheckoutClient,
  StaffPaymentCheckoutClient,
  DeleteCheckoutClient,
  GetCheckoutClient,
  AccountToAccountTransactionCheckoutClient,
  CreditTransactionCheckoutClient,
  DebitTransactionCheckoutClient,
  ExpenseInvoicePayCheckCheckoutClient,
  ExpenseInvoicePayMoneyCheckoutClient,
  ExpenseInvoicePayNewCheckCheckoutClient,
  SaleInvoiceReceiveCheckCheckoutClient,
  SaleInvoiceReceiveMoneyCheckoutClient,
  CompanyCollectionCheckoutClient,
  CompanyPaymentCheckoutClient,
  CompanyPayNewCheckCheckoutClient,
  CompanyPayCheckCheckoutClient,
  UpdateAdvanceCheckClient,
  SearchTransactionsCheckoutClient,
  GetCollectionReceiptClient,
  UpdateCheckoutDescriptionClient,
  //#endregion
  //#region Checkout Approve
  SearchCheckoutApproveClient,
  UpdateCheckoutApproveConfirmationClient
  //#endregion
  
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;

//#region Checkout
const searchByStaffIdCheckoutClient = new SearchByStaffIdCheckoutClient(SERVICE_URL);
const salaryPaymentCheckoutClient = new SalaryPaymentCheckoutClient(SERVICE_URL);
const staffPaymentCheckoutClient = new StaffPaymentCheckoutClient(SERVICE_URL);
const deleteCheckoutClient = new DeleteCheckoutClient(SERVICE_URL);
const getCheckoutClient = new GetCheckoutClient(SERVICE_URL);
const accountToAccountTransactionCheckoutClient = new AccountToAccountTransactionCheckoutClient(SERVICE_URL);
const creditTransactionCheckoutClient = new CreditTransactionCheckoutClient(SERVICE_URL);
const debitTransactionCheckoutClient = new DebitTransactionCheckoutClient(SERVICE_URL);
const expenseInvoicePayCheckCheckoutClient = new ExpenseInvoicePayCheckCheckoutClient(SERVICE_URL);
const expenseInvoicePayMoneyCheckoutClient = new ExpenseInvoicePayMoneyCheckoutClient(SERVICE_URL);
const expenseInvoicePayNewCheckCheckoutClient = new ExpenseInvoicePayNewCheckCheckoutClient(SERVICE_URL);
const saleInvoiceReceiveCheckCheckoutClient = new SaleInvoiceReceiveCheckCheckoutClient(SERVICE_URL);
const saleInvoiceReceiveMoneyCheckoutClient = new SaleInvoiceReceiveMoneyCheckoutClient(SERVICE_URL);
const companyCollectionCheckoutClient = new CompanyCollectionCheckoutClient(SERVICE_URL);
const companyPaymentCheckoutClient = new CompanyPaymentCheckoutClient(SERVICE_URL);
const companyPayNewCheckCheckoutClient = new CompanyPayNewCheckCheckoutClient(SERVICE_URL);
const companyPayCheckCheckoutClient = new CompanyPayCheckCheckoutClient(SERVICE_URL);
const updateAdvanceCheckClient = new UpdateAdvanceCheckClient(SERVICE_URL);
const searchTransactionsCheckoutClient = new SearchTransactionsCheckoutClient(SERVICE_URL);
const getCollectionReceiptClient = new GetCollectionReceiptClient(SERVICE_URL);
const updateCheckoutDescriptionClient = new UpdateCheckoutDescriptionClient(SERVICE_URL);
//#endregion

//#region Checkout Approve
const searchCheckoutApproveClient = new SearchCheckoutApproveClient(SERVICE_URL);
const updateCheckoutApproveConfirmationClient = new UpdateCheckoutApproveConfirmationClient(SERVICE_URL);
//#endregion


export const checkoutActions = {
  //#region Checkout
  checkoutSearchByStaff,
  checkoutSalaryPayment,
  checkoutStaffPayment,
  checkoutDelete,
  checkoutGetId,
  checkoutAccountToAccountTransaction,
  checkoutCreditTransaction,
  checkoutDebitTransaction,
  checkoutExpenseInvoicePayCheck,
  checkoutExpenseInvoicePayMoney,
  checkoutExpenseInvoicePayNewCheck,
  checkoutExpenseInvoiceAdvanceCheck,
  checkoutSaleInvoiceReceiveCheck,
  checkoutSaleInvoiceReceiveMoney,
  checkoutCompanyCollection,
  checkoutCompanyPayment,
  checkoutCompanyPayNewCheck,
  checkoutCompanyPayCheckout,
  checkoutTransactionsSearch,
  checkoutTransactionsExcel,
  checkoutGetCollectionReceipt,
  checkoutUpdateDescription,
  //#endregion  

  //#region Checkout Approve
  checkoutApproveGetAll,
  checkoutApproveConfirm
  //#endregion  
};

//#region Checkout
function checkoutSearchByStaff(params) {
  return dispatch => {
    dispatch(request());

    searchByStaffIdCheckoutClient.search(params)
      .then(
        checkout => dispatch(success(checkout)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: checkoutConstants.CHECKOUT_SEARCHBYSTAFFID_REQUEST } }
  function success(checkout) { return { type: checkoutConstants.CHECKOUT_SEARCHBYSTAFFID_SUCCESS, checkout } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_SEARCHBYSTAFFID_FAILURE, error } }
}

function checkoutSalaryPayment(params) {
  return dispatch => {
    dispatch(request(params));

    salaryPaymentCheckoutClient.create(params)
      .then(
        checkout => dispatch(success(checkout, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_SALARYPAYMENT_REQUEST, params } }
  function success(checkout, params) { return { type: checkoutConstants.CHECKOUT_SALARYPAYMENT_SUCCESS, checkout, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_SALARYPAYMENT_FAILURE, error, params } }
}

function checkoutStaffPayment(params) {
  return dispatch => {
    dispatch(request(params));

    staffPaymentCheckoutClient.create(params)
      .then(
        checkout => dispatch(success(checkout, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_STAFFPAYMENT_REQUEST, params } }
  function success(checkout, params) { return { type: checkoutConstants.CHECKOUT_STAFFPAYMENT_SUCCESS, checkout, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_STAFFPAYMENT_FAILURE, error, params } }
}

function checkoutGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getCheckoutClient.get(id)
      .then(
        checkout => dispatch(success(checkout)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: checkoutConstants.CHECKOUT_GETID_REQUEST, id } }
  function success(checkout) { return { type: checkoutConstants.CHECKOUT_GETID_SUCCESS, checkout } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_GETID_FAILURE, error } }
}

function checkoutDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteCheckoutClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted, params)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_DELETE_REQUEST, params } }
  function success(isDeleted, params) { return { type: checkoutConstants.CHECKOUT_DELETE_SUCCESS, isDeleted, params } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_DELETE_FAILURE, error } }
}

function checkoutAccountToAccountTransaction(params) {
  return dispatch => {
    dispatch(request(params));

    accountToAccountTransactionCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_FAILURE, error, params } }
}

function checkoutCreditTransaction(params) {
  return dispatch => {
    dispatch(request(params));

    creditTransactionCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_CREDITTRANSACTION_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_CREDITTRANSACTION_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_CREDITTRANSACTION_FAILURE, error, params } }
}

function checkoutDebitTransaction(params) {
  return dispatch => {
    dispatch(request(params));

    debitTransactionCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_DEBITTRANSACTION_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_DEBITTRANSACTION_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_DEBITTRANSACTION_FAILURE, error, params } }
}

function checkoutExpenseInvoicePayCheck(params) {
  return dispatch => {
    dispatch(request(params));

    expenseInvoicePayCheckCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYCHECK_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYCHECK_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYCHECK_FAILURE, error, params } }
}

function checkoutExpenseInvoicePayMoney(params) {
  return dispatch => {
    dispatch(request(params));

    expenseInvoicePayMoneyCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYMONEY_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYMONEY_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYMONEY_FAILURE, error, params } }
}

function checkoutExpenseInvoicePayNewCheck(params) {
  return dispatch => {
    dispatch(request(params));

    expenseInvoicePayNewCheckCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_FAILURE, error, params } }
}

function checkoutExpenseInvoiceAdvanceCheck(params) {
  return dispatch => {
    dispatch(request(params));

    updateAdvanceCheckClient.update(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEADVANCECHECK_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEADVANCECHECK_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_EXPENSEINVOICEADVANCECHECK_FAILURE, error, params } }
}

function checkoutSaleInvoiceReceiveCheck(params) {
  return dispatch => {
    dispatch(request(params));

    saleInvoiceReceiveCheckCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_SALEINVOICERECEIVECHECK_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_SALEINVOICERECEIVECHECK_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_SALEINVOICERECEIVECHECK_FAILURE, error, params } }
}

function checkoutSaleInvoiceReceiveMoney(params) {
  return dispatch => {
    dispatch(request(params));

    saleInvoiceReceiveMoneyCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_SALEINVOICERECEIVEMONEY_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_SALEINVOICERECEIVEMONEY_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_SALEINVOICERECEIVEMONEY_FAILURE, error, params } }
}

function checkoutCompanyCollection(params) {
  return dispatch => {
    dispatch(request(params));

    companyCollectionCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_COMPANY_COLLECTION_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_COMPANY_COLLECTION_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_COMPANY_COLLECTION_FAILURE, error, params } }
}

function checkoutCompanyPayment(params) {
  return dispatch => {
    dispatch(request(params));

    companyPaymentCheckoutClient.create(params)
      .then(
        transaction => dispatch(success(transaction, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_COMPANY_PAYMENT_REQUEST, params } }
  function success(transaction, params) { return { type: checkoutConstants.CHECKOUT_COMPANY_PAYMENT_SUCCESS, transaction, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_COMPANY_PAYMENT_FAILURE, error, params } }
}

function checkoutCompanyPayNewCheck(params) {
  return dispatch => {
    dispatch(request(params));

    companyPayNewCheckCheckoutClient.create(params)
      .then(
        companyNewCheck => dispatch(success(companyNewCheck, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_COMPANYPAYNEWCHECK_REQUEST, params } }
  function success(companyNewCheck, params) { return { type: checkoutConstants.CHECKOUT_COMPANYPAYNEWCHECK_SUCCESS, companyNewCheck, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_COMPANYPAYNEWCHECK_FAILURE, error, params } }
}

function checkoutCompanyPayCheckout(params) {
  return dispatch => {
    dispatch(request(params));

    companyPayCheckCheckoutClient.create(params)
      .then(
        companyCheckCheckout => dispatch(success(companyCheckCheckout, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_COMPANYPAYCHECKCHECKOUT_REQUEST, params } }
  function success(companyCheckCheckout, params) { return { type: checkoutConstants.CHECKOUT_COMPANYPAYCHECKCHECKOUT_SUCCESS, companyCheckCheckout, params } }
  function failure(error, params) { return { type: checkoutConstants.CHECKOUT_COMPANYPAYCHECKCHECKOUT_FAILURE, error, params } }
}

function checkoutTransactionsSearch(params) {
  return dispatch => {
    dispatch(request());
    searchTransactionsCheckoutClient.search(params.name, params.beginDate, params.endDate, params.toAccountId, params.fromAccountId, params.minAmount, params.maxAmount, params.checkoutProcessTypeId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        transactions => dispatch(success(transactions)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: checkoutConstants.CHECKOUT_TRANSACTIONS_REQUEST } }
  function success(transactions) { return { type: checkoutConstants.CHECKOUT_TRANSACTIONS_SUCCESS, transactions } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_TRANSACTIONS_FAILURE, error } }
}

function checkoutTransactionsExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchTransactionsCheckoutClient.excel(params.name, params.beginDate, params.endDate, params.toAccountId, params.fromAccountId, params.minAmount, params.maxAmount, params.checkoutProcessTypeId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: checkoutConstants.CHECKOUT_TRANSACTIONS_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: checkoutConstants.CHECKOUT_TRANSACTIONS_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_TRANSACTIONS_EXCEL_FAILURE, error } }
}

function checkoutGetCollectionReceipt(params) {
  return dispatch => {
    dispatch(request(params));

    getCollectionReceiptClient.get(params)
      .then(
        receipt => dispatch(success(receipt)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_GETRECEIPT_REQUEST, params } }
  function success(receipt) { return { type: checkoutConstants.CHECKOUT_GETRECEIPT_SUCCESS, receipt } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_GETRECEIPT_FAILURE, error } }
}

function checkoutUpdateDescription(params) {
  return dispatch => {
    dispatch(request(params));

    updateCheckoutDescriptionClient.update(params)
      .then(
        updateDescription => dispatch(success(updateDescription)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: checkoutConstants.CHECKOUT_UPDATEDESCRIPTION_REQUEST, params } }
  function success(updateDescription) { return { type: checkoutConstants.CHECKOUT_UPDATEDESCRIPTION_SUCCESS, updateDescription } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_UPDATEDESCRIPTION_FAILURE, error } }
}
//#endregion

//#region Checkout Approve
function checkoutApproveGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchCheckoutApproveClient.search(params.checkoutApproveStateId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        checkoutApprove => dispatch(success(checkoutApprove)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: checkoutConstants.CHECKOUT_APPROVEGETALL_REQUEST } }
  function success(checkoutApprove) { return { type: checkoutConstants.CHECKOUT_APPROVEGETALL_SUCCESS, checkoutApprove } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_APPROVEGETALL_FAILURE, error } }
}

function checkoutApproveConfirm(params) {
  return dispatch => {
    dispatch(request());

    updateCheckoutApproveConfirmationClient.update(params)
      .then(
        checkoutApproveConfirm => dispatch(success(checkoutApproveConfirm)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: checkoutConstants.CHECKOUT_APPROVECONFIRM_REQUEST } }
  function success(checkoutApproveConfirm) { return { type: checkoutConstants.CHECKOUT_APPROVECONFIRM_SUCCESS, checkoutApproveConfirm } }
  function failure(error) { return { type: checkoutConstants.CHECKOUT_APPROVECONFIRM_FAILURE, error } }
}
//#endregion
