/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import "flatpickr/dist/flatpickr.css";
import { KTCard, KTCardBody, Select } from '../../../components';
import { PageTitle } from '../../../core';
import { useDispatch, useSelector } from 'react-redux';
import { userTaskActions, lookupActions } from '../../../redux/actions';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import { KTSVG } from '../../../helpers';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')
  const { allUserLookup } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.userTask);
  const dispatch = useDispatch();

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    UserId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    TaskDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  useEffect(() => {
    dispatch(lookupActions.allUserLookup());
  }, [])

  const [formValues] = useState({
    Name: '',
    UserId: '',
    TaskDate: new Date()
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(userTaskActions.userTaskCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.USERTASK' }),
      path: '/user/task',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.USERTASK.ADD' })}</PageTitle>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <KTCard>
            <KTCardBody>
              <form className='form' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USERTASK.CREATE.NAME' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      required
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('Name')}
                    />
                    {formik.touched.Name && formik.errors.Name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.Name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USERTASK.CREATE.USERID' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <Select
                      data={allUserLookup}
                      required
                      {...formik.getFieldProps('UserId')}
                      onChange={item => formik.setFieldValue('UserId', item.value)}
                    />
                    {formik.touched.UserId && formik.errors.UserId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.UserId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USERTASK.CREATE.TASKDATE' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        {...formik.getFieldProps('TaskDate')}
                        onChange={([date]) => formik.setFieldValue('TaskDate', date)}
                      />
                    </div>
                  </div>
                </div>
                
                <div className='row pt-15'>
                  <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                    <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-userTasks-modal-action='submit'
                      disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                      <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                      {(formik.isSubmitting) && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({ id: 'PLEASE' })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
