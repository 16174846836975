import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../components'
import { CustomCell } from '../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../components/Table/columns/CustomHeader'
import { CustomActionCell } from '../../components/Table/columns/CustomActionCell'
import { ListHeader } from '../../components/Table/header/ListHeader';
import { ListLoading } from '../../components/Table/ListLoading'
import { PageTitle } from '../../core';

import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../redux/actions';

function Notification() {
  const intl = useIntl()
  const {notification, complete, loading} = useSelector(state => state.notification);
	const dispatch = useDispatch();

  const [ params, setParams ] = useState()

  useEffect(() => {
    if (params) dispatch(notificationActions.notificationGetAll(params));
  }, [params, complete])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'NOTIFICATION.SEARCH.CONTENT' })} className='min-w-125px' />,
      accessor: 'content',
      Cell: ({...props}) => <CustomCell item={props} to={`/${props.data[props.row.index].sourcePath}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'NOTIFICATION.SEARCH.NOTIFICATIONID' })} className='min-w-125px' />,
      accessor: 'notificationTypeId',
      Cell: ({...props}) => <CustomCell item={props} to={`/${props.data[props.row.index].sourcePath}`} notificationType />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} title={intl.formatMessage({ id: 'NOTIFICATION.SEARCH.ISCOMPLETED' })} className='min-w-125px' />,
      accessor: 'isCompleted',
      Cell: ({...props}) => <CustomCell item={props} to={`/${props.data[props.row.index].sourcePath}`} />,
    },
    {
      Header: () => <></>,
      id: 'actions',
      Cell: ({...props}) => <CustomActionCell id={props.data[props.row.index].id} title={intl.formatMessage({ id: 'NOTIFICATION.SEARCH.BUTTON' })} onClick={(id) => dispatch(notificationActions.notificationComplete({id}))} />,
    }
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(notificationActions.notificationExcel(params, intl.formatMessage({ id: 'BC.NOTIFICATION' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'NOTIFICATION.SEARCH.CONTENT',
          type: 'text',
          name: 'content'
        },
        {
          title: 'NOTIFICATION.SEARCH.ISCOMPLETED',
          data: [            
            {
              id: 'true',
              name: intl.formatMessage({ id: 'NOTIFICATION.SEARCH.ISCOMPLETED.TRUE' })
            },
            {
              id: 'false',
              name: intl.formatMessage({ id: 'NOTIFICATION.SEARCH.ISCOMPLETED.FALSE' })
            },
          ],
          name: 'isCompleted'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.NOTIFICATION' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'NOTIFICATION'}
        />
        <KTCardBody>
          {!notification && <ListLoading /> }
          {notification && <Table 
                      items={notification} 
                      cols={Columns} 
                      handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))} 
                      paddless 
                    /> }
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Notification};
