import { bankPaymentOrderConstants } from '../constants';
import {
  //#region BankPaymentOrder
  SearchBankPaymentOrderClient,
  CreateBankPaymentOrderClient,
  GetBankPaymentOrderClient,
  DeleteBankPaymentOrderClient,
  UpdateBankPaymentOrderClient,
  GetAvailablePaymentsClient,
  GetExcelReportClient,
  UpdateSendToConfirmationClient,
  UpdateBankPaymentOrderConfirmationClient,
  CompleteBankOrderClient
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region BankPaymentOrder
const searchBankPaymentOrderClient = new SearchBankPaymentOrderClient(SERVICE_URL);
const createBankPaymentOrderClient = new CreateBankPaymentOrderClient(SERVICE_URL);
const getBankPaymentOrderClient = new GetBankPaymentOrderClient(SERVICE_URL);
const deleteBankPaymentOrderClient = new DeleteBankPaymentOrderClient(SERVICE_URL);
const updateBankPaymentOrderClient = new UpdateBankPaymentOrderClient(SERVICE_URL);
const getAvailablePaymentsClient = new GetAvailablePaymentsClient(SERVICE_URL);
const getExcelReportClient = new GetExcelReportClient(SERVICE_URL);
const updateSendToConfirmationClient = new UpdateSendToConfirmationClient(SERVICE_URL);
const updateBankPaymentOrderConfirmationClient = new UpdateBankPaymentOrderConfirmationClient(SERVICE_URL);
const completeBankOrderClient = new CompleteBankOrderClient(SERVICE_URL);
//#endregion

export const bankPaymentOrderActions = {
  //#region BankPaymentOrder
  bankPaymentOrderGetAll,
  bankPaymentOrderCreate,
  bankPaymentOrderGetId,
  bankPaymentOrderDelete,
  bankPaymentOrderUpdate,
  bankPaymentOrderGetAvailablePayments,
  bankPaymentOrderExcel,
  bankPaymentOrderSendToConfirmation,
  bankPaymentOrderConfirm,
  bankPaymentOrderCompleteBankOrder
  //#endregion 
};

//#region BankPaymentOrder
function bankPaymentOrderGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchBankPaymentOrderClient.search(params.name, params.beginDate, params.endDate, params.bankPaymentOrderStateId, params.items_Per_Page, params.page, params.order, params.sort)
      .then(
        bankPaymentOrder => dispatch(success(bankPaymentOrder)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETALL_REQUEST } }
  function success(bankPaymentOrder) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETALL_SUCCESS, bankPaymentOrder } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETALL_FAILURE, error } }
}

function bankPaymentOrderCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createBankPaymentOrderClient.create(params)
      .then(
        bankPaymentOrder => dispatch(success(bankPaymentOrder)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_CREATE_REQUEST, params } }
  function success(bankPaymentOrder) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_CREATE_SUCCESS, bankPaymentOrder } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_CREATE_FAILURE, error } }
}

function bankPaymentOrderGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getBankPaymentOrderClient.get(id)
      .then(
        bankPaymentOrder => dispatch(success(bankPaymentOrder)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETID_REQUEST, id } }
  function success(bankPaymentOrder) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETID_SUCCESS, bankPaymentOrder } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETID_FAILURE, error } }
}

function bankPaymentOrderDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteBankPaymentOrderClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_DELETE_FAILURE, error } }
}

function bankPaymentOrderUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateBankPaymentOrderClient.update(params)
      .then(
        bankPaymentOrder => dispatch(success(bankPaymentOrder, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_UPDATE_REQUEST, params } }
  function success(bankPaymentOrder, params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_UPDATE_SUCCESS, bankPaymentOrder, params } }
  function failure(error, params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_UPDATE_FAILURE, error, params } }
}

function bankPaymentOrderGetAvailablePayments() {
  return dispatch => {
    dispatch(request());

    getAvailablePaymentsClient.search({})
      .then(
        getAvailablePayments => dispatch(success(getAvailablePayments)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_REQUEST } }
  function success(getAvailablePayments) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_SUCCESS, getAvailablePayments } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_GETAVAIBLEPAYMENTS_FAILURE, error } }
}

function bankPaymentOrderExcel(id, fileName) {
  return dispatch => {
    dispatch(request(id));

    getExcelReportClient.get(id)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_EXCEL_FAILURE, error } }
}

function bankPaymentOrderSendToConfirmation(params) {
  return dispatch => {
    dispatch(request(params));

    updateSendToConfirmationClient.update(params)
      .then(
        sendToConfirmation => dispatch(success(sendToConfirmation)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_SENDTOCONFIRMATION_REQUEST, params } }
  function success(sendToConfirmation) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_SENDTOCONFIRMATION_SUCCESS, sendToConfirmation } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_SENDTOCONFIRMATION_FAILURE, error } }
}

function bankPaymentOrderConfirm(params) {
  return dispatch => {
    dispatch(request(params));

    updateBankPaymentOrderConfirmationClient.update(params)
      .then(
        confirm => dispatch(success(confirm)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_CONFIRM_REQUEST, params } }
  function success(confirm) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_CONFIRM_SUCCESS, confirm } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_CONFIRM_FAILURE, error } }
}
function bankPaymentOrderCompleteBankOrder(params) {
  return dispatch => {
    dispatch(request(params));

    completeBankOrderClient.update(params)
      .then(
        completeBankOrder => dispatch(success(completeBankOrder)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_COMPLETEBANKORDER_REQUEST, params } }
  function success(completeBankOrder) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_COMPLETEBANKORDER_SUCCESS, completeBankOrder } }
  function failure(error) { return { type: bankPaymentOrderConstants.BANKPAYMENTORDER_COMPLETEBANKORDER_FAILURE, error } }
}
//#endregion
