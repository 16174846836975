/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { KTSVG, SourceType } from '../../../helpers'

import { KTCard, KTCardBody, DeleteModal, Timeline, Notes } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions } from '../../../redux/actions';
import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, error, loading } = useSelector(state => state.staff);
	const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')
  
  useEffect(() => {
    dispatch(staffActions.overtimeGetId(id));
  }, [])

  const handleDelete = () => dispatch(staffActions.overtimeDelete({id}));

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [    
    {
      title: intl.formatMessage({ id: 'BC.OVERTIME' }),
      path: '/staff/overtime',
      isSeparator: false,
      isActive: false,
    }
  ]


  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.OVERTIME.DETAIL' })}</PageTitle>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.overtimeTypeName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/general/gen044.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.overtimeTypeName}
                            </div>
                          </>}


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>                        
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">                            
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>		  
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'STAFF_NAME' })}</div>
                          <div className="fw-bold fs-4">{detail.staffNameSurname}</div>
                        </div>
                        {detail?.startDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'OVERTIME.DATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.startDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'OVERTIME.TIME' })}</div>
                          <div className="fw-bold fs-4">{detail.hourDuration} {intl.formatMessage({ id: 'OVERTIME.TIMEINFO' })}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>           
          </>}         
        </div>
        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Overtime} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.Overtime} collapse={false} />
        </div>
      </div>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}
export {Detail};
