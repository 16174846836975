/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { staffActions, lookupActions } from '../../../redux/actions';

import { Years } from '../../../helpers';

function List() {
  const intl = useIntl()
  const {monthlySalary, loading} = useSelector(state => state.staff);
  const { projectLookup, Month } = useSelector(state => state.lookup);
	const dispatch = useDispatch();

  const [ params, setParams ] = useState()

  useEffect(() => {
    if (params) dispatch(staffActions.monthlySalaryGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('Month'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.SEARCH.PROJECTNAME' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props} to={`/staff/monthly-salary/detail?projectId=${props.data[props.row.index].projectId}&year=${props.data[props.row.index].year}&month=${props.data[props.row.index].month}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.SEARCH.MONTHNAME' })} className='min-w-125px' />,
      accessor: 'monthName',
      Cell: ({...props}) => <CustomCell item={props} to={`/staff/monthly-salary/detail?projectId=${props.data[props.row.index].projectId}&year=${props.data[props.row.index].year}&month=${props.data[props.row.index].month}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.SEARCH.YEAR' })} className='min-w-125px' />,
      accessor: 'year',
      Cell: ({...props}) => <CustomCell item={props} to={`/staff/monthly-salary/detail?projectId=${props.data[props.row.index].projectId}&year=${props.data[props.row.index].year}&month=${props.data[props.row.index].month}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.SEARCH.PAYMENT' })} className='min-w-125px' />,
      accessor: 'payment',
      Cell: ({...props}) => <CustomCell item={props} price to={`/staff/monthly-salary/detail?projectId=${props.data[props.row.index].projectId}&year=${props.data[props.row.index].year}&month=${props.data[props.row.index].month}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.SEARCH.BALANCE' })} className='min-w-125px' />,
      accessor: 'balance',
      Cell: ({...props}) => <CustomCell item={props} price to={`/staff/monthly-salary/detail?projectId=${props.data[props.row.index].projectId}&year=${props.data[props.row.index].year}&month=${props.data[props.row.index].month}`} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'STAFF.MONTHLY_SALERY.SEARCH.BESPAYMENT' })} className='min-w-125px' />,
      accessor: 'besPayment',
      Cell: ({...props}) => <CustomCell item={props} price to={`/staff/monthly-salary/detail?projectId=${props.data[props.row.index].projectId}&year=${props.data[props.row.index].year}&month=${props.data[props.row.index].month}`} />,
    },
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(staffActions.monthlySalaryExcel(params, intl.formatMessage({ id: 'BC.MONTHLY_SALARY' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'STAFF.MONTHLY_SALERY.SEARCH.PROJECTNAME',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'STAFF.MONTHLY_SALERY.SEARCH.MONTHNAME',
          data: Month,
          name: 'month'
        },
        {
          title: 'STAFF.MONTHLY_SALERY.SEARCH.YEAR',
          data: Years,
          name: 'year'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.MONTHLY_SALARY' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({...prevState, name: item }))}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'MONTHLY_SALARY'}
        />
        <KTCardBody>
          {!monthlySalary && <ListLoading /> }
          {monthlySalary &&  <Table 
                        items={monthlySalary} 
                        cols={Columns} 
                        handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))} 
                        paddless 
                      /> }
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {List};
