/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl'
import { KTSVG } from '../../helpers';
import clsx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../redux/actions';

const Activity = ({isShow, onClickClose}) => {
  const intl = useIntl()

  const { activity } = useSelector(state => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isShow) dispatch(commonActions.activityGetAll({}));
  }, [isShow])

  return (
    <div
      id='kt_activities'
      className={clsx('bg-white', {'drawer drawer-end drawer-on': isShow && activity?.length > 0})}
      data-kt-drawer='true'
      data-kt-drawer-name='activities'
      data-kt-drawer-activate='false'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_activities_toggle'
      data-kt-drawer-close='#kt_activities_close'
    >
      <div className='card shadow-none rounded-0'>
        <div className='card-header' id='kt_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>{intl.formatMessage({ id: 'ACTIVITY.TITLE' })}</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_activities_close'
              onClick={() => onClickClose && onClickClose()}
            >
              <KTSVG path='/images/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>
          </div>
        </div>
        <div className='card-body position-relative' id='kt_activities_body'>
          <div
            id='kt_activities_scroll'
            className='position-relative scroll-y me-n5 pe-5'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_activities_body'
            data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
            data-kt-scroll-offset='5px'
          >
            <div className='timeline'>
              {
                activity?.map((item, index) => {
                  return (
                    <div className='timeline-item' key={index}>
                      <div className='timeline-line w-40px'></div>

                      <div className='timeline-icon symbol symbol-circle symbol-40px'>
                        <div className='symbol-label bg-light'>
                          <KTSVG
                            path='/images/icons/duotune/communication/com009.svg'
                            className='svg-icon-2 svg-icon-gray-500'
                          />
                        </div>
                      </div>

                      <div className='timeline-content mb-10 mt-n2'>
                      <Link to={`/${item?.sourceTypeLink}`} className='btn btn-link'>
                        <div className='overflow-auto pe-3'>
                          
                            <div className='fs-5 fw-bold mb-2'>
                              {item?.name}
                            </div>
                         
                          <div className='d-flex align-items-center mt-1 fs-6'>
                            <div className='text-muted me-2 fs-7'>{intl.formatDate(new Date(item?.createdDate), { year: 'numeric', month: 'long', day: 'numeric',  hour: "numeric", minute: "numeric" })}</div>
                            <span className="badge badge-light-primary">{item?.sourceTypeName}</span>
                          </div>
                        </div>
                        </Link>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Activity.propTypes = {
	isShow: PropTypes.bool
};

Activity.defaultProps = {
  isShow: false
};

export {Activity}
