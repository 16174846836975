export const PurchaseRequestStatus = [
  {
    id: 1,
    name: 'PURCHASEREQUESTSTATUS.ONHOLD'
  },
  {
    id: 2,
    name: 'PURCHASEREQUESTSTATUS.WAITINGFORANSWER'
  },
  {
    id: 3,
    name: 'PURCHASEREQUESTSTATUS.SENT'
  },
  {
    id: 4,
    name: 'PURCHASEREQUESTSTATUS.DENIED'
  },
  {
    id: 5,
    name: 'PURCHASEREQUESTSTATUS.ORDER'
  },
  {
    id: 6,
    name: 'PURCHASEREQUESTSTATUS.NEGATIVE'
  }
]
