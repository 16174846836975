/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import clsx from 'clsx'

const KTCardHeader = (props) => {
  const {className, title, children, onCollapse, collapsed} = props;
  const [isCollapse, setIsCollapse] = useState(collapsed)

  const handleClick  = () => {
    setIsCollapse(!isCollapse)
    onCollapse && onCollapse(!isCollapse)
  }

  return (
    <div
      className={clsx('card-header', className, {'collapsed': isCollapse || collapsed, 'cursor-pointer': onCollapse})}
      onClick={handleClick}
    >
      <div className="card-title m-0">
        <h3 className="fw-bolder m-0">{title}</h3>
      </div>
      {children}
    </div>
  )
}

export {KTCardHeader}
