import { saleinvoiceConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  isSent: false,
  documentdata: null,
  excel: null
}

export function saleinvoice(state = {}, action) {
  switch (action.type) {
    //#region SaleInvoice
    case saleinvoiceConstants.SALEINVOICE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case saleinvoiceConstants.SALEINVOICE_GETALL_SUCCESS:
      return {
        ...state,
        saleinvoice: action.saleinvoice,
        loading: false
      };
    case saleinvoiceConstants.SALEINVOICE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case saleinvoiceConstants.SALEINVOICE_GETID_REQUEST:
      return { ...state, ...initialState };
    case saleinvoiceConstants.SALEINVOICE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.saleinvoice,
        loading: false
      };
    case saleinvoiceConstants.SALEINVOICE_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case saleinvoiceConstants.SALEINVOICE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case saleinvoiceConstants.SALEINVOICE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case saleinvoiceConstants.SALEINVOICE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case saleinvoiceConstants.SALEINVOICE_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case saleinvoiceConstants.SALEINVOICE_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case saleinvoiceConstants.SALEINVOICE_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case saleinvoiceConstants.SALEINVOICE_CREATE_REQUEST:
      return { ...state, ...initialState };
    case saleinvoiceConstants.SALEINVOICE_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.saleinvoice,
        loading: false
      };
    case saleinvoiceConstants.SALEINVOICE_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case saleinvoiceConstants.SALEINVOICE_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case saleinvoiceConstants.SALEINVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.saleinvoice || action.params,
        detail: action.params,
        loading: false
      };
    case saleinvoiceConstants.SALEINVOICE_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case saleinvoiceConstants.SALEINVOICE_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case saleinvoiceConstants.SALEINVOICE_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case saleinvoiceConstants.SALEINVOICE_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    //#endregion    
    default:
      return state
  }
}
