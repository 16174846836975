/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../helpers'
import clsx from 'clsx';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {useDispatch, useSelector} from 'react-redux'
import {notificationActions} from '../../redux/actions'

const Notification = ({isShow}) => {
  const intl = useIntl()

  const {notificationDashboard} = useSelector((state) => state.notification)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isShow) dispatch(notificationActions.notificationGetDashboard({}))
  }, [isShow])

  return (
    <div
      className={clsx('menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px', {'show': isShow})}
      data-kt-menu='true'
      id='kt_menu_notifications'
      style={{zIndex: 105, position: 'absolute', margin: 0, transform: 'translate3d(-50px, 120px, 0px)', right: '0', backgroundImage: "url('/images/notification/menu-header-bg.jpeg')"}}
    >
      <div className='d-flex flex-column bgi-no-repeat rounded-top'>
        <h3 className='text-white fw-semibold px-9 mt-10 mb-6 d-flex gap-2'>
          {intl.formatMessage({ id: 'NOTIFICATION' })} <span className='badge badge-light fs-8'>{notificationDashboard?.totalNotificationCount || 0}</span>
        </h3>
      </div>

      <Tabs className="nav-line-tabs px-8" fill>
        <Tab eventKey="success" tabClassName='text-white' className='bg-white' title={<>{intl.formatMessage({ id: 'NOTIFICATION.TABS.SUCCESS' })} <span className='badge badge-light fs-8'>{notificationDashboard?.successCount || 0}</span></>}>
          <div className='scroll-y mh-325px px-8'>
            {notificationDashboard?.successList?.length == 0 && <div className='fs-3 text-gray-800 fw-bold p-2 text-center'>{intl.formatMessage({ id: 'NOTIFICATION.TABS.NONE' })}</div>}
            { notificationDashboard?.successList?.map((item, index) => {
              return (
                <div className='d-flex flex-stack py-4' key={index}>
                  <div className='d-flex align-items-center'>
                    <div className='mb-0 me-2'>
                      {
                        item?.sourcePath ? 
                        <>
                          <Link to={`/${item?.sourcePath}`} className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                            {item?.content}
                          </Link>
                        </> : 
                        <>
                          <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                            {item?.content}
                          </div>
                        </>
                      }
                      
                    </div>
                  </div>

                  {item?.notificationDate && <span className='badge badge-light fs-8'>{intl.formatDate(new Date(item?.notificationDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span>}
                </div>
              )
            })}
            
          </div>

          <div className='py-3 text-center border-top'>
            <Link to={'/notification'}>{intl.formatMessage({ id: 'NOTIFICATION.ALL' })} <KTSVG path='/images/icons/duotune/arrows/arr071.svg' className='svg-icon-2' /></Link>
          </div>
        </Tab>

        <Tab eventKey="warning" tabClassName='text-white' className='bg-white' title={<>{intl.formatMessage({ id: 'NOTIFICATION.TABS.WARNING' })} <span className='badge badge-light fs-8'>{notificationDashboard?.warningCount || 0}</span></>}>
          <div className='scroll-y mh-325px px-8'>
            {notificationDashboard?.warningList?.length == 0 && <div className='fs-3 text-gray-800 fw-bold p-2 text-center'>{intl.formatMessage({ id: 'NOTIFICATION.TABS.NONE' })}</div>}
            { notificationDashboard?.warningList?.map((item, index) => {
              return (
                <div className='d-flex flex-stack py-4' key={index}>
                  <div className='d-flex align-items-center'>
                    <div className='mb-0 me-2'>
                      {
                        item?.sourcePath ? 
                        <>
                          <Link to={`/${item?.sourcePath}`} className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                            {item?.content}
                          </Link>
                        </> : 
                        <>
                          <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                            {item?.content}
                          </div>
                        </>
                      }
                    </div>
                  </div>

                  {item?.notificationDate && <span className='badge badge-light fs-8'>{intl.formatDate(new Date(item?.notificationDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span>}
                </div>
              )
            })}
            
          </div>

          <div className='py-3 text-center border-top'>
            <Link to={'/notification'}>{intl.formatMessage({ id: 'NOTIFICATION.ALL' })} <KTSVG path='/images/icons/duotune/arrows/arr071.svg' className='svg-icon-2' /></Link>
          </div>
        </Tab>

        <Tab eventKey="alert" tabClassName='text-white' className='bg-white' title={<>{intl.formatMessage({ id: 'NOTIFICATION.TABS.ALERT' })} <span className='badge badge-light fs-8'>{notificationDashboard?.alertCount || 0}</span></>}>
          <div className='scroll-y mh-325px px-8'>
            {notificationDashboard?.alertList?.length == 0 && <div className='fs-3 text-gray-800 fw-bold p-2 text-center'>{intl.formatMessage({ id: 'NOTIFICATION.TABS.NONE' })}</div>}
            { notificationDashboard?.alertList?.map((item, index) => {
              return (
                <div className='d-flex flex-stack py-4' key={index}>
                  <div className='d-flex align-items-center'>
                    <div className='mb-0 me-2'>
                      {
                        item?.sourcePath ? 
                        <>
                          <Link to={`/${item?.sourcePath}`} className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                            {item?.content}
                          </Link>
                        </> : 
                        <>
                          <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                            {item?.content}
                          </div>
                        </>
                      }
                    </div>
                  </div>

                  {item?.notificationDate && <span className='badge badge-light fs-8'>{intl.formatDate(new Date(item?.notificationDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span>}
                </div>
              )
            })}
            
          </div>

          <div className='py-3 text-center border-top'>
            <Link to={'/notification'}>{intl.formatMessage({ id: 'NOTIFICATION.ALL' })} <KTSVG path='/images/icons/duotune/arrows/arr071.svg' className='svg-icon-2' /></Link>
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}

Notification.propTypes = {
  isShow: PropTypes.bool,
}

Notification.defaultProps = {
  isShow: false,
}

export {Notification}
