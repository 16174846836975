/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, DeleteModal, DataTables, Category, PaymentRequestConfirmation } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { paymentRequestActions } from '../../../redux/actions';
import { SourceType, KTSVG, detailPageUrl, DetailPage } from '../../../helpers';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Dropdown from 'react-bootstrap/Dropdown';

import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

function Detail() {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState('confirm');
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { detail, isDeleted, error, onConfirmationState, loading, updateConfirmation } = useSelector(state => state.paymentRequest);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const validSchema = Yup.object().shape({
    CompanyBalance: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ApprovedPayment: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ApprovedDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: id,
    ApprovedDate: new Date(),
    CompanyBalance: 0,
    ApprovedPayment: 0,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      if (activeTab === 'onConfirmationState') {
        dispatch(paymentRequestActions.paymentRequestUpdateOnConfirmationState(values));
      } 
    }
  })

  useEffect(() => {
    dispatch(paymentRequestActions.paymentRequestGetId(id));
  }, [id, onConfirmationState, updateConfirmation])

  const handleDelete = () => dispatch(paymentRequestActions.paymentRequestDelete({ id }));

  useEffect(() => {
    if (detail) {
      if (detail?.canUserUpdateOnConfirmation) setActiveTab('onConfirmationState')

      formik.setFieldValue('ApprovedDate', detail?.approvedDate ? new Date(detail?.approvedDate) : new Date())
      formik.setFieldValue('CompanyBalance', detail?.companyBalance)
      formik.setFieldValue('ApprovedPayment', detail?.approvedPayment)
    }
  }, [detail])

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.PAYMENTREQUEST' }),
      path: '/expenses/payment-request',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.PAYMENTREQUEST.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 gap-4">

                          <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                            <KTSVG
                              path='/images/icons/nav/Projects.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail?.projectName}
                          </Link>

                          {detail?.requestedUserNameSurname && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <Link to={detailPageUrl(DetailPage.Staff, detail?.requestedUserId)} className='text-gray-400 text-hover-primary' target='_blank'>
                                <KTSVG
                                  path='/images/icons/nav/Staff.svg'
                                  className='svg-icon svg-icon-2 fs-4 me-1'
                                />
                                {detail?.requestedUserNameSurname}
                              </Link>                            
                            </div>
                          </>}

                          {detail?.paymentRequestTypeName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <div className='text-gray-400' >
                                <KTSVG
                                  path='/images/icons/nav/Staff.svg'
                                  className='svg-icon svg-icon-2 fs-4 me-1'
                                />
                                {detail?.paymentRequestTypeName}
                              </div>                            
                            </div>
                          </>}

                          {detail?.paymentRequestStateName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <div className='text-gray-400' >
                                <KTSVG
                                  path='/images/icons/nav/Staff.svg'
                                  className='svg-icon svg-icon-2 fs-4 me-1'
                                />
                                {detail?.paymentRequestStateName}
                              </div>                            
                            </div>
                          </>}

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.PaymentRequest} />

                        {detail?.canUserUpdate && <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>}

                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.REQUESTEDPAYMENT' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail?.requestedPayment, { style: 'currency', currency: 'try' })}</span></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.APPROVEDPAYMENT' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail?.approvedPayment, { style: 'currency', currency: 'try' })}</span></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.COMPANYBALANCE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail?.companyBalance, { style: 'currency', currency: 'try' })}</span></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.REQUESTEDATE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{detail?.requestedDate ? intl.formatDate(new Date(detail?.requestedDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</span></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.APPROVEDDATE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{detail?.approvedDate ? intl.formatDate(new Date(detail?.approvedDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</span></div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>

                {detail?.companyTitle && <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                  {detail?.companyTitle}
                </div>}
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.COMPANYID' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.companyName}</span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.EXPENSEINVOICEID' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.expenseInvoiceName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.NAME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.name}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.IDENTITYNUMBER' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.identityNumber}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.TAXNUMBER' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.taxNumber}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.IBAN' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.iban}</span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.FIRSTAPPROVEDUSERNAMESURNAME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6"><Link to={detailPageUrl(DetailPage.Staff, detail?.firstApprovedUserId)} className='text-gray-400 text-hover-primary' target='_blank'>{detail.firstApprovedUserNameSurname}</Link></span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.SECONDAPPROVEDUSERNAMESURNAME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6"><Link to={detailPageUrl(DetailPage.Staff, detail?.secondApprovedUserId)} className='text-gray-400 text-hover-primary' target='_blank'>{detail.secondApprovedUserNameSurname}</Link></span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.THIRDAPPROVEDUSERNAMESURNAME' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6"><Link to={detailPageUrl(DetailPage.Staff, detail?.thirdApprovedUserId)} className='text-gray-400 text-hover-primary' target='_blank'>{detail.thirdApprovedUserNameSurname}</Link></span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.FIRSTAPPROVEDDATE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail?.firstApprovedDate ? intl.formatDate(new Date(detail?.firstApprovedDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.SECONDAPPROVEDDATE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail?.secondApprovedDate ? intl.formatDate(new Date(detail?.secondApprovedDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.THIRDAPPROVEDDATE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail?.thirdApprovedDate ? intl.formatDate(new Date(detail?.thirdApprovedDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}</span></div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  {detail?.paymentRequestConfirmations?.length > 0 && <Tab eventKey="paymentRequestConfirmations" title={intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS.REQUESTEDUSERNAMESURNAME' }),
                          prop: 'requestedUserNameSurname',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS.PAYMENTREQUESTCONFIRMATIONLEVELNAME' }),
                          prop: 'paymentRequestConfirmationLevelName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS.PAYMENTREQUESTCONFIRMATIONSTATENAME' }),
                          prop: 'paymentRequestConfirmationStateName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS.APPROVEUSERNAMESURNAME' }),
                          prop: 'approveUserNameSurname',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS.COMPLETIONDATE' }),
                          prop: 'completionDate',
                          cell: (row) => row?.completionDate ? intl.formatDate(new Date(row?.completionDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS.PAYMENTREQUESTDESCRIPTION' }),
                          prop: 'paymentRequestDescription',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.paymentRequestConfirmations}
                      exportTitle={intl.formatMessage({ id: 'PAYMENTREQUEST.GET.PAYMENTREQUESTCONFIRMATIONS' })}
                    />
                  </Tab>}

                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>
          <KTCard className='mb-5'>
            <KTCardBody className='p-3'>
              <div className='position-relative mb-6'>
                <Tabs
                  defaultActiveKey={activeTab}
                  fill
                  onSelect={(active) => setActiveTab(active)}
                  className="nav-line-tabs"
                >
                  {detail?.canUserUpdateOnConfirmation && <Tab eventKey="onConfirmationState" title={intl.formatMessage({ id: 'PAYMENTREQUEST.GET.UPDATEONCONFIRMATIONSTATE' })} className='p-5'>
                    <form className='form' onSubmit={formik.handleSubmit} noValidate>
                      <div className='row mb-6'>
                        <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.UPDATEONCONFIRMATIONSTATE.APPROVEDDATE' })}</label>
                        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                          <div className="position-relative d-flex align-items-center">
                            <KTSVG
                              path='/images/icons/duotune/general/gen014.svg'
                              className='svg-icon svg-icon-2 position-absolute mx-4'
                            />
                            <Flatpickr
                              className="form-control form-control-solid ps-12"
                              options={{ "locale": Turkish }}
                              {...formik.getFieldProps('ApprovedDate')}
                              onChange={([date]) => formik.setFieldValue('ApprovedDate', date)}
                            />
                          </div>
                          {formik.touched.ApprovedDate && formik.errors.ApprovedDate && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.ApprovedDate}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.UPDATEONCONFIRMATIONSTATE.APPROVEDPAYMENT' })}</label>
                        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                          <input
                            type='number'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            autoComplete='off'
                            disabled={formik.isSubmitting}
                            {...formik.getFieldProps('ApprovedPayment')}
                            onFocus={event => event.target.select()}
                          />
                          {formik.touched.ApprovedPayment && formik.errors.ApprovedPayment && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.ApprovedPayment}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.UPDATEONCONFIRMATIONSTATE.COMPANYBALANCE' })}</label>
                        <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                          <input
                            type='number'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            autoComplete='off'
                            disabled={formik.isSubmitting}
                            {...formik.getFieldProps('CompanyBalance')}
                            onFocus={event => event.target.select()}
                          />
                          {formik.touched.CompanyBalance && formik.errors.CompanyBalance && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.CompanyBalance}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='text-center'>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          data-kt-users-modal-action='submit'
                          disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                        >
                          <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                          {(formik.isSubmitting) && (
                            <span className='indicator-progress'>
                              {intl.formatMessage({ id: 'PLEASE' })}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  </Tab>}

                  <Tab eventKey="confirm" title={intl.formatMessage({ id: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE' })} className='p-5'>
                    <PaymentRequestConfirmation PaymentRequestId={id} />
                  </Tab>
                 
                </Tabs>
              </div>
            </KTCardBody>
          </KTCard>

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.PaymentRequest} />
        </div>

      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
