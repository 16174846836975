export const userTaskConstants = {
  
  USERTASK_GETALL_REQUEST: 'USERTASK_GETALL_REQUEST',
  USERTASK_GETALL_SUCCESS: 'USERTASK_GETALL_SUCCESS',
  USERTASK_GETALL_FAILURE: 'USERTASK_GETALL_FAILURE',

  USERTASK_GETID_REQUEST: 'USERTASK_GETID_REQUEST',
  USERTASK_GETID_SUCCESS: 'USERTASK_GETID_SUCCESS',
  USERTASK_GETID_FAILURE: 'USERTASK_GETID_FAILURE',

  USERTASK_DELETE_REQUEST: 'USERTASK_DELETE_REQUEST',
  USERTASK_DELETE_SUCCESS: 'USERTASK_DELETE_SUCCESS',
  USERTASK_DELETE_FAILURE: 'USERTASK_DELETE_FAILURE',

  USERTASK_CREATE_REQUEST: 'USERTASK_CREATE_REQUEST',
  USERTASK_CREATE_SUCCESS: 'USERTASK_CREATE_SUCCESS',
  USERTASK_CREATE_FAILURE: 'USERTASK_CREATE_FAILURE',

  USERTASK_UPDATE_REQUEST: 'USERTASK_UPDATE_REQUEST',
  USERTASK_UPDATE_SUCCESS: 'USERTASK_UPDATE_SUCCESS',
  USERTASK_UPDATE_FAILURE: 'USERTASK_UPDATE_FAILURE',

  USERTASK_COMPLETE_REQUEST: 'USERTASK_COMPLETE_REQUEST',
  USERTASK_COMPLETE_SUCCESS: 'USERTASK_COMPLETE_SUCCESS',
  USERTASK_COMPLETE_FAILURE: 'USERTASK_COMPLETE_FAILURE',
};
