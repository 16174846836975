/* eslint-disable react/react-in-jsx-scope */
import {KTSVG} from '../../helpers'
import {Search} from './Search'

const Topbar = ({ handleClickNotification }) => {
  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      <Search
        className='w-lg-250px'
        mobileToggleBtnClass='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px'
      />

      <div className='d-flex align-items-center ms-3 ms-lg-4'>
        <div 
          className='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
          onClick={handleClickNotification}
        >
          <KTSVG path='/images/icons/duotune/communication/com003.svg' className='svg-icon-1' />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div>      
    </div>
  )
}

export {Topbar}
