/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, DeleteModal, Notes } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from '../../../redux/actions';
import { KTSVG, SourceType, detailPageUrl, DetailPage } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Detail() {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, error } = useSelector(state => state.project);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(projectActions.contractsGetId(id));
  }, [])

  const handleDelete = () => dispatch(projectActions.contractsDelete({ id }));

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'MENU.PROJECTS.CONTRACTS' }),
      path: '/projects/contracts',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.CONTRACTS.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>
                           
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2"> 
                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <Link to={detailPageUrl(DetailPage.CompanySupplier, detail?.companyId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/duotune/finance/fin006.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.companyName}
                            </Link>                            
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <Link to={detailPageUrl(DetailPage.Staff, detail?.relatedStaffId)} className='text-gray-400 text-hover-primary' target='_blank'>
                            <KTSVG
                              path='/images/icons/duotune/communication/com006.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                              {detail.relatedStaffName}
                              </Link>
                          </div>
                         
                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle 
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        {detail?.signDate && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CONTRACT.CREATE.SIGNDATE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.signDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>                          
                        </div>}

                        {detail?.startDate && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CONTRACT.CREATE.STARTDATE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.startDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>                          
                        </div>}              

                        {detail?.endDate && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CONTRACT.CREATE.ENDDATE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatDate(new Date(detail?.endDate), { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>                          
                        </div>}

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'CONTRACT.CREATE.DURATION' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{detail.duration}</span></div>
                        </div>
                    
                      </div>
                    </div>
                  </div>
                </div>
                {detail?.importantItem && <>
                  <div className='notice d-flex rounded border-primary border border-dashed  p-6' dangerouslySetInnerHTML={{ __html: detail?.importantItem?.replace(/\n/g, '<br />') }} />
                </>}      
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>                      
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CONTRACT.CREATE.CONTRACTTYPEID' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail?.contractTypeName}</span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CONTRACT.CREATE.CONTRACTSTATEID' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail?.contractStateName}</span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CONTRACT.CREATE.CONTRACTNUMBER' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail?.contractNumber}</span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CONTRACT.CREATE.DESCRIPTION' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail?.description}</span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'CONTRACT.CREATE.TOTALPRICE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalPrice, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>
        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Contract} />
        </div>
      </div>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
