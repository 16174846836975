import { saleinvoiceConstants } from '../constants';
import {
  //#region SaleInvoice
  SearchSaleInvoiceClient,
  GetSaleInvoiceClient,
  DeleteSaleInvoiceClient,
  UpdateSaleInvoiceClient,
  CreateSaleInvoiceClient,
  ArchivingSaleInvoiceClient
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region SaleInvoice
const archivingSaleInvoiceClient = new ArchivingSaleInvoiceClient(SERVICE_URL);
const searchSaleInvoiceClient = new SearchSaleInvoiceClient(SERVICE_URL);
const getSaleInvoiceClient = new GetSaleInvoiceClient(SERVICE_URL);
const createSaleInvoiceClient = new CreateSaleInvoiceClient(SERVICE_URL);
const deleteSaleInvoiceClient = new DeleteSaleInvoiceClient(SERVICE_URL);
const updateSaleInvoiceClient = new UpdateSaleInvoiceClient(SERVICE_URL);
//#endregion

export const saleinvoiceActions = {
  //#region SaleInvoice
  saleinvoiceGetAll,
  saleinvoiceGetId,
  saleinvoiceDelete,
  saleinvoiceCreate,
  saleinvoiceUpdate,
  saleinvoiceArchiving,
  saleinvoiceExcel
  //#endregion    
};

//#region SaleInvoice
function saleinvoiceGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchSaleInvoiceClient.search(params.name, params.editStartDate, params.editEndDate, params.projectId, params.companyId, params.salePaymentStatusId, params.categoryId, params.invoiceKindId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        saleinvoice => dispatch(success(saleinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: saleinvoiceConstants.SALEINVOICE_GETALL_REQUEST } }
  function success(saleinvoice) { return { type: saleinvoiceConstants.SALEINVOICE_GETALL_SUCCESS, saleinvoice } }
  function failure(error) { return { type: saleinvoiceConstants.SALEINVOICE_GETALL_FAILURE, error } }
}

function saleinvoiceExcel(params, fileName) {
  return dispatch => {
    dispatch(request());
    searchSaleInvoiceClient.excel(params.name, params.editStartDate, params.editEndDate, params.projectId, params.companyId, params.salePaymentStatusId, params.categoryId, params.invoiceKindId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: saleinvoiceConstants.SALEINVOICE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: saleinvoiceConstants.SALEINVOICE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: saleinvoiceConstants.SALEINVOICE_EXCEL_FAILURE, error } }
}

function saleinvoiceGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getSaleInvoiceClient.get(id)
      .then(
        saleinvoice => dispatch(success(saleinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: saleinvoiceConstants.SALEINVOICE_GETID_REQUEST, id } }
  function success(saleinvoice) { return { type: saleinvoiceConstants.SALEINVOICE_GETID_SUCCESS, saleinvoice } }
  function failure(error) { return { type: saleinvoiceConstants.SALEINVOICE_GETID_FAILURE, error } }
}

function saleinvoiceDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteSaleInvoiceClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: saleinvoiceConstants.SALEINVOICE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: saleinvoiceConstants.SALEINVOICE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: saleinvoiceConstants.SALEINVOICE_DELETE_FAILURE, error } }
}

function saleinvoiceArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingSaleInvoiceClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: saleinvoiceConstants.SALEINVOICE_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: saleinvoiceConstants.SALEINVOICE_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: saleinvoiceConstants.SALEINVOICE_ARCHIVE_FAILURE, error } }
}

function saleinvoiceCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createSaleInvoiceClient.create(params)
      .then(
        saleinvoice => dispatch(success(saleinvoice)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: saleinvoiceConstants.SALEINVOICE_CREATE_REQUEST, params } }
  function success(saleinvoice) { return { type: saleinvoiceConstants.SALEINVOICE_CREATE_SUCCESS, saleinvoice } }
  function failure(error) { return { type: saleinvoiceConstants.SALEINVOICE_CREATE_FAILURE, error } }
}

function saleinvoiceUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateSaleInvoiceClient.update(params)
      .then(
        saleinvoice => dispatch(success(saleinvoice, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: saleinvoiceConstants.SALEINVOICE_UPDATE_REQUEST, params } }
  function success(saleinvoice, params) { return { type: saleinvoiceConstants.SALEINVOICE_UPDATE_SUCCESS, saleinvoice, params } }
  function failure(error, params) { return { type: saleinvoiceConstants.SALEINVOICE_UPDATE_FAILURE, error, params } }
}
//#endregion
