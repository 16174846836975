/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault, Footer, Header, Activity, Notification} from '../components'
import { PageDataProvider } from '../core/PageData'
import clsx from 'clsx'

const MasterLayout = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activityOpen, setActivityOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const {pathname} = useLocation();

  useEffect(() => {
    setActivityOpen(false)
    setNotificationOpen(false)
  }, [pathname])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault opened={menuOpen}/>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <Header 
            onClick={(val) => setMenuOpen(val)} 
            isShow={menuOpen} 
            handleClickActivity={() => setActivityOpen(true)}
            handleClickNotification={() => setNotificationOpen(!notificationOpen)}
          />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div id='kt_content_container' className='container-fluid'>
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>

        <div 
          className={clsx('drawer-overlay', {'d-flex': menuOpen || activityOpen})}
          onClick={() => {
            setMenuOpen(false)
            setActivityOpen(false)
          }} 
        />
      </div>

      <Activity 
        isShow={activityOpen} 
        onClickClose={() => {
          setMenuOpen(false)
          setActivityOpen(false)
        }} 
      />

      <Notification 
        isShow={notificationOpen} 
      />
    </PageDataProvider>
  )
}

export {MasterLayout}
