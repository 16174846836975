/* eslint-disable no-unused-vars */
import {DefaultLayoutConfig} from './DefaultLayoutConfig'

const LAYOUT_CONFIG_KEY = 'LayoutConfig'

export function getLayout() {
  const ls = localStorage.getItem(LAYOUT_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls)
    } catch (er) {
      console.error(er)
    }
  }
  return DefaultLayoutConfig
}

function setLayout(config) {
  try {
    localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(config))
  } catch (er) {
    console.error(er)
  }
}

export function getEmptyCssClasses() {
  return {
    header: [],
    headerContainer: [],
    headerMobile: [],
    headerMenu: [],
    aside: [],
    asideMenu: [],
    asideToggle: [],
    toolbar: [],
    toolbarContainer: [],
    content: [],
    contentContainer: [],
    footerContainer: [],
    sidebar: [],
    pageTitle: [],
  }
}

export function getEmptyHTMLAttributes() {
  return {
    asideMenu: new Map(),
    headerMobile: new Map(),
    headerMenu: new Map(),
    headerContainer: new Map(),
    pageTitle: new Map(),
  }
}

export function getEmptyCSSVariables() {
  return {
    body: new Map(),
  }
}

export class LayoutSetup {
  static isLoaded = false
  static config = getLayout()
  static classes = getEmptyCssClasses()
  static attributes = getEmptyHTMLAttributes()
  static cssVariables = getEmptyCSSVariables()

  static initCSSClasses() {
    LayoutSetup.classes = getEmptyCssClasses()
  }

  static initHTMLAttributes() {
    LayoutSetup.attributes = Object.assign({}, getEmptyHTMLAttributes())
  }

  static initCSSVariables() {
    LayoutSetup.cssVariables = getEmptyCSSVariables()
  }

  static initLayout(config) {}

  static initHeader(config) {
    LayoutSetup.classes.headerContainer.push(
      config.width === 'fluid' ? 'container-fluid' : 'container'
    )

    if (config.fixed.desktop) {
      document.body.classList.add('header-fixed')
    }

    if (config.fixed.tabletAndMobile) {
      document.body.classList.add('header-tablet-and-mobile-fixed')
    }
  }

  static initToolbar(config) {}

  static initPageTitle(config) {
    if (!config.display) {
      return
    }

    if (config.direction === 'column') {
      this.classes.pageTitle.push('flex-column')
      this.classes.pageTitle.push('align-items-start')
    } else {
      this.classes.pageTitle.push('align-items-center')
      this.classes.pageTitle.push('flex-wrap')
    }
    this.classes.pageTitle.push('me-3')

    if (config.responsive) {
      this.classes.pageTitle.push('mb-5')
      this.classes.pageTitle.push('mb-lg-0')

      LayoutSetup.attributes.pageTitle.set('data-kt-swapper', true)
      LayoutSetup.attributes.pageTitle.set('data-kt-swapper-mode', 'prepend')
      LayoutSetup.attributes.pageTitle.set(
        'data-kt-swapper-parent',
        `{ default: '#kt_content_container', '${config.responsiveBreakpoint}': '${config.responsiveTarget}'}`
      )
    }
  }

  static initContent(config) {
    if (config.width === 'fluid') {
      LayoutSetup.classes.contentContainer.push('container-fluid')
    } else {
      if (config.width === 'fixed') {
        LayoutSetup.classes.contentContainer.push('container-xxl')
      }
    }
  }

  static initAside(config) {
    // Check if aside is displayed
    if (!config.display) {
      return
    }
  }

  static initAsideMenu(config) {}

  static initSidebar(sidebarConfig) {
    if (!sidebarConfig) {
      return
    }
  }

  static initFooter(config) {
    LayoutSetup.classes.footerContainer.push(`container${config.width === 'fluid' ? '-fluid' : ''}`)
  }

  static initConfig(config) {
    // Init layout
    LayoutSetup.initLayout(config)
    if (config.main?.type !== 'default') {
      return
    }

    LayoutSetup.initHeader(config.header)
    LayoutSetup.initPageTitle(config.pageTitle)
    LayoutSetup.initToolbar(config.toolbar)
    LayoutSetup.initContent(config.content)
    LayoutSetup.initAside(config.aside)
    LayoutSetup.initFooter(config.footer)
    LayoutSetup.initAsideMenu(config.aside)
    LayoutSetup.initSidebar(config.sidebar)
  }

  static updatePartialConfig(fieldsToUpdate) {
    const config = LayoutSetup.config
    const updatedConfig = {...config, ...fieldsToUpdate}
    LayoutSetup.initCSSClasses()
    LayoutSetup.initCSSVariables()
    LayoutSetup.initHTMLAttributes()
    LayoutSetup.isLoaded = false
    LayoutSetup.config = updatedConfig
    LayoutSetup.initConfig(Object.assign({}, updatedConfig))
    LayoutSetup.isLoaded = true // remove loading there
    return updatedConfig
  }

  static setConfig(config) {
    setLayout(config)
  }

  static bootstrap = (() => {
    LayoutSetup.updatePartialConfig(LayoutSetup.config)
  })()
}
