import React, {useState} from 'react'
import PropTypes from 'prop-types';
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch } from 'react-redux';
import { bankPaymentOrderActions } from '../../redux/actions';
import { KTSVG } from '../../helpers';

import { Select} from '..';

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

const BankPaymentOrderConfirmation = ({BankPaymentOrderId}) => {
  const intl = useIntl()

  const [confirmationState] = useState([
    {
      Id: 2,
      Name: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.CONFIRMATIONSTATE.CONFIRM'
    },
    {
      Id: 3,
      Name: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.CONFIRMATIONSTATE.REJECT'
    }
  ])
	const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    ApprovedDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Approved: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    BankPaymentOrderId,
    ApprovedDate: '',
    Approved: ''
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(bankPaymentOrderActions.bankPaymentOrderConfirm(values));
      resetForm()
    },
  })

  return (
    <>
      <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>

        <div className='row mb-6'>
          <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.CONFIRM.APPROVEDDATE' })}</label>
          <div className='col-lg-12 fv-row fv-plugins-icon-container'>
            <div className="position-relative d-flex align-items-center">
              <KTSVG
                path='/images/icons/duotune/general/gen014.svg'
                className='svg-icon svg-icon-2 position-absolute mx-4'
              />
              <Flatpickr
                className="form-control form-control-solid ps-12"
                options={{ "locale": Turkish }}
                {...formik.getFieldProps('ApprovedDate')}
                onChange={([date]) => formik.setFieldValue('ApprovedDate', date)}
              />
            </div>
          </div>
        </div>
          
        <div className='row mb-6'>
          <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'BANKPAYMENTORDER.GET.CONFIRM.APPROVED' })}</label>
          <div className='col-lg-12 fv-row fv-plugins-icon-container'>
            <Select
              data={confirmationState} 
              required
              textType={'intl'}
              {...formik.getFieldProps('Approved')}
              onChange={item => formik.setFieldValue('Approved', Number(item?.value))}
            />
            {formik.touched.Approved && formik.errors.Approved && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.Approved}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center'>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                {intl.formatMessage({ id: 'PLEASE' })}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}

BankPaymentOrderConfirmation.propTypes = {
	collapse: PropTypes.bool,
};

export {BankPaymentOrderConfirmation}
