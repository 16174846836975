import { gibinvoiceConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  isSent: false,
  documentdata: null,
  pdfdata: null,
  detail: null
}

export function gibinvoice(state = {}, action) {
  switch (action.type) {
    //#region Invoice
    case gibinvoiceConstants.GIBINVOICE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case gibinvoiceConstants.GIBINVOICE_GETALL_SUCCESS:
      return {
        ...state,
        gibinvoice: action.gibinvoice,
        loading: false
      };
    case gibinvoiceConstants.GIBINVOICE_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case gibinvoiceConstants.GIBINVOICE_GETID_REQUEST:
      return { ...state, ...initialState };
    case gibinvoiceConstants.GIBINVOICE_GETID_SUCCESS:
      return {
        ...state,
        detail: action.gibinvoice,
        loading: false
      };
    case gibinvoiceConstants.GIBINVOICE_GETID_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };


    case gibinvoiceConstants.GIBINVOICE_SEND_REQUEST:
      return { ...state, ...initialState };
    case gibinvoiceConstants.GIBINVOICE_SEND_SUCCESS:
      return {
        ...state,
        isSent: action.gibinvoice,
        loading: false
      };
    case gibinvoiceConstants.GIBINVOICE_SEND_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case gibinvoiceConstants.GIBINVOICE_DOCUMENTPDF_REQUEST:
      return { ...state, ...initialState };
    case gibinvoiceConstants.GIBINVOICE_DOCUMENTPDF_SUCCESS:
      return {
        ...state,
        documentdata: action.gibinvoice,
        loading: false
      };
    case gibinvoiceConstants.GIBINVOICE_DOCUMENTPDF_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case gibinvoiceConstants.GIBINVOICE_INVOICEPDF_REQUEST:
      return { ...state, ...initialState };
    case gibinvoiceConstants.GIBINVOICE_INVOICEPDF_SUCCESS:
      return {
        ...state,
        pdfdata: action.pdfdata,
        loading: false
      };
    case gibinvoiceConstants.GIBINVOICE_INVOICEPDF_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion    
    default:
      return state
  }
}
