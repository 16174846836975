import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { paymentRequestActions } from '../../redux/actions';
import { Select} from '..';

const PaymentRequestConfirmation = ({PaymentRequestId}) => {
  const intl = useIntl()
  const { getConfirmationState, updateConfirmation } = useSelector(state => state.paymentRequest);
  const [confirmationState] = useState([
    {
      Id: 2,
      Name: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.CONFIRMATIONSTATE.CONFIRM'
    },
    {
      Id: 3,
      Name: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.CONFIRMATIONSTATE.REJECT'
    }
  ])
	const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    ConfirmationStateId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    PaymentRequestId,
    NextConfirmationLevelId: '',
    LastConfirmationLevel: '',
    NextUserId: 0,
    ConfirmationStateId: '',
    ConfirmationNote: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(paymentRequestActions.paymentRequestUpdateConfirmation(values));
      resetForm()
    },
  })

  useEffect(() => {
    dispatch(paymentRequestActions.paymentRequestGetConfirmationState({PaymentRequestId}));
  }, [PaymentRequestId, updateConfirmation])

  useEffect(() => {
    if (getConfirmationState) {
      formik.setFieldValue('NextConfirmationLevelId', getConfirmationState?.nextConfirmationLevelId)
      formik.setFieldValue('LastConfirmationLevel', getConfirmationState?.lastConfirmationLevel)
    }
  }, [getConfirmationState])

  return (
    <>
      <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-6'>
          <label className='col-lg-12 fw-bold fs-6 mb-2'>{getConfirmationState?.lastStateName}</label>
        </div>

        {getConfirmationState?.canUserApprove && <>
          {!getConfirmationState?.lastConfirmationLevel && <div className='row mb-6'>
            <label className='col-lg-12 fw-bold fs-6 mb-2'>{getConfirmationState?.nextConfirmationLevelName} {intl.formatMessage({ id: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.NEXTUSERLIST' })}</label>
            <div className='col-lg-12 fv-row fv-plugins-icon-container'>
              <Select
                data={getConfirmationState?.nextUserList} 
                required
                {...formik.getFieldProps('NextUserId')}
                onChange={item => formik.setFieldValue('NextUserId', Number(item?.value))}
              />
            </div>
          </div>}

          <div className='row mb-6'>
            <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.CONFIRMATIONSTATE' })}</label>
            <div className='col-lg-12 fv-row fv-plugins-icon-container'>
              <Select
                data={confirmationState} 
                required
                {...formik.getFieldProps('ConfirmationStateId')}
                onChange={item => formik.setFieldValue('ConfirmationStateId', Number(item?.value))}
                textType={'intl'}
              />
              {formik.touched.ConfirmationStateId && formik.errors.ConfirmationStateId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.ConfirmationStateId}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.NOTE' })}</label>
            <div className='col-lg-12 fv-row fv-plugins-icon-container'>
              <textarea
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                disabled={formik.isSubmitting}
                rows={2}
                {...formik.getFieldProps('ConfirmationNote')}
              />
            </div>
          </div>

          <div className='text-center'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
              {(formik.isSubmitting) && (
                <span className='indicator-progress'>
                  {intl.formatMessage({ id: 'PLEASE' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </> }
      </form>
    </>
  )
}

PaymentRequestConfirmation.propTypes = {
	collapse: PropTypes.bool,
};

export {PaymentRequestConfirmation}
