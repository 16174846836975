import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { checkoutActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { transactions, loading } = useSelector(state => state.checkout);
  const { allAccountLookup, CheckoutProcessType } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
   if (params) dispatch(checkoutActions.checkoutTransactionsSearch(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.allAccountLookup());
    dispatch(lookupActions.basicLookup('CheckoutProcessType'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TRANSACTIONS.GET.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={(event) => event} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TRANSACTIONS.GET.TOACCOUNTNAME' })} className='min-w-125px' />,
      accessor: 'toAccountName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={(event) => event} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))} title={intl.formatMessage({ id: 'TRANSACTIONS.GET.FROMACCOUNTNAME' })} className='min-w-125px' />,
      accessor: 'fromAccountName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={(event) => event} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TRANSACTIONS.GET.PAYMENTDATE' })} className='min-w-125px' />,
      accessor: 'paymentDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" onClick={(event) => event} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TRANSACTIONS.GET.CHECKOUTTYPENAME' })} className='min-w-125px' />,
      accessor: 'checkoutProcessTypeName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={(event) => event} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))} title={intl.formatMessage({ id: 'TRANSACTIONS.GET.ISOFFICIALNAME' })} className='min-w-125px' />,
      accessor: 'isOfficialName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={(event) => event} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'TRANSACTIONS.GET.AMOUNT' })} className='min-w-125px' />,
      accessor: 'amount',
      Cell: ({ ...props }) => <CustomCell item={props} price onClick={(event) => event} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' onClick={(event) => event} />,
    }
  ];

  const buttons = []

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(checkoutActions.checkoutTransactionsExcel(params, intl.formatMessage({ id: 'MENU.CASH.TRANSACTIONS' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'TRANSACTIONS.GET.TOACCOUNTNAME',
          data: allAccountLookup,
          name: 'toAccountId'
        },
        {
          title: 'TRANSACTIONS.GET.FROMACCOUNTNAME',
          data: allAccountLookup,
          name: 'fromAccountId'
        },
        {
          title: 'TRANSACTIONS.GET.CHECKOUTPROCESSTYPEID',
          data: CheckoutProcessType,
          name: 'checkoutProcessTypeId'
        },
        {
          title: 'TRANSACTIONS.GET.MINAMOUNT',
          type: 'number',
          name: 'minAmount'
        },
        {
          title: 'TRANSACTIONS.GET.MAXAMOUNT',
          type: 'number',
          name: 'maxAmount'
        },
        {
          title: 'TRANSACTIONS.GET.STARTDATE',
          type: 'date',
          name: 'beginDate'
        },
        {
          title: 'TRANSACTIONS.GET.ENDDATE',
          type: 'date',
          name: 'endDate'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.CASH.TRANSACTIONS' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages='TRANSACTIONS'
          buttons={buttons}
        />
        <KTCardBody>
          {!transactions && <ListLoading />}
          {transactions && <Table
            items={transactions}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
