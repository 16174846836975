/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTSVG } from '../../../helpers';
import { KTCard, KTCardBody, InputPrice, Select } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, staffActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { staffLookup, accountLookup, PaymentState, projectLookup } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.staff);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.staffGetAll());
    dispatch(lookupActions.bankCashAccountGetAll());
    dispatch(lookupActions.basicLookup('PaymentState'));
    dispatch(lookupActions.projectLookup());
  }, [])

  const [paymentStateType, setPaymentStateType] = useState('2')
  const [stepSchema, setStepSchema] = useState({
    StaffId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProgressPaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    DueDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Payment: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PaymentStateId: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' }))
  })

  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    StaffId: '',
    ProgressPaymentDate: new Date(),
    Name: '',
    DueDate: new Date(),
    Payment: 0,
    PaymentStateId: '2',
    AccountId: null,
    PaymentDate: null,
    ProjectId: null
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(staffActions.salaryCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.SALARY' }),
      path: '/staff/salary',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  const onChangeState = (value) => {
    formik.setFieldValue('PaymentStateId', value);
    setPaymentStateType(value)

    if (value === '1') {
      setStepSchema({
        StaffId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProgressPaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        AccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Payment: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentStateId: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    } else {
      setStepSchema({
        StaffId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProgressPaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        DueDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Payment: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        PaymentStateId: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.SALARY.ADD' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'STAFF_NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={staffLookup}
                  required
                  {...formik.getFieldProps('StaffId')}
                  onChange={item => formik.setFieldValue('StaffId', Number(item?.value))}
                />
                {formik.touched.StaffId && formik.errors.StaffId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.StaffId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPLANATION' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <textarea
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={4}
                  {...formik.getFieldProps('Name')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENT.PROJECT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={projectLookup}
                  required
                  {...formik.getFieldProps('ProjectId')}
                  onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                />
                {formik.touched.ProjectId && formik.errors.ProjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROGRESS_PAYMENT_DATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    required
                    {...formik.getFieldProps('ProgressPaymentDate')}
                    onChange={([date]) => formik.setFieldValue('ProgressPaymentDate', date)}
                  />
                </div>
                {formik.touched.ProgressPaymentDate && formik.errors.ProgressPaymentDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProgressPaymentDate}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('Payment')} 
                  onChange={(value) => formik.setFieldValue('Payment', Number(value))} 
                />
                {formik.touched.Payment && formik.errors.Payment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Payment}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {PaymentState && <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENT.STATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={PaymentState}
                  required
                  {...formik.getFieldProps('PaymentStateId')}
                  onChange={item => onChangeState(Number(item.value))}
                  defaultValue={paymentStateType}
                />
                {formik.touched.PaymentStateId && formik.errors.PaymentStateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.PaymentStateId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>}
            {paymentStateType === '2' && <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT.DUE.DATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    required
                    {...formik.getFieldProps('DueDate')}
                    onChange={([date]) => formik.setFieldValue('DueDate', date)}
                  />
                </div>
                {formik.touched.DueDate && formik.errors.DueDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.DueDate}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>}

            {paymentStateType === 1 && <>
              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PAYMENT.DATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      required
                      {...formik.getFieldProps('PaymentDate')}
                      onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                    />
                  </div>
                  {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.PaymentDate}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'ACCOUNT' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={accountLookup}
                    required
                    {...formik.getFieldProps('AccountId')}
                    onChange={item => formik.setFieldValue('AccountId', Number(item?.value))}
                  />
                  {formik.touched.AccountId && formik.errors.AccountId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.AccountId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>}

            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
