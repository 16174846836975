/* eslint-disable react/react-in-jsx-scope */
import clsx from 'clsx'

const KTCardBody = (props) => {
  const {className, scroll, height, children, collapseble, isShow} = props;

  return (
    <div
      className={clsx(
        'card-body',
        className && className,
        {
          'card-scroll': scroll,
          'collapse': collapseble || isShow,
          'show': isShow
        },
        height && `h-${height}px`
      )}
    >
      {children}
    </div>
  )
}

export {KTCardBody}
