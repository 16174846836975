import { reportingConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  saleinvoiceCategory: [],
  saleinvoiceProject: [],
  saleinvoiceDate: [],
  saleinvoiceMonth: [],
  saleinvoiceStatus: [],
  expenseinvoiceCategory: [],
  expenseinvoiceProject: [],
  expenseinvoiceDate: [],
  expenseinvoiceMonth: [],
  expenseinvoiceStatus: [],
  projectCompleteRateByProject: [],
  constructionSiteCompleteRateByProject: [],
  blockCompleteRateByConstructionSite: [],
  rootTaskCompleteRateByProject: [],
  dailyTaskCompleteRateByProject: [],
  details: null,
  auditList: [],
  cashflow: {}
}

export function reporting(state = {}, action) {
  switch (action.type) {
    //#region dynamic
    case reportingConstants.DYNAMICREPORT_SEARCH_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.DYNAMICREPORT_SEARCH_SUCCESS:
      return {
        ...state,
        dynamicreport: action.dynamicreport,
        loading: false
      };
    case reportingConstants.DYNAMICREPORT_SEARCH_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    //#endregion

    //#region saleinvoice
    case reportingConstants.SALEINVOICE_CATEGORY_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.SALEINVOICE_CATEGORY_SUCCESS:
      return {
        ...state,
        saleinvoiceCategory: action.saleinvoiceCategory,
        loading: false
      };
    case reportingConstants.SALEINVOICE_CATEGORY_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case reportingConstants.SALEINVOICE_PROJECT_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.SALEINVOICE_PROJECT_SUCCESS:
      return {
        ...state,
        saleinvoiceProject: action.saleinvoiceProject,
        loading: false
      };
    case reportingConstants.SALEINVOICE_PROJECT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case reportingConstants.SALEINVOICE_DATE_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.SALEINVOICE_DATE_SUCCESS:
      return {
        ...state,
        saleinvoiceDate: action.saleinvoiceDate,
        loading: false
      };
    case reportingConstants.SALEINVOICE_DATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.SALEINVOICE_MONTH_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.SALEINVOICE_MONTH_SUCCESS:
      return {
        ...state,
        saleinvoiceMonth: action.saleinvoiceMonth,
        loading: false
      };
    case reportingConstants.SALEINVOICE_MONTH_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.SALEINVOICE_STATUS_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.SALEINVOICE_STATUS_SUCCESS:
      return {
        ...state,
        saleinvoiceStatus: action.saleinvoiceStatus,
        loading: false
      };
    case reportingConstants.SALEINVOICE_STATUS_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    //#endregion

    //#region expenseinvoice
    case reportingConstants.EXPENSEINVOICE_CATEGORY_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.EXPENSEINVOICE_CATEGORY_SUCCESS:
      return {
        ...state,
        expenseinvoiceCategory: action.expenseinvoiceCategory,
        loading: false
      };
    case reportingConstants.EXPENSEINVOICE_CATEGORY_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case reportingConstants.EXPENSEINVOICE_PROJECT_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.EXPENSEINVOICE_PROJECT_SUCCESS:
      return {
        ...state,
        expenseinvoiceProject: action.expenseinvoiceProject,
        loading: false
      };
    case reportingConstants.EXPENSEINVOICE_PROJECT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case reportingConstants.EXPENSEINVOICE_DATE_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.EXPENSEINVOICE_DATE_SUCCESS:
      return {
        ...state,
        expenseinvoiceDate: action.expenseinvoiceDate,
        loading: false
      };
    case reportingConstants.EXPENSEINVOICE_DATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.EXPENSEINVOICE_MONTH_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.EXPENSEINVOICE_MONTH_SUCCESS:
      return {
        ...state,
        expenseinvoiceMonth: action.expenseinvoiceMonth,
        loading: false
      };
    case reportingConstants.EXPENSEINVOICE_MONTH_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.EXPENSEINVOICE_STATUS_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.EXPENSEINVOICE_STATUS_SUCCESS:
      return {
        ...state,
        expenseinvoiceStatus: action.expenseinvoiceStatus,
        loading: false
      };
    case reportingConstants.EXPENSEINVOICE_STATUS_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    //#endregion

    //#region WorkProgramReport
    case reportingConstants.WORKPROGRAM_PROJECT_COMPLETE_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.WORKPROGRAM_PROJECT_COMPLETE_SUCCESS:
      return {
        ...state,
        projectCompleteRateByProject: action.projectCompleteRateByProject,
        loading: false
      };
    case reportingConstants.WORKPROGRAM_PROJECT_COMPLETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case reportingConstants.WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_SUCCESS:
      return {
        ...state,
        constructionSiteCompleteRateByProject: action.constructionSiteCompleteRateByProject,
        loading: false
      };
    case reportingConstants.WORKPROGRAM_CONSTRUCTIONSITE_COMPLETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.WORKPROGRAM_BLOCK_COMPLETE_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.WORKPROGRAM_BLOCK_COMPLETE_SUCCESS:
      return {
        ...state,
        blockCompleteRateWithConstructionSiteByProject: action.blockCompleteRateWithConstructionSiteByProject,
        loading: false
      };
    case reportingConstants.WORKPROGRAM_BLOCK_COMPLETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.WORKPROGRAM_ROOTTASK_COMPLETE_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.WORKPROGRAM_ROOTTASK_COMPLETE_SUCCESS:
      return {
        ...state,
        rootTaskCompleteRateByProject: action.rootTaskCompleteRateByProject,
        loading: false
      };
    case reportingConstants.WORKPROGRAM_ROOTTASK_COMPLETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.WORKPROGRAM_DAILY_COMPLETE_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.WORKPROGRAM_DAILY_COMPLETE_SUCCESS:
      return {
        ...state,
        dailyTaskCompleteRateByProject: action.dailyTaskCompleteRateByProject,
        loading: false
      };
    case reportingConstants.WORKPROGRAM_DAILY_COMPLETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    //#endregion

    //#region audit
    case reportingConstants.AUDIT_SEARCH_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.AUDIT_SEARCH_SUCCESS:
      return {
        ...state,
        audit: action.audit,
        loading: false
      };
    case reportingConstants.AUDIT_SEARCH_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   

    case reportingConstants.AUDIT_GETID_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.AUDIT_GETID_SUCCESS:
      return {
        ...state,
        details: action.details,
        loading: false
      };
    case reportingConstants.AUDIT_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    case reportingConstants.AUDIT_GETLIST_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.AUDIT_GETLIST_SUCCESS:
      return {
        ...state,
        auditList: action.auditList,
        loading: false
      };
    case reportingConstants.AUDIT_GETLIST_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    //#endregion
    //#region CashFlow
    case reportingConstants.CASHFLOW_SEARCH_REQUEST:
      return { ...state, ...initialState };
    case reportingConstants.CASHFLOW_SEARCH_SUCCESS:
      console.log("action", action)
      return {
        ...state,
        cashflow: action.cashflow,
        loading: false
      };
    case reportingConstants.CASHFLOW_SEARCH_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      }; 
    //#endregion
    default:
      return state
  }
}
