import { guaranteeletterConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  excel: null
}

export function guaranteeletter(state = {}, action) {
  switch (action.type) {
    //#region Guaranteeletter
    case guaranteeletterConstants.GUARATEELETTER_GETALL_REQUEST:
      return { ...state, ...initialState };
    case guaranteeletterConstants.GUARATEELETTER_GETALL_SUCCESS:
      return {
        ...state,
        guaranteeletter: action.guaranteeletter,
        loading: false
      };
    case guaranteeletterConstants.GUARATEELETTER_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case guaranteeletterConstants.GUARATEELETTER_GETID_REQUEST:
      return { ...state, ...initialState };
    case guaranteeletterConstants.GUARATEELETTER_GETID_SUCCESS:
      return {
        ...state,
        detail: action.guaranteeletter,
        loading: false
      };
    case guaranteeletterConstants.GUARATEELETTER_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case guaranteeletterConstants.GUARATEELETTER_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case guaranteeletterConstants.GUARATEELETTER_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case guaranteeletterConstants.GUARATEELETTER_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case guaranteeletterConstants.GUARATEELETTER_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case guaranteeletterConstants.GUARATEELETTER_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.guaranteeletter || action.params,
        detail: action.params,
        loading: false
      };
    case guaranteeletterConstants.GUARATEELETTER_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case guaranteeletterConstants.GUARATEELETTER_DELETE_REQUEST:
      return { ...state, ...initialState };
    case guaranteeletterConstants.GUARATEELETTER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case guaranteeletterConstants.GUARATEELETTER_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case guaranteeletterConstants.GUARATEELETTER_CREATE_REQUEST:
      return { ...state, ...initialState };
    case guaranteeletterConstants.GUARATEELETTER_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.guaranteeletter,
        loading: false
      };
    case guaranteeletterConstants.GUARATEELETTER_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion    
    default:
      return state
  }
}
