export const categoryConstants = {
  //#region Category
  CATEGORY_GETALL_REQUEST: 'CATEGORY_GETALL_REQUEST',
  CATEGORY_GETALL_SUCCESS: 'CATEGORY_GETALL_SUCCESS',
  CATEGORY_GETALL_FAILURE: 'CATEGORY_GETALL_FAILURE',

  CATEGORY_DELETE_REQUEST: 'CATEGORY_DELETE_REQUEST',
  CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  CATEGORY_DELETE_FAILURE: 'CATEGORY_DELETE_FAILURE',

  CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',

  CATEGORY_UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CATEGORY_UPDATE_FAILURE: 'CATEGORY_UPDATE_FAILURE',  
  //#endregion

  //#region rECORDCategory
  RECORDCATEGORY_CREATE_REQUEST: 'RECORDCATEGORY_CREATE_REQUEST',
  RECORDCATEGORY_CREATE_SUCCESS: 'RECORDCATEGORY_CREATE_SUCCESS',
  RECORDCATEGORY_CREATE_FAILURE: 'RECORDCATEGORY_CREATE_FAILURE',

  RECORDCATEGORY_GETID_REQUEST: 'RECORDCATEGORY_GETID_REQUEST',
  RECORDCATEGORY_GETID_SUCCESS: 'RECORDCATEGORY_GETID_SUCCESS',
  RECORDCATEGORY_GETID_FAILURE: 'RECORDCATEGORY_GETID_FAILURE',

  RECORDCATEGORY_UPDATE_REQUEST: 'RECORDCATEGORY_UPDATE_REQUEST',
  RECORDCATEGORY_UPDATE_SUCCESS: 'RECORDCATEGORY_UPDATE_SUCCESS',
  RECORDCATEGORY_UPDATE_FAILURE: 'RECORDCATEGORY_UPDATE_FAILURE',
  //#endregion            
};
