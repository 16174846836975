/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, DeleteModal, DataTables, Notes } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../../../redux/actions';
import { KTSVG, SourceType } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Detail() {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, error } = useSelector(state => state.product);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(productActions.warehouseTransferGetId(id));
  }, [])

  const handleDelete = () => dispatch(productActions.warehouseTransferDelete({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.WAREHOUSE_TRANSFER' }),
      path: '/stock/warehouse-transfer',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.WAREHOUSE_TRANSFER.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.projectName && <> 
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.projectName}
                            </div>
                          </>}
                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen014.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {intl.formatDate(new Date(detail?.shipmentDate), { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })}
                          </div>


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>                       
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">                            
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        {detail?.shipmentDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'WAREHOUSETRANSFER.GET.SHIPMENTDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.shipmentDate), { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}                      

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'WAREHOUSETRANSFER.GET.DEPARTUREWAREHOUSE' })}</div>
                          <div className="fw-bold fs-4">{detail?.departureWarehouseName}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'WAREHOUSETRANSFER.GET.DELIVERYPERSON' })}</div>
                          <div className="fw-bold fs-4">{detail?.deliveryPerson}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'WAREHOUSETRANSFER.GET.ENTRYWAREHOUSE' })}</div>
                          <div className="fw-bold fs-4">{detail?.entryWarehouseName}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'WAREHOUSETRANSFER.GET.RECEIVERPERSON' })}</div>
                          <div className="fw-bold fs-4">{detail?.receiverPerson}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >                 
                  <Tab eventKey="transferitems" title={intl.formatMessage({ id: 'WAREHOUSETRANSFER.CREATE.WAREHOUSETRANSFERITEMS' })}>
                    <div className='row p-5'>
                      <div className='col-lg-12 order-2 order-lg-1'>
                        {detail?.warehouseTransferItems?.length > 0 && <DataTables
                          columns={[
                            {
                              title: intl.formatMessage({ id: 'WAREHOUSETRANSFER.CREATE.WAREHOUSETRANSFERITEMS.PRODUCTID' }),
                              prop: 'productName',
                              isSortable: true,
                              isFilterable: true
                            },
                            {
                              title: intl.formatMessage({ id: 'WAREHOUSETRANSFER.CREATE.WAREHOUSETRANSFERITEMS.AMOUNT' }),
                              prop: 'amount',
                              isSortable: true,
                              isFilterable: true
                            }]
                          }
                          data={detail?.warehouseTransferItems}
                          exportTitle={intl.formatMessage({ id: 'WAREHOUSETRANSFER.CREATE.WAREHOUSETRANSFERITEMS' })}
                        />}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>
        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.WarehouseTransfer} />
        </div>
      </div>
      
      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
