export const userConstants = {
  
  USER_GETALL_REQUEST: 'USER_GETALL_REQUEST',
  USER_GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
  USER_GETALL_FAILURE: 'USER_GETALL_FAILURE',

  USER_GETID_REQUEST: 'USER_GETID_REQUEST',
  USER_GETID_SUCCESS: 'USER_GETID_SUCCESS',
  USER_GETID_FAILURE: 'USER_GETID_FAILURE',

  USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',

  USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',

  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

  USERROLES_UPDATE_REQUEST: 'USERROLES_UPDATE_REQUEST',
  USERROLES_UPDATE_SUCCESS: 'USERROLES_UPDATE_SUCCESS',
  USERROLES_UPDATE_FAILURE: 'USERROLES_UPDATE_FAILURE',

  USER_EXCEL_REQUEST: 'USER_EXCEL_REQUEST',
  USER_EXCEL_SUCCESS: 'USER_EXCEL_SUCCESS',
  USER_EXCEL_FAILURE: 'USER_EXCEL_FAILURE',
  
};
