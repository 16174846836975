export const commonConstants = {
  HISTORY_GETALL_REQUEST: 'HISTORY_GETALL_REQUEST',
  HISTORY_GETALL_SUCCESS: 'HISTORY_GETALL_SUCCESS',
  HISTORY_GETALL_FAILURE: 'HISTORY_GETALL_FAILURE',

  NOTE_GETALL_REQUEST: 'NOTE_GETALL_REQUEST',
  NOTE_GETALL_SUCCESS: 'NOTE_GETALL_SUCCESS',
  NOTE_GETALL_FAILURE: 'NOTE_GETALL_FAILURE',

  NOTE_IMAGES_GETALL_REQUEST: 'NOTE_IMAGES_GETALL_REQUEST',
  NOTE_IMAGES_GETALL_SUCCESS: 'NOTE_IMAGES_GETALL_SUCCESS',
  NOTE_IMAGES_GETALL_FAILURE: 'NOTE_IMAGES_GETALL_FAILURE',

  NOTE_DELETE_REQUEST: 'NOTE_DELETE_REQUEST',
  NOTE_DELETE_SUCCESS: 'NOTE_DELETE_SUCCESS',
  NOTE_DELETE_FAILURE: 'NOTE_DELETE_FAILURE',

  NOTE_CREATE_REQUEST: 'NOTE_CREATE_REQUEST',
  NOTE_CREATE_SUCCESS: 'NOTE_CREATE_SUCCESS',
  NOTE_CREATE_FAILURE: 'NOTE_CREATE_FAILURE',

  GLOBALSEARCH_GETALL_REQUEST: 'GLOBALSEARCH_GETALL_REQUEST',
  GLOBALSEARCH_GETALL_SUCCESS: 'GLOBALSEARCH_GETALL_SUCCESS',
  GLOBALSEARCH_GETALL_FAILURE: 'GLOBALSEARCH_GETALL_FAILURE',

  GENERAL_DASHBOARD_GET_REQUEST: 'GENERAL_DASHBOARD_GET_REQUEST',
  GENERAL_DASHBOARD_GET_SUCCESS: 'GENERAL_DASHBOARD_GET_SUCCESS',
  GENERAL_DASHBOARD_GET_FAILURE: 'GENERAL_DASHBOARD_GET_FAILURE',

  BANKCASH_DASHBOARD_GET_REQUEST: 'BANKCASH_DASHBOARD_GET_REQUEST',
  BANKCASH_DASHBOARD_GET_SUCCESS: 'BANKCASH_DASHBOARD_GET_SUCCESS',
  BANKCASH_DASHBOARD_GET_FAILURE: 'BANKCASH_DASHBOARD_GET_FAILURE',

  ACTIVITY_GETALL_REQUEST: 'ACTIVITY_GETALL_REQUEST',
  ACTIVITY_GETALL_SUCCESS: 'ACTIVITY_GETALL_SUCCESS',
  ACTIVITY_GETALL_FAILURE: 'ACTIVITY_GETALL_FAILURE',

  FILEFOLDER_CREATE_REQUEST: 'FILEFOLDER_CREATE_REQUEST',
  FILEFOLDER_CREATE_SUCCESS: 'FILEFOLDER_CREATE_SUCCESS',
  FILEFOLDER_CREATE_FAILURE: 'FILEFOLDER_CREATE_FAILURE',
    
  FILEFOLDER_RENAME_REQUEST: 'FILEFOLDER_RENAME_REQUEST',
  FILEFOLDER_RENAME_SUCCESS: 'FILEFOLDER_RENAME_SUCCESS',
  FILEFOLDER_RENAME_FAILURE: 'FILEFOLDER_RENAME_FAILURE',

  FILEFOLDER_GETALLWITHFILES_REQUEST: 'FILEFOLDER_GETALLWITHFILES_REQUEST',
  FILEFOLDER_GETALLWITHFILES_SUCCESS: 'FILEFOLDER_GETALLWITHFILES_SUCCESS',
  FILEFOLDER_GETALLWITHFILES_FAILURE: 'FILEFOLDER_GETALLWITHFILES_FAILURE',

  FILEFOLDER_GETID_REQUEST: 'FILEFOLDER_GETID_REQUEST',
  FILEFOLDER_GETID_SUCCESS: 'FILEFOLDER_GETID_SUCCESS',
  FILEFOLDER_GETID_FAILURE: 'FILEFOLDER_GETID_FAILURE',

  FILEFOLDER_DELETE_REQUEST: 'FILEFOLDER_DELETE_REQUEST',
  FILEFOLDER_DELETE_SUCCESS: 'FILEFOLDER_DELETE_SUCCESS',
  FILEFOLDER_DELETE_FAILURE: 'FILEFOLDER_DELETE_FAILURE',

  FILE_CREATE_REQUEST: 'FILE_CREATE_REQUEST',
  FILE_CREATE_SUCCESS: 'FILE_CREATE_SUCCESS',
  FILE_CREATE_FAILURE: 'FILE_CREATE_FAILURE',

  FILE_DELETE_REQUEST: 'FILE_DELETE_REQUEST',
  FILE_DELETE_SUCCESS: 'FILE_DELETE_SUCCESS',
  FILE_DELETE_FAILURE: 'FILE_DELETE_FAILURE',
};
