/* eslint-disable react/react-in-jsx-scope */
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {NotificationType} from '../../../helpers'

// eslint-disable-next-line no-unused-vars
const CustomCell = ({
  item,
  to,
  date,
  suffix,
  leftsuffix,
  computation,
  price,
  notificationType,
  onClick,
  customValue,
}) => {
  let intl = useIntl()
  let value = String(customValue || item.cell.value)

  if (date) {
    const dateValue = new Date(value)

    if (isNaN(dateValue)) {
      value = '-'
    } else {
      if (date === 'long') {
        value = new Intl.DateTimeFormat('tr', {
          hour: 'numeric',
          minute: 'numeric',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(dateValue)
      } else if (date === 'short') {
        value = new Intl.DateTimeFormat('tr', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(dateValue)
      } else if (date === 'month') {
        value = new Intl.DateTimeFormat('tr', {month: 'long'}).format(dateValue)
      }
    }
  }

  var currency = 'try'
  if (item.data[item.row.index].currencyCode != null)
    currency = item.data[item.row.index].currencyCode

  if (price) {
    value = intl.formatNumber(value, {
      style: 'currency',
      currency: currency,
    })
  }

  if (notificationType) {
    value = NotificationType[item.cell.value]
  }

  let computedValue = value

  if (computation === 'remainingDay') {
    computedValue = value.startsWith('-')
      ? value * -1 + intl.formatMessage({id: 'PROJECT.DAYPASSED'})
      : value + intl.formatMessage({id: 'PROJECT.DAYLEFT'})
  }

  if (computation === 'delayDay') {
    computedValue = value.startsWith('-') ? value * -1 : value
  }

  if (onClick) {
    return (
      <div
        className='d-flex text-gray-600 p-5'
        id={item.data[item.row.index].id}
        onClick={() => onClick && onClick()}
        role='button'
      >
        {leftsuffix}
        {computedValue} {suffix}
      </div>
    )
  }

  return (
    <Link
      to={to ? to : `detail/${item.data[item.row.index].id}`}
      id={item.data[item.row.index].id}
      className='d-flex text-gray-600 p-5'
    >
      {leftsuffix}
      {computedValue} {suffix}
    </Link>
  )
}

export {CustomCell}
