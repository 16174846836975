import { companyConstants } from '../constants';
import {
  //#region Company
  SearchCompanyClient,
  GetCompanyClient,
  DeleteCompanyClient,
  UpdateCompanyClient,
  CreateCompanyClient,
  ArchivingCompanyClient,
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region Company
const archivingCompanyClient = new ArchivingCompanyClient(SERVICE_URL);
const searchCompanyClient = new SearchCompanyClient(SERVICE_URL);
const getCompanyClient = new GetCompanyClient(SERVICE_URL);
const createCompanyClient = new CreateCompanyClient(SERVICE_URL);
const deleteCompanyClient = new DeleteCompanyClient(SERVICE_URL);
const updateCompanyClient = new UpdateCompanyClient(SERVICE_URL);
//#endregion

export const companyActions = {
  //#region Company
  companyGetAll,
  companyGetId,
  companyDelete,
  companyCreate,
  companyUpdate,
  companyArchiving,
  companyExcel,
  //#endregion    
};

//#region Company
function companyGetAll(params, companyTypeId) {
  return dispatch => {
    dispatch(request());

    searchCompanyClient.search(params.name, companyTypeId, params.hasBalance, params.categoryId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        company => dispatch(success(company)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.COMPANY_GETALL_REQUEST } }
  function success(company) { return { type: companyConstants.COMPANY_GETALL_SUCCESS, company } }
  function failure(error) { return { type: companyConstants.COMPANY_GETALL_FAILURE, error } }
}

function companyGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getCompanyClient.get(id)
      .then(
        company => dispatch(success(company)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: companyConstants.COMPANY_GETID_REQUEST, id } }
  function success(company) { return { type: companyConstants.COMPANY_GETID_SUCCESS, company } }
  function failure(error) { return { type: companyConstants.COMPANY_GETID_FAILURE, error } }
}

function companyDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteCompanyClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: companyConstants.COMPANY_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: companyConstants.COMPANY_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: companyConstants.COMPANY_DELETE_FAILURE, error } }
}

function companyArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingCompanyClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: companyConstants.COMPANY_ARCHIVING_REQUEST, params } }
  function success(isArchived) { return { type: companyConstants.COMPANY_ARCHIVING_SUCCESS, isArchived } }
  function failure(error) { return { type: companyConstants.COMPANY_ARCHIVING_FAILURE, error } }
}

function companyCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createCompanyClient.create(params)
      .then(
        company => dispatch(success(company)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: companyConstants.COMPANY_CREATE_REQUEST, params } }
  function success(company) { return { type: companyConstants.COMPANY_CREATE_SUCCESS, company } }
  function failure(error) { return { type: companyConstants.COMPANY_CREATE_FAILURE, error } }
}

function companyUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateCompanyClient.update(params)
      .then(
        company => dispatch(success(company, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: companyConstants.COMPANY_UPDATE_REQUEST, params } }
  function success(company, params) { return { type: companyConstants.COMPANY_UPDATE_SUCCESS, company, params } }
  function failure(error, params) { return { type: companyConstants.COMPANY_UPDATE_FAILURE, error, params } }
}

function companyExcel(params, companyTypeId, fileName) {
  return dispatch => {
    dispatch(request());

    searchCompanyClient.excel(params.name, companyTypeId, params.hasBalance, params.categoryId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: companyConstants.COMPANY_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: companyConstants.COMPANY_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: companyConstants.COMPANY_EXCEL_FAILURE, error } }
}
//#endregion
