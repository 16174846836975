import React from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as XLSX from 'xlsx';

const ExcelButton = ({data, title}) => {
  const intl = useIntl();

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  return (
    <button type="button" className="btn btn-sm btn-light-primary" onClick={() => downloadExcel(data)}>{intl.formatMessage({ id: 'BUTTON.EXCEL' })}</button>
  )
}

ExcelButton.propTypes = {
	title: PropTypes.string,
  data: PropTypes.array
};

export {ExcelButton}
