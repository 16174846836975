export const recordProcessType = {
  1: {
    title: 'Kayıt Ekleme',
    classType: 'text-primary'
  },
  2: {
    title: 'Kayıt Güncelleme',
    classType: 'text-secondary'
  },
  3: {
    title: 'Bilgi',
    classType: 'text-info'
  },
  4: {
    title: 'Ödeme',
    classType: 'text-warning'
  },
  5: {
    title: 'İşlem Tamamlandı',
    classType: 'text-success'
  }
}
