import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { checkActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { check, loading } = useSelector(state => state.check);
  const { companyLookup, CheckType, CheckState } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) dispatch(checkActions.checkGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.basicLookup('CheckType'));
    dispatch(lookupActions.basicLookup('CheckState'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECK.SEARCH.CHECK' })} className='min-w-125px' />,
      accessor: 'description',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="checkNo" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECK.SEARCH.CHECKTYPENAME' })} className='min-w-125px' />,
      accessor: 'checkTypeName',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="bankName" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECK.SEARCH.COMPANYNAME' })} className='min-w-125px' />,
      accessor: 'fromCompanyName',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="toCompanyName" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECK.SEARCH.EDITDATE' })} className='min-w-125px' />,
      accessor: 'editDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECK.SEARCH.DEFERMENTDATE' })} className='min-w-125px' />,
      accessor: 'defermentDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECK.SEARCH.TOTALPRICE' })} className='min-w-125px' />,
      accessor: 'totalPrice',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="checkStateName" price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    },
  ];

  const buttons = []

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(checkActions.checkExcel(params, intl.formatMessage({ id: 'BC.CHECKS' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'CHECK.SEARCH.COMPANYNAME',
          data: companyLookup,
          name: 'companyId'
        },
        {
          title: 'CHECK.SEARCH.CHECKTYPENAME',
          data: CheckType,
          name: 'checkTypeId'
        },
        {
          title: 'CHECK.SEARCH.CHECKSTATEID',
          data: CheckState,
          name: 'checkStateId'
        },
        {
          title: 'CHECK.SEARCH.DEFERMENTSTARTDATE',
          type: 'date',
          name: 'defermentStartDate'
        },
        {
          title: 'CHECK.SEARCH.DEFERMENTENDDATE',
          type: 'date',
          name: 'defermentEndDate'
        },
      ]
    }
  ]
  
  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.CHECKS' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          buttons={buttons}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'CHECKS'}
        />
        <KTCardBody>
          {!check && <ListLoading />}
          {check && <Table
            items={check}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
