import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { proposalActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { proposal, loading } = useSelector(state => state.proposal);
  const { projectLookup, ProposalStatus, companyLookup } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) dispatch(proposalActions.proposalGetAll(params));
  }, [params])

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.basicLookup('ProposalStatus'));
  }, [])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PROPOSAL.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="companyName" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PROPOSAL.SEARCH.PROJECTNAME' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PROPOSAL.SEARCH.EDITDATE' })} className='min-w-125px' />,
      accessor: 'editDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PROPOSAL.SEARCH.DEFERMENTDATE' })} className='min-w-125px' />,
      accessor: 'defermentDate',
      Cell: ({ ...props }) => <CustomCellDouble item={props} date="short" double="delayDay" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PROPOSAL.SEARCH.TOTALPRICE' })} className='min-w-125px' />,
      accessor: 'totalPrice',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'PROPOSAL.SEARCH.PROPOSALSTATUSNAME' })} className='min-w-125px' />,
      accessor: 'proposalStatusName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    },
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(proposalActions.proposalExcel(params, intl.formatMessage({ id: 'BC.PROPOSAL' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'PROPOSAL.SEARCH.PROJECTNAME',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'PROPOSAL.CREATE.COMPANYID',
          data: companyLookup,
          name: 'companyId'
        },
        {
          title: 'PROPOSAL.SEARCH.PROPOSALSTATUSNAME',
          data: ProposalStatus,
          name: 'proposalStatusId'
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.PROPOSAL' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'PROPOSAL'}
        />
        <KTCardBody>
          {!proposal && <ListLoading />}
          {proposal && <Table
            items={proposal}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
