/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../helpers'
import { DefaultTitle } from './DefaultTitle'
import PropTypes from 'prop-types';
import {Topbar} from './Topbar'

export function Header({onClick, isShow, handleClickActivity, handleClickNotification}) {
  const [isOpen, setIsOpen] = useState(isShow)

  useEffect(() => {
    onClick && onClick(isOpen)
  }, [isOpen])

  useEffect(() => {
    setIsOpen(isShow)
  }, [isShow])

  return (
    <div
      className='header'
      data-kt-sticky={'false'}
      data-kt-sticky-name='header'
    >
      <div
        className='container-fluid d-flex flex-stack flex-wrap gap-2'
        id='kt_header_container'
      >
        <DefaultTitle />

        <div className='d-flex d-lg-none align-items-center ms-n2 me-2'>
          <div className='btn btn-icon btn-active-icon-primary' onClick={() => setIsOpen(!isOpen)}>
            <KTSVG path='/images/icons/duotune/abstract/abs015.svg' className='svg-icon-1 mt-1' />
          </div>

          <Link to='/dashboard' className='d-flex align-items-center'>
            <img
              alt='Beneksus'
              src={toAbsoluteUrl('/images/logo.svg')}
              className='h-25px'
            />
          </Link>
        </div>
        <Topbar handleClickActivity={handleClickActivity} handleClickNotification={handleClickNotification} />
      </div>
    </div>
  )
}

Header.propTypes = {
	isShow: PropTypes.bool,
};

Header.defaultProps = {
	isShow: false,
}
