    /* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { TaxType } from '../../../helpers';
import { KTCard, KTCardBody, Select, InputPrice } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, projectActions, categoryActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { projectLookup, EstateState, EstateType, Province, District, Tax } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.project);
  const { category } = useSelector((state) => state.category)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('Tax'));
    dispatch(lookupActions.basicLookup('EstateState'));
    dispatch(lookupActions.basicLookup('EstateType'));
    dispatch(lookupActions.basicLookup('Province'));
    dispatch(categoryActions.categoryGetAll({ SourceTypeId: 10 }))
  }, [])

  const onChangeDistrict = (value) => {
    formik.setFieldValue('ProvinceId', value);
    dispatch(lookupActions.districtLookup({ 'provinceId': value }));
  }

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    BasePurchasePrice: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    BaseSalePrice: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    TaxId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    EstateStateId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    PurchasePriceWithTax: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    SalePriceWithTax: Yup.number().typeError(intl.formatMessage({ id: 'REQUIRED.VALID' })).required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Name: '',
    ProjectId: null,
    EstateTypeId: null,
    GrossArea: null,
    NetArea: null,
    EstateStateId: null,
    BasePurchasePrice: 0,
    BaseSalePrice: 0,
    TaxId: 0,
    PurchasePriceWithTax: 0,
    SalePriceWithTax: 0,
    ProvinceId: null,
    DistrictId: null,
    Address: '',
    Description: '',
    CategoryId: null,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(projectActions.realEstateCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.ESTATES' }),
      path: '/projects/estates',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  const onChangeItem = (value) => {
    const tax = TaxType.find(item => item.id == value)
    const purchasePriceWithTax = formik.values.BasePurchasePrice + ((formik.values.BasePurchasePrice * (tax?.value || 0)) / 100);
    const salePriceWithTax = formik.values.BaseSalePrice + ((formik.values.BaseSalePrice * (tax?.value || 0)) / 100);

    formik.setFieldValue(`PurchasePriceWithTax`, purchasePriceWithTax);
    formik.setFieldValue(`SalePriceWithTax`, salePriceWithTax);
    formik.setFieldValue('TaxId', Number(value))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.ESTATES.ADD' })}</PageTitle>
      <KTCard>        
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REALESTATE.CREATE.CATEGORY' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={category}
                  {...formik.getFieldProps('CategoryId')}
                  onChange={item => formik.setFieldValue('CategoryId', Number(item?.value))}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.ESTATESTATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={EstateState}
                  required
                  {...formik.getFieldProps('EstateStateId')}
                  onChange={item => formik.setFieldValue('EstateStateId', Number(item?.value))}
                />
                {formik.touched.EstateStateId && formik.errors.EstateStateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.EstateStateId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.PROJECT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={projectLookup}
                  required
                  {...formik.getFieldProps('ProjectId')}
                  onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                />
                {formik.touched.ProjectId && formik.errors.ProjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>               
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.BASEPURCHASEPRICE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('BasePurchasePrice')} 
                  onChange={(value) => formik.setFieldValue('BasePurchasePrice', Number(value))} 
                />
                {formik.touched.BasePurchasePrice && formik.errors.BasePurchasePrice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.BasePurchasePrice}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.BASESALEPRICE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('BaseSalePrice')} 
                  onChange={(value) => formik.setFieldValue('BaseSalePrice', Number(value))} 
                />
                {formik.touched.BaseSalePrice && formik.errors.BaseSalePrice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.BaseSalePrice}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.TAX' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={Tax}
                  required
                  {...formik.getFieldProps('TaxId')}
                  onChange={item => onChangeItem(Number(item.value))}
                />
                {formik.touched.TaxId && formik.errors.TaxId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.TaxId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.PURCHASEPRICEWITHTAX' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('PurchasePriceWithTax')} 
                  onChange={(value) => formik.setFieldValue('PurchasePriceWithTax', Number(value))} 
                />
                {formik.touched.PurchasePriceWithTax && formik.errors.PurchasePriceWithTax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.PurchasePriceWithTax}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'REALESTATE.CREATE.SALEPRICEWITHTAX' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('SalePriceWithTax')} 
                  onChange={(value) => formik.setFieldValue('SalePriceWithTax', Number(value))} 
                />
                {formik.touched.SalePriceWithTax && formik.errors.SalePriceWithTax && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.SalePriceWithTax}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REALESTATE.CREATE.ESTATETYPE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={EstateType}
                  {...formik.getFieldProps('EstateTypeId')}
                  onChange={item => formik.setFieldValue('EstateTypeId', Number(item?.value))}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REALESTATE.CREATE.GROSSAREA' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('GrossArea')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REALESTATE.CREATE.NETAREA' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('NetArea')}
                />
              </div>
            </div>       
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REALESTATE.CREATE.PROVINCE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={Province} 
                  {...formik.getFieldProps('ProvinceId')}
                  onChange={item => onChangeDistrict(Number(item.value))}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REALESTATE.CREATE.DISTRICT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={District} 
                  {...formik.getFieldProps('DistrictId')}
                  onChange={item => formik.setFieldValue('DistrictId', Number(item?.value))}
                />
                {formik.touched.DistrictId && formik.errors.DistrictId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.DistrictId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REALESTATE.CREATE.ADDRESS' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <textarea
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={2}
                  {...formik.getFieldProps('Address')}
                />
              </div>
            </div>           
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROJECT.DESCRIPTION' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <textarea
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={2}
                  {...formik.getFieldProps('Description')}
                />
              </div>
            </div>            
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
