export const checkoutConstants = {
  //#region Checkout
  CHECKOUT_SEARCHBYSTAFFID_REQUEST: 'CHECKOUT_SEARCHBYSTAFFID_REQUEST',
  CHECKOUT_SEARCHBYSTAFFID_SUCCESS: 'CHECKOUT_SEARCHBYSTAFFID_SUCCESS',
  CHECKOUT_SEARCHBYSTAFFID_FAILURE: 'CHECKOUT_SEARCHBYSTAFFID_FAILURE',
  
  CHECKOUT_SALARYPAYMENT_REQUEST: 'CHECKOUT_SALARYPAYMENT_REQUEST',
  CHECKOUT_SALARYPAYMENT_SUCCESS: 'CHECKOUT_SALARYPAYMENT_SUCCESS',
  CHECKOUT_SALARYPAYMENT_FAILURE: 'CHECKOUT_SALARYPAYMENT_FAILURE',
  
  CHECKOUT_STAFFPAYMENT_REQUEST: 'CHECKOUT_STAFFPAYMENT_REQUEST',
  CHECKOUT_STAFFPAYMENT_SUCCESS: 'CHECKOUT_STAFFPAYMENT_SUCCESS',
  CHECKOUT_STAFFPAYMENT_FAILURE: 'CHECKOUT_STAFFPAYMENT_FAILURE',

  CHECKOUT_DELETE_REQUEST: 'CHECKOUT_DELETE_REQUEST',
  CHECKOUT_DELETE_SUCCESS: 'CHECKOUT_DELETE_SUCCESS',
  CHECKOUT_DELETE_FAILURE: 'CHECKOUT_DELETE_FAILURE',

  CHECKOUT_GETID_REQUEST: 'CHECKOUT_GETID_REQUEST',
  CHECKOUT_GETID_SUCCESS: 'CHECKOUT_GETID_SUCCESS',
  CHECKOUT_GETID_FAILURE: 'CHECKOUT_GETID_FAILURE',

  CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_REQUEST: 'CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_REQUEST',
  CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_SUCCESS: 'CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_SUCCESS',
  CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_FAILURE: 'CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_FAILURE',

  CHECKOUT_CREDITTRANSACTION_REQUEST: 'CHECKOUT_CREDITTRANSACTION_REQUEST',
  CHECKOUT_CREDITTRANSACTION_SUCCESS: 'CHECKOUT_CREDITTRANSACTION_SUCCESS',
  CHECKOUT_CREDITTRANSACTION_FAILURE: 'CHECKOUT_CREDITTRANSACTION_FAILURE',

  CHECKOUT_DEBITTRANSACTION_REQUEST: 'CHECKOUT_DEBITTRANSACTION_REQUEST',
  CHECKOUT_DEBITTRANSACTION_SUCCESS: 'CHECKOUT_DEBITTRANSACTION_SUCCESS',
  CHECKOUT_DEBITTRANSACTION_FAILURE: 'CHECKOUT_DEBITTRANSACTION_FAILURE',

  CHECKOUT_EXPENSEINVOICEPAYCHECK_REQUEST: 'CHECKOUT_EXPENSEINVOICEPAYCHECK_REQUEST',
  CHECKOUT_EXPENSEINVOICEPAYCHECK_SUCCESS: 'CHECKOUT_EXPENSEINVOICEPAYCHECK_SUCCESS',
  CHECKOUT_EXPENSEINVOICEPAYCHECK_FAILURE: 'CHECKOUT_EXPENSEINVOICEPAYCHECK_FAILURE',

  CHECKOUT_EXPENSEINVOICEADVANCECHECK_REQUEST: 'CHECKOUT_EXPENSEINVOICEADVANCECHECK_REQUEST',
  CHECKOUT_EXPENSEINVOICEADVANCECHECK_SUCCESS: 'CHECKOUT_EXPENSEINVOICEADVANCECHECK_SUCCESS',
  CHECKOUT_EXPENSEINVOICEADVANCECHECK_FAILURE: 'CHECKOUT_EXPENSEINVOICEADVANCECHECK_FAILURE',

  CHECKOUT_EXPENSEINVOICEPAYMONEY_REQUEST: 'CHECKOUT_EXPENSEINVOICEPAYMONEY_REQUEST',
  CHECKOUT_EXPENSEINVOICEPAYMONEY_SUCCESS: 'CHECKOUT_EXPENSEINVOICEPAYMONEY_SUCCESS',
  CHECKOUT_EXPENSEINVOICEPAYMONEY_FAILURE: 'CHECKOUT_EXPENSEINVOICEPAYMONEY_FAILURE',

  CHECKOUT_SALEINVOICERECEIVECHECK_REQUEST: 'CHECKOUT_SALEINVOICERECEIVECHECK_REQUEST',
  CHECKOUT_SALEINVOICERECEIVECHECK_SUCCESS: 'CHECKOUT_SALEINVOICERECEIVECHECK_SUCCESS',
  CHECKOUT_SALEINVOICERECEIVECHECK_FAILURE: 'CHECKOUT_SALEINVOICERECEIVECHECK_FAILURE',

  CHECKOUT_SALEINVOICERECEIVEMONEY_REQUEST: 'CHECKOUT_SALEINVOICERECEIVEMONEY_REQUEST',
  CHECKOUT_SALEINVOICERECEIVEMONEY_SUCCESS: 'CHECKOUT_SALEINVOICERECEIVEMONEY_SUCCESS',
  CHECKOUT_SALEINVOICERECEIVEMONEY_FAILURE: 'CHECKOUT_SALEINVOICERECEIVEMONEY_FAILURE',

  CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_REQUEST: 'CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_REQUEST',
  CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_SUCCESS: 'CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_SUCCESS',
  CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_FAILURE: 'CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_FAILURE',

  CHECKOUT_COMPANY_COLLECTION_REQUEST: 'CHECKOUT_COMPANY_COLLECTION_REQUEST',
  CHECKOUT_COMPANY_COLLECTION_SUCCESS: 'CHECKOUT_COMPANY_COLLECTION_SUCCESS',
  CHECKOUT_COMPANY_COLLECTION_FAILURE: 'CHECKOUT_COMPANY_COLLECTION_FAILURE',

  CHECKOUT_COMPANY_PAYMENT_REQUEST: 'CHECKOUT_COMPANY_PAYMENT_REQUEST',
  CHECKOUT_COMPANY_PAYMENT_SUCCESS: 'CHECKOUT_COMPANY_PAYMENT_SUCCESS',
  CHECKOUT_COMPANY_PAYMENT_FAILURE: 'CHECKOUT_COMPANY_PAYMENT_FAILURE',

  CHECKOUT_COMPANYPAYNEWCHECK_REQUEST: 'CHECKOUT_COMPANYPAYNEWCHECK_REQUEST',
  CHECKOUT_COMPANYPAYNEWCHECK_SUCCESS: 'CHECKOUT_COMPANYPAYNEWCHECK_SUCCESS',
  CHECKOUT_COMPANYPAYNEWCHECK_FAILURE: 'CHECKOUT_COMPANYPAYNEWCHECK_FAILURE',

  CHECKOUT_COMPANYPAYCHECKCHECKOUT_REQUEST: 'CHECKOUT_COMPANYPAYCHECKCHECKOUT_REQUEST',
  CHECKOUT_COMPANYPAYCHECKCHECKOUT_SUCCESS: 'CHECKOUT_COMPANYPAYCHECKCHECKOUT_SUCCESS',
  CHECKOUT_COMPANYPAYCHECKCHECKOUT_FAILURE: 'CHECKOUT_COMPANYPAYCHECKCHECKOUT_FAILURE',

  CHECKOUT_TRANSACTIONS_REQUEST: 'CHECKOUT_TRANSACTIONS_REQUEST',
  CHECKOUT_TRANSACTIONS_SUCCESS: 'CHECKOUT_TRANSACTIONS_SUCCESS',
  CHECKOUT_TRANSACTIONS_FAILURE: 'CHECKOUT_TRANSACTIONS_FAILURE',

  CHECKOUT_TRANSACTIONS_EXCEL_REQUEST: 'CHECKOUT_TRANSACTIONS_EXCEL_REQUEST',
  CHECKOUT_TRANSACTIONS_EXCEL_SUCCESS: 'CHECKOUT_TRANSACTIONS_EXCEL_SUCCESS',
  CHECKOUT_TRANSACTIONS_EXCEL_FAILURE: 'CHECKOUT_TRANSACTIONS_EXCEL_FAILURE',

  CHECKOUT_GETRECEIPT_REQUEST: 'CHECKOUT_GETRECEIPT_REQUEST',
  CHECKOUT_GETRECEIPT_SUCCESS: 'CHECKOUT_GETRECEIPT_SUCCESS',
  CHECKOUT_GETRECEIPT_FAILURE: 'CHECKOUT_GETRECEIPT_FAILURE',

  CHECKOUT_UPDATEDESCRIPTION_REQUEST: 'CHECKOUT_UPDATEDESCRIPTION_REQUEST',
  CHECKOUT_UPDATEDESCRIPTION_SUCCESS: 'CHECKOUT_UPDATEDESCRIPTION_SUCCESS',
  CHECKOUT_UPDATEDESCRIPTION_FAILURE: 'CHECKOUT_UPDATEDESCRIPTION_FAILURE',
  //#endregion

  //#region Checkout Approve
  CHECKOUT_APPROVEGETALL_REQUEST: 'CHECKOUT_APPROVEGETALL_REQUEST',
  CHECKOUT_APPROVEGETALL_SUCCESS: 'CHECKOUT_APPROVEGETALL_SUCCESS',
  CHECKOUT_APPROVEGETALL_FAILURE: 'CHECKOUT_APPROVEGETALL_FAILURE',

  CHECKOUT_APPROVECONFIRM_REQUEST: 'CHECKOUT_APPROVECONFIRM_REQUEST',
  CHECKOUT_APPROVECONFIRM_SUCCESS: 'CHECKOUT_APPROVECONFIRM_SUCCESS',
  CHECKOUT_APPROVECONFIRM_FAILURE: 'CHECKOUT_APPROVECONFIRM_FAILURE',
  //#endregion
};
