/* eslint-disable react/react-in-jsx-scope */
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import { useMsal } from "@azure/msal-react";
import { toAbsoluteUrl} from '../../helpers'
import {Languages} from './Languages'
import {useIntl} from 'react-intl'

const UserMenu = ({isOpen, user}) => {
  const { instance } = useMsal();
  const intl = useIntl()

  const styles = {
    zIndex: '105',
    position: 'fixed',
    inset: 'auto 0px 0px auto',
    margin: '0',
    left: '60px',
    bottom: '115px'
  }

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  return (
    <div
      className={clsx('menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px', {'show': isOpen} )}
      style={ isOpen ? {...styles} : {} }
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='User' src={toAbsoluteUrl('/images/blank.png')} />
          </div>

          <div className='d-flex flex-column w-75'>
            <div className='fw-bolder d-flex align-items-center fs-5 text-truncate'>
              {user?.firstName} {user?.lastName}
            </div>
            <div className='fw-bold text-muted fs-7 text-truncate'>
              {user?.email}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'MENU.PROFILE.PROFILE' })}
        </Link>
      </div>

      <div className='separator my-2'> </div>

      <Languages languageMenuPlacement='right-end' />

      <div className='menu-item px-5 my-1'>
        <a href='https://account.microsoft.com/security' target={'_blank'} className='menu-link px-5' rel="noreferrer">
          {intl.formatMessage({id: 'MENU.PROFILE.SECURITY' })}
        </a>
      </div>

      <div className='menu-item px-5'>
        <span onClick={handleLogout} className='menu-link px-5'>{intl.formatMessage({id: 'MENU.PROFILE.EXIT' })}</span>
      </div>
    </div>
  )
}

export {UserMenu}
