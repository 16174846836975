/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import {useIntl} from 'react-intl'
import { KTCard, KTCardBody } from '../../components'
import { PageTitle } from '../../core';

import { useDispatch, useSelector } from 'react-redux';
import { profileActions } from '../../redux/actions';
import { Link } from 'react-router-dom';

function Profile() {
  const intl = useIntl()
  const { userProfile } = useSelector(state => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileActions.userProfileGet());
  }, [])

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.PROFILE.PROFILE' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <div className="d-flex flex-center flex-column py-5">
            <div className="symbol symbol-100px symbol-circle mb-7">
              <img src="/images/blank.png" alt="image" />
            </div>
            <div className="fs-3 text-gray-800 fw-bold mb-3">{userProfile?.firstName} {userProfile?.lastName}</div>
            <div className="fw-bold mb-3">
              { userProfile?.email }
            </div>
            <div className="separator"></div>
            <Link to={'/profile/edit'} className="btn btn-sm btn-light-primary">{intl.formatMessage({id: 'BUTTON.EDIT' })}</Link>
          </div>
          <div className="separator"></div>
          <div className="pt-5 fs-6 d-flex flex-column">
            <table className='table align-middle table-row-dashed gy-5 fs-6 table-striped table-hover'>
              <thead>
                <tr>
                  <th className='p-5'>
                    <div className="d-flex flex-stack fs-4 py-3">
                      <div className="fw-bold">{intl.formatMessage({ id: 'PROFILE.GET.ROLESWITHNAME' })}</div>
                    </div>
                  </th>
                </tr>
              </thead>
              {userProfile?.rolesWithName != null && <><tbody>
                {userProfile?.rolesWithName.map((item, index) => <tr key={index}>
                  <td className='p-5'>
                    <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                      {item.name}
                    </div>
                  </td>
                </tr>)}
              </tbody>
              </>}
            </table>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}
export {Profile};
