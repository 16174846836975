import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../redux/actions';

import { pagination } from '../../../helpers';

function List() {
  const intl = useIntl();
  const { user, loading } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [params, setParams] = useState(pagination);

  useEffect(() => {
    dispatch(userActions.userGetAll(params));
  }, [params]);

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USER.SEARCH.DISPLAYNAME' })} className='min-w-125px' />,
      accessor: 'displayName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'USER.SEARCH.EMAIL' })} className='min-w-125px' />,
      accessor: 'email',
      Cell: ({ ...props }) => <CustomCell item={props}/>,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    }
  ];

  const buttons = [
    {
      title: 'BUTTON.USER.ADD',
      to: '/admin/users/create',
      icon: '/images/icons/duotune/arrows/arr075.svg',
      variant: 'primary'
    }
  ]

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(userActions.userExcel(params, intl.formatMessage({ id: 'BC.USERS' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.USERS' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          buttons={buttons}
          actionButtons={actionButtons}
        />
        <KTCardBody>
          {!user && <ListLoading />}
          {user && <Table
            items={user}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
