export const accountConstants = {
  //#region Account
  BANKCASHACCOUNT_GETALL_REQUEST: 'BANKCASHACCOUNT_GETALL_REQUEST',
  BANKCASHACCOUNT_GETALL_SUCCESS: 'BANKCASHACCOUNT_GETALL_SUCCESS',
  BANKCASHACCOUNT_GETALL_FAILURE: 'BANKCASHACCOUNT_GETALL_FAILURE',

  ACCOUNT_SEARCHTRANSACTION_REQUEST: 'ACCOUNT_SEARCHTRANSACTION_REQUEST',
  ACCOUNT_SEARCHTRANSACTION_SUCCESS: 'ACCOUNT_SEARCHTRANSACTION_SUCCESS',
  ACCOUNT_SEARCHTRANSACTION_FAILURE: 'ACCOUNT_SEARCHTRANSACTION_FAILURE',

  BANKCASHACCOUNT_GETID_REQUEST: 'BANKCASHACCOUNT_GETID_REQUEST',
  BANKCASHACCOUNT_GETID_SUCCESS: 'BANKCASHACCOUNT_GETID_SUCCESS',
  BANKCASHACCOUNT_GETID_FAILURE: 'BANKCASHACCOUNT_GETID_FAILURE',

  ACCOUNT_DELETE_REQUEST: 'ACCOUNT_DELETE_REQUEST',
  ACCOUNT_DELETE_SUCCESS: 'ACCOUNT_DELETE_SUCCESS',
  ACCOUNT_DELETE_FAILURE: 'ACCOUNT_DELETE_FAILURE',

  BANKACCOUNT_CREATE_REQUEST: 'BANKACCOUNT_CREATE_REQUEST',
  BANKACCOUNT_CREATE_SUCCESS: 'BANKACCOUNT_CREATE_SUCCESS',
  BANKACCOUNT_CREATE_FAILURE: 'BANKACCOUNT_CREATE_FAILURE',

  CASHACCOUNT_CREATE_REQUEST: 'CASHACCOUNT_CREATE_REQUEST',
  CASHACCOUNT_CREATE_SUCCESS: 'CASHACCOUNT_CREATE_SUCCESS',
  CASHACCOUNT_CREATE_FAILURE: 'CASHACCOUNT_CREATE_FAILURE',

  BANKACCOUNT_UPDATE_REQUEST: 'BANKACCOUNT_UPDATE_REQUEST',
  BANKACCOUNT_UPDATE_SUCCESS: 'BANKACCOUNT_UPDATE_SUCCESS',
  BANKACCOUNT_UPDATE_FAILURE: 'BANKACCOUNT_UPDATE_FAILURE',

  CASHACCOUNT_UPDATE_REQUEST: 'CASHACCOUNT_UPDATE_REQUEST',
  CASHACCOUNT_UPDATE_SUCCESS: 'CASHACCOUNT_UPDATE_SUCCESS',
  CASHACCOUNT_UPDATE_FAILURE: 'CASHACCOUNT_UPDATE_FAILURE',

  ACCOUNT_ARCHIVE_REQUEST: 'ACCOUNT_ARCHIVE_REQUEST',
  ACCOUNT_ARCHIVE_SUCCESS: 'ACCOUNT_ARCHIVE_SUCCESS',
  ACCOUNT_ARCHIVE_FAILURE: 'ACCOUNT_ARCHIVE_FAILURE',

  BANKCASHACCOUNT_EXCEL_REQUEST: 'BANKCASHACCOUNT_EXCEL_REQUEST',
  BANKCASHACCOUNT_EXCEL_SUCCESS: 'BANKCASHACCOUNT_EXCEL_SUCCESS',
  BANKCASHACCOUNT_EXCEL_FAILURE: 'BANKCASHACCOUNT_EXCEL_FAILURE',

  CURRENTACCOUNT_GET_REQUEST: 'CURRENTACCOUNT_GET_REQUEST',
  CURRENTACCOUNT_GET_SUCCESS: 'CURRENTACCOUNT_GET_SUCCESS',
  CURRENTACCOUNT_GET_FAILURE: 'CURRENTACCOUNT_GET_FAILURE',
  //#endregion            
};
