import { projectConstants } from '../constants';
import {
  //#region Project
  SearchProjectClient,
  GetProjectClient,
  DeleteProjectClient,
  UpdateProjectClient,
  CreateProjectClient,
  ArchivingProjectClient,
  //#endregion

  //#region RealEstate
  SearchRealEstateClient,
  GetRealEstateClient,
  DeleteRealEstateClient,
  UpdateRealEstateClient,
  CreateRealEstateClient,
  ArchivingRealEstateClient,
  //#endregion

  //#region Contracts
  SearchContractClient,
  GetContractClient,
  DeleteContractClient,
  UpdateContractClient,
  CreateContractClient,
  //#endregion

  //#region Constructionsite
  SearchConstructionSiteClient,
  CreateConstructionSiteClient,
  UpdateConstructionSiteClient,
  DeleteConstructionSiteClient,
  GetConstructionSiteClient,
  //#endregion

  //#region Block
  CreateBlockClient,
  UpdateBlockClient,
  DeleteBlockClient,
  GetBlockClient,
  SearchBlockClient,
  //#endregion

  //#region Buildingblocktype
  CreateBuildingBlockTypeClient,
  UpdateBuildingBlockTypeClient,
  DeleteBuildingBlockTypeClient,
  GetBuildingBlockTypeClient,
  SearchBuildingBlockTypeClient,
  //#endregion

  //#region Plot
  CreatePlotClient,
  UpdatePlotClient,
  DeletePlotClient,
  GetPlotClient,
  SearchPlotClient
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//const SERVICE_URL = "https://localhost:7016";

//#region Project
const archivingProjectClient = new ArchivingProjectClient(SERVICE_URL);
const searchProjectClient = new SearchProjectClient(SERVICE_URL);
const getProjectClient = new GetProjectClient(SERVICE_URL);
const createProjectClient = new CreateProjectClient(SERVICE_URL);
const deleteProjectClient = new DeleteProjectClient(SERVICE_URL);
const updateProjectClient = new UpdateProjectClient(SERVICE_URL);
//#endregion

//#region RealEstate
const archivingRealEstateClient = new ArchivingRealEstateClient(SERVICE_URL);
const searchRealEstateClient = new SearchRealEstateClient(SERVICE_URL);
const getRealEstateClient = new GetRealEstateClient(SERVICE_URL);
const createRealEstateClient = new CreateRealEstateClient(SERVICE_URL);
const deleteRealEstateClient = new DeleteRealEstateClient(SERVICE_URL);
const updateRealEstateClient = new UpdateRealEstateClient(SERVICE_URL);
//#endregion

//#region Contracts
const searchContractClient = new SearchContractClient(SERVICE_URL);
const getContractClient = new GetContractClient(SERVICE_URL);
const deleteContractClient = new DeleteContractClient(SERVICE_URL);
const updateContractClient = new UpdateContractClient(SERVICE_URL);
const createContractClient = new CreateContractClient(SERVICE_URL);
//#endregion

//#region Constructionsite
const searchConstructionSiteClient = new SearchConstructionSiteClient(SERVICE_URL);
const createConstructionSiteClient = new CreateConstructionSiteClient(SERVICE_URL);
const updateConstructionSiteClient = new UpdateConstructionSiteClient(SERVICE_URL);
const deleteConstructionSiteClient = new DeleteConstructionSiteClient(SERVICE_URL);
const getConstructionSiteClient = new GetConstructionSiteClient(SERVICE_URL);
//#endregion

//#region Block
const createBlockClient = new CreateBlockClient(SERVICE_URL);
const updateBlockClient = new UpdateBlockClient(SERVICE_URL);
const deleteBlockClient = new DeleteBlockClient(SERVICE_URL);
const getBlockClient = new GetBlockClient(SERVICE_URL);
const searchBlockClient = new SearchBlockClient(SERVICE_URL);
//#endregion

//#region Buildingblocktype
const createBuildingBlockTypeClient = new CreateBuildingBlockTypeClient(SERVICE_URL);
const updateBuildingBlockTypeClient = new UpdateBuildingBlockTypeClient(SERVICE_URL);
const deleteBuildingBlockTypeClient = new DeleteBuildingBlockTypeClient(SERVICE_URL);
const getBuildingBlockTypeClient = new GetBuildingBlockTypeClient(SERVICE_URL);
const searchBuildingBlockTypeClient = new SearchBuildingBlockTypeClient(SERVICE_URL);
//#endregion

//#region Plot
const createPlotClient = new CreatePlotClient(SERVICE_URL);
const updatePlotClient = new UpdatePlotClient(SERVICE_URL);
const deletePlotClient = new DeletePlotClient(SERVICE_URL);
const getPlotClient = new GetPlotClient(SERVICE_URL);
const searchPlotClient = new SearchPlotClient(SERVICE_URL);
//#endregion

export const projectActions = {
  //#region Project
  projectGetAll,
  projectGetId,
  projectDelete,
  projectCreate,
  projectUpdate,
  projectArchiving,
  projectExcel,
  //#endregion

  //#region RealEstate
  realEstateGetAll,
  realEstateGetId,
  realEstateDelete,
  realEstateCreate,
  realEstateUpdate,
  realEstateArchiving,
  realEstateExcel,
  //#endregion

  //#region Contracts
  contractsGetAll,
  contractsGetId,
  contractsDelete,
  contractsCreate,
  contractsUpdate,
  contractsExcel,
  //#endregion

  //#region Constructionsite
  constructionsiteGetAll,
  constructionsiteGetId,
  constructionsiteDelete,
  constructionsiteCreate,
  constructionsiteUpdate,
  constructionsiteExcel,
  //#endregion

  //#region Block
  blockGetAll,
  blockGetId,
  blockDelete,
  blockCreate,
  blockUpdate,
  blockExcel,
  //#endregion

  //#region Buildingblocktype
  buildingblocktypeGetAll,
  buildingblocktypeGetId,
  buildingblocktypeDelete,
  buildingblocktypeCreate,
  buildingblocktypeUpdate,
  buildingblocktypeExcel,
  //#endregion

  //#region Plot
  plotGetAll,
  plotGetId,
  plotDelete,
  plotCreate,
  plotUpdate,
  plotExcel,
  //#endregion
};

//#region Project
function projectGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchProjectClient.search(params.name, params.projectStateId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        project => dispatch(success(project)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.PROJECT_GETALL_REQUEST } }
  function success(project) { return { type: projectConstants.PROJECT_GETALL_SUCCESS, project } }
  function failure(error) { return { type: projectConstants.PROJECT_GETALL_FAILURE, error } }
}

function projectExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchProjectClient.excel(params.name, params.projectStateId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.PROJECT_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: projectConstants.PROJECT_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: projectConstants.PROJECT_EXCEL_FAILURE, error } }
}

function projectGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getProjectClient.get(id)
      .then(
        project => dispatch(success(project)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: projectConstants.PROJECT_GETID_REQUEST, id } }
  function success(project) { return { type: projectConstants.PROJECT_GETID_SUCCESS, project } }
  function failure(error) { return { type: projectConstants.PROJECT_GETID_FAILURE, error } }
}

function projectDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteProjectClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.PROJECT_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: projectConstants.PROJECT_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: projectConstants.PROJECT_DELETE_FAILURE, error } }
}

function projectArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingProjectClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.PROJECT_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: projectConstants.PROJECT_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: projectConstants.PROJECT_ARCHIVE_FAILURE, error } }
}

function projectCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createProjectClient.create(params)
      .then(
        project => dispatch(success(project)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.PROJECT_CREATE_REQUEST, params } }
  function success(project) { return { type: projectConstants.PROJECT_CREATE_SUCCESS, project } }
  function failure(error) { return { type: projectConstants.PROJECT_CREATE_FAILURE, error } }
}

function projectUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateProjectClient.update(params)
      .then(
        project => dispatch(success(project, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: projectConstants.PROJECT_UPDATE_REQUEST, params } }
  function success(project, params) { return { type: projectConstants.PROJECT_UPDATE_SUCCESS, project, params } }
  function failure(error, params) { return { type: projectConstants.PROJECT_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region RealEstate
function realEstateGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchRealEstateClient.search(params.name, params.estateTypeId, params.estateStateId, params.projectId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        realEstate => dispatch(success(realEstate)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.REALESTATE_GETALL_REQUEST } }
  function success(realEstate) { return { type: projectConstants.REALESTATE_GETALL_SUCCESS, realEstate } }
  function failure(error) { return { type: projectConstants.REALESTATE_GETALL_FAILURE, error } }
}

function realEstateExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchRealEstateClient.excel(params.name, params.estateTypeId, params.estateStateId, params.projectId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.REALESTATE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: projectConstants.REALESTATE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: projectConstants.REALESTATE_EXCEL_FAILURE, error } }
}

function realEstateGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getRealEstateClient.get(id)
      .then(
        realEstate => dispatch(success(realEstate)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: projectConstants.REALESTATE_GETID_REQUEST, id } }
  function success(realEstate) { return { type: projectConstants.REALESTATE_GETID_SUCCESS, realEstate } }
  function failure(error) { return { type: projectConstants.REALESTATE_GETID_FAILURE, error } }
}

function realEstateDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteRealEstateClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.REALESTATE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: projectConstants.REALESTATE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: projectConstants.REALESTATE_DELETE_FAILURE, error } }
}

function realEstateArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingRealEstateClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.REALESTATE_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: projectConstants.REALESTATE_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: projectConstants.REALESTATE_ARCHIVE_FAILURE, error } }
}

function realEstateCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createRealEstateClient.create(params)
      .then(
        realEstate => dispatch(success(realEstate)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.REALESTATE_CREATE_REQUEST, params } }
  function success(realEstate) { return { type: projectConstants.REALESTATE_CREATE_SUCCESS, realEstate } }
  function failure(error) { return { type: projectConstants.REALESTATE_CREATE_FAILURE, error } }
}

function realEstateUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateRealEstateClient.update(params)
      .then(
        realEstate => dispatch(success(realEstate, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: projectConstants.REALESTATE_UPDATE_REQUEST, params } }
  function success(realEstate, params) { return { type: projectConstants.REALESTATE_UPDATE_SUCCESS, realEstate, params } }
  function failure(error, params) { return { type: projectConstants.REALESTATE_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Contracts
function contractsGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchContractClient.search(params.name, params.projectId, params.startDate, params.endDate, params.contractTypeId, params.contractStateId, params.companyId, params.contractName, params.items_per_page, params.page, params.order, params.sort)
      .then(
        contracts => dispatch(success(contracts)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.CONTRACTS_GETALL_REQUEST } }
  function success(contracts) { return { type: projectConstants.CONTRACTS_GETALL_SUCCESS, contracts } }
  function failure(error) { return { type: projectConstants.CONTRACTS_GETALL_FAILURE, error } }
}

function contractsExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchContractClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.CONTRACTS_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: projectConstants.CONTRACTS_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: projectConstants.CONTRACTS_EXCEL_FAILURE, error } }
}

function contractsGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getContractClient.get(id)
      .then(
        contracts => dispatch(success(contracts)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: projectConstants.CONTRACTS_GETID_REQUEST, id } }
  function success(contracts) { return { type: projectConstants.CONTRACTS_GETID_SUCCESS, contracts } }
  function failure(error) { return { type: projectConstants.CONTRACTS_GETID_FAILURE, error } }
}

function contractsDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteContractClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.CONTRACTS_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: projectConstants.CONTRACTS_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: projectConstants.CONTRACTS_DELETE_FAILURE, error } }
}

function contractsCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createContractClient.create(params)
      .then(
        contracts => dispatch(success(contracts)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.CONTRACTS_CREATE_REQUEST, params } }
  function success(contracts) { return { type: projectConstants.CONTRACTS_CREATE_SUCCESS, contracts } }
  function failure(error) { return { type: projectConstants.CONTRACTS_CREATE_FAILURE, error } }
}

function contractsUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateContractClient.update(params)
      .then(
        contracts => dispatch(success(contracts, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: projectConstants.CONTRACTS_UPDATE_REQUEST, params } }
  function success(contracts, params) { return { type: projectConstants.CONTRACTS_UPDATE_SUCCESS, contracts, params } }
  function failure(error, params) { return { type: projectConstants.CONTRACTS_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Constructionsite
function constructionsiteGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchConstructionSiteClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        constructionsite => dispatch(success(constructionsite)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.CONSTRUCTIONSITE_GETALL_REQUEST } }
  function success(constructionsite) { return { type: projectConstants.CONSTRUCTIONSITE_GETALL_SUCCESS, constructionsite } }
  function failure(error) { return { type: projectConstants.CONSTRUCTIONSITE_GETALL_FAILURE, error } }
}

function constructionsiteExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchConstructionSiteClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.CONSTRUCTIONSITE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: projectConstants.CONSTRUCTIONSITE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: projectConstants.CONSTRUCTIONSITE_EXCEL_FAILURE, error } }
}

function constructionsiteGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getConstructionSiteClient.get(id)
      .then(
        constructionsite => dispatch(success(constructionsite)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: projectConstants.CONSTRUCTIONSITE_GETID_REQUEST, id } }
  function success(constructionsite) { return { type: projectConstants.CONSTRUCTIONSITE_GETID_SUCCESS, constructionsite } }
  function failure(error) { return { type: projectConstants.CONSTRUCTIONSITE_GETID_FAILURE, error } }
}

function constructionsiteDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteConstructionSiteClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.CONSTRUCTIONSITE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: projectConstants.CONSTRUCTIONSITE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: projectConstants.CONSTRUCTIONSITE_DELETE_FAILURE, error } }
}

function constructionsiteCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createConstructionSiteClient.create(params)
      .then(
        constructionsite => dispatch(success(constructionsite)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.CONSTRUCTIONSITE_CREATE_REQUEST, params } }
  function success(constructionsite) { return { type: projectConstants.CONSTRUCTIONSITE_CREATE_SUCCESS, constructionsite } }
  function failure(error) { return { type: projectConstants.CONSTRUCTIONSITE_CREATE_FAILURE, error } }
}

function constructionsiteUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateConstructionSiteClient.update(params)
      .then(
        constructionsite => dispatch(success(constructionsite, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: projectConstants.CONSTRUCTIONSITE_UPDATE_REQUEST, params } }
  function success(constructionsite, params) { return { type: projectConstants.CONSTRUCTIONSITE_UPDATE_SUCCESS, constructionsite, params } }
  function failure(error, params) { return { type: projectConstants.CONSTRUCTIONSITE_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Block
function blockGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchBlockClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        block => dispatch(success(block)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.BLOCK_GETALL_REQUEST } }
  function success(block) { return { type: projectConstants.BLOCK_GETALL_SUCCESS, block } }
  function failure(error) { return { type: projectConstants.BLOCK_GETALL_FAILURE, error } }
}

function blockExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchBlockClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.BLOCK_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: projectConstants.BLOCK_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: projectConstants.BLOCK_EXCEL_FAILURE, error } }
}

function blockGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getBlockClient.get(id)
      .then(
        block => dispatch(success(block)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: projectConstants.BLOCK_GETID_REQUEST, id } }
  function success(block) { return { type: projectConstants.BLOCK_GETID_SUCCESS, block } }
  function failure(error) { return { type: projectConstants.BLOCK_GETID_FAILURE, error } }
}

function blockDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteBlockClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.BLOCK_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: projectConstants.BLOCK_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: projectConstants.BLOCK_DELETE_FAILURE, error } }
}

function blockCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createBlockClient.create(params)
      .then(
        block => dispatch(success(block)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.BLOCK_CREATE_REQUEST, params } }
  function success(block) { return { type: projectConstants.BLOCK_CREATE_SUCCESS, block } }
  function failure(error) { return { type: projectConstants.BLOCK_CREATE_FAILURE, error } }
}

function blockUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateBlockClient.update(params)
      .then(
        block => dispatch(success(block, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: projectConstants.BLOCK_UPDATE_REQUEST, params } }
  function success(block, params) { return { type: projectConstants.BLOCK_UPDATE_SUCCESS, block, params } }
  function failure(error, params) { return { type: projectConstants.BLOCK_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Buildingblocktype
function buildingblocktypeGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchBuildingBlockTypeClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        buildingblocktype => dispatch(success(buildingblocktype)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.BUILDINGBLOCKTYPE_GETALL_REQUEST } }
  function success(buildingblocktype) { return { type: projectConstants.BUILDINGBLOCKTYPE_GETALL_SUCCESS, buildingblocktype } }
  function failure(error) { return { type: projectConstants.BUILDINGBLOCKTYPE_GETALL_FAILURE, error } }
}

function buildingblocktypeExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchBuildingBlockTypeClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.BUILDINGBLOCKTYPE_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: projectConstants.BUILDINGBLOCKTYPE_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: projectConstants.BUILDINGBLOCKTYPE_EXCEL_FAILURE, error } }
}

function buildingblocktypeGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getBuildingBlockTypeClient.get(id)
      .then(
        buildingblocktype => dispatch(success(buildingblocktype)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: projectConstants.BUILDINGBLOCKTYPE_GETID_REQUEST, id } }
  function success(buildingblocktype) { return { type: projectConstants.BUILDINGBLOCKTYPE_GETID_SUCCESS, buildingblocktype } }
  function failure(error) { return { type: projectConstants.BUILDINGBLOCKTYPE_GETID_FAILURE, error } }
}

function buildingblocktypeDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteBuildingBlockTypeClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.BUILDINGBLOCKTYPE_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: projectConstants.BUILDINGBLOCKTYPE_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: projectConstants.BUILDINGBLOCKTYPE_DELETE_FAILURE, error } }
}

function buildingblocktypeCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createBuildingBlockTypeClient.create(params)
      .then(
        buildingblocktype => dispatch(success(buildingblocktype)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.BUILDINGBLOCKTYPE_CREATE_REQUEST, params } }
  function success(buildingblocktype) { return { type: projectConstants.BUILDINGBLOCKTYPE_CREATE_SUCCESS, buildingblocktype } }
  function failure(error) { return { type: projectConstants.BUILDINGBLOCKTYPE_CREATE_FAILURE, error } }
}

function buildingblocktypeUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateBuildingBlockTypeClient.update(params)
      .then(
        buildingblocktype => dispatch(success(buildingblocktype, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: projectConstants.BUILDINGBLOCKTYPE_UPDATE_REQUEST, params } }
  function success(buildingblocktype, params) { return { type: projectConstants.BUILDINGBLOCKTYPE_UPDATE_SUCCESS, buildingblocktype, params } }
  function failure(error, params) { return { type: projectConstants.BUILDINGBLOCKTYPE_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region Plot
function plotGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchPlotClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        plot => dispatch(success(plot)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.PLOT_GETALL_REQUEST } }
  function success(plot) { return { type: projectConstants.PLOT_GETALL_SUCCESS, plot } }
  function failure(error) { return { type: projectConstants.PLOT_GETALL_FAILURE, error } }
}

function plotExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchPlotClient.excel(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: projectConstants.PLOT_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: projectConstants.PLOT_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: projectConstants.PLOT_EXCEL_FAILURE, error } }
}

function plotGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getPlotClient.get(id)
      .then(
        plot => dispatch(success(plot)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: projectConstants.PLOT_GETID_REQUEST, id } }
  function success(plot) { return { type: projectConstants.PLOT_GETID_SUCCESS, plot } }
  function failure(error) { return { type: projectConstants.PLOT_GETID_FAILURE, error } }
}

function plotDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deletePlotClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.PLOT_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: projectConstants.PLOT_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: projectConstants.PLOT_DELETE_FAILURE, error } }
}

function plotCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createPlotClient.create(params)
      .then(
        plot => dispatch(success(plot)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: projectConstants.PLOT_CREATE_REQUEST, params } }
  function success(plot) { return { type: projectConstants.PLOT_CREATE_SUCCESS, plot } }
  function failure(error) { return { type: projectConstants.PLOT_CREATE_FAILURE, error } }
}

function plotUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updatePlotClient.update(params)
      .then(
        plot => dispatch(success(plot, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: projectConstants.PLOT_UPDATE_REQUEST, params } }
  function success(plot, params) { return { type: projectConstants.PLOT_UPDATE_SUCCESS, plot, params } }
  function failure(error, params) { return { type: projectConstants.PLOT_UPDATE_FAILURE, error, params } }
}
//#endregion