/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { toAbsoluteUrl, KTSVG } from '../../../helpers'
import { KTCard, KTCardBody, KTCardHeader, Earning, Select } from '../../../components';

import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, staffActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { LeaveType } = useSelector(state => state.lookup);
  const { isRedirect, detail, loading } = useSelector(state => state.staff);
	const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.staffGetAll());
    dispatch(lookupActions.basicLookup('LeaveType'));
    dispatch(staffActions.leaveGetId(id));
  }, [])

  const validSchema = Yup.object().shape({
    StartDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    DayCount: Yup.number().typeError(intl.formatMessage({id: 'REQUIRED.VALID' })).required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    LeaveTypeId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: '',
    StartDate: '',
    EndDate: '',
    DayCount: '',
    LeaveTypeId: '',
    Name: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(staffActions.leaveUpdate(values));
    },
  })

  useEffect(() => {
    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);
      for (const values in formValues) {
        if (values === propVal) {
          formik.setFieldValue(propVal, property === 'startDate' || property === 'endDate' ? new Date(detail[property]) : detail[property])
        }
      }
    }
  }, [detail])

  const breadcrumbs = [    
    {
      title: intl.formatMessage({ id: 'BC.LEAVE' }),
      path: '/staff/leave',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({id: 'PAGE_TITLE.DETAIL' }),
      path: `/staff/leave/detail/${id}`,
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.LEAVE.EDIT' })}</PageTitle>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          <KTCard>           
            <KTCardBody>
              {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>

                <div className="row mb-7">
                  <div className="d-flex align-items-center mb-7">
                    <div className="symbol symbol-50px me-5">
                      <img src={toAbsoluteUrl('/images/icons/duotune/communication/com013.svg')} alt="" />
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-dark fw-bold fs-6">{detail.staffNameSurname}</div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'LEAVE.TYPENAME' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <Select 
                      data={LeaveType}
                      required
                      {...formik.getFieldProps('LeaveTypeId')}
                      onChange={item => formik.setFieldValue('LeaveTypeId', Number(item?.value))}
                    />
                    {formik.touched.LeaveTypeId && formik.errors.LeaveTypeId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.LeaveTypeId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'EXPLANATION' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <textarea
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      rows={4}
                      {...formik.getFieldProps('Name')}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'LEAVE.DATE.START' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr 
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        required
                        {...formik.getFieldProps('StartDate')}
                        onChange={([date]) => formik.setFieldValue('StartDate', date)}
                      />
                    </div>
                    {formik.touched.StartDate && formik.errors.StartDate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.StartDate}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'LEAVE.DATE.END' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <div className="position-relative d-flex align-items-center">
                      <KTSVG
                        path='/images/icons/duotune/general/gen014.svg'
                        className='svg-icon svg-icon-2 position-absolute mx-4'
                      />
                      <Flatpickr 
                        className="form-control form-control-solid ps-12"
                        options={{ "locale": Turkish }}
                        {...formik.getFieldProps('EndDate')}
                        onChange={([date]) => formik.setFieldValue('EndDate', date)}
                      />
                    </div>
                    {formik.touched.EndDate && formik.errors.EndDate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.EndDate}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'LEAVE.DAYCOUNT' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='number'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      required
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('DayCount')}
                    />
                    {formik.touched.DayCount && formik.errors.DayCount && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.DayCount}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className='row pt-15'>
                  <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                    <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  </div>
                </div>
              </form> }
            </KTCardBody>
          </KTCard>
        </div>

        <div className='col-xl-3'>
          {detail?.staffId && <Earning StaffId={detail?.staffId} /> }
        </div>
      </div>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Edit};
