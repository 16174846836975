export const purchaseConstants = {
  //#region Purchase Request
  PURCHASEREQUEST_GETALL_REQUEST: 'PURCHASEREQUEST_GETALL_REQUEST',
  PURCHASEREQUEST_GETALL_SUCCESS: 'PURCHASEREQUEST_GETALL_SUCCESS',
  PURCHASEREQUEST_GETALL_FAILURE: 'PURCHASEREQUEST_GETALL_FAILURE',

  PURCHASEREQUEST_GETID_REQUEST: 'PURCHASEREQUEST_GETID_REQUEST',
  PURCHASEREQUEST_GETID_SUCCESS: 'PURCHASEREQUEST_GETID_SUCCESS',
  PURCHASEREQUEST_GETID_FAILURE: 'PURCHASEREQUEST_GETID_FAILURE',

  PURCHASEREQUEST_DELETE_REQUEST: 'PURCHASEREQUEST_DELETE_REQUEST',
  PURCHASEREQUEST_DELETE_SUCCESS: 'PURCHASEREQUEST_DELETE_SUCCESS',
  PURCHASEREQUEST_DELETE_FAILURE: 'PURCHASEREQUEST_DELETE_FAILURE',

  PURCHASEREQUEST_CREATE_REQUEST: 'PURCHASEREQUEST_CREATE_REQUEST',
  PURCHASEREQUEST_CREATE_SUCCESS: 'PURCHASEREQUEST_CREATE_SUCCESS',
  PURCHASEREQUEST_CREATE_FAILURE: 'PURCHASEREQUEST_CREATE_FAILURE',

  PURCHASEREQUEST_UPDATE_REQUEST: 'PURCHASEREQUEST_UPDATE_REQUEST',
  PURCHASEREQUEST_UPDATE_SUCCESS: 'PURCHASEREQUEST_UPDATE_SUCCESS',
  PURCHASEREQUEST_UPDATE_FAILURE: 'PURCHASEREQUEST_UPDATE_FAILURE',

  PURCHASEREQUEST_UPDATESTATUS_REQUEST: 'PURCHASEREQUEST_UPDATESTATUS_REQUEST',
  PURCHASEREQUEST_UPDATESTATUS_SUCCESS: 'PURCHASEREQUEST_UPDATESTATUS_SUCCESS',
  PURCHASEREQUEST_UPDATESTATUS_FAILURE: 'PURCHASEREQUEST_UPDATESTATUS_FAILURE',

  PURCHASEREQUEST_ARCHIVE_REQUEST: 'PURCHASEREQUEST_ARCHIVE_REQUEST',
  PURCHASEREQUEST_ARCHIVE_SUCCESS: 'PURCHASEREQUEST_ARCHIVE_SUCCESS',
  PURCHASEREQUEST_ARCHIVE_FAILURE: 'PURCHASEREQUEST_ARCHIVE_FAILURE',

  PURCHASEREQUEST_EXCEL_REQUEST: 'PURCHASEREQUEST_EXCEL_REQUEST',
  PURCHASEREQUEST_EXCEL_SUCCESS: 'PURCHASEREQUEST_EXCEL_SUCCESS',
  PURCHASEREQUEST_EXCEL_FAILURE: 'PURCHASEREQUEST_EXCEL_FAILURE',
  //#endregion

  //#region Purchase Proposal
  PURCHASEPROPOSAL_DELETE_REQUEST: 'PURCHASEPROPOSAL_DELETE_REQUEST',
  PURCHASEPROPOSAL_DELETE_SUCCESS: 'PURCHASEPROPOSAL_DELETE_SUCCESS',
  PURCHASEPROPOSAL_DELETE_FAILURE: 'PURCHASEPROPOSAL_DELETE_FAILURE',
                                            
  PURCHASEPROPOSAL_CREATE_REQUEST: 'PURCHASEPROPOSAL_CREATE_REQUEST',
  PURCHASEPROPOSAL_CREATE_SUCCESS: 'PURCHASEPROPOSAL_CREATE_SUCCESS',
  PURCHASEPROPOSAL_CREATE_FAILURE: 'PURCHASEPROPOSAL_CREATE_FAILURE',
  //#endregion

  //#region Purchase Order
  PURCHASEORDER_GETALL_REQUEST: 'PURCHASEORDER_GETALL_REQUEST',
  PURCHASEORDER_GETALL_SUCCESS: 'PURCHASEORDER_GETALL_SUCCESS',
  PURCHASEORDER_GETALL_FAILURE: 'PURCHASEORDER_GETALL_FAILURE',

  PURCHASEORDER_GETID_REQUEST: 'PURCHASEORDER_GETID_REQUEST',
  PURCHASEORDER_GETID_SUCCESS: 'PURCHASEORDER_GETID_SUCCESS',
  PURCHASEORDER_GETID_FAILURE: 'PURCHASEORDER_GETID_FAILURE',

  PURCHASEORDER_DELETE_REQUEST: 'PURCHASEORDER_DELETE_REQUEST',
  PURCHASEORDER_DELETE_SUCCESS: 'PURCHASEORDER_DELETE_SUCCESS',
  PURCHASEORDER_DELETE_FAILURE: 'PURCHASEORDER_DELETE_FAILURE',

  PURCHASEORDER_CREATE_REQUEST: 'PURCHASEORDER_CREATE_REQUEST',
  PURCHASEORDER_CREATE_SUCCESS: 'PURCHASEORDER_CREATE_SUCCESS',
  PURCHASEORDER_CREATE_FAILURE: 'PURCHASEORDER_CREATE_FAILURE',

  PURCHASEORDER_UPDATE_REQUEST: 'PURCHASEORDER_UPDATE_REQUEST',
  PURCHASEORDER_UPDATE_SUCCESS: 'PURCHASEORDER_UPDATE_SUCCESS',
  PURCHASEORDER_UPDATE_FAILURE: 'PURCHASEORDER_UPDATE_FAILURE',

  PURCHASEORDER_UPDATESTATUS_REQUEST: 'PURCHASEORDER_UPDATESTATUS_REQUEST',
  PURCHASEORDER_UPDATESTATUS_SUCCESS: 'PURCHASEORDER_UPDATESTATUS_SUCCESS',
  PURCHASEORDER_UPDATESTATUS_FAILURE: 'PURCHASEORDER_UPDATESTATUS_FAILURE',

  PURCHASEORDER_ARCHIVE_REQUEST: 'PURCHASEORDER_ARCHIVE_REQUEST',
  PURCHASEORDER_ARCHIVE_SUCCESS: 'PURCHASEORDER_ARCHIVE_SUCCESS',
  PURCHASEORDER_ARCHIVE_FAILURE: 'PURCHASEORDER_ARCHIVE_FAILURE',

  PURCHASEORDER_EXCEL_REQUEST: 'PURCHASEORDER_EXCEL_REQUEST',
  PURCHASEORDER_EXCEL_SUCCESS: 'PURCHASEORDER_EXCEL_SUCCESS',
  PURCHASEORDER_EXCEL_FAILURE: 'PURCHASEORDER_EXCEL_FAILURE',
  //#endregion
};
