/* eslint-disable react/react-in-jsx-scope */
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

// eslint-disable-next-line no-unused-vars
const CustomCellDouble = ({item, to, double, badge, price, date, onClick}) => {
  let intl = useIntl()
  let value = String(item.cell.value)
  let doubleValue = String(item.data[item.row.index][double])

  if (date) {
    const dateValue = new Date(value)
    if (isNaN(dateValue)) {
      value = '-'
    } else {
      if (date === 'long') {
        value = new Intl.DateTimeFormat('tr', {
          hour: 'numeric',
          minute: 'numeric',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(dateValue)
      } else if (date === 'short') {
        value = new Intl.DateTimeFormat('tr', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(dateValue)
      } else if (date === 'month') {
        value = new Intl.DateTimeFormat('tr', {month: 'long'}).format(dateValue)
      }
    }
  }

  if (price) {
    value = value > 0 ? intl.formatNumber(value, {style: 'currency', currency: 'try'}) : '-'
  }

  if (double === 'delayDay') {
    doubleValue =
      doubleValue == 0
        ? ''
        : doubleValue.startsWith('-')
        ? doubleValue * -1 + intl.formatMessage({id: 'PROJECT.DAYLEFT'})
        : doubleValue + intl.formatMessage({id: 'PROJECT.DAYPASSED'})
  } else if (double === 'remainingDay') {
    doubleValue =
      doubleValue == 0
        ? ''
        : doubleValue.startsWith('-')
        ? doubleValue * -1 + intl.formatMessage({id: 'PROJECT.DAYPASSED'})
        : doubleValue + intl.formatMessage({id: 'PROJECT.DAYLEFT'})
  } else if (double === 'title') {
    doubleValue =
      item.data[item.row.index][double] !== null ? item.data[item.row.index][double] : ''
  } else if (double === 'lastModifiedDate' || double === 'dateTime') {
    const dateValue = new Date(item?.data[item?.row.index][double])
    doubleValue = item.data[item.row.index][double]
      ? new Intl.DateTimeFormat('tr', {
          hour: 'numeric',
          minute: 'numeric',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(dateValue)
      : '-'
  }

  if (onClick) {
    return (
      <div
        className='d-block text-gray-600 p-5'
        id={item.data[item.row.index].id}
        onClick={() => onClick && onClick()}
        role='button'
      >
        {value}{' '}
        {badge && item.data[item.row.index][double] && (
          <span className='badge badge-success'>{badge}</span>
        )}
        {!badge && doubleValue !== 'undefined' && (
          <div className='text-muted fw-semibold text-muted d-block fs-7'>{doubleValue}</div>
        )}
      </div>
    )
  }

  return (
    <Link
      to={to ? to : `detail/${item.data[item.row.index].id}`}
      id={item.data[item.row.index].id}
      className='d-block text-gray-600 p-5'
    >
      {value}{' '}
      {badge && item.data[item.row.index][double] && (
        <span className='badge badge-success'>{badge}</span>
      )}
      {!badge && doubleValue !== 'undefined' && (
        <div className='text-muted fw-semibold text-muted d-block fs-7'>{doubleValue}</div>
      )}
    </Link>
  )
}

export {CustomCellDouble}
