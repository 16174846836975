/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { KTCard, KTCardBody, DeleteModal, DataTables } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../redux/actions';
import { KTSVG } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, error } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(userActions.userGetId(id));
  }, [])

  const handleDelete = () => dispatch(userActions.userDelete(id));

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.USERS' }),
      path: '/admin/users',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.USERS.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.firstName} {detail.lastName}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.email && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.email}
                            </div>
                          </>}


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  {detail?.rolesWithName.length > 0 && <Tab eventKey="role" title={intl.formatMessage({ id: 'USER.GET.ROLES' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'USER.GET.ROLESWITHNAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.rolesWithName}
                      exportTitle={intl.formatMessage({ id: 'USER.GET.ROLES' })}
                    />
                  </Tab>}
                  {detail?.projects?.length > 0 && <Tab eventKey="projects" title={intl.formatMessage({ id: 'COMPANY.GET.PROJECTS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.projects}
                      exportTitle={intl.formatMessage({ id: 'COMPANY.GET.PROJECTS' })}
                    />
                  </Tab>}
                  {detail?.dynamicReports?.length > 0 && <Tab eventKey="dynamicReports" title={intl.formatMessage({ id: 'USER.CREATE.DYNAMICREPORTROLES' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.dynamicReports}
                      exportTitle={intl.formatMessage({ id: 'USER.CREATE.DYNAMICREPORTROLES' })}
                    />
                  </Tab>}
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
