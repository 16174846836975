/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { paymentplanActions } from '../../../redux/actions';
import { InputPrice } from '../../';

const PaymentPlanModal = ({onOpen, onClose, error, data}) => {
  const [show, setShow] = useState(onOpen);
  const intl = useIntl()

  useEffect(() => {
    setShow(onOpen)
  }, [onOpen])

  const handleClose = () => {
    setShow(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (error && show) {
      setShow(false)
    }
  }, [error])

  const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    TotalPrice: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    DefermentDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: 0,
    Name: '',
    DefermentDate: new Date(),
    TotalPrice: 0,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(paymentplanActions.paymentPlanUpdate(values));
      setShow(false)
      onClose && onClose()
    },
  })

  useEffect(() => {
    if (data) {
      formik.setFieldValue('Id', data?.id)
      formik.setFieldValue('Name', data?.name)
      formik.setFieldValue('DefermentDate', data?.defermentDate)
      formik.setFieldValue('TotalPrice', data?.totalPrice)
    }
  }, [data])
  
  return (
    <>
      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <div className='modal-content'>
          <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
              <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'SALEINVOICE.CREATE.PLANS' })}</h5>
              <div className='d-flex ms-2'>
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={handleClose}
                >
                  <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
                </div>
              </div>
            </div>

            <div className='d-block py-5 ps-8 pe-5'>
              <div className='row mb-6'>
                <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.NAME' })}</label>
                <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    required
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Name')}
                  />
                  {formik.touched.Name && formik.errors.Name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.DEFERMENTDATE' })}</label>
                <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      {...formik.getFieldProps('DefermentDate')}
                      onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
                    />
                    {formik.touched.DefermentDate && formik.errors.DefermentDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.DefermentDate}</span>
                      </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALPRICE' })}</label>
                <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                  <InputPrice 
                    {...formik.getFieldProps('TotalPrice')} 
                    onChange={(value) => formik.setFieldValue('TotalPrice', Number(value))} 
                  />
                  {formik.touched.TotalPrice && formik.errors.TotalPrice && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.TotalPrice}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
              <div className='d-flex align-items-center me-3'>
                <button className="btn btn-light me-3" onClick={handleClose}>{intl.formatMessage({id: 'BUTTON.CLOSE' })}</button>
                <button type='submit' className="btn btn-primary" disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

PaymentPlanModal.propTypes = {
	onOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

PaymentPlanModal.defaultProps = {
	onOpen: false
};

export {PaymentPlanModal}
