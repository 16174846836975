import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AuditModal, KTCard, KTCardBody, Table } from '../../../components'
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { reportingActions, lookupActions } from '../../../redux/actions';

import { AuthType, AuditTypeSelect } from '../../../helpers';

function List() {
  const intl = useIntl();
  const { audit, loading } = useSelector(state => state.reporting);
  const { allUserLookup } = useSelector(state => state.lookup);

  const dispatch = useDispatch();

  const [params, setParams] = useState();
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (params) {
      dispatch(reportingActions.auditGetAll(params));
      dispatch(lookupActions.allUserLookup());
    }
  }, [params]);

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REPORTING.AUDIT.FULLNAME' })} className='min-w-125px' />,
      accessor: 'fullName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={() => setItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REPORTING.AUDIT.TYPE' })} className='min-w-125px' />,
      accessor: 'type',
      Cell: ({ ...props }) => <CustomCell item={props} customValue={AuthType[`${props.data[props.row.index].type}`]} onClick={() => setItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'REPORTING.AUDIT.CREATEDBY' })} className='min-w-125px' />,
      accessor: 'createdBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='dateTime' onClick={() => setItem(props.data[props.row.index])} />,
    }
  ];

  const actionButtons = [
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'REPORTING.AUDIT.USERID',
          data: allUserLookup,
          name: 'userId'
        },
         {
          title: 'REPORTING.AUDIT.TYPE',
          data: AuditTypeSelect,
          name: 'type'
        },
        {
          title: 'REPORTING.AUDIT.DATEFROM',
          type: 'date',
          name: 'dateFrom'
        },
        {
          title: 'REPORTING.AUDIT.DATETO',
          type: 'date',
          name: 'dateTo'
        },
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.REPORTING.AUDIT' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          buttons={[]}
          pages={'AUDIT'}
        />
        <KTCardBody>
          {!audit && <ListLoading />}
          {audit && <Table
            items={audit}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      <AuditModal
        onOpen={item ? true : false}
        onClose={() => setItem(null)}
        oldData={item?.oldValues}
        newData={item?.newValues}
      />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}

    </>
  )
}
export { List };
