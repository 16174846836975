import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table, Select } from '../../../components';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import { useDispatch, useSelector } from 'react-redux';
import { checkoutActions, lookupActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const [show, setShow] = useState(false);

  const { checkoutApprove, loading, checkoutApproveConfirm } = useSelector(state => state.checkout);
  const { CheckoutApproveState } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState()
  
  useEffect(() => {
   if (params) dispatch(checkoutActions.checkoutApproveGetAll(params));
  }, [params, checkoutApproveConfirm])

  useEffect(() => {
   dispatch(lookupActions.basicLookup('CheckoutApproveState'));
  }, [])

  const [confirmationState] = useState([
    {
      Id: 2,
      Name: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.CONFIRMATIONSTATE.CONFIRM'
    },
    {
      Id: 3,
      Name: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE.CONFIRMATIONSTATE.REJECT'
    }
  ])

  const validSchema = Yup.object().shape({
    CheckoutApproveStateId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: '',
    CheckoutId: '',
    CheckoutApproveStateId: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(checkoutActions.checkoutApproveConfirm(values));
      resetForm()
      setShow(false)
    },
  })

  const onClickItem = (data) => {
    setShow(data)
    formik.setFieldValue("CheckoutId", data?.checkoutId)
    formik.setFieldValue("Id", data?.id)
  }

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.DESCRIPTION' })} className='min-w-125px' />,
      accessor: 'description',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={() => onClickItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.CHECKOUTAPPROVESTATENAME' })} className='min-w-125px' />,
      accessor: 'checkoutApproveStateName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={() => onClickItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.PAYMENT' })} className='min-w-125px' />,
      accessor: 'payment',
      Cell: ({ ...props }) => <CustomCell item={props} price onClick={() => onClickItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.CHECKOUTUSERNAMESURNAME' })} className='min-w-125px' />,
      accessor: 'checkoutUserNameSurname',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={() => onClickItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.PAYMENTDATE' })} className='min-w-125px' />,
      accessor: 'paymentDate',
      Cell: ({ ...props }) => <CustomCell item={props} date="short" onClick={() => onClickItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.APPROVEDUSERNAMESURNAME' })} className='min-w-125px' />,
      accessor: 'approvedUserNameSurname',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={() => onClickItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))} title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.FROMACCOUNTNAME' })} className='min-w-125px' />,
      accessor: 'fromAccountName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={() => onClickItem(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))} title={intl.formatMessage({ id: 'CHECKOUTAPPROVE.SEARCH.TOACCOUNTNAME' })} className='min-w-125px' />,
      accessor: 'toAccountName',
      Cell: ({ ...props }) => <CustomCell item={props} onClick={() => onClickItem(props.data[props.row.index])} />,
    }
  ];

  const buttons = []

  const actionButtons = [
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'CHECKOUTAPPROVE.SEARCH.CHECKOUTAPPROVESTATEID',
          data: CheckoutApproveState,
          name: 'checkoutApproveStateId'
        }
      ]
    }
  ]
 
  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.CASH.CHECKOUTAPPROVE' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages='CHECKOUTAPPROVE'
          buttons={buttons}
        />
        <KTCardBody>
          {!checkoutApprove && <ListLoading />}
          {checkoutApprove && <Table
            items={checkoutApprove}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}

      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShow(false)
          formik.setFieldValue("CheckoutId", '')
        }}
      >
        <div className='modal-content'>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE' })}</h5>
          </div>
          
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <KTCard>
              <KTCardBody className='p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-5 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PAYMENTREQUEST.GET.GETCONFIRMATIONSTATE' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <Select
                      data={confirmationState} 
                      required
                      {...formik.getFieldProps('CheckoutApproveStateId')}
                      onChange={item => formik.setFieldValue('CheckoutApproveStateId', Number(item?.value))}
                      textType={'intl'}
                      menuPortalTarget={null}
                    />
                    {formik.touched.CheckoutApproveStateId && formik.errors.CheckoutApproveStateId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.CheckoutApproveStateId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
              <div className='d-flex align-items-center me-3'>
                <button className="btn btn-light me-3" onClick={() => {
                    setShow(false)
                    formik.setFieldValue("CheckoutId", '')
                  }}>
                  {intl.formatMessage({id: 'BUTTON.CLOSE' })}
                </button>
                <button className="btn btn-primary">{intl.formatMessage({id: 'BUTTON.ADD' })}</button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}
export { List };
