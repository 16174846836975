import { commonConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  activity: [],
  getFileFolderId: null,
  getAllWithFiles: []
}

export function common(state = {}, action) {
  switch (action.type) {
    case commonConstants.HISTORY_GETALL_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.HISTORY_GETALL_SUCCESS:
      return {
        ...state,
        history: action.history,
        loading: false
      };
    case commonConstants.HISTORY_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.NOTE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.NOTE_GETALL_SUCCESS:
      return {
        ...state,
        note: action.note,
        loading: false
      };
    case commonConstants.NOTE_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.NOTE_IMAGES_GETALL_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.NOTE_IMAGES_GETALL_SUCCESS:
      return {
        ...state,
        noteImages: action.noteImages,
        loading: false
      };
    case commonConstants.NOTE_IMAGES_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case commonConstants.NOTE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.NOTE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        note: state.note.filter(item => item.id !== action.params.Id),
        loading: false
      };
    case commonConstants.NOTE_DELETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case commonConstants.NOTE_CREATE_REQUEST:
      return {
        ...state,
        noteCreate: action.noteCreate,
        loading: true
      };
    case commonConstants.NOTE_CREATE_SUCCESS:
      return {
        ...state,
        noteCreate: action.noteCreate,
        loading: false
      };
    case commonConstants.NOTE_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case commonConstants.GLOBALSEARCH_GETALL_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.GLOBALSEARCH_GETALL_SUCCESS:
      return {
        ...state,
        search: action.search,
        loading: false
      };
    case commonConstants.GLOBALSEARCH_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.GENERAL_DASHBOARD_GET_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.GENERAL_DASHBOARD_GET_SUCCESS:
      return {
        ...state,
        generalDashResult: action.generalDashResult,
        loading: false
      };
    case commonConstants.GENERAL_DASHBOARD_GET_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.BANKCASH_DASHBOARD_GET_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.BANKCASH_DASHBOARD_GET_SUCCESS:
      return {
        ...state,
        bankCashDashResult: action.bankCashDashResult,
        loading: false
      };
    case commonConstants.BANKCASH_DASHBOARD_GET_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.ACTIVITY_GETALL_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.ACTIVITY_GETALL_SUCCESS:
      return {
        ...state,
        activity: action.activity,
        loading: false
      };
    case commonConstants.ACTIVITY_GETALL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.FILEFOLDER_CREATE_REQUEST:
      return {
        ...state,
        fileFolder: action.fileFolder,
        loading: true
      };
    case commonConstants.FILEFOLDER_CREATE_SUCCESS:
      return {
        ...state,
        fileFolder: action.fileFolder,
        loading: false
      };
    case commonConstants.FILEFOLDER_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.FILEFOLDER_RENAME_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.FILEFOLDER_RENAME_SUCCESS:
      return {
        ...state,
        isRedirect: action.rename || action.params,
        detail: action.params,
        loading: false
      };
    case commonConstants.FILEFOLDER_RENAME_FAILURE:
      AuthCheck(action)

      return {
        ...state,

        detail: action.params,
        error: action.error,
        loading: false
      };

    case commonConstants.FILEFOLDER_GETALLWITHFILES_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.FILEFOLDER_GETALLWITHFILES_SUCCESS:
      return {
        ...state,
        getAllWithFiles: action.getAllWithFiles,
        loading: false
      };
    case commonConstants.FILEFOLDER_GETALLWITHFILES_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.FILEFOLDER_GETID_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.FILEFOLDER_GETID_SUCCESS:
      return {
        ...state,
        getFileFolderId: action.getFileFolderId,
        loading: false
      };
    case commonConstants.FILEFOLDER_GETID_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.FILEFOLDER_DELETE_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.FILEFOLDER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case commonConstants.FILEFOLDER_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.FILE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case commonConstants.FILE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case commonConstants.FILE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case commonConstants.FILE_CREATE_REQUEST:
      return {
        ...state,
        file: action.file,
        loading: true
      };
    case commonConstants.FILE_CREATE_SUCCESS:
      return {
        ...state,
        file: action.file,
        loading: false
      };
    case commonConstants.FILE_CREATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state
  }
}
