/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { accountActions } from '../../../redux/actions';
import { Select } from '../..';

const StatementModal = ({onOpen, onClose, error, CompanyId}) => {
  const [show, setShow] = useState(onOpen);
  const intl = useIntl()
  const { statement } = useSelector(state => state.account);
  const [statementIsOpen, setStatementIsOpen] = useState(false);

  useEffect(() => {
    setShow(onOpen)
  }, [onOpen])

  const handleClose = () => {
    setShow(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (error && show) setShow(false)
  }, [error])

  const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    ExportTypeName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    CompanyId,
    ExportTypeName: '',
    StartDate: null,
    EndDate: null,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(accountActions.accountStatement(values));
      setShow(false)
      setStatementIsOpen(true)
      onClose && onClose()
    },
  })

  useEffect(() => {
    if (statement && statement?.fileUrl && statementIsOpen) {
      window.open(statement?.fileUrl, '_blank');
      setStatementIsOpen(false)
    }
  }, [statement])

  const exportType = [
    {
      Id: 'pdf',
      Name: "BUTTON.PDF.DOWNLOAD"
    },
    {
      Id: 'excel',
      Name: "BUTTON.EXCEL"
    }
  ]
  
  return (
    <>
      <Modal
        className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <div className='modal-content'>
          <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
            <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
              <h5 className='fw-bold m-0'>{intl.formatMessage({id: 'CURRENTACCOUNT.GET.TITLE' })}</h5>
              <div className='d-flex ms-2'>
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={handleClose}
                >
                  <KTSVG className='svg-icon-1' path='/images/icons/duotune/arrows/arr061.svg' />
                </div>
              </div>
            </div>

            <div className='d-block py-5 ps-8 pe-5'>
              <div className='row mb-6'>
                <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CURRENTACCOUNT.GET.STARTDATE' })}</label>
                <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      {...formik.getFieldProps('StartDate')}
                      onChange={([date]) => formik.setFieldValue('StartDate', date)}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'CURRENTACCOUNT.GET.ENDDATE' })}</label>
                <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      {...formik.getFieldProps('EndDate')}
                      onChange={([date]) => formik.setFieldValue('EndDate', date)}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CURRENTACCOUNT.GET.EXPORTTYPENAME' })}</label>
                <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                  <Select 
                    data={exportType}
                    required
                    {...formik.getFieldProps('ExportTypeName')}
                    onChange={item => formik.setFieldValue('ExportTypeName', item?.value)}
                    textType={'intl'}
                    menuPortalTarget={null}
                  />
                  {formik.touched.ExportTypeName && formik.errors.ExportTypeName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ExportTypeName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center justify-content-center py-5 ps-8 pe-5 border-top'>
              <div className='d-flex align-items-center me-3'>
                <button className="btn btn-light me-3" onClick={handleClose}>{intl.formatMessage({id: 'BUTTON.CLOSE' })}</button>
                <button type='submit' className="btn btn-primary" disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>{intl.formatMessage({id: 'BUTTON.STATEMENT' })}</button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

StatementModal.propTypes = {
	onOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

StatementModal.defaultProps = {
	onOpen: false
};

export {StatementModal}
