import React, {useState} from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types';
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../helpers'

const AsideMenuItemWithSub = ({
  children,
  to,
  title,
  icon,
  hasBullet,
}) => {
  const {pathname} = useLocation();
  const isActive = checkIsActive(pathname, to);
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <div
      className={clsx('menu-item', {'here show': isActive}, {'here show': isOpen})}
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive}, {'menu-active-bg': isOpen})}>
        {children}
      </div>
    </div>
  )
}

AsideMenuItemWithSub.propTypes = {
  children: PropTypes.any,
	to: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.string,
	hasBullet: PropTypes.bool
};

export {AsideMenuItemWithSub}
