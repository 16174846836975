import React from 'react'
import SVG from 'react-inlinesvg'
import PropTypes from 'prop-types';
import {toAbsoluteUrl} from '.'

const KTSVG = ({className = '', path, svgClassName = 'mh-50px'}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

KTSVG.propTypes = {
	className: PropTypes.string,
	path: PropTypes.string,
	svgClassName: PropTypes.string
};

export {KTSVG}
