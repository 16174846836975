import React from 'react'
import PropTypes from 'prop-types';
import { ExcelButton } from './ExcelButton';
import { PdfButton } from './PdfButton';

const ExportButtons = ({excelData, pdfData, title}) => {
  return (
    <div className="btn-group" role="group" aria-label="Export Buttons">
      <ExcelButton data={excelData} title={title} />
      <PdfButton data={pdfData} title={title} />
    </div>
  )
}

ExportButtons.propTypes = {
	excelData: PropTypes.array,
	pdfData: PropTypes.object,
	title: PropTypes.string,
};

ExportButtons.defaultProps = {
  title: new Date()
}

export {ExportButtons}
