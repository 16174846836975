export const TaskState = [
  {
    Id: 1,
    Name: 'TASKSTATE.BEGIN'
  },
  {
    Id: 2,
    Name: 'TASKSTATE.PROGRESS'
  },
  {
    Id: 3,
    Name: 'TASKSTATE.COMPLETED'
  }
]