/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik, FormikProvider, FieldArray } from 'formik'

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTSVG } from '../../../helpers';
import { KTCard, KTCardBody, KTCardHeader, Select } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, productActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const [shipmentDistrict, setShipmentDistrict] = useState()
  const [exitDistrict, setExitDistrict] = useState()
  const [districtType, setDistrictType] = useState()
  const { productLookup, warehouseLookup, companyLookup, projectLookup, ShipmentType, Province, District } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    districtType === 'Shipment' ? setShipmentDistrict(District) : setExitDistrict(District);
  }, [District])

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.basicLookup('ShipmentType'));
    dispatch(lookupActions.basicLookup('Province'));
    dispatch(lookupActions.allProductLookup());
    dispatch(lookupActions.warehouseLookup());

    dispatch(lookupActions.projectLookup());
  }, [])

  const onChangeShipmentDistrict = (value) => {
    formik.setFieldValue('ShipmentProvinceId', value);
    dispatch(lookupActions.districtLookup({ 'provinceId': value }));
    setDistrictType('Shipment');
  }

  const onChangeExitDistrict = (value) => {
    formik.setFieldValue('ExitProvinceId', value);
    dispatch(lookupActions.districtLookup({ 'provinceId': value }));
    setDistrictType('Exit');
  }

  const [shipmentType, setShipmentType] = useState()

  const onChangeShipmentType = (value) => {
    formik.setFieldValue('ShipmentTypeId', value);
    setShipmentType(value)
  }

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Name: '',
    ProjectId: null,
    CompanyId: null,
    ShipmentAddress: '',
    ShipmentDistrictId: null,
    ShipmentProvinceId: null,
    ExitAddress: '',
    ExitDistrictId: null,
    ExitProvinceId: null,
    ShipmentTypeId: null,
    IssueDate: new Date(),
    ShipmentDate: new Date(),
    WaybillNumber: '',
    CarPlate: '',
    DriverName: '',
    CargoCompany: '',
    CargoCompanyTaxNumber: '',
    OutgoingWaybillItems: [{
      ProductId: 0,
      WarehouseId: 0,
      Amount: 0
    }]
  })

  const onClickNewItem = (arrayHelpers) => {
    arrayHelpers.push({
      ProductId: '',
      WarehouseId: '',
      Amount: ''
    })
  }

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(productActions.outgoingWaybillCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.OUT_WAYBILLS' }),
      path: '/stock/out-waybills',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.OUT_WAYBILLS.ADD' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <FormikProvider value={formik}>
            <form className='form' onSubmit={formik.handleSubmit} noValidate>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.NAME' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    required
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Name')}
                  />
                  {formik.touched.Name && formik.errors.Name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.PROJECT' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={projectLookup}
                    required
                    {...formik.getFieldProps('ProjectId')}
                    onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                  />
                  {formik.touched.ProjectId && formik.errors.ProjectId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ProjectId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.COMPANY' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={companyLookup}
                    required
                    {...formik.getFieldProps('CompanyId')}
                    onChange={item => formik.setFieldValue('CompanyId', Number(item?.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.SHIPMENTADDRESS' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('ShipmentAddress')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.SHIPMENTPROVINCE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={Province}
                    {...formik.getFieldProps('ShipmentProvinceId')}
                    onChange={item => onChangeShipmentDistrict(Number(item.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.SHIPMENTDISTRICT' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={shipmentDistrict}
                    required
                    {...formik.getFieldProps('ShipmentDistrictId')}
                    onChange={item => formik.setFieldValue('ShipmentDistrictId', Number(item?.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.EXITADDRESS' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('ExitAddress')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.EXITPROVINCE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={Province}
                    {...formik.getFieldProps('ExitProvinceId')}
                    onChange={item => onChangeExitDistrict(Number(item.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.EXITDISTRICT' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={exitDistrict}
                    {...formik.getFieldProps('ExitDistrictId')}
                    onChange={item => formik.setFieldValue('ExitDistrictId', Number(item?.value))}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.SHIPMENTTYPE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={ShipmentType}
                    {...formik.getFieldProps('ShipmentTypeId')}
                    onChange={item => onChangeShipmentType(Number(item.value))}
                  />
                </div>
              </div>
              {shipmentType == '1' && <> <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.CARPLATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('CarPlate')}
                  />
                </div>
              </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.DRIVERNAME' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <input
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('DriverName')}
                    />
                  </div>
                </div></>}
              {shipmentType == '2' && <> <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.CARGOCOMPANY' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('CargoCompany')}
                  />
                </div>
              </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.CARGOCOMPANYTAXNUMBER' })}</label>
                  <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <textarea
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                      {...formik.getFieldProps('CargoCompanyTaxNumber')}
                    />
                  </div>
                </div></>}
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.ISSUEDATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      {...formik.getFieldProps('IssueDate')}
                      onChange={([date]) => formik.setFieldValue('IssueDate', date)}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.SHIPMENTDATE' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <div className="position-relative d-flex align-items-center">
                    <KTSVG
                      path='/images/icons/duotune/general/gen014.svg'
                      className='svg-icon svg-icon-2 position-absolute mx-4'
                    />
                    <Flatpickr
                      className="form-control form-control-solid ps-12"
                      options={{ "locale": Turkish }}
                      {...formik.getFieldProps('ShipmentDate')}
                      onChange={([date]) => formik.setFieldValue('ShipmentDate', date)}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.WAYBILLNUMBER' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('WaybillNumber')}
                  />
                </div>
              </div>

              <FieldArray
                name="OutgoingWaybillItems"
                render={(arrayHelpers) => (
                  <>
                    <div className="table-responsive">
                      <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" >
                        <thead>
                          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                            <th>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS.PRODUCTID' })}</th>
                            <th>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS.WAREHOUSEID' })}</th>
                            <th>{intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS.AMOUNT' })}</th>
                          </tr>
                        </thead>
                        <tbody>

                          {formik.values.OutgoingWaybillItems.map((item, index) => (
                            <tr className="border-bottom border-bottom-dashed" key={index}>
                              <td className="ps-1">
                                <Select
                                  data={productLookup}
                                  name={`OutgoingWaybillItems[${index}].ProductId`}
                                  value={formik.values.OutgoingWaybillItems[index].ProductId}
                                  required
                                  onChange={item => formik.setFieldValue(`OutgoingWaybillItems[${index}].ProductId`, Number(item?.value))}
                                />
                              </td>
                              <td className="ps-0">
                                <Select
                                  data={warehouseLookup}
                                  name={`ExpenseInvoiceItems[${index}].WarehouseId`}
                                  value={formik.values.OutgoingWaybillItems[index].WarehouseId}
                                  required
                                  onChange={item => formik.setFieldValue(`OutgoingWaybillItems[${index}].WarehouseId`, Number(item?.value))}
                                />
                              </td>
                              <td className="ps-0">
                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  autoComplete='off'
                                  disabled={formik.isSubmitting}
                                  placeholder={intl.formatMessage({ id: 'OUTGOINGWAYBILL.CREATE.OUTGOINGWAYBILLITEMS.AMOUNT' })}
                                  name={`OutgoingWaybillItems[${index}].Amount`}
                                  value={formik.values.OutgoingWaybillItems[index].Amount}
                                  onChange={(event) => formik.handleChange(event)}
                                  onFocus={event => event.target.select()}
                                />
                              </td>

                              <td className="pt-5 text-end">
                                <div className='d-flex'>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-icon btn-active-color-primary"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>

                    <button
                      type="button"
                      className="btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success"
                      onClick={() => onClickNewItem(arrayHelpers)}
                    >
                      <KTSVG path='/images/icons/duotune/arrows/arr009.svg' className='svg-icon-2' />
                      {intl.formatMessage({ id: 'ADD_NEW_LINE' })}
                    </button>
                  </>
                )}
              />

              <div className='row pt-15'>
                <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                  <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({ id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
