import { userTaskConstants } from '../constants';
import { AuthCheck } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  detail: null,
  completeTask: false
}

export function userTask(state = {}, action) {
  switch (action.type) {
 
    case userTaskConstants.USERTASK_GETALL_REQUEST:
      return { ...state, ...initialState };
    case userTaskConstants.USERTASK_GETALL_SUCCESS:
      return {
        ...state,
        userTask: action.userTask,
        loading: false
      };
    case userTaskConstants.USERTASK_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userTaskConstants.USERTASK_GETID_REQUEST:
      return { ...state, ...initialState };
    case userTaskConstants.USERTASK_GETID_SUCCESS:
      return {
        ...state,
        detail: action.userTask,
        loading: false
      };
    case userTaskConstants.USERTASK_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userTaskConstants.USERTASK_DELETE_REQUEST:
      return { ...state, ...initialState };
    case userTaskConstants.USERTASK_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case userTaskConstants.USERTASK_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userTaskConstants.USERTASK_CREATE_REQUEST:
      return { ...state, ...initialState };
    case userTaskConstants.USERTASK_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.userTask,
        loading: false
      };
    case userTaskConstants.USERTASK_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userTaskConstants.USERTASK_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case userTaskConstants.USERTASK_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.userTask || action.params,
        loading: false
      };
    case userTaskConstants.USERTASK_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userTaskConstants.USERTASK_COMPLETE_REQUEST:
      return { ...state, ...initialState };
    case userTaskConstants.USERTASK_COMPLETE_SUCCESS:
      return {
        ...state,
        completeTask: true,
        loading: false
      };
    case userTaskConstants.USERTASK_COMPLETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state
  }
}
