import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl'
import { KTCard } from '../Card/KTCard'
import { KTCardBody } from '../Card/KTCardBody'
import { KTCardHeader } from '../Card/KTCardHeader'

import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../redux/actions';

import { recordProcessType } from '../../helpers';

const Timeline = ({collapse, SourceId, SourceTypeId, isRefresh}) => {
  const intl = useIntl()
  const [isCollapse, setIsCollapse] = useState(collapse);

  const { history } = useSelector(state => state.common);
	const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.historyGetAll({SourceId, SourceTypeId}));
  }, [isRefresh])

  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
  };

  if (history && history.length > 0) {
    return (
      <KTCard className='mb-5 mb-xl-10'>
        <KTCardHeader title={intl.formatMessage({id: 'HISTORY' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

        <KTCardBody 
          collapseble
          className='p-5'
          isShow={isCollapse}
        >
          <div className='card-scroll ps-5 mh-300px'>
            <div className="timeline-label ">
              { history?.map((item, index) => {
                const className = `fa fa-genderless fs-1 ${recordProcessType[item.recordProcessTypeId].classType}`;
                return (
                  <div className="timeline-item" key={index}>
                    <div className="timeline-badge"><i className={className}></i></div>
                    <div className="timeline-content ps-3">
                      <div className="fw-bold text-gray-800 text-muted">{item.userNameSurname} - {intl.formatDate(new Date(item.recordDate), options)}</div>
                      <div className="fw-mormal text-muted fs-7">{item.description}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </KTCardBody>
      </KTCard>
    )
  }

  return <></>
}

Timeline.propTypes = {
	collapse: PropTypes.bool,
	SourceId: PropTypes.number,
	SourceTypeId: PropTypes.number,
};

Timeline.defaultProps = {
	collapse: true
};

export {Timeline}
