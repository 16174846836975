import { notificationConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  error: null,
  excel: null,
  complete: false
}

export function notification(state = {}, action) {
  switch (action.type) {
    //#region Notification
    case notificationConstants.NOTIFICATION_GETDASHBOARD_REQUEST:
      return { ...state, ...initialState };
    case notificationConstants.NOTIFICATION_GETDASHBOARD_SUCCESS:
      return {
        ...state,
        notificationDashboard: action.notificationDashboard,
        loading: false
      };
    case notificationConstants.NOTIFICATION_GETDASHBOARD_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case notificationConstants.NOTIFICATION_GETALL_REQUEST:
      return { ...state, ...initialState };
    case notificationConstants.NOTIFICATION_GETALL_SUCCESS:
      return {
        ...state,
        notification: action.notification,
        loading: false
      };
    case notificationConstants.NOTIFICATION_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
      
    case notificationConstants.NOTIFICATION_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case notificationConstants.NOTIFICATION_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case notificationConstants.NOTIFICATION_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case notificationConstants.NOTIFICATION_COMPLETE_REQUEST:
      return { ...state, ...initialState };
    case notificationConstants.NOTIFICATION_COMPLETE_SUCCESS:
      return {
        ...state,
        complete: true,
        loading: false
      };
    case notificationConstants.NOTIFICATION_COMPLETE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
  
    //#endregion    
    default:
      return state
  }
}
