import { userConstants } from '../constants';
import { AuthCheck, DownloadFileFn } from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  detail: null,
  excel: null,
}

export function user(state = {}, action) {
  switch (action.type) {
 
    case userConstants.USER_GETALL_REQUEST:
      return { ...state, ...initialState };
    case userConstants.USER_GETALL_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false
      };
    case userConstants.USER_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userConstants.USER_GETID_REQUEST:
      return { ...state, ...initialState };
    case userConstants.USER_GETID_SUCCESS:
      return {
        ...state,
        detail: action.user,
        loading: false
      };
    case userConstants.USER_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userConstants.USER_DELETE_REQUEST:
      return { ...state, ...initialState };
    case userConstants.USER_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case userConstants.USER_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userConstants.USER_CREATE_REQUEST:
      return { ...state, ...initialState };
    case userConstants.USER_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.user,
        loading: false
      };
    case userConstants.USER_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userConstants.USER_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case userConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.user || action.params,
        loading: false
      };
    case userConstants.USER_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case userConstants.USERROLES_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case userConstants.USERROLES_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.user || action.params,
        loading: false
      };
    case userConstants.USERROLES_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case userConstants.USER_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case userConstants.USER_EXCEL_SUCCESS:
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    case userConstants.USER_EXCEL_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state
  }
}
