import { checkoutConstants } from '../constants';
import {AuthCheck, DownloadFileFn} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  transaction: false,
  companyNewCheck: false,
  companyCheckCheckout: false,
  excel: null,
  receipt: null,
  checkoutApproveConfirm: false,
  updateDescription: false
}

export function checkout(state = {}, action) {
  switch (action.type) {
    //#region Checkout
    case checkoutConstants.CHECKOUT_SEARCHBYSTAFFID_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_SEARCHBYSTAFFID_SUCCESS:
      return {
        ...state,
        checkout: action.checkout,
        loading: false
      };
    case checkoutConstants.CHECKOUT_SEARCHBYSTAFFID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_SALARYPAYMENT_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_SALARYPAYMENT_SUCCESS:
      return {
        ...state,
        checkout: action.checkout,
        loading: false
      };
    case checkoutConstants.CHECKOUT_SALARYPAYMENT_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_STAFFPAYMENT_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_STAFFPAYMENT_SUCCESS:
      return {
        ...state,
        checkout: action.checkout,
        loading: false
      };
    case checkoutConstants.CHECKOUT_STAFFPAYMENT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_DELETE_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_DELETE_SUCCESS:
      return {
        ...state,
        transaction: action.params.id,
        loading: false
      };
    case checkoutConstants.CHECKOUT_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_GETID_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_GETID_SUCCESS:
      return {
        ...state,
        detail: action.checkout,
        loading: false
      };
    case checkoutConstants.CHECKOUT_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_ACCOUNTTOACCOUNTTRANSACTION_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_CREDITTRANSACTION_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_CREDITTRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_CREDITTRANSACTION_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_DEBITTRANSACTION_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_DEBITTRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_DEBITTRANSACTION_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYCHECK_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYCHECK_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYCHECK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEADVANCECHECK_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEADVANCECHECK_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEADVANCECHECK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
  
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYMONEY_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYMONEY_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYMONEY_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_SALEINVOICERECEIVECHECK_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_SALEINVOICERECEIVECHECK_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_SALEINVOICERECEIVECHECK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_SALEINVOICERECEIVEMONEY_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_SALEINVOICERECEIVEMONEY_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_SALEINVOICERECEIVEMONEY_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_EXPENSEINVOICEPAYNEWCHECK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_COMPANY_COLLECTION_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_COMPANY_COLLECTION_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_COMPANY_COLLECTION_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    case checkoutConstants.CHECKOUT_COMPANY_PAYMENT_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_COMPANY_PAYMENT_SUCCESS:
      return {
        ...state,
        transaction: action.transaction || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_COMPANY_PAYMENT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_COMPANYPAYNEWCHECK_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_COMPANYPAYNEWCHECK_SUCCESS:
      return {
        ...state,
        companyNewCheck: action.companyNewCheck || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_COMPANYPAYNEWCHECK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_COMPANYPAYCHECKCHECKOUT_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_COMPANYPAYCHECKCHECKOUT_SUCCESS:
      return {
        ...state,
        companyCheckCheckout: action.companyCheckCheckout || true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_COMPANYPAYCHECKCHECKOUT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_TRANSACTIONS_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.transactions,
        loading: false
      };
    case checkoutConstants.CHECKOUT_TRANSACTIONS_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_TRANSACTIONS_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_TRANSACTIONS_EXCEL_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
      
      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    }
    case checkoutConstants.CHECKOUT_TRANSACTIONS_EXCEL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_GETRECEIPT_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_GETRECEIPT_SUCCESS:
      return {
        ...state,
        receipt: action.receipt,
        loading: false
      };
    case checkoutConstants.CHECKOUT_GETRECEIPT_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_UPDATEDESCRIPTION_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_UPDATEDESCRIPTION_SUCCESS:
      return {
        ...state,
        updateDescription: true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_UPDATEDESCRIPTION_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_APPROVEGETALL_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_APPROVEGETALL_SUCCESS:
      return {
        ...state,
        checkoutApprove: action.checkoutApprove,
        loading: false
      };
    case checkoutConstants.CHECKOUT_APPROVEGETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case checkoutConstants.CHECKOUT_APPROVECONFIRM_REQUEST:
      return { ...state, ...initialState };
    case checkoutConstants.CHECKOUT_APPROVECONFIRM_SUCCESS:
      return {
        ...state,
        checkoutApproveConfirm: true,
        loading: false
      };
    case checkoutConstants.CHECKOUT_APPROVECONFIRM_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion    
    default:
      return state
  }
}
