import { filtersConstants } from '../constants';

export const filtersActions = {
  //#region Filter
  filtersCreate,
  filtersGetAll,
  filtersDelete,
  filtersSearchCreate,
  filtersSearchGetAll
  //#endregion    
};

//#region Filter
function filtersCreate(params) {
  return dispatch => {
    dispatch(success(params))
  };

  function success(filters) { return { type: filtersConstants.FILTER_CREATE, filters } }
}

function filtersGetAll(pages) {
  return dispatch => {
    dispatch(success(pages))
  };

  function success(pages) { return { type: filtersConstants.FILTER_GETALL, pages } }
}

function filtersDelete(pages) {
  return dispatch => {
    dispatch(success(pages))
  };

  function success(pages) { return { type: filtersConstants.FILTER_DELETE, pages } }
}

function filtersSearchCreate(params) {
  return dispatch => {
    dispatch(success(params))
  };

  function success(nameFilter) { return { type: filtersConstants.FILTER_SEARCH_CREATE, nameFilter } }
}

function filtersSearchGetAll(pages) {
  return dispatch => {
    dispatch(success(pages))
  };

  function success(pages) { return { type: filtersConstants.FILTER_SEARCH_GETALL, pages } }
}

//#endregion
