import { guaranteeletterConstants } from '../constants';
import {
  //#region Guaranteeletter
  CreateGuaranteeLetterClient,
  DeleteGuaranteeLetterClient,
  GetGuaranteeLetterClient,
  SearchGuaranteeLetterClient,
  UpdateGuaranteeLetterClient,
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region Guaranteeletter
const createGuaranteeLetterClient = new CreateGuaranteeLetterClient(SERVICE_URL);
const deleteGuaranteeLetterClient = new DeleteGuaranteeLetterClient(SERVICE_URL);
const getGuaranteeLetterClient = new GetGuaranteeLetterClient(SERVICE_URL);
const searchGuaranteeLetterClient = new SearchGuaranteeLetterClient(SERVICE_URL);
const updateGuaranteeLetterClient = new UpdateGuaranteeLetterClient(SERVICE_URL);
//#endregion

export const guaranteeletterActions = {
  //#region Guaranteeletter
  guaranteeletterGetAll,
  guaranteeletterGetId,
  guaranteeletterExcel,
  guaranteeletterCreate,
  guaranteeletterUpdate,
  guaranteeletterDelete,
  //#endregion    
};

//#region Guaranteeletter
function guaranteeletterGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchGuaranteeLetterClient.search(params.name, params.projectId, params.companyId, params.bankId, params.guaranteeLetterTypeId, params.guaranteeLetterStateId, params.startDate, params.endDate, params.items_per_page, params.page, params.order, params.sort)
      .then(
        guaranteeletter => dispatch(success(guaranteeletter)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: guaranteeletterConstants.GUARATEELETTER_GETALL_REQUEST } }
  function success(guaranteeletter) { return { type: guaranteeletterConstants.GUARATEELETTER_GETALL_SUCCESS, guaranteeletter } }
  function failure(error) { return { type: guaranteeletterConstants.GUARATEELETTER_GETALL_FAILURE, error } }
}

function guaranteeletterExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchGuaranteeLetterClient.excel(params.name, params.projectId, params.companyId, params.bankId, params.guaranteeLetterTypeId, params.guaranteeLetterStateId, params.startDate, params.endDate, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: guaranteeletterConstants.GUARATEELETTER_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: guaranteeletterConstants.GUARATEELETTER_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: guaranteeletterConstants.GUARATEELETTER_EXCEL_FAILURE, error } }
}

function guaranteeletterGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getGuaranteeLetterClient.get(id)
      .then(
        guaranteeletter => dispatch(success(guaranteeletter)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: guaranteeletterConstants.GUARATEELETTER_GETID_REQUEST, id } }
  function success(guaranteeletter) { return { type: guaranteeletterConstants.GUARATEELETTER_GETID_SUCCESS, guaranteeletter } }
  function failure(error) { return { type: guaranteeletterConstants.GUARATEELETTER_GETID_FAILURE, error } }
}

function guaranteeletterDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteGuaranteeLetterClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: guaranteeletterConstants.GUARATEELETTER_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: guaranteeletterConstants.GUARATEELETTER_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: guaranteeletterConstants.GUARATEELETTER_DELETE_FAILURE, error } }
}

function guaranteeletterCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createGuaranteeLetterClient.create(params)
      .then(
        guaranteeletter => dispatch(success(guaranteeletter)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: guaranteeletterConstants.GUARATEELETTER_CREATE_REQUEST, params } }
  function success(guaranteeletter) { return { type: guaranteeletterConstants.GUARATEELETTER_CREATE_SUCCESS, guaranteeletter } }
  function failure(error) { return { type: guaranteeletterConstants.GUARATEELETTER_CREATE_FAILURE, error } }
}

function guaranteeletterUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateGuaranteeLetterClient.update(params)
      .then(
        guaranteeletter => dispatch(success(guaranteeletter, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: guaranteeletterConstants.GUARATEELETTER_UPDATE_REQUEST, params } }
  function success(guaranteeletter, params) { return { type: guaranteeletterConstants.GUARATEELETTER_UPDATE_SUCCESS, guaranteeletter, params } }
  function failure(error, params) { return { type: guaranteeletterConstants.GUARATEELETTER_UPDATE_FAILURE, error, params } }
}
//#endregion
