export const companyConstants = {
  //#region Company
  COMPANY_GETALL_REQUEST: 'COMPANY_GETALL_REQUEST',
  COMPANY_GETALL_SUCCESS: 'COMPANY_GETALL_SUCCESS',
  COMPANY_GETALL_FAILURE: 'COMPANY_GETALL_FAILURE',

  COMPANY_GETID_REQUEST: 'COMPANY_GETID_REQUEST',
  COMPANY_GETID_SUCCESS: 'COMPANY_GETID_SUCCESS',
  COMPANY_GETID_FAILURE: 'COMPANY_GETID_FAILURE',

  COMPANY_DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
  COMPANY_DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  COMPANY_DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',

  COMPANY_CREATE_REQUEST: 'COMPANY_CREATE_REQUEST',
  COMPANY_CREATE_SUCCESS: 'COMPANY_CREATE_SUCCESS',
  COMPANY_CREATE_FAILURE: 'COMPANY_CREATE_FAILURE',

  COMPANY_UPDATE_REQUEST: 'COMPANY_UPDATE_REQUEST',
  COMPANY_UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
  COMPANY_UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',

  COMPANY_ARCHIVE_REQUEST: 'COMPANY_ARCHIVE_REQUEST',
  COMPANY_ARCHIVE_SUCCESS: 'COMPANY_ARCHIVE_SUCCESS',
  COMPANY_ARCHIVE_FAILURE: 'COMPANY_ARCHIVE_FAILURE',

  COMPANY_EXCEL_REQUEST: 'COMPANY_EXCEL_REQUEST',
  COMPANY_EXCEL_SUCCESS: 'COMPANY_EXCEL_SUCCESS',
  COMPANY_EXCEL_FAILURE: 'COMPANY_EXCEL_FAILURE',
  //#endregion            
};
