import { combineReducers } from 'redux';

import { staff } from './staff.reducer';
import { lookup } from './lookup.reducer';
import { common } from './common.reducer';
import { project } from './project.reducer';
import { company } from './company.reducer';
import { product } from './product.reducer';
import { checkout } from './checkout.reducer';
import { saleinvoice } from './saleinvoice.reducer';
import { expenseinvoice } from './expenseinvoice.reducer';
import { check } from './check.reducer';
import { account } from './account.reducer';
import { category } from './category.reducer';
import { tenantcompany } from './tenantcompany.reducer';
import { user } from './user.reducer';
import { profile } from './profile.reducer';
import { paymentplan } from './paymentplan.reducer';
import { proposal } from './proposal.reducer';
import { workplan } from './workplan.reducer';
import { purchase } from './purchase.reducer';
import { gibinvoice } from './gibinvoice.reducer';
import { scorecard } from './scorecard.reducer';
import { reporting } from './reporting.reducer';
import { notification } from './notification.reducer';
import { guaranteeletter } from './guaranteeletter.reducer';
import { filters } from './filters.reducer';
import { official } from './official.reducer';
import { paymentRequest } from './paymentrequest.reducer';
import { bankPaymentOrder } from './bankpaymentorder.reducer';
import { userTask } from './usertask.reducer';

const rootReducer = combineReducers({
  staff,
  lookup,
  project,
  company,
  product,  
  common,
  checkout,
  saleinvoice,
  expenseinvoice,
  check,
  account,
  category,
  tenantcompany,
  user,
  profile,
  paymentplan,
  proposal,
  workplan,
  purchase,
  gibinvoice,
  scorecard,
  reporting,
  notification,
  guaranteeletter,
  filters,
  official,
  paymentRequest,
  bankPaymentOrder,
  userTask
});

export default rootReducer;
