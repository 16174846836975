import { filtersConstants } from '../constants';
import { pagination } from '../../helpers';

const initialState = {
  filters: [],
  filterList: {},
  pagination,
  nameFilter: [],
  name: null
};

export function filters(state = initialState, action) {
  switch (action.type) {
    case filtersConstants.FILTER_CREATE: {
      const { pages, values } = action.filters;

      const existingFilterIndex = state.filters.findIndex(filter => filter.pages === pages);
      if (existingFilterIndex !== -1) {
        const updatedFilters = [...state.filters];
        updatedFilters[existingFilterIndex] = { pages, values };
        return {
          ...state,
          filters: updatedFilters,
        };
      } else {
        return {
          ...state,
          filters: [...state.filters, { pages, values }],
        };
      }
    }

    case filtersConstants.FILTER_GETALL: {
      const { pages } = action;
      const filterList = state?.filters?.find(filter => filter.pages === pages);

      return {
        ...state,
        filterList: filterList?.values || {}
      };
    }

    case filtersConstants.FILTER_DELETE: {
      const { pages } = action;
      const updatedFilters = state.filters.filter(filter => filter.pages !== pages);
      return {
        ...state,
        filters: updatedFilters,
        filterList: state.filterList
      };
    }

    case filtersConstants.FILTER_SEARCH_CREATE: {
      const { pages, value } = action.nameFilter;

      const existingFilterIndex = state.nameFilter.findIndex(filter => filter.pages === pages);
      if (existingFilterIndex !== -1) {
        const updatedFilters = [...state.filters];
        updatedFilters[existingFilterIndex] = { pages, value };
        return {
          ...state,
          nameFilter: updatedFilters,
        };
      } else {
        return {
          ...state,
          nameFilter: [...state.nameFilter, { pages, value }],
        };
      }
    }

    case filtersConstants.FILTER_SEARCH_GETALL: {
      const { pages } = action;
      const filterList = state?.nameFilter?.find(filter => filter.pages === pages);

      return {
        ...state,
        name: filterList?.value || ''
      };
    }

    default:
      return state;
  }
}
