import { scorecardConstants } from '../constants';
import {
  //#region ScoreCard
  SearchScoreCardClient,
  GetScoreCardClient,
  DeleteScoreCardClient,
  UpdateScoreCardClient,
  CreateScoreCardClient,
  //#endregion

  //#region ScoreCardDay
  GetScoreCardDaysClient,
  UpdateScoreCardDaysClient,
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region ScoreCard
const searchScoreCardClient = new SearchScoreCardClient(SERVICE_URL);
const getScoreCardClient = new GetScoreCardClient(SERVICE_URL);
const createScoreCardClient = new CreateScoreCardClient(SERVICE_URL);
const deleteScoreCardClient = new DeleteScoreCardClient(SERVICE_URL);
const updateScoreCardClient = new UpdateScoreCardClient(SERVICE_URL);
//#endregion

//#region ScoreCardDay
const getScoreCardDaysClient = new GetScoreCardDaysClient(SERVICE_URL);
const updateScoreCardDaysClient = new UpdateScoreCardDaysClient(SERVICE_URL);
//#endregion

export const scorecardActions = {
  //#region ScoreCard
  scorecardGetAll,
  scorecardGetId,
  scorecardDelete,
  scorecardCreate,
  scorecardUpdate,
  //#endregion

  //#region ScoreCardDay
  scorecardDayGetAll,
  scorecardDayUpdate,
  //#endregion
};

//#region ScoreCard
function scorecardGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchScoreCardClient.search(params.name, params.items_per_page, params.page, params.order, params.sort)
      .then(
        scorecard => dispatch(success(scorecard)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: scorecardConstants.SCORECARD_GETALL_REQUEST } }
  function success(scorecard) { return { type: scorecardConstants.SCORECARD_GETALL_SUCCESS, scorecard } }
  function failure(error) { return { type: scorecardConstants.SCORECARD_GETALL_FAILURE, error } }
}

function scorecardGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getScoreCardClient.get(id)
      .then(
        scorecard => dispatch(success(scorecard)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: scorecardConstants.SCORECARD_GETID_REQUEST, id } }
  function success(scorecard) { return { type: scorecardConstants.SCORECARD_GETID_SUCCESS, scorecard } }
  function failure(error) { return { type: scorecardConstants.SCORECARD_GETID_FAILURE, error } }
}

function scorecardDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteScoreCardClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: scorecardConstants.SCORECARD_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: scorecardConstants.SCORECARD_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: scorecardConstants.SCORECARD_DELETE_FAILURE, error } }
}

function scorecardCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createScoreCardClient.create(params)
      .then(
        scorecard => dispatch(success(scorecard)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: scorecardConstants.SCORECARD_CREATE_REQUEST, params } }
  function success(scorecard) { return { type: scorecardConstants.SCORECARD_CREATE_SUCCESS, scorecard } }
  function failure(error) { return { type: scorecardConstants.SCORECARD_CREATE_FAILURE, error } }
}

function scorecardUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateScoreCardClient.update(params)
      .then(
        scorecard => dispatch(success(scorecard, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: scorecardConstants.SCORECARD_UPDATE_REQUEST, params } }
  function success(scorecard, params) { return { type: scorecardConstants.SCORECARD_UPDATE_SUCCESS, scorecard, params } }
  function failure(error, params) { return { type: scorecardConstants.SCORECARD_UPDATE_FAILURE, error, params } }
}
//#endregion

//#region ScoreCardDay
function scorecardDayGetAll(params) {
  return dispatch => {
    dispatch(request());

    getScoreCardDaysClient.search(params)
      .then(
        scorecard => dispatch(success(scorecard)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: scorecardConstants.SCORECARDDAY_GETALL_REQUEST } }
  function success(scorecard) { return { type: scorecardConstants.SCORECARDDAY_GETALL_SUCCESS, scorecard } }
  function failure(error) { return { type: scorecardConstants.SCORECARDDAY_GETALL_FAILURE, error } }
}

function scorecardDayUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateScoreCardDaysClient.update(params)
      .then(
        scorecard => dispatch(success(scorecard, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: scorecardConstants.SCORECARDDAY_UPDATE_REQUEST, params } }
  function success(scorecard, params) { return { type: scorecardConstants.SCORECARDDAY_UPDATE_SUCCESS, scorecard, params } }
  function failure(error, params) { return { type: scorecardConstants.SCORECARDDAY_UPDATE_FAILURE, error, params } }
}
//#endregion
