/* eslint-disable react/react-in-jsx-scope */
import {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {ListToolbar} from './ListToolbar'
import {useIntl} from 'react-intl'
import {ListSearchComponent} from './ListSearchComponent'
import {KTSVG} from '../../../helpers'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Flatpickr from 'react-flatpickr'
import {Turkish} from 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/flatpickr.css'
import {useDispatch, useSelector} from 'react-redux'
import {filtersActions} from '../../../redux/actions'
import Select from 'react-select'
import { InputPrice } from '../../'

const ListHeader = ({search, buttons, actionButtons, onSubmit, pages}) => {
  const selectRefs = useRef({})
  const intl = useIntl()
  const [showFilter, setShowFilter] = useState(false)
  const [lastItem, setLastItem] = useState(false)
  const [onInit, setOnInit] = useState(false)
  const [searchValue, setSearchValue] = useState(null)

  const {filterList, pagination, name} = useSelector((state) => state.filters)
  const dispatch = useDispatch()

  const checkTexts = (text) => {
    const regexUpperCase = /[A-Z]/
    const regexDot = /\./
    const regexUnderscore = /_/

    return regexUpperCase.test(text) || regexDot.test(text) || regexUnderscore.test(text)
  }

  const validSchema = Yup.object().shape({})

  const [formValues] = useState({})

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setShowFilter(false)
      onSubmit && onSubmit({...values, ...pagination, name: searchValue})
      dispatch(filtersActions.filtersCreate({pages, values}))
    },
  })

  useEffect(() => {
    dispatch(filtersActions.filtersGetAll(pages))
    dispatch(filtersActions.filtersSearchGetAll(pages))
  }, [])

  useEffect(() => {
    if (typeof filterList === 'object' && filterList !== null && filterList) {
      const properties = Object.keys(filterList)
      const lastProperty = properties[properties.length - 1]

      if (properties.length) {
        for (const property in filterList) {
          formik.setFieldValue(property, filterList[property])

          if (property == lastProperty) {
            setLastItem(true)
          }
        }
      } else {
        setOnInit(true)
      }
    }
  }, [filterList])

  useEffect(() => {
    if (lastItem) onSubmit && onSubmit({...pagination, ...formik.values, name})
  }, [lastItem])

  useEffect(() => {
    if (onInit) onSubmit && onSubmit({...pagination, ...formik.values, name})
  }, [onInit])

  const handleClickClear = () => {
    const {values} = formik
    for (const property in values) {
      formik.setFieldValue(property, '')
    }

    for (const ref in selectRefs.current) {
      selectRefs.current[ref].clearValue()
    }

    formik.resetForm()
    dispatch(filtersActions.filtersDelete(pages))
  }

  useEffect(() => {
    setSearchValue(name)
  }, [name])

  return (
    <div className='card-header pt-6 pb-6'>
      <ListSearchComponent
        handleSearch={(value) => {
          dispatch(filtersActions.filtersSearchCreate({pages, value}))
          search && search(value)
          setSearchValue(value)
        }}
        name={name}
      />
      <div className='card-toolbar'>
        <ListToolbar
          buttons={buttons}
          actionButtons={actionButtons}
          onClickShow={() => setShowFilter(!showFilter)}
        />
      </div>

      <div className={clsx('collapse', 'w-100', {show: showFilter})}>
        <div className='separator separator-dashed mt-6 mb-6'></div>
        <form className='form' onSubmit={formik.handleSubmit} noValidate>
          {actionButtons?.map((item, index) => {
            if (item?.list) {
              return (
                <div className='row g-8' key={index}>
                  {item?.list?.map((list, key) => {
                    if (list?.type == 'date') {
                      return (
                        <div className='col-lg-4 mb-6' key={key}>
                          <div className='row'>
                            <label className='fs-6 form-label fw-bold text-dark'>
                              {intl.formatMessage({id: list.title})}
                            </label>
                            <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                              <div className='position-relative d-flex align-items-center'>
                                <KTSVG
                                  path='/images/icons/duotune/general/gen014.svg'
                                  className='svg-icon svg-icon-2 position-absolute mx-4'
                                />
                                <Flatpickr
                                  className='form-control form-control-solid ps-12'
                                  options={{locale: Turkish}}
                                  {...formik.getFieldProps(list.name)}
                                  value={
                                    formik.values[list.name] ? formik.values[list.name] : 'today'
                                  }
                                  onChange={([date]) => formik.setFieldValue(list.name, date)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    if (list?.type == 'text' || list?.type == 'number') {
                      return (
                        <div className='col-lg-4 mb-6' key={key}>
                          <div className='row'>
                            <label className='fs-6 form-label fw-bold text-dark'>
                              {intl.formatMessage({id: list.title})}
                            </label>
                            <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                              <input
                                type={list?.type}
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                required
                                {...formik.getFieldProps(list.name)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }

                    if (list?.type == 'price') {
                      return (
                        <div className='col-lg-4 mb-6' key={key}>
                          <div className='row'>
                            <label className='fs-6 form-label fw-bold text-dark'>
                              {intl.formatMessage({id: list.title})}
                            </label>
                            <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                              <InputPrice 
                                {...formik.getFieldProps(list.name)}
                                suffix={list?.suffix}
                                onChange={(value) => {
                                  if (list?.onChange) list?.onChange(Number(value))
                                  formik.setFieldValue(list.name, Number(value))
                                }} 
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }

                    const options = [
                      {
                        value: 'null',
                        label: intl.formatMessage({id: 'FILTER.ALL'}),
                      },
                    ]

                    list?.data?.map((item) => {
                      return options.push({
                        value: item.id || item.Id,
                        label: checkTexts(item?.Name)
                          ? intl.formatMessage({id: item?.Name})
                          : item.name || item?.Name,
                      })
                    })

                    const newValue = options.find((item) => item.value == formik.values[list.name])

                    return (
                      <div className='col-lg-4 mb-6' key={key}>
                        <div className='row'>
                          <label className='fs-6 form-label fw-bold text-dark'>
                            {intl.formatMessage({id: list.title})}
                          </label>
                          <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                            {options && options.length > 0 && (
                              <Select
                                placeholder={intl.formatMessage({id: 'CHOOSE'})}
                                classNamePrefix='form-select'
                                className={'form-select-solid'}
                                options={options}
                                name={list.name}
                                value={newValue}
                                onChange={(item) => {
                                  if (list?.onChange)
                                    list?.onChange(
                                      item?.value > -1 ? Number(item?.value) : item?.value
                                    )
                                  formik.setFieldValue(
                                    list.name,
                                    item?.value > -1 ? Number(item?.value) : item?.value
                                  )
                                }}
                                filtersOption={(option, query) =>
                                  String(option.data.label)
                                    .toLocaleLowerCase('tr')
                                    .includes(query.toLocaleLowerCase('tr'))
                                }
                                isClearable
                                ref={(ref) => (selectRefs.current[list.name] = ref)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            }
          })}

          <div className='row pt-5 pb-6'>
            <div className='col-lg-12 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
              <button type='button' className='btn btn-light' onClick={() => handleClickClear()}>
                {intl.formatMessage({id: 'BUTTON.CLEAR'})}
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.FILTER'})}</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    {intl.formatMessage({id: 'PLEASE'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

ListHeader.propTypes = {
  buttons: PropTypes.array,
}

ListHeader.defaultProps = {
  buttons: [
    {
      title: 'BUTTON.ADD',
      icon: '/images/icons/duotune/arrows/arr075.svg',
      to: 'create',
      variant: 'primary',
    },
  ],
}

export {ListHeader}
