export const ActivityReportType = [
  {
    Id: 1,
    Name: 'ACTIVITYREPORTTYPE.DAILY'
  },
  {
    Id: 2,
    Name: 'ACTIVITYREPORTTYPE.WEEKLY'
  },
  {
    Id: 3,
    Name: 'ACTIVITYREPORTTYPE.MONTHLY'
  }
]