import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { accountActions, lookupActions } from '../../../redux/actions';

import { pagination, AccountType } from '../../../helpers';

function List() {
  const intl = useIntl()
  const { account, loading } = useSelector(state => state.account);
  const { projectLookup, Currency } = useSelector(state => state.lookup);
  const dispatch = useDispatch();

  const [params, setParams] = useState(pagination)

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('Currency'));
    dispatch(accountActions.accountGetAll(params));
  }, [params])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKCASHACCOUNT.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='accountTypeName' />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKCASHACCOUNT.SEARCH.BANKBRANCH' })} className='min-w-125px' />,
      accessor: 'bankBranchName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKCASHACCOUNT.SEARCH.PROJECT' })} className='min-w-125px' />,
      accessor: 'projectName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKCASHACCOUNT.SEARCH.IBAN' })} className='min-w-125px' />,
      accessor: 'iban',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))} title={intl.formatMessage({ id: 'BANKCASHACCOUNT.SEARCH.CURRENCY' })} className='min-w-125px' />,
      accessor: 'currencyName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'BANKCASHACCOUNT.SEARCH.BALANCE' })} className='min-w-125px' />,
      accessor: 'balance',
      Cell: ({ ...props }) => <CustomCell item={props} price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    }
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(accountActions.accountExcel(params, intl.formatMessage({ id: 'BC.BANKCASE' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'BANKCASHACCOUNT.SEARCH.ACCOUNTTYPEID',
          data: AccountType,
          name: 'accountTypeId'
        },
        {
          title: 'BANKCASHACCOUNT.SEARCH.PROJECT',
          data: projectLookup,
          name: 'projectId'
        },
        {
          title: 'BANKCASHACCOUNT.SEARCH.CURRENCY',
          data: Currency,
          name: 'currencyId'
        },
        {
          title: 'BANKCASHACCOUNT.SEARCH.MINBALANCE',
          type: 'price',
          name: 'minBalance',
          suffix: ''
        },
        {
          title: 'BANKCASHACCOUNT.SEARCH.MAXBALANCE',
          type: 'price',
          name: 'maxBalance',
          suffix: ''
        }
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.BANKCASE' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))} 
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
        />
        <KTCardBody>
          {!account && <ListLoading />}
          {account && <Table
            items={account}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
