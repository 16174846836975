import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { KTSVG } from '../../helpers'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch } from 'react-redux';
import { paymentplanActions } from '../../redux/actions';
import { DeleteModal, PaymentPlanModal, InputPrice } from '../';

const PaymentPlan = ({SaleInvoiceId, ExpenseInvoiceId, data, isFormVisible, ProjectId, SalaryId}) => {
  const intl = useIntl()
  const [deleteId, setDeleteId] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

	const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    TotalPrice: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    Name: '',
    DefermentDate: new Date(),
    TotalPrice: 0,
    SaleInvoiceId,
    ExpenseInvoiceId,
    ProjectId,
    SalaryId
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(paymentplanActions.paymentPlanCreate(values));
      resetForm()
    },
  })

  const handleClickDelete = (id) => {
    setDeleteId(id)
    setShow(true)
  }

  const handleClickEdit = (item) => {
    setSelectedItem(item)
    setShowEdit(true)
  }

  const handleDelete = () => {
    dispatch(paymentplanActions.paymentPlanDelete({Id: deleteId}));
    setShow(false)
  }

  return (
    <>
      {isFormVisible && <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-6 p-5'>
          <div className='col-lg-4 fv-row fv-plugins-icon-container'>
            <input
              placeholder={intl.formatMessage({id: 'SALEINVOICE.CREATE.PLANS.NAME' })}
              type='text'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              disabled={formik.isSubmitting}
              {...formik.getFieldProps('Name')}
            />
          </div>

          <div className='col-lg-3 fv-row fv-plugins-icon-container'>
            <div className="position-relative d-flex align-items-center mb-3 mb-lg-0">
              <KTSVG
                path='/images/icons/duotune/general/gen014.svg'
                className='svg-icon svg-icon-2 position-absolute mx-4'
              />
              <Flatpickr 
                className="form-control form-control-solid ps-12"
                options={{ "locale": Turkish }}
                {...formik.getFieldProps('DefermentDate')}
                onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
              />
            </div>
          </div>

          <div className='col-lg-3 fv-row fv-plugins-icon-container'>
            <InputPrice 
              placeholder={intl.formatMessage({id: 'SALEINVOICE.CREATE.PLANS.TOTALPRICE' })}
              {...formik.getFieldProps('TotalPrice')} 
              onChange={(value) => formik.setFieldValue('TotalPrice', Number(value))} 
            />
            {formik.touched.TotalPrice && formik.errors.TotalPrice && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.TotalPrice}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-lg-2 fv-row fv-plugins-icon-container d-grid'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
              {(formik.isSubmitting) && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'PLEASE' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>}

      {data?.length > 0 && <div className='table-responsive'>
        <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
          <thead>
            <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
              <th>{intl.formatMessage({id: 'SALEINVOICE.CREATE.PLANS.NAME' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'SALEINVOICE.CREATE.PLANS.DEFERMENTDATE' })}</th>
              <th className='text-center'>{intl.formatMessage({id: 'SALEINVOICE.CREATE.PLANS.TOTALPRICE' })}</th>
              <th className='text-end'>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => <tr key={index}>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                  {item.name}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatDate(new Date(item?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
              </td>
              <td className='p-5'>
                <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                  {intl.formatNumber(item.totalPrice, { style: 'currency', currency: 'try' })}
                </div>
              </td>
              <td className='p-5 text-end'>
                <button 
                  type="button" 
                  className="btn btn-sm btn-icon btn-active-color-primary" 
                  onClick={() => handleClickEdit(item)}
                >
                  <KTSVG path='/images/icons/duotune/general/gen055.svg' className='svg-icon-2' />
                </button>
                <button 
                  type="button" 
                  className="btn btn-sm btn-icon btn-active-color-primary" 
                  onClick={() => handleClickDelete(item.id)}
                >
                  <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                </button>
              </td>
            </tr> )}
          </tbody>
        </table>
      </div>}

      <PaymentPlanModal
        onOpen={showEdit}
        data={selectedItem}
        onClose={() => setShowEdit(false)}
      />

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
      />
    </>
  )
}

PaymentPlan.propTypes = {
	collapse: PropTypes.bool,
  SourceId: PropTypes.number,
	SourceTypeId: PropTypes.number,
	isFormVisible: PropTypes.bool,
};

PaymentPlan.defaultProps = {
	isFormVisible: true
};

export {PaymentPlan}
