import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { KTCard, KTCardBody, Table } from '../../../components';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import {detailPageUrl, DetailPage, ExpenseDetailPage } from '../../../helpers'

import { useDispatch, useSelector } from 'react-redux';
import { paymentplanActions } from '../../../redux/actions';

function List() {
  const intl = useIntl()
  const { unscheduled, loading } = useSelector(state => state.paymentplan);
  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
   if (params) dispatch(paymentplanActions.paymentPlanUnscheduled(params));
  }, [params])

  const newUrlGenerate = (data) => {
    if (data?.expenseInvoiceId > 0) {
      return detailPageUrl(ExpenseDetailPage[data?.expenseInvoiceTypeId], data?.expenseInvoiceId)
    } 

    return detailPageUrl(DetailPage.SaleInvoice, data?.saleInvoiceId)
  }

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'UNSCHEDULED.GET.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCell item={props} to={newUrlGenerate(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'UNSCHEDULED.GET.TOTALPRICE' })} className='min-w-125px' />,
      accessor: 'totalPrice',
      Cell: ({ ...props }) => <CustomCell item={props} price to={newUrlGenerate(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'UNSCHEDULED.GET.UNSCHEDULEDPRICE' })} className='min-w-125px' />,
      accessor: 'unscheduledPrice',
      Cell: ({ ...props }) => <CustomCell item={props} price to={newUrlGenerate(props.data[props.row.index])} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'UNSCHEDULED.GET.INVOICETYPENAME' })} className='min-w-125px' />,
      accessor: 'invoiceTypeName',
      Cell: ({ ...props }) => <CustomCell item={props} to={newUrlGenerate(props.data[props.row.index])} />,
    },
  ];

  const buttons = []

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.CASH.UNSCHEDULED' })}</PageTitle>
      <KTCard>
        <ListHeader 
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          onSubmit={(values) => setParams({ ...values })}
          pages='UNSCHEDULED'
          buttons={buttons}
        />
        <KTCardBody>
          {!unscheduled && <ListLoading />}
          {unscheduled && <Table
            items={unscheduled}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
