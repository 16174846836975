/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTCard, KTCardBody, Select } from '../../../components';
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";
import { KTSVG } from '../../../helpers';

import { PageTitle } from '../../../core';
import { useDispatch, useSelector } from 'react-redux';
import { userTaskActions, lookupActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')
  const { UserTaskStatus } = useSelector(state => state.lookup);
  const { isRedirect, detail, loading } = useSelector(state => state.userTask);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userTaskActions.userTaskGetId(id));
    dispatch(lookupActions.basicLookup('UserTaskStatus'));
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    TaskDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    Id: '',
    Name: '',
    TaskDate: new Date(),
    UserTaskStatusId: ''
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      dispatch(userTaskActions.userTaskUpdate(values));
    },
  })

  useEffect(() => {
    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);

      for (const values in formValues) {
        if (values === propVal) {
          formik.setFieldValue(propVal, property === 'taskDate' ? new Date(detail[property]) : detail[property])
        }
      }
    }
  }, [detail])
  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.USERTASK' }),
      path: '/user/task',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/user/task/detail/${id}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.USERTASK.EDIT' })}</PageTitle>
      <KTCard>        
        <KTCardBody>
          {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USERTASK.CREATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USERTASK.CREATE.TASKDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('TaskDate')}
                    onChange={([date]) => formik.setFieldValue('TaskDate', date)}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'USERTASK.CREATE.USERTASKSTASUSID' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={UserTaskStatus}
                  required
                  {...formik.getFieldProps('UserTaskStatusId')}
                  onChange={item => formik.setFieldValue('UserTaskStatusId', item.value)}
                />
                {formik.touched.UserTaskStatusId && formik.errors.UserTaskStatusId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.UserTaskStatusId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>                
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-userTasks-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
