import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../core';
import { KTCard, KTCardHeader, KTCardBody, ChartColumn } from '../../components';
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../redux/actions';

import { KTSVG, detailPageUrl, DetailPage, ExpenseDetailPage, AuthRole } from '../../helpers'

function Dashboard() {
  const intl = useIntl()
  const { info, loading } = useSelector(state => state.profile);
  const { bankCashDashResult, generalDashResult } = useSelector(state => state.common);
  const dispatch = useDispatch();

  useEffect(() => {    
    if (info?.roles?.includes(AuthRole.Bank.name)) dispatch(commonActions.bankCashDashboard({}));
    
    dispatch(commonActions.generalDashboard({}));
  }, [info])

  const Widget = ({
    className,
    color,
    svgIcon,
    iconColor,
    title,
    titleColor,
    description,
    descriptionColor,
    link
  }) => {
    return (
      <div className={`card bg-${color} ${className}`}>
        <div className='card-body'>
          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-5x ms-n1`} svgClassName={'mh-100px'} />

          <div className={`text-${titleColor} fw-bold fs-1 mb-2 mt-5`}>{title}</div>

          {link ? <Link to={link} className={`fw-semibold text-${descriptionColor}`}>{description}</Link> : <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>}
        </div>
      </div>
    )
  }

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'DASHBOARD.HELLO' })}{info?.firstName}</PageTitle>
      <div className='row gy-5 g-xl-10'>     
        <div className='col-xl-3'>
          <Widget
            className='card-xl-stretch mb-xl-8'
            svgIcon='/images/icons/duotune/general/gen019.svg'
            color='danger'
            iconColor='white'
            title={generalDashResult?.totalUserTaskCount ? `${generalDashResult?.totalUserTaskCount} ${intl.formatMessage({ id: 'DASHBOARD.TOTALUSERTASK.TASK' })}` : intl.formatMessage({ id: 'DASHBOARD.TOTALUSERTASK.NONTASK' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALUSERTASK.LINK' })}
            titleColor='white'
            descriptionColor='white'
            link={'/user/task'}
          />
        </div>
        
        {info?.roles?.includes(AuthRole.Bank.name) && <>
          <div className='col-xl-3'>
            <Widget
              className='card-xl-stretch mb-xl-8'
              svgIcon='/images/icons/duotune/finance/fin008.svg'
              color='primary'
              iconColor='white'
              title={intl.formatNumber(bankCashDashResult?.cashFlows?.totalDefaultCurrencyAmount, { style: 'currency', currency: 'try' })}
              description={intl.formatMessage({ id: 'DASHBOARD.TOTALDEFAULTCURRENCYAMOUNT' })}
              titleColor='white'
              descriptionColor='white'
            />
          </div>
          <div className='col-xl-3'>
            <Widget
              className='card-xl-stretch mb-xl-8'
              svgIcon='/images/icons/duotune/finance/fin010.svg'
              color='info'
              iconColor='white'
              title={intl.formatNumber(bankCashDashResult?.cashFlows?.totalOtherCurrenciesAmount, { style: 'currency', currency: 'try' })}
              description={intl.formatMessage({ id: 'DASHBOARD.TOTALOTHERCURRENCYAMOUNT' })}
              titleColor='white'
              descriptionColor='white'
            />
          </div>
          <div className='col-xl-3'>
            <Widget
              className='card-xl-stretch mb-xl-8'
              svgIcon='/images/icons/duotune/finance/fin001.svg'
              color='success'
              iconColor='white'
              title={intl.formatNumber(bankCashDashResult?.cashFlows?.totalAllCurrenciesAmount, { style: 'currency', currency: 'try' })}
              description={intl.formatMessage({ id: 'DASHBOARD.TOTALALLCURRENCYAMOUNT' })}
              titleColor='white'
              descriptionColor='white'
            />
          </div>

        </>}
      </div>

      <div className='row gy-5 g-xl-10'>
        {info?.roles?.includes(AuthRole.Bank.name) && <>
          <div className='col-xl-8 mb-10'>
            <ChartColumn
              chartHeight='400px'
              categories={bankCashDashResult?.cashFlows?.paymentPlansMonthlyExpenses?.map(item => `${item?.monthName}`)}
              data={[
                {
                  name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANEXPENSES' }),
                  data: bankCashDashResult?.cashFlows?.paymentPlansMonthlyExpenses?.map(item => item?.totalPrice),
                },
                {
                  name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSALES' }),
                  data: bankCashDashResult?.cashFlows?.paymentPlansMonthlySales?.map(item => item?.totalPrice),
                },
                {
                  name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSCASHFLOW' }),
                  data: bankCashDashResult?.cashFlows?.paymentPlansCashFlows?.map(item => item?.cashFlow),
                }
              ]}
              isMultipleColumn={true}
              title={intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSCASHFLOW' })}
              tooltip={{
                y: {
                  formatter: (val) => {
                    return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                  }
                }
              }}
            />
          </div>
          <div className='col-xl-4'>
            <div className='row gy-5 g-xl-10 mb-10'>
              <div className='col-xl-12'>
                <KTCard>
                  <KTCardHeader title={intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.CURRENTBANKCASHACCOUNTS' })} />
                  <KTCardBody>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.ACCOUNTNAME' })}</th>
                            <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.TOTALPRICE' })}</th>
                            <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.DEFAULTTOTALPRICE' })}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {
                            bankCashDashResult?.cashFlows?.currenctBankCashAccounts?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        {item.name}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className='text-dark fw-bold d-block fs-6'>
                                      {intl.formatNumber(item?.totalAmount, { style: 'currency', currency: item?.currencyCode })}
                                      {item?.currentRate != 0 && <>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                          {intl.formatNumber(item?.currentRate, { style: 'currency', currency: 'try' })}
                                        </span>
                                      </>}                                      
                                    </span>
                                  </td>
                                  <td>
                                    <span className='text-dark fw-bold d-block fs-6'>
                                      {intl.formatNumber(item?.defaultCurrenctAmount, { style: 'currency', currency: 'try' })}
                                    </span>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </KTCardBody>
                </KTCard>
              </div>
            </div>
          </div>
        </>}
      </div>

      {info?.roles?.includes(AuthRole.Bank.name) && <>

        <div className='row gy-5 g-xl-10 mb-10'>
          <div className='col-xl-6'>
            <KTCard>
              <KTCardHeader title={intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES' })} />
              <KTCardBody>
                <div className='card-scroll mh-400px'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>                          
                          <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.NAME' })}</th>
                          <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.PAYMENTPLANTYPENAME' })} - {intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.COMPANYNAME' })}</th>
                          <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.DEFERMENTDATE' })}</th>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.TOTALPRICE' })}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          bankCashDashResult?.paymentPlansExpenses?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      {item?.expenseInvoiceId && <>
                                        <Link to={detailPageUrl(ExpenseDetailPage[item.expenseTypeId], item.expenseInvoiceId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                      </>}
                                      {item?.salaryId && <>
                                        <Link to={detailPageUrl(DetailPage.Salary, item.salaryId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                      </>}
                                      {item?.checkId && <>
                                        <Link to={detailPageUrl(DetailPage.Check, item.checkId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                      </>}  
                                      <div>
                                        {item?.expenseInvoiceId && <>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            <Link to={detailPageUrl(ExpenseDetailPage[item.expenseTypeId], item.expenseInvoiceId)} className='text-dark fs-6 text-hover-primary' target='_blank'>{item.name}</Link>
                                          </span>
                                        </>}
                                        {item?.salaryId && <>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            <Link to={detailPageUrl(DetailPage.Salary, item.salaryId)} className='text-dark fs-6 text-hover-primary' target='_blank'>{item.name}</Link>
                                          </span>
                                        </>}
                                        {item?.checkId && <>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            <Link to={detailPageUrl(DetailPage.Check, item.checkId)} className='text-dark fs-6 text-hover-primary' target='_blank'>{item.name}</Link>
                                          </span>
                                        </>}
                                        {item?.expenseInvoiceId == null && item?.salaryId == null && item?.checkId == null && <>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {item.name}
                                          </span>
                                        </>}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {item?.paymentPlanTypeName}
                                    {item?.companyName && <>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {item?.companyName}
                                      </span>
                                    </>}                                                                          
                                  </span>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {intl.formatDate(new Date(item?.defermenDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {intl.formatNumber(item?.totalPrice, { style: 'currency', currency: 'try' })}
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>
          </div>

          <div className='col-xl-6'>
            <KTCard>
              <KTCardHeader title={intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES' })} />
              <KTCardBody>
                <div className='card-scroll mh-400px'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.NAME' })}</th>
                          <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.PAYMENTPLANTYPENAME' })} - {intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.COMPANYNAME' })}</th>
                          <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.DEFERMENTDATE' })}</th>
                          <th className='min-w-120px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.TOTALPRICE' })}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          bankCashDashResult?.paymentPlansSales?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      {item?.saleInvoiceId && <>
                                        <Link to={detailPageUrl(DetailPage.SaleInvoice, item.saleInvoiceId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                      </>}                                      
                                      {item?.checkId && <>
                                        <Link to={detailPageUrl(DetailPage.Check, item.checkId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                      </>}
                                      <div>
                                        {item?.saleInvoiceId && <>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            <Link to={detailPageUrl(DetailPage.SaleInvoice, item.saleInvoiceId)} className='text-dark fs-6 text-hover-primary' target='_blank'>{item.name}</Link>
                                          </span>
                                        </>}
                                        {item?.checkId && <>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            <Link to={detailPageUrl(DetailPage.Check, item.checkId)} className='text-dark fs-6 text-hover-primary' target='_blank'>{item.name}</Link>
                                          </span>
                                        </>}
                                        {item?.saleInvoiceId == null && item?.checkId == null && <>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {item.name}
                                          </span>
                                        </>}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {item?.paymentPlanTypeName}
                                    {item?.companyName && <>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {item?.companyName}
                                      </span>
                                    </>}    
                                  </span>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {intl.formatDate(new Date(item?.defermenDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-dark fw-bold d-block fs-6'>
                                    {intl.formatNumber(item?.totalPrice, { style: 'currency', currency: 'try' })}
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>
          </div>
        </div>        
      </>}

      {
        loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
          <span className="spinner-border text-primary" role="status"></span>
          <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
        </div>)
      }
    </>
  )
}
export { Dashboard };
