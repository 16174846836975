import { companyConstants } from '../constants';
import {AuthCheck, DownloadFileFn} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  detail: false,
  excel: null
}

export function company(state = {}, action) {
  switch (action.type) {
    //#region Company
    case companyConstants.COMPANY_GETALL_REQUEST:
      return { ...state, ...initialState };
    case companyConstants.COMPANY_GETALL_SUCCESS:
      return {
        ...state,
        company: action.company,
        loading: false
      };
    case companyConstants.COMPANY_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case companyConstants.COMPANY_GETID_REQUEST:
      return { ...state, ...initialState };
    case companyConstants.COMPANY_GETID_SUCCESS:
      return {
        ...state,
        detail: action.company,
        loading: false
      };
    case companyConstants.COMPANY_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case companyConstants.COMPANY_DELETE_REQUEST:
      return { ...state, ...initialState };
    case companyConstants.COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case companyConstants.COMPANY_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case companyConstants.COMPANY_ARCHIVE_REQUEST:
      return { ...state, ...initialState };
    case companyConstants.COMPANY_ARCHIVE_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case companyConstants.COMPANY_ARCHIVE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case companyConstants.COMPANY_CREATE_REQUEST:
      return { ...state, ...initialState };
    case companyConstants.COMPANY_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.company,
        loading: false
      };
    case companyConstants.COMPANY_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case companyConstants.COMPANY_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case companyConstants.COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.company || action.params,
        detail: action.params,
        loading: false
      };
    case companyConstants.COMPANY_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };

    case companyConstants.COMPANY_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case companyConstants.COMPANY_EXCEL_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)

      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    }
    case companyConstants.COMPANY_EXCEL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion    
    default:
      return state
  }
}
