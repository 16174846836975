import { expenseinvoiceConstants } from '../constants';
import {AuthCheck, DownloadFileFn} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false,
  excel: null
}

export function expenseinvoice(state = {}, action) {
  switch (action.type) {
    //#region ExpenseInvoice
    case expenseinvoiceConstants.EXPENSEINVOICE_GETALL_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICE_GETALL_SUCCESS:
      return {
        ...state,
        expenseinvoice: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICE_GETALL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };   
    
    case expenseinvoiceConstants.EXPENSEINVOICE_DELETE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICE_DELETE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICE_ARCHIVING_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICE_ARCHIVING_SUCCESS:
      return {
        ...state,
        isArchived: true,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICE_ARCHIVING_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICE_EXCEL_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICE_EXCEL_SUCCESS: {
      DownloadFileFn(action.excel?.data, action.excel?.fileName || action.fileName)
      
      return {
        ...state,
        excel: action.excel,
        loading: false
      };
    }
      
    case expenseinvoiceConstants.EXPENSEINVOICE_EXCEL_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Bank
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_GETID_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_GETID_SUCCESS:
      return {
        ...state,
        detail: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_CREATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice || action.params,
        detail: action.params,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEBANK_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Detail
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_GETID_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_GETID_SUCCESS:
      return {
        ...state,
        detail: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_CREATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
  
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice || action.params,
        detail: action.params,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEDETAIL_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Fast
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_GETID_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_GETID_SUCCESS:
      return {
        ...state,
        detail: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_CREATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice || action.params,
        detail: action.params,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICEFAST_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Sundry
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_GETID_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_GETID_SUCCESS:
      return {
        ...state,
        detail: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_CREATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice || action.params,
        detail: action.params,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICESUNDRY_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion

    //#region Tax
    case expenseinvoiceConstants.EXPENSEINVOICETAX_GETID_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICETAX_GETID_SUCCESS:
      return {
        ...state,
        detail: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICETAX_GETID_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICETAX_CREATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICETAX_CREATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICETAX_CREATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };
    
    case expenseinvoiceConstants.EXPENSEINVOICETAX_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case expenseinvoiceConstants.EXPENSEINVOICETAX_UPDATE_SUCCESS:
      return {
        ...state,
        isRedirect: action.expenseinvoice || action.params,
        detail: action.params,
        loading: false
      };
    case expenseinvoiceConstants.EXPENSEINVOICETAX_UPDATE_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        detail: action.params,
        error: action.error,
        loading: false
      };
    //#endregion
    default:
      return state
  }
}
