import { proposalConstants } from '../constants';
import {
  //#region Proposal
  SearchProposalClient,
  GetProposalClient,
  DeleteProposalClient,
  UpdateProposalClient,
  UpdateProposalStatusClient,
  CreateProposalClient,
  ArchivingProposalClient,
  //#endregion    
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//#region Proposal
const archivingProposalClient = new ArchivingProposalClient(SERVICE_URL);
const searchProposalClient = new SearchProposalClient(SERVICE_URL);
const getProposalClient = new GetProposalClient(SERVICE_URL);
const createProposalClient = new CreateProposalClient(SERVICE_URL);
const deleteProposalClient = new DeleteProposalClient(SERVICE_URL);
const updateProposalClient = new UpdateProposalClient(SERVICE_URL);
const updateProposalStatusClient = new UpdateProposalStatusClient(SERVICE_URL);
//#endregion

export const proposalActions = {
  //#region Proposal
  proposalGetAll,
  proposalGetId,
  proposalDelete,
  proposalCreate,
  proposalUpdate,
  proposalUpdateStatus,
  proposalArchiving,
  proposalExcel,
  //#endregion    
};

//#region Proposal
function proposalGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchProposalClient.search(params.name, params.proposalStatusId, params.projectId, params.companyId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        proposal => dispatch(success(proposal)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: proposalConstants.PROPOSAL_GETALL_REQUEST } }
  function success(proposal) { return { type: proposalConstants.PROPOSAL_GETALL_SUCCESS, proposal } }
  function failure(error) { return { type: proposalConstants.PROPOSAL_GETALL_FAILURE, error } }
}

function proposalExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchProposalClient.excel(params.name, params.proposalStatusId, params.projectId, params.companyId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: proposalConstants.PROPOSAL_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: proposalConstants.PROPOSAL_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: proposalConstants.PROPOSAL_EXCEL_FAILURE, error } }
}

function proposalGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getProposalClient.get(id)
      .then(
        proposal => dispatch(success(proposal)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: proposalConstants.PROPOSAL_GETID_REQUEST, id } }
  function success(proposal) { return { type: proposalConstants.PROPOSAL_GETID_SUCCESS, proposal } }
  function failure(error) { return { type: proposalConstants.PROPOSAL_GETID_FAILURE, error } }
}

function proposalDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteProposalClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: proposalConstants.PROPOSAL_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: proposalConstants.PROPOSAL_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: proposalConstants.PROPOSAL_DELETE_FAILURE, error } }
}

function proposalArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingProposalClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: proposalConstants.PROPOSAL_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: proposalConstants.PROPOSAL_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: proposalConstants.PROPOSAL_ARCHIVE_FAILURE, error } }
}

function proposalCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createProposalClient.create(params)
      .then(
        proposal => dispatch(success(proposal)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: proposalConstants.PROPOSAL_CREATE_REQUEST, params } }
  function success(proposal) { return { type: proposalConstants.PROPOSAL_CREATE_SUCCESS, proposal } }
  function failure(error) { return { type: proposalConstants.PROPOSAL_CREATE_FAILURE, error } }
}

function proposalUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateProposalClient.update(params)
      .then(
        proposal => dispatch(success(proposal, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: proposalConstants.PROPOSAL_UPDATE_REQUEST, params } }
  function success(proposal, params) { return { type: proposalConstants.PROPOSAL_UPDATE_SUCCESS, proposal, params } }
  function failure(error, params) { return { type: proposalConstants.PROPOSAL_UPDATE_FAILURE, error, params } }
}

function proposalUpdateStatus(params) {
  return dispatch => {
    dispatch(request(params));

    updateProposalStatusClient.updateStatus(params)
      .then(
        proposal => dispatch(success(proposal, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: proposalConstants.PROPOSAL_UPDATESTATUS_REQUEST, params } }
  function success(proposal, params) { return { type: proposalConstants.PROPOSAL_UPDATESTATUS_SUCCESS, proposal, params } }
  function failure(error, params) { return { type: proposalConstants.PROPOSAL_UPDATESTATUS_FAILURE, error, params } }
}
//#endregion
