import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { KTSVG, toAbsoluteUrl, PurchaseProposalStatus } from '../../helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch } from 'react-redux';
import { purchaseActions } from '../../redux/actions';
import { DeleteModal, DataTables, Select, InputPrice} from '..';

const PurchaseProposal = ({data, id}) => {
  const intl = useIntl()
  const [deleteId, setDeleteId] = useState();
  const [show, setShow] = useState(false);

	const dispatch = useDispatch();
  
  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    TotalPrice: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    PurchaseProposalStatusId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    PurchaseRequestId: id,
    Name: '',
    CompanyName: '',
    TotalPrice: '',
    ProposalFile: null,
    PurchaseProposalStatusId: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(purchaseActions.purchaseProposalCreate(values));
      resetForm()
    },
  })

  const handleClickDelete = (id) => {
    setDeleteId(id)
    setShow(true)
  }

  const handleDelete = () => {
    dispatch(purchaseActions.purchaseProposalDelete({Id: deleteId}));
    setShow(false)
  }

  return (
    <>
      <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
        <div className='row pt-5'>
          <div className='col-lg-4 mb-6 fv-row fv-plugins-icon-container'>
            <input
              placeholder={intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.NAME' })}
              type='text'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              required
              disabled={formik.isSubmitting}
              {...formik.getFieldProps('Name')}
            />
            {formik.touched.Name && formik.errors.Name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.Name}</span>
                </div>
              </div>
            )}
          </div>
          
          <div className='col-lg-4 mb-6 fv-row fv-plugins-icon-container'>
            <InputPrice 
              placeholder={intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.TOTALPRICE' })}
              {...formik.getFieldProps('TotalPrice')} 
              onChange={(value) => formik.setFieldValue('TotalPrice', Number(value))} 
            />
            {formik.touched.TotalPrice && formik.errors.TotalPrice && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.TotalPrice}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-lg-4 mb-6 fv-row fv-plugins-icon-container'>
            <Select
              placeholder={intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.PURCHASEPROPOSALSTATUSNAME' })}
              data={PurchaseProposalStatus} 
              required
              {...formik.getFieldProps('PurchaseProposalStatusId')}
              onChange={item => formik.setFieldValue('PurchaseProposalStatusId', Number(item?.value))}
              textType={'intl'}
            />
            {formik.touched.PurchaseProposalStatusId && formik.errors.PurchaseProposalStatusId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.PurchaseProposalStatusId}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-lg-4 mb-6 fv-row fv-plugins-icon-container'>
            <input
              placeholder={intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.COMPANYNAME' })}
              type='text'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              required
              disabled={formik.isSubmitting}
              {...formik.getFieldProps('CompanyName')}
            />
          </div>

          <div className='col-lg-4 mb-6 fv-row fv-plugins-icon-container'>
            <label htmlFor='ProposalFile' className='btn btn-bg-light w-100 btn-active-color-primary position-relative overflow-hidden text-muted'>
              <input
                id='ProposalFile'
                className='position-absolute z-index-2 opacity-0'
                style={{width: 0, height: 0}}
                type='file' 
                accept='image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                name={'ProposalFile'}
                onChange={(event) => formik.setFieldValue('ProposalFile', event.currentTarget.files[0])}
              />
              <KTSVG
                path='/images/icons/duotune/communication/com008.svg'
                className='svg-icon-2 position-relative z-index-1'
              />

              <span>{intl.formatMessage({ id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.PROPOSALFILEURL' })}</span>
            </label>
          </div>

          <div className='col-lg-4 mb-6 fv-row fv-plugins-icon-container d-grid'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
              {(formik.isSubmitting) && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'PLEASE' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>

      <div className='separator mt-4 mb-4'></div>

      {data && data?.length > 0 && <DataTables 
        columns={[
          {
            title: intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.NAME' }),
            prop: 'name',
            isSortable: true,
            isFilterable: true
          },
          {
            title: intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.COMPANYNAME' }),
            prop: 'companyName',
            isSortable: true,
            isFilterable: true
          },
          {
            title: intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.PURCHASEPROPOSALSTATUSNAME' }),
            prop: 'purchaseProposalStatusName',
            isSortable: true,
            isFilterable: true
          },
          {
            title: intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.TOTALPRICE' }),
            prop: 'totalPrice',
            cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
            isSortable: true,
            isFilterable: true,
            alignment: {
              horizontal: 'right'
            }
          },
          {
            title: intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.PROPOSALFILEURL' }),
            prop: 'proposalFileUrl',
            cell: (row) => {
              if (!row.proposalFileUrl) return false;
              
              return (
                <a href={row.proposalFileUrl} target='_blank' rel="noreferrer" className='d-flex align-items-center'>
                  <div className='symbol symbol-30px me-2'>
                    <span className='symbol-label'><img src={toAbsoluteUrl('/images/icons/duotune/files/folder-document.svg')} className="h-50 align-self-center" alt="" /></span>
                  </div>
                  <div className='flex-grow-1'>
                    <div className='text-dark fw-bold text-hover-primary fs-6'>{intl.formatMessage({id: 'REQUEST.GET.PURCHASEPROPOSALITEMS.PROPOSALFILEURL' })}</div>
                  </div>
                </a>
              )
            }
          },
          {
            title: '',
            prop: 'id',
            cell: (row) => {
              return (
                <button 
                  type="button" 
                  className="btn btn-sm btn-icon btn-active-color-primary" 
                  onClick={() => handleClickDelete(row.id)}
                >
                  <KTSVG path='/images/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                </button>
              )
            }
          }
        ]
        }
        data={data}
        exportTitle={intl.formatMessage({ id: 'REQUEST.GET.PURCHASEPROPOSALITEMS' })}
      />}

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
      />
    </>
  )
}

PurchaseProposal.propTypes = {
	collapse: PropTypes.bool,
  SourceId: PropTypes.number,
	SourceTypeId: PropTypes.number,
};

export {PurchaseProposal}
