export const DetailPage = {
  CompanySupplier: '/expenses/supplier/detail/',
  CompanyCustomer: '/expenses/supplier/detail/',
  Project: '/projects/projects/detail/',
  Staff: '/staff/staff/detail/',
  Estates: '/projects/estates/detail/',
  SaleInvoice: '/sales/sales-invoices/detail/',
  Salary: '/staff/salary/detail/',
  Check: '/cash/checks/detail/',
  Product: '/stock/product-services/detail/',
  Bank: '/cash/bank-safes/detail/',
  Overtime: '/staff/overtime/detail/',
  Leave: '/staff/leave/detail/',
  Task: '/workplan/task/detail/',
  PaymentRequest: '/expenses/payment-request/detail/'
}

export const ExpenseDetailPage = {
  1: '/expenses/expenses/detail/detail/',
  2: '/expenses/expenses/fast/detail/',
  3: '/expenses/expenses/tax/detail/',
  4: '/expenses/expenses/bank/detail/',
  5: '/expenses/expenses/sundry/detail/',
}


export const detailPageUrl = (type, id) => {
  return `${type}${id || ''}`;
}
