export const guaranteeletterConstants = {
  //#region Guaranteeletter
  GUARATEELETTER_GETALL_REQUEST: 'GUARATEELETTER_GETALL_REQUEST',
  GUARATEELETTER_GETALL_SUCCESS: 'GUARATEELETTER_GETALL_SUCCESS',
  GUARATEELETTER_GETALL_FAILURE: 'GUARATEELETTER_GETALL_FAILURE',

  GUARATEELETTER_GETID_REQUEST: 'GUARATEELETTER_GETID_REQUEST',
  GUARATEELETTER_GETID_SUCCESS: 'GUARATEELETTER_GETID_SUCCESS',
  GUARATEELETTER_GETID_FAILURE: 'GUARATEELETTER_GETID_FAILURE',
  
  GUARATEELETTER_EXCEL_REQUEST: 'GUARATEELETTER_EXCEL_REQUEST',
  GUARATEELETTER_EXCEL_SUCCESS: 'GUARATEELETTER_EXCEL_SUCCESS',
  GUARATEELETTER_EXCEL_FAILURE: 'GUARATEELETTER_EXCEL_FAILURE',
  
  GUARATEELETTER_UPDATE_REQUEST: 'GUARATEELETTER_UPDATE_REQUEST',
  GUARATEELETTER_UPDATE_SUCCESS: 'GUARATEELETTER_UPDATE_SUCCESS',
  GUARATEELETTER_UPDATE_FAILURE: 'GUARATEELETTER_UPDATE_FAILURE',
  
  GUARATEELETTER_CREATE_REQUEST: 'GUARATEELETTER_CREATE_REQUEST',
  GUARATEELETTER_CREATE_SUCCESS: 'GUARATEELETTER_CREATE_SUCCESS',
  GUARATEELETTER_CREATE_FAILURE: 'GUARATEELETTER_CREATE_FAILURE',
  
  GUARATEELETTER_DELETE_REQUEST: 'GUARATEELETTER_DELETE_REQUEST',
  GUARATEELETTER_DELETE_SUCCESS: 'GUARATEELETTER_DELETE_SUCCESS',
  GUARATEELETTER_DELETE_FAILURE: 'GUARATEELETTER_DELETE_FAILURE',
  //#endregion            
};
