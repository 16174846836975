export const staffConstants = {
  //#region Staff
  STAFF_ARCHIVING_REQUEST: 'STAFF_ARCHIVING_REQUEST',
  STAFF_ARCHIVING_SUCCESS: 'STAFF_ARCHIVING_SUCCESS',
  STAFF_ARCHIVING_FAILURE: 'STAFF_ARCHIVING_FAILURE',

  STAFF_GETALL_REQUEST: 'STAFF_GETALL_REQUEST',
  STAFF_GETALL_SUCCESS: 'STAFF_GETALL_SUCCESS',
  STAFF_GETALL_FAILURE: 'STAFF_GETALL_FAILURE',

  STAFF_GETID_REQUEST: 'STAFF_GETID_REQUEST',
  STAFF_GETID_SUCCESS: 'STAFF_GETID_SUCCESS',
  STAFF_GETID_FAILURE: 'STAFF_GETID_FAILURE',

  STAFF_DELETE_REQUEST: 'STAFF_DELETE_REQUEST',
  STAFF_DELETE_SUCCESS: 'STAFF_DELETE_SUCCESS',
  STAFF_DELETE_FAILURE: 'STAFF_DELETE_FAILURE',

  STAFF_CREATE_REQUEST: 'STAFF_CREATE_REQUEST',
  STAFF_CREATE_SUCCESS: 'STAFF_CREATE_SUCCESS',
  STAFF_CREATE_FAILURE: 'STAFF_CREATE_FAILURE',

  STAFF_UPDATE_REQUEST: 'STAFF_UPDATE_REQUEST',
  STAFF_UPDATE_SUCCESS: 'STAFF_UPDATE_SUCCESS',
  STAFF_UPDATE_FAILURE: 'STAFF_UPDATE_FAILURE',

  STAFF_GETID_CHECKOUT_REQUEST: 'STAFF_GETID_CHECKOUT_REQUEST',
  STAFF_GETID_CHECKOUT_SUCCESS: 'STAFF_GETID_CHECKOUT_SUCCESS',
  STAFF_GETID_CHECKOUT_FAILURE: 'STAFF_GETID_CHECKOUT_FAILURE',

  STAFF_GETID_OVERTIME_REQUEST: 'STAFF_GETID_OVERTIME_REQUEST',
  STAFF_GETID_OVERTIME_SUCCESS: 'STAFF_GETID_OVERTIME_SUCCESS',
  STAFF_GETID_OVERTIME_FAILURE: 'STAFF_GETID_OVERTIME_FAILURE',

  STAFF_GETID_LEAVE_REQUEST: 'STAFF_GETID_LEAVE_REQUEST',
  STAFF_GETID_LEAVE_SUCCESS: 'STAFF_GETID_LEAVE_SUCCESS',
  STAFF_GETID_LEAVE_FAILURE: 'STAFF_GETID_LEAVE_FAILURE',

  STAFF_GETID_SALARY_REQUEST: 'STAFF_GETID_SALARY_REQUEST',
  STAFF_GETID_SALARY_SUCCESS: 'STAFF_GETID_SALARY_SUCCESS',
  STAFF_GETID_SALARY_FAILURE: 'STAFF_GETID_SALARY_FAILURE',

  STAFF_EXCEL_REQUEST: 'STAFF_EXCEL_REQUEST',
  STAFF_EXCEL_SUCCESS: 'STAFF_EXCEL_SUCCESS',
  STAFF_EXCEL_FAILURE: 'STAFF_EXCEL_FAILURE',
  //#endregion

  //#region Leave
  LEAVE_GETALL_REQUEST: 'LEAVE_GETALL_REQUEST',
  LEAVE_GETALL_SUCCESS: 'LEAVE_GETALL_SUCCESS',
  LEAVE_GETALL_FAILURE: 'LEAVE_GETALL_FAILURE',

  LEAVE_GETID_REQUEST: 'LEAVE_GETID_REQUEST',
  LEAVE_GETID_SUCCESS: 'LEAVE_GETID_SUCCESS',
  LEAVE_GETID_FAILURE: 'LEAVE_GETID_FAILURE',

  LEAVE_DELETE_REQUEST: 'LEAVE_DELETE_REQUEST',
  LEAVE_DELETE_SUCCESS: 'LEAVE_DELETE_SUCCESS',
  LEAVE_DELETE_FAILURE: 'LEAVE_DELETE_FAILURE',

  LEAVE_CREATE_REQUEST: 'LEAVE_CREATE_REQUEST',
  LEAVE_CREATE_SUCCESS: 'LEAVE_CREATE_SUCCESS',
  LEAVE_CREATE_FAILURE: 'LEAVE_CREATE_FAILURE',

  LEAVE_UPDATE_REQUEST: 'LEAVE_UPDATE_REQUEST',
  LEAVE_UPDATE_SUCCESS: 'LEAVE_UPDATE_SUCCESS',
  LEAVE_UPDATE_FAILURE: 'LEAVE_UPDATE_FAILURE',

  LEAVE_EXCEL_REQUEST: 'LEAVE_EXCEL_REQUEST',
  LEAVE_EXCEL_SUCCESS: 'LEAVE_EXCEL_SUCCESS',
  LEAVE_EXCEL_FAILURE: 'LEAVE_EXCEL_FAILURE',
  //#endregion

  //#region Earning Leave
  EARNING_GETID_REQUEST: 'EARNING_GETID_REQUEST',
  EARNING_GETID_SUCCESS: 'EARNING_GETID_SUCCESS',
  EARNING_GETID_FAILURE: 'EARNING_GETID_FAILURE',

  EARNING_GETBALANCE_REQUEST: 'EARNING_GETBALANCE_REQUEST',
  EARNING_GETBALANCE_SUCCESS: 'EARNING_GETBALANCE_SUCCESS',
  EARNING_GETBALANCE_FAILURE: 'EARNING_GETBALANCE_FAILURE',

  EARNING_DELETE_REQUEST: 'EARNING_DELETE_REQUEST',
  EARNING_DELETE_SUCCESS: 'EARNING_DELETE_SUCCESS',
  EARNING_DELETE_FAILURE: 'EARNING_DELETE_FAILURE',

  EARNING_CREATE_REQUEST: 'EARNING_CREATE_REQUEST',
  EARNING_CREATE_SUCCESS: 'EARNING_CREATE_SUCCESS',
  EARNING_CREATE_FAILURE: 'EARNING_CREATE_FAILURE',
  //#endregion

  //#region Salary
  SALARY_GETALL_REQUEST: 'SALARY_GETALL_REQUEST',
  SALARY_GETALL_SUCCESS: 'SALARY_GETALL_SUCCESS',
  SALARY_GETALL_FAILURE: 'SALARY_GETALL_FAILURE',

  SALARY_GETID_REQUEST: 'SALARY_GETID_REQUEST',
  SALARY_GETID_SUCCESS: 'SALARY_GETID_SUCCESS',
  SALARY_GETID_FAILURE: 'SALARY_GETID_FAILURE',

  SALARY_DELETE_REQUEST: 'SALARY_DELETE_REQUEST',
  SALARY_DELETE_SUCCESS: 'SALARY_DELETE_SUCCESS',
  SALARY_DELETE_FAILURE: 'SALARY_DELETE_FAILURE',

  SALARY_ARCHIVING_REQUEST: 'SALARY_ARCHIVING_REQUEST',
  SALARY_ARCHIVING_SUCCESS: 'SALARY_ARCHIVING_SUCCESS',
  SALARY_ARCHIVING_FAILURE: 'SALARY_ARCHIVING_FAILURE',

  SALARY_CREATE_REQUEST: 'SALARY_CREATE_REQUEST',
  SALARY_CREATE_SUCCESS: 'SALARY_CREATE_SUCCESS',
  SALARY_CREATE_FAILURE: 'SALARY_CREATE_FAILURE',

  SALARY_UPDATE_REQUEST: 'SALARY_UPDATE_REQUEST',
  SALARY_UPDATE_SUCCESS: 'SALARY_UPDATE_SUCCESS',
  SALARY_UPDATE_FAILURE: 'SALARY_UPDATE_FAILURE',

  SALARY_EXCEL_REQUEST: 'SALARY_EXCEL_REQUEST',
  SALARY_EXCEL_SUCCESS: 'SALARY_EXCEL_SUCCESS',
  SALARY_EXCEL_FAILURE: 'SALARY_EXCEL_FAILURE',
  //#endregion

  //#region Overtime
  OVERTIME_GETALL_REQUEST: 'OVERTIME_GETALL_REQUEST',
  OVERTIME_GETALL_SUCCESS: 'OVERTIME_GETALL_SUCCESS',
  OVERTIME_GETALL_FAILURE: 'OVERTIME_GETALL_FAILURE',

  OVERTIME_GETID_REQUEST: 'OVERTIME_GETID_REQUEST',
  OVERTIME_GETID_SUCCESS: 'OVERTIME_GETID_SUCCESS',
  OVERTIME_GETID_FAILURE: 'OVERTIME_GETID_FAILURE',

  OVERTIME_DELETE_REQUEST: 'OVERTIME_DELETE_REQUEST',
  OVERTIME_DELETE_SUCCESS: 'OVERTIME_DELETE_SUCCESS',
  OVERTIME_DELETE_FAILURE: 'OVERTIME_DELETE_FAILURE',

  OVERTIME_CREATE_REQUEST: 'OVERTIME_CREATE_REQUEST',
  OVERTIME_CREATE_SUCCESS: 'OVERTIME_CREATE_SUCCESS',
  OVERTIME_CREATE_FAILURE: 'OVERTIME_CREATE_FAILURE',

  OVERTIME_UPDATE_REQUEST: 'OVERTIME_UPDATE_REQUEST',
  OVERTIME_UPDATE_SUCCESS: 'OVERTIME_UPDATE_SUCCESS',
  OVERTIME_UPDATE_FAILURE: 'OVERTIME_UPDATE_FAILURE',

  OVERTIME_EXCEL_REQUEST: 'OVERTIME_EXCEL_REQUEST',
  OVERTIME_EXCEL_SUCCESS: 'OVERTIME_EXCEL_SUCCESS',
  OVERTIME_EXCEL_FAILURE: 'OVERTIME_EXCEL_FAILURE',
  //#endregion

  //#region Certificate

  CERTIFICATE_DELETE_REQUEST: 'CERTIFICATE_DELETE_REQUEST',
  CERTIFICATE_DELETE_SUCCESS: 'CERTIFICATE_DELETE_SUCCESS',
  CERTIFICATE_DELETE_FAILURE: 'CERTIFICATE_DELETE_FAILURE',

  CERTIFICATE_CREATE_REQUEST: 'CERTIFICATE_CREATE_REQUEST',
  CERTIFICATE_CREATE_SUCCESS: 'CERTIFICATE_CREATE_SUCCESS',
  CERTIFICATE_CREATE_FAILURE: 'CERTIFICATE_CREATE_FAILURE',
  //#endregion

  //#region MonthlySalary
  MONTHLY_SALARY_GETALL_REQUEST: 'MONTHLY_SALARY_GETALL_REQUEST',
  MONTHLY_SALARY_GETALL_SUCCESS: 'MONTHLY_SALARY_GETALL_SUCCESS',
  MONTHLY_SALARY_GETALL_FAILURE: 'MONTHLY_SALARY_GETALL_FAILURE',

  MONTHLY_SALARY_EXCEL_REQUEST: 'MONTHLY_SALARY_EXCEL_REQUEST',
  MONTHLY_SALARY_EXCEL_SUCCESS: 'MONTHLY_SALARY_EXCEL_SUCCESS',
  MONTHLY_SALARY_EXCEL_FAILURE: 'MONTHLY_SALARY_EXCEL_FAILURE',

  MONTHLY_SALARY_GET_REQUEST: 'MONTHLY_SALARY_GET_REQUEST',
  MONTHLY_SALARY_GET_SUCCESS: 'MONTHLY_SALARY_GET_SUCCESS',
  MONTHLY_SALARY_GET_FAILURE: 'MONTHLY_SALARY_GET_FAILURE',

  MONTHLY_SALARY_GET_MONTHLY_REQUEST: 'MONTHLY_SALARY_GET_MONTHLY_REQUEST',
  MONTHLY_SALARY_GET_MONTHLY_SUCCESS: 'MONTHLY_SALARY_GET_MONTHLY_SUCCESS',
  MONTHLY_SALARY_GET_MONTHLY_FAILURE: 'MONTHLY_SALARY_GET_MONTHLY_FAILURE',

  MONTHLY_SALARY_CREATE_REQUEST: 'MONTHLY_SALARY_CREATE_REQUEST',
  MONTHLY_SALARY_CREATE_SUCCESS: 'MONTHLY_SALARY_CREATE_SUCCESS',
  MONTHLY_SALARY_CREATE_FAILURE: 'MONTHLY_SALARY_CREATE_FAILURE',

  MONTHLY_SALARY_UPDATE_REQUEST: 'MONTHLY_SALARY_UPDATE_REQUEST',
  MONTHLY_SALARY_UPDATE_SUCCESS: 'MONTHLY_SALARY_UPDATE_SUCCESS',
  MONTHLY_SALARY_UPDATE_FAILURE: 'MONTHLY_SALARY_UPDATE_FAILURE',

  MONTHLY_SALARY_UPDATE_PAYMENT_REQUEST: 'MONTHLY_SALARY_UPDATE_PAYMENT_REQUEST',
  MONTHLY_SALARY_UPDATE_PAYMENT_SUCCESS: 'MONTHLY_SALARY_UPDATE_PAYMENT_SUCCESS',
  MONTHLY_SALARY_UPDATE_PAYMENT_FAILURE: 'MONTHLY_SALARY_UPDATE_PAYMENT_FAILURE',
  //#endregion
};
