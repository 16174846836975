export const productConstants = {
  //#region Product
  PRODUCT_GETALL_REQUEST: 'PRODUCT_GETALL_REQUEST',
  PRODUCT_GETALL_SUCCESS: 'PRODUCT_GETALL_SUCCESS',
  PRODUCT_GETALL_FAILURE: 'PRODUCT_GETALL_FAILURE',

  PRODUCT_GETID_REQUEST: 'PRODUCT_GETID_REQUEST',
  PRODUCT_GETID_SUCCESS: 'PRODUCT_GETID_SUCCESS',
  PRODUCT_GETID_FAILURE: 'PRODUCT_GETID_FAILURE',

  PRODUCT_GET_INFO_REQUEST: 'PRODUCT_GET_INFO_REQUEST',
  PRODUCT_GET_INFO_SUCCESS: 'PRODUCT_GET_INFO_SUCCESS',
  PRODUCT_GET_INFO_FAILURE: 'PRODUCT_GET_INFO_FAILURE',

  PRODUCT_DELETE_REQUEST: 'PRODUCT_DELETE_REQUEST',
  PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
  PRODUCT_DELETE_FAILURE: 'PRODUCT_DELETE_FAILURE',

  PRODUCT_CREATE_REQUEST: 'PRODUCT_CREATE_REQUEST',
  PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
  PRODUCT_CREATE_FAILURE: 'PRODUCT_CREATE_FAILURE',

  PRODUCT_CREATEFAST_REQUEST: 'PRODUCT_CREATEFAST_REQUEST',
  PRODUCT_CREATEFAST_SUCCESS: 'PRODUCT_CREATEFAST_SUCCESS',
  PRODUCT_CREATEFAST_FAILURE: 'PRODUCT_CREATEFAST_FAILURE',

  PRODUCT_UPDATE_REQUEST: 'PRODUCT_UPDATE_REQUEST',
  PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
  PRODUCT_UPDATE_FAILURE: 'PRODUCT_UPDATE_FAILURE',

  PRODUCT_ARCHIVE_REQUEST: 'PRODUCT_ARCHIVE_REQUEST',
  PRODUCT_ARCHIVE_SUCCESS: 'PRODUCT_ARCHIVE_SUCCESS',
  PRODUCT_ARCHIVE_FAILURE: 'PRODUCT_ARCHIVE_FAILURE',

  PRODUCT_GETSTOCK_REQUEST: 'PRODUCT_GETSTOCK_REQUEST',
  PRODUCT_GETSTOCK_SUCCESS: 'PRODUCT_GETSTOCK_SUCCESS',
  PRODUCT_GETSTOCK_FAILURE: 'PRODUCT_GETSTOCK_FAILURE',

  PRODUCT_SEARCHSTOCKS_REQUEST: 'PRODUCT_SEARCHSTOCKS_REQUEST',
  PRODUCT_SEARCHSTOCKS_SUCCESS: 'PRODUCT_SEARCHSTOCKS_SUCCESS',
  PRODUCT_SEARCHSTOCKS_FAILURE: 'PRODUCT_SEARCHSTOCKS_FAILURE',

  PRODUCT_SEARCHSALEINVOICES_REQUEST: 'PRODUCT_SEARCHSALEINVOICES_REQUEST',
  PRODUCT_SEARCHSALEINVOICES_SUCCESS: 'PRODUCT_SEARCHSALEINVOICES_SUCCESS',
  PRODUCT_SEARCHSALEINVOICES_FAILURE: 'PRODUCT_SEARCHSALEINVOICES_FAILURE',

  PRODUCT_SEARCHEXPENSEINVOICES_REQUEST: 'PRODUCT_SEARCHEXPENSEINVOICES_REQUEST',
  PRODUCT_SEARCHEXPENSEINVOICES_SUCCESS: 'PRODUCT_SEARCHEXPENSEINVOICES_SUCCESS',
  PRODUCT_SEARCHEXPENSEINVOICES_FAILURE: 'PRODUCT_SEARCHEXPENSEINVOICES_FAILURE',

  PRODUCT_SEARCHWAREHOUSES_REQUEST: 'PRODUCT_SEARCHWAREHOUSES_REQUEST',
  PRODUCT_SEARCHWAREHOUSES_SUCCESS: 'PRODUCT_SEARCHWAREHOUSES_SUCCESS',
  PRODUCT_SEARCHWAREHOUSES_FAILURE: 'PRODUCT_SEARCHWAREHOUSES_FAILURE',

  PRODUCT_UPDATEWAREHOUSES_REQUEST: 'PRODUCT_UPDATEWAREHOUSES_REQUEST',
  PRODUCT_UPDATEWAREHOUSES_SUCCESS: 'PRODUCT_UPDATEWAREHOUSES_SUCCESS',
  PRODUCT_UPDATEWAREHOUSES_FAILURE: 'PRODUCT_UPDATEWAREHOUSES_FAILURE',

  PRODUCT_EXCEL_REQUEST: 'PRODUCT_EXCEL_REQUEST',
  PRODUCT_EXCEL_SUCCESS: 'PRODUCT_EXCEL_SUCCESS',
  PRODUCT_EXCEL_FAILURE: 'PRODUCT_EXCEL_FAILURE',

  //#endregion

  //#region Warehouse
  WAREHOUSE_GETALL_REQUEST: 'WAREHOUSE_GETALL_REQUEST',
  WAREHOUSE_GETALL_SUCCESS: 'WAREHOUSE_GETALL_SUCCESS',
  WAREHOUSE_GETALL_FAILURE: 'WAREHOUSE_GETALL_FAILURE',

  WAREHOUSE_GETID_REQUEST: 'WAREHOUSE_GETID_REQUEST',
  WAREHOUSE_GETID_SUCCESS: 'WAREHOUSE_GETID_SUCCESS',
  WAREHOUSE_GETID_FAILURE: 'WAREHOUSE_GETID_FAILURE',

  WAREHOUSE_DELETE_REQUEST: 'WAREHOUSE_DELETE_REQUEST',
  WAREHOUSE_DELETE_SUCCESS: 'WAREHOUSE_DELETE_SUCCESS',
  WAREHOUSE_DELETE_FAILURE: 'WAREHOUSE_DELETE_FAILURE',

  WAREHOUSE_CREATE_REQUEST: 'WAREHOUSE_CREATE_REQUEST',
  WAREHOUSE_CREATE_SUCCESS: 'WAREHOUSE_CREATE_SUCCESS',
  WAREHOUSE_CREATE_FAILURE: 'WAREHOUSE_CREATE_FAILURE',

  WAREHOUSE_UPDATE_REQUEST: 'WAREHOUSE_UPDATE_REQUEST',
  WAREHOUSE_UPDATE_SUCCESS: 'WAREHOUSE_UPDATE_SUCCESS',
  WAREHOUSE_UPDATE_FAILURE: 'WAREHOUSE_UPDATE_FAILURE',

  WAREHOUSE_ARCHIVE_REQUEST: 'WAREHOUSE_ARCHIVE_REQUEST',
  WAREHOUSE_ARCHIVE_SUCCESS: 'WAREHOUSE_ARCHIVE_SUCCESS',
  WAREHOUSE_ARCHIVE_FAILURE: 'WAREHOUSE_ARCHIVE_FAILURE',

  WAREHOUSE_SETDEFAULT_REQUEST: 'WAREHOUSE_SETDEFAULT_REQUEST',
  WAREHOUSE_SETDEFAULT_SUCCESS: 'WAREHOUSE_SETDEFAULT_SUCCESS',
  WAREHOUSE_SETDEFAULT_FAILURE: 'WAREHOUSE_SETDEFAULT_FAILURE',

  WAREHOUSE_EXCEL_REQUEST: 'WAREHOUSE_EXCEL_REQUEST',
  WAREHOUSE_EXCEL_SUCCESS: 'WAREHOUSE_EXCEL_SUCCESS',
  WAREHOUSE_EXCEL_FAILURE: 'WAREHOUSE_EXCEL_FAILURE',
  //#endregion

  //#region OutgoingWaybill
  OUTGOINGWAYBILL_GETALL_REQUEST: 'OUTGOINGWAYBILL_GETALL_REQUEST',
  OUTGOINGWAYBILL_GETALL_SUCCESS: 'OUTGOINGWAYBILL_GETALL_SUCCESS',
  OUTGOINGWAYBILL_GETALL_FAILURE: 'OUTGOINGWAYBILL_GETALL_FAILURE',

  OUTGOINGWAYBILL_GETID_REQUEST: 'OUTGOINGWAYBILL_GETID_REQUEST',
  OUTGOINGWAYBILL_GETID_SUCCESS: 'OUTGOINGWAYBILL_GETID_SUCCESS',
  OUTGOINGWAYBILL_GETID_FAILURE: 'OUTGOINGWAYBILL_GETID_FAILURE',

  OUTGOINGWAYBILL_DELETE_REQUEST: 'OUTGOINGWAYBILL_DELETE_REQUEST',
  OUTGOINGWAYBILL_DELETE_SUCCESS: 'OUTGOINGWAYBILL_DELETE_SUCCESS',
  OUTGOINGWAYBILL_DELETE_FAILURE: 'OUTGOINGWAYBILL_DELETE_FAILURE',

  OUTGOINGWAYBILL_CREATE_REQUEST: 'OUTGOINGWAYBILL_CREATE_REQUEST',
  OUTGOINGWAYBILL_CREATE_SUCCESS: 'OUTGOINGWAYBILL_CREATE_SUCCESS',
  OUTGOINGWAYBILL_CREATE_FAILURE: 'OUTGOINGWAYBILL_CREATE_FAILURE',

  OUTGOINGWAYBILL_UPDATE_REQUEST: 'OUTGOINGWAYBILL_UPDATE_REQUEST',
  OUTGOINGWAYBILL_UPDATE_SUCCESS: 'OUTGOINGWAYBILL_UPDATE_SUCCESS',
  OUTGOINGWAYBILL_UPDATE_FAILURE: 'OUTGOINGWAYBILL_UPDATE_FAILURE',

  OUTGOINGWAYBILL_EXCEL_REQUEST: 'OUTGOINGWAYBILL_EXCEL_REQUEST',
  OUTGOINGWAYBILL_EXCEL_SUCCESS: 'OUTGOINGWAYBILL_EXCEL_SUCCESS',
  OUTGOINGWAYBILL_EXCEL_FAILURE: 'OUTGOINGWAYBILL_EXCEL_FAILURE',
  //#endregion

  //#region IncomingWaybill
  INCOMINGWAYBILL_GETALL_REQUEST: 'INCOMINGWAYBILL_GETALL_REQUEST',
  INCOMINGWAYBILL_GETALL_SUCCESS: 'INCOMINGWAYBILL_GETALL_SUCCESS',
  INCOMINGWAYBILL_GETALL_FAILURE: 'INCOMINGWAYBILL_GETALL_FAILURE',

  INCOMINGWAYBILL_GETID_REQUEST: 'INCOMINGWAYBILL_GETID_REQUEST',
  INCOMINGWAYBILL_GETID_SUCCESS: 'INCOMINGWAYBILL_GETID_SUCCESS',
  INCOMINGWAYBILL_GETID_FAILURE: 'INCOMINGWAYBILL_GETID_FAILURE',

  INCOMINGWAYBILL_DELETE_REQUEST: 'INCOMINGWAYBILL_DELETE_REQUEST',
  INCOMINGWAYBILL_DELETE_SUCCESS: 'INCOMINGWAYBILL_DELETE_SUCCESS',
  INCOMINGWAYBILL_DELETE_FAILURE: 'INCOMINGWAYBILL_DELETE_FAILURE',

  INCOMINGWAYBILL_CREATE_REQUEST: 'INCOMINGWAYBILL_CREATE_REQUEST',
  INCOMINGWAYBILL_CREATE_SUCCESS: 'INCOMINGWAYBILL_CREATE_SUCCESS',
  INCOMINGWAYBILL_CREATE_FAILURE: 'INCOMINGWAYBILL_CREATE_FAILURE',

  INCOMINGWAYBILL_UPDATE_REQUEST: 'INCOMINGWAYBILL_UPDATE_REQUEST',
  INCOMINGWAYBILL_UPDATE_SUCCESS: 'INCOMINGWAYBILL_UPDATE_SUCCESS',
  INCOMINGWAYBILL_UPDATE_FAILURE: 'INCOMINGWAYBILL_UPDATE_FAILURE',

  INCOMINGWAYBILL_EXCEL_REQUEST: 'INCOMINGWAYBILL_EXCEL_REQUEST',
  INCOMINGWAYBILL_EXCEL_SUCCESS: 'INCOMINGWAYBILL_EXCEL_SUCCESS',
  INCOMINGWAYBILL_EXCEL_FAILURE: 'INCOMINGWAYBILL_EXCEL_FAILURE',
  //#endregion

  //#region WarehouseTransfer
  WAREHOUSETRANSFER_GETALL_REQUEST: 'WAREHOUSETRANSFER_GETALL_REQUEST',
  WAREHOUSETRANSFER_GETALL_SUCCESS: 'WAREHOUSETRANSFER_GETALL_SUCCESS',
  WAREHOUSETRANSFER_GETALL_FAILURE: 'WAREHOUSETRANSFER_GETALL_FAILURE',

  WAREHOUSETRANSFER_GETID_REQUEST: 'WAREHOUSETRANSFER_GETID_REQUEST',
  WAREHOUSETRANSFER_GETID_SUCCESS: 'WAREHOUSETRANSFER_GETID_SUCCESS',
  WAREHOUSETRANSFER_GETID_FAILURE: 'WAREHOUSETRANSFER_GETID_FAILURE',

  WAREHOUSETRANSFER_DELETE_REQUEST: 'WAREHOUSETRANSFER_DELETE_REQUEST',
  WAREHOUSETRANSFER_DELETE_SUCCESS: 'WAREHOUSETRANSFER_DELETE_SUCCESS',
  WAREHOUSETRANSFER_DELETE_FAILURE: 'WAREHOUSETRANSFER_DELETE_FAILURE',

  WAREHOUSETRANSFER_CREATE_REQUEST: 'WAREHOUSETRANSFER_CREATE_REQUEST',
  WAREHOUSETRANSFER_CREATE_SUCCESS: 'WAREHOUSETRANSFER_CREATE_SUCCESS',
  WAREHOUSETRANSFER_CREATE_FAILURE: 'WAREHOUSETRANSFER_CREATE_FAILURE',

  WAREHOUSETRANSFER_EXCEL_REQUEST: 'WAREHOUSETRANSFER_EXCEL_REQUEST',
  WAREHOUSETRANSFER_EXCEL_SUCCESS: 'WAREHOUSETRANSFER_EXCEL_SUCCESS',
  WAREHOUSETRANSFER_EXCEL_FAILURE: 'WAREHOUSETRANSFER_EXCEL_FAILURE',
  //#endregion
};
