/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { KTCard, KTCardBody, KTCardHeader, Notes, DeleteModal, ArchiveModal, DefaultWarehouse, DataTables } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { productActions } from '../../../redux/actions';
import { SourceType, KTSVG } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showDefaultWarehouse, setShowDefaultWarehouse] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, isDefault, error } = useSelector(state => state.product);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(productActions.warehouseGetId(id));
  }, [])

  const handleDelete = () => dispatch(productActions.warehouseDelete({ id }));

  const handleArchive = () => dispatch(productActions.warehouseArchiving({ id }));

  const handleSetDefault = () => dispatch(productActions.warehouseSetDefault({ id }));

  useEffect(() => {
    if (isDeleted || isArchived || isDefault) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived, isDefault])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.WAREHOUSE' }),
      path: '/stock/warehouse',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.WAREHOUSE.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.projectName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.projectName}
                            </div>
                          </>}
                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen018.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.address} {detail.provinceName} - {detail.districtName}
                          </div>


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        {!detail?.isDefaultWareHouse && <button className="btn btn-sm btn-secondary align-self-center" onClick={() => setShowDefaultWarehouse(true)}>{intl.formatMessage({ id: 'WAREHOUSE.GET.MAKEDEFAULT' })}</button>}
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>                        
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>	
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">                       
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >                  
                  {detail?.products?.length > 0 && <Tab eventKey="products" title={intl.formatMessage({ id: 'WAREHOUSE.GET.PRODUCTS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.PRODUCTS.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.PRODUCTS.STOCKCOUNT' }),
                          prop: 'stockCount',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.PRODUCTS.BASEPURCHASEPRIC' }),
                          prop: 'basePurchasePrice',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.PRODUCTS.BASESALEPRICE' }),
                          prop: 'baseSalePrice',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.products}
                      exportTitle={intl.formatMessage({ id: 'WAREHOUSE.GET.PRODUCTS' })}
                    />
                  </Tab>}
                  {detail?.stocks?.length > 0 && <Tab eventKey="stocks" title={intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.PRODUCTNAME' }),
                          prop: 'productName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.STOCKPROCESSTYPENAME' }),
                          prop: 'stockProcessTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.STOCKTYPENAME' }),
                          prop: 'stockTypeName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.PROCESSDATE' }),
                          prop: 'processDate',
                          cell: (row) => intl.formatDate(new Date(row.processDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS.AMOUNT' }),
                          prop: 'amount',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.stocks}
                      exportTitle={intl.formatMessage({ id: 'WAREHOUSE.GET.STOCKS' })}
                    />
                  </Tab>}
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}         
        </div>

        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Warehouse} />
        </div>
      </div>
      
      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal 
        onClick={handleArchive} 
        onOpen={showArchive} 
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <DefaultWarehouse 
        onClick={handleSetDefault} 
        onOpen={showDefaultWarehouse} 
        onClose={() => setShowDefaultWarehouse(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
