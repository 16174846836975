/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';
import { KTSVG, SourceType, detailPageUrl, DetailPage } from '../../../helpers'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import * as Yup from 'yup'
import {useFormik} from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, KTCardHeader, Notes, Timeline, PaymentStatus, CheckoutDetail, DeleteModal, ArchiveModal, Select, PaymentPlan, Category, InputPrice } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { saleinvoiceActions, lookupActions, checkoutActions, gibinvoiceActions } from '../../../redux/actions';

import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [checkoutId, setCheckouId] = useState();
  const [isCollapse, setIsCollapse] = useState(true);
  const [activeTab, setActiveTab] = useState('cash');
  const [gibIsEnabled, setGibIsEnabled] = useState('2');
  const [pdfIsOpen, setPdfIsOpen] = useState(false);
  const [receiptIsOpen, setReceiptIsOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  
  const { detail, isDeleted, isArchived, error } = useSelector(state => state.saleinvoice);
  const { accountLookup, Bank } = useSelector(state => state.lookup);
  const { transaction, loading, receipt } = useSelector(state => state.checkout);
  const { paymentPlan, isDeleted: paymentDelete } = useSelector(state => state.paymentplan);
  const { isSent, pdfdata } = useSelector(state => state.gibinvoice);
  const { info } = useSelector(state => state.profile);

  const dispatch = useDispatch();
  
  const pageUrl = location.pathname.split('/')

  const [stepSchema, setStepSchema] = useState({
    PaymentDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    ToAccountId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
    Payment: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    SaleInvoiceId: id,
    PaymentDate: new Date(),
    ToAccountId: '',
    Payment: 0,
    Name: '',
    DefermentDate: new Date(),
    EditDate: new Date(),
    BankId: '',
    CheckNo: '',
    Description: '',
    TotalPrice: 0,
    IsOfficial: true
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      if (activeTab === 'cash') {
        dispatch(checkoutActions.checkoutSaleInvoiceReceiveMoney(values));
      } else {
        dispatch(checkoutActions.checkoutSaleInvoiceReceiveCheck(values));
      }
      
      formik.setFieldValue('TotalPrice', 0)
      formik.setFieldValue('Payment', 0)
      formik.setFieldValue('Name', '')
      formik.setFieldValue('Description', '')
    },
  })

  useEffect(() => {
    dispatch(saleinvoiceActions.saleinvoiceGetId(id));
    dispatch(lookupActions.bankCashAccountGetAll());
    dispatch(lookupActions.basicLookup('Bank'));
  }, [id, transaction, paymentPlan, paymentDelete, isSent])

  useEffect(() => {
    const gibIsEnabled = info?.claims?.find(item => item.type == 'GibIsEnabled');
    setGibIsEnabled(gibIsEnabled?.value);
  }, [info])

  useEffect(() => {
    if (pdfdata && pdfIsOpen) {
      const file = new Blob([pdfdata?.data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
      setPdfIsOpen(false)
    }
  }, [pdfdata])

  useEffect(() => {
    if (receipt && receipt?.fileUrl && receiptIsOpen) {
      window.open(receipt?.fileUrl, '_blank');
      setReceiptIsOpen(false)
    }
  }, [receipt])

  useEffect(() => {
    if (activeTab === 'cash') {
      setStepSchema({
        PaymentDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        ToAccountId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        Payment: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
      })
    } else if (activeTab === 'check') {
      setStepSchema({
        BankId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        CheckNo: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        DefermentDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        EditDate: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
        TotalPrice: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
      })
    }
  }, [activeTab])

  const handleDelete = () => dispatch(saleinvoiceActions.saleinvoiceDelete({id}));

  const handleArchive = () => dispatch(saleinvoiceActions.saleinvoiceArchiving({ id }));

  const handleClickSendInvoice = () => dispatch(gibinvoiceActions.gibinvoiceSend(id));

  const handleClickGetInvoicePdf = () => {
    dispatch(gibinvoiceActions.gibinvoceGetInvoicePdf(id));
    setPdfIsOpen(true)
  }
  
  const onClickRecipt = (values) => {
    dispatch(checkoutActions.checkoutGetCollectionReceipt(values));
    setReceiptIsOpen(true)
  }

  useEffect(() => {
    if (isSent?.isSuccess) {
      toast.success(intl.formatMessage({ id: 'INVOICE.MESSAGE' }), { autoClose: 5000, theme: "colored" })
    }
  }, [isSent])

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.SALESINVOICES' }),
      path: '/sales/sales-invoices',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.SALESINVOICES.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
           
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>                            
                          </div>

                          {detail?.invoiceNumber && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/finance/fin004.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.invoiceNumber}
                            </div>
                          </>}

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/ecommerce/ecm006.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.invoiceTypeName}
                          </div>

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen014.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {intl.formatDate(new Date(detail?.editDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                          </div>                        
                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.SaleInvoice} />
                        {detail?.gibInvoiceStatusId != 2 && <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>}
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            { detail?.gibInvoiceStatusId != 2 && <>
                              <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                              <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                              {gibIsEnabled == 1 && <Dropdown.Item onClick={() => handleClickSendInvoice()} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.INVOICE' })}</div></Dropdown.Item>}
                            </>}
                            {gibIsEnabled == 1 && detail?.gibInvoiceStatusId == 2 && <Dropdown.Item onClick={() => handleClickGetInvoicePdf()} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.PDF' })}</div></Dropdown.Item>}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATEDETAIL.COMPANYID' })}</div>                          
                          <div className="fw-bold fs-4"><Link to={detailPageUrl(DetailPage.CompanyCustomer, detail?.companyId)} className='text-dark text-hover-primary' target='_blank'>{detail?.companyName}</Link></div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalPrice, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.PAID' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.totalPrice - detail?.balance, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.BALANCE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail?.balance, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'EXPENSEINVOICE.CREATEDETAIL.DEFERMENTDATE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                        </div>                   

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'SALEINVOICE.CREATE.INVOICEKINDNAME' })}</div>
                          <div className="fw-bold fs-4">{detail?.invoiceKindName}</div>
                        </div>                   
                      </div>
                    </div>
                  </div>
                </div>
              </KTCardBody>
            </KTCard>            

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="saleInvoice" title={intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS' })}>
                    {detail?.saleInvoiceItemModels?.length > 0 && <div className='table-responsive'>
                      <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                        <thead>
                          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.PRODUCTID' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.WAREHOUSEID' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.AMOUNT' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.UNITPRICE' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.TAXID' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.DISCOUNT' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.WITHOLDING' })}</th>
                            <th className='text-end'>{intl.formatMessage({ id: 'SALEINVOICE.CREATE.SALEINVOICEITEMS.TOTALPRICE' })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail?.saleInvoiceItemModels.map((item, index) => <tr key={index}>
                            <td className='p-5'>
                              <div className="flex-grow-1">                                                                
                                <div className="text-dark fw-semibold fs-6"><Link to={detailPageUrl(DetailPage.Product, item.id )} className='text-dark text-hover-primary' target='_blank'>{item.productName}</Link></div>
                                <span className="text-muted d-block fw-semibold">{item.description}</span>
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.warehouseName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                                {item.amount} {item.productUnitName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                                {intl.formatNumber(item.unitPrice, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-center'>
                                {item.taxName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {intl.formatNumber(item.discount, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {intl.formatNumber(item.witholding, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-end'>
                                {intl.formatNumber(item.totalPrice, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                          </tr>)}
                        </tbody>

                        <tfoot>
                          <tr className="border-top border-top-dashed align-middle fs-6 fw-bold text-gray-700">
                            <th colSpan={5}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'SALEINVOICE.CREATE.SUBTOTALPRICE' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail.subTotalPrice, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={5}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALDISCOUNT' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail.totalDiscount, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={5}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'SALEINVOICE.CREATE.GROSSTOTALPRICE' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail.grossTotalPrice, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={5}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALTAX' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail.totalTax, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={5}></th>
                            <th className="border-bottom border-bottom-dashed p-5 fs-5" colSpan={2}>
                              {intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALWITHOLDING' })}
                            </th>
                            <th className="border-bottom border-bottom-dashed p-5 text-end">{intl.formatNumber(detail.totalWitholding, { style: 'currency', currency: 'try' })}</th>
                          </tr>

                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={5}></th>
                            <th className="fs-4 p-5" colSpan={2}>
                              {intl.formatMessage({ id: 'SALEINVOICE.CREATE.TOTALPRICE' })}
                            </th>
                            <th className="text-end fs-4 text-nowrap p-5">{intl.formatNumber(detail.totalPrice, { style: 'currency', currency: 'try' })}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>}
                  </Tab>

                  {detail?.checkoutModels?.length > 0 && <Tab eventKey="checkout" title={intl.formatMessage({ id: 'SALEINVOICE.CREATE.CHECKOUTS' })}>
                    <div className='table-responsive'>
                      <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                        <thead>
                          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.GET.CHECKOUTMODELS.TOACCOUNTNAME' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.GET.CHECKOUTMODELS.DESCRIPTION' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.GET.CHECKOUTMODELS.PAYMENTDATE' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.GET.CHECKOUTMODELS.DEFERMENTDATE' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.GET.CHECKOUTMODELS.CHECKOUTPROCESSTYPENAME' })}</th>
                            <th>{intl.formatMessage({ id: 'SALEINVOICE.GET.CHECKOUTMODELS.ISOFFICIALNAME' })}</th>
                            <th>{intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })}</th>
                            <th className='text-end'>{intl.formatMessage({ id: 'SALEINVOICE.GET.CHECKOUTMODELS.PAYMENT' })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail?.checkoutModels.map((item, index) => <tr key={index} role="button" onClick={() => setCheckouId(item.id)}>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.toAccountName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item?.name}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {intl.formatDate(new Date(item?.paymentDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item?.defermentDate ? intl.formatDate(new Date(item?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' }) : '-'}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.checkoutProcessTypeName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.isOfficialName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.lastModifiedBy} <br /> {intl.formatDate(new Date(item?.lastModifiedDate), { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6 text-end'>
                                {intl.formatNumber(item.payment, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                          </tr>)}
                        </tbody>

                        <tfoot>
                          <tr className="align-middle fw-bold text-gray-700">
                            <th colSpan={2}>&nbsp;</th>
                            <th className="fs-4 p-5">
                              {intl.formatMessage({ id: 'SALEINVOICE.GET.BALANCE' })}
                            </th>
                            <th className="text-end fs-4 text-nowrap p-5">{intl.formatNumber(detail?.balance, { style: 'currency', currency: 'try' })}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </Tab>}

                  <Tab eventKey="plans" title={intl.formatMessage({ id: 'SALEINVOICE.CREATE.PLANS' })}>
                    <PaymentPlan SaleInvoiceId={id} data={detail?.paymentPlans} ProjectId={detail?.projectId} />
                  </Tab>
                </Tabs>

              </KTCardBody>
            </KTCard>
             </> }
          
        </div>

        <div className='col-xl-3'>
          {detail && <PaymentStatus
            balance={detail?.balance}
            delayDay={detail?.delayDay}
            payment={detail?.totalPrice}
          />}

          {detail?.balance > 0 && <KTCard className='mb-5'>
            <KTCardHeader title={intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.ADD' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody 
              className='p-3'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <Tabs
                  defaultActiveKey={activeTab}
                  fill
                  onSelect={(active) => setActiveTab(active)}
                  className="nav-line-tabs"
                >
                  <Tab eventKey="cash" title={intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.CASH' })} className='p-5'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.PAYMENTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr 
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('PaymentDate')}
                            onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                          />
                        </div>
                        {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.PaymentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.TOACCOUNDID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select 
                          data={accountLookup}
                          required
                          {...formik.getFieldProps('ToAccountId')}
                          onChange={item => formik.setFieldValue('ToAccountId', Number(item?.value))}
                        />
                        {formik.touched.ToAccountId && formik.errors.ToAccountId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ToAccountId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.ISOFFICIAL.COLLECTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input 
                            className="form-check-input h-30px w-50px" 
                            type="checkbox" 
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <InputPrice 
                          {...formik.getFieldProps('Payment')} 
                          onChange={(value) => formik.setFieldValue('Payment', Number(value))} 
                        />
                        {formik.touched.Payment && formik.errors.Payment && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Payment}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.NAME' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Name')}
                        />
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="check" title={intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.CHECK' })} className='p-5'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.EDITDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr 
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('EditDate')}
                            onChange={([date]) => formik.setFieldValue('EditDate', date)}
                          />
                        </div>
                        {formik.touched.EditDate && formik.errors.EditDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.EditDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.DEFERMENTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr 
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('DefermentDate')}
                            onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
                          />
                        </div>
                        {formik.touched.DefermentDate && formik.errors.DefermentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.DefermentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.ISOFFICIAL.COLLECTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input h-30px w-50px"
                            type="checkbox"
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <InputPrice 
                          {...formik.getFieldProps('TotalPrice')} 
                          onChange={(value) => formik.setFieldValue('TotalPrice', Number(value))} 
                        />
                        {formik.touched.TotalPrice && formik.errors.TotalPrice && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.TotalPrice}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.DESCRIPTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Description')}
                        />
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.BANKID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select 
                          data={Bank}
                          required
                          {...formik.getFieldProps('BankId')}
                          onChange={item => formik.setFieldValue('BankId', Number(item?.value))}
                        />
                        {formik.touched.BankId && formik.errors.BankId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.BankId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'SALEINVOICE.GET.RECIEVE.CHECKNO' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('CheckNo')}
                        />
                        {formik.touched.CheckNo && formik.errors.CheckNo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.CheckNo}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>
                </Tabs>
              </form>
            </KTCardBody>
          </KTCard>}

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.SaleInvoice} />
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.SaleInvoice} collapse={false} />
        </div>
      </div>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal 
        onClick={handleArchive} 
        onOpen={showArchive} 
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <CheckoutDetail id={checkoutId} onClose={() => setCheckouId(null)} receiptClick={(exportTypeName) => onClickRecipt({checkoutId, exportTypeName})} />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Detail};
