/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, Select, InputPrice } from '../../../components';
import { KTSVG } from '../../../helpers';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, guaranteeletterActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { projectLookup, companyLookup, Bank, GuaranteeLetterType, GuaranteeLetterState } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.guaranteeletter);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.allCompanyLookup());
    dispatch(lookupActions.projectLookup());
    dispatch(lookupActions.basicLookup('Bank'));
    dispatch(lookupActions.basicLookup('GuaranteeLetterType'));
    dispatch(lookupActions.basicLookup('GuaranteeLetterState'));
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    CompanyId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    BankId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Branch: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    GuaranteeLetterTypeId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    GuaranteeLetterStateId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    GuaranteeLetterNo: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    ProjectId: null,
    CompanyId: null,
    BankId: null,
    Branch: '',
    Name: '',
    GuaranteeLetterTypeId: '',
    GuaranteeLetterStateId: '',
    GuaranteeLetterNo: '',
    EditDate: new Date(),
    DefermentDate: new Date(),
    CommissionDate: new Date(),
    DeliveryDate: new Date(),
    Beneficiary: '',
    OriginalAmount: 0,
    BankCommission: 0,
    Balance: 0,
    FinancialRate: 0,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(guaranteeletterActions.guaranteeletterCreate(values));   
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.GUARATEELETTER' }),
      path: '/cash/guaranteeletter',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.GUARATEELETTER.ADD' })}</PageTitle>
      <KTCard>      
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.PROJECTNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={projectLookup}
                  required
                  {...formik.getFieldProps('ProjectId')}
                  onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                />
                {formik.touched.ProjectId && formik.errors.ProjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.COMPANYNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={companyLookup} 
                  required
                  {...formik.getFieldProps('CompanyId')}
                  onChange={item => formik.setFieldValue('CompanyId', Number(item?.value))}
                />
                {formik.touched.CompanyId && formik.errors.CompanyId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.CompanyId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.BANKNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={Bank} 
                  required
                  {...formik.getFieldProps('BankId')}
                  onChange={item => formik.setFieldValue('BankId', Number(item?.value))}
                />
                {formik.touched.BankId && formik.errors.BankId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.BankId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.BRANCH' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  required
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Branch')}
                />
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.GUARATEELETTERTYPENAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={GuaranteeLetterType} 
                  required
                  {...formik.getFieldProps('GuaranteeLetterTypeId')}
                  onChange={item => formik.setFieldValue('GuaranteeLetterTypeId', Number(item?.value))}
                />
                {formik.touched.GuaranteeLetterTypeId && formik.errors.GuaranteeLetterTypeId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.GuaranteeLetterTypeId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.GUARATEELETTERSTATENAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={GuaranteeLetterState} 
                  required
                  {...formik.getFieldProps('GuaranteeLetterStateId')}
                  onChange={item => formik.setFieldValue('GuaranteeLetterStateId', Number(item?.value))}
                />
                {formik.touched.GuaranteeLetterStateId && formik.errors.GuaranteeLetterStateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.GuaranteeLetterStateId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.GUARATEELETTERNO' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('GuaranteeLetterNo')}
                />
                {formik.touched.GuaranteeLetterNo && formik.errors.GuaranteeLetterNo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.GuaranteeLetterNo}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.EDITDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('EditDate')}
                    onChange={([date]) => formik.setFieldValue('EditDate', date)}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.DEFERMENTDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('DefermentDate')}
                    onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.BENEFICIARY' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Beneficiary')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.ORIGINALAMOUNT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('OriginalAmount')} 
                  onChange={(value) => formik.setFieldValue('OriginalAmount', Number(value))} 
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.BANKCOMISSION' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('BankCommission')} 
                  onChange={(value) => formik.setFieldValue('BankCommission', Number(value))} 
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.COMMISSIONDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('CommissionDate')}
                    onChange={([date]) => formik.setFieldValue('CommissionDate', date)}
                  />
                </div>
              </div>
            </div>
            
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.DELIVERYDATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="position-relative d-flex align-items-center">
                  <KTSVG
                    path='/images/icons/duotune/general/gen014.svg'
                    className='svg-icon svg-icon-2 position-absolute mx-4'
                  />
                  <Flatpickr
                    className="form-control form-control-solid ps-12"
                    options={{ "locale": Turkish }}
                    {...formik.getFieldProps('DeliveryDate')}
                    onChange={([date]) => formik.setFieldValue('DeliveryDate', date)}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.BALANCE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('Balance')} 
                  onChange={(value) => formik.setFieldValue('Balance', Number(value))} 
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'GUARATEELETTER.CREATE.FINANCIALRATE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='number'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('FinancialRate')}
                  onFocus={event => event.target.select()}
                />
              </div>
            </div>

            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
