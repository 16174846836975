export const AuditTypeSelect = [
  {
    Id: 'Create',
    Name: 'REPORTING.AUDIT.TYPE.CREATE'
  },
  {
    Id: 'Update',
    Name: 'REPORTING.AUDIT.TYPE.UPDATE'
  },
  {
    Id: 'Delete',
    Name: 'REPORTING.AUDIT.TYPE.DELETE'
  },
]
