import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, Table } from '../../../components'
import { CustomCellDouble } from '../../../components/Table/columns/CustomCellDouble';
import { CustomCell } from '../../../components/Table/columns/CustomCell';
import { CustomHeader } from '../../../components/Table/columns/CustomHeader'
import { ListHeader } from '../../../components/Table/header/ListHeader';
import { ListLoading } from '../../../components/Table/ListLoading'
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { companyActions, categoryActions } from '../../../redux/actions';

import { CompanyType } from '../../../helpers';

function List() {
  const intl = useIntl()
  const { company, loading } = useSelector(state => state.company);
  const { category } = useSelector((state) => state.category)

  const dispatch = useDispatch();

  const [params, setParams] = useState()

  useEffect(() => {
    if (params) {
      dispatch(companyActions.companyGetAll(params, CompanyType.Subcontractor));
      dispatch(categoryActions.categoryGetAll({ SourceTypeId: 11 }))
    }  
  }, [params])

  const Columns = [
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'COMPANY.SEARCH.NAME' })} className='min-w-125px' />,
      accessor: 'name',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="title" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'COMPANY.SEARCH.CATEGORYNAME' })} className='min-w-125px' />,
      accessor: 'categoryName',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'COMPANY.SEARCH.TAXOFFICENO' })} className='min-w-125px' />,
      accessor: 'taxOffice',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="taxNumber" />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'COMPANY.SEARCH.IDENTITYNUMBER' })} className='min-w-125px' />,
      accessor: 'identityNumber',
      Cell: ({ ...props }) => <CustomCell item={props} />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'COMPANY.SEARCH.BALANCE' })} className='min-w-125px' />,
      accessor: 'balance',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double="balanceInformation" price />,
    },
    {
      Header: (props) => <CustomHeader tableProps={props} handleChangeSorting={(item) => setParams(prevState => ({ ...prevState, sort: item?.sort, order: item?.order }))}  title={intl.formatMessage({ id: 'LIST.LASTMODIFIEDINFO' })} className='min-w-125px' />,
      accessor: 'lastModifiedBy',
      Cell: ({ ...props }) => <CustomCellDouble item={props} double='lastModifiedDate' />,
    }
  ];

  const actionButtons = [
    {
      title: 'BUTTON.EXCEL',
      onClick: () => dispatch(companyActions.companyExcel(params, CompanyType.Subcontractor, intl.formatMessage({ id: 'BC.SUBCONTRACTOR' }))),
      icon: '/images/icons/duotune/arrows/arr044.svg',
      variant: 'light-primary'
    },
    {
      title: 'FILTER',
      icon: '/images/icons/duotune/general/gen031.svg',
      variant: 'light-primary',
      list: [
        {
          title: 'FILTER.HASBALANCE',
          data: [            
            {
              id: 'true',
              name: intl.formatMessage({ id: 'FILTER.HASBALANCE.TRUE' })
            },
            {
              id: 'false',
              name: intl.formatMessage({ id: 'FILTER.HASBALANCE.FALSE' })
            },
          ],
          name: 'hasBalance'
        },
        {
          title: 'COMPANY.SEARCH.CATEGORYNAME',
          data: category,
          name: 'categoryId'
        }, 
      ]
    }
  ]

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'BC.SUBCONTRACTOR' })}</PageTitle>
      <KTCard>
        <ListHeader
          search={(item) => setParams(prevState => ({ ...prevState, name: item }))}
          actionButtons={actionButtons}
          onSubmit={(values) => setParams({ ...values })}
          pages={'SUBCONTACTOR'}
        />
        <KTCardBody>
          {!company && <ListLoading />}
          {company && <Table
            items={company}
            cols={Columns}
            handlePagination={(pagination) => setParams(prevState => ({ ...prevState, ...pagination }))}
            paddless
          />}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { List };
