/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, KTCardHeader, Notes, DeleteModal, ArchiveModal, DataTables, Select, Timeline } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { purchaseActions } from '../../../redux/actions';
import { SourceType, PurchaseOrderStatus, KTSVG } from '../../../helpers';
import Dropdown from 'react-bootstrap/Dropdown';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import * as Yup from 'yup'
import {useFormik} from 'formik'

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, error, loading } = useSelector(state => state.purchase);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(purchaseActions.purchaseOrderGetId(id));
  }, [])

  useEffect(() => {
    if (detail) formik.setFieldValue('PurchaseOrderStatusId', detail.purchaseOrderStatusId)
  }, [detail])

  const handleDelete = () => dispatch(purchaseActions.purchaseOrderDelete({ id }));
  const handleArchive = () => dispatch(purchaseActions.purchaseOrderArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.ORDER' }),
      path: '/purchase/order',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const validSchema = Yup.object().shape({
    PurchaseOrderStatusId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    PurchaseOrderStatusId: '',
    Id: id,
  })
  
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(purchaseActions.purchaseOrderUpdateStatus(values));
      resetForm()
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.ORDER.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>

            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.projectName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.projectName}
                            </div>
                          </>}
                          {detail?.companyName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/finance/fin006.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.companyName}
                            </div>
                          </>}
                          {detail?.relatedStaffName && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/nav/Staff.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.relatedStaffName}
                            </div>
                          </>}


                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>                        
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>	
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ORDER.GET.DELAYDATE' })}</div>
                          <div className="fw-bold fs-4">{detail?.delayDay == 0 ? '-' : detail?.delayDay < 0 ? detail?.delayDay * -1 + intl.formatMessage({ id: 'PROJECT.DAYPASSED' }) : detail?.delayDay + intl.formatMessage({ id: 'PROJECT.DAYLEFT' })}</div>
                        </div>
                        {detail?.issueDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ORDER.GET.ISSUEDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.issueDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}
                        {detail?.shipmentDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ORDER.GET.SHIPMENTDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.shipmentDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}
                        {detail?.deliveryDate && <>
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ORDER.GET.DELIVERYDATE' })}</div>
                            <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.deliveryDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                          </div>
                        </>}
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'ORDER.GET.DELIVERYADDRESS' })}</div>
                          <div className="fw-bold fs-4">{detail.deliveryAddress}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {detail?.note && <>
                  <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                    {detail?.note}
                  </div>
                </>}      
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >                 
                  {detail?.purchaseOrderItems?.length > 0 && <Tab eventKey="subTasks" title={intl.formatMessage({ id: 'ORDER.GET.PURCHASEORDERITEMS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'ORDER.GET.PURCHASEORDERITEMS.PRODUCTNAME' }),
                          prop: 'productName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'ORDER.GET.PURCHASEORDERITEMS.AMOUNT' }),
                          prop: 'amount',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'ORDER.GET.PURCHASEORDERITEMS.PRODUCTUNITNAME' }),
                          prop: 'productUnitName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'ORDER.GET.PURCHASEORDERITEMS.DESCRIPTION' }),
                          prop: 'description',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.purchaseOrderItems}
                      exportTitle={intl.formatMessage({ id: 'ORDER.GET.PURCHASEORDERITEMS' })}
                    />
                  </Tab>}
                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}          
        </div>
        <div className='col-xl-3'>
          <KTCard className='mb-5'>
            <KTCardHeader title={intl.formatMessage({id: 'ORDER.CREATE.PURCHASEREQUESTSTATUSID' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

            <KTCardBody 
              className='ps-10 pe-10 pt-5 pb-0'
              collapseble
              isShow={isCollapse}
            >
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>
                  <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({id: 'REALESTATE.CREATE.ESTATESTATE' })}</label>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                    <Select 
                      data={PurchaseOrderStatus} 
                      required
                      {...formik.getFieldProps('PurchaseOrderStatusId')}
                      onChange={item => formik.setFieldValue('PurchaseOrderStatusId', Number(item?.value))}
                      textType={'intl'}
                    />
                    {formik.touched.PurchaseOrderStatusId && formik.errors.PurchaseOrderStatusId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.PurchaseOrderStatusId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                  >
                    <span className='indicator-label'>{intl.formatMessage({id: 'BUTTON.SUBMIT' })}</span>
                    {(formik.isSubmitting) && (
                      <span className='indicator-progress'>
                        {intl.formatMessage({id: 'PLEASE' })}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.PurchaseOrder} />
          
          <Timeline isRefresh={loading} SourceId={Number(id)} SourceTypeId={SourceType.PurchaseOrder} collapse={false} />
        </div>
      </div>
      
      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal 
        onClick={handleArchive} 
        onOpen={showArchive} 
        onClose={() => setShowArchive(false)}
        error={error}
      />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Detail };
