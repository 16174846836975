/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { KTCard, KTCardBody, Select } from '../../../components';

import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, projectActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { projectLookup, Province, District } = useSelector(state => state.lookup);
  const { isRedirect, detail, loading } = useSelector(state => state.project);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.basicLookup('Province'));
    dispatch(lookupActions.projectLookup());
    dispatch(projectActions.constructionsiteGetId(id));
  }, [])

  const onChangeDistrict = (value) => {
    formik.setFieldValue('ProvinceId', value);
    dispatch(lookupActions.districtLookup({ 'provinceId': value }));
  }
  
  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    TotalWork: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Id: '',
    Name: '',
    ProjectId: '',
    ProvinceId: null,
    DistrictId: null,
    Address: '',
    Description: '',
    TotalWork: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      dispatch(projectActions.constructionsiteUpdate(values));
    },
  })

  useEffect(() => {
    if (detail?.provinceId) dispatch(lookupActions.districtLookup({ 'provinceId': detail?.provinceId }));

    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);
      for (const values in formValues) {
        if (values === propVal) {
          formik.setFieldValue(propVal, detail[property])
        }
      }
    }
  }, [detail])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'MENU.PROJECTS.CONSTRUCTIONSITE' }),
      path: '/projects/construction-site',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/projects/construction-site/detail/${id}`,
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.CONSTRUCTIONSITE.EDIT' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>
          <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CONSTRUCTIONSITE.SEARCH.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PROJECT_NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={projectLookup}
                  required
                  {...formik.getFieldProps('ProjectId')}
                  onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                />
                {formik.touched.ProjectId && formik.errors.ProjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CONSTRUCTIONSITE.SEARCH.TOTALWORK' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='number'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('TotalWork')}
                  onFocus={event => event.target.select()}
                />
                {formik.touched.TotalWork && formik.errors.TotalWork && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.TotalWork}</span>
                    </div>
                  </div>
                )}
              </div>
            </div> 
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROJECT.PROVINCE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={Province} 
                  {...formik.getFieldProps('ProvinceId')}
                  onChange={item => onChangeDistrict(Number(item.value))}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROJECT.DISTRICT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={District} 
                  {...formik.getFieldProps('DistrictId')}
                  onChange={item => formik.setFieldValue('DistrictId', Number(item?.value))}
                />
                {formik.touched.DistrictId && formik.errors.DistrictId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.DistrictId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROJECT.ADDRESS' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <textarea
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={2}
                  {...formik.getFieldProps('Address')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PROJECT.DESCRIPTION' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <textarea
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={2}
                  {...formik.getFieldProps('Description')}
                />
              </div>
            </div>           
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>        <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>}

          
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
