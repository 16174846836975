export const notificationConstants = {
  //#region Notification
  NOTIFICATION_GETDASHBOARD_REQUEST: 'NOTIFICATION_GETDASHBOARD_REQUEST',
  NOTIFICATION_GETDASHBOARD_SUCCESS: 'NOTIFICATION_GETDASHBOARD_SUCCESS',
  NOTIFICATION_GETDASHBOARD_FAILURE: 'NOTIFICATION_GETDASHBOARD_FAILURE',

  NOTIFICATION_GETALL_REQUEST: 'NOTIFICATION_GETALL_REQUEST',
  NOTIFICATION_GETALL_SUCCESS: 'NOTIFICATION_GETALL_SUCCESS',
  NOTIFICATION_GETALL_FAILURE: 'NOTIFICATION_GETALL_FAILURE',
  
  NOTIFICATION_EXCEL_REQUEST: 'NOTIFICATION_EXCEL_REQUEST',
  NOTIFICATION_EXCEL_SUCCESS: 'NOTIFICATION_EXCEL_SUCCESS',
  NOTIFICATION_EXCEL_FAILURE: 'NOTIFICATION_EXCEL_FAILURE',

  NOTIFICATION_COMPLETE_REQUEST: 'NOTIFICATION_COMPLETE_REQUEST',
  NOTIFICATION_COMPLETE_SUCCESS: 'NOTIFICATION_COMPLETE_SUCCESS',
  NOTIFICATION_COMPLETE_FAILURE: 'NOTIFICATION_COMPLETE_FAILURE',
  //#endregion            
};
