/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTSVG, detailPageUrl, DetailPage, ExpenseDetailPage, SourceType } from '../../../helpers'
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, DeleteModal, Notes, Category } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { paymentplanActions } from '../../../redux/actions';

import Dropdown from 'react-bootstrap/Dropdown';

function Detail() {
  const [show, setShow] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()

  const { detail, isDeleted, error } = useSelector(state => state.paymentplan);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  useEffect(() => {
    dispatch(paymentplanActions.paymentPlanExpenseSaleInvoiceGetId(id));
  }, [id])

  const handleDelete = () => dispatch(paymentplanActions.paymentPlanDelete({ id }));

  useEffect(() => {
    if (isDeleted) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.CASH.SALE' }),
      path: '/cash/sale',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.CASH.SALE.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail?.name}</div>

                          <div className='ms-5'>

                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">                            
                            <Link to={detailPageUrl(DetailPage.Project, detail?.projectId)} className='text-gray-400 text-hover-primary' target='_blank'>
                              <KTSVG
                                path='/images/icons/nav/Projects.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail?.projectName}
                            </Link>
                          </div>

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.PaymentPlan} />
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.DEFERMENTDATE' })}</div>
                          {detail?.defermentDate && <div className="fw-bold fs-4">{intl.formatDate(new Date(detail?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' })}</div>}
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.TOTALPRICE' })}</div>
                          <div className="fw-bold fs-4">{intl.formatNumber(detail.totalPrice, { style: 'currency', currency: 'try' })}</div>
                        </div>

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.PAYMENTPLANTYPENAME' })}</div>
                          <div className="fw-bold fs-4">{detail?.paymentPlanTypeName}</div>
                        </div>

                        {detail?.expenseInvoiceName && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.EXPENSE' })}</div>
                          <Link to={detailPageUrl(ExpenseDetailPage[detail.expenseTypeId], detail.expenseInvoiceId)} className='text-dark text-hover-primary' target='_blank'>
                            <div className="fw-bold fs-4">{detail?.expenseInvoiceName}</div>
                          </Link>
                        </div>}

                        {detail?.saleInvoiceName && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.SALE' })}</div>
                          <Link to={detailPageUrl(DetailPage.SaleInvoice, detail.saleInvoiceId)} className='text-dark text-hover-primary' target='_blank'>
                            <div className="fw-bold fs-4">{detail?.saleInvoiceName}</div>
                          </Link>
                        </div>}

                        {detail?.checkName && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.CHECKS' })}</div>
                          <Link to={detailPageUrl(DetailPage.Check, detail.checkId)} className='text-dark text-hover-primary' target='_blank'>
                            <div className="fw-bold fs-4">{detail?.checkName}</div>
                          </Link>
                        </div>}

                        {detail?.salaryName && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.SALARY' })}</div>
                          <Link to={detailPageUrl(DetailPage.Salary, detail.salaryId)} className='text-dark text-hover-primary' target='_blank'>
                            <div className="fw-bold fs-4">{detail?.salaryName}</div>
                          </Link>
                        </div>}

                        {detail?.companyId > 0 && <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'PAYMENTPLAN.GET.COMPANYNAME' })}</div>
                          <Link to={detailPageUrl(DetailPage.CompanySupplier, detail.companyId)} className='text-dark text-hover-primary' target='_blank'>
                            <div className="fw-bold fs-4">{detail?.companyName}</div>
                          </Link>
                        </div>}

                      </div>
                    </div>
                  </div>
                </div>

                {detail?.description && <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                  {detail?.description}
                </div>}  
              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>
          <Notes SourceId={Number(id)} SourceTypeId={SourceType.PaymentPlan} />
        </div>
      </div>
      
      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}
export { Detail };
