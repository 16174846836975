import { workplanConstants } from '../constants';
import {
  //#region Task
  SearchTaskClient,
  GetTaskClient,
  DeleteTaskClient,
  UpdateTaskClient,
  CreateTaskClient,
  ArchivingTaskClient,
  UpdateTaskCompletionClient,
  //#endregion

  //#region ActivityReport
  SearchActivityReportClient,
  GetActivityReportClient,
  CreateActivityReportClient,
  UpdateActivityReportClient,
  GetAllTimeKeepingTemplateClient,
  CheckActivityReportClient,
  GetProjectInfoClient,
  GetActivityReportExcelClient,
  ReleaseActivityReportClient
  //#endregion
} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//const SERVICE_URL = "https://localhost:7016";

//#region Task
const archivingTaskClient = new ArchivingTaskClient(SERVICE_URL);
const searchTaskClient = new SearchTaskClient(SERVICE_URL);
const getTaskClient = new GetTaskClient(SERVICE_URL);
const createTaskClient = new CreateTaskClient(SERVICE_URL);
const deleteTaskClient = new DeleteTaskClient(SERVICE_URL);
const updateTaskClient = new UpdateTaskClient(SERVICE_URL);
const updateTaskCompletionClient = new UpdateTaskCompletionClient(SERVICE_URL);
//#endregion

//#region ActivityReport
const searchActivityReportClient = new SearchActivityReportClient(SERVICE_URL);
const getActivityReportClient = new GetActivityReportClient(SERVICE_URL);
const createActivityReportClient = new CreateActivityReportClient(SERVICE_URL);
const updateActivityReportClient = new UpdateActivityReportClient(SERVICE_URL);
const getAllTimeKeepingTemplateClient = new GetAllTimeKeepingTemplateClient(SERVICE_URL);
const checkActivityReportClient = new CheckActivityReportClient(SERVICE_URL);
const getProjectInfoClient = new GetProjectInfoClient(SERVICE_URL);
const getActivityReportExcelClient = new GetActivityReportExcelClient(SERVICE_URL);
const releaseActivityReportClient = new ReleaseActivityReportClient(SERVICE_URL);
//#endregion

export const workplanActions = {
  //#region Task
  taskGetAll,
  taskGetId,
  taskDelete,
  taskCreate,
  taskUpdate,
  taskArchiving,
  taskExcel,
  taskUpdateCompletion,
  //#endregion

  //#region ActivityReport
  activityReportGetAll,
  activityReportGetId,
  activityReportCreate,
  activityReportUpdate,
  activityReportGetAllTimeKeeping,
  activityReportCheckReport,
  activityReportExcel,
  activityReportGetProjectInformation,
  activityReportExcelGetId,
  activityReportRelease
  //#endregion
};

//#region Task
function taskGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchTaskClient.search(params.projectId, params.constructionSiteId, params.blockId, params.plotId, params.taskStateId, params.staffId, params.onlyRootTask, params.name, params.startDate, params.endDate, params.items_per_page, params.page, params.order, params.sort)
      .then(
        task => dispatch(success(task)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: workplanConstants.TASK_GETALL_REQUEST } }
  function success(task) { return { type: workplanConstants.TASK_GETALL_SUCCESS, task } }
  function failure(error) { return { type: workplanConstants.TASK_GETALL_FAILURE, error } }
}

function taskGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getTaskClient.get(id)
      .then(
        task => dispatch(success(task)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: workplanConstants.TASK_GETID_REQUEST, id } }
  function success(task) { return { type: workplanConstants.TASK_GETID_SUCCESS, task } }
  function failure(error) { return { type: workplanConstants.TASK_GETID_FAILURE, error } }
}

function taskDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deleteTaskClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.TASK_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: workplanConstants.TASK_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: workplanConstants.TASK_DELETE_FAILURE, error } }
}

function taskArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingTaskClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.TASK_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: workplanConstants.TASK_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: workplanConstants.TASK_ARCHIVE_FAILURE, error } }
}

function taskCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createTaskClient.create(params)
      .then(
        task => dispatch(success(task)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.TASK_CREATE_REQUEST, params } }
  function success(task) { return { type: workplanConstants.TASK_CREATE_SUCCESS, task } }
  function failure(error) { return { type: workplanConstants.TASK_CREATE_FAILURE, error } }
}

function taskUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateTaskClient.update(params)
      .then(
        task => dispatch(success(task, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: workplanConstants.TASK_UPDATE_REQUEST, params } }
  function success(task, params) { return { type: workplanConstants.TASK_UPDATE_SUCCESS, task, params } }
  function failure(error, params) { return { type: workplanConstants.TASK_UPDATE_FAILURE, error, params } }
}

function taskExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchTaskClient.excel(params.projectId, params.constructionSiteId, params.blockId, params.plotId, params.taskStateId, params.staffId, params.name, params.startDate, params.endDate, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: workplanConstants.TASK_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: workplanConstants.TASK_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: workplanConstants.TASK_EXCEL_FAILURE, error } }
}

function taskUpdateCompletion(params) {
  return dispatch => {
    dispatch(request(params));

    updateTaskCompletionClient.update(params)
      .then(
        task => dispatch(success(task, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: workplanConstants.TASK_UPDATE_COMPLETION_REQUEST, params } }
  function success(task, params) { return { type: workplanConstants.TASK_UPDATE_COMPLETION_SUCCESS, task, params } }
  function failure(error, params) { return { type: workplanConstants.TASK_UPDATE_COMPLETION_FAILURE, error, params } }
}
//#endregion

//#region ActivityReport
function activityReportGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchActivityReportClient.search(params.name, params.activityReportType, params.projectId, params.reportDate, params.items_per_page, params.page, params.order, params.sort)
      .then(
        activityReport => dispatch(success(activityReport)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: workplanConstants.ACTIVITYREPORT_GETALL_REQUEST } }
  function success(activityReport) { return { type: workplanConstants.ACTIVITYREPORT_GETALL_SUCCESS, activityReport } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_GETALL_FAILURE, error } }
}

function activityReportGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getActivityReportClient.get(id)
      .then(
        activityReport => dispatch(success(activityReport)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: workplanConstants.ACTIVITYREPORT_GETID_REQUEST, id } }
  function success(activityReport) { return { type: workplanConstants.ACTIVITYREPORT_GETID_SUCCESS, activityReport } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_GETID_FAILURE, error } }
}

function activityReportCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createActivityReportClient.create(params)
      .then(
        activityReport => dispatch(success(activityReport)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.ACTIVITYREPORT_CREATE_REQUEST, params } }
  function success(activityReport) { return { type: workplanConstants.ACTIVITYREPORT_CREATE_SUCCESS, activityReport } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_CREATE_FAILURE, error } }
}

function activityReportGetAllTimeKeeping(params) {
  return dispatch => {
    dispatch(request(params));

    getAllTimeKeepingTemplateClient.getAll(params)
      .then(
        timeKeepingTemplate => dispatch(success(timeKeepingTemplate)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_REQUEST, params } }
  function success(timeKeepingTemplate) { return { type: workplanConstants.ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_SUCCESS, timeKeepingTemplate } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_TIME_KEEPING_TEMPLATE_FAILURE, error } }
}

function activityReportCheckReport(params) {
  return dispatch => {
    dispatch(request(params));

    checkActivityReportClient.get(params)
      .then(
        checkreport => dispatch(success(checkreport)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.ACTIVITYREPORT_CHECK_REPORT_REQUEST, params } }
  function success(checkreport) { return { type: workplanConstants.ACTIVITYREPORT_CHECK_REPORT_SUCCESS, checkreport } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_CHECK_REPORT_FAILURE, error } }
}

function activityReportUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updateActivityReportClient.update(params)
      .then(
        activityReport => dispatch(success(activityReport, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: workplanConstants.ACTIVITYREPORT_UPDATE_REQUEST, params } }
  function success(activityReport, params) { return { type: workplanConstants.ACTIVITYREPORT_UPDATE_SUCCESS, activityReport, params } }
  function failure(error, params) { return { type: workplanConstants.ACTIVITYREPORT_UPDATE_FAILURE, error, params } }
}

function activityReportExcel(params, fileName) {
  return dispatch => {
    dispatch(request());
    
    searchActivityReportClient.excel(params.name, params.activityReportType, params.projectId, params.reportDate, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: workplanConstants.ACTIVITYREPORT_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: workplanConstants.ACTIVITYREPORT_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_EXCEL_FAILURE, error } }
}

function activityReportGetProjectInformation(params) {
  return dispatch => {
    dispatch(request(params));

    getProjectInfoClient.getAll(params)
      .then(
        checkinfo => dispatch(success(checkinfo)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.ACTIVITYREPORT_CHECK_INFO_REQUEST, params } }
  function success(checkinfo) { return { type: workplanConstants.ACTIVITYREPORT_CHECK_INFO_SUCCESS, checkinfo } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_CHECK_INFO_FAILURE, error } }
}

function activityReportExcelGetId(id, fileName) {
  return dispatch => {
    dispatch(request(id));

    getActivityReportExcelClient.get(id)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: workplanConstants.ACTIVITYREPORT_EXCELGETID_REQUEST, id } }
  function success(excel, fileName) { return { type: workplanConstants.ACTIVITYREPORT_EXCELGETID_SUCCESS, excel, fileName } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_EXCELGETID_FAILURE, error } }
}

function activityReportRelease(params) {
  return dispatch => {
    dispatch(request(params));

    releaseActivityReportClient.update(params)
      .then(
        releaseReport => dispatch(success(releaseReport)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: workplanConstants.ACTIVITYREPORT_RELEASEREPORT_REQUEST, params } }
  function success(releaseReport) { return { type: workplanConstants.ACTIVITYREPORT_RELEASEREPORT_SUCCESS, releaseReport } }
  function failure(error) { return { type: workplanConstants.ACTIVITYREPORT_RELEASEREPORT_FAILURE, error } }
}
//#endregion
