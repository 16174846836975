/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { TaxType } from '../../../helpers';
import { KTCard, KTCardBody, Select, InputPrice } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, productActions, categoryActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { ProductUnit, Tax } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.product);
  const { category } = useSelector((state) => state.category)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.basicLookup('Tax'));
    dispatch(lookupActions.basicLookup('ProductUnit'));
    dispatch(categoryActions.categoryGetAll({ SourceTypeId: 13 }))
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProductUnitId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    IsFollowStock: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    TaxId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    IsTotalStockAlarm: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Name: '',
    Code: '',
    ProductUnitId: null,
    IsFollowStock: false,
    BasePurchasePrice: 0,
    BaseSalePrice: 0,
    TaxId: null,
    PurchasePriceWithTax: 0,
    SalePriceWithTax: 0,
    IsTotalStockAlarm: false,
    TotalStockAlarmCount: 0,
    CategoryId: null,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(productActions.productCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.PRODUCT_SERVICES' }),
      path: '/stock/product-services',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  const onChangeItem = (value) => {
    const tax = TaxType.find(item => item.id == value)
    const purchasePriceWithTax = formik.values.BasePurchasePrice + ((formik.values.BasePurchasePrice * (tax?.value || 0)) / 100);
    const salePriceWithTax = formik.values.BaseSalePrice + ((formik.values.BaseSalePrice * (tax?.value || 0)) / 100);

    formik.setFieldValue(`PurchasePriceWithTax`, purchasePriceWithTax);
    formik.setFieldValue(`SalePriceWithTax`, salePriceWithTax);
    formik.setFieldValue('TaxId', Number(value))
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.PRODUCT_SERVICES.ADD' })}</PageTitle>
      <KTCard>       
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PRODUCT.CREATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.CATEGORY' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={category}
                  {...formik.getFieldProps('CategoryId')}
                  onChange={item => formik.setFieldValue('CategoryId', Number(item?.value))}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.CODE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Code')}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PRODUCT.CREATE.PRODUCTUNIT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={ProductUnit} 
                  {...formik.getFieldProps('ProductUnitId')}
                  onChange={item => formik.setFieldValue('ProductUnitId', Number(item?.value))}
                />
                {formik.touched.ProductUnitId && formik.errors.ProductUnitId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProductUnitId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.BASEPURCHASEPRICE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('BasePurchasePrice')} 
                  onChange={(value) => formik.setFieldValue('BasePurchasePrice', Number(value))} 
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.BASESALEPRICE' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('BaseSalePrice')} 
                  onChange={(value) => formik.setFieldValue('BaseSalePrice', Number(value))} 
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PRODUCT.CREATE.TAX' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select 
                  data={Tax} 
                  {...formik.getFieldProps('TaxId')}
                  onChange={item => onChangeItem(Number(item.value))}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.PURCHASEPRICEWITHTAX' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('PurchasePriceWithTax')} 
                  onChange={(value) => formik.setFieldValue('PurchasePriceWithTax', Number(value))} 
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.SALEPRICEWITHTAX' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <InputPrice 
                  {...formik.getFieldProps('SalePriceWithTax')} 
                  onChange={(value) => formik.setFieldValue('SalePriceWithTax', Number(value))} 
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.ISFOLLOWSTOCK' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input 
                    className="form-check-input h-30px w-50px" 
                    type="checkbox" 
                    {...formik.getFieldProps('IsFollowStock')}
                  />
                </div>
              </div>
            </div>           
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.ISTOTALSTOCKALARM' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input 
                    className="form-check-input h-30px w-50px" 
                    type="checkbox" 
                    {...formik.getFieldProps('IsTotalStockAlarm')}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PRODUCT.CREATE.TOTALSTOCKALARMCOUNT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='number'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('TotalStockAlarmCount')}
                  onFocus={event => event.target.select()}
                />
              </div>
            </div>
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
