/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../../core';

import { KTSVG, detailPageUrl, DetailPage, ExpenseDetailPage } from '../../../helpers'
import { KTCard, KTCardBody, KTCardHeader, ChartColumn } from '../../../components';

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { reportingActions } from '../../../redux/actions';

function Report() {
  const { loading, cashflow } = useSelector(state => state.reporting);

  const intl = useIntl()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportingActions.cashFlowReportGetAll({}));
  }, [])

  const Widget = ({
    className,
    color,
    svgIcon,
    iconColor,
    title,
    titleColor,
    description,
    descriptionColor,
  }) => {
    return (
      <div className={`card bg-${color} ${className}`}>
        <div className='card-body'>
          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-5x ms-n1`} svgClassName={'mh-100px'} />

          <div className={`text-${titleColor} fw-bold fs-1 mb-2 mt-5`}>{title}</div>

          <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.REPORTING.CASHFLOW' })}</PageTitle>

      <div className='row gy-5 g-xl-10'>
        <div className='col-xl-3'>
          <Widget
            className='card-xl-stretch mb-xl-8'
            svgIcon='/images/icons/duotune/finance/fin008.svg'
            color='primary'
            iconColor='white'
            title={intl.formatNumber(cashflow?.bankAccounts?.totalDefaultCurrencyAmount, { style: 'currency', currency: 'try' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALDEFAULTCURRENCYAMOUNT' })}
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <Widget
            className='card-xl-stretch mb-xl-8'
            svgIcon='/images/icons/duotune/finance/fin010.svg'
            color='info'
            iconColor='white'
            title={intl.formatNumber(cashflow?.bankAccounts?.totalOtherCurrenciesAmount, { style: 'currency', currency: 'try' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALOTHERCURRENCYAMOUNT' })}
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <Widget
            className='card-xl-stretch mb-xl-8'
            svgIcon='/images/icons/duotune/finance/fin001.svg'
            color='success'
            iconColor='white'
            title={intl.formatNumber(cashflow?.bankAccounts?.totalAllCurrenciesAmount, { style: 'currency', currency: 'try' })}
            description={intl.formatMessage({ id: 'DASHBOARD.TOTALALLCURRENCYAMOUNT' })}
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <div className="card card-xl-stretch mb-xl-10 bg-warning">
            <div className="card-body d-flex flex-column">
              <div className="d-flex flex-column mb-7">
                <div className="text-white fw-bolder fs-3">{intl.formatMessage({ id: 'REPORTING.CASHFLOW.BANKACCOUNTS.TITLE' })}</div>
              </div>

              <div className="row g-0">
                { cashflow?.bankAccounts?.currencyInfos.map((item, index) => {
                    return (
                      <div className="col-6" key={index}>
                        <div className="d-flex align-items-center mb-9 me-2">
                          <div>
                            <div className="fs-5 text-white fw-bolder lh-1">{intl.formatNumber(item?.currencyRate, { style: 'currency', currency: item?.currencyCode })}</div>
                            <div className="fs-7 text-white">{item?.name}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-5 g-xl-10'>
        <div className='col-xl-8 mb-10'>
          <ChartColumn
            chartHeight='380px'
            categories={cashflow?.generalCashFlows?.paymentPlansYearlyExpenses?.map(item => `${item?.monthName}`)}
            data={[
              {
                name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANEXPENSES' }),
                data: cashflow?.generalCashFlows?.paymentPlansYearlyExpenses?.map(item => item?.totalPrice),
              },
              {
                name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSALES' }),
                data: cashflow?.generalCashFlows?.paymentPlansYearlySales?.map(item => item?.totalPrice),
              },
              {
                name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSCASHFLOW' }),
                data: cashflow?.generalCashFlows?.paymentPlansYearlyCashFlows?.map(item => item?.cashFlow),
              }
            ]}
            isMultipleColumn={true}
            title={intl.formatMessage({ id: 'REPORTING.CASHFLOW.YEARLY.TITLE' })}
            tooltip={{
              y: {
                formatter: (val) => {
                  return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                }
              }
            }}
          />
        </div>

        <div className='col-xl-4'>
          <div className='row gy-5 g-xl-10 mb-10'>
            <div className='col-xl-12'>
              <KTCard>
                <KTCardHeader title={intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.CURRENTBANKCASHACCOUNTS' })} />
                <KTCardBody>
                  <div className='card-scroll mh-350px'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.ACCOUNTNAME' })}</th>
                            <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.TOTALPRICE' })}</th>
                            <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.CASHFLOW.DEFAULTTOTALPRICE' })}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {
                            cashflow?.bankAccounts?.currenctBankCashAccounts?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        {item.name}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className='text-dark fw-bold d-block fs-6'>
                                      {intl.formatNumber(item?.totalAmount, { style: 'currency', currency: item?.currencyCode })}
                                      {item?.currentRate != 0 && item?.currencyCode != 'TRY' && <>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                          {intl.formatNumber(item?.currentRate, { style: 'currency', currency: 'try' })}
                                        </span>
                                      </>}                                      
                                    </span>
                                  </td>
                                  <td>
                                    <span className='text-dark fw-bold d-block fs-6'>
                                      {intl.formatNumber(item?.defaultCurrencyAmount, { style: 'currency', currency: 'try' })}
                                    </span>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </KTCardBody>
              </KTCard>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-5 g-xl-10'>
        <div className='col-xl-12 mb-10'>
          <ChartColumn
            chartHeight='380px'
            categories={cashflow?.currentCashFlows?.paymentPlansMonthlyExpenses?.map(item => `${item?.day} ${item?.monthName}`)}
            data={[
              {
                name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANEXPENSES' }),
                data: cashflow?.currentCashFlows?.paymentPlansMonthlyExpenses?.map(item => item?.totalPrice),
              },
              {
                name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSALES' }),
                data: cashflow?.currentCashFlows?.paymentPlansMonthlySales?.map(item => item?.totalPrice),
              },
              {
                name: intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSCASHFLOW' }),
                data: cashflow?.currentCashFlows?.paymentPlansMonthlyCashFlows?.map(item => item?.cashFlow),
              }
            ]}
            isMultipleColumn={true}
            title={intl.formatMessage({ id: 'REPORTING.CASHFLOW.DETAIL.TITLE' })}
            tooltip={{
              y: {
                formatter: (val) => {
                  return intl.formatNumber(val, { style: 'currency', currency: 'try' })
                }
              }
            }}
          />
        </div>
      </div>

      <div className='row gy-5 g-xl-10 mb-10'>
        <div className='col-xl-6'>
          <KTCard>
            <KTCardHeader title={intl.formatMessage({ id: 'REPORTING.CASHFLOW.PAYMENTPLANSEXPENSES.TITLE' })} />
            <KTCardBody>
              <div className='card-scroll mh-400px'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>                          
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.NAME' })}</th>
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.PAYMENTPLANTYPENAME' })} - {intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.COMPANYNAME' })}</th>
                        <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.DEFERMENTDATE' })}</th>
                        <th className='min-w-120px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.TOTALPRICE' })}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        cashflow?.paymentPlansExpenses?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {item?.expenseInvoiceId && <>
                                      <Link to={detailPageUrl(ExpenseDetailPage[item.expenseTypeId], item.expenseInvoiceId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                    </>}
                                    {item?.salaryId && <>
                                      <Link to={detailPageUrl(DetailPage.Salary, item.salaryId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                    </>}
                                    {item?.checkId && <>
                                      <Link to={detailPageUrl(DetailPage.Check, item.checkId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                    </>}  
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {item.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {item?.paymentPlanTypeName}
                                  {item?.companyName && <>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {item?.companyName}
                                    </span>
                                  </>}                                                                          
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatDate(new Date(item?.defermenDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.totalPrice, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </KTCardBody>
          </KTCard>
        </div>

        <div className='col-xl-6'>
          <KTCard>
            <KTCardHeader title={intl.formatMessage({ id: 'REPORTING.CASHFLOW.PAYMENTPLANSSALES.TITLE' })} />
            <KTCardBody>
              <div className='card-scroll mh-400px'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.NAME' })}</th>
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSEXPENSES.PAYMENTPLANTYPENAME' })} - {intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.COMPANYNAME' })}</th>
                        <th className='min-w-140px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.DEFERMENTDATE' })}</th>
                        <th className='min-w-120px'>{intl.formatMessage({ id: 'DASHBOARD.PAYMENTPLANSSALES.TOTALPRICE' })}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        cashflow?.paymentPlansSales?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {item?.saleInvoiceId && <>
                                      <Link to={detailPageUrl(DetailPage.SaleInvoice, item.saleInvoiceId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                    </>}                                      
                                    {item?.checkId && <>
                                      <Link to={detailPageUrl(DetailPage.Check, item.checkId)} className='text-dark fw-bold fs-6 text-hover-primary' target='_blank'>{item.description}</Link>
                                    </>}                                      
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {item.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {item?.paymentPlanTypeName}
                                  {item?.companyName && <>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {item?.companyName}
                                    </span>
                                  </>}    
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatDate(new Date(item?.defermenDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.totalPrice, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </KTCardBody>
          </KTCard>
        </div>

        <div className='col-xl-6'>
          <KTCard>
            <KTCardHeader title={intl.formatMessage({ id: 'REPORTING.CASHFLOW.YEARLY' })} />
            <KTCardBody>
              <div className='card-scroll mh-400px'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.NAME' })}</th>
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.CASHFLOW' })}</th>
                        <th className='min-w-140px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.EXPENSE' })}</th>
                        <th className='min-w-120px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.SALE' })}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        cashflow?.generalCashFlowList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {item.name}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.yearlyCashFlowListItem, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.yearlyExpenseListItem, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.yearlySaleListItem, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </KTCardBody>
          </KTCard>
        </div>

        <div className='col-xl-6'>
          <KTCard>
            <KTCardHeader title={intl.formatMessage({ id: 'REPORTING.CASHFLOW.MONTHLY' })} />
            <KTCardBody>
              <div className='card-scroll mh-400px'>
                <div className='table-responsive'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.NAME' })}</th>
                        <th className='min-w-150px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.CASHFLOW' })}</th>
                        <th className='min-w-140px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.EXPENSE' })}</th>
                        <th className='min-w-120px'>{intl.formatMessage({ id: 'REPORTING.CASHFLOW.SALE' })}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        cashflow?.currentCashFlowList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {item.name}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.monthlyCashFlowListItem, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.monthlyExpenseListItem, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                              <td>
                                <span className='text-dark fw-bold d-block fs-6'>
                                  {intl.formatNumber(item?.monthlySaleListItem, { style: 'currency', currency: 'try' })}
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </KTCardBody>
          </KTCard>
        </div>
      </div> 

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Report};
