/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../../core';

import * as Yup from 'yup'
import {useFormik} from 'formik'
import "flatpickr/dist/flatpickr.css";

import { KTCard, KTCardBody, Select, ChartDonut, ChartColumn, ChartLine } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, reportingActions } from '../../../redux/actions';

function Report() {
  const { projectLookup } = useSelector(state => state.lookup);
  const { loading, projectCompleteRateByProject, constructionSiteCompleteRateByProject, blockCompleteRateWithConstructionSiteByProject, rootTaskCompleteRateByProject, dailyTaskCompleteRateByProject } = useSelector(state => state.reporting);

  const intl = useIntl()
  const dispatch = useDispatch();

  const [dailyNewData, setDailyNewData] = useState([])
  const [rootNewData, setRootNewData] = useState([])

  const validSchema = Yup.object().shape({
    ProjectId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    ProjectId: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      dispatch(reportingActions.workPlanProjectCompleteRateByProjectGetAll(values));
      dispatch(reportingActions.workPlanConstructionSiteCompleteRateByProjectGetAll(values));
      dispatch(reportingActions.workPlanBlockCompleteRateByConstructionSiteGetAll(values));
      dispatch(reportingActions.workPlanRootTaskCompleteRateByProjectGetAll(values));
      dispatch(reportingActions.workPlanDailyTaskCompleteRateByProjectGetAll(values));
    },
  })

  useEffect(() => {    
    dispatch(lookupActions.projectLookup());
  }, [])

  useEffect(() => {
    if (dailyTaskCompleteRateByProject) {
      const newData = []

      for (const key in dailyTaskCompleteRateByProject[0]) {
        if (key !== "title") {
          const data = dailyTaskCompleteRateByProject.map(item => item[key])
          newData.push({
            data,
            name: key.toUpperCase()
          })
        }
      }
  
      setDailyNewData(newData)
    }
  }, [dailyTaskCompleteRateByProject])

  useEffect(() => {
    if (rootTaskCompleteRateByProject) {
      const newData = []

      for (const key in rootTaskCompleteRateByProject[0]) {
        if (key !== "name") {
          const data = rootTaskCompleteRateByProject.map(item => item[key])
          newData.push({
            data,
            name: key.toUpperCase()
          })
        }
      }
  
      setRootNewData(newData)
    }
  }, [rootTaskCompleteRateByProject])

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.REPORTING.WORKPROGRAM' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <KTCard className='mb-5'>
            <KTCardBody className='p-9'>
              <form className='form' onSubmit={formik.handleSubmit} noValidate>
                <div className='row mb-6'>                                   
                  <div className='col-lg-6'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'REPORT.WORKPROGRAM.PROJECT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={projectLookup}
                          required
                          {...formik.getFieldProps('ProjectId')}
                          onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                        />
                        {formik.touched.ProjectId && formik.errors.ProjectId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ProjectId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row pt-5'>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                      <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SEARCH' })}</span>
                      {(formik.isSubmitting) && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({ id: 'PLEASE' })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      <div className='row gy-5 g-xl-10'>
        {projectCompleteRateByProject && projectCompleteRateByProject?.length > 0 && <div className='col-xl-6'>
          <ChartDonut
            chartHeight='418px'
            categories={[intl.formatMessage({ id: 'REPORT.WORKPROGRAM.PROJECTCOMPLETERATEBYPROJECT.REMAININGWORK' }), intl.formatMessage({ id: 'REPORT.WORKPROGRAM.PROJECTCOMPLETERATEBYPROJECT.COMPLETEDWORK' })]}
            data={[projectCompleteRateByProject[0]?.remainingWork, projectCompleteRateByProject[0]?.completedWork]}
            title={intl.formatMessage({ id: 'REPORT.WORKPROGRAM.PROJECTCOMPLETERATEBYPROJECT' })}
          />
        </div>}

        {constructionSiteCompleteRateByProject && constructionSiteCompleteRateByProject?.length > 0 && <div className='col-xl-6'>
          <ChartColumn
            chartHeight='400px'
            categories={constructionSiteCompleteRateByProject.map(item => item?.constructionSiteName)}
            data={[
              {
                name: intl.formatMessage({ id: 'REPORT.WORKPROGRAM.CONSTRUCTIONSITECOMPLETERATEBYPROJECT.COMPLETEDWORK' }),
                data: constructionSiteCompleteRateByProject.map(item => item?.completedWork),
              },
              {
                name: intl.formatMessage({ id: 'REPORT.WORKPROGRAM.CONSTRUCTIONSITECOMPLETERATEBYPROJECT.REMAININGWORK' }),
                data: constructionSiteCompleteRateByProject.map(item => item?.remainingWork),
              }
            ]}
            isMultipleColumn={true}
            title={intl.formatMessage({ id: 'REPORT.WORKPROGRAM.CONSTRUCTIONSITECOMPLETERATEBYPROJECT' })}
            stacked
            dataLabels
            barColors={['#01e396', '#018ffb']}
          />
        </div>}
        {rootNewData && rootNewData?.length > 0 && <div className='col-xl-6'>
          <ChartColumn
            chartHeight='400px'
            categories={rootTaskCompleteRateByProject.map(item => item?.name)}
            data={rootNewData}
            isMultipleColumn={true}
            horizontal={true}
            title={intl.formatMessage({ id: 'REPORT.WORKPROGRAM.ROOTCOMPLETERATEBYPROJECT' })}
          />
        </div>}

        {dailyNewData && dailyNewData?.length > 0 && <div className='col-xl-6'>
          <ChartLine
            chartHeight='400px'
            categories={dailyTaskCompleteRateByProject.map(item => item?.title)}
            data={dailyNewData}
            isMultipleColumn={true}
            title={intl.formatMessage({ id: 'REPORT.WORKPROGRAM.DAILYCOMPLETERATEBYPROJECT' })}
            barColors={['#01e396', '#018ffb']}
          />
        </div>}

        {blockCompleteRateWithConstructionSiteByProject?.map((item, index) => {
          return (
            <div className={item?.blockList.length > 10 ? 'col-xl-12' : 'col-xl-6'} key={index}>
              <ChartColumn
                chartHeight='400px'
                categories={item?.blockList?.map(item => item?.blockName)}
                data={[
                  {
                    name: intl.formatMessage({ id: 'REPORT.WORKPROGRAM.CONSTRUCTIONSITECOMPLETERATEBYPROJECT.COMPLETEDWORK' }),
                    data: item?.blockList?.map(item => item?.completedWork),
                  },
                  {
                    name: intl.formatMessage({ id: 'REPORT.WORKPROGRAM.CONSTRUCTIONSITECOMPLETERATEBYPROJECT.REMAININGWORK' }),
                    data: item?.blockList?.map(item => item?.remainingWork),
                  }
                ]}
                isMultipleColumn={true}
                title={item?.constructionSiteName}
                stacked
                dataLabels
                barColors={['#01e396', '#018ffb']}
              />
            </div>
          )
        })}
       
      </div> 

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export {Report};
