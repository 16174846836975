import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-lg-row flex-column-fluid'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/images/auth/bg10.jpeg')})`,
        height: '100vh'
      }}
    >
      <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
        <div className="bg-body d-flex flex-center rounded-4 w-600px p-10">
          <div className="w-md-400px">
            <Outlet />
          </div>
        </div>
      </div>

      <div className="d-flex flex-lg-row-fluid">
        <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
          <img className="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src={toAbsoluteUrl('/images/auth/login-report.png')} alt="" />
          <h1 className="text-gray-800 fs-2qx fw-bold text-center mb-7">Beneksus İle Projeleriniz Kontrol Altında</h1>

          <div className="text-gray-600 fs-base text-center fw-semibold">
            İnşaat sektöründe çalışanlar için tasarlanmış bulut tabanlı proje takip
            <br />yazılımdır. Bu yazılım ile, projelerinizi daha etkili ve verimli bir şekilde yönetebilirsiniz.            
            <br /><a href="https://www.beneksus.com" className="opacity-75-hover text-primary me-1">Beneksus</a>ile verimliliğinizi arttırmak ve işlerinizi daha kolay yönetmek için hemen deneyin!
          </div>
        </div>
      </div>


    </div>
  )
}

export { AuthLayout };
