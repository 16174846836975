/* eslint-disable no-unused-vars */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import PropTypes from 'prop-types';

const ChartColumn = ({className, backGroundColor, chartHeight, options, title, data, categories, tooltipTitle, tooltip, isMultipleColumn, legend, horizontal, stacked, dataLabels, barColors}) => {
  const chartRef = useRef(null)

  const colors = barColors || ApexCharts.colors;

  const series = isMultipleColumn ? data : [{
    name: tooltipTitle,
    data
  }]

  const defaultOption = {
    series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      stacked,
      stackType: '100%'
    },
    plotOptions: {
      bar: {
        horizontal,
        borderRadius: 5,
      },
    },
    legend: {
      show: legend,
    },
    dataLabels: {
      enabled: dataLabels,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip,
    colors,
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }

  const chartOptions = { ...defaultOption, ...options }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions)
    if (chart) {
      chart.render()
    }

    return chart;
  }

  return (
    <div className={`card ${className} theme-dark-bg-body`} style={{backgroundColor: backGroundColor}}>
      <div className='card-body d-flex flex-column'>
        <div className='d-flex flex-column flex-grow-1'>
          <span className='text-dark text-hover-primary fw-bolder fs-3'>{title}</span>

          <div
            ref={chartRef}
            className='mixed-widget-13-chart'
            style={{height: chartHeight, minHeight: chartHeight}}
          ></div>
        </div>
      </div>
    </div>
  )
  
}

ChartColumn.propTypes = {
	title: PropTypes.string,
  categories: PropTypes.array,
  data: PropTypes.array,
  tooltipTitle: PropTypes.string,
  tooltip: PropTypes.any,
  legend: PropTypes.bool,
  horizontal: PropTypes.bool,
  dataLabels: PropTypes.bool,
  stacked: PropTypes.bool
};

ChartColumn.defaultProps = {
	categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
	data: [15, 25, 15, 40, 20, 50],
  legend: true,
  horizontal: false,
  tooltip: {
    y: {
      formatter: (val) => {
        return val
      }
    }
  },
  dataLabels: false,
  stacked: false
};

export {ChartColumn}
