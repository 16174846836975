/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { KTCard, KTCardBody, KTCardHeader } from '../../../components';

import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { accountActions } from '../../../redux/actions';

function Edit() {
  const intl = useIntl()
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { isRedirect, detail, loading } = useSelector(state => state.account);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountActions.accountGetBankCash(id));
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    Id: '',
    Name: '',
    BankName: '',
    Branch: '',
    AccountNumber: '',
    Iban: ''
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      if (detail.accountTypeId === 1 || detail.accountTypeId === 7) {
        dispatch(accountActions.accountUpdateBank(values));
      }
      else {
        dispatch(accountActions.accountUpdateCash(values));
      }      
    },
  })

  useEffect(() => {
    for (const property in detail) {
      const propVal = property.charAt(0).toUpperCase() + property.slice(1);
      for (const values in formValues) {
        if (values === propVal) {
          formik.setFieldValue(propVal, detail[property])
        }
      }
    }
  }, [detail])
  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.BANKCASE' }),
      path: '/cash/bank-safes',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'PAGE_TITLE.DETAIL' }),
      path: `/cash/bank-safes/detail/${id}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    if (isRedirect) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)
  }, [isRedirect])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.BANKCASE.EDIT' })}</PageTitle>
      <KTCard>        
        <KTCardBody>
          {detail && <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'BANKACCOUNT.UPDATE.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {(detail.accountTypeId === 1 || detail.accountTypeId === 7) && <>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'BANKACCOUNT.UPDATE.IBAN' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Iban')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'BANKACCOUNT.UPDATE.BANKNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('BankName')}
                />
              </div>
            </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'BANKACCOUNT.UPDATE.BRANCH' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('Branch')}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'BANKACCOUNT.UPDATE.ACCOUNTNUMBER' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <input
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('AccountNumber')}
                  />
                </div>
              </div>
             </>}
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>                
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${id}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>}
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Edit };
