import { lookupConstants } from '../constants';
import {AuthCheck} from '../../helpers'

const initialState = {
  loading: true, 
  isRedirect: false, 
  error: null,
  isDeleted: false,
  isArchived: false
}

export function lookup(state = {}, action) {
  switch (action.type) {
    case lookupConstants.BASIC_LOOKUP_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.BASIC_LOOKUP_SUCCESS:
      return {
        ...state,
        [action.name]: action.lookup,
        loading: false
      };
    case lookupConstants.BASIC_LOOKUP_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_STAFF_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_STAFF_SUCCESS:
      return {
        ...state,
        staffLookup: action.staffLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_STAFF_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_ACCOUNT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountLookup: action.accountLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_ACCOUNT_FAILURE:
      AuthCheck(action)
      
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_EXTENDED_ACCOUNT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_EXTENDED_ACCOUNT_SUCCESS:
      return {
        ...state,
        extendedAccountLookup: action.extendedAccountLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_EXTENDED_ACCOUNT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.DISTRICT_LOOKUP_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.DISTRICT_LOOKUP_SUCCESS:
      return {
        ...state,
        District: action.District,
        loading: false
      };
    case lookupConstants.DISTRICT_LOOKUP_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_COMPANY_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_COMPANY_SUCCESS:
      return {
        ...state,
        companyLookup: action.companyLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_COMPANY_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_PRODUCT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_PRODUCT_SUCCESS:
      return {
        ...state,
        productLookup: action.productLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_PRODUCT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_USABLECHECK_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_USABLECHECK_SUCCESS:
      return {
        ...state,
        usablecheckLookup: action.usablecheckLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_USABLECHECK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_PROJECT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_PROJECT_SUCCESS:
      return {
        ...state,
        projectLookup: action.projectLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_PROJECT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_ALL_PROJECT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_ALL_PROJECT_SUCCESS:
      return {
        ...state,
        allProjectLookup: action.allProjectLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_ALL_PROJECT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_STOCKPRODUCT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_STOCKPRODUCT_SUCCESS:
      return {
        ...state,
        stockproductLookup: action.stockproductLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_STOCKPRODUCT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_WAREHOUSE_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseLookup: action.warehouseLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_WAREHOUSE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_ROLE_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_ROLE_SUCCESS:
      return {
        ...state,
        roleLookup: action.roleLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_ROLE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_PRODUCT_FOR_UPDATE_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_PRODUCT_FOR_UPDATE_SUCCESS:
      return {
        ...state,
        allProductForUpdate: action.allProductForUpdate,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_PRODUCT_FOR_UPDATE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_SCORECARD_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_SCORECARD_SUCCESS:
      return {
        ...state,
        scorecard: action.scorecard,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_SCORECARD_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_DYNAMICREPORT_ROLE_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_DYNAMICREPORT_ROLE_SUCCESS:
      return {
        ...state,
        dynamicReportRoleLookup: action.dynamicReportRoleLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_DYNAMICREPORT_ROLE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_CONSTRUCTION_SITE_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_CONSTRUCTION_SITE_SUCCESS:
      return {
        ...state,
        constructionSiteLookup: action.constructionSiteLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_CONSTRUCTION_SITE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_BLOCK_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_BLOCK_SUCCESS:
      return {
        ...state,
        blockLookup: action.blockLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_BLOCK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_PLOT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_PLOT_SUCCESS:
      return {
        ...state,
        plotLookup: action.plotLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_PLOT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_BUILDINGBLOCKTYPE_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_BUILDINGBLOCKTYPE_SUCCESS:
      return {
        ...state,
        buildingBlockTypeLookup: action.buildingBlockTypeLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_BUILDINGBLOCKTYPE_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_ADVANCECHECK_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_ADVANCECHECK_SUCCESS:
      return {
        ...state,
        advanceCheckLookup: action.advanceCheckLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_ADVANCECHECK_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_ALLACCOUNT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_ALLACCOUNT_SUCCESS:
      return {
        ...state,
        allAccountLookup: action.allAccountLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_ALLACCOUNT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_ALLCOMPANYBYPROJECT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_ALLCOMPANYBYPROJECT_SUCCESS:
      return {
        ...state,
        allCompanyByProjectLookup: action.allCompanyByProjectLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_ALLCOMPANYBYPROJECT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_COMPANYINFORMATION_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_COMPANYINFORMATION_SUCCESS:
      return {
        ...state,
        companyInformationLookup: action.companyInformationLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_COMPANYINFORMATION_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_USERPROJECT_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_USERPROJECT_SUCCESS:
      return {
        ...state,
        userProjectLookup: action.userProjectLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_USERPROJECT_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    case lookupConstants.TENANT_LOOKUP_ALLUSER_REQUEST:
      return { ...state, ...initialState };
    case lookupConstants.TENANT_LOOKUP_ALLUSER_SUCCESS:
      return {
        ...state,
        allUserLookup: action.allUserLookup,
        loading: false
      };
    case lookupConstants.TENANT_LOOKUP_ALLUSER_FAILURE:
      AuthCheck(action)

      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state
  }
}
