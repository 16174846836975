export const paymentRequestConstants = {
  //#region PaymentRequest
  PAYMENTREQUEST_GETALL_REQUEST: 'PAYMENTREQUEST_GETALL_REQUEST',
  PAYMENTREQUEST_GETALL_SUCCESS: 'PAYMENTREQUEST_GETALL_SUCCESS',
  PAYMENTREQUEST_GETALL_FAILURE: 'PAYMENTREQUEST_GETALL_FAILURE',

  PAYMENTREQUEST_GETID_REQUEST: 'PAYMENTREQUEST_GETID_REQUEST',
  PAYMENTREQUEST_GETID_SUCCESS: 'PAYMENTREQUEST_GETID_SUCCESS',
  PAYMENTREQUEST_GETID_FAILURE: 'PAYMENTREQUEST_GETID_FAILURE',

  PAYMENTREQUEST_DELETE_REQUEST: 'PAYMENTREQUEST_DELETE_REQUEST',
  PAYMENTREQUEST_DELETE_SUCCESS: 'PAYMENTREQUEST_DELETE_SUCCESS',
  PAYMENTREQUEST_DELETE_FAILURE: 'PAYMENTREQUEST_DELETE_FAILURE',

  PAYMENTREQUEST_CREATE_REQUEST: 'PAYMENTREQUEST_CREATE_REQUEST',
  PAYMENTREQUEST_CREATE_SUCCESS: 'PAYMENTREQUEST_CREATE_SUCCESS',
  PAYMENTREQUEST_CREATE_FAILURE: 'PAYMENTREQUEST_CREATE_FAILURE',

  PAYMENTREQUEST_UPDATE_REQUEST: 'PAYMENTREQUEST_UPDATE_REQUEST',
  PAYMENTREQUEST_UPDATE_SUCCESS: 'PAYMENTREQUEST_UPDATE_SUCCESS',
  PAYMENTREQUEST_UPDATE_FAILURE: 'PAYMENTREQUEST_UPDATE_FAILURE',

  PAYMENTREQUEST_ONCONFIRMATIONSTATE_REQUEST: 'PAYMENTREQUEST_ONCONFIRMATIONSTATE_REQUEST',
  PAYMENTREQUEST_ONCONFIRMATIONSTATE_SUCCESS: 'PAYMENTREQUEST_ONCONFIRMATIONSTATE_SUCCESS',
  PAYMENTREQUEST_ONCONFIRMATIONSTATE_FAILURE: 'PAYMENTREQUEST_ONCONFIRMATIONSTATE_FAILURE',

  PAYMENTREQUEST_GETCONFIRMATIONSTATE_REQUEST: 'PAYMENTREQUEST_GETCONFIRMATIONSTATE_REQUEST',
  PAYMENTREQUEST_GETCONFIRMATIONSTATE_SUCCESS: 'PAYMENTREQUEST_GETCONFIRMATIONSTATE_SUCCESS',
  PAYMENTREQUEST_GETCONFIRMATIONSTATE_FAILURE: 'PAYMENTREQUEST_GETCONFIRMATIONSTATE_FAILURE',

  PAYMENTREQUEST_UPDATECONFIRMATION_REQUEST: 'PAYMENTREQUEST_UPDATECONFIRMATION_REQUEST',
  PAYMENTREQUEST_UPDATECONFIRMATION_SUCCESS: 'PAYMENTREQUEST_UPDATECONFIRMATION_SUCCESS',
  PAYMENTREQUEST_UPDATECONFIRMATION_FAILURE: 'PAYMENTREQUEST_UPDATECONFIRMATION_FAILURE',
  //#endregion

  //#region WaitingConfirmation
  WAITINGCONFIRMATION_GETALL_REQUEST: 'WAITINGCONFIRMATION_GETALL_REQUEST',
  WAITINGCONFIRMATION_GETALL_SUCCESS: 'WAITINGCONFIRMATION_GETALL_SUCCESS',
  WAITINGCONFIRMATION_GETALL_FAILURE: 'WAITINGCONFIRMATION_GETALL_FAILURE',
  //#endregion 
};
