/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { PageTitle } from '../../../core';

import { KTCard, KTCardBody, Notes, DeleteModal, ArchiveModal, Select, DataTables, CheckoutDetail, Category, StatementModal } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { companyActions, lookupActions, checkoutActions, accountActions } from '../../../redux/actions';
import { SourceType, KTSVG, detailPageUrl, DetailPage, ExpenseDetailPage } from '../../../helpers';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Dropdown from 'react-bootstrap/Dropdown';

import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js"
import "flatpickr/dist/flatpickr.css";

function Detail() {
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const [activeTab, setActiveTab] = useState('payment');
  const [checkoutId, setCheckouId] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation()
  const intl = useIntl()
  const { detail, isDeleted, isArchived, error } = useSelector(state => state.company);
  const { accountLookup, Bank, usablecheckLookup, projectLookup } = useSelector(state => state.lookup);
  const { transaction, loading, companyNewCheck, companyCheckCheckout } = useSelector(state => state.checkout);
  const { accountList } = useSelector(state => state.account);
  const dispatch = useDispatch();

  const pageUrl = location.pathname.split('/')

  const [stepSchema, setStepSchema] = useState({
    PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    FromAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    Amount: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const validSchema = Yup.object().shape(stepSchema)

  const [formValues] = useState({
    CompanyId: id,
    PaymentDate: new Date(),
    FromAccountId: '',
    ToAccountId: '',
    Amount: 0,
    Name: '',
    DefermentDate: new Date(),
    EditDate: new Date(),
    BankId: '',
    CheckNo: '',
    Description: '',
    TotalPrice: 0,
    CheckId: '',
    ToCompanyId: id,
    ProjectId: '',
    IsOfficial: true
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      if (activeTab === 'payment') {
        dispatch(checkoutActions.checkoutCompanyPayment(values));
      } else if (activeTab === 'collection') {
        dispatch(checkoutActions.checkoutCompanyCollection(values));
      } else if (activeTab === 'newcheck') {
        dispatch(checkoutActions.checkoutCompanyPayNewCheck(values));
      } else {
        dispatch(checkoutActions.checkoutCompanyPayCheckout(values));
      }

      formik.setFieldValue('Payment', 0)
      formik.setFieldValue('TotalPrice', 0)
      formik.setFieldValue('Name', '')
      formik.setFieldValue('Description', '')
    },
  })

  useEffect(() => {
    formik.setFieldValue('PaymentDate', new Date())
    formik.setFieldValue('DefermentDate', new Date())
    formik.setFieldValue('EditDate', new Date())
    formik.setFieldValue('FromAccountId', '')
    formik.setFieldValue('ToAccountId', '')
    formik.setFieldValue('Amount', '')
    formik.setFieldValue('Name', '')
    formik.setFieldValue('BankId', '')
    formik.setFieldValue('CheckNo', '')
    formik.setFieldValue('Description', '')
    formik.setFieldValue('TotalPrice', '')
    formik.setFieldValue('CheckId', '')
    formik.setFieldValue('ProjectId', '')
    formik.setFieldValue('IsOfficial', true)

    if (activeTab === 'payment') {
      setStepSchema({
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        FromAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Amount: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    } else if (activeTab === 'collection') {
      setStepSchema({
        PaymentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ToAccountId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        Amount: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    } else if (activeTab === 'check') {
      setStepSchema({
        CheckId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    } else if (activeTab === 'newcheck') {
      setStepSchema({
        BankId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        CheckNo: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        DefermentDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        EditDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
        TotalPrice: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
      })
    }
  }, [activeTab])

  useEffect(() => {
    dispatch(companyActions.companyGetId(id));
    dispatch(lookupActions.usableCheckLookup());
    dispatch(lookupActions.bankCashAccountGetAll());
    dispatch(lookupActions.projectLookup());
  }, [id, transaction, companyNewCheck, companyCheckCheckout])

  useEffect(() => {
    if (detail?.accountId) {
      dispatch(accountActions.accountSearchTransactions({ id: detail?.accountId }));
    }
  }, [detail?.accountId])

  const handleDelete = () => dispatch(companyActions.companyDelete({ id }));
  const handleArchive = () => dispatch(companyActions.companyArchiving({ id }));

  useEffect(() => {
    if (isDeleted || isArchived) navigate(`/${pageUrl[1]}/${pageUrl[2]}`)
  }, [isDeleted, isArchived])

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.SUBCONTRACTOR' }),
      path: '/expenses/subcontractor',
      isSeparator: false,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.SUBCONTRACTOR.DETAIL' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-9'>
          {detail && <>
            <KTCard className='mb-5'>
              <KTCardBody className='p-9'>
                <div className="d-flex flex-wrap">
                  <div className="flex-grow-1 w-100">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-5 row">
                      <div className="d-flex flex-column col-xl-7">
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-gray-900 fs-2 fw-bold me-1">{detail.name}</div>

                          <div className='ms-5'>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">

                          {detail?.phone && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/communication/com003.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.phone}
                            </div>
                          </>}

                          {detail?.email && <>
                            <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                              <KTSVG
                                path='/images/icons/duotune/communication/com011.svg'
                                className='svg-icon svg-icon-2 fs-4 me-1'
                              />
                              {detail.email}
                            </div>
                          </>}

                          <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                            <KTSVG
                              path='/images/icons/duotune/general/gen018.svg'
                              className='svg-icon svg-icon-2 fs-4 me-1'
                            />
                            {detail.address} {detail.postalCode} {detail.provinceName} - {detail.districtName}
                          </div>

                        </div>
                      </div>

                      <div className='d-flex gap-3 align-items-center col-xl-5 justify-content-end'>
                        <Category SourceId={Number(id)} SourceTypeId={SourceType.Company} />
                        <Link to={`/${pageUrl[1]}/${pageUrl[2]}/edit/${id}`} className="btn btn-sm btn-primary align-self-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen055.svg'
                            className='svg-icon-2'
                          />
                          {intl.formatMessage({ id: 'BUTTON.EDIT' })}
                        </Link>
                        <Dropdown autoClose="outside" align={{ lg: 'end' }}>
                          <Dropdown.Toggle
                            variant='secondary'
                            size="sm"
                            className='iconless ps-3 pe-3'
                          >
                            <KTSVG path='/images/icons/duotune/general/gen053.svg' className='svg-icon-2' />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-3">
                            <Dropdown.Item onClick={() => setShowStatement(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.STATEMENT' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowArchive(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.ARCHIVE' })}</div></Dropdown.Item>
                            <Dropdown.Item onClick={() => setShow(true)} className="menu-item px-3"><div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">{intl.formatMessage({ id: 'BUTTON.DELETE' })}</div></Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">

                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'COMPANY.GET.BALANCE' })}</div>
                          <div className="fw-bold fs-4"><span className="fw-bold fs-6">{intl.formatNumber(detail.balance, { style: 'currency', currency: 'try' })} {detail.balanceInformation}</span></div>
                        </div>

                        {
                          detail?.totalExpenses > 0 && <>
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                              <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'COMPANY.GET.TOTALEXPENSES' })}</div>
                              <div className="fw-bold fs-4">{intl.formatNumber(detail.totalExpenses, { style: 'currency', currency: 'try' })}</div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                              <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'COMPANY.GET.TOTALEXPENSESBALANCE' })}</div>
                              <div className="fw-bold fs-4">{intl.formatNumber(detail.totalExpenseBalances, { style: 'currency', currency: 'try' })}</div>
                            </div>
                          </>
                        }

                        {
                          detail?.totalSales > 0 && <>
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                              <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'COMPANY.GET.TOTALSALES' })}</div>
                              <div className="fw-bold fs-4">{intl.formatNumber(detail.totalSales, { style: 'currency', currency: 'try' })}</div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                              <div className="fw-semibold fs-6 text-gray-400">{intl.formatMessage({ id: 'COMPANY.GET.TOTALSALESBALANCE' })}</div>
                              <div className="fw-bold fs-4">{intl.formatNumber(detail.totalSaleBalances, { style: 'currency', currency: 'try' })}</div>
                            </div>
                          </>
                        }

                      </div>
                    </div>
                  </div>
                </div>
                {detail?.iban && <div className='notice d-flex rounded border-primary border border-dashed  p-6'>
                  {detail?.iban}
                </div>}
              </KTCardBody>
            </KTCard>

            <KTCard>
              <KTCardBody className='p-9'>
                <Tabs
                  className="nav-line-tabs"
                  fill
                >
                  <Tab eventKey="general" title={intl.formatMessage({ id: 'GENERAL' })}>
                    <div className='row p-5'>
                      <div className='col-lg-10 order-2 order-lg-1'>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.TITLE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.title}</span></div>
                        </div>

                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.COMMERCIALENTERPRISETYPE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.commercialEnterpriseTypeName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.IDENTITYNUMBER' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.identityNumber}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.TAXOFFICE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.taxOffice}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.TAXNUMBER' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.taxNumber}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.FAX' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.fax}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.TOTALPAYMENT' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalPayment, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.TOTALCOLLECTION' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalCollection, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.TOTALCHECKPAYMENT' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{intl.formatNumber(detail.totalCheckPayment, { style: 'currency', currency: 'try' })}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.GIBINVOICETYPE' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.gibInvoiceTypeName}</span></div>
                        </div>
                        <div className="row mb-7">
                          <label className="col-lg-4 fw-bold text-muted">{intl.formatMessage({ id: 'COMPANY.GET.INVOICEEMAIL' })}</label>
                          <div className="col-lg-8 fv-row"><span className="fw-bold fs-6">{detail.invoiceEmail}</span></div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  {accountList?.length > 0 && <Tab eventKey="accountList" title={intl.formatMessage({ id: 'COMPANY.GET.CHECKOUTS' })}>
                    <div className='table-responsive'>
                      <table className="table align-middle gy-5 fs-6 table-striped table-hover" >
                        <thead>
                          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                            <th>{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.FROMACCOUNTNAME' })}</th>
                            <th>{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.PAYMENTDATE' })}</th>
                            <th>{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.DEFERMENTDATE' })}</th>
                            <th>{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.PROCESSTYPENAME' })}</th>
                            <th>{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.ISOFFICIALNAME' })}</th>
                            <th>{intl.formatMessage({ id: 'BANKCASHACCOUNT.GET.AMOUNT' })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountList.map((item, index) => <tr key={index} role="button" onClick={() => setCheckouId(item.id)}>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.fromAccountName}
                                {item.name && <span className="text-muted fw-semibold d-block">{item.name}</span>}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {intl.formatDate(new Date(item?.paymentDate), { year: 'numeric', month: 'long', day: 'numeric' })}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item?.defermentDate != null ? intl.formatDate(new Date(item?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' }) : "-"}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.processTypeName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {item.isOfficialName}
                              </div>
                            </td>
                            <td className='p-5'>
                              <div className='text-dark fw-semibold d-block mb-1 fs-6'>
                                {intl.formatNumber(item.amount, { style: 'currency', currency: 'try' })}
                              </div>
                            </td>
                          </tr>)}
                        </tbody>
                      </table>
                    </div>
                  </Tab>}

                  {detail?.expenseInvoices?.length > 0 && <Tab eventKey="expenseInvoices" title={intl.formatMessage({ id: 'COMPANY.GET.EXPENSES' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(ExpenseDetailPage[row?.expenseTypeId], row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.PROJECTNAME' }),
                          prop: 'projectName',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Project, row?.projectId)} className='text-dark text-hover-primary' target='_blank'>{row.projectName}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.COMPANYNAME' }),
                          prop: 'companyName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.EDITDATE' }),
                          prop: 'editDate',
                          cell: (row) => intl.formatDate(new Date(row?.editDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.EXPENSEPAYMENTSTATUSNAME' }),
                          prop: 'expensePaymentStatusName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.INVOICEKINDNAME' }),
                          prop: 'invoiceKindName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.BALANCE' }),
                          prop: 'balance',
                          cell: (row) => intl.formatNumber(row.balance, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.expenseInvoices}
                      exportTitle={intl.formatMessage({ id: 'COMPANY.GET.EXPENSES' })}
                    />
                  </Tab>}

                  {detail?.saleInvoices?.length > 0 && <Tab eventKey="saleInvoices" title={intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.SaleInvoice, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES.PROJECTNAME' }),
                          prop: 'projectName',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Project, row?.projectId)} className='text-dark text-hover-primary' target='_blank'>{row.projectName}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES.COMPANYNAME' }),
                          prop: 'companyName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES.EDITDATE' }),
                          prop: 'editDate',
                          cell: (row) => intl.formatDate(new Date(row?.editDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES.SALEPAYMENTSTATUSNAME' }),
                          prop: 'salePaymentStatusName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.INVOICEKINDNAME' }),
                          prop: 'invoiceKindName',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES.TOTALPRICE' }),
                          prop: 'totalPrice',
                          cell: (row) => intl.formatNumber(row.totalPrice, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES.BALANCE' }),
                          prop: 'balance',
                          cell: (row) => intl.formatNumber(row.balance, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }]
                      }
                      data={detail?.saleInvoices}
                      exportTitle={intl.formatMessage({ id: 'COMPANY.GET.SALEINVOICES' })}
                    />
                  </Tab>}

                  {detail?.projects?.length > 0 && <Tab eventKey="projects" title={intl.formatMessage({ id: 'COMPANY.GET.PROJECTS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.EXPENSEINVOICES.NAME' }),
                          prop: 'name',
                          cell: (row) => <Link to={detailPageUrl(DetailPage.Project, row?.id)} className='text-dark text-hover-primary' target='_blank'>{row.name}</Link>,
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.EXPENSETOTAL' }),
                          prop: 'expenseTotal',
                          cell: (row) => intl.formatNumber(row.expenseTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.EXPENSEBALANCETOTAL' }),
                          prop: 'expenseBalanceTotal',
                          cell: (row) => intl.formatNumber(row.expenseBalanceTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.SALETOTAL' }),
                          prop: 'saleTotal',
                          cell: (row) => intl.formatNumber(row.saleTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        },
                        {
                          title: intl.formatMessage({id: 'COMPANY.GET.EXPENSEINVOICES.SALEBALANCETOTAL' }),
                          prop: 'saleBalanceTotal',
                          cell: (row) => intl.formatNumber(row.saleBalanceTotal, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true,
                          alignment: {
                            horizontal: 'right'
                          }
                        }
                      ]}
                      data={detail?.projects}
                      exportTitle={intl.formatMessage({ id: 'COMPANY.GET.PROJECTS' })}
                    />
                  </Tab>}

                  {detail?.companyPeoples?.length > 0 && <Tab eventKey="companyPeoples" title={intl.formatMessage({ id: 'COMPANY.GET.COMPANYPEOPLE' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.NAMESURNAME' }),
                          prop: 'nameSurname',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.PHONE' }),
                          prop: 'phone',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.EMAIL' }),
                          prop: 'email',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.CREATE.COMPANYPEOPLE.DESCRIPTION' }),
                          prop: 'description',
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.companyPeoples}
                      exportTitle={intl.formatMessage({ id: 'COMPANY.GET.COMPANYPEOPLE' })}
                    />
                  </Tab>}

                  {detail?.checkPayments?.length > 0 && <Tab eventKey="checkPayments" title={intl.formatMessage({ id: 'COMPANY.GET.CHECKPAYMENTS' })}>
                    <DataTables
                      columns={[
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.CHECKPAYMENTS.NAME' }),
                          prop: 'name',
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.CHECKPAYMENTS.DEFERMENTDATE' }),
                          prop: 'defermentDate',
                          cell: (row) => intl.formatDate(new Date(row?.defermentDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.CHECKPAYMENTS.PAYMENTDATE' }),
                          prop: 'paymentDate',
                          cell: (row) => intl.formatDate(new Date(row?.paymentDate), { year: 'numeric', month: 'long', day: 'numeric' }),
                          isSortable: true,
                          isFilterable: true
                        },
                        {
                          title: intl.formatMessage({ id: 'COMPANY.GET.CHECKPAYMENTS.AMOUNT' }),
                          prop: 'amount',
                          cell: (row) => intl.formatNumber(row.amount, { style: 'currency', currency: 'try' }),
                          isSortable: true,
                          isFilterable: true
                        }]
                      }
                      data={detail?.checkPayments}
                      exportTitle={intl.formatMessage({ id: 'COMPANY.GET.CHECKPAYMENTS' })}
                    />
                  </Tab>}

                </Tabs>
              </KTCardBody>
            </KTCard>
          </>}
        </div>

        <div className='col-xl-3'>
          <KTCard className='mb-6'>
            <KTCardBody className='p-3 pt-11'>
              <form className='form position-relative mb-6' onSubmit={formik.handleSubmit} noValidate>
                <Tabs
                  defaultActiveKey={activeTab}
                  fill
                  onSelect={(active) => setActiveTab(active)}
                  className="nav-line-tabs"
                >
                  <Tab eventKey="payment" title={intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.ADD' })} className='p-5'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'CHECK.ADDPAYMENT.PAYMENTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('PaymentDate')}
                            onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                          />
                        </div>
                        {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.PaymentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.TOACCOUNDID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={accountLookup}
                          required
                          {...formik.getFieldProps('FromAccountId')}
                          onChange={item => formik.setFieldValue('FromAccountId', Number(item?.value))}
                        />
                        {formik.touched.FromAccountId && formik.errors.FromAccountId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.FromAccountId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.PROJECTID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={projectLookup}
                          required
                          {...formik.getFieldProps('ProjectId')}
                          onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                        />
                        {formik.touched.ProjectId && formik.errors.ProjectId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ProjectId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.ISOFFICIAL.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input 
                            className="form-check-input h-30px w-50px" 
                            type="checkbox" 
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('Amount')}
                        />
                        {formik.touched.Amount && formik.errors.Amount && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Amount}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.NAME' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Name')}
                        />
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="collection" title={intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.ADD' })} className='p-5'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.COLLECTIONDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('PaymentDate')}
                            onChange={([date]) => formik.setFieldValue('PaymentDate', date)}
                          />
                        </div>
                        {formik.touched.PaymentDate && formik.errors.PaymentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.PaymentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.TOACCOUNDID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={accountLookup}
                          required
                          {...formik.getFieldProps('ToAccountId')}
                          onChange={item => formik.setFieldValue('ToAccountId', Number(item?.value))}
                        />
                        {formik.touched.ToAccountId && formik.errors.ToAccountId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ToAccountId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.PROJECTID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={projectLookup}
                          required
                          {...formik.getFieldProps('ProjectId')}
                          onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                        />
                        {formik.touched.ProjectId && formik.errors.ProjectId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ProjectId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.ISOFFICIAL.COLLECTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input 
                            className="form-check-input h-30px w-50px" 
                            type="checkbox" 
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('Amount')}
                        />
                        {formik.touched.Amount && formik.errors.Amount && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.Amount}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.DESCRIPTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Name')}
                        />
                      </div>
                    </div>


                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="check" title={intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.SAVEDCHECK' })} className='p-5'>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.CHECK' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={usablecheckLookup}
                          required
                          {...formik.getFieldProps('CheckId')}
                          onChange={item => formik.setFieldValue('CheckId', Number(item?.value))}
                        />
                        {formik.touched.CheckId && formik.errors.CheckId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.CheckId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.ISOFFICIAL.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input h-30px w-50px"
                            type="checkbox"
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.DESCRIPTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Name')}
                        />
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="newcheck" title={intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.NEWCHECK' })} className='p-5'>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.BANKID' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={Bank}
                          required
                          {...formik.getFieldProps('BankId')}
                          onChange={item => formik.setFieldValue('BankId', Number(item?.value))}
                        />
                        {formik.touched.BankId && formik.errors.BankId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.BankId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.CHECKNO' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('CheckNo')}
                        />
                        {formik.touched.CheckNo && formik.errors.CheckNo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.CheckNo}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.EDITDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('EditDate')}
                            onChange={([date]) => formik.setFieldValue('EditDate', date)}
                          />
                        </div>
                        {formik.touched.EditDate && formik.errors.EditDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.EditDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.DEFERMENTDATE' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="position-relative d-flex align-items-center">
                          <KTSVG
                            path='/images/icons/duotune/general/gen014.svg'
                            className='svg-icon svg-icon-2 position-absolute mx-4'
                          />
                          <Flatpickr
                            className="form-control form-control-solid ps-12"
                            options={{ "locale": Turkish }}
                            {...formik.getFieldProps('EditDate')}
                            onChange={([date]) => formik.setFieldValue('DefermentDate', date)}
                          />
                        </div>
                        {formik.touched.DefermentDate && formik.errors.DefermentDate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.DefermentDate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'SALEINVOICE.GET.RECIEVE.ISOFFICIAL.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input h-30px w-50px"
                            type="checkbox"
                            checked={formik.values.IsOfficial}
                            {...formik.getFieldProps('IsOfficial')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.PAYMENT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <input
                          type='number'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          {...formik.getFieldProps('TotalPrice')}
                          onFocus={event => event.target.select()}
                        />
                        {formik.touched.TotalPrice && formik.errors.TotalPrice && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.TotalPrice}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'EXPENSEINVOICE.GETDETAIL.RECIEVE.DESCRIPTION' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <textarea
                          type='text'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          autoComplete='off'
                          disabled={formik.isSubmitting}
                          rows={2}
                          {...formik.getFieldProps('Description')}
                        />
                      </div>
                    </div>

                    <div className='text-center'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                      >
                        <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                        {(formik.isSubmitting) && (
                          <span className='indicator-progress'>
                            {intl.formatMessage({ id: 'PLEASE' })}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Tab>
                </Tabs>
              </form>
            </KTCardBody>
          </KTCard>

          <Notes SourceId={Number(id)} SourceTypeId={SourceType.Company} />
        </div>
      </div>

      <DeleteModal
        onClick={handleDelete}
        onOpen={show}
        onClose={() => setShow(false)}
        error={error}
      />

      <ArchiveModal
        onClick={handleArchive}
        onOpen={showArchive}
        onClose={() => setShowArchive(false)}
        error={error}
      />

      <StatementModal
        onOpen={showStatement}
        CompanyId={id}
        onClose={() => setShowStatement(false)}
      />
      
      <CheckoutDetail id={checkoutId} onClose={() => setCheckouId(null)} />
      {
        loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
          <span className="spinner-border text-primary" role="status"></span>
          <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
        </div>)
      }
    </>
  )
}
export { Detail };
