import { purchaseConstants } from '../constants';
import {
  //#region PurchaseRequest
  SearchPurchaseRequestClient,
  GetPurchaseRequestClient,
  DeletePurchaseRequestClient,
  UpdatePurchaseRequestClient,
  UpdatePurchaseRequestStatusClient,
  CreatePurchaseRequestClient,
  ArchivingPurchaseRequestClient,
  //#endregion

  //#region PurchaseProposal
  DeletePurchaseProposalClient,
  CreatePurchaseProposalClient,
  //#endregion

  //#region PurchaseOrder
  SearchPurchaseOrderClient,
  GetPurchaseOrderClient,
  DeletePurchaseOrderClient,
  UpdatePurchaseOrderClient,
  UpdatePurchaseOrderStatusClient,
  CreatePurchaseOrderClient,
  ArchivingPurchaseOrderClient,
  //#endregion

} from '../../api'

const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
//const SERVICE_URL = "https://localhost:7016";

//#region PurchaseRequest
const archivingPurchaseRequestClient = new ArchivingPurchaseRequestClient(SERVICE_URL);
const searchPurchaseRequestClient = new SearchPurchaseRequestClient(SERVICE_URL);
const getPurchaseRequestClient = new GetPurchaseRequestClient(SERVICE_URL);
const createPurchaseRequestClient = new CreatePurchaseRequestClient(SERVICE_URL);
const deletePurchaseRequestClient = new DeletePurchaseRequestClient(SERVICE_URL);
const updatePurchaseRequestClient = new UpdatePurchaseRequestClient(SERVICE_URL);
const updatePurchaseRequestStatusClient = new UpdatePurchaseRequestStatusClient(SERVICE_URL);
//#endregion

//#region PurchaseProposal
const createPurchaseProposalClient = new CreatePurchaseProposalClient(SERVICE_URL);
const deletePurchaseProposalClient = new DeletePurchaseProposalClient(SERVICE_URL);
//#endregion

//#region PurchaseOrder
const archivingPurchaseOrderClient = new ArchivingPurchaseOrderClient(SERVICE_URL);
const searchPurchaseOrderClient = new SearchPurchaseOrderClient(SERVICE_URL);
const getPurchaseOrderClient = new GetPurchaseOrderClient(SERVICE_URL);
const createPurchaseOrderClient = new CreatePurchaseOrderClient(SERVICE_URL);
const deletePurchaseOrderClient = new DeletePurchaseOrderClient(SERVICE_URL);
const updatePurchaseOrderClient = new UpdatePurchaseOrderClient(SERVICE_URL);
const updatePurchaseOrderStatusClient = new UpdatePurchaseOrderStatusClient(SERVICE_URL);
//#endregion

export const purchaseActions = {
  //#region PurchaseRequest
  purchaseRequestGetAll,
  purchaseRequestGetId,
  purchaseRequestDelete,
  purchaseRequestCreate,
  purchaseRequestUpdate,
  purchaseRequestUpdateStatus,
  purchaseRequestArchiving,
  purchaseRequestExcel,
  //#endregion

  //#region PurchaseProposal
  purchaseProposalDelete,
  purchaseProposalCreate,
  //#endregion

  //#region PurchaseOrder
  purchaseOrderGetAll,
  purchaseOrderGetId,
  purchaseOrderDelete,
  purchaseOrderCreate,
  purchaseOrderUpdate,
  purchaseOrderUpdateStatus,
  purchaseOrderArchiving,
  purchaseOrderExcel,
  //#endregion
};

//#region PurchaseRequest
function purchaseRequestGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchPurchaseRequestClient.search(params.name, params.purchaseRequestStatusId, params.projectId, params.companyId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        purchase => dispatch(success(purchase)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: purchaseConstants.PURCHASEREQUEST_GETALL_REQUEST } }
  function success(purchase) { return { type: purchaseConstants.PURCHASEREQUEST_GETALL_SUCCESS, purchase } }
  function failure(error) { return { type: purchaseConstants.PURCHASEREQUEST_GETALL_FAILURE, error } }
}

function purchaseRequestExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchPurchaseRequestClient.excel(params.name, params.purchaseRequestStatusId, params.projectId, params.companyId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: purchaseConstants.PURCHASEREQUEST_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: purchaseConstants.PURCHASEREQUEST_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: purchaseConstants.PURCHASEREQUEST_EXCEL_FAILURE, error } }
}

function purchaseRequestGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getPurchaseRequestClient.get(id)
      .then(
        purchase => dispatch(success(purchase)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: purchaseConstants.PURCHASEREQUEST_GETID_REQUEST, id } }
  function success(purchase) { return { type: purchaseConstants.PURCHASEREQUEST_GETID_SUCCESS, purchase } }
  function failure(error) { return { type: purchaseConstants.PURCHASEREQUEST_GETID_FAILURE, error } }
}

function purchaseRequestDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deletePurchaseRequestClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEREQUEST_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: purchaseConstants.PURCHASEREQUEST_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: purchaseConstants.PURCHASEREQUEST_DELETE_FAILURE, error } }
}

function purchaseRequestArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingPurchaseRequestClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEREQUEST_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: purchaseConstants.PURCHASEREQUEST_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: purchaseConstants.PURCHASEREQUEST_ARCHIVE_FAILURE, error } }
}

function purchaseRequestCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createPurchaseRequestClient.create(params)
      .then(
        purchase => dispatch(success(purchase)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEREQUEST_CREATE_REQUEST, params } }
  function success(purchase) { return { type: purchaseConstants.PURCHASEREQUEST_CREATE_SUCCESS, purchase } }
  function failure(error) { return { type: purchaseConstants.PURCHASEREQUEST_CREATE_FAILURE, error } }
}

function purchaseRequestUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updatePurchaseRequestClient.update(params)
      .then(
        purchase => dispatch(success(purchase, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEREQUEST_UPDATE_REQUEST, params } }
  function success(purchase, params) { return { type: purchaseConstants.PURCHASEREQUEST_UPDATE_SUCCESS, purchase, params } }
  function failure(error, params) { return { type: purchaseConstants.PURCHASEREQUEST_UPDATE_FAILURE, error, params } }
}

function purchaseRequestUpdateStatus(params) {
  return dispatch => {
    dispatch(request(params));

    updatePurchaseRequestStatusClient.updateStatus(params)
      .then(
        purchase => dispatch(success(purchase, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEREQUEST_UPDATESTATUS_REQUEST, params } }
  function success(purchase, params) { return { type: purchaseConstants.PURCHASEREQUEST_UPDATESTATUS_SUCCESS, purchase, params } }
  function failure(error, params) { return { type: purchaseConstants.PURCHASEREQUEST_UPDATESTATUS_FAILURE, error, params } }
}
//#endregion

//#region PurchaseProposal
function purchaseProposalDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deletePurchaseProposalClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEPROPOSAL_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: purchaseConstants.PURCHASEPROPOSAL_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: purchaseConstants.PURCHASEPROPOSAL_DELETE_FAILURE, error } }
}

function purchaseProposalCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createPurchaseProposalClient.create(params.PurchaseRequestId, params.Name, params.CompanyName, params.TotalPrice, params.ProposalFile, params.PurchaseProposalStatusId)
      .then(
        purchase => dispatch(success(purchase)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEPROPOSAL_CREATE_REQUEST, params } }
  function success(purchase) { return { type: purchaseConstants.PURCHASEPROPOSAL_CREATE_SUCCESS, purchase } }
  function failure(error) { return { type: purchaseConstants.PURCHASEPROPOSAL_CREATE_FAILURE, error } }
}
//#endregion

//#region PurchaseOrder
function purchaseOrderGetAll(params) {
  return dispatch => {
    dispatch(request());

    searchPurchaseOrderClient.search(params.name, params.companyId, params.purchaseOrderStatusId, params.projectId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        purchase => dispatch(success(purchase)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: purchaseConstants.PURCHASEORDER_GETALL_REQUEST } }
  function success(purchase) { return { type: purchaseConstants.PURCHASEORDER_GETALL_SUCCESS, purchase } }
  function failure(error) { return { type: purchaseConstants.PURCHASEORDER_GETALL_FAILURE, error } }
}

function purchaseOrderExcel(params, fileName) {
  return dispatch => {
    dispatch(request());

    searchPurchaseOrderClient.excel(params.name, params.companyId, params.purchaseOrderStatusId, params.projectId, params.items_per_page, params.page, params.order, params.sort)
      .then(
        excel => dispatch(success(excel, fileName)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: purchaseConstants.PURCHASEORDER_EXCEL_REQUEST } }
  function success(excel, fileName) { return { type: purchaseConstants.PURCHASEORDER_EXCEL_SUCCESS, excel, fileName } }
  function failure(error) { return { type: purchaseConstants.PURCHASEORDER_EXCEL_FAILURE, error } }
}

function purchaseOrderGetId(id) {
  return dispatch => {
    dispatch(request(id));

    getPurchaseOrderClient.get(id)
      .then(
        purchase => dispatch(success(purchase)),
        error => dispatch(failure(error))
      );
  };

  function request(id) { return { type: purchaseConstants.PURCHASEORDER_GETID_REQUEST, id } }
  function success(purchase) { return { type: purchaseConstants.PURCHASEORDER_GETID_SUCCESS, purchase } }
  function failure(error) { return { type: purchaseConstants.PURCHASEORDER_GETID_FAILURE, error } }
}

function purchaseOrderDelete(params) {
  return dispatch => {
    dispatch(request(params));

    deletePurchaseOrderClient.delete(params)
      .then(
        isDeleted => dispatch(success(isDeleted)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEORDER_DELETE_REQUEST, params } }
  function success(isDeleted) { return { type: purchaseConstants.PURCHASEORDER_DELETE_SUCCESS, isDeleted } }
  function failure(error) { return { type: purchaseConstants.PURCHASEORDER_DELETE_FAILURE, error } }
}

function purchaseOrderArchiving(params) {
  return dispatch => {
    dispatch(request(params));

    archivingPurchaseOrderClient.archive(params)
      .then(
        isArchived => dispatch(success(isArchived)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEORDER_ARCHIVE_REQUEST, params } }
  function success(isArchived) { return { type: purchaseConstants.PURCHASEORDER_ARCHIVE_SUCCESS, isArchived } }
  function failure(error) { return { type: purchaseConstants.PURCHASEORDER_ARCHIVE_FAILURE, error } }
}

function purchaseOrderCreate(params) {
  return dispatch => {
    dispatch(request(params));

    createPurchaseOrderClient.create(params)
      .then(
        purchase => dispatch(success(purchase)),
        error => dispatch(failure(error))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEORDER_CREATE_REQUEST, params } }
  function success(purchase) { return { type: purchaseConstants.PURCHASEORDER_CREATE_SUCCESS, purchase } }
  function failure(error) { return { type: purchaseConstants.PURCHASEORDER_CREATE_FAILURE, error } }
}

function purchaseOrderUpdate(params) {
  return dispatch => {
    dispatch(request(params));

    updatePurchaseOrderClient.update(params)
      .then(
        purchase => dispatch(success(purchase, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEORDER_UPDATE_REQUEST, params } }
  function success(purchase, params) { return { type: purchaseConstants.PURCHASEORDER_UPDATE_SUCCESS, purchase, params } }
  function failure(error, params) { return { type: purchaseConstants.PURCHASEORDER_UPDATE_FAILURE, error, params } }
}

function purchaseOrderUpdateStatus(params) {
  return dispatch => {
    dispatch(request(params));

    updatePurchaseOrderStatusClient.updateStatus(params)
      .then(
        purchase => dispatch(success(purchase, params)),
        error => dispatch(failure(error, params))
      );
  };

  function request(params) { return { type: purchaseConstants.PURCHASEORDER_UPDATESTATUS_REQUEST, params } }
  function success(purchase, params) { return { type: purchaseConstants.PURCHASEORDER_UPDATESTATUS_SUCCESS, purchase, params } }
  function failure(error, params) { return { type: purchaseConstants.PURCHASEORDER_UPDATESTATUS_FAILURE, error, params } }
}
//#endregion
