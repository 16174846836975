export const DownloadFileFn = (data, fileName, type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', isBlob = true) => {
  let fileURL
  const a = document.createElement('a');
  if (isBlob) {
    const file = new Blob([data], { type });
    fileURL = URL.createObjectURL(file);
    a.download = `${new Intl.DateTimeFormat('tr', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date()).replaceAll('.', '-')}-${fileName}.xlsx`
  } else {
    fileURL = data?.fileUrl;
  }

  a.href = fileURL;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();

  URL.revokeObjectURL(fileURL);
  document.body.removeChild(a);
}
