export const expenseinvoiceConstants = {
  //#region ExpenseInvoice
  EXPENSEINVOICE_GETALL_REQUEST: 'EXPENSEINVOICE_GETALL_REQUEST',
  EXPENSEINVOICE_GETALL_SUCCESS: 'EXPENSEINVOICE_GETALL_SUCCESS',
  EXPENSEINVOICE_GETALL_FAILURE: 'EXPENSEINVOICE_GETALL_FAILURE',

  EXPENSEINVOICE_DELETE_REQUEST: 'EXPENSEINVOICE_DELETE_REQUEST',
  EXPENSEINVOICE_DELETE_SUCCESS: 'EXPENSEINVOICE_DELETE_SUCCESS',
  EXPENSEINVOICE_DELETE_FAILURE: 'EXPENSEINVOICE_DELETE_FAILURE',

  EXPENSEINVOICE_ARCHIVE_REQUEST: 'EXPENSEINVOICE_ARCHIVE_REQUEST',
  EXPENSEINVOICE_ARCHIVE_SUCCESS: 'EXPENSEINVOICE_ARCHIVE_SUCCESS',
  EXPENSEINVOICE_ARCHIVE_FAILURE: 'EXPENSEINVOICE_ARCHIVE_FAILURE',

  EXPENSEINVOICE_EXCEL_REQUEST: 'EXPENSEINVOICE_EXCEL_REQUEST',
  EXPENSEINVOICE_EXCEL_SUCCESS: 'EXPENSEINVOICE_EXCEL_SUCCESS',
  EXPENSEINVOICE_EXCEL_FAILURE: 'EXPENSEINVOICE_EXCEL_FAILURE',

  EXPENSEINVOICEBANK_CREATE_REQUEST: 'EXPENSEINVOICEBANK_CREATE_REQUEST',
  EXPENSEINVOICEBANK_CREATE_SUCCESS: 'EXPENSEINVOICEBANK_CREATE_SUCCESS',
  EXPENSEINVOICEBANK_CREATE_FAILURE: 'EXPENSEINVOICEBANK_CREATE_FAILURE',

  EXPENSEINVOICEBANK_UPDATE_REQUEST: 'EXPENSEINVOICEBANK_UPDATE_REQUEST',
  EXPENSEINVOICEBANK_UPDATE_SUCCESS: 'EXPENSEINVOICEBANK_UPDATE_SUCCESS',
  EXPENSEINVOICEBANK_UPDATE_FAILURE: 'EXPENSEINVOICEBANK_UPDATE_FAILURE',

  EXPENSEINVOICEBANK_GETID_REQUEST: 'EXPENSEINVOICEBANK_GETID_REQUEST',
  EXPENSEINVOICEBANK_GETID_SUCCESS: 'EXPENSEINVOICEBANK_GETID_SUCCESS',
  EXPENSEINVOICEBANK_GETID_FAILURE: 'EXPENSEINVOICEBANK_GETID_FAILURE',

  EXPENSEINVOICEDETAIL_CREATE_REQUEST: 'EXPENSEINVOICEDETAIL_CREATE_REQUEST',
  EXPENSEINVOICEDETAIL_CREATE_SUCCESS: 'EXPENSEINVOICEDETAIL_CREATE_SUCCESS',
  EXPENSEINVOICEDETAIL_CREATE_FAILURE: 'EXPENSEINVOICEDETAIL_CREATE_FAILURE',

  EXPENSEINVOICEDETAIL_UPDATE_REQUEST: 'EXPENSEINVOICEDETAIL_UPDATE_REQUEST',
  EXPENSEINVOICEDETAIL_UPDATE_SUCCESS: 'EXPENSEINVOICEDETAIL_UPDATE_SUCCESS',
  EXPENSEINVOICEDETAIL_UPDATE_FAILURE: 'EXPENSEINVOICEDETAIL_UPDATE_FAILURE',

  EXPENSEINVOICEDETAIL_GETID_REQUEST: 'EXPENSEINVOICEDETAIL_GETID_REQUEST',
  EXPENSEINVOICEDETAIL_GETID_SUCCESS: 'EXPENSEINVOICEDETAIL_GETID_SUCCESS',
  EXPENSEINVOICEDETAIL_GETID_FAILURE: 'EXPENSEINVOICEDETAIL_GETID_FAILURE',

  EXPENSEINVOICEFAST_CREATE_REQUEST: 'EXPENSEINVOICEFAST_CREATE_REQUEST',
  EXPENSEINVOICEFAST_CREATE_SUCCESS: 'EXPENSEINVOICEFAST_CREATE_SUCCESS',
  EXPENSEINVOICEFAST_CREATE_FAILURE: 'EXPENSEINVOICEFAST_CREATE_FAILURE',

  EXPENSEINVOICEFAST_UPDATE_REQUEST: 'EXPENSEINVOICEFAST_UPDATE_REQUEST',
  EXPENSEINVOICEFAST_UPDATE_SUCCESS: 'EXPENSEINVOICEFAST_UPDATE_SUCCESS',
  EXPENSEINVOICEFAST_UPDATE_FAILURE: 'EXPENSEINVOICEFAST_UPDATE_FAILURE',

  EXPENSEINVOICEFAST_GETID_REQUEST: 'EXPENSEINVOICEFAST_GETID_REQUEST',
  EXPENSEINVOICEFAST_GETID_SUCCESS: 'EXPENSEINVOICEFAST_GETID_SUCCESS',
  EXPENSEINVOICEFAST_GETID_FAILURE: 'EXPENSEINVOICEFAST_GETID_FAILURE',

  EXPENSEINVOICESUNDRY_CREATE_REQUEST: 'EXPENSEINVOICESUNDRY_CREATE_REQUEST',
  EXPENSEINVOICESUNDRY_CREATE_SUCCESS: 'EXPENSEINVOICESUNDRY_CREATE_SUCCESS',
  EXPENSEINVOICESUNDRY_CREATE_FAILURE: 'EXPENSEINVOICESUNDRY_CREATE_FAILURE',

  EXPENSEINVOICESUNDRY_UPDATE_REQUEST: 'EXPENSEINVOICESUNDRY_UPDATE_REQUEST',
  EXPENSEINVOICESUNDRY_UPDATE_SUCCESS: 'EXPENSEINVOICESUNDRY_UPDATE_SUCCESS',
  EXPENSEINVOICESUNDRY_UPDATE_FAILURE: 'EXPENSEINVOICESUNDRY_UPDATE_FAILURE',

  EXPENSEINVOICESUNDRY_GETID_REQUEST: 'EXPENSEINVOICESUNDRY_GETID_REQUEST',
  EXPENSEINVOICESUNDRY_GETID_SUCCESS: 'EXPENSEINVOICESUNDRY_GETID_SUCCESS',
  EXPENSEINVOICESUNDRY_GETID_FAILURE: 'EXPENSEINVOICESUNDRY_GETID_FAILURE',

  EXPENSEINVOICETAX_CREATE_REQUEST: 'EXPENSEINVOICETAX_CREATE_REQUEST',
  EXPENSEINVOICETAX_CREATE_SUCCESS: 'EXPENSEINVOICETAX_CREATE_SUCCESS',
  EXPENSEINVOICETAX_CREATE_FAILURE: 'EXPENSEINVOICETAX_CREATE_FAILURE',

  EXPENSEINVOICETAX_UPDATE_REQUEST: 'EXPENSEINVOICETAX_UPDATE_REQUEST',
  EXPENSEINVOICETAX_UPDATE_SUCCESS: 'EXPENSEINVOICETAX_UPDATE_SUCCESS',
  EXPENSEINVOICETAX_UPDATE_FAILURE: 'EXPENSEINVOICETAX_UPDATE_FAILURE',

  EXPENSEINVOICETAX_GETID_REQUEST: 'EXPENSEINVOICETAX_GETID_REQUEST',
  EXPENSEINVOICETAX_GETID_SUCCESS: 'EXPENSEINVOICETAX_GETID_SUCCESS',
  EXPENSEINVOICETAX_GETID_FAILURE: 'EXPENSEINVOICETAX_GETID_FAILURE',
  //#endregion            
};
