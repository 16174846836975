/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { KTCard, KTCardBody, Select } from '../../../components';
import { PageTitle } from '../../../core';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions, projectActions } from '../../../redux/actions';

function Create() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation()
  const pageUrl = location.pathname.split('/')

  const { projectLookup, constructionSiteLookup, blockLookup, buildingBlockTypeLookup } = useSelector(state => state.lookup);
  const { isRedirect, loading } = useSelector(state => state.project);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
  }, [])

  const validSchema = Yup.object().shape({
    Name: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ProjectId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    ConstructionSiteId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    BlockId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    BuildingBlockTypeId: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
    BasementHeight: Yup.string().required(intl.formatMessage({ id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    Name: '',
    ProjectId: '',
    ConstructionSiteId: '',
    BlockId: '',
    BuildingBlockTypeId: '',
    BasementHeight: '',
    Description: '',
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      dispatch(projectActions.plotCreate(values));
      resetForm()
    },
  })

  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'BC.PLOT' }),
      path: '/projects/plot',
      isSeparator: false,
      isActive: false,
    }
  ]

  useEffect(() => {
    if (isRedirect?.id) navigate(`/${pageUrl[1]}/${pageUrl[2]}/detail/${isRedirect?.id}`)
  }, [isRedirect])

  useEffect(() => {
    if (formik.values.ProjectId) {
      dispatch(lookupActions.constructionSiteLookup({projectId: formik.values.ProjectId}));
      formik.setFieldValue('ConstructionSiteId', '')
      formik.setFieldValue('BlockId', '')
      formik.setFieldValue('BuildingBlockTypeId', '')
    }
  }, [formik.values.ProjectId])

  useEffect(() => {
    if (formik.values.ConstructionSiteId) {
      dispatch(lookupActions.blockLookup({constructionSiteId: formik.values.ConstructionSiteId}));
      formik.setFieldValue('BlockId', '')
      formik.setFieldValue('BuildingBlockTypeId', '')
    }
  }, [formik.values.ConstructionSiteId])

  useEffect(() => {
    if (formik.values.BlockId) {
      dispatch(lookupActions.buildingBlockTypeLookup({
        projectId: formik.values.ProjectId
      }));
      formik.setFieldValue('BuildingBlockTypeId', null)
    }
  }, [formik.values.BlockId])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({ id: 'BC.PLOT.ADD' })}</PageTitle>
      <KTCard>
        <KTCardBody>
          <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PLOT.SEARCH.NAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('Name')}
                />
                {formik.touched.Name && formik.errors.Name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PLOT.SEARCH.BASEMENTHEIGHT' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <input
                  type='text'
                  required
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('BasementHeight')}
                />
                {formik.touched.BasementHeight && formik.errors.BasementHeight && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.BasementHeight}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PLOT.SEARCH.PROJECTNAME' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <Select
                  data={projectLookup}
                  required
                  {...formik.getFieldProps('ProjectId')}
                  onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                />
                {formik.touched.ProjectId && formik.errors.ProjectId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ProjectId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {formik.values.ProjectId && <>
              <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PLOT.SEARCH.CONSTRUCTIONSITENAME' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    required
                    data={constructionSiteLookup}
                    {...formik.getFieldProps('ConstructionSiteId')}
                    onChange={item => formik.setFieldValue('ConstructionSiteId', Number(item?.value))}
                  />
                  {formik.touched.ConstructionSiteId && formik.errors.ConstructionSiteId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ConstructionSiteId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {formik.values.ConstructionSiteId && <div className='row mb-6'>
                <label className='col-lg-3 required fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PLOT.SEARCH.BLOCKNAME' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    required
                    data={blockLookup}
                    {...formik.getFieldProps('BlockId')}
                    onChange={item => formik.setFieldValue('BlockId', Number(item?.value))}
                  />
                  {formik.touched.BlockId && formik.errors.BlockId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.BlockId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div> }

              {formik.values.BlockId && <div className='row mb-6'>
                <label className='col-lg-3 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'PLOT.SEARCH.BUILDINGBLOCKTYPENAME' })}</label>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <Select
                    data={buildingBlockTypeLookup}
                    {...formik.getFieldProps('BuildingBlockTypeId')}
                    onChange={item => formik.setFieldValue('BuildingBlockTypeId', Number(item?.value))}
                  />                  
                </div>
              </div>}
            </> }
            
            <div className='row mb-6'>
              <label className='col-lg-3 fw-bold fs-6 mb-2'>{intl.formatMessage({ id: 'PLOT.SEARCH.DESCRIPTION' })}</label>
              <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                <textarea
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={2}
                  {...formik.getFieldProps('Description')}
                />
              </div>
            </div>
            
            <div className='row pt-15'>
              <div className='col-lg-9 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                <button className="btn btn-light" onClick={() => navigate(`/${pageUrl[1]}/${pageUrl[2]}`)}>{intl.formatMessage({ id: 'BUTTON.CANCEL' })}</button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SUBMIT' })}</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({ id: 'PLEASE' })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { Create };
