import React from 'react'
import { KTCard } from '../../../components'

function List() {

  return (
    <KTCard>
      
    </KTCard>
  )
}
export {List};
