import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { KTCard } from '../Card/KTCard'
import { KTCardBody } from '../Card/KTCardBody'
import { KTCardHeader } from '../Card/KTCardHeader'
import { KTSVG } from '../../helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../redux/actions';
import { DeleteModal } from '../';

const Notes = ({collapse, SourceId, SourceTypeId}) => {
  const intl = useIntl()
  const [isCollapse, setIsCollapse] = useState(collapse);
  const [deleteId, setDeleteId] = useState();
  const [show, setShow] = useState(false);

  const { note, noteCreate, error } = useSelector(state => state.common);
	const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.noteGetAll({SourceId, SourceTypeId}));
  }, [SourceId, SourceTypeId, noteCreate])
  
  const validSchema = Yup.object().shape({
    Description: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' }))
  })

  const [formValues] = useState({
    SourceId,
    SourceTypeId,
    Description: '',
    File: null,
  })

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      dispatch(commonActions.noteCreate(values.Description, values.SourceId, values.SourceTypeId, values.File));
      resetForm()
    },
  })

  const handleClickDelete = (id) => {
    setDeleteId(id)
    setShow(true)
  }
  const handleDelete = () => {
    dispatch(commonActions.noteDelete({Id: deleteId, SourceId, SourceTypeId}));
    setShow(false)
  }

  return (
    <>
      <KTCard className='mb-5'>
        <KTCardHeader title={intl.formatMessage({id: 'NOTES' })} collapsed={isCollapse} onCollapse={(event) => setIsCollapse(event)} />

        <KTCardBody 
          className='p-5'
          collapseble
          isShow={isCollapse}
        >
          <div className='card-scroll mh-300px'>
            {note?.map((item, index) => <div key={index} className='overflow-hidden'>
              <div className='row mb-7'>
                <div className='col-10'>
                  <div className='d-flex flex-column'>
                    <div className='text-gray-800 fs-6 fw-bold text-truncate w-100'>
                      {item.displayName}
                    </div>
                    <span className='text-gray-600 fw-semibold'>{item.description}</span>
                    <span className='text-gray-400 fw-semibold'>{intl.formatDate(new Date(item?.noteDate), { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                    {item.fileUrl && <div className='d-flex align-items-center mt-2'>
                      <a
                        href={item.fileUrl}
                        className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        <KTSVG path='/images/icons/duotune/communication/com008.svg' className='svg-icon-3' />
                        {item.fileName}
                      </a>
                    </div> }
                  </div>
                </div>
                <div className='col-2'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-danger'
                    onClick={() => handleClickDelete(item?.id)}
                  >
                    <KTSVG path='/images/icons/duotune/general/gen040.svg' className='svg-icon-2' />
                  </button>
                </div>
              </div>

              <div className='separator mb-4'></div>
            </div> )}

          </div>

          <form className='form position-relative' onSubmit={formik.handleSubmit} noValidate>
            <div className='d-flex gap-3'>
              <div className='fv-row fv-plugins-icon-container w-100'>
                <textarea
                  className='form-control form-control-solid'
                  rows={2}
                  required
                  placeholder={intl.formatMessage({id: 'NOTES.ADD' })}
                  {...formik.getFieldProps('Description')}
                />
                {formik.touched.Description && formik.errors.Description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Description}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row fv-plugins-icon-container d-flex gap-1 ps-0'>
                <label htmlFor='noteFile' className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary position-relative overflow-hidden'>
                  <input
                    id='noteFile'
                    className='position-absolute z-index-2 opacity-0'
                    style={{width: 0, height: 0}}
                    type='file' 
                    accept='image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    name={'File'}
                    onChange={(event) => formik.setFieldValue('File', event.currentTarget.files[0])}
                  />
                  <KTSVG
                    path='/images/icons/duotune/communication/com008.svg'
                    className='svg-icon-3 position-relative z-index-1'
                  />
                </label>

                <button type='submit' className='btn btn-sm btn-icon btn-icon-white btn-bg-primary'>
                  <KTSVG path='/images/icons/duotune/general/gen016.svg' className='svg-icon-3' />
                </button>
              </div>
            </div>
          </form>
        </KTCardBody>
      </KTCard>

      <DeleteModal 
        onClick={handleDelete} 
        onOpen={show} 
        onClose={() => setShow(false)}
        error={error}
      />
    </>
  )
}

Notes.propTypes = {
	collapse: PropTypes.bool,
  SourceId: PropTypes.number,
	SourceTypeId: PropTypes.number,
};

Notes.defaultProps = {
	collapse: true
};

export {Notes}
