/* eslint-disable react/react-in-jsx-scope */
import clsx from 'clsx';
import { useMemo, useState } from 'react';

const CustomHeader = ({ className, title, tableProps, handleChangeSorting }) => {
  const id = tableProps.column.id;
  const [state, setState] = useState();

  const isSelectedForSorting = useMemo(() => {
    return state?.order && state?.order === id;
  }, [state, id]);

  const sort = useMemo(() => state?.sort, [state]);

  const sortColumn = () => {
    if (!isSelectedForSorting) {
      setState({ order: id, sort: 'asc'});
      handleChangeSorting && handleChangeSorting({ order: id, sort: 'asc'})
      return;
    }

    if (isSelectedForSorting && sort !== undefined) {
      if (sort === 'asc') {
        setState({ order: id, sort: 'desc' });
        handleChangeSorting && handleChangeSorting({ order: id, sort: 'desc' })
        return;
      }
      
      setState({ sort: undefined, order: undefined });
      handleChangeSorting && handleChangeSorting({ sort: undefined, order: undefined })
    }
  };

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && sort !== undefined && `table-sort-${sort}`
      )}
      style={{ cursor: 'pointer' }}
      onClick={sortColumn}
    >
      {title}
    </th>
  );
};

export { CustomHeader };
