import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client';
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../core';
import * as WebDataRocksReact from 'react-webdatarocks';
import { GetDynamicReportEntityClient } from '../../../api'

import * as Yup from 'yup'
import {useFormik} from 'formik'

import { KTCard, KTCardBody, Select } from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { lookupActions } from '../../../redux/actions';

//https://github.com/WebDataRocks/pivot-react/tree/master/typescript/src

function TaskCompleteRateByProject() {
  const intl = useIntl()
  const dispatch = useDispatch();

  const { projectLookup } = useSelector(state => state.lookup);
  const [loading, setLoading] = useState(false)

  const validSchema = Yup.object().shape({
    ProjectId: Yup.string().required(intl.formatMessage({id: 'REQUIRED.GENERAL' })),
  })

  const [formValues] = useState({
    ProjectId: '',
  })

  useEffect(() => {
    dispatch(lookupActions.projectLookup());
  }, [])

  const SERVICE_URL = window.ENVVARS.REACT_APP_SERVICE_URL;
  const reportClient = new GetDynamicReportEntityClient(SERVICE_URL);

  const columns = [
    {
      "uniqueName": "rootTaskName",
      "caption": "ANA İŞ PROGRAMI"
    },
    {
      "uniqueName": "name",
      "caption": "ALT İŞ PROGRAMI"
    }         
  ]

  function customizeToolbar(toolbar) {
    let tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      delete tabs[0]; // delete Connect
      delete tabs[1]; // delete Open
      return tabs;
    }
  }

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)

      reportClient.taskCompleteRateByProject(values.ProjectId).then(reportJson => {
        setLoading(false)
        reportJson.forEach(item => {
          for (const key in item) {
            if (!columns.find(column => column.uniqueName === key)) {
              const newColumn = {
                "uniqueName": key,
                "caption": key.toUpperCase()
              };
              columns.push(newColumn);
            }
          }
        });
    
        let report = {
          "formats": [
            {
              "name": "",
              "decimalPlaces": 2,
              "thousandsSeparator": ".",
              "decimalSeparator": ","
            }
          ],
          dataSource: {
            data: reportJson
          },
          localization: `https://cdn.webdatarocks.com/loc/${intl.locale}.json`,
          options: {
            grid: {
              type: "flat",
              showGrandTotals: "off"
            },
            showEmptyData: false
            },
            "slice": {
              columns
            }
        }
    
        const root = createRoot(document.getElementById('report-app'));
        root.render(<WebDataRocksReact.Pivot
          toolbar={true}
          width="100%"
          height="100%"
          report={report}
          beforetoolbarcreated={customizeToolbar}
        />);
      })
    },
  })

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'DYNAMICREPORT.REPORT.TASKCOMPLETERATEBYPROJECT' })}</PageTitle>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-12'>
          <KTCard className='mb-5'>
            <KTCardBody className='p-9'>
              <form className='form' onSubmit={formik.handleSubmit} noValidate>

                <div className='row mb-6'>
                  <div className='col-lg-6'>
                    <div className='row mb-6'>
                      <label className='col-lg-12 fw-bold fs-6 mb-2 required'>{intl.formatMessage({ id: 'DYNAMICREPORT.REPORT.PROJECT' })}</label>
                      <div className='col-lg-12 fv-row fv-plugins-icon-container'>
                        <Select
                          data={projectLookup}
                          {...formik.getFieldProps('ProjectId')}
                          onChange={item => formik.setFieldValue('ProjectId', Number(item?.value))}
                        />
                        {formik.touched.ProjectId && formik.errors.ProjectId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.ProjectId}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row pt-5'>
                  <div className='col-lg-12 fv-row fv-plugins-icon-container d-flex gap-3 justify-content-center'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      data-kt-users-modal-action='submit'
                      disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                      <span className='indicator-label'>{intl.formatMessage({ id: 'BUTTON.SEARCH' })}</span>
                      {(formik.isSubmitting) && (
                        <span className='indicator-progress'>
                          {intl.formatMessage({ id: 'PLEASE' })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </KTCardBody>
          </KTCard>
        </div>
      </div>

      <div className="App" id="report-app" style={{height: '80vh'}} />

      {loading && (<div className="page-loader flex-column bg-dark bg-opacity-50">
        <span className="spinner-border text-primary" role="status"></span>
        <span className="text-light fs-6 fw-semibold mt-5">{intl.formatMessage({ id: 'LOADING' })}</span>
      </div>)}
    </>
  )
}
export { TaskCompleteRateByProject };
