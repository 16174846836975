export const PurchaseProposalStatus = [
  {
    id: 1,
    name: 'PURCHASEPROPALSTATUS.EVALUATING'
  },
  {
    id: 2,
    name: 'PURCHASEPROPALSTATUS.APPROVED'
  },
  {
    id: 3,
    name: 'PURCHASEPROPALSTATUS.DENIED'
  }
]