/* eslint-disable react/react-in-jsx-scope */
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl'
import { ExportButtons } from '../ExportButtons';

const DataTables = ({columns, data, className, onClick, initialOrder, initialProp, intialRowsPerPage, exportTitle}) => {
  const intl = useIntl()

  const excelData = data.map(item => {
    const newItem = {};
    columns.forEach(column => {
      newItem[column.title] = item[column.prop];
    });
    return newItem;
  });

  const pdfData = {
    columns: columns.map(column => column.title),
    data: data.map(item => {
      return columns.map(column => {
        const value = item[column.prop];
        if (value instanceof Date) {
          const formattedDate = intl.formatDate(new Date(value), { year: 'numeric', month: 'long', day: 'numeric' });
          return formattedDate;
        } else {
          return value;
        }
      });
    })
  };
  
  return (
    <div className={className}>
      <DatatableWrapper
        body={data}
        headers={columns}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: intialRowsPerPage,
            options: [10, 20, 50, 100]
          }
        }}
        sortProps={{
          initialState: {
            prop: initialProp,
            order: initialOrder
          }
        }}
      >
        <div className='row'>
          <div className='col-6 d-flex align-items-center justify-content-start'>
            <div className='d-flex align-items-center position-relative my-1'>
              <Filter 
                classes={{input: 'form-control-solid form-select-sm w-150px z-index-1 mb-3 mt-3', clearButton: 'd-none'}} 
                placeholder={intl.formatMessage({ id: 'DASHBOARD.SEARCH' })} 
              />
            </div>
          </div>
          <div className='col-6 d-flex align-items-center justify-content-end'>
            <ExportButtons excelData={excelData} pdfData={pdfData} title={exportTitle} />
          </div>
        </div>

        <Table responsive striped hover>
          <TableHeader classes={{tr: 'border-bottom fs-7 fw-bold text-gray-700 text-uppercase', th: 'p-5'}} />
          <TableBody classes={{td: 'p-5 text-dark fw-semibold fs-6'}} rowProps={{'role': onClick ? 'button': 'cell'}} onRowClick={(item) => onClick && onClick(item)} labels={{noResults: intl.formatMessage({ id: 'NON_RESULT'})}} />
        </Table>

        <div className='row'>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
            <PaginationOptions classes={{formControl: 'form-select form-select-sm form-select-solid', formText: 'd-none'}} />
          </div>
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
            <Pagination 
              labels={{firstPage: '<<', prevPage: '<', nextPage: '>', lastPage: '>>'}} 
              classes={{button: 'btn-sm btn-light-primary'}} 
            />
          </div>
        </div>
      </DatatableWrapper>
    </div>
  )
}

DataTables.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	initialOrder: PropTypes.string,
	initialProp: PropTypes.string,
	intialRowsPerPage: PropTypes.number,
  exportTitle: PropTypes.string
};

DataTables.defaultProps = {
	initialOrder: 'desc',
	initialProp: 'id',
	intialRowsPerPage: 10
};

export {DataTables}
